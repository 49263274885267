import types from '../types';

const addKPIRequest = data => ({
  type: types.ADD_KPI_REQUEST,
  payload: data,
});

const addKPIResponse = (data, request = null) => ({
  type: types.ADD_KPI_RESPONSE,
  payload: data,
  request,
});

const updateKPIRequest = data => ({
  type: types.UPDATE_KPI_REQUEST,
  payload: data,
});

const updateKPIResponse = (data, request = null) => ({
  type: types.UPDATE_KPI_RESPONSE,
  payload: data,
  request,
});

const deleteKPIRequest = data => ({
  type: types.DELETE_KPI_REQUEST,
  payload: data,
});

const deleteKPIResponse = (data, request = null) => ({
  type: types.DELETE_KPI_RESPONSE,
  payload: data,
  request,
});

export {
  addKPIRequest,
  addKPIResponse,
  updateKPIRequest,
  updateKPIResponse,
  deleteKPIRequest,
  deleteKPIResponse,
};
