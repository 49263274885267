import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import RoundButton from '~UI/RoundButton/RoundButton';
import { BackIcon } from '~UI/RoundButton/RoundIcons';
import imagesLibrary from '~images/imagesLibrary';

const propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  value: undefined,
};

const IconGroup = ({ options, value, onChange }) => {
  const parseOptions = (optionsArg, valueArg) => {
    // TODO: use breadth-first traversal to support unlimited level
    let newCurOptions;
    let newShowBackBtn = false;
    let newHasLeafSelected = false;
    const selected = valueArg && optionsArg.find(option => option.value === valueArg);
    if (selected) {
      // we find it on first level
      if (selected.enum && selected.enum.length > 0) {
        // it is an intermediate option, display its enum
        newCurOptions = selected.enum;
        newShowBackBtn = true;
      } else {
        // it is a leaf, display first level, render() will highlight the selected one
        newCurOptions = optionsArg;
        newHasLeafSelected = true;
      }
    } else {
      const selectedGroup = optionsArg.find(option => option.enum && option.enum.some(opt => opt.value === valueArg));
      if (selectedGroup) {
        // we find it on second level
        newCurOptions = selectedGroup.enum;
        newHasLeafSelected = true;
        newShowBackBtn = true;
      } else {
        newCurOptions = optionsArg;
      }
    }
    return { curOptions: newCurOptions, showBackBtn: newShowBackBtn, hasLeafSelected: newHasLeafSelected };
  };

  const {
    curOptions: initCurOptions,
    showBackBtn: initShowBackBtn,
    hasLeafSelected: initHasLeafSelected,
  } = parseOptions(options, value);

  const [curOptions, setCurOptions] = useState(initCurOptions);
  const [showBackBtn, setShowBackBtn] = useState(initShowBackBtn);
  const [hasLeafSelected, setHasLeafSelected] = useState(initHasLeafSelected);

  useEffect(() => {
    const {
      curOptions: newCurOptions,
      showBackBtn: newShowBackBtn,
      hasLeafSelected: newHasLeafSelected,
    } = parseOptions(options, value);
    setCurOptions(newCurOptions);
    setShowBackBtn(newShowBackBtn);
    setHasLeafSelected(newHasLeafSelected);
  }, [value]);

  const renderMenu = () => curOptions.map(option => (
    <RoundButton
      key={option.value}
      className={classnames({
        Selected: value === option.value,
        Unselected: value !== option.value && hasLeafSelected,
      })}
      name={option.label || option.value}
      color={option.color}
      backgroundImage={imagesLibrary[option.icon]}
      backgroundSize={option.size || 'cover'}
      onClick={() => onChange(option.value)}
    />
  ));

  return (
    <div className="IconGroup">
      {showBackBtn && <BackIcon onClick={() => onChange(undefined)} />}
      {renderMenu()}
    </div>
  );
};

IconGroup.propTypes = propTypes;
IconGroup.defaultProps = defaultProps;

export default IconGroup;
