import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import { ClientMetricsAction, ClientMetricsActionTypes } from './actions';
import { ClientMetrics } from './types';

export type ClientMetricsReducerState = ClientMetrics[] | null;

function clientMetrics(state: ClientMetricsReducerState = null, action: ClientMetricsAction) {
  switch (action.type) {
    case ClientMetricsActionTypes.GET_CLIENT_METRICS_RESPONSE:
      return action.payload.clientMetrics;
    default:
      return state;
  }
}

const clientMetricsPropType = PropTypes.arrayOf(PropTypes.shape({
  timestamp: PropTypes.number.isRequired,
  triggers: PropTypes.number.isRequired,
  actions: PropTypes.number.isRequired,
}));

export const propTypes = PropTypes.arrayOf(nullable(clientMetricsPropType));

export default clientMetrics;
