import types from './types';

const commandsUndo = () => ({
  type: types.UNDO,
});

const commandsUndoFail = () => ({
  type: types.UNDO_FAIL,
});

const commandsRedo = () => ({
  type: types.REDO,
});

const commandsRedoFail = () => ({
  type: types.REDO_FAIL,
});

const commandsSave = command => ({
  type: types.SAVE,
  payload: command,
});

export {
  commandsUndo,
  commandsUndoFail,
  commandsRedo,
  commandsRedoFail,
  commandsSave,
};
