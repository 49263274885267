import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import {
  DisplayInterval,
  DisplayIntervalBetweenTimes,
  DisplaySpecificTime,
  DisplayFactoryShifts,
  DisplayMachineShifts,
  DisplayAllShifts,
} from './RepeatOptions';
import { splitTime } from '~utils/time';
import { convertFormToStringUtil, getSelectedDaysFromCron, getSelectedShiftsFromCron, shiftToCronString } from '../utils';
import { sortArray } from '~utils/sort';
import '../TriggerForms.scss';

const options = {
  none: 'none',
  interval: 'interval',
  intervalBetweenTimes: 'intervalBetweenTimes',
  specificTime: 'specificTime',
  factoryShifts: 'factoryShifts',
  machineShifts: 'machineShifts',
  allShifts: 'allShifts',
};
class CronTriggerForm extends Component {
  constructor(props) {
    super(props);
    const { t, trigger, shifts, machines } = props;
    const { details } = trigger;
    const repeatSelected = details?.repeatOption ?? 'none';
    const cron = details?.cronTime ?? '';
    const shiftTimeOption = details?.shiftTimeOption;
    const machineId = details?.machineId;
    const timezone = details?.timezone;
    const factoryShifts = shifts;

    const cronStrings = new Set();
    if (details?.cronTime) {
      cronStrings.add(details.cronTime);
    }
    const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));
    const machine = machines.find(m => m.id === machineId);
    const machineShifts = machine?.shifts || [];
    const repeatOption = repeatSelected || options.none;
    const allShifts = shifts.concat(...machines.map(m => m.shifts));

    this.state = {
      allShifts,
      machineId,
      machine,
      machineShifts,
      machineOptions,
      factoryShifts,
      timezone,
      shiftTimeOption,
      repeatOption,
      units: [
        { value: 'Seconds', label: t('Seconds') },
        { value: 'Minutes', label: t('Minutes') },
        { value: 'Hours', label: t('Hours') },
      ],
      intervalAmount: details?.amount ?? null,
      intervalUnit: details?.unitSelected ?? 'Seconds',
      startTimeStr: details?.startTime ?? null,
      endTimeStr: details?.endTime ?? null,
      specificTime: details?.oneTime ?? null,
      specificTimeObj: {
        hourSpecific: details?.oneTime ? splitTime(details.oneTime).hours : null,
        minuteSpecific: details?.oneTime ? splitTime(details.oneTime).minutes : null,
      },
      cron,
      selectedDays: getSelectedDaysFromCron(cron, cron.length === 0),
      selectedShifts: getSelectedShiftsFromCron(
        cron, shiftTimeOption, repeatOption, factoryShifts, machineShifts, allShifts,
      ),
      cronStrings,
    };
  }

  handleShiftSelect = shift => {
    const { shiftTimeOption } = this.state;

    this.setState(prevState => {
      let newSelectedShifts = [...prevState.selectedShifts];
      const prevStateHasShift = prevState.selectedShifts.find(s => s === shift);
      if (prevStateHasShift) {
        newSelectedShifts = newSelectedShifts.filter(s => s !== shift);
      } else {
        newSelectedShifts.push(shift);
      }

      const newCronStrings = newSelectedShifts.map(selectedShift => shiftToCronString(selectedShift, shiftTimeOption));

      return {
        selectedShifts: newSelectedShifts,
        cronStrings: new Set(newCronStrings),
        cron: Array.from(new Set(newCronStrings)).join('+'),
        timezone: newSelectedShifts[0]?.timezone || null,
      };
    });
  };

  handleTimezoneChange = selectedTimezone => {
    this.setState({
      timezone: selectedTimezone,
    });
  };

  handleOptionChange = selectedOption => {
    const { repeatOption, allShifts } = this.state;
    this.setState({
      shiftTimeOption: selectedOption,
      timezone: null,
      cronStrings: new Set(),
      cron: '',
      selectedShifts: [],
    }, () => {
      if (repeatOption === 'allShifts') {
        allShifts.forEach(shift => this.handleShiftSelect(shift));
      }
    });
  };

  handleMachineChange = machineSelect => {
    const { machines } = this.props;
    const machineSelected = machines.find(m => m.id === machineSelect);
    this.setState({
      machineId: machineSelect,
      machine: machineSelected,
      machineShifts: machineSelected.shifts,
      shiftTimeOption: '',
      timezone: null,
    });
  };

  handleRepeatChange = e => {
    this.setState({
      shiftTimeOption: '',
      machine: '',
      machineId: '',
      cron: '',
      repeatOption: e,
      selectedDays: getSelectedDaysFromCron('', true),
      intervalAmount: 1,
      timezone: null,
      startTimeStr: null,
      endTimeStr: null,
      specificTimeObj: {
        hourSpecific: null,
        minuteSpecific: null,
      },
      intervalUnit: 'Seconds',
    }, () => this.convertFormToString());
  };

  handleDayOfWeekCheckboxChange = e => {
    const dayOfWeek = e.target.value;

    this.setState(prevState => ({
      selectedDays: {
        ...prevState.selectedDays,
        [dayOfWeek]: {
          value: !prevState.selectedDays[dayOfWeek].value,
          name: dayOfWeek,
        },
      },
    }), () => this.convertFormToString());
  };

  handleIntervalChange = e => {
    this.setState({
      intervalAmount: e.target.value,
    }, () => this.convertFormToString());
  };

  handleUnitChange = newUnit => {
    if (newUnit === 'Seconds' || newUnit === 'Minutes' || newUnit === 'Hours') {
      this.setState(prevState => ({
        intervalUnit: newUnit,
        intervalAmount: newUnit === 'Hours' && prevState.intervalAmount > 23 ? 1 : prevState.intervalAmount,
      }), () => this.convertFormToString());
    } else {
      this.setState({
        intervalAmount: Number(newUnit),
      });
    }
  };

  handleTimeChange = (startOrEnd, time) => {
    switch (startOrEnd) {
      case 'startTime':
        this.setState(prevState => ({
          startTimeStr: time,
          endTimeStr: prevState.endTimeStr || '00:00',
        }), () => this.convertFormToString());
        break;
      case 'endTime':
        this.setState(prevState => ({
          endTimeStr: time,
          startTimeStr: prevState.startTimeStr || '00:00',
        }), () => this.convertFormToString());
        break;
      case 'oneTime':
        this.setState({
          specificTimeObj: {
            hourSpecific: splitTime(time).hours,
            minuteSpecific: splitTime(time).minutes,
          },
        }, () => this.convertFormToString());
        break;
      default:
        break;
    }
  };

  convertFormToString = () => {
    const {
      startTimeStr, endTimeStr, specificTimeObj, intervalAmount, intervalUnit, selectedDays,
    } = this.state;
    this.setState(
      convertFormToStringUtil(
        selectedDays,
        startTimeStr,
        endTimeStr,
        specificTimeObj,
        intervalAmount,
        intervalUnit,
      ), () => {
        const { cron } = this.state;
        this.setState({ cronStrings: new Set([cron]) });
      },
    );
  };

  displayDaysWeek = () => {
    const { t } = this.props;
    const { selectedDays } = this.state;

    const columns = Object.keys(selectedDays).map(day => (
      <Col className="dayCheckboxes" xs={6} md={2} key={selectedDays[day].name}>
        <input
          className="dayCheckbox"
          type="checkbox"
          name={selectedDays[day].name}
          value={selectedDays[day].name}
          checked={selectedDays[day].value}
          onChange={this.handleDayOfWeekCheckboxChange}
        />
        &nbsp;
        {t(selectedDays[day].name)}
      </Col>
    ));

    return (
      <div>
        <span className="onText">{t('onDate')}</span>
        <Grid className="checkboxContainer">
          <Row>
            {columns[0]}
            {columns[1]}
            {columns[2]}
          </Row>
          <Row>
            {columns[3]}
            {columns[4]}
            {columns[5]}
          </Row>
          <Row>
            {columns[6]}
          </Row>
        </Grid>
      </div>
    );
  };

  displayTimezoneOptions = () => {
    const { timezone } = this.state;
    const { t } = this.props;
    return (
      <div>
        <div className="inputTitle">{t('timezone')}</div>
        <Select
          style={{ width: '300px' }}
          name="timezone"
          options={moment.tz.names().map(tz => ({ value: tz, label: tz.replace(/_/g, ' ') }))}
          onChange={this.handleTimezoneChange}
          value={timezone || 'America/Toronto'}
          placeholder={t('selectTimezone')}
        />
      </div>
    );
  };

  displayRepeatOptions = () => {
    const { repeatOption } = this.state;
    switch (repeatOption) {
      case 'none':
        return null;
      case 'interval':
        return (
          <DisplayInterval
            {...this.state}
            onIntervalChange={this.handleIntervalChange}
            onUnitChange={this.handleUnitChange}
          />
        );
      case 'intervalBetweenTimes':
        return (
          <DisplayIntervalBetweenTimes
            {...this.state}
            onIntervalChange={this.handleIntervalChange}
            onUnitChange={this.handleUnitChange}
            onTimeChange={this.handleTimeChange}
            displayDaysWeek={this.displayDaysWeek}
            displayTimezoneOptions={this.displayTimezoneOptions}
          />
        );
      case 'specificTime':
        return (
          <DisplaySpecificTime
            {...this.state}
            onTimeChange={this.handleTimeChange}
            displayDaysWeek={this.displayDaysWeek}
            displayTimezoneOptions={this.displayTimezoneOptions}
          />
        );
      case 'factoryShifts':
        return (
          <DisplayFactoryShifts
            {...this.state}
            onShiftSelect={this.handleShiftSelect}
            onOptionChange={this.handleOptionChange}
          />
        );
      case 'machineShifts':
        return (
          <DisplayMachineShifts
            {...this.state}
            onMachineChange={this.handleMachineChange}
            onShiftSelect={this.handleShiftSelect}
            onOptionChange={this.handleOptionChange}
          />
        );
      case 'allShifts':
        return (
          <DisplayAllShifts
            {...this.state}
            onOptionChange={this.handleOptionChange}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    const { repeatOption, cron, cronStrings } = this.state;
    return (
      <div>
        <div className="inputTitle">{t('repeat')}</div>
        <Select
          style={{ width: '300px' }}
          onChange={this.handleRepeatChange}
          name="repeatOption"
          value={repeatOption || options.none}
          options={Object.keys(options).map(option => (
            { label: t(option), value: option }))}
        />
        {this.displayRepeatOptions()}
        <div className="inputTitle">{t('cronString')}</div>
        <div>
          {
            repeatOption !== 'none' ? (
              <input
                type="text"
                placeholder={t('generatingCron')}
                name="cronTime"
                className="cronString"
                value={[...cronStrings].join('+')}
                readOnly
              />
            )
              : (
                <Input
                  defaultValue={cron || ''}
                  name="cronTime"
                  placeholder={t('manuallyInput')}
                />
              )
          }

        </div>
      </div>
    );
  }
}

CronTriggerForm.propTypes = {
  t: PropTypes.func.isRequired,
  machines: PropTypes.arrayOf(PropTypes.string).isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  trigger: PropTypes.shape({
    details: PropTypes.shape({
      cronTime: PropTypes.string,
      machineId: PropTypes.string,
      shiftTimeOption: PropTypes.string,
      repeatOption: PropTypes.string,
      timezone: PropTypes.string,
      amount: PropTypes.string,
      unitSelected: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      oneTime: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  shifts: state.shifts.shifts,
  machines: state.machines,
});

export default connect(mapStateToProps)(withTranslation()(CronTriggerForm));
