import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import './HoverRoundButton.scss';

/**
 * HoverRoundButton
 * @props pullLeft: Bool, set whether the button should be on the left or right side (Default: right side)
 * @props position: Int, the position of the button from the top, will provide even spacing between buttons (Default: 1)
 *
 * @class HoverRoundButton
 * @extends {Component}
 */

const FULL_OPACITY = 1;
const HIDDEN_OPACITY = 0.2;
const SPACE_BETWEEN_BTN = 15;

const HoverRoundButton = ({
  children,
  content,
  diameter,
  disabled,
  onClick,
  position,
  pullLeft,
  pullDown,
  toolTipLabel,
  alwaysFullOpacity,
  // toolTipPlace,
}) => {
  const [timeoutMount, setTimeoutMount] = useState(null);
  const [timeoutLeave, setTimeoutLeave] = useState(null);

  const [opacity, setOpacity] = useState(disabled ? HIDDEN_OPACITY : FULL_OPACITY);

  const handleMouseEnter = () => {
    if (!disabled && !alwaysFullOpacity) {
      if (timeoutMount != null) {
        clearTimeout(timeoutMount);
      }
      if (timeoutLeave != null) {
        clearTimeout(timeoutLeave);
      }
      setOpacity(FULL_OPACITY);
    }
  };

  const handleMouseLeave = () => {
    if (!disabled && !alwaysFullOpacity) {
      setTimeoutLeave(setTimeout(() => {
        setOpacity(HIDDEN_OPACITY);
      }, 1500));
    }
  };

  useEffect(() => {
    if (!disabled && !alwaysFullOpacity) {
      setTimeoutMount(setTimeout(() => {
        setOpacity(HIDDEN_OPACITY);
      }, 2000));
    }

    return () => {
      clearTimeout(timeoutMount);
      clearTimeout(timeoutLeave);
    };
  }, []);

  // first diameter is the margin to the edge
  // position starts at 1
  const yTopAxisPx = diameter + (SPACE_BETWEEN_BTN + diameter) * (position - 1);
  const yBotAxisPx = diameter * 1.5 + (SPACE_BETWEEN_BTN + diameter) * (position - 1);
  const btnStyle = {
    opacity,
  };

  btnStyle[pullLeft ? 'left' : 'right'] = '30px';
  btnStyle[pullDown ? 'bottom' : 'top'] = pullDown ? `${(yBotAxisPx).toString()}px` : `${yTopAxisPx.toString()}px`;

  if (diameter) {
    btnStyle.width = diameter;
    btnStyle.height = diameter;
  }

  const toolTipId = uuidv4();

  return (
    <div>
      <button
        type="submit"
        className="btn-hoverRound"
        style={btnStyle}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-tip={toolTipLabel}
        data-for={toolTipId}
      >
        {content || children}
      </button>
      {
        /*
        toolTipLabel && (
          <Tooltip
            id={toolTipId}
            place={toolTipPlace}
            effect="solid"
          />
        )
        */
      }
    </div>
  );
};

HoverRoundButton.propTypes = {
  children: PropTypes.element,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  diameter: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  position: PropTypes.number,
  pullLeft: PropTypes.bool,
  pullDown: PropTypes.bool,
  toolTipLabel: PropTypes.shape({}),
  // toolTipPlace: PropTypes.string.isRequired,
  alwaysFullOpacity: PropTypes.bool,
};

HoverRoundButton.defaultProps = {
  children: null,
  content: null,
  disabled: false,
  onClick: () => undefined,
  pullLeft: false,
  pullDown: false,
  position: 1,
  toolTipLabel: {},
  alwaysFullOpacity: false,
};

export default HoverRoundButton;
