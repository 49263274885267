import {
  redirectHistoryClear,
  redirectHistoryPop,
  redirectHistoryPush,
} from './actions';

export default {
  redirectHistoryClear,
  redirectHistoryPop,
  redirectHistoryPush,
};
