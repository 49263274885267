import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash.clonedeep';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { getVariablesDependencies } from '~utils';
import { roundStr } from '~utils/math';
import { evaluate } from '~utils/parser';
import TextTileContent from './TextTileContent';
import TileContents from '../TileContents';

const variableUsageRegex = '\\$\\{[A-Za-z0-9_-]+\\}';

class TextTile extends Component {
  constructor(props) {
    super(props);
    const { tile: { text, rules } } = this.props;
    let textAndRules = text || '';
    rules.forEach(rule => {
      if (rule.type === 'text') {
        textAndRules += ` ${rule.text}`;
      }
    });
    this.state = {
      displayText: text || '',
      isSocketInitialized: false,
      valuesToDisplay: getVariablesDependencies(textAndRules)
        .reduce((ac, a) => ({ ...ac, [a]: '' }), {}),
    };
  }

  componentDidMount() {
    const socket = getSocket();
    const { isSocketInitialized } = this.state;
    if (socket && !isSocketInitialized) {
      socket.on('data', this.handleSocketData);
      socket.on('value', this.handleSocketValues);
      this.fetchValues();
      this.setState({
        isSocketInitialized: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const socket = getSocket();
    const { tile: { text } } = this.props;
    const { isSocketInitialized } = this.state;
    if (socket && !isSocketInitialized) {
      socket.on('data', this.handleSocketData);
      socket.on('value', this.handleSocketValues);
      this.setState({
        isSocketInitialized: true,
      });
    }
    if (prevProps.tile && prevProps.tile.text !== text) {
      this.setState({
        displayText: text || '',
        valuesToDisplay: getVariablesDependencies(text).reduce((ac, a) => ({ ...ac, [a]: 0 }), {}),
      }, this.fetchValues);
    }
  }

  componentWillUnmount() {
    const socket = getSocket();
    if (socket) {
      socket.removeListener('data', this.handleSocketData);
      socket.removeListener('value', this.handleSocketValues);
    }
  }

  fetchValues = async () => {
    const { valuesToDisplay } = this.state;

    const valuesToDisplayClone = cloneDeep(valuesToDisplay);
    for (const valueId in valuesToDisplay) {
      if (Object.hasOwnProperty.call(valuesToDisplay, valueId)) {
        const value = await API.getValues(valueId, {}, 1).then(response => {
          if (typeof response?.values[0]?.value === 'string' || typeof response?.values[0]?.value === 'boolean') {
            return response.values[0].value;
          }
          return (response && response.values && response.values[0] && response.values[0].value !== null)
            ? roundStr(response.values[0].value, response.values[0].value % 1 !== 0 ? 1 : 0)
            : '';
        });
        valuesToDisplayClone[valueId] = value;
      }
    }
    this.setState({ valuesToDisplay: valuesToDisplayClone });
  };

  handleSocketData = socketData => {
    const { tile: { text, rules } } = this.props;
    const { valuesToDisplay } = this.state;

    const websocketData = JSON.parse(socketData);
    let displayText = text || '';
    if (rules) {
      const textRules = rules.filter(rule => rule.type === 'text');
      for (const textRule of textRules) {
        if (evaluate(textRule.condition, websocketData, textRule.variableType)) {
          displayText = textRule.text;
          break;
        }
      }
    }

    // Replace values if there are some
    const replacedText = displayText
      .replace(new RegExp(`(${variableUsageRegex})`, 'g'), p => {
        const valueUsageRegex = new RegExp(`(${variableUsageRegex})`, 'g');
        return p
          .toString()
          .replace(valueUsageRegex, valueIdWithBrackets => {
            const valueIdWithoutBrackets = valueIdWithBrackets.substr(2).slice(0, -1);
            const value = valuesToDisplay[valueIdWithoutBrackets];
            if (value === undefined || value === null) {
              return null;
            }
            return value;
          });
      });
    this.setState({ displayText: replacedText });
  };

  handleSocketValues = socketValue => {
    const { valuesToDisplay } = this.state;

    if (Object.hasOwnProperty.call(valuesToDisplay, socketValue.id)) {
      const valuesToDisplayClone = cloneDeep(valuesToDisplay);
      if (typeof socketValue.value === 'string' || typeof socketValue.value === 'boolean') {
        valuesToDisplayClone[socketValue.id] = socketValue.value;
      } else {
        valuesToDisplayClone[socketValue.id] = roundStr(socketValue.value, socketValue.value % 1 !== 0 ? 1 : 0);
      }

      this.setState({ valuesToDisplay: valuesToDisplayClone });
    }
  };

  render() {
    const { backgroundColor, height, tile, width } = this.props;
    const { displayText, valuesToDisplay } = this.state;

    const replacedText = displayText
      .replace(new RegExp(`(${variableUsageRegex})`, 'g'), p => {
        const valueUsageRegex = new RegExp(`(${variableUsageRegex})`, 'g');
        return p
          .toString()
          .replace(valueUsageRegex, valueIdWithBrackets => {
            const valueIdWithoutBrackets = valueIdWithBrackets.substr(2).slice(0, -1);
            const value = valuesToDisplay[valueIdWithoutBrackets];
            if (value === undefined || value === null) {
              return null;
            }
            return value;
          });
      });

    return (
      <TileContents
        tile={tile}
        backgroundColor={backgroundColor}
        height={height}
        width={width}
      >
        <TextTileContent
          displayText={replacedText}
          height={height}
          tile={tile}
          width={width}
        />
      </TileContents>
    );
  }
}

TextTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.shape({
    rules: PropTypes.arrayOf(PropTypes.object),
    text: PropTypes.string,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default TextTile;
