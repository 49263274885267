import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import { CancelButton, SubmitButton, CheckboxToggle } from '~UI';
import { reduxOperations } from '~services';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';

const propTypes = {
  machineId: PropTypes.string.isRequired,
  modifiedItemId: PropTypes.string,
  goBack: PropTypes.func.isRequired,
};

const defaultProps = {
  modifiedItemId: null,
};

const MachineSKUForm = ({ machineId, modifiedItemId, goBack }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);

  const machine = machines.find(elem => elem.id === machineId) || {};
  const skuNumbers = machine.skuNumbers || [];
  const sku = skuNumbers.find(elem => elem.id === modifiedItemId) || {};
  const hasSPP = sku.nbSignalsPerPartIn || sku.nbSignalsPerPartOut;

  const [hasSignalsPerPart, setHasSignalsPerPart] = useState(hasSPP);
  const defaultSKU = sku;

  const handleSubmit = e => {
    e.preventDefault();

    const formData = getFormData('SKU__form');
    const newMachine = machines.find(elem => elem.id === machineId) || {};
    const newskuNumbers = [...newMachine.skuNumbers] || [];
    const skuIndex = newskuNumbers.map(elem => elem.id).indexOf(modifiedItemId);
    const message = skuIndex >= 0 ? t('showSuccessUpdated') : t('showSuccessCreated');

    if (formData.nbSignalsPerPartIn && !formData.nbSignalsPerPartOut) {
      delete formData.nbSignalsPerPartOut;
    } else if (!formData.nbSignalsPerPartIn && formData.nbSignalsPerPartOut) {
      delete formData.nbSignalsPerPartIn;
    }

    if (skuIndex >= 0) {
      newskuNumbers[skuIndex] = {
        id: modifiedItemId,
        ...formData,
      };
    } else {
      newskuNumbers.push({
        ...formData,
      });
    }

    dispatch(reduxOperations.machines.patchMachine(machineId, { skuNumbers: newskuNumbers })).then(() => {
      showSuccess(message);
      goBack();
    })
      .catch(error => showError(error.status));
  };

  return (
    <form
      id="SKU__form"
      onSubmit={handleSubmit}
    >
      <div className="inputTitle">{t('name')}</div>
      <input
        name="name"
        type="text"
        defaultValue={defaultSKU.name}
        autoFocus
        className="fullwidth"
      />
      <div className="inputTitle">{t('description')}</div>
      <input
        name="description"
        type="text"
        defaultValue={defaultSKU.description}
        className="fullwidth"
      />
      <div className="inputTitle">{`${t('unitCompletionTime')} (ms)`}</div>
      <input
        name="number:unitCompletionTime"
        type="number"
        defaultValue={defaultSKU.unitCompletionTime}
        className="fullwidth"
      />
      <div className="inputTitle">
        {t('signalsPerPart')}
          &nbsp;
        <CheckboxToggle
          controlledCheck={hasSignalsPerPart}
          onChange={() => setHasSignalsPerPart(prevHasSignalsPerPart => !prevHasSignalsPerPart)}
        />
        <Icon
          icon="info-circle"
          style={{ fontSize: '17px' }}
          tooltipLabel={t('signalsPerPartInfo')}
        />
      </div>
      {
        hasSignalsPerPart && (
          <div style={{ marginLeft: '18px' }}>
            <div className="inOutTitle" style={{ width: language === 'fr' ? '65px' : '40px' }}>{`${t('IN')}: `}</div>
            <input
              name="number:nbSignalsPerPartIn"
              type="number"
              min={0}
              step="any"
              defaultValue={defaultSKU.nbSignalsPerPartIn}
            />
            <br />
            <div className="inOutTitle" style={{ width: language === 'fr' ? '65px' : '40px' }}>{`${t('OUT')}: `}</div>
            <input
              name="number:nbSignalsPerPartOut"
              type="number"
              min={0}
              step="any"
              defaultValue={defaultSKU.nbSignalsPerPartOut}
            />
          </div>
        )
      }
      <div className="inputTitle">{t('scheduling')}</div>
      <input
        name="number:order"
        type="number"
        min={1}
        step={1}
        className="fullwidth"
        defaultValue={defaultSKU.order}
      />
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <CancelButton onClick={goBack} />
        <SubmitButton label={t('modify')} />
      </div>
    </form>
  );
};

MachineSKUForm.propTypes = propTypes;
MachineSKUForm.defaultProps = defaultProps;

export default MachineSKUForm;
