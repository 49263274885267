import { sortArray } from '~utils/sort';

function getVariablesToGroupedOptions(variables) {
  const sortedVariables = sortArray('alphabetically', variables, 'variable');
  return ({
    label: 'Variables',
    options: sortedVariables.map(variable => (
      {
        value: variable.id,
        label: variable.variable,
      }
    )),
  });
}

export { getVariablesToGroupedOptions };
