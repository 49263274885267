import { ActionAction, ActionActionTypes } from './actions';
import { Action } from './types';

export type ActionReducerState = Action[] | null;

export default function reducer(state: ActionReducerState = null, action: ActionAction) {
  switch (action.type) {
    case ActionActionTypes.GET_ACTIONS_RESPONSE:
      return action.payload.actions;
    case ActionActionTypes.CREATE_ACTION_RESPONSE:
      return state?.concat(action.payload.action);
    case ActionActionTypes.UPDATE_ACTION_RESPONSE:
      return state?.map(a => ((a.id === action.payload.action.id)
        ? action.payload.action
        : a));
    case ActionActionTypes.DELETE_ACTION_RESPONSE:
      return state?.filter(a => a.id !== action.payload.actionId);
    default:
      return state;
  }
}
