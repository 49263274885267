import { Dispatch } from 'redux';
import {
  getClientMetricsRequest, getClientMetricsResponse, ClientMetricsAction,
} from './actions';
import API from '~services/endpoints';

type ClientMetricsDispatch = Dispatch<ClientMetricsAction>;

// Client Metrics
function fetchClientMetrics(filter = {}) {
  return (dispatch: ClientMetricsDispatch) => {
    dispatch(getClientMetricsRequest({ filter }));
    return API.getClientMetrics(filter)
      .then(res => dispatch(getClientMetricsResponse(res)));
  };
}

export default {
  fetchClientMetrics,
};
