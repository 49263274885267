import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { EntriesForm } from '~UI/index';
import './ActionForm.scss';

const METHODS = ['GET', 'HEAD', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'];

const CONTENT_TYPES = ['text/plain', 'application/json', 'text/html'];

const propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      method: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      headers: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })),
      query: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      })),
      contentType: PropTypes.string,
      body: PropTypes.string,
    }),
  }),
};

const defaultProps = {
  action: undefined,
};

const APICallActionForm = ({ action }) => {
  const { t } = useTranslation();

  const [headers, setHeaders] = useState([...(action?.params?.headers || []), { key: '', value: '' }]);
  const [query, setQuery] = useState([...(action?.params?.query || []), { key: '', value: '' }]);
  const [contentType, setContentType] = useState(action?.params?.contentType || '');
  const [body, setBody] = useState(action?.params?.body || '');
  const [method, setMethod] = useState(action?.params?.method || 'GET');
  const [url, setUrl] = useState(action?.params?.url || '');
  return (
    <>
      <div className="inputTitle">{t('method')}</div>
      <Select
        value={method}
        name="method"
        options={METHODS.map(m => ({ value: m, label: m }))}
        onChange={setMethod}
      />
      <div className="inputTitle">{t('url')}</div>
      <Input value={url} name="url" placeholder={t('url')} onChange={setUrl} />
      <div className="inputTitle">{t('headers')}</div>
      <EntriesForm
        params={headers}
        onChange={setHeaders}
      />
      <div className="inputTitle">{t('queryParams')}</div>
      <EntriesForm
        params={query}
        onChange={setQuery}
      />
      <div className="inputTitle">{t('contentType')}</div>
      <Select
        value={contentType}
        name="contentType"
        options={CONTENT_TYPES.map(ct => ({ value: ct, label: ct }))}
        onChange={setContentType}
      />
      <input type="hidden" name="contentType" value={contentType} />
      <div className="inputTitle">{t('body')}</div>
      <textarea
        value={body}
        name="body"
        placeholder={t('body')}
        onChange={e => setBody(e.target.value)}
      />
      {headers && (
        <input type="hidden" name="headers" value={JSON.stringify(headers.filter(h => h.key !== ''))} />
      )}
      {query && (
        <input type="hidden" name="query" value={JSON.stringify(query.filter(q => q.key !== ''))} />
      )}
    </>
  );
};

APICallActionForm.propTypes = propTypes;
APICallActionForm.defaultProps = defaultProps;

export default APICallActionForm;
