import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Row, Col } from 'react-bootstrap';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import ShiftManager from './ShiftManager';
import '../TriggerForms.scss';

const timeOptions = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00',
  '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
  '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

export const DisplayInterval = ({
  units, intervalAmount, intervalUnit, cron, onIntervalChange, onUnitChange,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <span className="text-every">{t('every')}</span>
      <input
        className="numberInput"
        type="number"
        name="amount"
        min="1"
        max={intervalUnit === 'Hours' ? '23' : '59'}
        onChange={onIntervalChange}
        value={cron?.length > 0 ? intervalAmount : ''}
      />
      {' '}
      <Select
        style={{ width: '150px', display: 'inline-block' }}
        name="unitSelected"
        onChange={onUnitChange}
        value={intervalUnit}
        options={[
          { label: units[0].label, value: units[0].value },
          { label: units[1].label, value: units[1].value },
          { label: units[2].label, value: units[2].value },
        ]}
      />
    </div>
  );
};

export const DisplayIntervalBetweenTimes = ({
  units, intervalAmount, intervalUnit, cron, startTimeStr, endTimeStr,
  onIntervalChange, onUnitChange, onTimeChange, displayDaysWeek, displayTimezoneOptions,
}) => {
  const { t } = useTranslation();
  return (
    <div className="cronForm">
      <Grid className="timeGrid">
        <Row>
          <Col className="colText" xs={12} md={2}>{t('every')}</Col>
          <Col xs={12} md={2}>
            <input
              className="numberInput"
              type="number"
              name="amount"
              min="1"
              max={intervalUnit === 'Hours' ? '23' : '59'}
              onChange={onIntervalChange}
              value={cron?.charAt(0) === '' ? null : intervalAmount}
            />
          </Col>
          <Col xs={12} md={2}>
            <Select
              style={{ width: '150px', display: 'inline-block' }}
              name="unitSelected"
              onChange={onUnitChange}
              value={intervalUnit}
              options={[
                { label: units[0].label, value: units[0].value },
                { label: units[1].label, value: units[1].value },
                { label: units[2].label, value: units[2].value },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="colText" xs={12} md={2}>{t('between')}</Col>
          <Col xs={12} md={2}>
            <Select
              style={{ marginRight: '20px', display: 'inline-block' }}
              name="startTime"
              onChange={e => onTimeChange('startTime', e)}
              value={startTimeStr || '00:00'}
              options={timeOptions.map(option => (
                { label: option, value: option }))}
            />
          </Col>
          <Col className="colText and" xs={12} md={1}>{t('and')}</Col>
          <Col xs={12} md={2}>
            <Select
              style={{ marginLeft: '-10px', display: 'inline-block' }}
              name="endTime"
              onChange={e => onTimeChange('endTime', e)}
              value={endTimeStr || '00:00'}
              options={timeOptions.map(option => (
                { label: option, value: option }))}
            />
          </Col>
        </Row>
      </Grid>
      {displayDaysWeek()}
      {displayTimezoneOptions()}
    </div>
  );
};

export const DisplaySpecificTime = ({
  specificTime, onTimeChange, displayDaysWeek, displayTimezoneOptions,
}) => {
  const { t } = useTranslation();
  return (
    <div className="specificTime">
      <Grid className="specificTimeGrid">
        <Row>
          <Col className="colText" xs={12} md={2}>{t('at')}</Col>
          <Col xs={12} md={2}>
            <input type="time" name="oneTime" onChange={e => onTimeChange('oneTime', e.target.value)} defaultValue={specificTime} placeholder="--:--" />
          </Col>
        </Row>
      </Grid>
      {displayDaysWeek()}
      {displayTimezoneOptions()}
    </div>
  );
};

export const DisplayFactoryShifts = ({
  selectedShifts, shiftTimeOption, factoryShifts, onShiftSelect, onOptionChange, timezone,
}) => (
  <div className="factoryShifts">
    <ShiftManager
      shiftTimeOption={shiftTimeOption}
      handleOptionChange={onOptionChange}
      shifts={factoryShifts}
      selectedShifts={selectedShifts}
      handleShiftSelect={onShiftSelect}
      timezone={timezone}
    />
  </div>
);

export const DisplayMachineShifts = ({
  selectedShifts, machineOptions, machineId, machine,
  machineShifts, shiftTimeOption, onMachineChange, onShiftSelect, onOptionChange, timezone,
}) => {
  const { t } = useTranslation();
  return (
    <div className="machineShifts">
      <div className="inputTitle">{t('selectMachine')}</div>
      <Select
        style={{ width: '300px' }}
        onChange={onMachineChange}
        name="machineId"
        value={machineId}
        options={machineOptions}
      />
      {machine && (
        <ShiftManager
          shiftTimeOption={shiftTimeOption}
          handleOptionChange={onOptionChange}
          shifts={machineShifts}
          selectedShifts={selectedShifts}
          handleShiftSelect={onShiftSelect}
          timezone={timezone}
        />
      )}
    </div>
  );
};

export const DisplayAllShifts = ({ shiftTimeOption, onOptionChange, repeatOption }) => (
  <ShiftManager
    shiftTimeOption={shiftTimeOption}
    handleOptionChange={onOptionChange}
    repeatOption={repeatOption}
  />
);

DisplayInterval.propTypes = {
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
  intervalAmount: PropTypes.number.isRequired,
  intervalUnit: PropTypes.string.isRequired,
  cron: PropTypes.string.isRequired,
  onIntervalChange: PropTypes.func.isRequired,
  onUnitChange: PropTypes.func.isRequired,
};

DisplayIntervalBetweenTimes.propTypes = {
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
  intervalAmount: PropTypes.number.isRequired,
  intervalUnit: PropTypes.string.isRequired,
  cron: PropTypes.string.isRequired,
  startTimeStr: PropTypes.string.isRequired,
  endTimeStr: PropTypes.string.isRequired,
  onIntervalChange: PropTypes.func.isRequired,
  onUnitChange: PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  displayDaysWeek: PropTypes.func.isRequired,
  displayTimezoneOptions: PropTypes.func.isRequired,
};

DisplaySpecificTime.propTypes = {
  specificTime: PropTypes.string.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  displayDaysWeek: PropTypes.func.isRequired,
  displayTimezoneOptions: PropTypes.func.isRequired,
};

DisplayFactoryShifts.propTypes = {
  selectedShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  shiftTimeOption: PropTypes.string.isRequired,
  factoryShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

DisplayMachineShifts.propTypes = {
  selectedShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  machineOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  machineId: PropTypes.string.isRequired,
  machine: PropTypes.object.isRequired,
  machineShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  shiftTimeOption: PropTypes.string.isRequired,
  onMachineChange: PropTypes.func.isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

DisplayAllShifts.propTypes = {
  shiftTimeOption: PropTypes.string.isRequired,
  onOptionChange: PropTypes.func.isRequired,
  repeatOption: PropTypes.string.isRequired,
};
