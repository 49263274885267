import React from 'react';
import PropTypes from 'prop-types';

import { FormScorecard } from '~components/forms';

const FormTileScorecard = ({ selectedTile = {} }) => (
  <FormScorecard
    selectedObject={selectedTile}
  />
);

FormTileScorecard.propTypes = {
  selectedTile: PropTypes.object.isRequired,
};

export default FormTileScorecard;
