import { Dispatch } from 'redux';
import {
  getLogsRequest, getLogsResponse, LogAction,
} from './actions';
import API from '~services/endpoints';

type LogDispatch = Dispatch<LogAction>;

// Logs
function fetchLogs(filter, sort, limit, select = undefined) {
  return (dispatch: LogDispatch) => {
    dispatch(getLogsRequest({ filter, sort, limit, select }));
    return API.getLogs(filter, sort, limit, select)
      .then(res => dispatch(getLogsResponse(res)));
  };
}

export default {
  fetchLogs,
};
