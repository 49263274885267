export default timePeriod => {
  let lapse = 3600000;
  const oneHour = 3600000;
  const dataTimeRange = timePeriod.end - timePeriod.start;

  // If less than 2 hours, split every 10 min
  if (dataTimeRange <= oneHour * 2) {
    lapse = oneHour / 6;
  } else if (dataTimeRange <= oneHour * 11.5) {
    lapse = oneHour;
  } else if (dataTimeRange <= oneHour * 24) {
    lapse = oneHour * 2;
  } else if (dataTimeRange <= oneHour * 24 * 4) {
    lapse = oneHour * 8;
  } else {
    lapse = oneHour * 24;
  }

  return lapse;
};
