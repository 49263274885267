import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Gauge } from '~UI/svg';
import { invertColor } from '~utils';
import TileContents from '../TileContents';

const GaugeTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const container = useRef();
  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      ref={container}
      height={height}
      width={width}
    >
      <div className="framed flex V center">
        <Gauge
          selectedObject={tile}
          backgroundColor={backgroundColor}
          textColor={invertColor(backgroundColor)}
          dimension={{ width, height }}
        />
      </div>
    </TileContents>
  );
};

GaugeTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  tile: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
};

export default GaugeTile;
