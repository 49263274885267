import React from 'react';
import PropTypes from 'prop-types';
import TileContents from '../TileContents';

const EmptyTile = ({
  backgroundColor,
  height,
  width,
}) => (
  <TileContents
    backgroundColor={backgroundColor}
    height={height}
    width={width}
  />
);

EmptyTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default EmptyTile;
