import types from './types';

const getUserRequest = () => ({
  type: types.GET_USER_REQUEST,
});

const getUserResponse = data => ({
  type: types.GET_USER_RESPONSE,
  payload: data,
});

const getUsersRequest = () => ({
  type: types.GET_USERS_REQUEST,
});

const getUsersResponse = data => ({
  type: types.GET_USERS_RESPONSE,
  payload: data,
});

const addUserRequest = user => ({
  type: types.ADD_USER_REQUEST,
  payload: user,
});

const addUserResponse = data => ({
  type: types.ADD_USER_RESPONSE,
  payload: data,
});

const updateUserRequest = (userId, userData) => ({
  type: types.UPDATE_USER_REQUEST,
  payload: { userId, userData },
});

const updateUserResponse = data => ({
  type: types.UPDATE_USER_RESPONSE,
  payload: data,
});

const updateVerifyUserRequest = (userId, userData) => ({
  type: types.UPDATE_VERIFY_USER_REQUEST,
  payload: { userId, userData },
});

const updateVerifyUserResponse = data => ({
  type: types.UPDATE_VERIFY_USER_RESPONSE,
  payload: data,
});

const deleteUserRequest = userId => ({
  type: types.DELETE_USER_REQUEST,
  payload: userId,
});

const deleteUserResponse = data => ({
  type: types.DELETE_USER_RESPONSE,
  payload: data,
});

const getAllUsersPermissionsRequest = () => ({
  type: types.GET_ALL_USERS_PERMISSIONS_REQUEST,
});

const getAllUsersPermissionsResponse = data => ({
  type: types.GET_ALL_USERS_PERMISSIONS_RESPONSE,
  payload: data,
});

const getUserPermissionsRequest = userId => ({
  type: types.GET_USER_PERMISSIONS_REQUEST,
  payload: userId,
});

const getUserPermissionsResponse = data => ({
  type: types.GET_USER_PERMISSIONS_RESPONSE,
  payload: data,
});

const updateUsersContentsPermissionsRequest = data => ({
  type: types.UPDATE_USERS_CONTENTS_PERMISSIONS_REQUEST,
  payload: data,
});

const updateUsersContentsPermissionsResponse = data => ({
  type: types.UPDATE_USERS_CONTENTS_PERMISSIONS_RESPONSE,
  payload: data,
});

const sendWelcomeEmailRequest = userId => ({
  type: types.SEND_WELCOME_EMAIL_REQUEST,
  payload: userId,
});

const sendWelcomeEmailResponse = data => ({
  type: types.SEND_WELCOME_EMAIL_RESPONSE,
  payload: data,
});

export {
  getUserRequest,
  getUserResponse,
  getUsersRequest,
  getUsersResponse,
  addUserRequest,
  addUserResponse,
  updateUserRequest,
  updateUserResponse,
  deleteUserRequest,
  deleteUserResponse,
  getAllUsersPermissionsRequest,
  getAllUsersPermissionsResponse,
  getUserPermissionsRequest,
  getUserPermissionsResponse,
  updateVerifyUserRequest,
  updateVerifyUserResponse,
  updateUsersContentsPermissionsRequest,
  updateUsersContentsPermissionsResponse,
  sendWelcomeEmailRequest,
  sendWelcomeEmailResponse,
};
