import types from './types';

const getStreamsRequest = () => ({
  type: types.GET_STREAMS_REQUEST,
});

const getStreamsResponse = res => ({
  type: types.GET_STREAMS_RESPONSE,
  payload: res,
});

const addStreamRequest = data => ({
  type: types.ADD_STREAM_REQUEST,
  payload: data,
});

const addStreamResponse = (data, request = null) => ({
  type: types.ADD_STREAM_RESPONSE,
  payload: data,
  request,
});

const updateStreamRequest = data => ({
  type: types.UPDATE_STREAM_REQUEST,
  payload: data,
});

const updateStreamResponse = (data, request = null) => ({
  type: types.UPDATE_STREAM_RESPONSE,
  payload: data,
  request,
});

const deleteStreamRequest = data => ({
  type: types.DELETE_STREAM_REQUEST,
  payload: data,
});

const deleteStreamResponse = (data, request = null) => ({
  type: types.DELETE_STREAM_RESPONSE,
  payload: data,
  request,
});

const updateInputsRequest = formData => ({
  type: types.UPDATE_INPUTS_REQUEST,
  payload: formData,
});

const updateInputsResponse = data => ({
  type: types.UPDATE_INPUTS_RESPONSE,
  payload: data,
});

// Stream Properties
const getStreamPropertiesRequest = data => ({
  type: types.GET_STREAM_PROPERTIES_REQUEST,
  payload: data,
});

const getStreamPropertiesResponse = res => ({
  type: types.GET_STREAM_PROPERTIES_RESPONSE,
  payload: res,
});

const addStreamPropertyRequest = data => ({
  type: types.ADD_STREAM_PROPERTY_REQUEST,
  payload: data,
});

const addStreamPropertyResponse = (data, request = null) => ({
  type: types.ADD_STREAM_PROPERTY_RESPONSE,
  payload: data,
  request,
});

const updateStreamPropertyRequest = data => ({
  type: types.UPDATE_STREAM_PROPERTY_REQUEST,
  payload: data,
});

const updateStreamPropertyResponse = (data, request = null) => ({
  type: types.UPDATE_STREAM_PROPERTY_RESPONSE,
  payload: data,
  request,
});

const deleteStreamPropertyRequest = data => ({
  type: types.DELETE_STREAM_PROPERTY_REQUEST,
  payload: data,
});

const deleteStreamPropertyResponse = (data, request = null) => ({
  type: types.DELETE_STREAM_PROPERTY_RESPONSE,
  payload: data,
  request,
});

export {
  getStreamsRequest,
  getStreamsResponse,
  addStreamRequest,
  addStreamResponse,
  updateStreamRequest,
  updateStreamResponse,
  deleteStreamRequest,
  deleteStreamResponse,
  updateInputsRequest,
  updateInputsResponse,
  getStreamPropertiesRequest,
  getStreamPropertiesResponse,
  addStreamPropertyRequest,
  addStreamPropertyResponse,
  updateStreamPropertyRequest,
  updateStreamPropertyResponse,
  deleteStreamPropertyRequest,
  deleteStreamPropertyResponse,
};
