
import React from 'react';
import PropTypes from 'prop-types';
import HoverRoundButton from './HoverRoundButtonContainer';
import { FontAwesome } from '~UI';

const CreationHoverRoundButton = ({
  Modal = null,
  onClick = null,
  ...props
}) => (
  <HoverRoundButton
    {...props}
    Modal={Modal}
    onClick={onClick}
    diameter={70}
    alwaysFullOpacity
  >
    <FontAwesome icon="plus" size="lg" />
  </HoverRoundButton>
);

CreationHoverRoundButton.propTypes = {
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

const GoTopHoverRoundButton = ({ pullDown = true, ...props }) => (
  <a href="#topOfPage">
    <HoverRoundButton
      {...props}
      diameter={40}
      pullDown={pullDown}
    >
      <FontAwesome
        icon="arrow-up"
        style={{
          position: 'absolute',
          top: '25%',
          left: '29%',
        }}
      />
    </HoverRoundButton>
  </a>
);

GoTopHoverRoundButton.propTypes = {
  pullDown: PropTypes.bool,
};

GoTopHoverRoundButton.defaultProps = {
  pullDown: true,
};

const NPSPopupHoverRoundButton = ({
  pullLeft = false,
  pullDown = true,
  Modal = null,
  onClick = null,
  ...props
}) => (
  <HoverRoundButton
    {...props}
    pullLeft={pullLeft}
    pullDown={pullDown}
    Modal={Modal}
    onClick={onClick}
    diameter={40}
  >
    <FontAwesome
      icon="smile-o"
      style={{
        position: 'absolute',
        top: '25%',
        left: '29%',
      }}
    />
  </HoverRoundButton>
);

const ChatPopupHoverRoundButton = ({
  pullLeft = false,
  pullDown = true,
  Modal = null,
  onClick = null,
  ...props
}) => (
  <HoverRoundButton
    {...props}
    pullLeft={pullLeft}
    pullDown={pullDown}
    Modal={Modal}
    onClick={onClick}
    diameter={40}
  >
    <FontAwesome
      icon="comments"
      style={{
        position: 'absolute',
        top: '25%',
        left: '29%',
      }}
    />
  </HoverRoundButton>
);

NPSPopupHoverRoundButton.propTypes = {
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  pullLeft: PropTypes.bool.isRequired,
  pullDown: PropTypes.bool.isRequired,
};

ChatPopupHoverRoundButton.propTypes = {
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  pullLeft: PropTypes.bool.isRequired,
  pullDown: PropTypes.bool.isRequired,
};

const JSONButton = ({
  onClick = null,
  ...props
}) => (
  <HoverRoundButton
    {...props}
    onClick={onClick}
    diameter={70}
    alwaysFullOpacity
  >
    <FontAwesome
      icon="code"
      size="lg"
      style={{
        position: 'absolute',
        top: '35%',
        left: '29%',
        fontWeight: 'bold',
      }}
    />
  </HoverRoundButton>
);

JSONButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export {
  CreationHoverRoundButton,
  GoTopHoverRoundButton,
  JSONButton,
  NPSPopupHoverRoundButton,
  ChatPopupHoverRoundButton,
};
