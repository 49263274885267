import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { roundStr } from '~utils/math';
import Tick from '~utils/Tick';
import { serverTime, getDataRangeStartEnd, getShiftFromTimestamp } from '~utils/time';
import ResponsiveScorecard from './ResponsiveScorecard';
import { getAggregatedValue, getComparisonMetricObject } from './utils';
import { useShift } from '~utils/hooks';

const ScorecardVariable = ({
  selectedObject, backgroundColor, isCircle, dimension, loaderPos,
}) => {
  const selectedObjectRef = useRef(selectedObject);
  const socket = getSocket();
  const { t } = useTranslation();

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  }, [selectedObject]);

  const variables = useSelector(state => state.variables);
  const shifts = useSelector(state => state.shifts.shifts);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);
  const [currentShift] = useShift(null);

  const getValue = variableId => {
    const properties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const all = [...properties, ...variables, ...kpis];
    return all.find(elem => elem.id === variableId);
  };

  const variable = getValue(selectedObject.valueId);

  const [hasUnmounted, setHasUnmounted] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [displayText, setDisplayText] = useState('');
  const [values, setValues] = useState([]);
  const [comparisonValues, setComparisonValues] = useState([]);
  const [currentDisplayedValue, setCurrentDisplayedValue] = useState(null);
  const [comparisonMetricObject, setComparisonMetricObject] = useState(null);

  const hasAggregate = variable ? variable.isEnabled && selectedObject.isAggregated : false;

  const getAdjustedValues = valuesArg => {
    const { start } = getDataRangeStartEnd(selectedObject.intervalType, currentShift);
    return valuesArg.map(val => {
      if (val.timestamp < start || !val.timestamp) {
        return { ...val, timestamp: start };
      }
      return val;
    });
  };

  const getCurrentShift = () => {
    const now = new Date();

    const currentShft = shifts.find(shift => {
      const shiftStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), shift.hourStart, shift.minuteStart);
      const shiftEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), shift.hourEnd, shift.minuteEnd);

      if (shiftEnd <= shiftStart) {
        shiftEnd.setDate(shiftEnd.getDate() + 1);
      }

      return now >= shiftStart && now < shiftEnd;
    });

    return currentShft;
  };

  const getPreviousShift = currentShft => {
    const sortedShifts = [...shifts].sort((a, b) => {
      const dayDiff = a.days[0] - b.days[0];
      if (dayDiff !== 0) return dayDiff;
      const hourDiff = a.hourStart - b.hourStart;
      if (hourDiff !== 0) return hourDiff;
      return a.minuteStart - b.minuteStart;
    });

    const currentShiftIndex = sortedShifts.findIndex(shift => shift.id === currentShft.id);

    const previousShiftIndex = (currentShiftIndex - 1 + sortedShifts.length) % sortedShifts.length;

    return sortedShifts[previousShiftIndex];
  };

  function getTimestampInShift(shift) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    let startTime = new Date(year, month, day, shift.hourStart, shift.minuteStart).getTime();
    const endTime = new Date(year, month, day, shift.hourEnd, shift.minuteEnd).getTime();

    if (endTime <= startTime) {
      startTime -= 24 * 60 * 60 * 1000;
    }

    const previousShift = getShiftFromTimestamp(shifts, startTime);

    return previousShift;
  }

  const updateTheRender = () => {
    const adjustedValues = getAdjustedValues(values);
    const newCurrentDisplayedValue = hasAggregate
      ? getAggregatedValue(adjustedValues, selectedObject.aggregateType, selectedObject.decimals)
      : displayText;
    const adjustedComparisonValues = getAdjustedValues(comparisonValues);
    const newComparisonMetricObject = selectedObject?.comparisonMetric !== 'none'
      ? getComparisonMetricObject(
        newCurrentDisplayedValue,
        adjustedComparisonValues,
        selectedObject.comparisonAggregateType,
        selectedObject.comparisonMetric,
        selectedObject.decimals,
      )
      : null;

    setComparisonMetricObject(newComparisonMetricObject);
    setCurrentDisplayedValue(newCurrentDisplayedValue);
  };

  const filterValues = () => {
    let outOfBoundValues = [...values];

    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
    const timeDifference = selectedObject.isAggregated ? end - start : 0;
    if (selectedObject.isAggregated) {
      outOfBoundValues = values.filter(value => value.timestamp < start || value.timestamp > end);
    }

    const comparisonIntervalType = selectedObject.comparisonIntervalType || 'shift';
    const newComparisonValues = selectedObject.comparisonMetric !== 'none' ? [...comparisonValues, ...outOfBoundValues] : [];
    const currentShft = getCurrentShift();
    const previousShift = currentShft ? getPreviousShift(currentShft) : null;
    const previousShiftStartEnd = previousShift ? getTimestampInShift(previousShift) : null;
    const { start: comparisonStart, end: comparisonEnd } = getDataRangeStartEnd(
      comparisonIntervalType, previousShiftStartEnd);

    setValues(prevValues => {
      const filteredValues = prevValues.filter((value, index) => {
        if (!selectedObject.isAggregated) { return true; }
        if (index + 1 === prevValues.length) { return true; }
        if (prevValues[index + 1].timestamp >= start
            && value.timestamp <= end) {
          return true;
        }
        return value.timestamp >= start && value.timestamp <= end;
      });

      return filteredValues;
    });

    if (newComparisonValues.length > 0) {
      setComparisonValues(newComparisonValues.filter((value, index) => {
        if (selectedObject.comparisonMetric === 'none') { return true; }
        if (index + 1 === newComparisonValues.length) { return true; }
        if (comparisonIntervalType === 'shift') {
          return value.timestamp >= comparisonStart && value.timestamp <= comparisonEnd;
        }
        if (newComparisonValues[index + 1].timestamp >= comparisonStart - timeDifference
              && value.timestamp <= comparisonEnd - timeDifference) {
          return true;
        }

        return false;
      }));
    }
  };

  const handleSocketValue = socketValue => {
    if (socketValue.id === selectedObjectRef.current.valueId) {
      if (selectedObjectRef.current.isAggregated) {
        setValues(prevValues => [...prevValues, { ...socketValue, timestamp: serverTime() }]);
      } else {
        if (typeof socketValue.value === 'string' || typeof socketValue.value === 'boolean') {
          setDisplayText(socketValue.value);
        } else {
          setDisplayText(roundStr(socketValue.value, selectedObjectRef.current.decimals));
        }

        if (selectedObjectRef.current.comparisonMetric !== 'none') {
          const newComparisonValue = { ...socketValue, timestamp: serverTime() };
          setComparisonValues(prevValues => [...prevValues, newComparisonValue]);
        }
      }
      filterValues();
    }
  };

  const fetchValues = async (start, end, setState) => {
    const { values: fetchedValues } = await API
      .getValues(selectedObject.valueId, { timestamp: { $gte: start, $lt: end } });
    const firstValues = await API.getValues(selectedObject.valueId, { timestamp: { $lt: start } }, 2);
    const firstValue = firstValues.values[0];

    if (setState) {
      if (fetchedValues) {
        const newValues = firstValue ? [firstValue, ...fetchedValues] : fetchedValues;
        setValues(newValues.sort((v1, v2) => v1.timestamp - v2.timestamp));
      } else {
        setValues([]);
      }
      setHasLoaded(true);
      return;
    }
    return { values: fetchedValues, firstValue };
  };

  const fetchComparatorValues = async () => {
    if (selectedObject.comparisonMetric !== 'none') {
      const intervalType = selectedObject.intervalType || 'shift';
      const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
      const timeDifference = selectedObject.isAggregated ? end - start : 0;

      const comparisonIntervalType = selectedObject.comparisonIntervalType || 'shift';
      const currentShft = getCurrentShift();
      const previousShift = currentShft ? getPreviousShift(currentShft) : null;
      const previousShiftStartEnd = previousShift ? getTimestampInShift(previousShift) : null;
      const { start: comparisonStart, end: comparisonEnd } = getDataRangeStartEnd(
        comparisonIntervalType, previousShiftStartEnd);
      const adjustedStart = comparisonIntervalType === 'shift' ? comparisonStart : comparisonStart - timeDifference;
      const adjustedEnd = comparisonIntervalType === 'shift' ? comparisonEnd : comparisonEnd - timeDifference;
      const { firstValue, values: fetchedValues } = await fetchValues(
        adjustedStart,
        adjustedEnd,
        false,
      );

      if (fetchedValues) {
        const newValues = firstValue ? [firstValue, ...fetchedValues] : fetchedValues;
        const newComparisonValues = newValues.sort((v1, v2) => v1.timestamp - v2.timestamp);
        setComparisonValues(newComparisonValues);
      } else {
        setComparisonValues([]);
      }
    }
  };

  useEffect(() => {
    if (!getValue(selectedObject.valueId)) {
      setHasLoaded(true);
      return;
    }

    if (selectedObject.isAggregated) {
      const intervalTypeDefault = selectedObject.intervalType || 'shift';
      const { start, end } = getDataRangeStartEnd(intervalTypeDefault, currentShift);
      fetchValues(start, end, true);
    } else {
      API.getValues(selectedObject.valueId, {}, 1).then(response => {
        if (!hasUnmounted) {
          if (typeof response?.values[0]?.value === 'string' || typeof response?.values[0]?.value === 'boolean') {
            setDisplayText(response.values[0].value);
          } else {
            const roundedValue = (response?.values[0]?.value !== null && response?.values[0]?.value !== undefined)
              ? roundStr(response.values[0].value, selectedObject.decimals)
              : '';
            setDisplayText(roundedValue);
          }
          setHasLoaded(true);
        }
      });
    }

    if (selectedObject.comparisonMetric !== 'none') {
      fetchComparatorValues();
    }
  }, [selectedObject, currentShift]);

  useEffect(() => {
    const newVariable = getValue(selectedObject.valueId);
    if (!newVariable) {
      return;
    }

    if ((newVariable?.isEnabled && selectedObject.isAggregated) || (newVariable?.isEnabled && selectedObject.comparisonMetric !== 'none')) {
      Tick.subscribe(filterValues, 5);
    }

    return () => {
      Tick.unsubscribe(filterValues);
    };
  }, [selectedObject, currentShift]);

  useEffect(() => {
    socket?.on('value', handleSocketValue);

    return () => {
      socket?.removeListener('value', handleSocketValue);
      setHasUnmounted(true);
    };
  }, [socket, currentShift]);

  useEffect(() => {
    updateTheRender();
  }, [values, comparisonValues, displayText]);

  const value = getValue(selectedObject.valueId);
  const valueDisplayed = value ? currentDisplayedValue : t('variableDeleted');

  return (
    <ResponsiveScorecard
      selectedObject={selectedObject}
      value={valueDisplayed}
      units={selectedObject.units || (value && value.units)}
      comparisonMetric={comparisonMetricObject}
      backgroundColor={backgroundColor}
      hasLoaded={hasLoaded}
      isCircle={isCircle}
      {...dimension}
      loaderPos={loaderPos}
    />
  );
};

ScorecardVariable.propTypes = {
  selectedObject: PropTypes.shape({
    decimals: PropTypes.number,
    intervalType: PropTypes.string,
    isAggregated: PropTypes.bool,
    scorecardType: PropTypes.string,
    valueId: PropTypes.string,
    units: PropTypes.string,
    comparisonMetric: PropTypes.string,
    aggregateType: PropTypes.string,
    comparisonIntervalType: PropTypes.string,
    comparisonAggregateType: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  isCircle: PropTypes.bool,
  dimension: PropTypes.object,
  loaderPos: PropTypes.object,
};
ScorecardVariable.defaultProps = {
  selectedObject: {},
};

export default ScorecardVariable;
