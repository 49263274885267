import types from './types';

export const fetchRequest = fetchType => ({
  type: types.FETCH_REQUEST,
  payload: { type: fetchType },
});

export const fetchResponse = (fetchType, res = null) => ({
  type: types.FETCH_RESPONSE,
  payload: { type: fetchType, res },
});

export default {
  fetchRequest,
  fetchResponse,
};
