import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services/index';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI/index';
import { ReportSelectionGrid } from './ReportSelectionGrid';

const ReportSelectionPage = () => {
  const { t } = useTranslation();
  const reports = useSelector(state => state.reports.reports);
  const tags = useSelector(state => state.tags.tags);

  const dispatch = useDispatch();

  const getContents = () => (
    <PageTemplate
      footer
      pageTemplateClass="flex V justifyStart"
      sidebar
      title={t('reports')}
    >
      <ReportSelectionGrid />
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[reports, tags]}
      resourceFetchers={[
        () => dispatch(reduxOperations.reports.fetchReports()),
        () => dispatch(reduxOperations.tags.fetchTags()),
      ]}
      getContents={getContents}
    />
  );
};

export { ReportSelectionPage };
