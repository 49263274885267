import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NumberInput } from '@mantine/core';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { millisToHHmmss } from '~utils/time';
import API from '~services/endpoints';
import MachinePopup from './MachinePopup';
import DefectEventPopup from './DefectEventPopup';

import './MachinePopup.scss';

const PartEventPopup = ({
  show, onHide, machineId: machineIdProp, modifiedItemId, eventsList, event: eventProp, onSubmitTrigger, params,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const machines = useSelector((state: RootState) => state.machines);

  const initEvent = eventsList.find(ev => ev.id === modifiedItemId);

  const [machineId, setMachineId] = useState(initEvent ? initEvent.machineId : machineIdProp);
  const [event, setEvent] = useState(initEvent || eventProp);
  const [count, setCount] = useState(event.count);

  const getEventDetails = () => {
    if (!event) {
      return null;
    }
    const start = millisToHHmmss(event.timestamp);

    return (
      <div>
        <p>
          {`${t('at')} ${start}`}
        </p>
      </div>
    );
  };

  const handleSubmit = async () => {
    const updatedData = { count };

    const id = event.id || event._id || modifiedItemId;
    API.patchEvent(id, updatedData);
    onHide();
  };

  useEffect(() => {
    setMachineId(machineIdProp);
    setEvent(eventProp);
  }, [machineIdProp, eventProp]);

  useEffect(() => {
    setCount(event.count);
  }, [event, onHide]);

  const getContents = () => {
    const machine = machines.find(m => m.id === machineId);

    if (event.eventType === 'SCRAP') {
      return (
        <DefectEventPopup
          show={show}
          onHide={onHide}
          machineId={machineIdProp}
          modifiedItemId={modifiedItemId}
          eventsList={eventsList}
          event={event}
          onSubmitTrigger={onSubmitTrigger}
          params={params}
          isEventPage
          mandatoryComment={machine.mandatoryComment}
        />
      );
    }

    return (
      <MachinePopup
        title={(machine ? `${machine.name} : ` : '') + t(event.eventType)}
        subtitle={getEventDetails()}
        show={show}
        onHide={onHide}
      >
        <div className="StopSelectionForm">
          <div style={{ textAlign: 'left' }}>
            {`${t('count')} :`}
            <NumberInput
              value={count}
              min={1}
              onChange={setCount}
            />
          </div>
          <div className="btn-group fullwidth">
            <button type="button" className="submitButton" onClick={handleSubmit}>
              {t('submit')}
            </button>
            <button type="button" className="submitButton red" onClick={onHide}>
              {t('cancel')}
            </button>
          </div>
        </div>
      </MachinePopup>
    );
  };

  return (
    <ResourcesHandler
      resources={[machines]}
      resourceFetchers={[dispatch(reduxOperations.machines.fetchMachines() as any)]}
      getContents={getContents}
    />
  );
};

PartEventPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmitTrigger: PropTypes.string,
  machineId: PropTypes.string,
  modifiedItemId: PropTypes.string,
  eventsList: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.shape({}),
  event: PropTypes.object,
};

PartEventPopup.defaultProps = {
  show: false,
  onHide: () => undefined,
  machineId: null,
  modifiedItemId: null,
  eventsList: [],
  event: {},
  params: {},
  onSubmitTrigger: '',
};

export default PartEventPopup;
