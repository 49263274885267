import { combineReducers } from 'redux';

import types from './types';

function lastPatchedEvent(state = null, action) {
  switch (action.type) {
    case types.PATCH_EVENT_RESPONSE:
      return action.payload.event;
    default:
      return state;
  }
}

export default combineReducers({
  lastPatchedEvent,
});
