import {
  getStopwatchesRequest,
  getStopwatchesResponse,
  addStopwatchRequest,
  addStopwatchResponse,
  updateStopwatchRequest,
  updateStopwatchResponse,
  deleteStopwatchRequest,
  deleteStopwatchResponse,
  stopwatchIORequest,
  stopwatchIOResponse,
  socketUpdateStopwatch,
} from './actions';
import { fetchOnceOperation } from '../fetchOperation';
import API from '~services/endpoints';

function fetchStopwatches() {
  return fetchOnceOperation(
    getStopwatchesRequest,
    getStopwatchesResponse,
    API.getStopwatches,
    state => state.stopwatches.stopwatches,
  );
}

function addStopwatch(stopwatch) {
  return dispatch => {
    dispatch(addStopwatchRequest(stopwatch));
    return API.addStopwatch(stopwatch)
      .then(res => dispatch(addStopwatchResponse(res.stopwatch)));
  };
}

function updateStopwatch(stopwatchId, stopwatch) {
  return dispatch => {
    dispatch(updateStopwatchRequest({ stopwatchId, stopwatch }));
    return API.updateStopwatch(stopwatchId, stopwatch)
      .then(res => dispatch(updateStopwatchResponse(res.stopwatch)));
  };
}

function stopwatchIO(stopwatchId, flag) {
  return dispatch => {
    dispatch(stopwatchIORequest({ stopwatchId, flag }));
    return API.stopwatchIO(stopwatchId, flag)
      .then(res => dispatch(stopwatchIOResponse({ ...res, stopwatchId })));
  };
}

function deleteStopwatch(stopwatchId) {
  return dispatch => {
    dispatch(deleteStopwatchRequest(stopwatchId));
    return API.deleteStopwatch(stopwatchId)
      .then(res => dispatch(deleteStopwatchResponse({ ...res, stopwatchId })));
  };
}

export default {
  fetchStopwatches,
  addStopwatch,
  updateStopwatch,
  deleteStopwatch,
  stopwatchIO,
  socketUpdateStopwatch,
};
