import React from 'react';
import PropTypes from 'prop-types';
import HoverRoundButton from './HoverRoundButton';
import { ModalHandler } from '~UI';

const HoverRoundButtonContainer = ({ Modal = null, onClick = null, ...props }) => {
  if (Modal === null) {
    return (<HoverRoundButton onClick={onClick} {...props} />);
  }
  return (
    <ModalHandler
      Trigger={{
        Component: HoverRoundButton,
        props,
      }}
      Modal={Modal}
    />
  );
};

HoverRoundButtonContainer.propTypes = {
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }),
  onClick: PropTypes.func,
};

HoverRoundButtonContainer.defaultProps = {
  Modal: null,
  onClick: () => undefined,
};

export default HoverRoundButtonContainer;
