import { get, post, put, del } from '../endpoints';

export const getFolders = () => get('/folders');

export const addFolder = folder => post('/folders', {}, folder);

export const updateFolder = (id, folder) => put('/folders/:id', { id }, folder);

export const deleteFolder = id => del('/folders/:id', { id });

export const addSubfolder = (id, subfolder) => post('/folders/:id/subfolders', { id }, subfolder);

export const updateSubfolder = (folderId, id, subfolder) => put('/folders/:folderId/subfolders/:id', { folderId, id }, subfolder);

export const deleteSubfolder = (folderId, id) => del('/folders/:folderId/subfolders/:id', { folderId, id });
