import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { sortArray } from '~utils/sort';
import './ActionForm.scss';

const StopwatchActionForm = ({ action }) => {
  const { t } = useTranslation();

  const stopwatches = useSelector((state: RootState) => state.stopwatches.stopwatches);

  const [selectedFlag, setSelectedFlag] = useState(action.params.type || 'Start');

  const handleFlagSelection = value => {
    setSelectedFlag(value);
  };

  const buttons = [
    { label: t('startVerb'), value: 'Start' },
    { label: t('stopVerb'), value: 'Stop' },
    { label: t('reset'), value: 'Reset' },
    { label: t('resetStart'), value: 'ResetStart' },
    { label: t('resetStop'), value: 'ResetStop' },
    { label: t('startStopToggle'), value: 'Start/Stop' },
  ];

  const stopwatchOptions = sortArray('alphabetically', stopwatches, 'name').map(s => ({ label: s.name, value: s.id }));

  return (
    <div className="stopwatchActionForm">
      <div className="inputTitle">{t('chooseStopwatch')}</div>
      <Select
        name="stopwatchId"
        defaultValue={action.params.stopwatchId}
        options={stopwatchOptions}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('chooseStopwatchAction')}</div>
      <input type="hidden" name="type" value={selectedFlag} />
      <ButtonGroup
        buttons={buttons}
        onChange={handleFlagSelection}
        value={selectedFlag}
      />
    </div>
  );
};

StopwatchActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      type: PropTypes.string,
      stopwatchId: PropTypes.string,
    }).isRequired,
  }),
};
StopwatchActionForm.defaultProps = {
  action: {
    params: {},
  },
};

export default StopwatchActionForm;
