import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './Popover.scss';

function Popover({
  children, content, show, setShow, className,
}) {
  const handleContentClick = e => {
    e.stopPropagation();
  };

  useEffect(() => {
    const handleClickOutside = e => {
      if (e.target.className !== 'body') {
        setShow(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setShow]);

  return (
    <div>
      {children}
      {show && (
        <div
          onClick={handleContentClick}
          className={className}
        >
          {content}
        </div>
      )}
    </div>
  );
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export { Popover };
