import { SET_TILEWIDGET_VALUE } from './actions';

const initialState = [];

const tileWidgetValue = (state = initialState, action) => {
  const existingIndex = state.findIndex(
    item => item?.selectedObjectId === action.payload?.selectedObjectId,
  );

  switch (action.type) {
    case SET_TILEWIDGET_VALUE:
      if (existingIndex !== -1) {
        return state.map((item, index) => (index === existingIndex
          ? { ...item, value: action.payload.value }
          : item),
        );
      }
      return [
        ...state,
        {
          selectedObjectId: action.payload.selectedObjectId,
          value: action.payload.value,
        },
      ];

    default:
      return state;
  }
};

export default tileWidgetValue;
