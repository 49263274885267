enum Types {
  GET_OPERATIONS_REQUEST = 'GET_OPERATIONS_REQUEST',
  GET_OPERATIONS_RESPONSE = 'GET_OPERATIONS_RESPONSE',
  ADD_OPERATION_REQUEST = 'ADD_OPERATION_REQUEST',
  ADD_OPERATION_RESPONSE = 'ADD_OPERATION_RESPONSE',
  UPDATE_OPERATION_REQUEST = 'UPDATE_OPERATION_REQUEST',
  UPDATE_OPERATION_RESPONSE = 'UPDATE_OPERATION_RESPONSE',
  DELETE_OPERATION_REQUEST = 'DELETE_OPERATION_REQUEST',
  DELETE_OPERATION_RESPONSE = 'DELETE_OPERATION_RESPONSE',
}

export default Types;
