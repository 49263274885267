import React from 'react';
import PropTypes from 'prop-types';

import { FormMedia } from '~components/forms';

const FormTileMedia = ({ selectedTile }) => (
  <FormMedia
    selectedObject={selectedTile}
  />
);

FormTileMedia.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileMedia.defaultProps = {
  selectedTile: {},
};

export default FormTileMedia;
