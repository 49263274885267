import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import machineStatsProperties from '~components/forms/machineProperties.json';
import API from '~services/endpoints';
import {
  CheckboxToggle, FontAwesome, TextInput,
} from '~UI';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { sortArray } from '~utils/sort';

const FormTileBarChart = ({ selectedTile }) => {
  const { t } = useTranslation();

  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);
  const machines = useSelector(state => state.machines);

  const {
    aggregateType, intervalType, machineId, showBarsValues, barChartType, showGoalToggle, goal, axisY, showArrows,
  } = selectedTile;

  const [barChartTypeState, setBarChartType] = useState(barChartType || 'events');
  const [eventNames, setEventNames] = useState([]);
  const [showGoalToggleState, setShowGoalToggle] = useState(showGoalToggle || false);
  const [goalState, setGoal] = useState(typeof goal === 'number' ? goal : null);
  const [axisYState, setAxisY] = useState(axisY || '');
  const [showArrowsState, setShowArrows] = useState(showArrows || false);

  const fetchEventNames = async () => {
    const { eventsPropertyElements } = await API.getDistinctEvents('name');
    setEventNames(eventsPropertyElements);
  };

  useEffect(() => {
    fetchEventNames();
  }, []);

  const handleShowGoalToggle = () => {
    setShowGoalToggle(prevShowGoalToggle => !prevShowGoalToggle);
  };

  const handleChangeGoal = e => {
    setGoal(e.currentTarget.value);
  };

  const handleShowArrows = () => {
    setShowArrows(prevShowArrows => !prevShowArrows);
  };

  const showContent = () => {
    const properties = [].concat(...streams.map(s => s.properties));
    const all = [...properties, ...variables];
    const allEnabled = sortArray('alphabetically', all.filter(a => a.isEnabled), 'variable');

    if (barChartTypeState === 'events') {
      return (
        <>
          <div className="inputTitle">{t('eventName')}</div>
          <TextInput
            name="axisY"
            options={eventNames}
            className="fullwidth"
            value={axisYState}
            onChange={name => setAxisY(name)}
          />
        </>
      );
    }
    if (barChartTypeState === 'machine') {
      const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

      const barChartMachineStats = machineStatsProperties
        .filter(prop => ['inputCount', 'outputCount', 'acceptableOutputCount', 'scrapCount'].includes(prop.property));

      const defaultMachineProperty = barChartMachineStats
        .filter(prop => axisYState === prop.property)[0] || barChartMachineStats[0];

      return (
        <>
          <div className="inputTitle">{t('machine')}</div>
          <Select
            name="machineId"
            options={machineOptions}
            defaultValue={machineId}
            placeholder={t('select')}
          />

          <div className="inputTitle">{t('machineKPI')}</div>
          <Select
            name="axisY"
            className="fullwidth"
            value={axisYState}
            onChange={e => setAxisY(e)}
            options={barChartMachineStats.map(property => (
              { label: t(property.name), value: property.property }))}
            placeholder={t('select')}
          />
          <div style={{ marginBottom: '8px' }}>
            <FontAwesome icon="info-circle" />
            &nbsp;&nbsp;
            {t(defaultMachineProperty.description)}
          </div>
        </>
      );
    }
    if (barChartTypeState === 'variables') {
      if (allEnabled.length > 0) {
        const valueStillExists = !axisYState || all.find(v => v.id === axisYState);
        const isStillEnabled = !axisYState || allEnabled.find(v => v.id === axisYState);
        return (
          <>
            <div className="inputTitle">{t('variable')}</div>
            {!valueStillExists && `${t('errorDeletedVariable')}.`}
            {valueStillExists && !isStillEnabled && `${t('errorHistoryDesactivated')}.`}
            <Select
              name="axisY"
              options={allEnabled.map(e => ({ label: e.variable, value: e.id }))}
              defaultValue={axisYState}
              placeholder={t('select')}
            />
          </>
        );
      }

      return (
        <div>
          {`${t('noHistoryVariables')}.`}
        </div>
      );
    }
  };

  const buttons = [
    { label: t('events'), value: 'events' },
    { label: t('variables'), value: 'variables' },
    { label: t('machine'), value: 'machine' },
  ];

  const aggregateOptions = [
    { label: t('sum'), value: 'sum' },
    { label: t('minimum'), value: 'min' },
    { label: t('maximum'), value: 'max' },
  ];

  return (
    <>
      <div className="inputTitle">{t('DataType')}</div>
      <input type="hidden" name="barChartType" value={barChartTypeState} />
      <ButtonGroup
        buttons={buttons}
        onChange={type => {
          setBarChartType(type);
          setAxisY('');
        }}
        value={barChartTypeState}
      />
      {showContent()}
      {barChartTypeState === 'variables' && (
        <>
          <div className="inputTitle">{t('aggregate')}</div>
          <Select
            style={{ width: '150px' }}
            name="aggregateType"
            defaultValue={aggregateType || 'sum'}
            options={aggregateOptions.map(option => (
              { label: option.label, value: option.value }))}
          />
        </>
      )}

      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={intervalType}
        intervalTypeInput
      />

      <div className="inputTitle">
        {t('setGoal')}
        &nbsp;
        <CheckboxToggle
          name="showGoalToggle"
          controlledCheck={showGoalToggleState}
          onChange={handleShowGoalToggle}
        />
      </div>
      <div className={`toggleBlock ${showGoalToggleState ? 'showToggledBlockPopup' : 'hideToggledBlock'}`}>
        {showGoalToggleState && (
          <input
            type="number"
            name="goal"
            min="0"
            className="fullwidth"
            defaultValue={goalState}
            onChange={handleChangeGoal}
          />
        )}
      </div>
      <div className="inputTitle">
        {t('showHistogramBarsValues')}
        &nbsp;
        <CheckboxToggle
          name="showBarsValues"
          defaultChecked={showBarsValues}
        />
      </div>
      <div className="inputTitle">
        {t('showTimeNavigationArrows')}
        &nbsp;
        <CheckboxToggle
          name="showArrows"
          controlledCheck={showArrowsState}
          onChange={handleShowArrows}
        />
      </div>
    </>
  );
};

FormTileBarChart.propTypes = {
  selectedTile: PropTypes.shape({
    axisY: PropTypes.string,
    barChartType: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
    showBarsValues: PropTypes.bool,
    intervalType: PropTypes.string,
    showArrows: PropTypes.bool,
    aggregateType: PropTypes.string,
    machineId: PropTypes.string,
  }).isRequired,
};

export default FormTileBarChart;
