import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import Tick from '~utils/Tick';
import { dateToStringWithoutYearAndMillis, getDataRangeStartEnd } from '~utils/time';
import { FontAwesome } from '~components/UI';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';
import { useShift } from '~utils/hooks';
import { round } from '~utils/math';
import { Gauge } from './GaugeSVG';

const GaugeEvents = ({ selectedObject, dimension, textColor }) => {
  const socket = getSocket();
  const selectedObjectRef = useRef(selectedObject);
  const { t } = useTranslation();

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  });

  const [events, setEvents] = useState([]);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentShift, shifts] = useShift(selectedObject.machineId);
  const [goal, setGoal] = useState(selectedObject.goal);
  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const idleTimerRef = useRef(null);

  const handleSocketEvent = socketEvent => {
    if (socketEvent.name === selectedObjectRef.current.currentEvent) {
      setEvents(prevEvents => [...prevEvents, socketEvent]);
    }

    if (selectedObjectRef.current.objectiveType === 'variable' && socketEvent.id === selectedObjectRef.current.goal) {
      const goalToSet = round(socketEvent.value, 2);
      if (goalToSet >= selectedObjectRef.current.intervalMin && goalToSet <= selectedObjectRef.current.intervalMax) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const fetchEvents = async () => {
    const { start, end } = timePeriod;

    const filter = {
      name: selectedObject.currentEvent,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const { events: newEvents } = await API.getEvents(filter);
    setEvents(newEvents);
  };

  useEffect(() => {
    setCurrentValue(events.length);
  }, [events]);

  const handleOnActive = () => {
    fetchEvents();
  };

  const handleOnIdle = () => {
    fetchEvents();
    idleTimerRef.current.reset();
  };

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, selectedObject.intervalType || 'shift');
    setEvents([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  useEffect(() => {
    setHasUsedArrows(false);
  }, [selectedObject.intervalType]);

  useEffect(() => {
    fetchEvents();
  }, [timePeriod, hasUsedArrows]);

  useEffect(() => {
    fetchEvents();

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }
  }, [selectedObject, currentShift]);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 20); // 20 secondes

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [selectedObject, selectedObject.intervalType, currentShift]);

  useEffect(() => {
    if (socket) {
      socket.on('event', handleSocketEvent);
      socket.on('value', handleSocketEvent);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
        socket.removeListener('value', handleSocketEvent);
      }
    };
  }, [socket]);

  const navigationText = hasUsedArrows
    ? `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.start))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.end))}`
    : '';

  return (
    <div className={`timeNavigationContainer ${!events && 'hide'}`}>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <div>{navigationText}</div>
      <div className="timeNavigation">
        {selectedObject.showArrows && (
          <FontAwesome
            icon="arrow-left"
            className="timeNavigationButton"
            style={{
              opacity: !canNavigateTime(true, timePeriod, shifts, selectedObject.intervalType || 'shift') && 0.4,
              cursor: !canNavigateTime(true, timePeriod, shifts, selectedObject.intervalType || 'shift') && 'default',
            }}
            onClick={() => navigateTime(true)}
          />
        )}
        <Gauge
          selectedObject={selectedObject}
          currentValue={currentValue}
          interval={{ min: selectedObject.intervalMin, max: selectedObject.intervalMax }}
          metric="unit"
          dimension={dimension}
          textColor={textColor}
          showGoalToggle={
            typeof selectedObject.showGoalToggle === 'boolean'
              ? selectedObject.showGoalToggle
              : typeof selectedObject.goal === 'number'
          } // for backward compatibility with no toggle
          goal={goal}
          aboveGoalColor={selectedObject.aboveGoalColor}
          belowGoalColor={selectedObject.belowGoalColor}
          showArrows={selectedObject.showArrows}
        />
        {selectedObject.showArrows && (
          <FontAwesome
            icon="arrow-right"
            className="timeNavigationButton"
            style={{
              opacity: !canNavigateTime(false, timePeriod, shifts, selectedObject.intervalType || 'shift') && 0.4,
              cursor: !canNavigateTime(false, timePeriod, shifts, selectedObject.intervalType || 'shift') && 'default',
            }}
            onClick={() => navigateTime(false)}
          />
        )}
      </div>
    </div>
  );
};

GaugeEvents.propTypes = {
  selectedObject: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    id: PropTypes.string,
    machineId: PropTypes.string,
    title: PropTypes.string,
    valueType: PropTypes.string,
    currentEvent: PropTypes.string,
    intervalMin: PropTypes.number,
    intervalMax: PropTypes.number,
    intervalType: PropTypes.string,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
    showArrows: PropTypes.bool,
  }).isRequired,
  dimension: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
};

export default GaugeEvents;
