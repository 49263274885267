import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SubmitButton, CheckboxToggle } from '~components/UI';

import './OptionalFields.scss';

const OptionalFieldsForm = ({
  fields, handleFieldsChange, changeFields, setShowFields, storageName,
}) => {
  const { t } = useTranslation();
  const [localFields, setLocalFields] = useState([...fields]);

  const handleLocalChange = (param, isChecked) => {
    if (isChecked) {
      setLocalFields([...localFields, param]);
    } else {
      setLocalFields(localFields.filter(field => field !== param));
    }
  };

  const getDisplayName = param => {
    if (param === 'name') {
      return t('eventName');
    }
    if (param === 'status') {
      return t('machineStatus');
    }
    return t(param);
  };

  return (
    <div>
      <div className="fieldsContainer">
        {
          (storageName === 'K2_machine_events' ? ['state', 'endDate', 'comments', 'name'] : ['endDate', 'comments', 'name']).map(param => (
            <div className="multipleSelectionInput">
              <div className="fieldInputContainer">
                {getDisplayName(param)}
                &nbsp;
                <CheckboxToggle
                  controlledCheck={localFields.includes(param)}
                  onChange={e => handleLocalChange(param, e.target.value)}
                />
              </div>
            </div>
          ))
        }
      </div>
      <div className="buttonsFlex">
        <SubmitButton
          label={t('apply')}
          onClick={() => {
            localFields.forEach(param => handleFieldsChange(param, true));
            changeFields(localFields);
            setShowFields(false);
          }}
        />
      </div>
    </div>
  );
};

OptionalFieldsForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleFieldsChange: PropTypes.func.isRequired,
  changeFields: PropTypes.func.isRequired,
  setShowFields: PropTypes.func.isRequired,
  storageName: PropTypes.string.isRequired,
};

export default OptionalFieldsForm;
