import React from 'react';
import PropTypes from 'prop-types';

import GaugeVariables from './GaugeVariables';
import GaugeEvents from './GaugeEvents';
import GaugeMachine from './GaugeMachine';
import { PERCENTAGE_HEIGHT_OUTER_RADIUS } from './GaugeSVG';

const Gauges = {
  variables: GaugeVariables,
  events: GaugeEvents,
  machine: GaugeMachine,
};

const Gauge = ({ selectedObject, ...props }) => {
  const dataType = selectedObject.dataType || 'machine';
  const TypedGauge = Gauges[dataType];
  return (
    <TypedGauge
      selectedObject={selectedObject}
      {...props}
    />
  );
};

Gauge.propTypes = {
  selectedObject: PropTypes.shape({
    dataType: PropTypes.string,
  }),
};

export {
  Gauge,
  PERCENTAGE_HEIGHT_OUTER_RADIUS,
};
