import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { reducersTypes, reduxOperations } from '~services/index';
import { isExternalLink } from '~utils/isExternalLink';

const ImageMedia = ({ displayURL, tile, onClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleClick() {
    onClick();
    if (tile.openNewTab) {
      window.open(tile.linksTo, '_blank');
    } else if (tile.linksTo) {
      if ((isExternalLink(tile.linksTo) && !tile.linksTo.startsWith('/')) || tile.linksTo.includes('redirect')) {
        window.location.href = tile.linksTo;
      } else {
        const hostPath = tile.linksTo.replace('http://', '').replace('https://', '').split('/')[0];
        const inAppURL = tile.linksTo.replace(hostPath, '').replace('http://', '').replace('https://', '');
        dispatch(reduxOperations.redirectHistory.redirectHistoryPush(window.location.pathname));
        history.push(inAppURL);
      }
    }
  }

  return (
    <div
      className="framed flex V"
      onClick={handleClick}
      role={tile.linksTo && 'button'}
    >
      <img
        className="Dashboard__Widget__Contents__Valeur__Media"
        src={displayURL}
        alt="Failed to load"
      />
    </div>
  );
};

ImageMedia.propTypes = {
  displayURL: PropTypes.string.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { ImageMedia };
