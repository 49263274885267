import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import MachinePopup from './MachinePopup';

const MachineParamsPopup = ({
  parameter, onHide, show, paramType, onSubmit, options,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [param, setParam] = useState(parameter);
  const [hasScrolledToSelectedOption, setHasScrolledToSelectedOption] = useState(false);

  const optionRefs = useRef([]) as any;

  const formattedOptions = (options || [])
    .map((option, index) => ({ ...option, index }))
    .sort((a, b) => {
      if (a.order && b.order) {
        return a.order - b.order;
      }
      if (!b.order) {
        return -1;
      }
      if (!a.order) {
        return 1;
      }
      return a.index - b.index;
    })
    .map(s => ({
      name: s.name,
      description: s.description,
      skuNumber: s.skuNumber,
      requiredQuantity: s.requiredQuantity,
      value: s.name,
    }));

  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };

  const handleOptionClick = option => {
    setParam(option.value);
    setHasScrolledToSelectedOption(false);
  };

  const filteredOptions = formattedOptions.filter(
    option => (option.name?.toLowerCase().includes(searchTerm.toLowerCase())
               || option.description?.toLowerCase().includes(searchTerm.toLowerCase())),
  );

  useEffect(() => {
    const selectedOptionIndex = filteredOptions.findIndex(option => option.value === param);
    if (show && !hasScrolledToSelectedOption && selectedOptionIndex !== -1 && optionRefs.current[selectedOptionIndex]) {
      optionRefs.current[selectedOptionIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      setHasScrolledToSelectedOption(true);
    }
  }, [filteredOptions, param, hasScrolledToSelectedOption, show]);

  const handleSubmit = e => {
    onSubmit(e);
    onHide();
    setSearchTerm('');
    setHasScrolledToSelectedOption(false);
  };

  const handleHide = () => {
    onHide();
    setSearchTerm('');
    setParam(parameter);
    setHasScrolledToSelectedOption(false);
  };

  return (
    <MachinePopup
      title={t(paramType)}
      show={show}
      onHide={handleHide}
      type="MachineSKUOperationPopup"
    >
      <div className="paramsPopup">
        <form id={`${paramType}__Popup__Form`} onSubmit={handleSubmit}>
          <div className="searchBar-container">
            <input type="text" className="searchBar" placeholder={t('Search')} onChange={handleSearch} />
          </div>
          <div className="param-options">
            {filteredOptions.map((option, index) => (
              <div
                className={`param-option ${option.value === param ? 'selected' : ''}`}
                key={option.value}
                onClick={() => handleOptionClick(option)}
                ref={el => optionRefs.current[index] = el}
              >
                <strong className={`no-title ${paramType === 'Operation' ? 'option-medium' : 'option-large'}`}>{option?.name}</strong>
                {paramType === 'Operation' && (
                  <>
                    <div className="vertical-line" />
                    <div className="title option-medium">
                      <div className="option-title">
                        {t('skuNumber')}
                      </div>
                      <span>{option?.skuNumber}</span>
                    </div>
                  </>
                )}
                <div className="vertical-line" />
                <div className={`title ${paramType === 'Operation' ? 'option-medium' : 'option-xlarge'}`}>
                  <div className="option-title">
                    {t('description')}
                  </div>
                  <span>{option?.description}</span>
                </div>
                {paramType === 'Operation' && (
                  <>
                    <div className="vertical-line" />
                    <div className="title option-small">
                      <div className="option-title">
                        {t('requiredQuantity')}
                      </div>
                      <span>{option?.requiredQuantity}</span>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <input type="hidden" name={paramType === 'SKU' ? 'skuNumber' : 'operation'} value={param} />
          <div className="param-submit-btn">
            <button type="submit" className="submitButton">
              {t('edit')}
            </button>
          </div>
        </form>
      </div>
    </MachinePopup>
  );
};

MachineParamsPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func,
  paramType: PropTypes.string.isRequired,
  parameter: PropTypes.string.isRequired,
};
MachineParamsPopup.defaultProps = {
  onSubmit: () => undefined,
};

export { MachineParamsPopup };
