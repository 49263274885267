enum Types {
  GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_RESPONSE = 'GET_SETTINGS_RESPONSE',
  UPDATE_SETTINGS_REQUEST = 'UPDATE_SETTINGS_REQUEST',
  UPDATE_SETTINGS_RESPONSE = 'UPDATE_SETTINGS_RESPONSE',
  PATCH_SETTINGS_REQUEST = 'PATCH_SETTINGS_REQUEST',
  PATCH_SETTINGS_RESPONSE = 'PATCH_SETTINGS_RESPONSE',
}

export default Types;
