enum Types {
  GET_VARIABLE_INPUTS_REQUEST = 'GET_VARIABLE_INPUTS_REQUEST',
  GET_VARIABLE_INPUTS_RESPONSE = 'GET_VARIABLE_INPUTS_RESPONSE',
  ADD_VARIABLE_REQUEST = 'ADD_VARIABLE_REQUEST',
  ADD_VARIABLE_RESPONSE = 'ADD_VARIABLE_RESPONSE',
  UPDATE_VARIABLE_REQUEST = 'UPDATE_VARIABLE_REQUEST',
  UPDATE_VARIABLE_RESPONSE = 'UPDATE_VARIABLE_RESPONSE',
  PATCH_VARIABLE_REQUEST = 'PATCH_VARIABLE_REQUEST',
  PATCH_VARIABLE_RESPONSE = 'PATCH_VARIABLE_RESPONSE',
  DELETE_VARIABLE_REQUEST = 'DELETE_VARIABLE_REQUEST',
  DELETE_VARIABLE_RESPONSE = 'DELETE_VARIABLE_RESPONSE',
}

export default Types;
