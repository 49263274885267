import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { millisToHHmm } from '~utils/time';
import { getNumberRange } from '~utils/math';
import {
  PADDING_LEFT,
  PADDING_RIGHT,
  PADDING_TOP,
  PADDING_BOTTOM,
  FONT_SIZE_AXISX,
  PADDING_RIGHT_WITH_ARROWS,
  BLUE,
  PADDING_LEFT_WITH_ARROWS,
} from './constants';
import './Charts.scss';

const callTooltip = (g, value) => {
  if (!value) {
    g.style('display', 'none');
  } else {
    g.style('display', null)
      .style('pointer-events', 'none')
      .style('font', '15px sans-serif');

    const path = g.selectAll('path')
      .data([null])
      .join('path')
      .attr('class', `background_${localStorage.theme === 'Dark' ? 'dark' : 'light'}`);

    const text = g.selectAll('text')
      .data([null])
      .join('text')
      .call(t => t
        .selectAll('tspan')
        .data((`${millisToHHmm(value.x)}\n${value.y}`).split(/\n/))
        .join('tspan')
        .attr('x', 0)
        .attr('y', (d, i) => `${i * 1.1}em`)
        .attr('class', `centerText text_${localStorage.theme === 'Dark' ? 'dark' : 'light'}`)
        .text(d => d));

    const { y, width: w, height: h } = text.node().getBBox();
    text.attr('transform', `translate(0,${15 - y})`);
    path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${1.45 * h}h-${w + 20}z`);
  }
};

const callDot = (g, value) => {
  if (!value) {
    g.style('display', 'none');
  } else {
    g.style('display', null)
      .style('pointer-events', 'none')
      .style('font', '15px sans-serif');

    g.selectAll('circle')
      .data([null])
      .join('circle')
      .attr('r', '5')
      .attr('fill', BLUE)
      .style('stroke-width', '2')
      .style('stroke', 'white');
  }
};

const AreaChart = ({
  height,
  width,
  domainX,
  data,
  showGoalToggle,
  goal,
  timePeriod,
  showArrows,
}) => {
  const node = useRef(null);

  const calculateYAxis = dataToShow => {
    const [minData, maxData] = d3.extent(dataToShow.map(d => Number(d.y)));
    if (!goal && (minData === undefined || (minData === 0 && maxData === 0))) {
      return [0, 10];
    }

    const min = minData || 0;
    let max = 0;
    if (goal && !maxData) {
      max = goal;
    } else if (!goal && maxData) {
      max = maxData;
    } else {
      max = Math.max(goal, maxData);
    }

    let minShowed = 0;
    let maxShowed = 0;

    if (min < 0) {
      minShowed = -2;
      if (min < minShowed) {
        const minPow = getNumberRange(min);
        minShowed = (Math.ceil(min / minPow)) * minPow - (minPow / 2);
        if (min < minShowed || !Number.isInteger(minShowed)) {
          minShowed -= (minPow / 2);
        }
      }
    }

    if (max > 0) {
      maxShowed = 2;
      if (max > maxShowed) {
        const maxPow = getNumberRange(max);
        maxShowed = (Math.floor(max / maxPow)) * maxPow + (maxPow / 2);
        if (max > maxShowed || !Number.isInteger(maxShowed)) {
          maxShowed += (maxPow / 2);
        }
      }
    }

    return [minShowed, maxShowed];
  };

  const calculateXAxis = () => {
    const { start, end } = timePeriod;
    // width of a time on the x axis in px + 5px of padding. Ex: "23:40"
    const timeWidthSize = 35 + 5;
    const oneHour = 3600000;
    const xAxisWidth = (0.9 * width - PADDING_LEFT * 2);
    const nbHoursInShift = (end - start) / oneHour;
    const theoreticalTimeGap = oneHour / ((xAxisWidth / timeWidthSize) / nbHoursInShift);
    let timeGap = oneHour;
    let finalTimeGap = 1;
    // At each iteration, we try to reduce the gap as much as possible on the xAxis
    const hourGapOptions = [16, 8, 4, 2, 1, 0.5, 0.25];
    hourGapOptions.forEach(gap => {
      if (theoreticalTimeGap < oneHour * gap) {
        timeGap = oneHour * gap;
        finalTimeGap = gap;
      }
    });

    const domainXToShow = [0];
    for (let i = start; i < end; i += timeGap) {
      domainXToShow.push(i);
    }

    const numberOfTicks = (end - start) / timeGap;
    const spaceBetweenTicks = xAxisWidth / numberOfTicks;
    const lastTickPosition = Math.trunc(numberOfTicks) * spaceBetweenTicks;

    // The ratio of the time difference between the last tick and the before to last tick.
    // E.g.: before to last tick : 16:00
    // last tick: 16:15
    // hourRatioOfLastTick = 15 / 16 = 0.25
    const hourRatioOfLastTick = (end - domainXToShow[domainXToShow.length - 1]) / oneHour;

    // If the last tick is a "good tick" (16:00 when the time gap is one hour, 16:30 when the time gap is 30 min etc..)
    // OR if the last "good tick" position and the end of the shift position is greater than 35px
    if (hourRatioOfLastTick < finalTimeGap && xAxisWidth - lastTickPosition < 35) {
      domainXToShow.pop();
    }
    domainXToShow.push(end);
    return domainXToShow;
  };

  const createAreaChart = () => {
    const heightChart = 0.85 * height - PADDING_BOTTOM;

    // Remove everything inside the div
    d3.select(node.current).selectAll('*').remove();

    const [minShowed, maxShowed] = calculateYAxis(data);

    // SVG for the graph
    const svg = d3.select(node.current)
      .append('svg')
      .classed('nocursorpointer', true)
      .attr('width', width)
      .attr('height', height);

    const x = d3.scaleTime()
      .domain(domainX)
      .range([
        showArrows
          ? PADDING_LEFT_WITH_ARROWS
          : PADDING_LEFT, width - (showArrows ? PADDING_RIGHT_WITH_ARROWS : PADDING_RIGHT)]);
    const y = d3.scaleLinear()
      .domain([minShowed, maxShowed])
      .range([heightChart, PADDING_TOP]);

    // Areas
    const area = d3.area()
      .curve(d3.curveLinear)
      .x(d => x(new Date(d.x)))
      .y1(d => y(d.y))
      .y0(y(0));
    svg.append('path')
      .datum(data)
      .attr('fill', BLUE)
      .attr('fill-opacity', 0.4)
      .attr('d', area);

    // Line
    const line = d3.line()
      .x(d => x(d.x))
      .y(d => y(d.y));
    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', BLUE)
      .attr('stroke-width', 3)
      .attr('d', line);

    // Goal line
    if (showGoalToggle && goal !== null) {
      const goaLine = d3.axisLeft(y)
        .tickValues([goal])
        .tickSize(-width + (PADDING_RIGHT * 2))
        .tickSizeOuter(0)
        .tickFormat('');
      svg.append('g')
        .classed('greenLine', true)
        .attr('transform', `translate(${PADDING_LEFT},0)`)
        .call(goaLine);
    }

    // we have to use our own algorithm here because the default D3.js 'scale' function was not giving us what we wanted
    const responsiveDomain = calculateXAxis();

    // X Axis
    const xAxis = d3.axisBottom(x)
      .tickValues(responsiveDomain)
      .tickSizeOuter(0)
      .tickFormat(d3.timeFormat('%H:%M'));
    svg.append('g')
      .attr('transform', `translate(0,${heightChart})`)
      .call(xAxis)
      .selectAll('text')
      .style('font-size', FONT_SIZE_AXISX);
    const xGridlines = d3.axisBottom(x)
      .ticks(responsiveDomain.length)
      .tickSize(-height)
      .tickSizeOuter(0)
      .tickFormat('');
    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(0,${heightChart})`)
      .call(xGridlines);

    // Y Axis
    const yAxis = d3.axisLeft(y)
      .ticks(height / 100)
      .tickFormat(d3.format('.0f'));
    svg.append('g')
      .attr('transform', `translate(${PADDING_LEFT},0)`)
      .call(yAxis);
    const yGridlines = d3.axisLeft(y)
      .ticks(height / 100)
      .tickSize(-width + (PADDING_RIGHT * 1.5))
      .tickSizeOuter(0)
      .tickFormat('');
    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${PADDING_LEFT},0)`)
      .call(yGridlines);

    // Dots
    const dot = svg.append('g');

    // Tooltip
    const tooltip = svg.append('g');

    // Mouse events
    svg.on('mousemove touchmove', () => {
      const [mouseX, mouseY] = d3.mouse(node.current);
      const graphX = (x.invert(mouseX)).getTime();
      const bisect = d3.bisector(d => d.x);
      const index = bisect.left(data, graphX);
      const value = data[index];
      if (value) {
        tooltip.attr('transform', `translate(${x(value.x)},${mouseY})`)
          .call(callTooltip, value);
        dot.attr('transform', `translate(${x(value.x)},${y(value.y)})`)
          .call(callDot, value);
      } else {
        tooltip.call(callTooltip, null);
        dot.call(callDot, null);
      }
    });
    svg.on('mouseleave touchend', () => {
      tooltip.call(callTooltip, null);
      dot.call(callDot, null);
    });
  };

  useEffect(() => {
    createAreaChart();
  }, [height, width, domainX, data, showGoalToggle, goal, timePeriod.start, timePeriod.end, showArrows]);

  return <div ref={node} />;
};

AreaChart.propTypes = {
  timePeriod: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  domainX: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  showGoalToggle: PropTypes.bool.isRequired,
  goal: PropTypes.number,
  showArrows: PropTypes.bool,
};
AreaChart.defaultProps = {
  data: [],
  showArrows: false,
};

export default AreaChart;
