import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import { triggerTypes } from '~utils/types';
import types from './types';

const triggerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(triggerTypes)),
  isActive: PropTypes.bool,
});

const triggersPropTypes = PropTypes.arrayOf(triggerPropTypes);

function triggers(state = null, action) {
  switch (action.type) {
    case types.GET_TRIGGERS_RESPONSE:
      return action.payload.triggers;
    case types.ADD_TRIGGER_RESPONSE:
      if (action.payload.error || !state) {
        return state;
      }
      return state.concat(action.payload.trigger);
    case types.UPDATE_TRIGGER_RESPONSE:
    case types.PATCH_TRIGGER_RESPONSE:
      if (action.payload.error || !state) {
        return state;
      }
      return state.map(trigger => ((trigger.id === action.payload.trigger.id) ? action.payload.trigger : trigger));
    case types.DELETE_TRIGGER_RESPONSE:
      if (action.payload.error || !state) {
        return state;
      }
      return state.filter(trigger => trigger.id !== action.payload.triggerId);
    default:
      return state;
  }
}

export const propTypes = {
  triggers: nullable(triggersPropTypes),
  trigger: nullable(triggerPropTypes),
};

export default triggers;
