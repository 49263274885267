import { Dispatch } from 'redux';
import { fetchOnceOperation, fetchOperation } from '../fetchOperation';
import {
  DashboardAction,
  getDashboardsRequest, getDashboardsResponse,
  updateDashboardRequest, updateDashboardResponse,
  addDashboardRequest, addDashboardResponse,
  deleteDashboardRequest, deleteDashboardResponse,
  updateDashboardTileRequest, updateDashboardTileResponse,
  updateManyDashboardTilesRequest, updateManyDashboardTilesResponse,
  addDashboardTileRequest, addDashboardTileResponse,
  deleteDashboardTileRequest, deleteDashboardTileResponse,
} from './actions';
import API from '~services/endpoints';
import { Dashboard } from './types';

type DashboardDispatch = Dispatch<DashboardAction>;

// Dashboards
function fetchRedirectedDashboards() {
  return fetchOperation(
    getDashboardsRequest,
    getDashboardsResponse,
    API.getDashboards,
    state => state.dashboards.dashboards,
  );
}

function fetchDashboards() {
  return fetchOnceOperation(
    getDashboardsRequest,
    getDashboardsResponse,
    API.getDashboards,
    state => state.dashboards.dashboards,
  );
}

function addDashboard(dashboard: Partial<Dashboard>) {
  return (dispatch: DashboardDispatch) => {
    dispatch(addDashboardRequest(dashboard));
    return API.addDashboard(dashboard)
      .then(res => dispatch(addDashboardResponse(res)));
  };
}

function updateDashboard(dashboardId, dashboard) {
  return (dispatch: DashboardDispatch) => {
    dispatch(updateDashboardRequest({ dashboardId, dashboard }));
    return API.updateDashboard(dashboardId, dashboard)
      .then(res => dispatch(updateDashboardResponse(res)));
  };
}

function deleteDashboard(dashboardId) {
  return (dispatch: DashboardDispatch) => {
    dispatch(deleteDashboardRequest(dashboardId));
    return API.deleteDashboard(dashboardId)
      .then(res => dispatch(deleteDashboardResponse(res)));
  };
}

function addDashboardTile(dashboardId, tile) {
  return (dispatch: DashboardDispatch) => {
    dispatch(addDashboardTileRequest({ dashboardId, tile }));
    return API.addDashboardTile(dashboardId, tile)
      .then(res => dispatch(addDashboardTileResponse(res.dashboard)));
  };
}

function updateDashboardTile(dashboardId, tileId, tileData) {
  return (dispatch: DashboardDispatch) => {
    dispatch(updateDashboardTileRequest({ dashboardId, tileId, tileData }));
    return API.updateDashboardTile(dashboardId, tileId, tileData)
      .then(res => dispatch(updateDashboardTileResponse(res.dashboard)));
  };
}

function updateManyDashboardTiles(dashboardId, tiles) {
  return (dispatch: DashboardDispatch) => {
    dispatch(updateManyDashboardTilesRequest({ dashboardId, tiles }));
    return API.updateManyDashboardTiles(dashboardId, { tiles })
      .then(res => dispatch(updateManyDashboardTilesResponse(res.dashboard)));
  };
}

function deleteDashboardTile(dashboardId, tileId) {
  return (dispatch: DashboardDispatch) => {
    dispatch(deleteDashboardTileRequest({ dashboardId, tileId }));
    return API.deleteDashboardTile(dashboardId, tileId)
      .then(res => dispatch(deleteDashboardTileResponse(res)));
  };
}

export default {
  // Dashboard
  fetchRedirectedDashboards,
  fetchDashboards,
  updateDashboard,
  addDashboard,
  deleteDashboard,
  // Tiles
  addDashboardTile,
  updateDashboardTile,
  updateManyDashboardTiles,
  deleteDashboardTile,
};
