const REDIRECTED_USER_ID = 'Redirected';

const buildLinkRedirectionTargetURL = (contentType, contentId) => {
  switch (contentType) {
    case 'Dashboard':
      return `/dashboard/${contentId}`;
    case 'Topview':
      return `/topview/${contentId}`;
    default:
      return '/';
  }
};

const isRedirected = userId => userId === REDIRECTED_USER_ID;

export {
  buildLinkRedirectionTargetURL,
  isRedirected,
};
