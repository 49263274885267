import cloneDeep from 'lodash.clonedeep';

const getPropertyDepth = (element, property) => {
  const properties = property.split('.');
  let res = element;
  properties.forEach(prop => {
    res = res[prop];
  });
  return res;
};

const sortArray = (type, array, property) => {
  const clonedArray = cloneDeep(array);
  if (type === 'alphabetically') {
    clonedArray?.sort((a, b) => {
      const nameA = getPropertyDepth(a, property);
      const nameB = getPropertyDepth(b, property);
      if (!nameA || nameA.length === 0) {
        return 1;
      }
      if (!nameB || nameB.length === 0) {
        return -1;
      }
      const plainA = nameA.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const plainB = nameB.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      if (plainA < plainB) {
        return -1;
      }
      if (plainA > plainB) {
        return 1;
      }
      return 0;
    });
  } else if (type === 'numberAscending') {
    clonedArray.sort((a, b) => {
      const valueA = getPropertyDepth(a, property);
      const valueB = getPropertyDepth(b, property);
      if (valueA === undefined || Number.isNaN(valueA)) {
        return 1;
      }
      if (valueB === undefined || Number.isNaN(valueB)) {
        return -1;
      }
      return valueA - valueB;
    });
  } else if (type === 'numberDescending') {
    clonedArray.sort((a, b) => {
      const valueA = getPropertyDepth(a, property);
      const valueB = getPropertyDepth(b, property);
      if (valueB === undefined || Number.isNaN(valueB)) {
        return -1;
      }
      if (valueA === undefined || Number.isNaN(valueA)) {
        return 1;
      }

      return valueB - valueA;
    });
  }

  return clonedArray;
};

export {
  sortArray,
};
