import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import DraggableCauseIcon from './DraggableCauseIcon';

const DraggableCauseIconContainer = ({ causesArray, updateCauses, onClick, children }) => {
  const [causes, setStopCauses] = useState(causesArray);

  useEffect(() => {
    setStopCauses(causesArray);
  }, [causesArray]);

  const updateCausesIcon = () => {
    updateCauses(causes);
  };

  const moveCause = useCallback((dragIndex, hoverIndex) => {
    const dragStopCause = causes[dragIndex];
    setStopCauses(update(causes, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragStopCause],
      ],
    }));
  }, [causes]);

  const renderCause = (cause, index) => (
    <DraggableCauseIcon
      key={index}
      index={index}
      cause={cause}
      moveCause={moveCause}
      updateCauses={updateCausesIcon}
      onClick={onClick}
    />
  );

  return (
    <div className="causesIconsContainer">
      {causes.map((cause, index) => renderCause(cause, index))}
      {children}
    </div>
  );
};

DraggableCauseIconContainer.propTypes = {
  causesArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateCauses: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element,
};

DraggableCauseIconContainer.defaultProps = {
  children: null,
};

export default DraggableCauseIconContainer;
