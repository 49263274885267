import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from './ErrorPage';

const Page404NotFound = () => {
  const { t } = useTranslation();
  const errorDefinition = t('pageNotFound');
  const errorDescription = t('pageNotFoundDescription');

  return (
    <ErrorPage
      errorNumber={404}
      errorDefinition={errorDefinition}
      errorDescription={errorDescription}
    />
  );
};

export default Page404NotFound;
