import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '@intelligenceindustrielle/react-ui-components';
import { DefaultModal, FontAwesome } from '~UI/index';
import imagesLibrary from '~images/imagesLibrary';
import UserModificationForm from './UserModificationForm/UserModificationForm';

const UserModificationPopup = ({
  isCreating,
  isProfile,
  modifiedItemId,
  onHide,
  show,
}) => {
  const { t } = useTranslation();
  const selectedUser = useSelector(state => state.users.selectedUser);
  const users = useSelector(state => state.users.users);
  const [user, setUser] = useState({});
  const [currentPageNb, setCurrentPageNb] = useState(modifiedItemId ? 1 : 0);
  const [currentAccountType, setCurrentAccountType] = useState('ii');

  const showPreviousPage = () => {
    setCurrentPageNb(0);
  };

  const showBackArrow = text => (
    <>
      {!isProfile && !!currentPageNb && (
        <FontAwesome
          icon="arrow-left"
          className="backButtonArrow"
          size="sm"
          onClick={showPreviousPage}
        />
      )}
      {text}
    </>
  );

  useEffect(() => {
    if (isProfile && selectedUser) {
      setCurrentAccountType(selectedUser.accountType || 'ii');
      setUser(selectedUser);
    } else if (users) {
      const newUser = users.find(u => u.id === modifiedItemId);
      if (newUser) {
        setCurrentAccountType(newUser.accountType || 'ii');
        setUser(newUser);
      }
    }
  }, [isProfile, modifiedItemId, users]);

  useEffect(() => {
    if (show) {
      setCurrentPageNb(0);
    }
  }, [show]);

  const handleSelectAccountType = value => {
    setCurrentPageNb(currentPageNb + 1);
    setCurrentAccountType(value);
  };

  return (
    <DefaultModal
      closePopup={onHide}
      show={show}
      title={modifiedItemId ? showBackArrow(`${t('editUser')} ${user.name}`) : showBackArrow(t('createUser'))}
    >
      {!isProfile && currentPageNb === 0 ? (
        <SelectionGrid
          onChange={handleSelectAccountType}
          options={[
            {
              bgColor: '#333',
              label: 'Intelligence Industrielle',
              value: 'ii',
              Icon: <img src={imagesLibrary.LogoII} alt="Intelligence Industrielle" width="40px" />,
            },
            {
              bgColor: '#333',
              label: 'Microsoft',
              value: 'microsoft',
              Icon: <FontAwesome icon="microsoft" width="40px" height="40px" />,
            },
          ]}
        />
      ) : (
        <UserModificationForm
          accountType={currentAccountType}
          isCreating={isCreating}
          isProfile={isProfile}
          modifiedItemId={modifiedItemId}
          onHide={onHide}
        />
      )}
    </DefaultModal>
  );
};

UserModificationPopup.propTypes = {
  isCreating: PropTypes.bool,
  isProfile: PropTypes.bool.isRequired,
  modifiedItemId: PropTypes.string.isRequired,
  onHide: PropTypes.func,
  show: PropTypes.bool,
};
UserModificationPopup.defaultProps = {
  isCreating: false,
  onHide: () => { },
  show: false,
};

export { UserModificationPopup };
