import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services/index';
import { getFormData } from '~utils/index';

import MachinePopup from './MachinePopup';

const propTypes = {
  // should be given by ModalHandler
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  machineParams: PropTypes.object,
  // global states
  machineId: PropTypes.string,
  value: PropTypes.string,
  onSubmitTrigger: PropTypes.string,
  params: PropTypes.arrayOf(PropTypes.shape({})),
};

const MachineWorkorderPopup = ({
  show, onHide, machineParams, value, machineId, params, onSubmitTrigger,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    const formData = getFormData('WorkOrder__Popup__Form');
    reduxOperations.machines.setParams(machineId, { ...machineParams, ...formData })(dispatch);

    if (onSubmitTrigger) {
      const optionalTriggerParams = { ...params, ...formData };
      reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
        ...optionalTriggerParams,
        machineId,
      })(dispatch);
    }
    onHide();
  };

  return (
    <MachinePopup
      title={t('workOrder')}
      show={show}
      onHide={onHide}
    >
      <div className="workOrder">
        <form id="WorkOrder__Popup__Form" onSubmit={handleSubmit}>
          <input
            name="workOrder"
            className="fullwidth"
            type="text"
            value={currentValue}
            placeholder={t('workOrder')}
            onChange={e => setCurrentValue(e.target.value)}
            autoFocus
          />
          <div className="btn-group fullwidth">
            <button type="button" className="submitButton red" onClick={onHide}>
              {t('cancel')}
            </button>
            <button type="submit" className="submitButton">
              {t('edit')}
            </button>
          </div>
        </form>
      </div>
    </MachinePopup>
  );
};

MachineWorkorderPopup.propTypes = propTypes;

export default MachineWorkorderPopup;
