import { fetchOnceOperation } from '../fetchOperation';
import {
  getStreamsRequest, getStreamsResponse,
  addStreamRequest, addStreamResponse,
  updateStreamRequest, updateStreamResponse,
  deleteStreamRequest, deleteStreamResponse,
  getStreamPropertiesRequest, getStreamPropertiesResponse,
  addStreamPropertyRequest, addStreamPropertyResponse,
  updateStreamPropertyRequest, updateStreamPropertyResponse,
  deleteStreamPropertyRequest, deleteStreamPropertyResponse,
} from './actions';
import API from '~services/endpoints';

function fetchStreams() {
  return fetchOnceOperation(
    getStreamsRequest,
    getStreamsResponse,
    API.getStreams,
    state => state.streams,
  );
}

function addStream(stream) {
  return dispatch => {
    dispatch(addStreamRequest(stream));
    return API.addStream(stream)
      .then(res => {
        if (!res.error && res.stream) {
          dispatch(addStreamResponse(res));
        }
      });
  };
}

function updateStream(streamId, stream) {
  return dispatch => {
    dispatch(updateStreamRequest({ streamId, stream }));
    return API.updateStream(streamId, stream)
      .then(res => {
        if (!res.error && res.stream) {
          dispatch(updateStreamResponse(res));
        }
      });
  };
}

function deleteStream(streamId) {
  return dispatch => {
    dispatch(deleteStreamRequest(streamId));
    return API.deleteStream(streamId)
      .then(res => {
        if (!res.error && res.streamId) {
          dispatch(deleteStreamResponse(res));
        }
      });
  };
}

// Stream Properties
function fetchStreamProperties(streamId) {
  return dispatch => {
    dispatch(getStreamPropertiesRequest({ streamId }));
    return API.getStreamProperties(streamId)
      .then(res => {
        if (!res.error && res.properties) {
          dispatch(getStreamPropertiesResponse(res));
        }
      });
  };
}

function addStreamProperty(streamId, property) {
  return dispatch => {
    dispatch(addStreamPropertyRequest({ streamId, property }));
    return API.addStreamProperty(streamId, property)
      .then(res => {
        if (!res.error && res.stream) {
          dispatch(addStreamPropertyResponse(res));
        }
      });
  };
}

function updateStreamProperty(streamId, propertyId, property) {
  return dispatch => {
    dispatch(updateStreamPropertyRequest({ streamId, propertyId, property }));
    return API.updateStreamProperty(streamId, propertyId, property)
      .then(res => {
        if (!res.error && res.stream) {
          dispatch(updateStreamPropertyResponse(res));
        }
      });
  };
}

function deleteStreamProperty(streamId, propertyId) {
  return dispatch => {
    dispatch(deleteStreamPropertyRequest({ streamId, propertyId }));
    return API.deleteStreamProperty(streamId, propertyId)
      .then(res => {
        if (!res.error && res.stream) {
          dispatch(deleteStreamPropertyResponse(res));
        }
      });
  };
}

export default {
  fetchStreams,
  addStream,
  updateStream,
  deleteStream,
  fetchStreamProperties,
  addStreamProperty,
  updateStreamProperty,
  deleteStreamProperty,
};
