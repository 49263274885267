import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import omit from 'lodash.omit';

import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AddIcon } from '~UI/RoundButton/RoundIcons';
import DraggableCauseIconContainer from '~components/UI/RoundButton/DraggableCauseIconContainer';
import { SubmitButton } from '~UI';
import { reduxOperations } from '~services';
import { showError, showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';

import DefectCauseEdition from './DefectCauseEdition';
import DefectCauseForm from './DefectCauseForm';

const DefectCausesForm = ({ machineId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const machines = useSelector(state => state.machines);

  const [selectedDefectCause, setSelectedDefectCause] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [defectCauses, setDefectCauses] = useState([]);

  useEffect(() => {
    const machine = machines.find(mach => mach.id === machineId);
    setDefectCauses(machine?.defectCauses || []);
  }, [machineId, machines]);

  const updateDefectCauses = newDefectCauses => {
    setDefectCauses(newDefectCauses);
    dispatch(reduxOperations.machines.patchMachine(machineId, { defectCauses: newDefectCauses }));
  };

  const selectPreset = () => {
    const machine = machines.find(mach => mach.id === selectedPreset);

    if (machine && machine.defectCauses) {
      const newDefectCauses = machine.defectCauses.map(topCause => omit({
        ...topCause,
        subMenu: topCause.subMenu.map(subCause => omit(subCause, 'id')),
      }, 'id'));
      dispatch(reduxOperations.machines.patchMachine(machineId, { defectCauses: newDefectCauses }))
        .then(() => {
          showSuccess(t('operationSucceeded'));
        })
        .catch(error => showError(error.status));
    } else {
      showError(t('machineNotFound'));
    }
  };

  const presetSelection = () => (
    <>
      <span className="presetSelectionText">
        {`${t('createFromTemplate')}: `}
      </span>
      <Select
        style={{ width: '150px', display: 'inline-block' }}
        value={selectedPreset || '-'}
        onChange={setSelectedPreset}
        options={[
          { label: '-', value: '-' },
          ...sortArray('alphabetically', machines.filter(m => m.id !== machineId), 'name').map(machine => ({ label: machine.name, value: machine.id })),
        ]}
      />
      &nbsp;
      <SubmitButton
        onClick={() => selectPreset()}
        label={t('import')}
        askConfirmation
      />
    </>
  );

  const machine = machines.find(m => m.id === machineId);

  if (selectedDefectCause && selectedDefectCause.name) {
    const currentDefectCause = machine.defectCauses.find(pc => pc.id === selectedDefectCause.id);
    return (
      <DefectCauseEdition
        defectCause={currentDefectCause}
        goBackForm={() => setSelectedDefectCause(null)}
        machineId={machineId}
      />
    );
  }

  if (selectedDefectCause) {
    return (
      <DefectCauseForm
        defectCause={selectedDefectCause}
        isInSubMenu={false}
        goBackForm={() => setSelectedDefectCause(null)}
        machineId={machineId}
      />
    );
  }

  return (
    <div className="DefectCausesForm">
      {presetSelection()}
      <div className="DefectCausesIconsContainer">
        <DndProvider backend={HTML5Backend}>
          <DraggableCauseIconContainer
            causesArray={defectCauses}
            onClick={setSelectedDefectCause}
            updateCauses={updateDefectCauses}
          >
            <AddIcon
              onClick={() => setSelectedDefectCause({})}
            />
          </DraggableCauseIconContainer>
        </DndProvider>
      </div>
    </div>
  );
};

DefectCausesForm.propTypes = {
  machineId: PropTypes.string.isRequired,
};

export default DefectCausesForm;
