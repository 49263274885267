import API from '~services/endpoints';
import { serverTime } from '~utils/time';

class StopwatchManager {
  constructor() {
    this.stopwatches = [];
  }

  updateStopwatchIO = socketData => {
    this.stopwatches = this.stopwatches.map(watch => {
      if (watch.id === socketData.id) {
        return {
          id: watch.id,
          name: watch.name,
          type: watch.type,
          startTime: watch.startTime,
          ...socketData.notification,
        };
      }
      return watch;
    });
  };

  setupSWManager = socket => {
    API.getStopwatches().then(res => {
      this.stopwatches = res.stopwatches;

      if (socket) {
        socket.on('stopwatches', this.updateStopwatchIO);
      }
    });
  };

  getStopwatchTime = id => {
    const stopwatch = this.stopwatches.find(watch => watch.id === id);
    let time = stopwatch ? stopwatch.currentTime : 0;

    if (stopwatch && stopwatch.isActive) {
      time = (serverTime() - stopwatch.savedAtEpoch) + stopwatch.currentTime;
    }

    if (stopwatch && stopwatch.type === 'CountDown') {
      time = stopwatch.startTime - time;
    }

    return time;
  };

  addStopwatch = stopwatch => {
    this.stopwatches.push(stopwatch);
  };

  updateStopwatch = stopwatch => {
    this.stopwatches = this.stopwatches.map(watch => (
      (watch.id === stopwatch.id)
        ? stopwatch
        : watch));
  };

  deleteStopwatch = id => {
    this.stopwatches = this.stopwatches.filter(watch => watch.id !== id);
  };
}

export default new StopwatchManager();
