import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import Tick from '~utils/Tick';
import { getDataRangeStartEnd, serverTime } from '~utils/time';
import { round } from '~utils/math';
import AreaChartComponent from './AreaChartComponent';
import { useShift } from '~utils/hooks';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';

const AreaChartVariables = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();
  const [data, setData] = useState(null);
  const [values, setValues] = useState([]);

  const [currentShift, shifts] = useShift(null);

  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const handleSocketData = socketData => {
    if (socketData.id === tile.axisY) {
      const value = {
        timestamp: serverTime(),
        valueId: tile.axisY,
        value: socketData.value,
      };
      setValues([...values, value].sort((v1, v2) => v1.timestamp - v2.timestamp));
    }
  };

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const evaluateVariables = valuesArg => {
    const { start, end } = timePeriod;

    const lapse = Math.round((end - start) / 2000); // 2000 points on the graph
    const newData = {};
    // Filter out old values.
    // Keeping a "firstValue" so that our graph does not start from somewhere else than x = 0 if possible
    const filteredValues = valuesArg.filter((value, index) => {
      if (index + 1 === valuesArg.length) { return true; }
      if (valuesArg[index + 1].timestamp > start && value.timestamp <= start) { return true; }
      return value.timestamp >= start && value.timestamp <= end;
    });

    // Assigning "start" timestamp to the firstValue to always start our graph from 0 if possible
    if (filteredValues.length && filteredValues[0].timestamp < start) {
      filteredValues[0].timestamp = start;
    }

    if (filteredValues.length) {
      filteredValues.forEach((value, index) => {
        const valueRelativeTime = value.timestamp - start;
        const relativeIndex = Math.trunc(valueRelativeTime / lapse);
        const x = start + lapse * relativeIndex;
        if (valueRelativeTime >= 0) {
          newData[x] = {
            x,
            y: round(value.value, 2),
          };
          if (index === filteredValues.length - 1) {
            // Add a final value to show live data
            newData[serverTime()] = {
              x: serverTime(),
              y: round(value.value, 2),
            };
          }
        }
      });
    }

    const result = [];
    // Create a data points for the X axis, with or without a Y axis value
    for (let time = start; time < serverTime(); time += lapse) {
      result.push(newData[time] || { x: time });
    }
    // If there is not a Y axis value to a data point, use the previous value.
    // If there is no previous value, y = 0
    result.forEach((dataPoint, index) => {
      if (!dataPoint.y && dataPoint.y !== 0) {
        dataPoint.y = result[index - 1] ? result[index - 1].y : 0;
      }
    });

    setData(result);
  };

  const fetchVariables = async () => {
    const { start, end } = timePeriod;
    const valueId = tile.axisY;

    let { values: newValues } = await API.getValues(valueId, { timestamp: { $gte: start, $lt: end } });

    const firstValueResponse = await API.getValues(valueId, { timestamp: { $lt: start } }, 2);
    let firstValue = null;

    if (firstValueResponse.values.length) {
      firstValue = {
        timestamp: start,
        value: firstValueResponse.values[0].value,
      };
    }

    // If there are no newValues and no values before start, get the most recent value
    if (newValues.length === 0 && firstValueResponse.values.length === 0) {
      const recentValueResponse = await API.getValues(valueId, {}, 1);
      if (recentValueResponse.values.length > 0) {
        firstValue = {
          timestamp: start,
          value: recentValueResponse.values[0].value,
        };
        newValues = [firstValue];
      }
    }

    const valuesToSet = (firstValue
      ? [firstValue, ...newValues]
      : newValues).sort((v1, v2) => v1.timestamp - v2.timestamp);
    setValues(valuesToSet);
    evaluateVariables(valuesToSet);
  };

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, tile.intervalType || 'shift');
    setValues([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  useEffect(() => {
    if (socket) {
      socket.on('value', handleSocketData);
    }

    return () => {
      if (socket) {
        socket.removeListener('value', handleSocketData);
      }
    };
  }, [socket]);

  useEffect(() => {
    fetchVariables();
  }, [timePeriod, hasUsedArrows]);

  useEffect(() => {
    fetchVariables();
  }, []);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 20); // 20 secondes

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [tile, tile.intervalType, currentShift]);

  return (
    <AreaChartComponent
      backgroundColor={backgroundColor}
      canNavigateTime={goBackward => canNavigateTime(goBackward, timePeriod, shifts, tile.intervalType || 'shift')}
      data={data}
      hasUsedArrows={hasUsedArrows}
      height={height}
      navigateTime={navigateTime}
      tile={tile}
      timePeriod={timePeriod}
      width={width}
    />
  );
};

AreaChartVariables.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.shape({
    axisY: PropTypes.string,
    intervalType: PropTypes.string,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default AreaChartVariables;
