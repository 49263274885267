import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Popover } from '~components/Popover';
import OptionalFields from './OptionalFields';
import { FontAwesome } from '~UI';
import { EventsTable } from '~components/Pages';
import { MAX_NB_EVENTS_ONE_PAGE } from '~utils/constants';
import { eventsToReadable } from '~utils/events';
import { getLocalStorageSetter } from '~utils/localStorage';
import { eventsFeature } from '~utils/featureToggles';
import { findNestedCause } from '~utils/nestedStopCause';

const EventsList = ({
  columnNames,
  entriesProperties,
  socketsOn,
  onModifyEvent,
  filteredList,
  leftHeader,
  storageName,
  noPagination,
  setShowFilter,
  showFields,
  setShowFields,
}) => {
  const putStorage = getLocalStorageSetter(storageName);
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);

  const [fields, setFields] = useState([]);
  const [flattenedList, setFlattenedList] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [isModifying, setIsModifying] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const getInitialFields = strgName => {
      const savedFields = localStorage.getItem('fieldsList');
      let initialFields = savedFields ? JSON.parse(savedFields) : [];

      if (strgName === 'K2_part_events' || strgName === 'K2_performance_events') {
        initialFields = initialFields.filter(field => field !== 'state');
      } else if (strgName !== 'K2_machine_events') {
        initialFields = [];
      }

      return initialFields;
    };

    setFields(getInitialFields(storageName));
  }, [storageName]);

  useEffect(() => {
    setPageNumber(1);
    setNumberOfPages(noPagination ? 1 : Math.ceil(filteredList.length / MAX_NB_EVENTS_ONE_PAGE));
    setFlattenedList(filteredList.map(e => {
      if (e.infosObject) {
        const newEvent = {
          ...e,
          operation: e.infosObject.operation,
          skuNumber: e.infosObject.skuNumber,
          operator: e.infosObject.operator,
          workOrder: e.infosObject.workOrder,
        };
        return newEvent;
      }
      return e;
    }));
  }, [filteredList]);

  useEffect(() => {
    if (storageName !== 'K2_default_events') {
      localStorage.setItem('fieldsList', JSON.stringify(fields));
    }
  }, [fields]);

  const changeFields = newFields => {
    setFields(newFields);
    putStorage('fieldsList', newFields);
  };

  const handleFieldsChange = (param, isChecked) => {
    const newFields = [...fields];
    if (isChecked) {
      newFields.push(param);
    } else {
      const index = newFields.indexOf(param);
      if (index > -1) {
        newFields.splice(index, 1);
      }
    }
    changeFields(newFields);
  };

  function getPageNavigation() {
    const rows = [];
    for (let i = 1; i < numberOfPages + 1; i += 1) {
      if (i === 1 || (pageNumber + 1 >= i && pageNumber - 1 <= i) || i === numberOfPages) {
        rows.push(
          <span
            key={i}
            onClick={() => setPageNumber(i)}
            className={`numbering ${pageNumber === i ? 'active' : ''}`}
          >
            {i}
          </span>,
        );
      } else if (pageNumber + 4 >= i && pageNumber - 4 <= i) {
        rows.push(<span key={i} className="noNumbering">.</span>);
      }
    }
    return (
      <div className="pagination">
        <span
          className="arrowLeft"
          onClick={() => setPageNumber(pageNumber > 1 ? pageNumber - 1 : pageNumber)}
        >
          &lang;
        </span>
        {rows}
        <span
          className="arrowRight"
          onClick={() => setPageNumber(pageNumber < numberOfPages ? pageNumber + 1 : pageNumber)}
        >
          &rang;
        </span>
      </div>
    );
  }

  function getColumnNames() {
    return columnNames.concat(fields).map(column => {
      let name;
      if (column === 'comments') {
        name = 'Comm.';
      } else if (column === 'name') {
        name = t('eventName');
      } else {
        name = t(column);
      }
      return name;
    });
  }

  function getEntries() {
    let filteredEvents = eventsToReadable(flattenedList, machines)
      .filter(event => event.status !== 'END')
      .sort((eventA, eventB) => eventB.timestamp - eventA.timestamp);

    filteredEvents = filteredEvents.map(event => {
      const machine = machines.find(m => m.id === event.machineId);
      let causeColor;
      switch (event.type) {
        case 'MachineStatus': {
          const stopCause = findNestedCause(machine?.stopCauses, event.stopCauseId);
          causeColor = stopCause?.color;
          break;
        }
        case 'PerformanceEvent': {
          const performanceCause = findNestedCause(machine?.performanceCauses, event.performanceCauseId);
          causeColor = performanceCause?.color;
          break;
        }
        case 'PartEvent': {
          const defectCause = findNestedCause(machine?.defectCauses, event.defectCauseId);
          causeColor = defectCause?.color;
          break;
        }
        default:
          break;
      }
      return { ...event, causeColor };
    });

    return noPagination ? filteredEvents : filteredEvents
      .slice((pageNumber - 1) * MAX_NB_EVENTS_ONE_PAGE, pageNumber * MAX_NB_EVENTS_ONE_PAGE);
  }

  return (
    <div>
      <div className="header">
        {leftHeader}
        {!noPagination && (
          <div className="topPagination">
            {storageName !== 'K2_default_events' && (
              <>
                {!socketsOn && eventsFeature.isUserAllowedToConfigure() && (
                  <FontAwesome
                    icon="edit"
                    className="editButton"
                    onClick={() => setIsModifying(!isModifying)}
                    style={{ color: isModifying ? '#0078FF' : null }}
                  />
                )}
                <Popover
                  show={showFields}
                  setShow={setShowFields}
                  className="fields-body"
                  content={(
                    <OptionalFields
                      filteredList={filteredList}
                      fields={fields}
                      entriesProperties={entriesProperties}
                      handleFieldsChange={handleFieldsChange}
                      changeFields={changeFields}
                      setShowFields={setShowFields}
                      storageName={storageName}
                    />
                  )}
                >
                  <button
                    type="button"
                    onClick={e => {
                      e.stopPropagation();
                      setShowFields(!showFields);
                      setShowFilter(false);
                    }}
                    className="fieldsButton"
                  >
                    {t('columns')}
                  </button>
                </Popover>
              </>
            )}
            {getPageNavigation()}
          </div>
        )}
      </div>

      <EventsTable
        onModifyEvent={onModifyEvent}
        columnNames={getColumnNames().map(column => {
          let name;
          if (column === 'comments') {
            name = 'Comm.';
          } else if (column === 'name') {
            name = t('eventName');
          } else {
            name = t(column);
          }
          return { name };
        })}
        entriesProperties={[...entriesProperties, ...fields]}
        entries={getEntries()}
        isModifying={isModifying}
        isTimeline={false}
      />
      {!noPagination && (
        <div className="bottomPagination">
          {getPageNavigation()}
        </div>
      )}
    </div>
  );
};

EventsList.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filteredList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  socketsOn: PropTypes.bool.isRequired,
  onModifyEvent: PropTypes.func.isRequired,
  editFormProps: PropTypes.shape({}).isRequired,
  entriesProperties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leftHeader: PropTypes.elementType.isRequired,
  storageName: PropTypes.string.isRequired,
  noPagination: PropTypes.bool,
  setShowFilter: PropTypes.func.isRequired,
  showFields: PropTypes.bool,
  setShowFields: PropTypes.func,
};

EventsList.defaultProps = {
  noPagination: false,
  showFields: false,
  setShowFields: () => {},
};

export { EventsList };
