import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CancelButton, SubmitButton, DefaultModal,
} from '~UI/index';
import { DefaultTable } from '~components/Pages';
import { sortArray } from '~utils/sort';

const MultipleMachinesPopup = ({
  showPopup,
  showMachineList,
  operation,
  validMachines,
  machinesSelected,
  cancelPopup,
  setShowMachineList,
  selectMachine,
}) => {
  const { t } = useTranslation();

  const [displayMachines, setDisplayMachines] = useState(
    validMachines.map(machine => ({ ...machine, isEnabled: false })));

  const handleToggleChange = (id, newChecked) => {
    if (id === 'all') {
      setDisplayMachines(displayMachines.map(machine => ({ ...machine, isEnabled: newChecked })));
      selectMachine(newChecked ? displayMachines.map(machine => machine.id) : []);
    } else {
      setDisplayMachines(
        displayMachines.map(item => (item.id === id ? { ...item, isEnabled: newChecked } : item)),
      );
      selectMachine(newChecked ? [id] : []);
    }
  };

  return (
    <>
      {showPopup && <div className="fade modal-backdrop in" />}
      <DefaultModal
        closePopup={cancelPopup}
        show={showPopup}
        title={showMachineList
          ? t('selectYourMachines')
          : t('modifyMultipleMachines')
        }
        style={{ zIndex: 1500 }}
      >
        {
          !showMachineList ? (
            <div className="ConfirmationText">
              {t('applyToOtherMachines')}
              <div className="actions">
                <button
                  type="button"
                  className="PopupButton cancel"
                  onClick={operation}
                >
                  {t('no')}
                </button>
                <button
                  type="button"
                  className="PopupButton"
                  style={{ backgroundColor: '#0078FF' }}
                  onClick={() => setShowMachineList(true)}
                >
                  {t('yes')}
                </button>
              </div>
            </div>
          ) : (
            <div>
              <DefaultTable
                columnNames={[
                  { name: t('name') },
                ]}
                entriesProperties={['name', 'check']}
                entries={sortArray('alphabetically', displayMachines || [], 'name')}
                toggleOnChange={handleToggleChange}
                noNumbering
              />
              <div className="buttonsHolder flexSpaceBetween">
                <div />
                <div>
                  <CancelButton onClick={cancelPopup} />
                  <SubmitButton label={t('confirm')} onClick={e => operation(e, machinesSelected)} />
                </div>
              </div>
            </div>
          )
        }
      </DefaultModal>
    </>
  );
};

MultipleMachinesPopup.propTypes = {
  showPopup: PropTypes.bool,
  showMachineList: PropTypes.bool.isRequired,
  operation: PropTypes.func.isRequired,
  validMachines: PropTypes.arrayOf(PropTypes.shape({})),
  machinesSelected: PropTypes.arrayOf(PropTypes.shape({})),
  cancelPopup: PropTypes.func.isRequired,
  setShowMachineList: PropTypes.func.isRequired,
  selectMachine: PropTypes.func.isRequired,
};

MultipleMachinesPopup.defaultProps = {
  showPopup: false,
  validMachines: [],
  machinesSelected: [],
};

export default MultipleMachinesPopup;
