import React from 'react';
import PropTypes from 'prop-types';
import JsxWidget from './BaseWidgets/JsxWidget';

const EmptyWidget = ({
  backgroundColor, h, w, x, y, widget,
}) => (
  <JsxWidget
    dimension={{ x, y, w, h }}
    backgroundColor={backgroundColor}
    shape={widget.format.shape}
  >
    <div />
  </JsxWidget>
);

EmptyWidget.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.object,
};
EmptyWidget.defaultProps = {
  widget: {},
};

export { EmptyWidget };
