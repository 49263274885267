import { reduxOperations } from '~services/index';
import { showUpload } from './toast';

const handleFileSelect = (selectedFile, dispatch, t, callback) => {
  showUpload(
    reduxOperations.images.addImage(selectedFile)(dispatch),
    t('uploadingImage'),
    t('showSuccessAdded'),
  )
    .then(res => {
      if (typeof res.payload.data === 'string') {
        callback(res.payload.data);
      }
    });
};

export { handleFileSelect };
