/* eslint-disable brace-style */
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '~services/store';
import { getShiftFromTimestamp, serverTime } from '~utils/time';

const getCurrentShiftForMachine = shifts => {
  const CURRENT_MACHINE_SHIFT_INITIAL_STATE = {
    start: null,
    end: null,
  };
  const currentShift = getShiftFromTimestamp(shifts, Date.now());
  const currShiftForMachine = currentShift || CURRENT_MACHINE_SHIFT_INITIAL_STATE;
  if (!currShiftForMachine.start || !currShiftForMachine.end) return null;
  return currShiftForMachine;
};

const useShift = machineId => {
  const machines = useSelector((state: RootState) => state.machines);
  const shifts = useSelector((state: RootState) => state.shifts.shifts);
  const machine = machines.find(m => m.id === machineId);
  const shiftsToUse = machine?.shifts?.length ? machine.shifts : shifts;
  const [currentShift, setCurrentShift] = useState(getCurrentShiftForMachine(shiftsToUse));
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    const checkShiftChange = () => {
      const now = serverTime();
      // timeUntilShiftEnd until shift ends plus 500ms
      const timeUntilShiftEnd = currentShift?.end ? currentShift.end.getTime() - now + 500 : null;
      // If the shift has ended, change the shift
      if (timeUntilShiftEnd !== null && timeUntilShiftEnd <= 0) {
        const updatedShift = getCurrentShiftForMachine(shiftsToUse);
        setCurrentShift(updatedShift);
      }
      // If the shift will end in less than 5 minutes, set timeout to the end of the shift
      else if (timeUntilShiftEnd !== null && timeUntilShiftEnd < 5 * 60 * 1000) {
        if (intervalRef.current) {
          clearInterval(intervalRef.current as any);
        }
        intervalRef.current = setInterval(checkShiftChange, timeUntilShiftEnd) as any;
      }
      // If the shift will end in more than 5 minutes, set interval to check every 5 minutes
      else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current as any);
        }
        intervalRef.current = setInterval(checkShiftChange, 5 * 60 * 1000) as any;
      }
    };

    if (!currentShift) {
      setCurrentShift(getCurrentShiftForMachine(shiftsToUse));
    }
    checkShiftChange();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current as any);
      }
    };
  }, [currentShift, shiftsToUse]);

  return [currentShift, shiftsToUse];
};

export default useShift;
