import React, { Component } from 'react';
import { Accordion, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import baseFeatures from './features.json';
import formatString from './formatString';

const propTypes = {
  /* List of permissions that are visible so the user can modify and check */
  allFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  /* List of permissions the user already have */
  allowedFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
  /* (features) => void where features is a new list of permissions the user has */
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const parseDbToForm = (all, allowed) => {
  // create an obj of feat-bool pair
  const obj = {};
  all.forEach(feat => { obj[feat] = false; });
  allowed.forEach(feat => { obj[feat] = true; });

  const ret = {};
  Object.keys(baseFeatures).forEach(featGroup => {
    Object.keys(baseFeatures[featGroup]).forEach(feat => {
      // ignore features that are invisible to the user
      if (obj[feat] !== undefined) {
        // initiate featGroup if it is not initiated yet
        if (!ret[featGroup]) {
          ret[featGroup] = {};
        }
        ret[featGroup][feat] = obj[feat];
      }
    });
    // give the group a display name if it is visible
    if (ret[featGroup]) {
      ret[featGroup].id = featGroup;
    }
  });
  return ret;
};

const parseFormToDb = featObj => {
  const ret = [];
  Object.values(featObj).forEach(obj => {
    Object.keys(obj).forEach(key => {
      if (key !== 'id' && obj[key]) {
        ret.push(key);
      }
    });
  });
  return ret;
};

class FeaturePermissionTable extends Component {
  constructor(props) {
    super(props);
    const permObj = parseDbToForm(props.allFeatures, props.allowedFeatures);
    const allReadChecked = Object.keys(permObj).every(featGroup => permObj[featGroup][`${featGroup}_READ`] !== false);
    const allEditChecked = Object.keys(permObj).every(featGroup => permObj[featGroup][`${featGroup}_EDIT`] !== false);
    this.state = {
      permObj,
      allReadChecked,
      allEditChecked,
    };
  }

  componentDidUpdate(prevProps) {
    this.updateAllowedFeaturesIfNeeded(prevProps.allowedFeatures);
  }

  updateAllowedFeaturesIfNeeded = prevAllowedFeatures => {
    const { allowedFeatures, allFeatures } = this.props;
    if (prevAllowedFeatures !== allowedFeatures) {
      const permObj = parseDbToForm(allFeatures, allowedFeatures);
      const allReadChecked = Object.keys(permObj).every(featGroup => permObj[featGroup][`${featGroup}_READ`] !== false);
      const allEditChecked = Object.keys(permObj).every(featGroup => permObj[featGroup][`${featGroup}_EDIT`] !== false);
      this.setState({ permObj, allReadChecked, allEditChecked });
    }
  };

  handleAllReadChange = isChecked => {
    const { onChange } = this.props;
    const { permObj } = this.state;
    Object.keys(permObj).forEach(featGroup => {
      if (permObj[featGroup][`${featGroup}_READ`] !== undefined) {
        permObj[featGroup][`${featGroup}_READ`] = isChecked;
        if (!isChecked && permObj[featGroup][`${featGroup}_EDIT`] !== undefined) {
          permObj[featGroup][`${featGroup}_EDIT`] = false;
        }
      }
    });
    onChange(parseFormToDb(permObj));
    const allEditChecked = this.isAllEditChecked();
    this.setState({ permObj, allReadChecked: isChecked, allEditChecked });
  };

  handleAllEditChange = isChecked => {
    const { onChange } = this.props;
    const { permObj } = this.state;
    Object.keys(permObj).forEach(featGroup => {
      if (permObj[featGroup][`${featGroup}_EDIT`] !== undefined && permObj[featGroup][`${featGroup}_READ`] !== false) {
        permObj[featGroup][`${featGroup}_EDIT`] = isChecked;
      }
      if (isChecked && permObj[featGroup][`${featGroup}_READ`] === false && permObj[featGroup][`${featGroup}_READ`] !== undefined) {
        permObj[featGroup][`${featGroup}_READ`] = isChecked;
        permObj[featGroup][`${featGroup}_EDIT`] = isChecked;
      }
    });
    onChange(parseFormToDb(permObj));
    const allReadChecked = this.isAllReadChecked();
    this.setState({ permObj, allEditChecked: isChecked, allReadChecked });
  };

  isAllReadChecked = () => {
    const { permObj } = this.state;
    return Object.keys(permObj).every(featGroup => permObj[featGroup][`${featGroup}_READ`] !== false);
  };

  isAllEditChecked = () => {
    const { permObj } = this.state;
    return Object.keys(permObj).every(featGroup => permObj[featGroup][`${featGroup}_EDIT`] !== false);
  };

  handleCheckboxChange = (itemId, permission, oldValue) => {
    const { onChange } = this.props;
    const { permObj } = this.state;

    const isChecked = !oldValue;
    if (permObj[itemId] !== undefined) {
      // if you check an EDIT checkbox, it checks the READ too if it exists
      if (permission.includes('EDIT') && isChecked) {
        const readPermission = permission.replace('EDIT', 'READ');
        if (permObj[itemId][readPermission] !== undefined) {
          permObj[itemId][readPermission] = isChecked;
        }
      }
      // if you uncheck a READ checkbox, it unchecks the EDIT too if it exists
      if (permission.includes('READ') && !isChecked) {
        const editPermission = permission.replace('READ', 'EDIT');
        if (permObj[itemId][editPermission] !== undefined) {
          permObj[itemId][editPermission] = isChecked;
        }
      }
      permObj[itemId][permission] = isChecked;
    }

    onChange(parseFormToDb(permObj));
    this.setState({ permObj });

    const allReadChecked = this.isAllReadChecked();
    const allEditChecked = this.isAllEditChecked();
    this.setState({ allReadChecked, allEditChecked });
  };

  renderCheckbox = (featGroup, feat) => {
    const { allFeatures } = this.props;
    const { permObj } = this.state;

    // If the configurator does not have the EDIT permission,
    // prevents from creating a disabled checkbox (which is used for the default READ permissions)
    if (!allFeatures.includes(feat) && feat.includes('EDIT')) {
      return null;
    }

    const value = permObj[featGroup][feat];
    if (value !== undefined) {
      return (
        <Checkbox
          checked={value}
          onChange={() => this.handleCheckboxChange(featGroup, feat, value)}
        />
      );
    }

    return (
      <Checkbox
        checked
        disabled
      />
    );
  };

  render() {
    const { t } = this.props;
    const { permObj, allReadChecked, allEditChecked } = this.state;

    return (
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        blueThemed
        items={[{
          title: t('features'),
          body: (
            <table className="userPermissionTable">
              <thead>
                <tr>
                  <th key="colFeature" />
                  <th key="colRead" style={{ textAlign: 'center' }}>{t('view')}</th>
                  <th key="colWrite" style={{ textAlign: 'center' }}>{t('editPermissions')}</th>
                </tr>
                <tr>
                  <td />
                  <td className="checkboxCell">
                    <Checkbox
                      checked={allReadChecked}
                      onChange={() => this.handleAllReadChange(!allReadChecked)}
                    />
                  </td>
                  <td className="checkboxCell">
                    <Checkbox
                      checked={allEditChecked}
                      onChange={() => this.handleAllEditChange(!allEditChecked)}
                    />
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(permObj).map(featGroup => (
                    <tr key={featGroup}>
                      <td>{t(formatString(featGroup))}</td>
                      <td className="checkboxCell">{this.renderCheckbox(featGroup, `${featGroup}_READ`)}</td>
                      <td className="checkboxCell">{this.renderCheckbox(featGroup, `${featGroup}_EDIT`)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ),
        }]}
      />
    );
  }
}

FeaturePermissionTable.propTypes = propTypes;

export default withTranslation()(FeaturePermissionTable);
