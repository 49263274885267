import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import StopwatchManager from '~utils/StopwatchManager';

import types from './types';

const stopwatchesPropTypes = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  isActive: PropTypes.bool,
  savedAtEpoch: PropTypes.number,
  startTime: PropTypes.number,
  type: PropTypes.string,
}));

function stopwatches(state = null, action) {
  switch (action.type) {
    case types.GET_STOPWATCHES_RESPONSE:
      return action.payload.stopwatches;
    case types.ADD_STOPWATCHES_RESPONSE:
      StopwatchManager.addStopwatch(action.payload);
      return state.concat(action.payload);
    case types.UPDATE_STOPWATCHES_RESPONSE:
      StopwatchManager.updateStopwatch(action.payload);
      if (!state) {
        return state;
      }
      return state.map(stopwatch => (
        (stopwatch.id === action.payload.id)
          ? action.payload
          : stopwatch
      ));
    case types.DELETE_STOPWATCHES_RESPONSE:
      StopwatchManager.deleteStopwatch(action.payload.stopwatchId);
      return state.filter(stopwatch => stopwatch.id !== action.payload.stopwatchId);
    case types.STOPWATCHIO_STOPWATCHES_RESPONSE:
      if (!state) {
        return state;
      }
      return state.map(stopwatch => (
        (stopwatch.id === action.payload.stopwatch.id)
          ? action.payload.stopwatch
          : stopwatch
      ));
    case types.SOCKET_UPDATE_STOPWATCH:
      if (!state) {
        return state;
      }
      return state.map(stopwatch => (
        (stopwatch.id === action.payload.id)
          ? ({ ...stopwatch, ...action.payload.notification })
          : stopwatch
      ));
    default:
      return state;
  }
}

export const propTypes = {
  stopwatches: nullable(stopwatchesPropTypes),
};

export default combineReducers({
  stopwatches,
});
