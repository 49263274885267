import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from '~components/UI/FontAwesome';
import './ConfigurationIcons.scss';

const FavoriteIcon = ({ isFavorite, onClick }) => (
  <div className="ConfigBtns favorite">
    <FontAwesome
      icon="star"
      onClick={onClick}
      size="6x"
      style={{ color: isFavorite ? 'gold' : 'grey' }}
    />
  </div>
);

FavoriteIcon.propTypes = {
  isFavorite: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
};

FavoriteIcon.defaultProps = {
  onClick: () => undefined,
};

export default FavoriteIcon;
