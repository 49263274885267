const PADDING_LEFT = 35;
const PADDING_RIGHT = 35;
const PADDING_LEFT_WITH_ARROWS = 20;
const PADDING_RIGHT_WITH_ARROWS = 20;
const PADDING_TOP = 10;
const PADDING_BOTTOM = 40;
const MAX_BAR_HEIGHT = 30;

const FONT_SIZE_AXISX = '13.5px';

const BLUE = '#0078FF';
const GREEN = '#1E871E';
const DARK_GRAY = '#3A3A3A';
const PALE_GRAY = '#AAA';

export {
  // paddings
  PADDING_LEFT,
  PADDING_RIGHT,
  PADDING_LEFT_WITH_ARROWS,
  PADDING_RIGHT_WITH_ARROWS,
  PADDING_TOP,
  PADDING_BOTTOM,
  MAX_BAR_HEIGHT,
  // axis x
  FONT_SIZE_AXISX,
  // colors
  BLUE,
  GREEN,
  DARK_GRAY,
  PALE_GRAY,
};
