import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getTriggerIcon } from '~utils/icons';
import { DefaultModal, FontAwesome } from '~UI';
import { idToReadableExpr } from '~utils/parser';
import { stopPropagation } from '~utils';
import { reducersTypes } from '~services';

import TriggerConditionForm from './triggers/TriggerConditionsForm';

import './RuleEditionPages.scss';

const TriggerSummaryComponent = ({
  trigger,
  variables,
  streams,
  machines,
  setShowPopupId,
  renderPopup,
  handleSaveConditions,
  closePopup,
  showPopupId,
}) => {
  const { t } = useTranslation();

  const renderConditionPopup = (condition, isAdding) => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    const popupId = isAdding ? `add_${condition.id}` : condition.id;
    return (
      <DefaultModal
        show={showPopupId === popupId}
        title={isAdding ? t('condition') : idToReadableExpr(condition.expression, inputsAndVariables)}
        closePopup={closePopup}
        children={(
          <TriggerConditionForm
            handleSaveConditions={handleSaveConditions}
            handleClose={closePopup}
            condition={condition}
            isAdding={isAdding}
            popupId={popupId}
          />
        )}
      />
    );
  };

  const comparatorHandler = (e, condition) => {
    stopPropagation(e);
    const comparatorList = ['And', 'Or', 'Xor'];
    const newExpression = { ...condition };

    const newListIndex = comparatorList.indexOf(condition.type) + 1;

    if (newListIndex > comparatorList.length - 1) {
      newExpression.type = 'And';
    } else {
      newExpression.type = comparatorList[newListIndex];
    }

    handleSaveConditions(condition.id, newExpression, true);
  };

  const showConditionHandler = (e, id) => {
    stopPropagation(e);
    setShowPopupId(id);
  };

  const removeConditionFromObject = (id, condition) => {
    if (condition.type === 'Expression') {
      const res = condition.id === id ? {} : condition;
      return res;
    }

    let newConditions = condition.conditions.filter(cond => cond.id !== id);
    if (newConditions.length === condition.conditions.length) {
      newConditions = condition.conditions.map(cond => removeConditionFromObject(id, cond));
    }

    if (newConditions.length === 1) {
      return newConditions[0];
    }
    condition.conditions = newConditions;
    return condition;
  };

  const removeCondition = (e, id) => {
    stopPropagation(e);

    const copyTrigger = { ...trigger, condition: { ...trigger.condition } };

    if (copyTrigger.condition.type === 'Expression') {
      handleSaveConditions(id, {}, false);
      return;
    }

    copyTrigger.condition = removeConditionFromObject(id, copyTrigger.condition);
    handleSaveConditions(id, copyTrigger.condition, false);
  };

  const renderComparatorBlock = (condition, size) => {
    if (condition.type === 'and' || condition.type === 'et') {
      condition.type = 'And';
      handleSaveConditions(condition.id, condition, true);
    }

    return (
      <div
        role="button"
        className={size === 'small' ? 'smallComparatorBlock' : 'comparatorBlock'}
        onClick={e => comparatorHandler(e, condition)}
      >
        {t(condition.type.toLowerCase()).toUpperCase()}
      </div>
    );
  };

  const renderConditionBlock = condition => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    if (condition.type === 'Expression') {
      return (
        <div className="conditionBlock">
          <div className="conditionSummary">
            {renderConditionPopup(condition, false)}
            <div
              role="button"
              className="conditionLine"
              onClick={e => showConditionHandler(e, condition.id)}
            >
              <span>{idToReadableExpr(condition.expression, inputsAndVariables)}</span>
              <div
                role="button"
                className="closeButton"
                onClick={e => removeCondition(e, condition.id)}
              >
                <FontAwesome icon="close" />
              </div>
            </div>
          </div>
          {
            trigger.condition.type !== 'Expression' && (
              <>
                {renderConditionPopup(condition, true)}
                <div
                  role="button"
                  className="addCondition small"
                  onClick={e => { e.stopPropagation(); setShowPopupId(`add_${condition.id}`); }}
                >
                  {`+ ${t('addCondition')}`}
                </div>
              </>
            )
          }
        </div>
      );
    }

    return (
      <div className="conditionBlock">
        <div className="conditionSummary">
          {
            condition.conditions.map((subCondition, index) => (
              <>
                {renderConditionPopup(subCondition, false)}
                <div
                  role="button"
                  className="conditionLine"
                  onClick={e => showConditionHandler(e, subCondition.id)}
                >
                  <span>{idToReadableExpr(subCondition.expression, inputsAndVariables)}</span>
                  <span
                    role="button"
                    className="closeButton"
                    onClick={e => removeCondition(e, subCondition.id)}
                  >
                    <FontAwesome icon="close" />
                  </span>
                </div>
                {
                  index !== condition.conditions.length - 1 && (
                    <>
                      {renderComparatorBlock(condition, 'small')}
                      <hr />
                    </>
                  )
                }
              </>
            ))
          }
        </div>
        {renderConditionPopup(condition, true)}
        <div
          role="button"
          className="addCondition small"
          onClick={e => { e.stopPropagation(); setShowPopupId(`add_${condition.id}`); }}
        >
          {`+ ${t('addCondition')}`}
        </div>
      </div>
    );
  };

  const renderCondition = condition => {
    if (condition.type === 'Expression') {
      return (
        <div className="conditionsContainer">
          <div className="empty" />
          <div className="fullwidth conditionsSubContainer">
            <div className="vlSelected" />
            <div className="comparatorBlockEmpty">&nbsp;</div>
            {renderConditionBlock(condition)}
            <div className="vlSelected" />
            {renderConditionPopup(condition, true)}
            <div
              role="button"
              className="addCondition"
              onClick={e => { e.stopPropagation(); setShowPopupId(`add_${condition.id}`); }}
            >
              {`+ ${t('addCondition')}`}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="conditionsContainer">
        <div className="empty" />
        <div className="fullwidth conditionsSubContainer">
          {
            condition.conditions.map((subCondition, index) => (
              <>
                <div className="vlSelected" />
                {
                  index === 0
                    ? <div className="comparatorBlockEmpty">&nbsp;</div>
                    : renderComparatorBlock(condition, 'big')

                }
                {renderConditionBlock(subCondition)}
              </>
            ))
          }

          {renderConditionPopup(condition, true)}
          <div
            role="button"
            className="addCondition"
            onClick={e => { e.stopPropagation(); setShowPopupId(`add_${condition.id}`); }}
          >
            {`+ ${t('addCondition')}`}
          </div>
        </div>
      </div>
    );
  };

  const typeTranslation = {
    DefaultTrigger: t('webhook'),
    ButtonTrigger: t('button'),
    CronTrigger: t('cronJob'),
    MachineParamChangeTrigger: t('machineParam'),
    StopCauseAssignTrigger: t('stopCause'),
    ValueChangeTrigger: t('value'),
    ValueSetTrigger: t('value'),
    WebhookTrigger: t('webhook'),
  };

  return (
    <>
      <div className="summaryTriggerTitle">
        {t('When')}
      </div>
      {!trigger ? (
        <button className="createTriggerContainer" onClick={() => setShowPopupId('trigger')} type="submit">
          <div
            role="button"
            onClick={e => e.stopPropagation()}
          >
            {renderPopup(true, 'trigger')}
          </div>
          <div className="iconTrigger">
            <FontAwesome icon="plus" />
          </div>
          <div className="summary">
            {t('selectTrigger')}
          </div>
        </button>
      ) : (
        <>
          <button
            className="summaryTriggerContainer"
            onClick={e => { e.stopPropagation(); setShowPopupId('trigger'); }}
            type="submit"
          >
            <div
              role="button"
              onClick={e => e.stopPropagation()}
            >
              {renderPopup(true, 'trigger')}
            </div>
            <div className="iconTriggerSummary">
              <FontAwesome icon={getTriggerIcon(trigger.type)} />
            </div>
            <div className="summary">
              {typeTranslation[trigger.type]}
            </div>
          </button>
          {
            isEmpty(trigger.condition) ? (
              <div className="conditionsContainer">
                <div className="empty" />
                <div className="fullwidth conditionsSubContainer">
                  <div className="vlSelected" />
                  {renderConditionPopup({ id: 'firstCondition' }, true)}
                  <div
                    role="button"
                    className="addCondition"
                    onClick={e => { e.stopPropagation(); setShowPopupId('add_firstCondition'); }}
                  >
                    {`+ ${t('addCondition')}`}
                  </div>
                </div>
              </div>
            )
              : renderCondition(trigger.condition)
          }
        </>
      )}
    </>
  );
};

TriggerSummaryComponent.propTypes = {
  trigger: reducersTypes.triggers.trigger.isRequired,
  variables: reducersTypes.variables.isRequired,
  streams: reducersTypes.streams.isRequired,
  machines: reducersTypes.machines.isRequired,
  setShowPopupId: PropTypes.func.isRequired,
  renderPopup: PropTypes.func.isRequired,
  handleSaveConditions: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  showPopupId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    variables: state.variables,
    streams: state.streams,
    machines: state.machines,
  };
}

export default connect(mapStateToProps)(TriggerSummaryComponent);
