import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { invertColor } from '~utils/index';
import { idToReadableExpr, stopwatchIdToReadableExpr } from '~utils/parser';
import { reducersTypes } from '~services/index';

const RuleCard = ({
  ruleCardProps,
  updateCards,
  moveCard,
  index,
  rule,
}) => {
  const {
    currentRule,
    inputsAndVariables,
    stopwatches,
    showCreateARule,
    handleShowARule,
  } = ruleCardProps;
  const { id } = rule;
  const ref = useRef<HTMLInputElement>(null);
  const [, drop] = useDrop({
    accept: 'card',
    drop() {
      updateCards();
    },
    hover(item : {type:string, id: string, index: number}, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current && ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = (clientOffset ? clientOffset.y : 0) - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY / 6) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY * 6) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item: { id, index },
    type: 'card',
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getButtonColor = () => {
    if (rule.contentColor) {
      return invertColor(rule.contentColor);
    } if (rule.color) {
      return invertColor(rule.color);
    }
    return '#fff';
  };

  drag(drop(ref));
  return (
    <div ref={ref}>
      <Button
        className="ruleButton"
        style={{
          backgroundColor: rule.contentColor || rule.color || '#337ab7',
          color: getButtonColor(),
          border: (currentRule === rule && showCreateARule) ? '3px solid white' : '',
        }}
        onClick={() => handleShowARule(rule)}
      >
        {stopwatchIdToReadableExpr(idToReadableExpr(rule.condition, inputsAndVariables), stopwatches)}
      </Button>
    </div>
  );
};

RuleCard.propTypes = {
  index: PropTypes.number.isRequired,
  ruleCardProps: PropTypes.shape({
    currentRule: PropTypes.shape({}),
    inputsAndVariables: PropTypes.arrayOf(PropTypes.shape({})),
    stopwatches: reducersTypes.stopwatches.stopwatches,
    showCreateARule: PropTypes.bool.isRequired,
    handleShowARule: PropTypes.func.isRequired,
  }).isRequired,
  updateCards: PropTypes.func.isRequired,
  moveCard: PropTypes.func.isRequired,
  rule: PropTypes.shape({
    contentColor: PropTypes.string,
    color: PropTypes.string,
    condition: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default RuleCard;
