const invertColor = hex => {
  if (!hex || typeof hex !== 'string') {
    // Invalid hex color
    return '#FFFFFF';
  }

  let hexToUse = hex;
  if (hexToUse.indexOf('#') === 0) {
    hexToUse = hexToUse.slice(1);
  }

  // If the color has transparency, only give the rgb HEX value
  if (hexToUse.length === 8) {
    hexToUse = hexToUse.substr(0, 6);
  }

  // convert 3-digit hex to 6-digits.
  if (hexToUse.length === 3) {
    hexToUse = hexToUse[0] + hexToUse[0] + hexToUse[1] + hexToUse[1] + hexToUse[2] + hexToUse[2];
  }
  if (hexToUse.length !== 6) {
    // Invalid hex color
    return '#FFFFFF';
  }
  const r = parseInt(hexToUse.slice(0, 2), 16);
  const g = parseInt(hexToUse.slice(2, 4), 16);
  const b = parseInt(hexToUse.slice(4, 6), 16);

  return (r * 0.299 + g * 0.587 + b * 0.114) > 160
    ? '#000000'
    : '#FFFFFF';
};

export { invertColor };
