import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CancelButton, SubmitButton, FontAwesome,
  DefaultModal, BasicCard, Cards,
} from '~UI';
import { sortArray } from '~utils/sort';

const MultipleDashboardsPopup = ({
  showPopup, operation, validDashboards, dashboardsSelected,
  cancelPopup, selectDashboard,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {showPopup && <div className="fade modal-backdrop in" />}
      <DefaultModal
        closePopup={cancelPopup}
        show={showPopup}
        title={t('selectYourDashboards')}
        style={{ zIndex: 1500 }}
      >
        <div>
          <Cards>
            {sortArray('alphabetically', validDashboards, 'name')
              .map(dashboard => (
                <BasicCard
                  key={dashboard.id}
                  title={dashboard.name}
                  text={dashboard.description || ' '}
                  icon={<FontAwesome icon="ii-dashboard" size="4x" />}
                  onClick={() => selectDashboard(dashboard.id)}
                  style={dashboardsSelected.includes(dashboard.id) && { boxShadow: '0px 0px 4px 2px #0078FF' }}
                />
              ))
            }
          </Cards>
          <div className="buttonsHolder flexSpaceBetween">
            <div />
            <div>
              <CancelButton onClick={cancelPopup} />
              <SubmitButton label={t('confirm')} onClick={operation} />
            </div>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

MultipleDashboardsPopup.propTypes = {
  showPopup: PropTypes.bool,
  operation: PropTypes.func.isRequired,
  cancelPopup: PropTypes.func.isRequired,
  validDashboards: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dashboardsSelected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectDashboard: PropTypes.func.isRequired,
};
MultipleDashboardsPopup.defaultProps = {
  showPopup: () => { },
};

export default MultipleDashboardsPopup;
