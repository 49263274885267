import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import { useTranslation } from 'react-i18next';
import RuleCard from './RuleCard';
import { reducersTypes } from '~services';

const RuleContainer = ({ arrayOfRules, ruleCardProps, updateRules }) => {
  const { t } = useTranslation();

  const [cards, setCards] = useState(arrayOfRules);
  const numberOfRulesHaveChanged = cards.length !== arrayOfRules.length;
  const typeOfRulesHaveChanged = (arrayOfRules.length > 0 && cards.length > 0)
    && arrayOfRules[0].type !== cards[0].type;

  let idOfRulesHaveChanged = false;
  if (!typeOfRulesHaveChanged) {
    arrayOfRules.forEach(element => {
      const object = cards.find(x => x.id === element.id);
      if (!object) {
        idOfRulesHaveChanged = true;
      }
    });
  }

  if (numberOfRulesHaveChanged || typeOfRulesHaveChanged || idOfRulesHaveChanged) {
    setCards(arrayOfRules);
  }

  const updateCards = () => {
    updateRules(cards);
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [cards],
  );

  const renderCard = (card, index) => (
    <div className="simpleFlex" key={`Div_${index}`}>
      <div key={`ifElse_${index}`} style={{ marginTop: '10px' }}>
        <div className="ifElse">
          {index === 0 ? t('if') : t('elseIf')}
        </div>
      </div>
      <RuleCard
        key={index}
        index={index}
        ruleCardProps={ruleCardProps}
        rule={card}
        moveCard={moveCard}
        updateCards={updateCards}
      />
    </div>
  );

  return <div>{cards.map((card, i) => renderCard(card, i))}</div>;
};

RuleContainer.propTypes = {
  arrayOfRules: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
  })).isRequired,
  ruleCardProps: PropTypes.shape({
    currentRule: PropTypes.shape({}),
    inputsAndVariables: PropTypes.arrayOf(PropTypes.shape({})),
    stopwatches: reducersTypes.stopwatches.stopwatches,
    handleShowARule: PropTypes.func.isRequired,
  }).isRequired,
  updateRules: PropTypes.func.isRequired,
};

export default RuleContainer;
