import { setParamsRequest, setParamsResponse } from '../actions';
import API from '~services/endpoints';

function setParams(machineId, formData) {
  return dispatch => {
    dispatch(setParamsRequest(machineId, formData));
    return API.updateMachineParams(machineId, formData)
      .then(res => {
        if (res) {
          dispatch(setParamsResponse(machineId, res));
        }
      });
  };
}

export { setParams };
