import React from 'react';
import PropTypes from 'prop-types';

import { FormMachineState } from '~components/forms';

const FormTileMachineState = ({ selectedTile, navSelected }) => (
  <FormMachineState
    selectedObject={selectedTile}
    isTile
    navSelected={navSelected}
  />
);

FormTileMachineState.propTypes = {
  selectedTile: PropTypes.object,
  navSelected: PropTypes.string,
};
FormTileMachineState.defaultProps = {
  selectedTile: {},
  navSelected: 'parameters',
};

export default FormTileMachineState;
