import API from '~services/endpoints';
import {
  getTriggersRequest,
  getTriggersResponse,
  addTriggerRequest,
  addTriggerResponse,
  updateTriggerRequest,
  updateTriggerResponse,
  patchTriggerRequest,
  patchTriggerResponse,
  deleteTriggerRequest,
  deleteTriggerResponse,
  executeTriggerRequest,
  executeTriggerResponse,
} from './actions';
import { fetchOperation, fetchOnceOperation } from '../fetchOperation';

const fetchTriggers = () => fetchOnceOperation(
  getTriggersRequest,
  getTriggersResponse,
  API.getTriggers,
  state => state.triggers,
);

const forceFetchTriggers = () => fetchOperation(
  getTriggersRequest,
  getTriggersResponse,
  API.getTriggers,
  state => state.triggers,
);

const addTrigger = trigger => dispatch => {
  dispatch(addTriggerRequest(trigger));
  return API.addTrigger(trigger)
    .then(res => dispatch(addTriggerResponse(res)));
};

const updateTrigger = (triggerId, triggerData) => dispatch => {
  dispatch(updateTriggerRequest(triggerId, triggerData));
  return API.updateTrigger(triggerId, triggerData)
    .then(res => dispatch(updateTriggerResponse(res)));
};

const patchTrigger = (triggerId, triggerData) => dispatch => {
  dispatch(patchTriggerRequest(triggerId, triggerData));
  return API.patchTrigger(triggerId, triggerData)
    .then(res => dispatch(patchTriggerResponse(res)));
};

const deleteTrigger = triggerId => dispatch => {
  dispatch(deleteTriggerRequest(triggerId));
  return API.deleteTrigger(triggerId)
    .then(res => dispatch(deleteTriggerResponse(res)));
};

const executeTrigger = (triggerId, params) => dispatch => {
  dispatch(executeTriggerRequest(triggerId, params));
  return API.executeTrigger(triggerId, params)
    .then(res => dispatch(executeTriggerResponse({ ...res, triggerId })));
};

export default {
  fetchTriggers,
  forceFetchTriggers,
  addTrigger,
  updateTrigger,
  patchTrigger,
  deleteTrigger,
  executeTrigger,
};
