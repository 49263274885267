import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getMetric } from '~components/utils';
import API from '~services/endpoints';
import { FontAwesome } from '~components/UI';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';
import { getSocket } from '~services/socket';
import { getTitle } from '~utils';
import { useShift } from '~utils/hooks';
import { dateToStringWithoutYearAndMillis, serverTime, getDataRangeStartEnd } from '~utils/time';
import Tick from '~utils/Tick';
import { round } from '~utils/math';

import CircularChart from './CircularChartSVG';

const CircularChartVariables = ({ selectedObject, dimension, textColor, isTile }) => {
  const socket = getSocket();

  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);
  const featureToggles = useSelector(state => state.settings.settings.featureToggles);
  const language = useSelector(state => state.views.language);
  const { t } = useTranslation();

  const properties = [].concat(...streams.map(s => s.properties));
  const machinesKPIS = [].concat(...machines.map(m => m.kpis));
  const all = [...properties, ...variables, ...machinesKPIS];
  const initialValue = all.find(elem => elem.id === selectedObject.valueId);
  const [currentShift, shifts] = useShift(selectedObject.machineId);

  const [values, setValues] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);
  const [value, setValue] = useState(initialValue);
  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const [goal, setGoal] = useState(selectedObject.goal);

  const title = featureToggles.features?.tileTranslations ? getTitle(selectedObject, language) : selectedObject?.title || '';

  const filterValues = () => {
    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
    setValues(prevValues => prevValues.filter(v => v.timestamp >= start && v.timestamp <= end));
  };

  const fetchVariables = async () => {
    if (selectedObject.isAggregated) {
      const { start, end } = timePeriod;

      const { values: newValues } = await API.getValues(selectedObject.valueId,
        { timestamp: { $gte: start, $lt: end } });

      setValues(newValues);
      filterValues();
    } else {
      API.getValues(selectedObject.valueId, {}, 1).then(response => {
        setDisplayedValue(response && response.values.length && round(response.values[0].value, 2));
      });
    }
  };

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, selectedObject.intervalType || 'shift');
    setValues([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  const handleSocketData = socketData => {
    if (socketData.id === selectedObject.valueId) {
      if (selectedObject.isAggregated) {
        const newValue = {
          timestamp: serverTime(),
          valueId: selectedObject.valueId,
          value: socketData.value,
        };
        setValues(prevValues => [...prevValues, newValue]);
        filterValues();
      } else {
        setDisplayedValue(round(socketData.value, 2));
      }
    }
    if (selectedObject.objectiveType === 'variable' && socketData.id === selectedObject.goal) {
      const goalToSet = round(socketData.value, 2);
      if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
        setGoal(goalToSet);
        return;
      }
      setGoal(-1);
    }
  };

  const evaluateVariables = () => {
    let aggregatedValue = null;

    if (values.length) {
      switch (selectedObject.aggregateType) {
        case 'sum':
          aggregatedValue = (values.reduce((prev, curr) => (prev + curr.value), 0)).toFixed(2);
          break;
        case 'min':
          aggregatedValue = Math.min(...values.map(v => v.value)).toFixed(2);
          break;
        case 'max':
          aggregatedValue = Math.max(...values.map(v => v.value)).toFixed(2);
          break;
        default:
          aggregatedValue = (values.reduce((prev, curr) => (prev + curr.value), 0)).toFixed(2);
          break;
      }
    }
    const roundedValue = aggregatedValue ? round(aggregatedValue, 1) : '';
    setDisplayedValue(roundedValue);
  };

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  useEffect(() => {
    Tick.unsubscribe(() => filterValues());
    if (selectedObject.isAggregated) {
      // We have to unsubscribe/subscribe because if the Tick was not started before, we have to do it now
      Tick.subscribe(() => filterValues(), 5); // 5 seconds
    }
    const newProperties = [].concat(...streams.map(s => s.properties));
    const newMachinesKPIS = [].concat(...machines.map(m => m.kpis));
    const newAll = [...newProperties, ...variables, ...newMachinesKPIS];
    const newValue = newAll.find(elem => elem.id === selectedObject.valueId);
    setValue(newValue);

    return () => {
      Tick.unsubscribe(() => filterValues());
    };
  }, [selectedObject, currentShift]);

  useEffect(() => {
    const listener = data => handleSocketData(data);
    socket.on('value', listener);
    if (value) {
      fetchVariables();
    }

    if (selectedObject.objectiveType === 'variable' && selectedObject.goal) {
      API.getValues(selectedObject.goal, {}, 1).then(response => {
        const goalToSet = response?.values[0] && round(response.values[0].value, 2);
        if (goalToSet >= selectedObject.intervalMin && goalToSet <= selectedObject.intervalMax) {
          setGoal(goalToSet);
          return;
        }
        setGoal(-1);
      });
    } else {
      setGoal(selectedObject.goal);
    }

    return () => {
      if (socket) {
        socket.removeListener('value', listener);
      }
    };
  }, [socket, selectedObject, currentShift]);

  useEffect(() => {
    if (value) {
      fetchVariables();
    }
  }, [value, timePeriod, hasUsedArrows]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [selectedObject.intervalType]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(selectedObject.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [selectedObject, selectedObject.intervalType, currentShift]);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 20); // 20 secondes

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    evaluateVariables();
  }, [values]);

  const getVariableMetric = () => {
    const isKPI = machinesKPIS.find(kpi => kpi.id === value.id);
    if (isKPI) {
      return getMetric(value.name);
    }
    return 'unit';
  };

  if (!value) {
    return <div style={{ fontSize: '25px' }}>{t('variableDeleted')}</div>;
  }

  const navigationText = hasUsedArrows
    ? `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.start))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.end))}`
    : '';

  return (
    <div className={`timeNavigationContainer ${!values && 'hide'}`}>
      <div>{navigationText}</div>
      <div className="timeNavigation">
        {selectedObject.showArrows && (
          <FontAwesome
            icon="arrow-left"
            className="timeNavigationButton"
            style={{
              opacity: !canNavigateTime(true, timePeriod, shifts, selectedObject.intervalType || 'shift') && 0.4,
              cursor: !canNavigateTime(true, timePeriod, shifts, selectedObject.intervalType || 'shift') && 'default',
            }}
            onClick={() => navigateTime(true)}
          />
        )}
        <CircularChart
          currentValue={displayedValue}
          selectedObject={selectedObject}
          interval={getVariableMetric() === 'percentage'
            ? { min: selectedObject.intervalMin / 100, max: selectedObject.intervalMax / 100 }
            : { min: selectedObject.intervalMin, max: selectedObject.intervalMax }}
          metric={getVariableMetric()}
          dimension={dimension}
          textColor={textColor}
          showGoalToggle={
            typeof selectedObject.showGoalToggle === 'boolean'
              ? selectedObject.showGoalToggle
              : typeof selectedObject.goal === 'number'
          } // for backward compatibility with no toggle
          goal={getVariableMetric() === 'percentage' ? goal / 100 : goal}
          units={selectedObject.units}
          isTile={isTile}
          title={title}
          aboveGoalColor={selectedObject.aboveGoalColor}
          belowGoalColor={selectedObject.belowGoalColor}
          showArrows={selectedObject.showArrows}
        />
        {selectedObject.showArrows && (
          <FontAwesome
            icon="arrow-right"
            className="timeNavigationButton"
            style={{
              opacity: !canNavigateTime(false, timePeriod, shifts, selectedObject.intervalType || 'shift') && 0.4,
              cursor: !canNavigateTime(false, timePeriod, shifts, selectedObject.intervalType || 'shift') && 'default',
            }}
            onClick={() => navigateTime(false)}
          />
        )}
      </div>
    </div>
  );
};

CircularChartVariables.propTypes = {
  selectedObject: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    objectiveType: PropTypes.string,
    goal: PropTypes.number,
    aboveGoalColor: PropTypes.string,
    belowGoalColor: PropTypes.string,
    id: PropTypes.string,
    machineId: PropTypes.string,
    title: PropTypes.string,
    valueType: PropTypes.string,
    valueId: PropTypes.string,
    intervalMin: PropTypes.number,
    intervalMax: PropTypes.number,
    intervalType: PropTypes.string,
    units: PropTypes.string,
    isAggregated: PropTypes.bool,
    aggregateType: PropTypes.string,
    showArrows: PropTypes.bool,
  }).isRequired,
  isTile: PropTypes.bool,
  dimension: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
};
CircularChartVariables.defaultProps = {
  isTile: false,
};

export default CircularChartVariables;
