import types from './types';

// Topview

const createTopviewRequest = data => ({
  type: types.CREATE_TOPVIEW_REQUEST,
  payload: data,
});

const createTopviewResponse = (data, request = null as unknown) => ({
  type: types.CREATE_TOPVIEW_RESPONSE,
  payload: data,
  request,
});

const readTopviewsRequest = () => ({
  type: types.GET_TOPVIEWS_REQUEST,
  payload: null,
});

const readTopviewsResponse = (data, request = null) => ({
  type: types.GET_TOPVIEWS_RESPONSE,
  payload: data,
  request,
});

const updateTopviewRequest = data => ({
  type: types.UPDATE_TOPVIEW_REQUEST,
  payload: data,
});

const updateTopviewResponse = (data, request = null as unknown) => ({
  type: types.UPDATE_TOPVIEW_RESPONSE,
  payload: data,
  request,
});

const deleteTopviewRequest = data => ({
  type: types.DELETE_TOPVIEW_REQUEST,
  payload: data,
});

const deleteTopviewResponse = (data, request = null as unknown) => ({
  type: types.DELETE_TOPVIEW_RESPONSE,
  payload: data,
  request,
});

// Widget

const createWidgetRequest = data => ({
  type: types.CREATE_WIDGET_REQUEST,
  payload: data,
});

const createWidgetResponse = (data, request = null as unknown) => ({
  type: types.CREATE_WIDGET_RESPONSE,
  payload: data,
  request,
});

const updateWidgetRequest = data => ({
  type: types.UPDATE_WIDGET_REQUEST,
  payload: data,
});

const updateWidgetResponse = (data, request = null as unknown) => ({
  type: types.UPDATE_WIDGET_RESPONSE,
  payload: data,
  request,
});

const deleteWidgetRequest = data => ({
  type: types.DELETE_WIDGET_REQUEST,
  payload: data,
});

const deleteWidgetResponse = (data, request = null as unknown) => ({
  type: types.DELETE_WIDGET_RESPONSE,
  payload: data,
  request,
});

export {
  // Topview
  createTopviewRequest,
  createTopviewResponse,
  readTopviewsRequest,
  readTopviewsResponse,
  updateTopviewRequest,
  updateTopviewResponse,
  deleteTopviewRequest,
  deleteTopviewResponse,
  // Widget
  createWidgetRequest,
  createWidgetResponse,
  updateWidgetRequest,
  updateWidgetResponse,
  deleteWidgetRequest,
  deleteWidgetResponse,
};
