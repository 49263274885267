const TITLE_HEIGHT_RATIO = 0.16;
const UNITS_HEIGHT_RATIO = 0.23;
const MACHINE_STATE_TIME_HEIGHT_RATIO = 0.15;
const MACHINE_STATE_PARAMS_HEIGHT_RATIO = 0.20;
const MACHINE_STATE_ICON_HEIGHT_RATIO = 0.50;

const MACHINE_STATE_WIDGET_TIME_HEIGHT_RATIO = 0.50;
const MACHINE_STATE_WIDGET_PARAMS_HEIGHT_RATIO = 0.10;

const HEIGHT_RATIO = 1.7;
const WIDTH_RATIO = 0.7;

const LARGE_DISPLAY_SIZE = 1.4;
const SMALL_DISPLAY_SIZE = 0.7;

// We calculate the font size by dividing the tile width by the number of letters to give us the width of one letter
// The result gives us the width of the largest letter: 'W'
// But actually, the average letter width in a lorem ipsum is around 55% of the 'W' (in our font)
// So we add a ratio to make the text bigger and to better take into account what the user types into the tile
const AVERAGE_LETTER_SIZE_RATIO = 0.65;

const defaultFontSize = {
  valueFontSize: 60,
  unitsFontSize: 30,
  title: 18,
};

const zoomToDisplaySize = zoom => {
  let displaySize = 1;
  if (zoom === 'large') {
    displaySize = LARGE_DISPLAY_SIZE;
  } else if (zoom === 'small') {
    displaySize = SMALL_DISPLAY_SIZE;
  }
  return displaySize;
};

const splitValue = (value, index) => [value.substring(0, index), value.substring(index)];

const splitStringInto2WithSpace = str => {
  if (!str.includes(' ')) { return [str]; }

  const middle = Math.round(str.length / 2);
  let i = 0;
  while (i < middle) {
    if (str[middle + i] === ' ') {
      return splitValue(str, middle + i);
    }
    if (str[middle - i] === ' ') {
      return splitValue(str, middle - i);
    }
    i += 1;
  }

  return [str];
};

const increaseFontSizeWithWrap = (text, height, width, displaySize) => {
  let splitText = splitStringInto2WithSpace(text);
  const longestString = splitText.reduce((a, b) => (a.length > b.length ? a : b));
  let widthFontSize = width / (longestString.length * AVERAGE_LETTER_SIZE_RATIO);
  let heightFontSize = height / splitText.length;

  if (splitText.length > 1 && heightFontSize > widthFontSize * 2) {
    splitText = splitText[0].length > splitText[1].length
      ? splitStringInto2WithSpace(splitText[0]).concat(splitText[1])
      : [splitText[0]].concat(splitStringInto2WithSpace(splitText[1]));

    const longestString2 = splitText.reduce((a, b) => (a.length > b.length ? a : b));
    widthFontSize = width / (longestString2.length * AVERAGE_LETTER_SIZE_RATIO);
    heightFontSize = height / splitText.length;
  }
  return {
    valueFontSize: Math.round(Math.min(widthFontSize, heightFontSize) * displaySize),
    wrappedText: splitText || text,
  };
};

const getValueFontSize = (text, dim, units, wrap, displaySize) => {
  const valueContainerHeight = units
    ? dim.height - (dim.height * TITLE_HEIGHT_RATIO) - (dim.height * UNITS_HEIGHT_RATIO)
    : dim.height - (dim.height * TITLE_HEIGHT_RATIO);
  const heightFontSize = valueContainerHeight / HEIGHT_RATIO;
  const widthFontSize = dim.width / (text.length * AVERAGE_LETTER_SIZE_RATIO);
  const fontSize = Math.min(heightFontSize, widthFontSize);

  if (wrap && (heightFontSize > widthFontSize)) {
    return increaseFontSizeWithWrap(text, valueContainerHeight, dim.width, displaySize);
  }
  return {
    valueFontSize: Math.round(fontSize * displaySize),
    wrappedText: wrap ? [text] : text,
  };
};

const getValueFontSizeNOPADDING = (text, width, height, numberOfLines, zoom) => {
  const displaySize = zoomToDisplaySize(zoom);
  const heightFontSize = (height / HEIGHT_RATIO) / numberOfLines;
  const widthFontSize = width / (text.length * AVERAGE_LETTER_SIZE_RATIO);
  const fontSize = Math.min(heightFontSize, widthFontSize);
  return Math.round(fontSize * displaySize) * 1.2;
};

const getUnitsFontSize = (dim, units) => {
  const unitsContainerHeight = dim.height * UNITS_HEIGHT_RATIO;

  const heightFontSize = unitsContainerHeight / HEIGHT_RATIO;

  const widthFontSize = dim.width / units.length;
  return Math.min(widthFontSize, heightFontSize);
};

const getFontSizeToFitTile = (height, width, text, units, wrap, zoom, metric) => {
  const textToDisplay = text || '';
  const dim = { height, width };
  const displaySize = zoomToDisplaySize(zoom);

  if (!dim) {
    return {
      valueFontSize: defaultFontSize.valueFontSize * displaySize,
      unitsFontSize: defaultFontSize.unitsFontSize * displaySize,
      wrappedText: wrap ? [textToDisplay] : textToDisplay,
    };
  }
  const { valueFontSize, wrappedText } = getValueFontSize(textToDisplay.toString(), dim, units, wrap, displaySize);
  const unitsFontSize = units ? getUnitsFontSize(dim, units) * displaySize : 0;
  const metricFontSize = metric ? getUnitsFontSize(dim, metric) * displaySize * 0.8 : 0;
  return {
    valueFontSize,
    unitsFontSize,
    wrappedText,
    metricFontSize,
  };
};

const getApproximateFontSize = (text = '', width, height = Number.POSITIVE_INFINITY, zoom = undefined) => getValueFontSize(text, { width, height }, undefined, undefined, zoomToDisplaySize(zoom)).valueFontSize;

const getTitleFontSize = (height, width, title) => {
  if (title) {
    const dim = { height, width };
    if (dim) {
      const titleContainerHeight = dim.height * TITLE_HEIGHT_RATIO;
      const heightFontSize = titleContainerHeight / HEIGHT_RATIO;
      const widthFontSize = dim.width / title.length;
      return Math.min(widthFontSize, heightFontSize);
    }
  }
  return defaultFontSize.title;
};

const getButtonFontSize = (height, width, zoom) => {
  const dim = { height, width };
  const displaySize = zoomToDisplaySize(zoom);
  if (!dim) { return 40; }

  const titleContainerHeight = dim.height - (dim.height * TITLE_HEIGHT_RATIO);

  // 15 is the title minimum height. % don't apply when the tile is very small
  const heightFontSize = titleContainerHeight * WIDTH_RATIO - defaultFontSize.title;

  const widthFontSize = dim.width * WIDTH_RATIO - 10;

  return Math.min(widthFontSize, heightFontSize) * displaySize;
};

const getTimerFontSize = (height, width, text, zoom, displayButtons) => {
  const dim = { height, width };
  const displaySize = zoomToDisplaySize(zoom);
  if (!dim) { return 40 * displaySize; }

  // buttons are given 25% of the total size
  const heightFontSize = dim.height - dim.height * (TITLE_HEIGHT_RATIO + (displayButtons ? 0.25 : 0));
  // 16 is the padding, 10 is the margin
  const buttonHeightFontSize = dim.height - dim.height * 0.75 - 16 - 10;

  // We remove the ":" from the text length for a more accurate width
  let textLength = text.length;
  if (text.length < 6) {
    textLength = 4;
  } else {
    textLength = 6;
  }

  const widthFontSize = dim.width / textLength;
  const buttonWidthFontSize = (dim.width * AVERAGE_LETTER_SIZE_RATIO) / 15;

  // We cap the button font size between 15 and 45
  return {
    timerFontSize: Math.min(widthFontSize, heightFontSize) * displaySize,
    buttonFontSize: Math.max(Math.min(buttonWidthFontSize, buttonHeightFontSize, 45), 15) * displaySize,
  };
};

const getMachineStateSize = (height, width, time, shownMachineParams) => {
  const dim = { height, width };
  if (!dim) { return 40; }

  const paramsLength = shownMachineParams.includes('operation') ? shownMachineParams.length + 1 : shownMachineParams.length;
  const rearranged = dim.width < 130 * paramsLength;

  const iconContainerHeight = dim.height - (dim.height * TITLE_HEIGHT_RATIO)
    - (dim.height * MACHINE_STATE_TIME_HEIGHT_RATIO) - (dim.height * MACHINE_STATE_PARAMS_HEIGHT_RATIO);
  const timeContainerHeight = dim.height - (dim.height * TITLE_HEIGHT_RATIO)
    - (dim.height * MACHINE_STATE_ICON_HEIGHT_RATIO) - (dim.height * MACHINE_STATE_PARAMS_HEIGHT_RATIO);

  const iconHeightFontSize = iconContainerHeight / 1.9 - defaultFontSize.title;
  const timeHeightFontSize = timeContainerHeight / 1.3 - defaultFontSize.title;

  const timeWidthFontSize = (dim.width / time) - 10;
  const iconWidthFontSize = dim.width * WIDTH_RATIO - 10;

  const iconSize = dim.height > 150 ? Math.min(iconWidthFontSize, iconHeightFontSize) : 0;
  const timeSize = paramsLength
    ? Math.min(timeWidthFontSize, timeHeightFontSize, 50) * 1.3
    : Math.min(timeWidthFontSize, timeHeightFontSize, 50) * 1.4;

  return {
    iconSize,
    timeSize: Math.max(dim.width > 170 && iconSize < 8 && !paramsLength ? 30 : timeSize, 23),
    rearranged,
  };
};

const getMachineStateWidgetSize = (width, height, displayButtons, maxLength) => {
  const dim = { width, height };

  const paramsHeightFontSize = dim.height * MACHINE_STATE_WIDGET_PARAMS_HEIGHT_RATIO;
  const timeHeightFontSize = dim.height * MACHINE_STATE_WIDGET_TIME_HEIGHT_RATIO;

  // 6 is the text length (hhmmss)
  const timeWidthFontSize = (dim.width / 6) - 10;
  const paramsWidthFontSize = dim.width / (maxLength * AVERAGE_LETTER_SIZE_RATIO);

  const paramsSize = Math.min(paramsHeightFontSize, paramsWidthFontSize);
  const timeSize = displayButtons
    ? Math.min(timeWidthFontSize, timeHeightFontSize)
    : Math.min(timeWidthFontSize, timeHeightFontSize) * 1.4;

  return {
    paramsSize: Math.min(paramsSize, timeSize),
    timeSize: Math.max(timeSize, 21),
  };
};

/**
 * get the font size that will let text takes the maximum of the space without going out
 * @param {string} text the text to be displayed
 * @returns {number} font size in px
 */
const fitText = (
  text = '',
  width = Number.POSITIVE_INFINITY,
  height = Number.POSITIVE_INFINITY,
  zoom = 1,
) => {
  const ratio = typeof zoom === 'number' ? zoom : zoomToDisplaySize(zoom);
  return Math.min(height, width / `${text}`.length / AVERAGE_LETTER_SIZE_RATIO) * ratio;
};

export {
  getFontSizeToFitTile,
  getApproximateFontSize,
  getTitleFontSize,
  getButtonFontSize,
  getTimerFontSize,
  getValueFontSize,
  getValueFontSizeNOPADDING,
  increaseFontSizeWithWrap,
  splitStringInto2WithSpace,
  getMachineStateSize,
  getMachineStateWidgetSize,
  fitText,
};
