import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { CheckboxToggle, FontAwesome } from '~UI/index';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { sortArray } from '~utils/sort';
import './ActionForm.scss';

const PerformanceActionForm = ({ action }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);

  const preSelectPerformanceCause = machineIdArg => {
    const selectedMachine = machines.find(m => m.id === machineIdArg);
    let newPerformanceCauses = selectedMachine && selectedMachine.performanceCauses;
    let newIsSubMenu = false;

    if (selectedMachine && newPerformanceCauses.length) {
      const newselectedPerformanceCauseId = action.params.performanceCauseId;
      newPerformanceCauses.forEach(topCause => {
        topCause.subMenu.forEach(subCause => {
          if (subCause.id === newselectedPerformanceCauseId) {
            newIsSubMenu = true;
            newPerformanceCauses = topCause.subMenu;
          }
        });
      });
      return {
        selectedPerformanceCauseId: newselectedPerformanceCauseId,
        performanceCauses: newPerformanceCauses,
        isSubMenu: newIsSubMenu,
      };
    }
    return { selectedPerformanceCauseId: '', performanceCauses: null, isSubMenu: newIsSubMenu };
  };

  const { machineId: initMachineId } = action.params;
  const {
    selectedPerformanceCauseId: initSelectedPerformanceCauseId,
    performanceCauses: initPerformanceCauses,
    isSubMenu: initIsSubMenu,
  } = preSelectPerformanceCause(initMachineId);

  const [machineId, setMachineId] = useState(initMachineId);
  const [performanceCauses, setPerfomanceCauses] = useState(initPerformanceCauses);
  const [selectedPerformanceCauseId, setSelectedPerformanceCauseId] = useState(initSelectedPerformanceCauseId);
  const [isSubMenu, setIsSubMenu] = useState(initIsSubMenu);
  const [checkboxPerformanceCauseEnabled,
    setCheckboxPerformanceCauseEnabled] = useState(!!initSelectedPerformanceCauseId);
  const [status, setStatus] = useState(action.params.status || 'START');

  const handlePerformanceCauseSelection = performanceCause => {
    const selectedMachine = machines.find(m => m.id === machineId);

    if (!performanceCause) {
      setSelectedPerformanceCauseId('');
      setIsSubMenu(false);
      setPerfomanceCauses(selectedMachine && selectedMachine.performanceCauses);
    } else if (performanceCause.subMenu && performanceCause.subMenu.length) {
      setPerfomanceCauses(performanceCause.subMenu);
      setIsSubMenu(true);
    } else {
      setSelectedPerformanceCauseId(performanceCause.id);
    }
  };

  const handleChangeCheckboxPerformanceCause = () => {
    const selectedMachine = machines.find(m => m.id === machineId);
    const newPerformanceCauses = selectedMachine && selectedMachine.performanceCauses;

    setCheckboxPerformanceCauseEnabled(prevCheckboxEnabled => !prevCheckboxEnabled);
    setSelectedPerformanceCauseId(prevId => (!checkboxPerformanceCauseEnabled ? prevId : ''));
    setPerfomanceCauses(prevCauses => (!checkboxPerformanceCauseEnabled ? prevCauses : newPerformanceCauses));
    setIsSubMenu(prevIsSubMenu => (!checkboxPerformanceCauseEnabled ? prevIsSubMenu : false));
  };

  const handleMachineIdChange = e => {
    const newMachineId = e.value;
    const {
      selectedPerformanceCauseId: newSelectedPerformanceCauseId,
      performanceCauses: newPerformanceCauses,
      isSubMenu: newIsSubMenu,
    } = preSelectPerformanceCause(newMachineId);
    setMachineId(newMachineId);
    setIsSubMenu(newIsSubMenu);
    setSelectedPerformanceCauseId(newSelectedPerformanceCauseId);
    setPerfomanceCauses(newPerformanceCauses);
  };

  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  const buttons = [{
    label: t('start'),
    value: 'START',
  },
  {
    label: t('end'),
    value: 'END',
  }];

  return (
    <>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('eventName')}</div>
      <Input
        defaultValue={action.params.name}
        name="name"
        placeholder="Event Name"
      />

      <div className="inputTitle" style={{ display: 'inline-block', marginRight: '8px' }}>{`${t('status')} :`}</div>
      <ButtonGroup
        buttons={buttons}
        name="status"
        onChange={newStatus => setStatus(newStatus)}
        value={status}
      />

      {
        performanceCauses && performanceCauses.length && status === 'START' && (
          <>
            <div className="inputTitle">
              {t('performanceDropCause')}
              &nbsp;
              <CheckboxToggle
                controlledCheck={checkboxPerformanceCauseEnabled}
                onChange={handleChangeCheckboxPerformanceCause}
              />
            </div>
            <div style={{ display: checkboxPerformanceCauseEnabled ? 'block' : 'none' }}>
              <input type="hidden" name="performanceCauseId" value={selectedPerformanceCauseId} />
              {
                isSubMenu && (
                  <FontAwesome
                    icon="arrow-left"
                    className="backButtonArrow"
                    style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
                    onClick={() => handlePerformanceCauseSelection(null)}
                  />
                )
              }
              {
                performanceCauses.map(performanceCause => (
                  <StopCauseIcon
                    key={performanceCause.id}
                    option={performanceCause}
                    small
                    className={(performanceCause.id === selectedPerformanceCauseId) ? 'Selected' : 'Unselected'}
                    onClick={() => handlePerformanceCauseSelection(performanceCause)}
                  />
                ))
              }
            </div>
          </>
        )
      }
    </>
  );
};

PerformanceActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      performanceCauseId: PropTypes.string,
      machineId: PropTypes.string,
      status: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  }),
};
PerformanceActionForm.defaultProps = {
  action: {
    params: {},
  },
};

export default PerformanceActionForm;
