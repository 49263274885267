import { Dispatch } from 'redux';
import {
  getActions as APIgetActions,
  createAction as APIcreateAction,
  updateAction as APIupdateAction,
  deleteAction as APIdeleteAction,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createActionRequest,
  createActionResponse,
  getActionsRequest,
  getActionsResponse,
  updateActionRequest,
  updateActionResponse,
  deleteActionRequest,
  deleteActionResponse,
  ActionAction,
} from './actions';
import { Action } from './types';

type ActionDispatch = Dispatch<ActionAction>;

export const fetchActions = () => fetchOnceOperation(
  getActionsRequest,
  getActionsResponse,
  APIgetActions,
  state => state.actions,
);

export const createAction = (action: Partial<Action>) => (dispatch: ActionDispatch) => {
  dispatch(createActionRequest(action));
  return APIcreateAction(action)
    .then(res => dispatch(createActionResponse(res)));
};

export const updateAction = (id: string, action: Action) => (dispatch: ActionDispatch) => {
  dispatch(updateActionRequest(id, action));
  return APIupdateAction(id, action)
    .then(res => dispatch(updateActionResponse(res)));
};

export const deleteAction = actionId => (dispatch: ActionDispatch) => {
  dispatch(deleteActionRequest(actionId));
  return APIdeleteAction(actionId)
    .then(res => dispatch(deleteActionResponse(res)));
};

export default {
  fetchActions,
  createAction,
  updateAction,
  deleteAction,
};
