import { serverTime, getShiftFromTimestamp } from '~utils/time';
import { configurationFeature } from '~utils/featureToggles';

// Checks that you can not go in the future and more than 3 days in the past
const canNavigateTime = (goBackward, timePeriod, shifts, intervalType) => {
  const currentTS = serverTime();
  const oneHour = 3600 * 1000;
  const oneDay = oneHour * 24;
  const daysBackAllowed = configurationFeature.isUserAllowedAccessAdmin() ? Infinity : 7; // one week

  switch (intervalType) {
    case 'shift': {
      const shiftCursor = goBackward ? timePeriod.start - 1 : timePeriod.end + 1;
      const shift = getShiftFromTimestamp(shifts, shiftCursor);
      if (goBackward) {
        const difference = currentTS - timePeriod.start;
        return Math.floor(difference / oneHour) <= (daysBackAllowed * 24);
      }
      return shift && shift.start < currentTS;
    }
    case 'lastHour':
      if (goBackward) {
        const difference = currentTS - (timePeriod.start - oneHour);
        return Math.floor(difference / oneHour) <= (daysBackAllowed * 24);
      }
      return timePeriod.end + oneHour <= currentTS;
    case 'last24Hours':
      if (goBackward) {
        const difference = currentTS - (timePeriod.start - oneDay);
        return Math.floor(difference / oneDay) < (daysBackAllowed + 1);
      }
      return timePeriod.end + oneDay <= currentTS;
    default:
      break;
  }
};

// Navigates forward or backward in time by returning a new time period
// Also indicates if the new time is "Live" or in the past
const navigateTimeUtil = (goBackward, timePeriod, shifts, intervalType) => {
  const oneHour = 3600 * 1000;
  const oneDay = oneHour * 24;
  const newTimePeriod = { ...timePeriod };

  switch (intervalType) {
    case 'shift': {
      const shiftCursor = goBackward ? timePeriod.start - 1 : timePeriod.end + 1;
      if (canNavigateTime(goBackward, timePeriod, shifts, intervalType)) {
        const newShift = getShiftFromTimestamp(shifts, shiftCursor);
        newTimePeriod.start = newShift.start.getTime();
        newTimePeriod.end = newShift.end.getTime();
      }
      break;
    }
    case 'lastHour':
      if (canNavigateTime(goBackward, timePeriod, shifts, intervalType)) {
        newTimePeriod.start = goBackward ? timePeriod.start - oneHour : timePeriod.start + oneHour;
        newTimePeriod.end = goBackward ? timePeriod.end - oneHour : timePeriod.end + oneHour;
      }
      break;
    case 'last24Hours':
      if (canNavigateTime(goBackward, timePeriod, shifts, intervalType)) {
        newTimePeriod.start = goBackward ? timePeriod.start - oneDay : timePeriod.start + oneDay;
        newTimePeriod.end = goBackward ? timePeriod.end - oneDay : timePeriod.end + oneDay;
      }
      break;
    default:
      break;
  }

  // If server time is after the end of the graph
  const hasUsedArrows = canNavigateTime(false, newTimePeriod, shifts, intervalType);

  return { newTimePeriod, hasUsedArrows };
};

export { canNavigateTime, navigateTimeUtil };
