import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { invertColor } from '~utils/index';
import './PushButton.scss';

function isUrl(testString: string) {
  try {
    const url = new URL(testString);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (error) {
    return false;
  }
}

const PushButton = ({
  buttonSize, buttonColor, buttonText, onClick, fontSize,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [srcIsAnImage, setSrcIsAnImage] = useState(false);
  const [eventHandled, setEventHandled] = useState(false);

  const handlePointerDown = () => {
    setIsActive(true);
    setEventHandled(false);
  };

  const handlePointerUp = event => {
    if (!eventHandled) {
      setIsActive(false);
      onClick();
      setEventHandled(true);
    }
    event.stopPropagation();
  };

  const handleTouchStart = event => {
    event.preventDefault();
    handlePointerDown();
  };

  const handleTouchEnd = event => {
    event.preventDefault();
    handlePointerUp(event);
  };

  const handleClick = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isUrl(buttonText)) {
      setSrcIsAnImage(true);
    } else if (typeof buttonText === 'string') {
      setSrcIsAnImage(false);
    }
  }, [buttonText]);

  return (
    <div
      className="react-pushbutton"
      style={{ width: `${buttonSize}px`, height: `${buttonSize}px` }}
    >
      <div
        className={`buttonWrapper ${isActive ? 'active' : 'inactive'}`}
        style={{ width: `${buttonSize}px`, height: `${buttonSize}px` }}
      >
        <button
          style={{
            backgroundColor: buttonColor,
            color: buttonColor ? invertColor(buttonColor) : '#fff',
            fontSize: fontSize || buttonSize / 6,
            height: `${buttonSize}px`,
            width: `${buttonSize}px`,
            backgroundImage: srcIsAnImage ? `url(${buttonText})` : 'none',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            WebkitUserSelect: 'none',
          }}
          type="submit"
          className={isActive ? 'active' : 'inactive'}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onPointerDown={handlePointerDown}
          onPointerUp={handlePointerUp}
          onClick={handleClick}
        >
          {!srcIsAnImage && buttonText}
        </button>
      </div>
    </div>
  );
};

PushButton.propTypes = {
  buttonSize: PropTypes.number,
  buttonColor: PropTypes.string,
  buttonText: PropTypes.node,
  fontSize: PropTypes.number,
  onClick: PropTypes.func,
};

PushButton.defaultProps = {
  buttonSize: 250,
  buttonColor: '#25871E',
  buttonText: '',
  fontSize: null,
  onClick: () => undefined,
};

export { PushButton };
