import React from 'react';
import PropTypes from 'prop-types';

import CircularChartVariables from './CircularChartVariables';
import CircularChartEvents from './CircularChartEvents';
import CircularChartMachine from './CircularChartMachine';

const CircularCharts = {
  variables: CircularChartVariables,
  events: CircularChartEvents,
  machine: CircularChartMachine,
};

const CircularChart = ({ selectedObject, ...props }) => {
  const dataType = selectedObject.dataType || 'machine';
  const TypedCircularChart = CircularCharts[dataType];
  return <TypedCircularChart selectedObject={selectedObject} {...props} />;
};

CircularChart.propTypes = {
  selectedObject: PropTypes.shape({
    dataType: PropTypes.string,
  }),
};

export default CircularChart;
