import {
  fetchOnceOperation,
} from '../fetchOperation';

import {
  getShiftsRequest,
  getShiftsResponse,
  addShiftsRequest,
  addShiftsResponse,
  updateShiftsRequest,
  updateShiftsResponse,
  deleteShiftsRequest,
  deleteShiftsResponse,
} from './actions';
import API from '~services/endpoints';

function fetchShifts() {
  return fetchOnceOperation(
    getShiftsRequest,
    getShiftsResponse,
    API.getShifts,
    state => state.shifts.shifts,
  );
}

function addShift(shift) {
  return dispatch => {
    dispatch(addShiftsRequest(shift));
    return API.addShift(shift)
      .then(res => dispatch(addShiftsResponse(res.shift)));
  };
}

function updateShift(id, shiftInfo) {
  return dispatch => {
    dispatch(updateShiftsRequest({ id, shiftInfo }));
    return API.updateShift(id, shiftInfo)
      .then(res => dispatch(updateShiftsResponse({ id, shift: res.shift })));
  };
}

function deleteShift(id) {
  return dispatch => {
    dispatch(deleteShiftsRequest(id));
    return API.deleteShift(id)
      .then(res => dispatch(deleteShiftsResponse(res.id)));
  };
}

export default {
  fetchShifts,
  addShift,
  updateShift,
  deleteShift,
};
