import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '@intelligenceindustrielle/react-ui-components';
import {
  DeleteButton, DefaultModal, CancelButton, SubmitButton, FontAwesome,
} from '~UI';
import { reduxOperations } from '~services';
import { showError, showSuccess } from '~utils/toast';
import { streamTypes } from '~utils/types';
import { getFormData } from '~utils';

import Page2 from './Page2';

import './StreamPopupForm.scss';

const streamOptions = [
  {
    type: streamTypes.SENSOR,
    icon: 'ii-sensor',
    name: 'sensor',
  },
];

const StreamPopUpForm = ({ stream, onHide, show }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [currentPageNb, setCurrentPageNb] = useState(stream ? 1 : 0);
  const [type, setType] = useState(stream ? stream.type : null);
  const streams = useSelector(state => state.streams);

  function addStream(data) {
    dispatch(reduxOperations.streams.addStream(data))
      .then(() => {
        onHide();
        showSuccess(t('operationSucceeded'));
      })
      .catch(() => {
        showError(t('couldNotCreateStream'));
      });
  }

  function updateStream(streamId, data) {
    dispatch(reduxOperations.streams.updateStream(streamId, data))
      .then(() => {
        onHide();
        showSuccess(t('operationSucceeded'));
      })
      .catch(() => {
        showError(t('couldNotEditStream'));
      });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = getFormData('Stream__form');
    const sameTopicIn = streams.find(s => {
      if (s.topicIn === formData.topicIn) {
        return !stream || stream.id !== s.id;
      }
      return false;
    });

    if (sameTopicIn) {
      showError(`${t('streamInboundTopicAlreadyExists')}: ${sameTopicIn.name}`);
      return;
    }
    if (stream) {
      updateStream(stream.id, formData);
    } else {
      addStream(formData);
    }
  }

  const handleDelete = async () => {
    dispatch(reduxOperations.machines.deleteStream(stream.id))
      .then(() => {
        onHide();
        showSuccess(t('operationSucceeded'));
      })
      .catch(error => showError(error.status));
    onHide();
  };

  const handleSelectStreamType = idStreamType => {
    setCurrentPageNb(currentPageNb + 1);
    setType(idStreamType);
  };

  const showPreviousPage = () => {
    setCurrentPageNb(0);
  };

  const showBackArrow = text => (
    <>
      <FontAwesome
        icon="arrow-left"
        className="backButtonArrow"
        size="sm"
        onClick={currentPageNb > 0 ? showPreviousPage : onHide}
      />
      {text}
    </>
  );

  let page2;
  switch (type) {
    case streamTypes.SENSOR: {
      page2 = <Page2.Sensor stream={stream} />;
      break;
    }
    default: {
      page2 = null;
    }
  }

  const title = stream
    ? t('editStream')
    : t('createStream');

  return (
    <DefaultModal
      show={show}
      title={currentPageNb > 0 ? showBackArrow(title) : title}
      closePopup={onHide}
      children={(
        <form
          id="Stream__form"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="type" value={type || ''} />
          {
            currentPageNb === 0 ? (
              <SelectionGrid
                onChange={handleSelectStreamType}
                options={
                  streamOptions.map(({ icon, name, type: streamType }) => ({
                    bgColor: '#333',
                    Icon: <FontAwesome icon={icon} size="3x" height="51" />,
                    label: t(name),
                    value: streamType,
                  }))
                }
              />
            )
              : page2
          }
          <div className="buttonsHolder flexSpaceBetween">
            {currentPageNb > 0 && (
              <>
                {
                  stream ? (
                    <DeleteButton handleDelete={handleDelete} askConfirmation />
                  ) : <div />
                }
                <div>
                  <CancelButton onClick={onHide} />
                  <SubmitButton label={stream ? t('modify') : t('add')} />
                </div>
              </>
            )}
          </div>
        </form>
      )}
    />
  );
};

StreamPopUpForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  stream: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    properties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        isAnalog: PropTypes.bool,
        IsEnabled: PropTypes.bool,
        isInput: PropTypes.bool,
        lerp: PropTypes.bool,
        name: PropTypes.string,
        units: PropTypes.string,
        variable: PropTypes.string,
      }),
    ).isRequired,
    topicIn: PropTypes.string,
    topicOut: PropTypes.string,
    type: PropTypes.string,
  }),
};

export { StreamPopUpForm };
