import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { reducersTypes } from '~services';

const ActionsContainer = ({ actionsToPerform, renderActionSummaryRow, updateActionsList }) => {
  const [actions, setActions] = useState(actionsToPerform);
  const numberOfActionsHasChanged = actions.length !== actionsToPerform.length;

  let idOfActionsHaveChanged = false;
  actionsToPerform.forEach(element => {
    const object = actions.find(x => x.id === element.id);
    if (!object) {
      idOfActionsHaveChanged = true;
    }
  });

  if (numberOfActionsHasChanged || idOfActionsHaveChanged) {
    setActions(actionsToPerform);
  }

  const updateActions = () => {
    updateActionsList(actions);
  };

  const moveAction = useCallback(
    (dragIndex, hoverIndex) => {
      const dragAction = actions[dragIndex];
      setActions(
        update(actions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragAction],
          ],
        }),
      );
    },
    [actions],
  );

  return (
    <div>{actions.map((action, index) => renderActionSummaryRow(action.id, index, moveAction, updateActions))}</div>
  );
};

ActionsContainer.propTypes = {
  actionsToPerform: PropTypes.arrayOf(reducersTypes.triggers.trigger.actionsToPerform).isRequired,
  renderActionSummaryRow: PropTypes.func.isRequired,
  updateActionsList: PropTypes.func.isRequired,
};

export default ActionsContainer;
