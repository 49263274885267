import React from 'react';
import PropTypes from 'prop-types';

import { FormStopwatch } from '~components/forms';

const FormTileStopwatch = ({ selectedTile }) => (
  <FormStopwatch
    selectedObject={selectedTile}
    isTile
  />
);

FormTileStopwatch.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileStopwatch.defaultProps = {
  selectedTile: {},
};

export default FormTileStopwatch;
