import { useEffect, useRef } from 'react';
import Tick from '~utils/Tick';

/**
 * @param {() => void} callback
 * @param {number} delay The time, in second, the timer should delay in between executions of the specified callback
 */
function useTick(callback, delay = 1) {
  const cbRef = useRef();

  useEffect(() => {
    cbRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => cbRef.current();
    Tick.subscribe(tick, delay);
    return () => Tick.unsubscribe(tick);
  }, [delay]);
}

export default useTick;
