import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { reduxOperations } from '~services';
import { CheckboxToggle } from '~UI/index';
import { showSuccess, showError } from '~utils/toast';
import './FeatureTogglesSettings.scss';
import { SubmitButton } from '~UI';

const FeatureTogglesSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const featureToggles = useSelector(state => state.settings.settings?.featureToggles);
  const [updatedFeatureToggles, setUpdatedFeatureToggles] = useState({});

  useEffect(() => {
    if (featureToggles) {
      setUpdatedFeatureToggles(featureToggles);
    }
  }, [featureToggles]);

  const handleCheckboxChange = (section, feature) => {
    setUpdatedFeatureToggles(prevState => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [feature]: !prevState[section][feature],
      },
    }));
  };
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(reduxOperations.settings.patchSettings({ featureToggles: updatedFeatureToggles }))
      .then(() => {
        showSuccess(t('success'));
      })
      .catch(error => showError(error.status));
  };

  return (
    <div className="featureTogglesSettingsForm">
      <div className="formsContainer">
        {Object.keys(updatedFeatureToggles).map(section => (
          <div key={section} className="filterSection">
            <div className="filterTitle">
              {t(section)}
            </div>
            <div className="filterItems">
              {Object.keys(updatedFeatureToggles[section]).map(feature => (
                <div key={feature} className="filterItem">
                  {t(feature)}
                  &nbsp;
                  <CheckboxToggle
                    controlledCheck={updatedFeatureToggles[section][feature]}
                    onChange={() => handleCheckboxChange(section, feature)}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="buttonFooter">
        <SubmitButton
          label={t('modify')}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default FeatureTogglesSettings;
