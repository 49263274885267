export default {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_RESPONSE: 'LOGIN_RESPONSE',
  LOGIN_MICROSOFT_REQUEST: 'LOGIN_MICROSOFT_REQUEST',
  LOGIN_MICROSOFT_RESPONSE: 'LOGIN_MICROSOFT_RESPONSE',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_RESPONSE: 'LOGOUT_RESPONSE',
  LOGOUT: 'LOGOUT',
  SET_THEME: 'SET_THEME',
  UPDATE_SIGNUP: 'UPDATE_SIGNUP',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_RESPONSE: 'SIGNUP_RESPONSE',
  UPDATE_VERIFICATION: 'UPDATE_VERIFICATION',
};
