import { ClientMetrics } from './types';

export enum ClientMetricsActionTypes {
  GET_CLIENT_METRICS_REQUEST = 'GET_CLIENT_METRICS_REQUEST',
  GET_CLIENT_METRICS_RESPONSE = 'GET_CLIENT_METRICS_RESPONSE',
}

export type ClientMetricsAction =
  | { type: ClientMetricsActionTypes.GET_CLIENT_METRICS_REQUEST; payload: { clientMetrics: ClientMetrics[]} }
  | { type: ClientMetricsActionTypes.GET_CLIENT_METRICS_RESPONSE; payload: { clientMetrics: ClientMetrics[]} };

const getClientMetricsRequest = data => ({
  type: ClientMetricsActionTypes.GET_CLIENT_METRICS_REQUEST,
  payload: data,
});

const getClientMetricsResponse = (data, request = null) => ({
  type: ClientMetricsActionTypes.GET_CLIENT_METRICS_RESPONSE,
  payload: data,
  request,
});

export {
  getClientMetricsRequest,
  getClientMetricsResponse,
};
