import React from 'react';
import PropTypes from 'prop-types';

import { FormPrompt } from '~components/forms';

const FormTilePrompt = ({ selectedTile }) => (
  <FormPrompt
    selectedObject={selectedTile}
  />
);

FormTilePrompt.propTypes = {
  selectedTile: PropTypes.object,
};
FormTilePrompt.defaultProps = {
  selectedTile: {},
};

export default FormTilePrompt;
