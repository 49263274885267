import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const reportPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  folderId: PropTypes.string,
  url: PropTypes.string,
  deletable: PropTypes.bool,
  tags: PropTypes.arrayOf(PropTypes.string),
  backgroundURL: PropTypes.string,
});

const reportsPropTypes = PropTypes.arrayOf(reportPropTypes);

function reports(state = null, action) {
  switch (action.type) {
    case types.GET_REPORTS_RESPONSE:
      return action.payload.reports;
    case types.ADD_REPORT_RESPONSE:
      return state.concat(action.payload.report);
    case types.UPDATE_REPORT_RESPONSE:
      return state.map(r => (action.payload.report.id === r.id ? action.payload.report : r));
    case types.DELETE_REPORT_RESPONSE:
      if (action.payload.error || !state) { return state; }
      return state.filter(report => report.id !== action.payload.id);
    default:
      return state;
  }
}

export const propTypes = {
  reports: nullable(reportsPropTypes),
};

export default combineReducers({
  reports,
});
