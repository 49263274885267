import React from 'react';
import PropTypes from 'prop-types';
import { FormButton } from '~components/forms';

const FormTileButton = ({ selectedTile }) => (
  <FormButton
    selectedObject={selectedTile}
  />
);

FormTileButton.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileButton.defaultProps = {
  selectedTile: {},
};

export default FormTileButton;
