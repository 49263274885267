import Air from '../icons/air.png';
import Alarm from '../icons/alarm.png';
import Supply from '../icons/supply.png';
import ArrowsChange from '../icons/arrows-change.png';
import Paperclip from '../icons/paperclip.png';
import BadPosition from '../icons/bad-position.png';
import CircleCrossed from '../icons/circle-crossed.png';
import BellAlarm from '../icons/bell-alarm.png';
import BellRing from '../icons/bell-ring.png';
import Bolt from '../icons/bolt.png';
import Break from '../icons/break.png';
import BrokenTool from '../icons/broken-tool.png';
import Broom from '../icons/broom.png';
import Brut from '../icons/brut.png';
import Cartouche from '../icons/cartouche.png';
import ToolsChange from '../icons/tools-change.png';
import Check from '../icons/check.png';
import Collision from '../icons/collision.png';
import Conveyor from '../icons/conveyor.png';
import DailyStops from '../icons/daily-stops.png';
import Default from '../icons/default.png';
import DNA from '../icons/dna.png';
import Drop from '../icons/drop.png';
import Erlenmeyer from '../icons/erlenmeyer.png';
import FemaleWorker from '../icons/female-worker.png';
import Vise from '../icons/vise.png';
import Exclamation from '../icons/exclamation.png';
import Eye from '../icons/eye.png';
import Gear from '../icons/gear.png';
import GearCrossed from '../icons/gear-crossed.png';
import Hand from '../icons/hand.png';
import Health from '../icons/health.png';
import IT from '../icons/IT.png';
import LackTool from '../icons/lack-tool.png';
import Magnifier from '../icons/magnifier.png';
import Lunch from '../icons/lunch.png';
import Maintenance from '../icons/maintenance.png';
import Missing from '../icons/missing.png';
import NeutralWorker from '../icons/neutral-worker.png';
import NoBattery from '../icons/no-battery.png';
import NoCooling from '../icons/no-cooling.png';
import NoFemaleWorker from '../icons/no-female-worker.png';
import NoGCode from '../icons/no-g-code.png';
import NoNeutralWorker from '../icons/no-neutral-worker.png';
import NonPreventive from '../icons/non-preventive.png';
import NoWorker from '../icons/no-worker.png';
import NoWifi from '../icons/no-wifi.png';
import Tool from '../icons/tool.png';
import Tools from '../icons/tools.png';
import PaperRoll from '../icons/paper-roll.png';
import Pastille from '../icons/pastille.png';
import Pause from '../icons/pause.png';
import Pliers from '../icons/pliers.png';
import Folding from '../icons/folding.png';
import Preparation from '../icons/preparation.png';
import Preventive from '../icons/preventive.png';
import Preventive2 from '../icons/preventive-02.png';
import Print from '../icons/print.png';
import ExclamationHexa from '../icons/exclamation-hexagon.png';
import Prototype from '../icons/prototype.png';
import PunchDie from '../icons/punch-die.png';
import RawMaterial from '../icons/raw-material.png';
import RobotArm from '../icons/robot-arm.png';
import RobotWelding from '../icons/robot-welding.png';
import Screw from '../icons/screw.png';
import Shift from '../icons/shift.png';
import SpotWelding from '../icons/spot-welding.png';
import Interrogation from '../icons/interrogation.png';
import Wifi from '../icons/wifi.png';
import Worker from '../icons/worker.png';
// Others
import LogoII from '../logo_ii.png';
import LogoIIDark from '../logo_ii_dark.png';

// TODO: ShadowLegacy, when stop causes in DB won't have old name icon, remove them here
const imagesLibrary = {
  Air,
  Alarm,
  Approvisionnement: Supply,
  ArrowsChange,
  Attache: Paperclip,
  BadPosition,
  BarredCircle: CircleCrossed,
  BellAlarm,
  BellRing,
  Bolt,
  Break,
  BrokenTool,
  Broom,
  Brut,
  Cartouche,
  ChangementOutil: ToolsChange,
  Check,
  CircleCrossed,
  Collision,
  Conveyor,
  Convoyer: Conveyor,
  DailyStops,
  Default,
  DNA,
  Drop,
  Erlenmeyer,
  Etau: Vise,
  Exclamation,
  ExclamationHexa,
  Eye,
  FemaleWorker,
  Folding,
  Gear,
  GearCrossed,
  Hand,
  Health,
  Interrogation,
  IT,
  LackTool,
  LogoII,
  LogoIIDark,
  Loupe: Magnifier,
  Lunch,
  Magnifier,
  Maintenance,
  Manque: Missing,
  Missing,
  NeutralWorker,
  NoBattery,
  NoCooling,
  NoFemaleWorker,
  NoGCode,
  NoNeutralWorker,
  NonPreventive,
  NoWifi,
  NoWorker,
  Paperclip,
  Outil: Tool,
  Outils: Tools,
  PaperRoll,
  Pastille,
  Pause,
  Pince: Pliers,
  Pliers,
  Plieuse: Folding,
  Preparation,
  Preventive,
  Preventive2,
  Print,
  ProblemHexa: ExclamationHexa,
  Prototype,
  PunchDie,
  RawMaterial,
  RobotArm,
  RobotWelding,
  Screw,
  Shift,
  SpotWelding,
  Supply,
  Tool,
  Tools,
  ToolsChange,
  Vise,
  Unknown: Interrogation,
  Wifi,
  Worker,
};

const IconsLibrary = {
  Air,
  Alarm,
  ArrowsChange,
  BadPosition,
  BellAlarm,
  BellRing,
  Bolt,
  Break,
  BrokenTool,
  Broom,
  Brut,
  Cartouche,
  Check,
  CircleCrossed,
  Collision,
  Conveyor,
  DailyStops,
  Default,
  DNA,
  Drop,
  Erlenmeyer,
  Exclamation,
  ExclamationHexa,
  Eye,
  FemaleWorker,
  Folding,
  Gear,
  GearCrossed,
  Hand,
  Health,
  Interrogation,
  IT,
  LackTool,
  Lunch,
  Magnifier,
  Maintenance,
  Missing,
  NeutralWorker,
  NoBattery,
  NoCooling,
  NoFemaleWorker,
  NoGCode,
  NoNeutralWorker,
  NonPreventive,
  NoWifi,
  NoWorker,
  Paperclip,
  PaperRoll,
  Pastille,
  Pause,
  Pliers,
  Preparation,
  Preventive,
  Preventive2,
  Print,
  Prototype,
  PunchDie,
  RawMaterial,
  RobotArm,
  RobotWelding,
  Screw,
  Shift,
  SpotWelding,
  Supply,
  Tool,
  Tools,
  ToolsChange,
  Vise,
  Wifi,
  Worker,
};

export {
  IconsLibrary,
};

export default imagesLibrary;
