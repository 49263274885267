/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from '~components/Pages';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ModalHandler, ResourcesHandler,
  Cards, BasicCard, CreateCard, FontAwesome,
} from '~UI';
import { reduxOperations, reducersTypes } from '~services';
import { padding } from '~utils';
import { configurationFeature } from '~utils/featureToggles';

import { Page401Unauthorized } from '../../ErrorPages';
import ShiftPopUpForm from './ShiftPopUpForm';
import './Shifts.scss';

const prettyPrintShift = shift => `${padding(shift.hourStart)}:${padding(shift.minuteStart)}  -  ${padding(shift.hourEnd)}:${padding(shift.minuteEnd)}`;

const Shifts = ({
  shifts, deleteShift, commandsSave, addShift, fetchShifts,
}) => {
  const { t } = useTranslation();
  const handleDelete = id => {
    deleteShift(id);
    const shift = omit(shifts.find(s => s.id === id), ['id']);
    commandsSave({
      undoAction: { action: addShift, params: [shift] },
      redoAction: { action: deleteShift, params: [id] },
    });
  };

  function getContents() {
    return (
      <PageTemplate
        sidebar
      >
        <Cards>
          <CreateCard
            title={t('addShift')}
            modal={{
              Component: ShiftPopUpForm,
              props: { isCreating: true },
            }}
          />
          {shifts.map(shift => (
            <ModalHandler
              key={shift.id}
              Trigger={{
                Component: BasicCard,
                props: {
                  text: prettyPrintShift(shift),
                  title: shift.name,
                  icon: <FontAwesome icon="ii-shift" size="4x" />,
                  editModal: {
                    Component: ShiftPopUpForm,
                    props: { modifiedItemId: shift.id },
                  },
                  deleteModal: {
                    Component: DeleteConfirmationForm,
                    props: { handleDelete: () => handleDelete(shift.id) },
                  },
                  style: { cursor: 'pointer' },
                },
              }}
              Modal={{
                Component: ShiftPopUpForm,
                props: { modifiedItemId: shift.id },
              }}
            />
          ))}
        </Cards>
      </PageTemplate>
    );
  }

  return (configurationFeature.isUserAllowedAccessShifts() ? (
    <ResourcesHandler
      resources={[shifts]}
      resourceFetchers={[fetchShifts]}
      getContents={getContents}
    />
  ) : (
    <Page401Unauthorized />
  )
  );
};

Shifts.propTypes = {
  shifts: reducersTypes.shifts.shifts.isRequired,
  deleteShift: PropTypes.func.isRequired,
  commandsSave: PropTypes.func.isRequired,
  addShift: PropTypes.func.isRequired,
  fetchShifts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    shifts: state.shifts.shifts,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    deleteShift: reduxOperations.shifts.deleteShift,
    commandsSave: reduxOperations.commands.commandsSave,
    addShift: reduxOperations.shifts.addShift,
    fetchShifts: reduxOperations.shifts.fetchShifts,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Shifts);
