import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import imagesLibrary from '~images/imagesLibrary';
import { showError } from '~utils/toast';

import './SelectionForm.scss';

const SelectionForm = ({
  handleAction, show, onHide, title, description, content, actionLabel, confirmButtonColor,
}) => {
  const { t } = useTranslation();

  const [selectedItemId, setSelectedItemId] = useState('');

  const handleSelection = id => {
    if (id !== selectedItemId) {
      setSelectedItemId(id);
    }
  };

  const handleSubmit = () => {
    if (selectedItemId.length === 0) {
      showError(t('showErrorNoneSelected'));
      return;
    }
    handleAction(selectedItemId);
    onHide();
  };

  return (
    <Modal
      show={show}
      className={`DefaultModal theme-${localStorage.getItem('theme')} undraggable confirmationFormPopup`}
      dialogClassName="DefaultModal"
      backdropClassName="delete-modal-backdrop"
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ textAlign: 'center' }}>
            {title || t('selectOne')}
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="SelectionDescription">
          {description}
        </div>
        <div className="SelectionGrid">
          {
            content.map(item => (
              <div
                className="Card"
                id={(selectedItemId && selectedItemId === item.id && 'selected') || undefined}
                onClick={() => handleSelection(item.id)}
                key={item.id}
              >
                <div
                  className="CardImageHolder"
                  style={{
                    backgroundImage: !item.img ? `url(${imagesLibrary.LogoIIDark})` : undefined,
                    backgroundSize: !item.img ? '37.5% 66.75%' : undefined,
                    backgroundRepeat: !item.img ? 'no-repeat' : undefined,
                    backgroundPosition: !item.img ? 'center' : undefined,
                  }}
                >
                  { item.img && <img alt="bookmark" className="CardImage" src={item.img} />}
                </div>
                <div className="CardText">
                  {item.name}
                </div>
              </div>
            ))
          }
        </div>
        <div className="actions">
          <button
            type="button"
            className="PopupButton cancel"
            onClick={onHide}
          >
            {t('cancel')}
          </button>
          {actionLabel && (
            <button
              type="button"
              className="PopupButton"
              style={{ backgroundColor: confirmButtonColor }}
              onClick={handleSubmit}
            >
              {actionLabel}
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

SelectionForm.propTypes = {
  handleAction: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.arrayOf(PropTypes.object),
  actionLabel: PropTypes.string,
  confirmButtonColor: PropTypes.string,
};

SelectionForm.defaultProps = {
  show: true,
  onHide: () => undefined,
  title: '',
  description: '',
  content: [],
  confirmButtonColor: '#bf3838',
  actionLabel: '',
};

export default SelectionForm;
