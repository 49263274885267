import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const shiftsPropTypes = PropTypes.arrayOf(PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  hourStart: PropTypes.number,
  minuteStart: PropTypes.number,
  hourEnd: PropTypes.number,
  minuteEnd: PropTypes.number,
}));

function shifts(state = null, action) {
  const newState = Object.assign([], state);
  switch (action.type) {
    case types.GET_SHIFTS_RESPONSE:
      return action.payload.shifts;

    case types.ADD_SHIFTS_RESPONSE:
      return newState.concat(action.payload);

    case types.UPDATE_SHIFTS_RESPONSE:
      if (state) {
        return newState.map(shift => (
          (shift.id === action.payload.shift.id) ? action.payload.shift : shift
        ));
      }
      return state;

    case types.DELETE_SHIFTS_RESPONSE:
      if (!action.payload.error) {
        return newState.filter(shift => shift.id !== action.payload);
      }
      return state;

    default:
      return state;
  }
}

export const propTypes = {
  shifts: nullable(shiftsPropTypes),
};

export default combineReducers({
  shifts,
});
