import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import { Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { PageTemplate } from '~components/Pages';
import { MultiSelect, ResourcesHandler } from '~components/UI';
import { Page401Unauthorized } from '../../ErrorPages';
import { configurationFeature } from '~utils/featureToggles';
import { RootState } from '~services/store';
import reduxOperations from '~services/reduxOperations';
import { ActivityLog } from '~services/logs/types';
import { MAX_NB_EVENTS_ONE_PAGE, MAX_NB_PAGES } from '~utils/constants';
import { LogsList } from './LogsList';
import './LogsPage.scss';
import { showError } from '~utils/toast';
import { serverTime } from '~utils/time';
import HoverRoundButton from '~components/UI/HoverRoundButton/HoverRoundButton';

const MODULES = [
  'actions',
  'dashboards',
  'events',
  'folders',
  'images',
  'machines',
  'operators',
  'operations',
  'reports',
  'shifts',
  'stopwatches',
  'streams',
  'topviews',
  'triggers',
  'users',
  'variables',
];

const LogsPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const users = useSelector((state: RootState) => state.users.users);
  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const machines = useSelector((state: RootState) => state.machines);
  const triggers = useSelector((state: RootState) => state.triggers);
  const actions = useSelector((state: RootState) => state.actions);
  const folders = useSelector((state: RootState) => state.folders);
  const operators = useSelector((state: RootState) => state.operators);
  const operations = useSelector((state: RootState) => state.operations.operations);
  const variables = useSelector((state: RootState) => state.variables);
  const reports = useSelector((state: RootState) => state.reports.reports);
  const shifts = useSelector((state: RootState) => state.shifts.shifts);
  const stopwatches = useSelector((state: RootState) => state.stopwatches.stopwatches);
  const streams = useSelector((state: RootState) => state.streams);
  const images = useSelector((state: RootState) => state.images.images);

  const moduleNames = {
    dashboards: dashboards?.map(dash => ({ value: dash.id, label: dash.name })) || [],
    users: users?.map(user => ({ value: user.id, label: user.name })) || [],
    topviews: topviews?.map(topview => ({ value: topview.id, label: topview.name })) || [],
    machines: machines?.map(machine => ({ value: machine.id, label: machine.name })) || [],
    triggers: triggers?.map(trigger => ({ value: trigger.id, label: trigger.name })) || [],
    actions: actions?.map(action => ({ value: action.id, label: action.name })) || [],
    folders: folders?.map(folder => ({ value: folder.id, label: folder.name })) || [],
    operators: operators?.map(operator => ({ value: operator.id, label: operator.name })) || [],
    operations: operations?.map(operation => ({ value: operation.id, label: operation.name })) || [],
    variables: variables?.map(variable => ({ value: variable.id, label: variable.variable })) || [],
    reports: reports?.map(report => ({ value: report.id, label: report.name })) || [],
    shifts: shifts?.map(shift => ({ value: shift.id, label: shift.name })) || [],
    stopwatches: stopwatches?.map(stopwatch => ({ value: stopwatch.id, label: stopwatch.name })) || [],
    streams: streams?.map(stream => ({ value: stream.id, label: stream.name })) || [],
    images: images?.map(image => ({ value: image.id, label: image.name })) || [],
  };

  const [selectedModules, setSelectedModules] = useState<{ value: string, label: string }[]>(
    MODULES.map(module => ({ value: module, label: t(module) })),
  );

  const [selectedUsers, setSelectedUsers] = useState<{ value: string, label: string }[]>(
    [...moduleNames.users, { value: 'unknown', label: t('unknown') }],
  );

  const [selectedModuleNames, setSelectedModuleNames] = useState<{ value: string, label: string }[]>([]);

  const [logsList, setLogsList] = useState<ActivityLog[]>([]);
  const [showModuleNames, setShowModuleNames] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<number>(serverTime() - 86400000);
  const [endDate, setEndDate] = useState<number>(serverTime());

  const [areEntriesFormatted, setAreEntriesFormatted] = useState<boolean>(true);

  const getUrlFilter = () => {
    if (selectedModules.length === 1 && selectedModules[0].value !== 'events') {
      const isOnlyUnknownSelected = selectedModuleNames.find(user => user.value === 'unknown') && selectedModuleNames.length === 1;
      if (isOnlyUnknownSelected) {
        const allItemsExistingOfModule = new RegExp(moduleNames[selectedModules[0].value].map(module => module.value).join('|')).source;
        const module = new RegExp(selectedModules[0].value).source;
        return {
          $and: [
            { url: { $not: { $regex: allItemsExistingOfModule } } },
            { url: { $regex: module } },
          ],
        };
      }
      if (selectedModuleNames.length - 1 === moduleNames[selectedModules[0].value].length) {
        const allItemsOfModule = new RegExp(selectedModules[0].value).source;
        return { url: { $regex: allItemsOfModule } };
      }
      const isUnknownSelected = selectedModuleNames.find(module => module.value === 'unknown');
      if (isUnknownSelected) {
        const allItemsNotSelected = new RegExp(moduleNames[selectedModules[0].value].filter(module => !selectedModuleNames.find(mod => module.value === mod.value)).map(module => module.value).join('|')).source;
        const module = new RegExp(selectedModules[0].value).source;
        return {
          $and: [
            { url: { $not: { $regex: allItemsNotSelected } } },
            { url: { $regex: module } },
          ],
        };
      }
      if (!selectedModuleNames.length) {
        return undefined;
      }
      const selectedItems = new RegExp(selectedModuleNames.map(module => module.value).join('|')).source;
      return { url: { $regex: selectedItems } };
    }
    if (selectedModules.length === MODULES.length) {
      return 'all';
    }
    if (!selectedModules.length) {
      return undefined;
    }
    const modulesSelected = new RegExp(selectedModules.map(module => module.value).join('|')).source;
    return { url: { $regex: modulesSelected } };
  };

  const getUsersFilter = () => {
    const isOnlyUnknownSelected = selectedUsers.find(user => user.value === 'unknown') && selectedUsers.length === 1;
    if (isOnlyUnknownSelected) {
      return { $not: { $in: moduleNames.users.map(user => user.value) } };
    }
    if (selectedUsers.length - 1 === users.length) {
      return 'all';
    }
    const isUnknownSelected = selectedUsers.find(user => user.value === 'unknown');
    if (isUnknownSelected) {
      return {
        $not: {
          $in: moduleNames.users.filter(user => !selectedUsers
            .find(usr => user.value === usr.value)).map(user => user.value),
        },
      };
    }
    if (!selectedUsers.length) {
      return undefined;
    }
    return { $in: selectedUsers.map(user => user.value) };
  };

  const fetchLogs = async () => {
    const urlFilter = getUrlFilter();
    const userFilter = getUsersFilter();
    if (!urlFilter || !userFilter) {
      setLogsList([]);
      return;
    }
    const filter = {
      type: 'Activity',
      ...(urlFilter !== 'all' && urlFilter),
      ...(userFilter !== 'all' && { 'decodedToken.userId': userFilter }),
      ...((startDate && endDate) && { createdAt: { $gte: startDate, $lte: endDate } }),
    };
    const sort = { createdAt: -1 };
    const limit = MAX_NB_EVENTS_ONE_PAGE * MAX_NB_PAGES;
    dispatch(reduxOperations.logs.fetchLogs(filter, sort, limit) as any).then(res => {
      setLogsList(res.payload.logs || []);
    }).catch(err => {
      showError(err);
      setLogsList([]);
    });
  };

  const handleModuleSelection = (selected: { value: string, label: string }[]) => {
    setSelectedModules(selected);
    if (selected.length === 1 && selected[0].value !== 'events') {
      setShowModuleNames(true);
      setSelectedModuleNames([...moduleNames[selected[0].value], { value: 'unknown', label: t('unknown') }]);
      return;
    }
    setShowModuleNames(false);
    setSelectedModuleNames([]);
  };

  const handleUserSelection = (selected: { value: string, label: string }[]) => {
    setSelectedUsers(selected);
  };

  const handleChangeDate = (sDate, eDate) => {
    const start = sDate || startDate;
    const end = eDate || endDate;
    setStartDate(start);
    setEndDate(end);
  };

  const getActionType = (method: string) => {
    switch (method) {
      case 'POST':
        return t('create');
      case 'PUT':
      case 'PATCH':
        return t('update');
      case 'DELETE':
        return t('delete');
      default:
        return t('unknown');
    }
  };

  const getModuleName = (log: ActivityLog) => {
    const urlParts = log.url.split('/');
    const module = urlParts[3];
    const id = urlParts[4];
    return module !== 'events' ? moduleNames[module]?.find(mod => mod.value === id)?.label || log.body?.name || t('unknown') : 'N/A';
  };

  const renderBodyCell = body => (
    <div style={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}
    >
      {body}
    </div>
  );

  const getModule = (url: string) => {
    const urlParts = url.split('/');
    return urlParts[3];
  };

  const getEntries = (filteredList: ActivityLog[], areEntriesFormattedArg: boolean) => {
    const entriesList = filteredList.map(log => ({
      date: new Date(log.createdAt).toLocaleString(),
      module: t(getModule(log.url)),
      name: getModuleName(log),
      url: areEntriesFormattedArg ? renderBodyCell(log.url) : log.url,
      actionType: getActionType(log.method),
      user: users?.find(user => user.id === log.decodedToken?.userId)?.name || log.decodedToken?.userId || t('unknown'),
      body: areEntriesFormattedArg ? renderBodyCell(JSON.stringify(log.body) || '') : JSON.stringify(log.body) || '',
    }));

    return entriesList;
  };

  const renderExportCSVButton = () => {
    const entriesList = getEntries(logsList, false);
    const headers = ['Date', t('user'), t('method'), t('module'), t('name'), 'URL', t('body')];
    const rows = entriesList.map(entry => [
      entry.date,
      entry.user,
      entry.actionType,
      entry.module,
      entry.name,
      entry.url,
      entry.body,
    ]);
    return (
      <HoverRoundButton
        content="CSV"
        position={1}
        diameter={70}
        onClick={() => {
          const csvContent = `data:text/csv;charset=utf-8,${headers.join('\t')}\n${rows.map(e => e.join('\t')).join('\n')}`;
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'ii_export_logs.csv');
          document.body.appendChild(link);
          link.click();
        }}
      />
    );
  };

  useEffect(() => {
    fetchLogs();
  }, [selectedModuleNames, selectedUsers, selectedModules, startDate, endDate]);

  const COLUMN_NAMES = [{ name: 'Date' }, { name: t('user') }, { name: t('method') }, { name: t('module') }, { name: t('name') }, { name: 'URL' }, { name: t('body') }];
  const ENTRIES_PROPERTIES = ['date', 'user', 'actionType', 'module', 'name', 'url', 'body'];

  const content = () => (
    configurationFeature.isUserAllowedAccessLogs() ? (
      <PageTemplate
        sidebar
        title={t('logs')}
        right={[renderExportCSVButton()]}
      >
        <div className="LogsPage" style={{ minHeight: '600px' }}>
          <LogsList
            columnNames={COLUMN_NAMES}
            entriesProperties={ENTRIES_PROPERTIES}
            entriesStyle={[{ property: 'url', style: { maxWidth: '400px' } }, { property: 'body', style: { maxWidth: '300px' } }]}
            filteredList={(getEntries(logsList, areEntriesFormatted))}
            leftHeader={(
              <div className="filterContainer">
                <div className="selectContainer">
                  {t('modules')}
                  <MultiSelect
                    className="selectContainer"
                    options={MODULES.map(module => ({ value: module, label: t(module) }))}
                    value={selectedModules}
                    onChange={options => handleModuleSelection(options)}
                  />
                </div>
                {showModuleNames && (
                  <div className="selectContainer">
                    {t('names')}
                    <MultiSelect
                      className="selectContainer"
                      options={[...moduleNames[selectedModules[0].value], { value: 'unknown', label: t('unknown') }]}
                      value={selectedModuleNames}
                      onChange={options => setSelectedModuleNames(options)}
                    />
                  </div>
                )}
                <div className="selectContainer">
                  {t('users')}
                  <MultiSelect
                    className="selectContainer"
                    options={[...moduleNames.users, { value: 'unknown', label: t('unknown') }]}
                    value={selectedUsers}
                    onChange={options => handleUserSelection(options)}
                  />
                </div>
                <div className="dateFilter">
                  {t('timePeriod')}
                  <div className="datePickerContainer">
                    <span style={{ marginRight: '5px' }}>{t('from')}</span>
                    <DatePicker
                      className="datePicker form-control"
                      selected={new Date(startDate)}
                      onChange={e => handleChangeDate(e.getTime(), null)}
                      showTimeSelect
                      dateFormat="MM/d/yyyy HH:mm"
                    />
                    <span style={{ margin: '0 5px 0 5px' }}>{t('to')}</span>
                    <DatePicker
                      className="datePicker form-control"
                      selected={new Date(endDate)}
                      onChange={e => handleChangeDate(null, e.getTime())}
                      showTimeSelect
                      dateFormat="MM/d/yyyy HH:mm"
                    />
                  </div>
                </div>
                <div className="checkboxContainer">
                  <Checkbox
                    checked={areEntriesFormatted}
                    onChange={setAreEntriesFormatted}
                  />
                  <span>
                    {t('formatEntries')}
                  </span>
                </div>
              </div>
            )}
          />
        </div>
      </PageTemplate>
    ) : (
      <Page401Unauthorized />
    )
  );

  return (
    <ResourcesHandler
      resources={[
        users, dashboards, topviews, machines, triggers, actions,
        folders, operators, operations, variables, reports, shifts, stopwatches, streams,
        images,
      ]}
      resourceFetchers={[
        () => dispatch(reduxOperations.users.fetchUsers() as any),
        () => dispatch(reduxOperations.dashboards.fetchDashboards() as any),
        () => dispatch(reduxOperations.topviews.fetchTopviews() as any),
        () => dispatch(reduxOperations.machines.fetchMachines() as any),
        () => dispatch(reduxOperations.triggers.fetchTriggers() as any),
        () => dispatch(reduxOperations.actions.fetchActions() as any),
        () => dispatch(reduxOperations.folders.fetchFolders() as any),
        () => dispatch(reduxOperations.operators.fetchOperators() as any),
        () => dispatch(reduxOperations.operations.fetchOperations() as any),
        () => dispatch(reduxOperations.variables.fetchVariables() as any),
        () => dispatch(reduxOperations.reports.fetchReports() as any),
        () => dispatch(reduxOperations.shifts.fetchShifts() as any),
        () => dispatch(reduxOperations.stopwatches.fetchStopwatches() as any),
        () => dispatch(reduxOperations.streams.fetchStreams() as any),
        () => dispatch(reduxOperations.images.fetchImages() as any),
      ]}
      getContents={content}
    />
  );
};

export default LogsPage;
