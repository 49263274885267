import API from '~services/endpoints';
import { fetchOnceOperation, fetchOperation } from '../fetchOperation';
import {
  getUserRequest,
  getUserResponse,
  getUsersRequest,
  getUsersResponse,
  addUserRequest,
  addUserResponse,
  updateUserRequest,
  updateUserResponse,
  updateVerifyUserRequest,
  updateVerifyUserResponse,
  deleteUserRequest,
  deleteUserResponse,
  getAllUsersPermissionsRequest,
  getAllUsersPermissionsResponse,
  getUserPermissionsRequest,
  getUserPermissionsResponse,
  updateUsersContentsPermissionsRequest,
  updateUsersContentsPermissionsResponse,
  sendWelcomeEmailRequest,
  sendWelcomeEmailResponse,
} from './actions';

function fetchOneUser(id) {
  return fetchOperation(
    getUserRequest,
    getUserResponse,
    API.getUser,
    state => state.users.users,
    [id],
  );
}

function fetchUsers() {
  return fetchOnceOperation(
    getUsersRequest,
    getUsersResponse,
    API.getUsers,
    state => state.users.users,
  );
}

function verifyUser(userId, userData) {
  return dispatch => {
    dispatch(updateVerifyUserRequest(userId, userData));
    return API.verifyUser(userId, userData)
      .then(res => dispatch(updateVerifyUserResponse(res)));
  };
}

function addUser(user, origin) {
  return dispatch => {
    dispatch(addUserRequest(user));
    return API.addUser(user, origin)
      .then(res => {
        if (!res.error && res.user) {
          return dispatch(addUserResponse(res.user));
        }
      });
  };
}

function updateUser(userData, userId) {
  return dispatch => {
    dispatch(updateUserRequest(userId, userData));
    return API.updateUser(userId, userData)
      .then(res => dispatch(updateUserResponse(res)));
  };
}

function deleteUser(userId) {
  return dispatch => {
    dispatch(deleteUserRequest(userId));
    return API.deleteUser(userId)
      .then(res => dispatch(deleteUserResponse({ ...res, userId })));
  };
}

function fetchAllUsersPermissions() {
  return fetchOperation(
    getAllUsersPermissionsRequest,
    getAllUsersPermissionsResponse,
    API.getAllUsersPermissions,
    state => state.users.users,
  );
}

function fetchUserPermissions(userId) {
  return fetchOperation(
    getUserPermissionsRequest,
    getUserPermissionsResponse,
    API.getUserPermissions,
    state => state.users.users,
    [userId],
  );
}

function updateUsersContentsPermissions(data) {
  return dispatch => {
    dispatch(updateUsersContentsPermissionsRequest({ data }));
    return API.updateUsersContentsPermissions(data.users, data.content)
      .then(res => {
        if (res) {
          dispatch(updateUsersContentsPermissionsResponse(res));
        }
      });
  };
}

function sendWelcomeEmail(userId) {
  return dispatch => {
    dispatch(sendWelcomeEmailRequest(userId));
    return API.sendWelcomeEmail(userId)
      .then(res => dispatch(sendWelcomeEmailResponse(res)));
  };
}

export default {
  fetchOneUser,
  fetchUsers,
  addUser,
  updateUser,
  deleteUser,
  fetchAllUsersPermissions,
  fetchUserPermissions,
  verifyUser,
  updateUsersContentsPermissions,
  sendWelcomeEmail,
};
