enum Types {
  // Topview
  CREATE_TOPVIEW_REQUEST = 'CREATE_TOPVIEW_REQUEST',
  CREATE_TOPVIEW_RESPONSE = 'CREATE_TOPVIEW_RESPONSE',
  GET_TOPVIEWS_REQUEST = 'GET_TOPVIEWS_REQUEST',
  GET_TOPVIEWS_RESPONSE = 'GET_TOPVIEWS_RESPONSE',
  UPDATE_TOPVIEW_REQUEST = 'UPDATE_TOPVIEW_REQUEST',
  UPDATE_TOPVIEW_RESPONSE = 'UPDATE_TOPVIEW_RESPONSE',
  DELETE_TOPVIEW_REQUEST = 'DELETE_TOPVIEW_REQUEST',
  DELETE_TOPVIEW_RESPONSE = 'DELETE_TOPVIEW_RESPONSE',

  // Widget
  CREATE_WIDGET_REQUEST = 'CREATE_WIDGET_REQUEST',
  CREATE_WIDGET_RESPONSE = 'CREATE_WIDGET_RESPONSE',
  UPDATE_WIDGET_REQUEST = 'UPDATE_WIDGET_REQUEST',
  UPDATE_WIDGET_RESPONSE = 'UPDATE_WIDGET_RESPONSE',
  DELETE_WIDGET_REQUEST = 'DELETE_WIDGET_REQUEST',
  DELETE_WIDGET_RESPONSE = 'DELETE_WIDGET_RESPONSE',
}

export default Types;
