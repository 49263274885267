import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { reduxOperations, reducersTypes } from '~services';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { FontAwesome } from '~UI';
import { evaluate } from '~utils/parser';
import { getFontSizeToFitTile } from '~utils/responsiveValue';
import { audioTypes } from '~utils/types';

const TextToSpeech = ({
  height,
  tile,
  width,
}) => {
  const socket = getSocket();
  const dispatch = useDispatch();

  const [displayText, setdisplayText] = useState(tile.text);
  const [currentValue, setCurrentValue] = useState();

  // Keep the current triggerValue as soon as the component mounts
  useEffect(() => {
    API.getValues(tile.valueId, {}, 1)
      .then(response => {
        let realValue = response.values.length ? response.values[0].value : undefined;
        if (/^\d+$/.test(realValue)) {
          realValue = Number(realValue);
        } else if (realValue === 'true' || realValue === 'false') {
          realValue = realValue === 'true';
        }
        setCurrentValue(realValue);
      })
      .catch(() => {
        setCurrentValue(null);
      });
  }, []);

  function speak() {
    if (displayText) {
      dispatch(reduxOperations.audios.addAudio({
        id: uuidv4(),
        type: audioTypes.TEXT_TO_SPEECH,
        text: displayText,
      }));
    }
  }

  function handleSocketValue(socketData) {
    if (socketData.id === tile.valueId) {
      let realSocketValue = socketData.value;
      if (/^\d+$/.test(realSocketValue)) {
        realSocketValue = Number(realSocketValue);
      } else if (realSocketValue === 'true' || realSocketValue === 'false') {
        realSocketValue = realSocketValue === 'true';
      }
      if (tile.triggerType === 'ValueChangeTrigger') {
        if (tile.triggerValue === '' || tile.triggerValue == null) {
          if (currentValue !== realSocketValue) {
            speak();
          }
        } else if (tile.triggerValue === realSocketValue && currentValue !== realSocketValue) {
          speak();
        }
      } else if (tile.triggerType === 'ValueSetTrigger') {
        if (tile.triggerValue === '' || tile.triggerValue == null) {
          speak();
        } else if (tile.triggerValue === realSocketValue) {
          speak();
        }
      }
      setCurrentValue(realSocketValue);
    }
  }

  function handleSocketData(socketData) {
    const websocketData = JSON.parse(socketData);
    let newText = tile.text;
    if (tile.rules) {
      const textRules = tile.rules.filter(rule => rule.type === 'text');
      for (const textRule of textRules) {
        if (evaluate(textRule.condition, websocketData, textRule.variableType)) {
          newText = textRule.text;
          break;
        }
      }
    }
    setdisplayText(newText);
  }

  useEffect(() => {
    if (socket && tile.text) {
      socket.on('value', handleSocketValue);
      socket.on('data', handleSocketData);
    }
    return () => {
      if (socket) {
        socket.removeListener('value', handleSocketValue);
        socket.removeListener('data', handleSocketData);
      }
    };
  }, [tile, socket, currentValue, displayText]);

  const splitText = (displayText || '').split('\n');
  const longestString = splitText.reduce((a, b) => (a.length > b.length ? a : b));
  const { valueFontSize } = getFontSizeToFitTile(
    height,
    width,
    longestString,
    false,
    false,
    'medium',
  );

  return (
    <div
      role="button"
      onClick={speak}
    >
      <div
        style={{
          fontSize: `${valueFontSize}px`,
          lineHeight: 1,
        }}
      >
        {splitText.map((line, index) => {
          if (index !== 0) {
            return (
              <>
                <br />
                {line}
              </>
            );
          }
          return line;
        })}
      </div>
      <br />
      <div>
        <FontAwesome
          icon="volume-up"
          size="lg"
        />
      </div>
    </div>
  );
};

TextToSpeech.propTypes = {
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile,
  width: PropTypes.number.isRequired,
};
TextToSpeech.defaultProps = {
  tile: {},
};

export { TextToSpeech };
