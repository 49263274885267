export default {
  GET_STREAMS_REQUEST: 'GET_STREAMS_REQUEST',
  GET_STREAMS_RESPONSE: 'GET_STREAMS_RESPONSE',
  ADD_STREAM_REQUEST: 'ADD_STREAM_REQUEST',
  ADD_STREAM_RESPONSE: 'ADD_STREAM_RESPONSE',
  UPDATE_STREAM_REQUEST: 'UPDATE_STREAM_REQUEST',
  UPDATE_STREAM_RESPONSE: 'UPDATE_STREAM_RESPONSE',
  DELETE_STREAM_REQUEST: 'DELETE_STREAM_REQUEST',
  DELETE_STREAM_RESPONSE: 'DELETE_STREAM_RESPONSE',
  UPDATE_INPUTS_REQUEST: 'UPDATE_INPUTS_REQUEST',
  UPDATE_INPUTS_RESPONSE: 'UPDATE_INPUTS_RESPONSE',

  GET_STREAM_PROPERTIES_REQUEST: 'GET_STREAM_PROPERTIES_REQUEST',
  GET_STREAM_PROPERTIES_RESPONSE: 'GET_STREAM_PROPERTIES_RESPONSE',
  ADD_STREAM_PROPERTY_REQUEST: 'ADD_STREAM_PROPERTY_REQUEST',
  ADD_STREAM_PROPERTY_RESPONSE: 'ADD_STREAM_PROPERTY_RESPONSE',
  UPDATE_STREAM_PROPERTY_REQUEST: 'UPDATE_STREAM_PROPERTY_REQUEST',
  UPDATE_STREAM_PROPERTY_RESPONSE: 'UPDATE_STREAM_PROPERTY_RESPONSE',
  DELETE_STREAM_PROPERTY_REQUEST: 'DELETE_STREAM_PROPERTY_REQUEST',
  DELETE_STREAM_PROPERTY_RESPONSE: 'DELETE_STREAM_PROPERTY_RESPONSE',
};
