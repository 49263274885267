import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reducersTypes } from '~services';
import { DefaultSubmitButton } from '~UI';
import { getTitleFontSize } from '~utils/responsiveValue';
import API from '~services/endpoints';
import TileContents from '../TileContents';
import { TextInput } from '~components/UI';
import { showError } from '~utils/toast';

const DataEntryTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const { t } = useTranslation();
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);

  const [currentValue, setCurrentValue] = useState('');
  const [isBool, setIsBool] = useState(undefined);
  const [isString, setIsString] = useState(false);

  useEffect(() => {
    const properties = [].concat(...streams.map(s => s.properties));
    const all = [...properties, ...variables];
    const value = all.find(elem => elem.id === tile.valueId);

    if (value) {
      API.getValues(tile.valueId, {}, 1).then(res => {
        const valueRes = res.values.length ? res.values[0].value : undefined;
        setIsBool(typeof valueRes === 'boolean');
        setIsString(typeof valueRes === 'string');
      });
    }
  }, [tile.valueId, streams, variables]);

  const sendData = () => {
    const { valueId: id } = tile;
    const properties = [].concat(...streams.map(s => s.properties));
    const all = [...properties, ...variables];
    const value = all.find(elem => elem.id === id);

    const validateString = isString && (currentValue.includes('"') || currentValue.includes('${'));
    if (validateString) {
      showError(t('invalidFormData'));
      setCurrentValue('');
      return;
    }

    if (currentValue !== '' && id && value) {
      API.updateValue(id, {
        name: value.variable,
        value: currentValue,
      });
    }

    setCurrentValue('');
  };

  const properties = [].concat(...streams.map(s => s.properties));
  const all = [...properties, ...variables];
  const value = all.find(elem => elem.id === tile.valueId);

  if (!value) {
    return (
      <div
        style={{ fontSize: 25 }}
        data-testid="dataEntryTile-valueDoesNotExistDiv"
      >
        {t('valueDoesNotExist')}
      </div>
    );
  }

  const style = {
    fontSize: getTitleFontSize(height, width, t('ValueToSubmit')),
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      sendData();
    }
  };

  const getInput = () => {
    if (isBool) {
      return (
        <div
          className="innerBlock undraggable"
          data-testid="dataEntryTile-booleanButtonsDiv"
        >
          <DefaultSubmitButton
            className={currentValue ? 'active' : ''}
            label={t('true')}
            onClick={() => setCurrentValue(true)}
            style={style}
            isActive
          />
          <DefaultSubmitButton
            className={currentValue === false ? 'active' : ''}
            label={t('false')}
            onClick={() => setCurrentValue(false)}
            style={style}
            isActive
          />
        </div>
      );
    }
    if (isString) {
      return (
        <TextInput
          className="innerBlock undraggable"
          value={currentValue}
          onChange={setCurrentValue}
          style={style}
          options={[]}
          placeholder={t('ValueToSubmit')}
          onKeyDown={handleKeyDown}
          data-testid="dataEntryTile-input"
        />
      );
    }
    return (
      <input
        type="number"
        pattern="[0-9]*"
        step="0.01"
        value={currentValue}
        className="innerBlock undraggable"
        placeholder={t('ValueToSubmit')}
        onChange={e => {
          const val = e.target.value;
          setCurrentValue(val === '' ? '' : Number(val));
        }}
        onKeyDown={handleKeyDown}
        style={style}
        data-testid="dataEntryTile-input"
      />
    );
  };

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
    >
      <div className="DataEntry">
        <div className="block">
          {getInput()}
        </div>
        <div className="block">
          <DefaultSubmitButton
            label={t('submit')}
            onClick={sendData}
            style={style}
            isActive={isBool !== undefined && currentValue !== ''}
          />
        </div>
      </div>
    </TileContents>
  );
};

DataEntryTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  width: PropTypes.number.isRequired,
};

export default DataEntryTile;
