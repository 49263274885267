import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from '~components/Pages';
import { ModalHandler } from '~UI';
import HoverRoundButton from '~UI/HoverRoundButton/HoverRoundButton';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { reduxOperations } from '~services';
import MachineEventsList from './MachineEventsList';
import PerformanceEventsList from './PerformanceEventsList';
import PartEventsList from './PartEventsList';
import DefaultEventsList from './DefaultEventsList';
import { ExportEventCSVForm } from './ExportEventCSVForm';

import './EventPage.scss';

function EventsPage() {
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const [navSelected, setNavSelected] = useState('machine');
  const [defaultEventNames, setDefaultNames] = useState([]);
  const [filterData, setFilterData] = useState({});
  const dispatch = useDispatch();

  const handleSelectNav = eventKey => {
    setNavSelected(eventKey);
  };

  const renderExportCSVButton = () => {
    let props = {};
    if (navSelected === 'events') {
      props = {
        type: 'DefaultEvent',
        eventNames: defaultEventNames || [],
      };
    } else if (navSelected === 'machine') {
      props = {
        type: 'MachineStatus',
      };
    } else if (navSelected === 'parts') {
      props = {
        type: 'PartEvent',
      };
    } else if (navSelected === 'performance') {
      props = {
        type: 'PerformanceEvent',
      };
    }

    return (
      <ModalHandler
        Trigger={{
          Component: HoverRoundButton,
          props: {
            content: 'CSV',
            position: 1,
            diameter: 70,
          },
        }}
        Modal={{
          Component: ExportEventCSVForm,
          props: {
            ...props,
            CSVType: navSelected,
          },
        }}
      />
    );
  };

  const content = () => (
    <PageTemplate
      sidebar
      right={[renderExportCSVButton()]}
      title={t('events')}
    >
      <div className="EventsPage">
        <Nav bsStyle="tabs" activeKey={navSelected} onSelect={eventKey => handleSelectNav(eventKey)}>
          <NavItem eventKey="machine">
            {t('machine')}
          </NavItem>
          <NavItem eventKey="parts">
            {t('parts')}
          </NavItem>
          <NavItem eventKey="performance">
            {t('performance')}
          </NavItem>
          <NavItem eventKey="events">
            {t('events')}
          </NavItem>

        </Nav>
        {navSelected === 'machine' && <MachineEventsList setParentFilterData={setFilterData} filters={{ ...filterData, eventType: undefined }} />}
        {navSelected === 'parts' && <PartEventsList setParentFilterData={setFilterData} filters={{ ...filterData, eventType: undefined }} />}
        {navSelected === 'performance' && <PerformanceEventsList setParentFilterData={setFilterData} filters={{ ...filterData, eventType: undefined }} />}
        {navSelected === 'events' && <DefaultEventsList setDefaultNames={setDefaultNames} />}
      </div>
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.machines.fetchMachines()),
      ]}
      getContents={content}
    />
  );
}

export default EventsPage;
