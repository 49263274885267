import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { PageTemplate, DefaultTable } from '~components/Pages';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ModalHandler, ResourcesHandler, Cards,
  BasicCard, CreateCard, FontAwesome,
  SquaredAddButton, DefaultModal,
} from '~UI';
import { reduxOperations, reducersTypes } from '~services';
import { configurationFeature } from '~utils/featureToggles';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { getTagListFromUsedTags } from '~utils/tags';
import { filterItems, sortItems } from '~components/SelectionGrid/utils';
import MachineCreationForm from './MachineCreationForm';
import { Page401Unauthorized } from '../../ErrorPages';
import directLinks from '~utils/directLinks';

const displayOptions = {
  CARD: 'card',
  LIST: 'list',
};

const propTypes = {
  storageType: PropTypes.shape({}).isRequired,
};

const unauthorizedPopup = ({ title, children, show, onHide }) => (
  <DefaultModal
    title={title}
    show={show}
    closePopup={onHide}
  >
    <div className="unauthorizedMessage">
      <FontAwesome icon="exclamation-triangle" size="3x" />
      {children}
    </div>
  </DefaultModal>
);

const MachinesSelectionGrid = (
  {
    machines, storageType,
  },
) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const updateLocalStorage = getLocalStorageSetter(storageType);
  const storage = getLocalStorageObject(storageType);
  const [filterText, setFilterText] = useState(storage.filterText || '');
  const [display, setDisplay] = useState(storage.display || displayOptions.CARD);
  const [sortInput, setSortInput] = useState(storage.sortParameter || '-');
  const [selectedTags] = useState(storage.tags || ['all']);
  const [showUnauthorizedPopup, setShowUnauthorizedModalPopup] = useState(false);
  const sortedItems = sortItems(machines, sortInput);
  let usedSelectedTags = getTagListFromUsedTags(sortedItems, selectedTags);
  usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
  const filteredItems = filterItems(filterText, usedSelectedTags, sortedItems);

  function onSortChange(newSortInput) {
    updateLocalStorage('sortParameter', newSortInput);
    setSortInput(newSortInput);
  }

  function handleDisplay(newValue) {
    updateLocalStorage('display', newValue);
    setDisplay(newValue);
  }

  return (
    <div>
      <div className="SearchBar">
        <div>
          {`${t('Search')}:`}
          <Input
            clearable
            onChange={value => {
              setFilterText(value);
              updateLocalStorage('filterText', value);
            }}
            onClear={() => {
              setFilterText('');
              updateLocalStorage('filterText', '');
            }}
            placeholder={t('machineName')}
            style={{
              display: 'inline-block',
              margin: '0 5px',
              width: 250,
            }}
            value={filterText}
          />
          {`${t('sortBy')}:`}
          <Select
            style={{ display: 'inline-block', width: '300px' }}
            className="sortInput inputFilter"
            value={sortInput}
            onChange={e => onSortChange(e)}
            options={[
              { label: '-', value: '-' },
              { label: t('name'), value: 'byName' },
            ]}
          />

          {/* This part of code is responsible of the sorting by creation date
            TODO : You can uncomment this if and only if you incorporate the creation date into the model */}

          {/* <option value="byCreationDateAscending">
                {t('creationDateNewest')}
              </option>
              <option value="byCreationDateDescending">
                {t('creationDateOldest')}
              </option> */}

        </div>
        <div className="displayIconContainer">
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.CARD)}
          >
            <FontAwesome icon="th" />
          </div>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.LIST)}
          >
            <FontAwesome icon="list-ul" />
          </div>
          {
            display === displayOptions.LIST && (
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={configurationFeature.isUserAllowedAccessAdmin() ? {
                  Component: MachineCreationForm,
                  props: { machines: null, isAdding: true },
                } : {
                  Component: unauthorizedPopup,
                  props: { title: t('unauthorized'), children: t('unauthorizedMessage'), show: showUnauthorizedPopup, onHide: () => setShowUnauthorizedModalPopup(false) },
                }}
              />
            )
          }
        </div>
      </div>
      <div>
        {
          display === displayOptions.CARD ? (
            <Cards>
              <CreateCard
                title={t('addMachine')}
                modal={configurationFeature.isUserAllowedAccessAdmin() ? {
                  Component: MachineCreationForm,
                  props: { machines: null, isAdding: true },
                } : {
                  Component: unauthorizedPopup,
                  props: { title: t('unauthorized'), children: t('unauthorizedMessage'), show: showUnauthorizedPopup, onHide: () => setShowUnauthorizedModalPopup(false) },
                }}
              />
              {filteredItems.map(machine => (
                <BasicCard
                  key={machine.id}
                  title={machine.name}
                  icon={<FontAwesome icon="cogs" size="4x" />}
                  deleteModal={configurationFeature.isUserAllowedAccessMachines() && {
                    Component: DeleteConfirmationForm,
                    props: { handleDelete: () => dispatch(reduxOperations.machines.deleteMachine(machine.id)) },
                  }}
                  onClick={() => history.push(directLinks.machine(machine.id, location.pathname))}
                />
              ))}
            </Cards>
          )
            : (
              <div>
                <DefaultTable
                  columnNames={[
                    { name: t('name') },
                  ]}
                  entriesProperties={['name']}
                  entries={filteredItems}
                  editFunction={id => history.push(directLinks.machine(id, location.pathname))}
                  deleteFunction={machine => dispatch(reduxOperations.machines.deleteMachine(machine))}
                  onClickEvent={id => history.push(directLinks.machine(id, location.pathname))}
                />
              </div>
            )}
      </div>
    </div>
  );
};

MachinesSelectionGrid.propTypes = {
  machines: reducersTypes.machines.isRequired,
  storageType: PropTypes.shape({}).isRequired,
};

const MachineSelectionPage = ({ storageType }) => {
  const dispatch = useDispatch();

  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);
  const operations = useSelector(state => state.operations.operations);
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);

  const getContents = () => (
    configurationFeature.isUserAllowedAccessMachines() ? (
      <PageTemplate
        sidebar
      >
        <MachinesSelectionGrid
          language={language}
          machines={machines}
          storageType={storageType}
        />
      </PageTemplate>
    ) : (
      <Page401Unauthorized />
    )
  );

  return (
    <ResourcesHandler
      resources={[machines, operations, streams, variables]}
      resourceFetchers={[
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
      ]}
      getContents={getContents}
    />
  );
};

MachineSelectionPage.propTypes = propTypes;

export default withRouter(MachineSelectionPage);
