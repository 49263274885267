import { Audio } from './types';

export enum AudioActionTypes {
  ADD_AUDIO = 'ADD_AUDIO',
  REMOVE_AUDIO = 'REMOVE_AUDIO',
}

export type AudioAction =
  | { type: AudioActionTypes.ADD_AUDIO; payload: Audio }
  | { type: AudioActionTypes.REMOVE_AUDIO; payload: string };

export const addAudio = (audio): AudioAction => ({
  type: AudioActionTypes.ADD_AUDIO,
  payload: audio,
});

export const removeAudio = (id: string): AudioAction => ({
  type: AudioActionTypes.REMOVE_AUDIO,
  payload: id,
});
