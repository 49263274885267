import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ImagePopUpForm from '../../../pages/Config/Images/ImagePopUpForm';
import ImageSelect from './ImageSelect';
import { Popover } from '~components/Popover';
import { showError } from '~utils/toast';
import { configurationFeature } from '~utils/featureToggles';
import './FileUpload.scss';

const FileUpload = ({
  onFileSelect, image, setImage, images, type, options,
}) => {
  const { t } = useTranslation();

  const [showImageSelect, setShowImageSelect] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);

  function handleChange(event) {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      showError(t('noFileSelected'));
      return;
    }
    if (selectedFile.size > 4.8 * 1024 * 1024) {
      showError(t('fileSizeError'));
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageInfo = {
        data: reader.result,
        contentType: selectedFile.type,
        name: selectedFile.name,
        locked: configurationFeature.isUserAllowedAccessAdmin(),
      };
      onFileSelect(imageInfo);
    };
    reader.readAsBinaryString(selectedFile);
  }

  return (
    <div className="selectFileContainer">
      {type !== 'popupForm' && images?.length > 0 && (
        <>
          <Popover
            show={showImageSelect}
            className={`image-body ${options?.popoverTop ? 'popover-top' : ''}`}
            setShow={setShowImageSelect}
            content={(
              <ImageSelect
                images={images}
                setImage={setImage}
                image={image}
                setShow={setShowImageSelect}
              />
            )}
          >
            <button
              type="button"
              onClick={e => {
                e.stopPropagation();
                setShowImageSelect(!showImageSelect);
              }}
              className={options?.blueButton ? 'DefaultSubmitButton IsActive' : 'selectButton'}
            >
              {t('select')}
            </button>
          </Popover>
          <div className="orText">{t('or')}</div>
        </>
      )}
      {type === 'causesIcons' ? (
        <>
          <ImagePopUpForm show={showImageUpload} onHide={() => setShowImageUpload(false)} onSubmit={onFileSelect} />
          <div
            className="fileUploadLabel"
            onClick={() => setShowImageUpload(true)}
            style={{ marginLeft: '10px' }}
          >
            {t('addImage')}
          </div>
        </>
      ) : (
        <>
          <input
            type="file"
            id="fileUploadInput"
            onChange={handleChange}
          />
          <label
            htmlFor="fileUploadInput"
            className="fileUploadLabel"
          >
            {t('uploadImage')}
          </label>
        </>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  onFileSelect: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    contentType: PropTypes.string,
    data: PropTypes.string,
  })).isRequired,
  type: PropTypes.string,
  options: PropTypes.shape({
    popoverTop: PropTypes.bool,
    blueButton: PropTypes.bool,
  }),
};

FileUpload.defaultProps = {
  type: 'default',
};

export default FileUpload;
