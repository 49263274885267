import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'react-bootstrap';

import './DashboardsSettings.scss';

const DashboardsSettings = ({ dashboardSizes, setDashboardSizes }) => {
  const { t } = useTranslation();

  const [openColumns, setOpenColumns] = useState(false);
  const [openBreakpoints, setOpenBreakpoints] = useState(false);

  const handleInputChange = (name, value) => {
    setDashboardSizes(prevState => {
      const newState = { ...prevState };
      const keys = name.split('.');
      keys.reduce((obj, key, index) => {
        if (index === keys.length - 1) {
          obj[key] = value;
        } else {
          obj[key] = { ...obj[key] };
        }
        return obj[key];
      }, newState);
      return newState;
    });
  };

  return (
    <div>
      <div className="dashboardsSettingsModificationForm">
        <div>
          <div className="dashboardsSettingsTitle">
            {t('dashboardSizes')}
          </div>
          <div className="formsContainer">
            <div className="settingsInputGroup">
              <div>
                <div className="inputTitle">{t('dashboardsSizesRows')}</div>
                <input
                  name="rows"
                  value={dashboardSizes.rows}
                  onChange={e => handleInputChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
            <div className="settingsInputGroup">
              <div className="collapsibleInputTitle">
                <div className="inputTitle">{t('dashboardsSizesColumns')}</div>
                <span
                  className="arrowRight"
                  onClick={() => setOpenColumns(!openColumns)}
                >
                  &rang;
                </span>
              </div>
              <Collapse in={openColumns}>
                <div className="sizesContainer">
                  <div>
                    <div>{t('large')}</div>
                    <input
                      name="column.large"
                      value={dashboardSizes.column.large}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>{t('medium')}</div>
                    <input
                      name="column.medium"
                      value={dashboardSizes.column.medium}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>{t('small')}</div>
                    <input
                      name="column.small"
                      value={dashboardSizes.column.small}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>
                      Extra
                      {' '}
                      {t('small')}
                    </div>
                    <input
                      name="column.xsmall"
                      value={dashboardSizes.column.xsmall}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>
                      Extra Extra
                      {' '}
                      {t('small')}
                    </div>
                    <input
                      name="column.xxsmall"
                      value={dashboardSizes.column.xxsmall}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <div className="settingsInputGroup">
              <div className="collapsibleInputTitle">
                <div className="inputTitle">{t('dashboardsSizesBreakpoints')}</div>
                <span
                  className="arrowRight"
                  onClick={() => setOpenBreakpoints(!openBreakpoints)}
                >
                  &rang;
                </span>
              </div>
              <Collapse in={openBreakpoints}>
                <div className="sizesContainer">
                  <div>
                    <div>{t('large')}</div>
                    <input
                      name="breakpoint.large"
                      value={dashboardSizes.breakpoint.large}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>{t('medium')}</div>
                    <input
                      name="breakpoint.medium"
                      value={dashboardSizes.breakpoint.medium}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>{t('small')}</div>
                    <input
                      name="breakpoint.small"
                      value={dashboardSizes.breakpoint.small}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                  <div>
                    <div>
                      Extra
                      {' '}
                      {t('small')}
                    </div>
                    <input
                      name="breakpoint.xsmall"
                      value={dashboardSizes.breakpoint.xsmall}
                      onChange={e => handleInputChange(e.target.name, e.target.value)}
                    />
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardsSettings.propTypes = {
  dashboardSizes: PropTypes.shape({
    rows: PropTypes.number.isRequired,
    column: PropTypes.shape({
      large: PropTypes.number.isRequired,
      medium: PropTypes.number.isRequired,
      small: PropTypes.number.isRequired,
      xsmall: PropTypes.number.isRequired,
      xxsmall: PropTypes.number.isRequired,
    }).isRequired,
    breakpoint: PropTypes.shape({
      large: PropTypes.number.isRequired,
      medium: PropTypes.number.isRequired,
      small: PropTypes.number.isRequired,
      xsmall: PropTypes.number.isRequired,
      xxsmall: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  setDashboardSizes: PropTypes.func.isRequired,

};

export { DashboardsSettings };
