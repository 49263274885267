import i18n from 'i18next';
import { showError } from '~utils/toast';

const validateVarNamesDollarSign = expression => {
  const regex1 = /\$[A-Za-z][A-Za-z0-9_]*/g;
  const varWithDollarSign = expression.match(regex1) || [];

  const regex2 = /#[A-Za-z][A-Za-z0-9_]*/g;
  const varWithParameterSign = expression.match(regex2) || [];

  // Split the string into segments that are inside and outside of double quotes
  const segments = expression.split(/(".*?")/);

  // Check each segment that's enclosed in double quotes
  for (let i = 1; i < segments.length; i += 2) {
    if (segments[i].includes('${')) {
      showError(i18n.t('invalidFormData'));
      return true;
    }
  }

  // Get the segments that are not enclosed in double quotes
  const outsideQuotes = segments.filter((_, index) => index % 2 === 0).join('');

  const regex3 = /[A-Za-z][A-Za-z0-9_]*/g;
  let varWithoutSign = outsideQuotes.match(regex3) || [];
  varWithoutSign = varWithoutSign.filter(ex => ex !== 'false' && ex !== 'true');

  if ((varWithDollarSign.length + varWithParameterSign.length) !== varWithoutSign.length) {
    showError(i18n.t('expressionNeedDollarSign'));
    return true;
  }

  return false;
};

export default validateVarNamesDollarSign;
