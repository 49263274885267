import API from '~services/endpoints';
import {
  getTagsRequest,
  getTagsResponse,
  addTagRequest,
  addTagResponse,
  updateTagRequest,
  updateTagResponse,
  deleteTagRequest,
  deleteTagResponse,
} from './actions';
import { fetchOnceOperation, fetchOperation } from '../fetchOperation';

const fetchTags = () => fetchOnceOperation(
  getTagsRequest,
  getTagsResponse,
  API.getTags,
  state => state.tags.tags,
);
const forceFetchTags = () => fetchOperation(
  getTagsRequest,
  getTagsResponse,
  API.getTags,
  state => state.tags.tags,
);

const addTag = tag => dispatch => {
  dispatch(addTagRequest(tag));
  return API.createTag(tag)
    .then(res => dispatch(addTagResponse(res)));
};

const updateTag = (tagId, tagName) => dispatch => {
  dispatch(updateTagRequest(tagId, tagName));
  return API.updateTag(tagId, tagName)
    .then(res => dispatch(updateTagResponse(res)));
};

const deleteTag = tagId => dispatch => {
  dispatch(deleteTagRequest(tagId));
  return API.deleteTag(tagId)
    .then(res => dispatch(deleteTagResponse(res)));
};

export default {
  fetchTags,
  forceFetchTags,
  addTag,
  updateTag,
  deleteTag,
};
