import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '~UI/index';
import ConfirmationForm from './ConfirmationForm';

const propTypes = {
  handleQuit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // global state props
};

const QuitConfirmationForm = ({ handleQuit, show, onHide }) => {
  const { t } = useTranslation();

  const title = () => (
    <>
      <FontAwesome
        icon="exclamation-triangle"
        color="#bf3838"
      />
        &nbsp;
      {t('quitConfirmation')}
    </>
  );

  return (
    <ConfirmationForm
      title={title()}
      body={(
        <>
          {t('beSureQuit')}
          <br />
          {t('unsavedChanges')}
        </>
      )}
      actionLabel={t('quit')}
      show={show}
      onHide={onHide}
      handleAction={handleQuit}
      confirmButtonColor="#bf3838"
    />
  );
};

function mapStateToProps(state) {
  return {
    language: state.views.language,
  };
}

QuitConfirmationForm.propTypes = propTypes;

export default connect(mapStateToProps)(QuitConfirmationForm);
