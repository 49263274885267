import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import API from '~services/endpoints';
import { CancelButton } from '~UI/Buttons';
import { FontAwesome } from '~UI/index';
import { DefaultModal } from '~UI/DefaultModal';
import { showSuccess } from '~utils/toast';
import './LinkRedirectionPopup.scss';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.string.isRequired,
};

const defaultProps = {
  onHide: () => undefined,
  show: false,
};

const LinkRedirectionPopup = ({ show, onHide, contentType, contentId }) => {
  const { t } = useTranslation();

  const [linkId, setLinkId] = useState<string | null>(null);

  const buttons = [
    { label: 'EN', value: 'en' },
    { label: 'FR', value: 'fr' },
    { label: 'ES', value: 'es' },
  ];

  const fetchLink = () => {
    API.addLinkRedirection({ contentType, contentId }).then(res => {
      setLinkId(`${window.location.protocol}//${window.location.host}/redirect/${res.linkRedirection}`);
    });
  };

  const handleLanguageChange = language => {
    if (!linkId) {
      return;
    }

    const url = new URL(linkId);
    const pathParts = url.pathname.split('/');

    if (pathParts.length >= 3 && ['en', 'fr', 'es'].includes(pathParts[2])) {
      pathParts[2] = language;
    } else {
      pathParts.splice(2, 0, language);
    }

    url.pathname = pathParts.join('/');
    setLinkId(url.toString());
  };

  const removeLanguageFromLink = () => {
    if (!linkId) {
      return;
    }

    const url = new URL(linkId);
    const pathParts = url.pathname.split('/');

    if (pathParts.length >= 3 && ['en', 'fr', 'es'].includes(pathParts[2])) {
      pathParts.splice(2, 1);
    }

    url.pathname = pathParts.join('/');
    setLinkId(url.toString());
  };

  useEffect(() => {
    if (show && !linkId) {
      fetchLink();
    }
  }, [show, linkId]);

  const copyAndClose = () => {
    if (linkId) {
      navigator.clipboard.writeText(linkId);
      removeLanguageFromLink();
      onHide();
      showSuccess(t('linkCopied'));
    }
  };

  return (
    <DefaultModal
      title={t('shareLink')}
      show={show}
      closePopup={() => {
        removeLanguageFromLink();
        onHide();
      }}
    >
      <div className="inputTitle">{t('language')}</div>
      <div className="languageSelector">
        <ButtonGroup
          buttons={buttons}
          onChange={handleLanguageChange}
          disabled={!linkId}
        />
      </div>
      <div className="LinkRedirectionPopup">
        {linkId || t('loading')}
        <FontAwesome
          icon="clone"
          className="copyIcon"
          onClick={copyAndClose}
        />
      </div>

      <div className="LinkRedirectionFooter">
        <CancelButton onClick={() => {
          removeLanguageFromLink();
          onHide();
        }}
        />
      </div>
    </DefaultModal>
  );
};

LinkRedirectionPopup.propTypes = propTypes;
LinkRedirectionPopup.defaultProps = defaultProps;

export default LinkRedirectionPopup;
