import { sortArray } from '~utils/sort';
import { elementHasAllSelectedTags } from '~utils/tags';

function filterItems(filter, selectedTags, items) {
  return items.filter(item => {
    const name = item.name.toUpperCase();
    return name.indexOf(filter.toUpperCase()) > -1
      && elementHasAllSelectedTags(item, selectedTags);
  });
}

function sortItems(items, sortInput) {
  switch (sortInput) {
    case 'byName':
      return sortArray('alphabetically', items, 'name');
    case 'byDescription':
      return sortArray('alphabetically', items, 'description');
    case 'byCreationDateAscending':
      return sortArray('numberDescending', items, 'createdAt');
    case 'byCreationDateDescending':
      return sortArray('numberAscending', items, 'createdAt');
    case 'byModificationDateAscending':
      return sortArray('numberDescending', items, 'modifiedAt');
    case 'byModificationDateDescending':
      return sortArray('numberAscending', items, 'modifiedAt');
    default:
      return items;
  }
}

export {
  filterItems,
  sortItems,
};
