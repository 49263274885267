import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Action } from 'redux';
import { RuleGrid } from '~components/Pages';
import directLinks from '~utils/directLinks';
import { triggerTypes } from '~utils/types';
import { RootState } from '~services/store';

const TriggersPage = () => {
  const { t } = useTranslation();
  const actions = useSelector((state: RootState) => state.actions);
  const triggers = useSelector((state: RootState) => state.triggers);

  const history = useHistory();
  const location = useLocation();
  const parseTriggers = () => {
    const ruleTiles = triggers.map(trigger => {
      const ruleActions : Action[] = [];
      // For each actionToPerform
      trigger.actionsToPerform.forEach(actionToPerform => {
        // find the actionToPerform info with its id
        const found = actions?.find(action => actionToPerform.id === action.id);
        if (found) {
          ruleActions.push(found);
        }
      });

      // parse default with type to fakeDefaults
      const typedTrigger = (trigger.type === triggerTypes.DEFAULT && trigger.details && trigger.details.type)
        ? { ...trigger, type: trigger.details.type }
        : trigger;
      const tile = {
        ...typedTrigger,
        actions: ruleActions,
        onClick: () => {
          history.push(directLinks.configRuleEngine(trigger.id, location.pathname));
        },
      };

      return tile;
    });
    return [{
      type: '+',
      name: t('createRule'),
      actions: [],
      onClick: () => {
        history.push(directLinks.configRuleEngine('newTrigger', location.pathname));
      },
    }, ...ruleTiles];
  };

  return (
    <RuleGrid
      tiles={parseTriggers()}
    />
  );
};

export default TriggersPage;
