import types from '../types';

const addPerformanceCauseRequest = data => ({
  type: types.ADD_PERFORMANCE_CAUSE_REQUEST,
  payload: data,
});

const addPerformanceCauseResponse = (data, request = null) => ({
  type: types.ADD_PERFORMANCE_CAUSE_RESPONSE,
  payload: data,
  request,
});

const updatePerformanceCauseRequest = data => ({
  type: types.UPDATE_PERFORMANCE_CAUSE_REQUEST,
  payload: data,
});

const updatePerformanceCauseResponse = (data, request = null) => ({
  type: types.UPDATE_PERFORMANCE_CAUSE_RESPONSE,
  payload: data,
  request,
});

const deletePerformanceCauseRequest = data => ({
  type: types.DELETE_PERFORMANCE_CAUSE_REQUEST,
  payload: data,
});

const deletePerformanceCauseResponse = (data, request = null) => ({
  type: types.DELETE_PERFORMANCE_CAUSE_RESPONSE,
  payload: data,
  request,
});

const addSubPerformanceCauseRequest = data => ({
  type: types.ADD_SUB_PERFORMANCE_CAUSE_REQUEST,
  payload: data,
});

const addSubPerformanceCauseResponse = (data, request = null) => ({
  type: types.ADD_SUB_PERFORMANCE_CAUSE_RESPONSE,
  payload: data,
  request,
});

const updateSubPerformanceCauseRequest = data => ({
  type: types.UPDATE_SUB_PERFORMANCE_CAUSE_REQUEST,
  payload: data,
});

const updateSubPerformanceCauseResponse = (data, request = null) => ({
  type: types.UPDATE_SUB_PERFORMANCE_CAUSE_RESPONSE,
  payload: data,
  request,
});

export {
  addPerformanceCauseRequest,
  addPerformanceCauseResponse,
  updatePerformanceCauseRequest,
  updatePerformanceCauseResponse,
  deletePerformanceCauseRequest,
  deletePerformanceCauseResponse,
  addSubPerformanceCauseRequest,
  addSubPerformanceCauseResponse,
  updateSubPerformanceCauseRequest,
  updateSubPerformanceCauseResponse,
};
