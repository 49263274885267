import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import types from './types';

const stopCausePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
});

const stopCausesPropTypes = PropTypes.arrayOf(PropTypes.shape({
  stopCausePropTypes,
  subMenu: PropTypes.arrayOf(stopCausePropTypes),
}));

const performanceCausePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
});

const performanceCausesPropTypes = PropTypes.arrayOf(PropTypes.shape({
  performanceCausePropTypes,
  subMenu: PropTypes.arrayOf(performanceCausePropTypes),
}));

const defectCausePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
});

const defectCausesPropTypes = PropTypes.arrayOf(PropTypes.shape({
  defectCausePropTypes,
  subMenu: PropTypes.arrayOf(defectCausePropTypes),
}));

const machinePropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  streamId: PropTypes.string,
  skuNumbers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    unitCompletionTime: PropTypes.number,
    description: PropTypes.string,
  })),
  params: (PropTypes.shape({
    operation: PropTypes.string,
    operator: PropTypes.string,
    skuNumber: PropTypes.string,
    workOrder: PropTypes.string,
  })),
  stopCauses: stopCausesPropTypes,
  performanceCauses: performanceCausesPropTypes,
  defectCauses: defectCausesPropTypes,
});

const machinesPropTypes = PropTypes.arrayOf(machinePropTypes);

function machines(state: any[] | null = null, action) {
  switch (action.type) {
    case types.GET_MACHINES_RESPONSE:
      if (!action.payload.error) {
        return action.payload.machines;
      }
      return state;
    case types.ADD_MACHINE_RESPONSE:
      return (state || []).concat(action.payload.machine);
    case types.UPDATE_MACHINE_RESPONSE:
    case types.PATCH_MACHINE_RESPONSE:
    case types.ADD_STOP_CAUSE_RESPONSE:
    case types.UPDATE_STOP_CAUSE_RESPONSE:
    case types.ADD_SUB_STOP_CAUSE_RESPONSE:
    case types.UPDATE_SUB_STOP_CAUSE_RESPONSE:
    case types.DELETE_STOP_CAUSE_RESPONSE:
    case types.DELETE_SUB_STOP_CAUSE_RESPONSE:
    case types.ADD_PERFORMANCE_CAUSE_RESPONSE:
    case types.UPDATE_PERFORMANCE_CAUSE_RESPONSE:
    case types.ADD_SUB_PERFORMANCE_CAUSE_RESPONSE:
    case types.UPDATE_SUB_PERFORMANCE_CAUSE_RESPONSE:
    case types.DELETE_PERFORMANCE_CAUSE_RESPONSE:
    case types.ADD_MACHINE_SHIFT_RESPONSE:
    case types.UPDATE_MACHINE_SHIFT_RESPONSE:
    case types.DELETE_MACHINE_SHIFT_RESPONSE:
      return (state || []).map(machine => (
        (machine.id === action.payload.machine.id) ? action.payload.machine : machine
      ));
    case types.ADD_DEFECT_CAUSE_RESPONSE:
    case types.UPDATE_DEFECT_CAUSE_RESPONSE:
    case types.ADD_SUB_DEFECT_CAUSE_RESPONSE:
    case types.UPDATE_SUB_DEFECT_CAUSE_RESPONSE:
    case types.DELETE_DEFECT_CAUSE_RESPONSE:
      return (state || []).map(machine => (
        (machine.id === action.payload.machine.id) ? action.payload.machine : machine
      ));
    case types.DELETE_MACHINE_RESPONSE:
      if (!action.payload.error) {
        return (state || []).filter(machine => machine.id !== action.payload);
      }
      return state;
    case types.ADD_KPI_RESPONSE:
      return (state || []).map(machine => {
        if (machine.id === action.payload.machineId) {
          const { kpi } = action.payload;
          return {
            ...machine,
            kpis: machine.kpis.concat(kpi),
          };
        }
        return machine;
      });
    case types.UPDATE_KPI_RESPONSE:
      return (state || []).map(machine => {
        if (machine.id === action.payload.machineId) {
          const { kpi } = action.payload;
          return {
            ...machine,
            kpis: machine.kpis.map(k => (k.id !== kpi.id ? k : kpi)),
          };
        }
        return machine;
      });
    case types.DELETE_KPI_RESPONSE:
      return (state || []).map(machine => {
        if (machine.id === action.payload.machineId) {
          const { kpi } = action.payload;
          return {
            ...machine,
            kpis: machine.kpis.filter(k => k.id !== kpi.id),
          };
        }
        return machine;
      });
    case types.SET_PARAMS_RESPONSE:
      return (state || []).map(machine => {
        if (machine.id === action.machineId) {
          return { ...machine, params: action.payload };
        }
        return machine;
      });
    default:
      return state;
  }
}

export const propTypes = {
  machines: nullable(machinesPropTypes),
  stopCauses: nullable(stopCausesPropTypes),
  stopCause: nullable(stopCausePropTypes),
  performanceCauses: nullable(performanceCausesPropTypes),
  performanceCause: nullable(performanceCausePropTypes),
  defectCauses: nullable(defectCausesPropTypes),
  defectCause: nullable(defectCausePropTypes),
};

export default machines;
