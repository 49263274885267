import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { sortArray } from '~utils/sort';

const FormDataEntry = ({ selectedObject }) => {
  const { t } = useTranslation();

  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);

  const properties = [].concat(...streams.map(s => s.properties));
  const all = [...properties, ...variables];
  const options = sortArray('alphabetically', all, 'variable').map(e => ({ label: e.variable, value: e.id }));
  const valueStillExists = !selectedObject.valueId || all.find(v => v.id === selectedObject.valueId);

  return (
    <div>
      <div className="inputTitle">{t('variable')}</div>
      {!valueStillExists && `${t('errorDeletedVariable')}.`}
      <Select
        name="valueId"
        options={options}
        defaultValue={selectedObject.valueId}
        placeholder={t('select')}
      />
    </div>
  );
};

FormDataEntry.propTypes = {
  selectedObject: PropTypes.object,
};
FormDataEntry.defaultProps = {
  selectedObject: {},
};

export { FormDataEntry };
