import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import ActionEditionForm from '../../../../pages/Config/RuleEngine/ruleEngineEdition/actions/ActionEditionForm.jsx';
import { DefaultModal, FontAwesome } from '~UI';
import TagsSelection from '~UI/Tags/TagsSelection';
import { getActionIcon, getTriggerIcon } from '~utils/icons';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { reduxOperations } from '~services';
import { sortArray } from '~utils/sort';
import { stopPropagation } from '~utils';
import { handleTagSelection, elementHasAllSelectedTags, idsToTags, getTagListFromUsedTags } from '~utils/tags';
import { DeleteConfirmationForm, TriggerDuplicationPopup } from '~components/Popups';
import CardActions from '~UI/Cards/CardActions/CardActions';
import { showSuccess, showError } from '~utils/toast';
import directLinks from '~utils/directLinks';
import { triggerTypes } from '~utils/types';
import RuleTagsForm from './RuleTagsForm/RuleTagsForm.jsx';
import './RuleGrid.scss';

const updateLocalStorage = getLocalStorageSetter('K2_ruleEngine');

const sortList = (tiles, sortInput) => {
  if (sortInput === 'byName') {
    return sortArray('alphabetically', tiles, 'name');
  }
  if (sortInput === 'byNbUsesAscending') {
    return sortArray('numberAscending', tiles, 'triggerCount');
  }
  if (sortInput === 'byNbUsesDescending') {
    return sortArray('numberDescending', tiles, 'triggerCount');
  }
  if (sortInput === 'byType') {
    return sortArray('alphabetically', tiles, 'type');
  }
  return tiles;
};

const getActions = actions => actions.map((action, index) => {
  if (index < 7) {
    return (
      <span
        className="actionIcon"
        key={`actionIcon${action.id}${index}`}
      >
        <FontAwesome icon={getActionIcon(action.type)} />
      </span>
    );
  }
  if (index === 7) {
    return (
      <span
        className="actionIcon"
        key={`actionIcon${action.id}${index}`}
      >
        ...
      </span>
    );
  }
  return null;
});

const cloneAndCopy = obj => {
  const newObject = omit(obj, ['id', 'triggerCount', 'onClick', 'actions']);
  newObject.name += ' (Copie)';
  return newObject;
};

const deleteConditionsIds = trigger => {
  if (trigger.condition) {
    delete trigger.condition.id;
    if (trigger.condition.type !== 'Expression') {
      trigger.condition.conditions.forEach(subCond => {
        delete subCond.id;
        if (subCond.type !== 'Expression') {
          subCond.conditions.forEach(subSubCond => {
            delete subSubCond.id;
          });
        }
      });
    }
  }
};

const RuleGrid = ({ isActionList, isPopup, tiles }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const triggers = useSelector(state => state.triggers);
  const actions = useSelector(state => state.actions);

  const tags = useSelector(state => state.tags.tags);
  const history = useHistory();
  const location = useLocation();
  const storage = getLocalStorageObject('K2_ruleEngine');
  const [actionsSearchInput, setActionsSearchInput] = useState(storage.actionsSearchInput || '');
  const [triggersSearchInput, setTriggersSearchInput] = useState(storage.triggersSearchInput || '');
  const [sortInput, setSortInput] = useState(isActionList ? storage.actionSortParameter : storage.triggerSortParameter);
  const [selectedTags, setSelectedTags] = useState(isActionList ? storage.actionTags || ['all'] : storage.triggerTags || ['all']);
  const [triggerToDuplicate, setTriggerToDuplicate] = useState(null);
  const [showPopupId, setShowPopupId] = useState(null);
  const [isCrud, setIsCrud] = useState(false);
  const [actionToUpdateId, setActionToUpdateId] = useState(null);

  /*
  // TODO
  useEffect(() => {
    // Tooltip.rebuild();
  });
  */

  const getTriggerCountByAction = tilesArg => tilesArg.map(actionTile => {
    let triggerCount = 0;
    triggers.forEach(trigger => {
      const usesOfActionInTrigger = trigger.actionsToPerform.filter(action => (
        actionTile.id === action.id
      ));
      triggerCount += usesOfActionInTrigger.length;
    });
    return { triggerCount, ...actionTile };
  });

  const onFilterChange = e => {
    updateLocalStorage(isActionList ? 'actionSortParameter' : 'triggerSortParameter', e);
    setSortInput(e);
  };

  const handleActionDuplication = (e, action) => {
    stopPropagation(e);
    const newAction = cloneAndCopy(action);
    newAction.tags = idsToTags(newAction.tags, tags);
    return dispatch(reduxOperations.actions.createAction(newAction))
      .then(res => { showSuccess(t('showSuccessCreated')); return { id: res.payload.action.id }; })
      .catch(() => showError(t('invalidFormData')));
  };

  const handleTriggerDuplication = async (e, trigger, actionsToDuplicate) => {
    stopPropagation(e);
    const newTrigger = cloneAndCopy(trigger);
    newTrigger.type = (newTrigger.type === 'ButtonTrigger' || newTrigger.type === 'WebhookTrigger') ? 'DefaultTrigger' : newTrigger.type;
    newTrigger.tags = idsToTags(newTrigger.tags, tags);
    if (!actionsToDuplicate) {
      newTrigger.actionsToPerform = [];
    } else if (actionsToDuplicate.length) {
      newTrigger.actionsToPerform = await Promise.all(actionsToDuplicate.map(action => (
        handleActionDuplication(null, action))));
    }
    deleteConditionsIds(newTrigger);
    dispatch(reduxOperations.triggers.addTrigger(newTrigger))
      .then(() => showSuccess(t('showSuccessCreated')))
      .catch(() => showError(t('invalidFormData')));
  };

  const toggleRuleActivation = tile => {
    dispatch(reduxOperations.triggers.patchTrigger(tile.id, { isActive: !tile.isActive }))
      .then(() => showSuccess(t('showSuccessUpdated')))
      .catch(() => showError(t('error')));
  };

  const getTriggersByAction = actionId => {
    const triggersByAction = triggers.filter(trigger => trigger.actionsToPerform
      .find(action => (actionId === action.id)));
    const triggerTiles = triggersByAction.map(trigger => {
      const ruleActions = [];
      // For each actionToPerform
      trigger.actionsToPerform.forEach(actionToPerform => {
        // find the actionToPerform info with its id
        const found = actions.find(action => actionToPerform.id === action.id);
        if (found) {
          ruleActions.push(found);
        }
      });

      // parse default with type to fakeDefaults
      const typedTrigger = (trigger.type === triggerTypes.DEFAULT && trigger.details && trigger.details.type)
        ? { ...trigger, type: trigger.details.type }
        : trigger;
      const tile = {
        ...typedTrigger,
        actions: ruleActions,
        onClick: () => {
          history.push(directLinks.configRuleEngine(trigger.id, location.pathname));
        },
      };
      return tile;
    });
    return triggerTiles;
  };

  const toggleCRUDPage = (e, actionIdArg) => {
    stopPropagation(e);
    setActionToUpdateId(actionIdArg);
    setIsCrud(prevIsCrud => !prevIsCrud);
  };

  const closePopup = () => {
    setShowPopupId(null);
  };

  const showPopup = (e, popupId) => {
    stopPropagation(e);
    setShowPopupId(popupId);
  };

  const renderPopup = (popupId, name, tilesToShow) => (
    <DefaultModal
      show={showPopupId === popupId}
      title={`${isActionList ? t('usesOf') : t('actionsOf')} "${name}"`}
      closePopup={closePopup}
    >
      <RuleGrid
        isActionList={!isActionList}
        isPopup
        tiles={tilesToShow}
      />
    </DefaultModal>
  );

  const getRuleOrActionCard = (tile, isActionListArg, index, tagsArg) => {
    const tileTags = idsToTags(tile.tags || [], tagsArg);
    const filteredTags = tileTags.filter(tag => !!tag);

    const tagsColors = filteredTags.length > 0 && (
      <div
        className="cardTagsContainer"
        key={tile.id}
      >
        { /* <Tooltip className="icon-tooltip" /> */}
        {
          filteredTags.map(tag => tag.color && (
            <span>
              <div
                className="cardTag"
                data-tip={tag.name}
                key={tag.id}
                style={{ background: tag.color }}
              />
            </span>
          ))
        }
      </div>
    );

    return (
      <button
        type="button"
        key={`cell_${index}`}
        className={`${isActionListArg ? 'ActionCard' : 'RuleCard'} ${!tile.isActive && tile.type !== '+' && 'deactivated'}`}
        // onClick={tile.onClick}
        onClick={(isActionList && isPopup) ? e => toggleCRUDPage(e, tile.id) : tile.onClick}
      >
        {
          isActionListArg ? (
            <>
              <div className="iconActionSummary">
                <FontAwesome icon={getActionIcon(tile.type)} />
              </div>
              <div style={{ width: '100%' }}>
                <div className="titleBlock">
                  <div className="summary">
                    <div className="hiddenOverflow title">{tile.name}</div>
                    {tagsColors}
                  </div>
                  {
                    tile.type !== '+' && (
                      <div className="optionsIcon">
                        <FontAwesome icon="clone" onClick={e => handleActionDuplication(e, tile)} color="#bbb" />
                      </div>
                    )
                  }
                </div>
                <div className="summary">
                  <span className={`subSummary ${!isPopup ? 'underline' : ''}`} {... (!isPopup && { onClick: e => showPopup(e, `action_uses_${tile.id}`) })}>
                    {tile.triggerCount === 0 || tile.triggerCount === undefined
                      ? ''
                      : `${tile.triggerCount} ${tile.triggerCount === 1 ? t('use') : t('uses')}`}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={`iconTriggerSummary ${!tile.isActive && tile.type !== '+' && 'deactivated'}`}>
                <FontAwesome icon={getTriggerIcon(tile.type)} />
              </div>
              <div style={{ width: '100%' }}>
                <div className="titleBlock">
                  <div className="summary">
                    <div className="hiddenOverflow title">{tile.name}</div>
                    {tagsColors}
                  </div>
                  {tile.type !== '+' && (
                    <div className="optionsIcon" onClick={e => e.stopPropagation()}>
                      <CardActions
                        card={tile}
                        duplicateAction={() => (tile.actions.length
                          ? setTriggerToDuplicate(tile)
                          : handleTriggerDuplication(null, tile, null)
                        )}
                        deleteModal={{
                          Component: DeleteConfirmationForm,
                          props: { handleDelete: () => dispatch(reduxOperations.triggers.deleteTrigger(tile.id)) },
                        }}
                        editModal={{
                          Component: RuleTagsForm,
                          props: { modifiedItemId: tile.id },
                        }}
                        editModalTitle={t('tags')}
                        editIcon="tags"
                        optionalAction={{
                          optText: tile.isActive
                            ? t('deactivate') : t('activate'),
                          optIcon: tile.isActive ? 'toggle-on' : 'toggle-off',
                          optOnClick: () => toggleRuleActivation(tile),
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="summary">
                  <span className={`subSummary ${!isPopup ? 'underline' : ''}`} {... (!isPopup && { onClick: e => showPopup(e, `trigger_actions_${tile.id}`) })}>
                    {tile.actions.length > 0 && (
                      <span className="actionIcon">
                        {`${tile.actions.length}`}
                      </span>
                    )}
                    {getActions(tile.actions)}
                  </span>
                </div>
              </div>
            </>
          )
        }
      </button>
    );
  };

  const selectTag = (selectedTag, withCtrl) => {
    setSelectedTags(prevSelectedTags => {
      let newTags = handleTagSelection(selectedTag, prevSelectedTags, withCtrl);
      const usedList = getTagListFromUsedTags(tiles, newTags);
      newTags = newTags.filter(tag => usedList.find(usedTag => usedTag === tag) || tag === 'all');
      updateLocalStorage(isActionList ? 'actionTags' : 'triggerTags', newTags);
      return newTags;
    });
  };

  const hasSecondarySearch = (tile, isActionListArg) => {
    if (!tile) {
      return false;
    }
    if (isActionListArg) {
      return Object.keys(tile.params || {}).some(key => {
        if (key.toLowerCase().includes('id')) {
          return false;
        }
        if (typeof tile.params[key] === 'string') {
          return tile.params[key].includes(actionsSearchInput.toLowerCase());
        }
        return false;
      });
    }
    return tile.actions.some(action => action.name.toLowerCase().includes(triggersSearchInput.toLowerCase()));
  };

  let tilesClone = cloneDeep(tiles);
  if (isActionList) {
    tilesClone = getTriggerCountByAction(tilesClone);
  }
  tilesClone = sortList(tilesClone, sortInput, isActionList);

  let usedSelectedTags = getTagListFromUsedTags(tiles, selectedTags);
  usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
  const usedTags = getTagListFromUsedTags(tiles, tags);

  const secondSearchResults = tilesClone
    .filter(tile => (
      (isActionList ? (
        triggersSearchInput === undefined || (!tile.name.toLowerCase().includes(actionsSearchInput.toLowerCase()))) : (
        actionsSearchInput === undefined || (!tile.name.toLowerCase().includes(triggersSearchInput.toLowerCase())))))
      && hasSecondarySearch(tile, isActionList))
    .filter(tile => tile.id && elementHasAllSelectedTags(tile, usedSelectedTags));

  return (
    <div className="RuleGridContainer">
      {
        triggerToDuplicate && (
          <TriggerDuplicationPopup
            trigger={triggerToDuplicate}
            handleTriggerDuplication={handleTriggerDuplication}
            onHide={() => setTriggerToDuplicate(null)}
            show={triggerToDuplicate}
          />
        )
      }
      {usedTags.length > 0 && !(usedTags.length === 1 && tags.includes('all')) && (
        <TagsSelection
          tags={usedTags}
          selectedTags={usedSelectedTags}
          selectTag={selectTag}
          tagInfo={t('tagInfoRule')}
          style={{ padding: '0 90px' }}
        />
      )}
      <div className="RuleGridContainerButtons">
        <div>
          {`${t('Search')}:`}
          {isActionList ? (
            <Input
              clearable
              onChange={value => {
                setActionsSearchInput(value);
                updateLocalStorage('actionsSearchInput', value);
              }}
              onClear={() => {
                setActionsSearchInput('');
                updateLocalStorage('actionsSearchInput', '');
              }}
              placeholder={t('searchByName')}
              style={{
                display: 'inline-block',
                margin: 5,
                width: 250,
              }}
              value={actionsSearchInput}
            />
          ) : (
            <Input
              clearable
              onChange={value => {
                setTriggersSearchInput(value);
                updateLocalStorage('triggersSearchInput', value);
              }}
              onClear={() => {
                setTriggersSearchInput('');
                updateLocalStorage('triggersSearchInput', '');
              }}
              placeholder={t('searchByName')}
              style={{
                display: 'inline-block',
                margin: 5,
                width: 250,
              }}
              value={triggersSearchInput}
            />
          )}
        </div>
        <div>
          {`${t('sortBy')}:`}
          <Select
            className="inputFilter"
            value={sortInput || '-'}
            onChange={onFilterChange}
            options={isActionList ? [
              { label: '-', value: '-' },
              { label: t('name'), value: 'byName' },
              { label: t('nbUsesAscending'), value: 'byNbUsesAscending' },
              { label: t('nbUsesDescending'), value: 'byNbUsesDescending' },
              { label: t('type'), value: 'byType' },
            ] : [
              { label: '-', value: '-' },
              { label: t('name'), value: 'byName' },
              { label: t('type'), value: 'byType' },
            ]}
            placeholder={t('select')}
            style={{ display: 'inline-block', width: 300 }}
          />
        </div>
      </div>
      <div className="RuleGrid">
        {!isPopup && getRuleOrActionCard(tiles[0], isActionList, 0)}
        {!isPopup && <div />}
        {
          tilesClone
            .filter(tile => (isActionList ? (
              actionsSearchInput === undefined
              || (tile.name.toLowerCase().includes(actionsSearchInput.toLowerCase()))) : (
              triggersSearchInput === undefined
              || (tile.name.toLowerCase().includes(triggersSearchInput.toLowerCase())))))
            .filter(tile => elementHasAllSelectedTags(tile, usedSelectedTags))
            .map((tile, index) => tile.id && getRuleOrActionCard(tile, isActionList, index, tags))
        }
      </div>
      {!!secondSearchResults.length && (
        <>
          <hr />
          <div>
            {
              isActionList
                ? t('searchByActionContent')
                : t('searchByActionName')
            }
          </div>
          <div className="RuleGrid">
            {
              secondSearchResults
                .map((tile, index) => getRuleOrActionCard(tile, isActionList, index, tags))
            }
          </div>
        </>
      )}
      {!isPopup && (isActionList ? (tilesClone
        .filter(tile => (actionsSearchInput === undefined
        || (tile.name.toLowerCase().includes(actionsSearchInput.toLowerCase())
        && elementHasAllSelectedTags(tile, usedSelectedTags)
        && tile.triggerCount)))
        .map(tile => renderPopup(`action_uses_${tile.id}`, tile.name, getTriggersByAction(tile.id))))
        : (tilesClone
          .filter(tile => (triggersSearchInput === undefined
          || (tile.name.toLowerCase().includes(triggersSearchInput.toLowerCase())
          && elementHasAllSelectedTags(tile, usedSelectedTags)
          && tile.actions.length)))
          .map(tile => renderPopup(`trigger_actions_${tile.id}`, tile.name, tile.actions)))
      )}
      {isActionList && isPopup && (
        <DefaultModal
          show={isCrud}
          title={t('selectAction')}
          closePopup={() => {
            setActionToUpdateId(null);
            setIsCrud(false);
          }}
          children={(
            <ActionEditionForm
              goBack={() => {
                setActionToUpdateId(null);
                setIsCrud(false);
              }}
              actionId={actionToUpdateId}
            />
          )}
        />
      )}
    </div>
  );
};

RuleGrid.propTypes = {
  isActionList: PropTypes.bool,
  isPopup: PropTypes.bool,
  tiles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

RuleGrid.defaultProps = {
  isActionList: false,
  isPopup: false,
};

export default RuleGrid;

