import types from './types';

const getVariableInputsRequest = () => ({
  type: types.GET_VARIABLE_INPUTS_REQUEST,
});

const getVariableInputsResponse = data => ({
  type: types.GET_VARIABLE_INPUTS_RESPONSE,
  payload: data,
});

const addVariableRequest = formData => ({
  type: types.ADD_VARIABLE_REQUEST,
  payload: formData,
});

const addVariableResponse = data => ({
  type: types.ADD_VARIABLE_RESPONSE,
  payload: data,
});

const updateVariableRequest = (variableId, formData) => ({
  type: types.UPDATE_VARIABLE_REQUEST,
  payload: { variableId, formData },
});

const updateVariableResponse = data => ({
  type: types.UPDATE_VARIABLE_RESPONSE,
  payload: data,
});

const patchVariableRequest = (variableId, formData) => ({
  type: types.PATCH_VARIABLE_REQUEST,
  payload: { variableId, formData },
});

const patchVariableResponse = data => ({
  type: types.PATCH_VARIABLE_RESPONSE,
  payload: data,
});

const deleteVariableRequest = variableId => ({
  type: types.DELETE_VARIABLE_REQUEST,
  payload: variableId,
});

const deleteVariableResponse = variableId => ({
  type: types.DELETE_VARIABLE_RESPONSE,
  payload: variableId,
});

export {
  getVariableInputsRequest,
  getVariableInputsResponse,
  addVariableRequest,
  addVariableResponse,
  updateVariableRequest,
  updateVariableResponse,
  patchVariableRequest,
  patchVariableResponse,
  deleteVariableRequest,
  deleteVariableResponse,
};
