import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { useContainerScrollPosition, useSessionStorage } from '~utils/hooks';
import { Page500InternalServerError, Page401Unauthorized } from '../../../pages/ErrorPages';
import { RootState } from '~services/store';

/**
 * Components that need to fetch a resource from the server before displaying
 * should use a ResourcesHandler as container.
 */
const ResourcesHandler = ({ resources, resourceFetchers, getContents, pageId }) => {
  const didUpdateRef = useRef(false);
  const connexion = useSelector((state: RootState) => state.session.connexion);
  const [isLoading, setIsLoading] = useState(true);
  const [is401, setIs401] = useState(false);
  const [is500, setIs500] = useState(false);
  const [scrollHeight, setScrollHeight] = useState(0);

  const [scrollHeightStorage] = useSessionStorage(`${pageId}_scrollHeight`);
  useContainerScrollPosition(pageId, scrollHeight >= scrollHeightStorage);

  const areResourcesFetched = () => resources.every(resource => resource !== null);

  const fetchResources = () => {
    if (connexion.loggedin) {
      Promise.all(
        resourceFetchers.map(async fetcher => {
          const res = await fetcher();
          if (res && res.payload && res.payload.res && res.payload.res.response) {
            const { status } = res.payload.res.response;
            switch (status) {
              case 200:
                break;
              case 401:
                setIs401(true);
                break;
              case 500:
                setIs500(true);
                break;
              default:
            }
            setIsLoading(false);
          }
        }),
      );
    }
  };

  useEffect(() => {
    if (areResourcesFetched()) {
      setIsLoading(false);
    } else {
      fetchResources();
    }
  }, [resources]);

  useEffect(() => {
    if (pageId) {
      if (didUpdateRef.current) {
        const container = document.getElementById(pageId);
        if (container) {
          setScrollHeight(container.scrollHeight);
        }
      } else {
        didUpdateRef.current = true;
      }
    }
  }, [pageId]);

  if (isLoading) {
    return (connexion.loggedin ? <Loader /> : null);
  }

  if (is401) {
    return <Page401Unauthorized />;
  }

  if (is500) {
    return <Page500InternalServerError />;
  }

  return getContents(resources);
};

ResourcesHandler.propTypes = {
  getContents: PropTypes.func,
  pageId: PropTypes.string,
  resources: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  resourceFetchers: PropTypes.arrayOf(PropTypes.func),
};

export default ResourcesHandler;
