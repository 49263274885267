import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { DefaultTable, PageTemplate } from '~components/Pages';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ModalHandler, ResourcesHandler, Cards, BasicCard,
  CreateCard, SquaredAddButton,
  FontAwesome,
} from '~UI';
import { reduxOperations, reducersTypes } from '~services';
import { configurationFeature } from '~utils/featureToggles';
import { filterItems, sortItems } from '~components/SelectionGrid/utils';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { getTagListFromUsedTags } from '~utils/tags';

import StopwatchCRUD from './StopwatchCRUD';
import { Page401Unauthorized } from '../../ErrorPages';
import './Stopwatches.scss';

const displayOptions = {
  CARD: 'card',
  LIST: 'list',
};

const StopwatchSelectionGrid = (
  {
    stopwatches, storageType, deleteStopwatches,
  },
) => {
  const { t } = useTranslation();

  const [filterText, setFilterText] = useState('');
  const updateLocalStorage = getLocalStorageSetter(storageType);
  const storage = getLocalStorageObject(storageType);
  const [display, setDisplay] = useState(storage.display || displayOptions.CARD);
  const [sortInput, setSortInput] = useState(storage.sortParameter || '-');
  const [selectedTags] = useState(storage.tags || ['all']);
  const sortedItems = sortItems(stopwatches, sortInput);
  let usedSelectedTags = getTagListFromUsedTags(sortedItems, selectedTags);
  usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
  const filteredItems = filterItems(filterText, usedSelectedTags, sortedItems);

  function onSortChange(newSortInput) {
    updateLocalStorage('sortParameter', newSortInput);
    setSortInput(newSortInput);
  }

  function handleDisplay(newValue) {
    updateLocalStorage('display', newValue);
    setDisplay(newValue);
  }

  return (
    <div>
      <div className="SearchBar">
        <div>
          {`${t('Search')}:`}
          <Input
            clearable
            onChange={value => setFilterText(value)}
            onClear={() => setFilterText('')}
            placeholder={t('stopwatchName')}
            style={{
              display: 'inline-block',
              margin: '0 5px',
              width: 250,
            }}
            value={filterText}
          />
          {`${t('sortBy')}:`}
          <Select
            className="sortInput inputFilter"
            onChange={e => onSortChange(e)}
            options={[
              { label: '-', value: '-' },
              { label: t('name'), value: 'byName' },
            ]}
            style={{ display: 'inline-block', minWidth: 300 }}
            value={sortInput}
          />
          {/* This part of code is responsible of the sorting by creation date
            TODO : You can uncomment this if and only if you incorporate the creation date into the model */}

          {/* <option value="byCreationDateAscending">
                {t('creationDateNewest')}
              </option>
              <option value="byCreationDateDescending">
                {t('creationDateOldest')}
              </option> */}
        </div>
        <div className="displayIconContainer">
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.CARD)}
          >
            <FontAwesome icon="th" />
          </div>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.LIST)}
          >
            <FontAwesome icon="list-ul" />
          </div>
          {
            display === displayOptions.LIST && (
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={{
                  Component: StopwatchCRUD,
                  props: { isCreating: true },
                }}
              />
            )
          }
        </div>
      </div>
      <div>
        {
          display === displayOptions.CARD ? (
            <Cards>
              <CreateCard
                title={t('addStopwatch')}
                modal={{
                  Component: StopwatchCRUD,
                  props: { isCreating: true },
                }}

              />
              {
                filteredItems.map(stopwatch => (
                  <ModalHandler
                    key={stopwatch.id}
                    Trigger={{
                      Component: BasicCard,
                      props: {
                        title: stopwatch.name,
                        icon: stopwatch.type === 'CountDown'
                          ? <FontAwesome icon="hourglass" size="4x" />
                          : <FontAwesome icon="ii-stopwatch" size="4x" />,
                        editModal: {
                          Component: StopwatchCRUD,
                          props: { modifiedItemId: stopwatch.id },
                        },
                        deleteModal: {
                          Component: DeleteConfirmationForm,
                          props: { handleDelete: () => deleteStopwatches(stopwatch.id) },
                        },
                        style: { cursor: 'pointer' },
                      },
                    }}
                    Modal={{
                      Component: StopwatchCRUD,
                      props: { modifiedItemId: stopwatch.id },
                    }}
                  />
                ))}
            </Cards>
          )
            : (
              <div>
                <DefaultTable
                  columnNames={[
                    { name: t('name') },
                  ]}
                  entriesProperties={['name']}
                  entries={filteredItems}
                  deleteFunction={deleteStopwatches}
                  editForm={StopwatchCRUD}
                  editPopUp
                  onClickEvent={() => StopwatchCRUD}
                />
              </div>
            )}
      </div>
    </div>
  );
};

const Stopwatches = ({
  language, deleteStopwatches, stopwatches, fetchStopwatches, storageType,
}) => {
  const getContents = () => (
    configurationFeature.isUserAllowedAccessStopwatches() ? (
      <PageTemplate
        sidebar
      >
        <StopwatchSelectionGrid
          language={language}
          stopwatches={stopwatches}
          fetchStopwatches={stopwatches}
          deleteStopwatches={deleteStopwatches}
          storageType={storageType}
        />
      </PageTemplate>
    ) : (
      <Page401Unauthorized />
    )
  );
  return (
    <ResourcesHandler
      resources={[stopwatches]}
      resourceFetchers={[fetchStopwatches]}
      getContents={getContents}
    />
  );
};

Stopwatches.propTypes = {
  language: reducersTypes.views.language.isRequired,
  stopwatches: reducersTypes.stopwatches.stopwatches.isRequired,
  fetchStopwatches: PropTypes.func.isRequired,
  deleteStopwatches: PropTypes.func.isRequired,
  storageType: PropTypes.string.isRequired,
};

StopwatchSelectionGrid.propTypes = {
  stopwatches: reducersTypes.stopwatches.stopwatches.isRequired,
  deleteStopwatches: PropTypes.func.isRequired,
  storageType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    stopwatches: state.stopwatches.stopwatches,
    language: state.views.language,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchStopwatches: reduxOperations.stopwatches.fetchStopwatches,
    deleteStopwatches: reduxOperations.stopwatches.deleteStopwatch,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stopwatches));
