import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import API from '~services/endpoints';
import { RootState } from '~services/store';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import MachineForm from './ScorecardMachineForm';
import EventForm from './ScorecardEventForm';
import VariableForm from './ScorecardVariableForm';

const FormScorecard = ({ selectedObject }) => {
  const { t } = useTranslation();

  const variables = useSelector((state: RootState) => state.variables);

  const [eventNames, setEventNames] = useState([]);
  const [scorecardType, setScorecardType] = useState(selectedObject.scorecardType || 'event');
  const [comparisonMetric, setComparisonMetric] = useState(selectedObject.comparisonMetric || 'none');
  const [units, setUnits] = useState(selectedObject.units || '');
  const [hasUnits, setHasUnits] = useState(false);
  const [renderVariableComparisonAggregateForm, setRenderVariableComparisonAggregateForm] = useState(false);
  const [unitPosition, setUnitPosition] = useState(selectedObject.unitPosition || 'bottom');

  const fetchEventNames = async () => {
    const { eventsPropertyElements } = await API.getDistinctEvents('name');
    setEventNames(eventsPropertyElements);
  };

  useEffect(() => {
    fetchEventNames();
  }, []);

  const updateVariableUnits = variable => {
    if (variable) {
      setUnits(variable.units);
      setHasUnits(variable.units && variable.units.length > 0);
    } else {
      setUnits('');
      setHasUnits(false);
    }
    setComparisonMetric('none');
    setRenderVariableComparisonAggregateForm(false);
    setUnitPosition('bottom');
  };

  const handleChangeScorecardType = type => {
    setScorecardType(type);
    setComparisonMetric('none');
    setUnitPosition('bottom');
    setRenderVariableComparisonAggregateForm(false);
    updateVariableUnits(type === 'variable' && variables.length > 0 ? variables[0] : null);
  };

  const renderAggregateForm = name => {
    const aggregateOptions = [
      { label: t('sum'), value: 'sum' },
      { label: t('average'), value: 'avg' },
      { label: t('minimum'), value: 'min' },
      { label: t('maximum'), value: 'max' },
    ];
    return (
      <>
        <div className="inputTitle">{t('aggregate')}</div>
        <Select
          style={{ width: '12%' }}
          name={name}
          defaultValue={selectedObject[name] || 'sum'}
          options={aggregateOptions}
        />
      </>
    );
  };

  const renderComparisonAggregateForm = (isVariable = true) => {
    const comparisonMetricButtons = [
      { label: t('none'), value: 'none' },
      { label: t('percentage'), value: 'percentage' },
      { label: t('absolute'), value: 'absolute' },
    ];

    return (
      <>
        <div className="inputTitle">
          {t('comparisonMetric')}
          &nbsp;&nbsp;
          <Select
            style={{ width: '14%', display: 'inline-block' }}
            name="comparisonMetric"
            value={comparisonMetric}
            onChange={setComparisonMetric}
            options={comparisonMetricButtons}
          />
        </div>

        {
          comparisonMetric !== 'none' && (
            <>
              <IntervalTimePicker
                intervalType={selectedObject.comparisonIntervalType || 'shift'}
                intervalTypeInput="comparisonIntervalType"
              />
              {isVariable && renderAggregateForm('comparisonAggregateType')}
            </>
          )
        }
      </>
    );
  };

  const renderVariableForm = () => (
    <>
      <VariableForm
        selectedObject={selectedObject}
        renderAggregateForm={renderAggregateForm}
        shouldRenderComparisonForm={e => setRenderVariableComparisonAggregateForm(e)}
        updateVariableUnits={updateVariableUnits}
      />
      {
        renderVariableComparisonAggregateForm
          ? renderComparisonAggregateForm()
          : (
            <>
              <input type="hidden" name="comparisonMetric" value="none" />
              <div style={{ color: 'lightBlue' }}>
                {t('cannotToggleDataPeriod')}
              </div>
            </>
          )
      }
      <div className="inputTitle">{t('numberOfDecimals')}</div>
      <input
        type="number"
        name="decimals"
        className="fullwidth"
        min={0}
        max={4}
        defaultValue={Number.isInteger(selectedObject.decimals) ? selectedObject.decimals : 0}
      />
    </>
  );

  const renderEventForm = () => (
    <>
      <EventForm
        eventNames={eventNames}
        selectedObject={selectedObject}
      />
      {renderComparisonAggregateForm(false)}
    </>
  );

  const renderMachineForm = () => (
    <MachineForm
      selectedObject={selectedObject}
    />
  );

  const buttons = [
    { label: t('events'), value: 'event' },
    { label: t('variables'), value: 'variable' },
    { label: t('machine'), value: 'machine' },
  ];

  let content: JSX.Element | null = null;
  if (scorecardType === 'variable') {
    content = renderVariableForm();
  } else if (scorecardType === 'event') {
    content = renderEventForm();
  } else if (scorecardType === 'machine') {
    content = renderMachineForm();
  }

  return (
    <div className="Form ScorecardForm">
      <div className="inputTitle">{t('DataType')}</div>
      <input type="hidden" name="scorecardType" value={scorecardType} />
      <ButtonGroup
        buttons={buttons}
        onChange={handleChangeScorecardType}
        value={scorecardType}
      />
      {content}
      <div className="inputTitle">{t('unitMeasurement')}</div>
      <div className="unitsInputContainer">
        <input
          type="text"
          name="units"
          className="mediumwidth"
          value={units}
          readOnly={hasUnits}
          onChange={e => setUnits(e.currentTarget.value)}
        />
        <Select
          style={{ width: '20%', display: 'inline-block' }}
          name="unitPosition"
          placeholder={t('Position')}
          value={unitPosition}
          onChange={setUnitPosition}
          options={[
            { value: 'bottom', label: t('bottom') },
            { value: 'left', label: t('left') },
            { value: 'right', label: t('right') },
          ]}
        />
      </div>
    </div>
  );
};

FormScorecard.defaultProps = {
  selectedObject: {},
};
FormScorecard.propTypes = {
  selectedObject: PropTypes.shape({
    decimals: PropTypes.number,
    scorecardType: PropTypes.string,
    units: PropTypes.string,
    unitPosition: PropTypes.string,
    comparisonMetric: PropTypes.string,
    comparisonIntervalType: PropTypes.string,
  }),
};

export default FormScorecard;
