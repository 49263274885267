export default {
  GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
  GET_REPORTS_RESPONSE: 'GET_REPORTS_RESPONSE',
  ADD_REPORT_REQUEST: 'ADD_REPORT_REQUEST',
  ADD_REPORT_RESPONSE: 'ADD_REPORT_RESPONSE',
  UPDATE_REPORT_REQUEST: 'UPDATE_REPORT_REQUEST',
  UPDATE_REPORT_RESPONSE: 'UPDATE_REPORT_RESPONSE',
  DELETE_REPORT_REQUEST: 'DELETE_REPORT_REQUEST',
  DELETE_REPORT_RESPONSE: 'DELETE_REPORT_RESPONSE',
};
