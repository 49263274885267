import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { audioTypes } from '~utils/types';
import { FormValueTrigger } from './FormValueTrigger';

const FormAudio = ({ selectedObject }) => {
  const { t } = useTranslation();

  const [audioType, setAudioType] = useState(selectedObject.audioType || audioTypes.TEXT_TO_SPEECH);

  const audioOptions = [
    { label: t(audioTypes.TEXT_TO_SPEECH), value: audioTypes.TEXT_TO_SPEECH },
    { label: t(audioTypes.FILE), value: audioTypes.FILE },
  ];

  return (
    <div>
      <div className="inputTitle">{t('audioType')}</div>
      <ButtonGroup
        buttons={audioOptions}
        name="audioType"
        onChange={type => setAudioType(type)}
        value={audioType}
      />
      {audioType === audioTypes.TEXT_TO_SPEECH && (
        <div>
          <div className="inputTitle">{t('text')}</div>
          <textarea
            name="text"
            className="fullwidth"
            defaultValue={selectedObject.text}
          />
        </div>
      )}
      {audioType === audioTypes.FILE && (
        <div>
          <div className="inputTitle">URL</div>
          <input
            name="url"
            type="url"
            className="fullwidth"
            defaultValue={selectedObject.url}
          />
        </div>
      )}
      <FormValueTrigger selectedObject={selectedObject} />
    </div>
  );
};

FormAudio.propTypes = {
  selectedObject: PropTypes.object,
};
FormAudio.defaultProps = {
  selectedObject: {},
};

export { FormAudio };
