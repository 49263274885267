import React from 'react';
import { useTranslation } from 'react-i18next';
import { reportFeature } from '~utils/featureToggles';
import directLinks from '~utils/directLinks';
import HomeTileContainer from './HomeTileContainer';

const HomeGrid = () => {
  const { t } = useTranslation();
  return (
    <div className="homeGrid flex H">
      <HomeTileContainer
        redirectLink={directLinks.dashboards()}
        title={t('dashboards')}
        logo="ii-dashboard"
        description={t('dashboardsDescription')}
      />
      <HomeTileContainer
        redirectLink={directLinks.topviews()}
        title={t('topViews')}
        logo="desktop"
        description={t('topViewDescription')}
      />
      {
        reportFeature.isUserAllowedAccess() && (
          <HomeTileContainer
            redirectLink={directLinks.reports()}
            title={t('reports')}
            logo="clipboard"
            description={t('reportDescription')}
          />
        )
      }
    </div>
  );
};

export default HomeGrid;
