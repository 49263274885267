import React from 'react';
import JSONPretty from 'react-json-pretty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import './TriggerForms.scss';

const WebhookTriggerForm = ({ trigger }) => {
  const { t } = useTranslation();
  const triggerId = trigger.id || '[RULE ID HERE]';

  const requestExample = {
    url: `https://${window.location.hostname}/api/v1/triggers/${triggerId}`,
    method: 'POST',
    Cookie: 'token=[YOUR API KEY HERE]',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: {
      paramName: 'value',
    },
  };

  const theme = {
    main: 'color:#fff; background:#333; overflow:auto;font-size:14px',
    key: 'color:#7cbaff;',
    string: 'color:#d3917a;',
  };

  return (
    <>
      <div className="inputTitle">
        <Icon
          icon="info-circle"
        />
        {t('appKeysInfos')}
      </div>
      <br />
      <JSONPretty theme={theme} id="json-pretty" data={requestExample} space="2" />
      {!trigger.id && <div className="inputTitle">{t('webhookInfo')}</div>}
    </>
  );
};

WebhookTriggerForm.propTypes = {
  trigger: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default WebhookTriggerForm;
