import React from 'react';
import PropTypes from 'prop-types';
import { getApproximateFontSize } from '~utils/responsiveValue';
import { invertColor } from '~utils/index';

const TITLE_FONT_SIZE_HEIGHT = 0.22;
const TITLE_POSITION = 0.10;

const RectWidget = ({
  dimension: { x, y, w, h }, backgroundColor, children, title, titleSize,
}) => {
  const fontSize = titleSize || getApproximateFontSize(title, w, h * TITLE_FONT_SIZE_HEIGHT);

  return (
    <>
      <rect x={x} y={y} width={w} height={h} fill={backgroundColor} stroke="none" />
      <text
        x={x + w / 2}
        y={y + h * TITLE_POSITION}
        fill={invertColor(backgroundColor)}
        style={{ fontSize: `${fontSize}px`, fontWeight: 'bold' }}
      >
        {title}
      </text>
      {children}
    </>
  );
};

RectWidget.propTypes = {
  dimension: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }).isRequired,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  titleSize: PropTypes.number.isRequired,
};

RectWidget.defaultProps = {
  backgroundColor: '',
  children: null,
  title: '',
};

export default RectWidget;
