import { getTileWidgetIcon } from '~utils/icons';
import { widgetTypes } from '~utils/types';

const widgetOptions = [
  {
    type: widgetTypes.BUTTON,
    name: 'button',
    icon: getTileWidgetIcon(widgetTypes.BUTTON),
  },
  {
    type: widgetTypes.CIRCULAR,
    name: 'circularChart',
    icon: getTileWidgetIcon(widgetTypes.CIRCULAR),
  },
  {
    type: widgetTypes.DATA_ENTRY,
    name: 'dataEntry',
    icon: getTileWidgetIcon(widgetTypes.DATA_ENTRY),
  },
  {
    type: widgetTypes.GAUGE,
    name: 'gauge',
    icon: getTileWidgetIcon(widgetTypes.GAUGE),
  },
  {
    type: widgetTypes.INFO,
    name: 'info',
    icon: getTileWidgetIcon(widgetTypes.INFO),
  },
  {
    type: widgetTypes.MACHINE_STATE,
    name: 'machineState',
    icon: getTileWidgetIcon(widgetTypes.MACHINE_STATE),
  },
  {
    type: widgetTypes.MEDIA,
    name: 'media',
    icon: getTileWidgetIcon(widgetTypes.MEDIA),
  },
  {
    type: widgetTypes.SCORECARD,
    name: 'scorecard',
    icon: getTileWidgetIcon(widgetTypes.SCORECARD),
  },
  {
    type: widgetTypes.STOPWATCH,
    name: 'stopwatch',
    icon: getTileWidgetIcon(widgetTypes.STOPWATCH),
  },
  {
    type: widgetTypes.TEXT,
    name: 'text',
    icon: getTileWidgetIcon(widgetTypes.TEXT),
  },
  {
    type: widgetTypes.PROMPT,
    name: 'prompt',
    icon: getTileWidgetIcon(widgetTypes.PROMPT),
  },
];

export { widgetOptions };
