import types from '../types';

const setParamsRequest = (machineId, data) => ({
  type: types.SET_PARAMS_REQUEST,
  machineId,
  payload: data,
});

const setParamsResponse = (machineId, data) => ({
  type: types.SET_PARAMS_RESPONSE,
  machineId,
  payload: data,
});

export {
  setParamsRequest,
  setParamsResponse,
};
