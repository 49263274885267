import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { CheckboxToggle } from '~components/UI';
import { sortArray } from '~utils/sort';

const FormTimeline = ({ selectedObject }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  const [showArrows, setShowArrows] = useState(selectedObject.showArrows);

  const handleShowArrows = () => {
    setShowArrows(!showArrows);
  };

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        defaultValue={selectedObject.machineId}
        placeholder={t('select')}
      />

      <div className="inputTitle">
        {t('showTimeNavigationArrows')}
        &nbsp;
        <CheckboxToggle
          name="showArrows"
          controlledCheck={showArrows}
          onChange={handleShowArrows}
        />
      </div>
    </div>
  );
};

FormTimeline.propTypes = {
  selectedObject: PropTypes.object,
};
FormTimeline.defaultProps = {
  selectedObject: {},
};

export { FormTimeline };
