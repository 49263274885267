import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import { SelectionGrid } from '@intelligenceindustrielle/react-ui-components';
import { NavigationTabs } from '~components/forms/NavigationTabs';
import { reduxOperations, reducersTypes } from '~services';
import { DefaultModal, FontAwesome } from '~UI';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';
import { TileForm } from './TileForm';
import { tileOptions } from './tileOptions';

import './TileCreationForm.scss';

class TileCreationForm extends React.Component {
  constructor(props) {
    super(props);
    const { tile } = props;
    this.state = {
      tile,
      tileType: tile.type || '',
      pageNumber: tile.type ? 2 : 1,
      navSelected: 'parameters',
      tagNavSelected: 'all',
      filterText: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { show, tile, isNewTile } = this.props;

    if (show !== prevProps.show || !deepEqual(tile, prevProps.tile, { strict: true })) {
      if (tile && tile.type) {
        this.setState({
          tile,
          tileType: tile.type,
          pageNumber: 2,
        });
      }
    } else if (isNewTile !== prevProps.isNewTile) {
      this.setState({
        tileType: null,
        pageNumber: 1,
      });
    }
  }

  handleCloseModal = () => {
    const { onHide, isNewTile } = this.props;
    this.setState({
      filterText: '',
      navSelected: 'parameters',
      tagNavSelected: 'all',
    });
    if (isNewTile) {
      this.setState({
        tileType: null,
        pageNumber: 1,
      });
    }
    onHide();
  };

  createNewTile = (selectedDashboard, formData) => {
    const { addDashboardTile, t } = this.props;
    const dataToSend = { ...formData };

    if (!formData.title) {
      dataToSend.title = '';
    }

    addDashboardTile(selectedDashboard, dataToSend)
      .then(() => {
        showSuccess(t('showSuccessCreated'));
        this.setState({
          tile: {},
          tileType: '',
          pageNumber: 1,
          navSelected: 'parameters',
          tagNavSelected: 'all',
          filterText: '',
        });
        this.handleCloseModal();
      })
      .catch(error => showError(error.status));
  };

  updateTileData = (selectedDashboard, formData) => {
    const { t, selectedTile, updateDashboardTile } = this.props;

    const dataToSend = { ...formData };
    if (!formData.title) {
      dataToSend.title = '';
    }

    updateDashboardTile(selectedDashboard, selectedTile, dataToSend)
      .then(() => {
        showSuccess(t('showSuccessUpdated'));
        this.handleCloseModal();
      })
      .catch(error => showError(error.status));
  };

  handleSubmit = (e, onCreationRules) => {
    e.preventDefault();
    const { match, isNewTile } = this.props;
    const { selectedDashboard } = match.params;
    const formData = onCreationRules.length
      ? { ...getFormData('Dashboard__Edition__Popup__Form'), rules: onCreationRules }
      : { ...getFormData('Dashboard__Edition__Popup__Form') };

    if (isNewTile) {
      this.createNewTile(selectedDashboard, formData);
    } else {
      this.updateTileData(selectedDashboard, formData);
    }
  };

  displayPreviousPage = () => {
    this.setState(prevState => ({
      tileType: null,
      pageNumber: prevState.pageNumber - 1,
      navSelected: 'parameters',
      tagNavSelected: 'all',
    }));
  };

  onSelection = optionId => {
    this.setState({
      tileType: optionId,
      pageNumber: 2,
    });
  };

  handleSelectNav = eventKey => {
    this.setState({ navSelected: eventKey });
  };

  handleTagSelectNav = eventKey => {
    this.setState({ tagNavSelected: eventKey });
  };

  handleFilter = e => {
    this.setState({ filterText: e.target.value });
  };

  afficherPage = () => {
    const {
      match, t, isNewTile, featureToggles, updateDashboardTile,
    } = this.props;
    const {
      tile, pageNumber, tileType, navSelected, tagNavSelected, filterText,
    } = this.state;

    if (!tile) {
      return null;
    }

    if (pageNumber === 1) {
      return (
        <>
          <span style={{ fontSize: '18px' }}>{`${t('Search')} : `}</span>
          <input
            type="text"
            onChange={this.handleFilter}
            className="inputFilter"
            placeholder={t('typeName')}
            value={filterText}
            autoFocus
          />
          <br />
          <br />
          <Nav
            bsStyle="tabs"
            activeKey={tagNavSelected}
            onSelect={eventKey => this.handleTagSelectNav(eventKey)}
          >
            <NavItem eventKey="all">{t('all2')}</NavItem>
            <NavItem eventKey="graph">{t('graphics')}</NavItem>
            <NavItem eventKey="media">{t('media')}</NavItem>
            <NavItem eventKey="other">{t('others')}</NavItem>
          </Nav>
          <SelectionGrid
            onChange={this.onSelection}
            options={
              tileOptions
                .filter(({ name, tag }) => {
                  const condFilter = t(name).toUpperCase().indexOf(filterText.toUpperCase()) > -1;
                  const condProgressBar = name !== 'progressBar' || featureToggles.features.progressBar;
                  const condTag = tagNavSelected === 'all' || tagNavSelected === tag;
                  const condPromptTile = name !== 'Prompt' || featureToggles.AI.promptTile;
                  return condFilter && condProgressBar && condTag && condPromptTile;
                })
                .map(({ icon, name, type }) => ({
                  bgColor: '#777777',
                  Icon: <FontAwesome icon={icon} size="3x" height="51" />,
                  label: t(name),
                  value: type,
                }))
            }
          />
        </>
      );
    }
    if (pageNumber === 2) {
      return (
        <div className="tileEditionForm">
          <NavigationTabs
            navSelected={navSelected}
            onSelect={eventKey => this.handleSelectNav(eventKey)}
            isNew={isNewTile}
            selectedObject={tile}
            type={tileType}
            isTile
          />
          <TileForm
            navSelected={navSelected}
            handleSubmit={this.handleSubmit}
            match={match}
            tile={tile}
            tileType={tileType}
            updateFunction={updateDashboardTile}
            onHide={this.handleCloseModal}
            noTitle={tileType === 'prompt'}
          />
        </div>
      );
    }
    return null;
  };

  showBackArrow = text => (
    <>
      <FontAwesome
        icon="arrow-left"
        className="backButtonArrow"
        size="sm"
        onClick={this.displayPreviousPage}
      />
      {text}
    </>
  );

  render() {
    const { show, t } = this.props;
    const { pageNumber, tileType } = this.state;
    let title = '';
    if (pageNumber === 1 || tileType === null) {
      title = t('chooseType');
    } else if (t(tileType)) {
      title = this.showBackArrow(t(tileType));
    } else {
      title = this.showBackArrow();
    }

    return (
      <DefaultModal
        show={show}
        closePopup={this.handleCloseModal}
        title={title}
        children={this.afficherPage()}
      />
    );
  }
}

TileCreationForm.propTypes = {
  tile: reducersTypes.dashboards.tile,
  selectedTile: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  featureToggles: PropTypes.object.isRequired,
  addDashboardTile: PropTypes.func.isRequired,
  updateDashboardTile: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  isNewTile: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};
TileCreationForm.defaultProps = {
  selectedTile: null,
  tile: {},
};

function mapStateToProps(state) {
  return {
    featureToggles: state.settings.settings?.featureToggles,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addDashboardTile: reduxOperations.dashboards.addDashboardTile,
    updateDashboardTile: reduxOperations.dashboards.updateDashboardTile,
    deleteDashboardTile: reduxOperations.dashboards.deleteDashboardTile,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TileCreationForm)));
