import {
  addDefectCauseRequest,
  addDefectCauseResponse,
  updateDefectCauseRequest,
  updateDefectCauseResponse,
  deleteDefectCauseRequest,
  deleteDefectCauseResponse,
  addSubDefectCauseRequest,
  addSubDefectCauseResponse,
  updateSubDefectCauseRequest,
  updateSubDefectCauseResponse,
} from '../actions';
import API from '~services/endpoints';

function addDefectCause(machineId, defectCause) {
  return dispatch => {
    dispatch(addDefectCauseRequest({ machineId, defectCause }));
    return API.createDefectCause(machineId, defectCause)
      .then(res => {
        if (!res.error) {
          dispatch(addDefectCauseResponse({ machineId, machine: res.machine }));
        }
      });
  };
}

function updateDefectCause(machineId, defectCauseId, defectCause) {
  return dispatch => {
    dispatch(updateDefectCauseRequest({ machineId, defectCauseId, defectCause }));
    return API.updateDefectCause(machineId, defectCauseId, defectCause)
      .then(res => dispatch(updateDefectCauseResponse({ machineId, machine: res.machine })));
  };
}

function deleteDefectCause(machineId, defectCauseId) {
  return dispatch => {
    dispatch(deleteDefectCauseRequest({ machineId, defectCauseId }));
    return API.deleteDefectCause(machineId, defectCauseId)
      .then(res => dispatch(deleteDefectCauseResponse({ machineId, machine: res.machine })));
  };
}

function addSubDefectCause(machineId, parentCauseId, defectCause) {
  return dispatch => {
    dispatch(addSubDefectCauseRequest({ machineId, parentCauseId, defectCause }));
    return API.createSubDefectCause(machineId, parentCauseId, defectCause)
      .then(res => dispatch(addSubDefectCauseResponse({ machineId, machine: res.machine })));
  };
}

function updateSubDefectCause(machineId, parentCauseId, defectCauseId, defectCause) {
  return dispatch => {
    dispatch(updateSubDefectCauseRequest({ machineId, parentCauseId, defectCauseId, defectCause }));
    return API.updateSubDefectCause(machineId, parentCauseId, defectCauseId, defectCause)
      .then(res => dispatch(updateSubDefectCauseResponse({ machineId, machine: res.machine })));
  };
}

export {
  addDefectCause,
  updateDefectCause,
  deleteDefectCause,
  addSubDefectCause,
  updateSubDefectCause,
};
