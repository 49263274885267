import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import DraggableCauseIconContainer from '~components/UI/RoundButton/DraggableCauseIconContainer';
import reduxOperations from '~services/reduxOperations';
import { AddIcon, StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { FontAwesome } from '~UI';
import DefectCauseForm from './DefectCauseForm';
import '../stopCauses/StopCausesForm.scss';

const SUB_MENU_LIMIT = 100;

const DefectCauseEdition = ({ goBackForm, machineId, defectCause }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [editedDefectCause, setEditedDefectCause] = useState(null);
  const [isInSubMenu, setIsInSubMenu] = useState(false);
  const [subDefectCauses, setSubDefectCauses] = useState(defectCause.subMenu || []);

  useEffect(() => {
    setSubDefectCauses(defectCause.subMenu || []);
  }, [defectCause]);

  const handleDefectCauseSelection = (editedDefectCauseArg, isInSubMenuArg) => {
    setEditedDefectCause(editedDefectCauseArg);
    if (typeof isInSubMenuArg === 'boolean') {
      setIsInSubMenu(isInSubMenuArg);
    }
  };

  const updateSubDefectCauses = newSubDefectCauses => {
    dispatch(reduxOperations.machines
      .updateDefectCause(machineId, defectCause.id, { ...defectCause, subMenu: newSubDefectCauses }));
  };

  if (editedDefectCause) {
    return (
      <DefectCauseForm
        firstDefectCause={defectCause}
        defectCause={editedDefectCause}
        isInSubMenu={isInSubMenu}
        goBackEdition={() => handleDefectCauseSelection(null)}
        goBackForm={goBackForm}
        machineId={machineId}
      />
    );
  }

  return (
    <div className="DefectCauseEdition">
      <div className="flexSpaceBetween">
        <FontAwesome
          icon="arrow-left"
          className="backButtonArrow"
          style={{
            cursor: 'pointer',
            fontSize: 20,
            marginTop: 4,
            marginLeft: '0px',
          }}
          onClick={goBackForm}
        />
        <span style={{ fontSize: 20 }}>
          <Icon
            icon="info-circle"
            tooltipLabel={t('stopCauseInfo')}
          />
        </span>
      </div>
      <div className="centered">
        <StopCauseIcon
          option={defectCause}
          onClick={() => handleDefectCauseSelection(defectCause, false)}
        />
      </div>
      <hr />
      <h2>{t('subMenu')}</h2>
      <div className="StopCausesIconsContainer" style={{ justifyContent: 'left' }}>
        <DndProvider backend={HTML5Backend}>
          <DraggableCauseIconContainer
            causesArray={subDefectCauses}
            onClick={dc => handleDefectCauseSelection(dc, true)}
            updateCauses={updateSubDefectCauses}
          >
            {(defectCause.subMenu || []).length < SUB_MENU_LIMIT && (
              <AddIcon
                onClick={() => handleDefectCauseSelection({}, true)}
              />
            )}
          </DraggableCauseIconContainer>
        </DndProvider>
      </div>
    </div>
  );
};

DefectCauseEdition.propTypes = {
  goBackForm: PropTypes.func.isRequired,
  defectCause: PropTypes.shape({
    id: PropTypes.string,
    subMenu: PropTypes.arrayOf(PropTypes.object),
  }),
  machineId: PropTypes.string.isRequired,
};
DefectCauseEdition.defaultProps = {
  defectCause: {},
};

export default DefectCauseEdition;
