import directLinks from '~utils/directLinks';

export const getBackLocation = history => {
  const { location: { pathname, search } } = history;
  const match = pathname.split('/');

  if (pathname === '/home') {
    return null;
  }

  if (search === '?from=bookmarks') {
    return directLinks.home();
  }

  if (match[1] === 'dashboard') {
    return directLinks.dashboards();
  }
  if (match[1] === 'topview') {
    return directLinks.topviews();
  }
  if (match[1] === 'report') {
    return directLinks.reports();
  }

  if (match[1] === 'config') {
    if (match[2] === 'dashboard') {
      return directLinks.config();
    }
    if (match[2] === 'machine') {
      return `${directLinks.config()}${directLinks.machines()}`;
    }
    if (match[2] === 'report') {
      return `${directLinks.config()}${directLinks.reports()}`;
    }
    if (match[2] === 'ruleEdition') {
      return `${directLinks.config()}${directLinks.ruleEngine()}`;
    }
    if (match[2] === 'stream') {
      return `${directLinks.config()}${directLinks.streams()}`;
    }
    if (match[2] === 'topview') {
      return `${directLinks.config()}${directLinks.topviews()}`;
    }
    if (match[2] === 'user') {
      return `${directLinks.config()}${directLinks.users()}`;
    }

    return directLinks.home();
  }

  return directLinks.home();
};

export default {
  getBackLocation,
};
