import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI';
import DashboardSelectionGrid from './DashboardSelectionGrid';

const DashboardSelectionPage = () => {
  const dashboards = useSelector(state => state.dashboards.dashboards);
  const { t } = useTranslation();
  const tags = useSelector(state => state.tags.tags);
  const images = useSelector(state => state.images.images);

  const dispatch = useDispatch();

  const getContents = () => (
    <PageTemplate
      footer
      pageId="DashboardSelectionPage"
      pageTemplateClass="flex V justifyStart"
      sidebar
      title={t('dashboards')}
    >
      <DashboardSelectionGrid />
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[dashboards, tags, images]}
      resourceFetchers={[
        () => dispatch(reduxOperations.dashboards.fetchDashboards()),
        () => dispatch(reduxOperations.images.fetchImages()),
        () => dispatch(reduxOperations.tags.fetchTags()),
      ]}
      getContents={getContents}
      pageId="DashboardSelectionPage"
    />
  );
};

export { DashboardSelectionPage };
