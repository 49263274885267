export default {
  GET_STOPWATCHES_REQUEST: 'GET_STOPWATCHES_REQUEST',
  GET_STOPWATCHES_RESPONSE: 'GET_STOPWATCHES_RESPONSE',
  ADD_STOPWATCHES_REQUEST: 'ADD_STOPWATCHES_REQUEST',
  ADD_STOPWATCHES_RESPONSE: 'ADD_STOPWATCHES_RESPONSE',
  UPDATE_STOPWATCHES_REQUEST: 'UPDATE_STOPWATCHES_REQUEST',
  UPDATE_STOPWATCHES_RESPONSE: 'UPDATE_STOPWATCHES_RESPONSE',
  DELETE_STOPWATCHES_REQUEST: 'DELETE_STOPWATCHES_REQUEST',
  DELETE_STOPWATCHES_RESPONSE: 'DELETE_STOPWATCHES_RESPONSE',
  STOPWATCHIO_STOPWATCHES_REQUEST: 'STOPWATCHIO_STOPWATCHES_REQUEST',
  STOPWATCHIO_STOPWATCHES_RESPONSE: 'STOPWATCHIO_STOPWATCHES_RESPONSE',
  SOCKET_UPDATE_STOPWATCH: 'SOCKET_UPDATE_STOPWATCH',
};
