import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '~components/SelectionGrid';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import directLinks from '~utils/directLinks';
import { configurationFeature } from '~utils/featureToggles';
import { showSuccess } from '~utils/toast';
import { ReportPopupForm } from './ReportPopupForm';

const ReportSelectionGrid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteReport = id => reduxOperations.reports.deleteReport(id)(dispatch);

  const reports = useSelector((state: RootState) => state.reports.reports);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const users = useSelector((state: RootState) => state.users.users);

  const isUserAllowedReportConfig = configurationFeature.isUserAllowedAccessReports();

  const updateUsersPermissions = (id, usersArg) => {
    if (configurationFeature.isUserAllowedAccessUsers()) {
      const permissionsData = {
        users: usersArg.map(user => user.value),
        content: {
          type: 'Report',
          id,
        },
      };
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleDuplication = report => {
    let usersWithPermission;
    if (report.id) {
      usersWithPermission = users
        .reduce((result, user) => {
          const userHasReportsAccess = user.permissions.contents.some(content => content.type === 'Report' && content.id === report.id);
          return userHasReportsAccess ? [...result, { value: user.id }] : result;
        }, []);
    }

    const newReport = {
      ...omit(report, ['id', 'modifiedAt', 'modifiedBy']),
      name: (() => { // create report name while avoiding duplicate names
        let i = 1;
        while (true) {
          const tail = `(${i})`;
          if (!reports.find(rep => rep.name === `${report.name}_${t('Copy')} ${tail}`)) break;
          i += 1;
        }
        return `${report.name}_${t('Copy')} (${i})`;
      })(),
      url: report.url,
      tags: (report.tags || [])
        .map(tagId => tags.find(tag => tagId === tag.id))
        .filter(tag => !!tag),
    };

    reduxOperations.reports.addReport(newReport)(dispatch).then(res => {
      showSuccess(t('showSuccessCreated'));
      updateUsersPermissions(res.payload.report.id, usersWithPermission);
    });
  };

  const getReports = () => {
    const reportsItems = reports.map(report => ({
      ...report,
      createdByUser: users.find(user => user.id === report.createdBy)?.name,
      modifiedByUser: users.find(user => user.id === report.modifiedBy)?.name,
    }));
    return reportsItems;
  };

  return (
    <SelectionGrid
      contentType="Report"
      deleteFunction={isUserAllowedReportConfig && deleteReport}
      directLinkType={directLinks.report}
      EditForm={isUserAllowedReportConfig && ReportPopupForm}
      handleDuplication={handleDuplication}
      isUserAllowedToConfig={isUserAllowedReportConfig}
      items={getReports()}
      redirectRessource="report"
      storageType="K2_reports"
      supportFavorites
      titleCreateCard={t('addReport')}
    />
  );
};

export { ReportSelectionGrid };
