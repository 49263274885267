import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import types from './types';

const settingsPropTypes = PropTypes.shape({
  featureToggles: PropTypes.object,
  defaultUnfilledStopCauseColor: PropTypes.string,
  defaultUnfilledPerformanceCauseColor: PropTypes.string,
});

function settings(state = null, action) {
  switch (action.type) {
    case types.GET_SETTINGS_RESPONSE:
    case types.UPDATE_SETTINGS_RESPONSE:
      if (!action.payload.error) {
        return action.payload.settings;
      }
      return state;
    default:
      return state;
  }
}

export const propTypes = {
  settings: settingsPropTypes,
};

export default combineReducers({
  settings,
});
