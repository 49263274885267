import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem } from 'react-bootstrap';
import deepEqual from 'deep-equal';
import { ConfirmationForm } from '~components/Popups';
import FontAwesome from '~UI/FontAwesome/FontAwesome';
import { MachineGeneral } from './MachineGeneral';
import MachineSKU from './MachineSKU';
import MachineParams from './MachineParams';
import MachineKPIs from './MachineKPIs';
import MachineOperators from './MachineOperators';
import StopCausesForm from './stopCauses/StopCausesForm';
import PerformanceCausesForm from './performanceCauses/PerformanceCausesForm';
import DefectCausesForm from './defectCauses/DefectCausesForm';
import ShiftsPage from './shifts/ShiftsPage';
import './MachinePage.scss';

const propTypes = {
  onHide: PropTypes.func.isRequired,
  modifiedItemId: PropTypes.string.isRequired,
};

const MachineEditionForm = ({ onHide, modifiedItemId }) => {
  const machines = useSelector(state => state.machines);
  const { t } = useTranslation();

  const [navSelected, setNavSelected] = useState('general');
  const machine = machines.find(elem => elem.id === modifiedItemId) || null;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newMachineParams, setNewMachineParams] = useState(machine.params);
  const [toChangeNav, setToChangeNav] = useState('general');

  const handleTabChange = eventKey => {
    const newMachine = machines.find(elem => elem.id === modifiedItemId);
    if (navSelected === 'parameters' && !deepEqual(newMachine.params, newMachineParams, { strict: true })) {
      setShowConfirmation(true);
      setToChangeNav(eventKey);
    } else {
      setNavSelected(eventKey);
    }
  };

  const forceTabChange = () => {
    const newMachine = machines.find(elem => elem.id === modifiedItemId);
    setNavSelected(toChangeNav);
    setNewMachineParams(newMachine.params);
  };

  const hideConfirmation = () => {
    setShowConfirmation(false);
  };

  return (
    <>
      <ConfirmationForm
        title={(
          <>
            <FontAwesome
              icon="exclamation-triangle"
              color="#bf3838"
            />
              &nbsp;
            {t('quitConfirmation')}
          </>
        )}
        body={(
          <>
            {t('beSureQuit')}
            <br />
            {t('unsavedChanges')}
          </>
        )}
        actionLabel={t('quit')}
        show={showConfirmation}
        onHide={hideConfirmation}
        handleAction={forceTabChange}
        confirmButtonColor="#bf3838"
      />
      <div className="MachinePage">
        <Nav bsStyle="tabs" activeKey={navSelected} onSelect={handleTabChange}>
          <NavItem eventKey="general">
            {t('general')}
          </NavItem>
          <NavItem eventKey="skuNumbers">
            {t('skuNumbers')}
          </NavItem>
          <NavItem eventKey="stopCauses">
            {t('stopCauses')}
          </NavItem>
          <NavItem eventKey="performanceDropCauses">
            {t('performanceDropCauses')}
          </NavItem>
          <NavItem eventKey="defectCauses">
            {t('defectCauses')}
          </NavItem>
          <NavItem eventKey="shifts">
            {t('shifts')}
          </NavItem>
          <NavItem eventKey="operators">
            {t('operators')}
          </NavItem>
          <NavItem eventKey="parameters">
            {t('parameters')}
          </NavItem>
          <NavItem eventKey="machineKPIs">
            {t('machineKPIs')}
          </NavItem>
        </Nav>
        {navSelected === 'general' && (
          <div className="navBlock">
            <MachineGeneral
              machineId={machine.id}
              onHide={onHide}
            />
          </div>
        )}
        {navSelected === 'skuNumbers' && (
          <div className="navBlock">
            <MachineSKU machineId={machine.id} />
          </div>
        )}
        {navSelected === 'stopCauses' && (
          <div className="navBlock">
            <StopCausesForm machineId={machine.id} />
          </div>
        )}
        {navSelected === 'performanceDropCauses' && (
          <div className="navBlock">
            <PerformanceCausesForm machineId={machine.id} />
          </div>
        )}
        {navSelected === 'defectCauses' && (
          <div className="navBlock">
            <DefectCausesForm machineId={machine.id} />
          </div>
        )}
        {navSelected === 'shifts' && (
          <div className="navBlock">
            <ShiftsPage machineId={machine.id} />
          </div>
        )}
        {navSelected === 'operators' && (
          <div className="navBlock">
            <MachineOperators machineId={machine.id} />
          </div>
        )}
        {navSelected === 'parameters' && (
          <div className="navBlock">
            <MachineParams
              machineId={machine.id}
              onHide={onHide}
              onChange={newParams => setNewMachineParams(newParams)}
            />
          </div>
        )}
        {navSelected === 'machineKPIs' && (
          <div className="navBlock">
            <MachineKPIs machineId={machine.id} />
          </div>
        )}
      </div>
    </>
  );
};

MachineEditionForm.propTypes = propTypes;

export default MachineEditionForm;
