import { OperatorAction, OperatorActionTypes } from './actions';
import { Operator } from './types';

export default function operators(state: Operator[] | null = null, action: OperatorAction) {
  switch (action.type) {
    case OperatorActionTypes.GET_OPERATORS_RESPONSE:
      return action.payload.operators;
    case OperatorActionTypes.ADD_OPERATOR_RESPONSE:
      return state?.concat(action.payload.operator);
    case OperatorActionTypes.UPDATE_OPERATOR_RESPONSE:
      return state?.map(o => (o.id === action.payload.operator.id ? action.payload.operator : o));
    case OperatorActionTypes.DELETE_OPERATOR_RESPONSE:
      return state?.filter(o => o.id !== action.payload.id);
    default:
      return state;
  }
}
