import React from 'react';
import PropTypes from 'prop-types';
import { FormAreaChart } from '~components/forms';

const FormTileAreaChart = ({ selectedTile }) => (
  <FormAreaChart
    selectedObject={selectedTile}
  />
);

FormTileAreaChart.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileAreaChart.defaultProps = {
  selectedTile: {},
};

export default FormTileAreaChart;
