import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import omit from 'lodash.omit';
import { reduxOperations } from '~services';
import { DeleteConfirmationForm } from '~components/Popups';
import { ConfigurationIcons } from '~UI';
import { showError, showSuccess } from '~utils/toast';
import TopviewWidgetEditionForm from './TopviewWidgetEdition/TopviewWidgetEditionForm';

const SIZE_MIN = 30;

const COLORS = {
  RESIZE_RED: 'rgb(248,0,0)',
  RESIZE_OPACITY: 0.205,
  TOP_BAR_BORDER: 'black',
};

const propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    w: PropTypes.number,
    h: PropTypes.number,
  }).isRequired,
  handleDrag: PropTypes.func.isRequired,
  createWidget: PropTypes.func.isRequired,
  deleteWidget: PropTypes.func.isRequired,
  maxX: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
  magnitude: PropTypes.number.isRequired,
  topviewId: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

class TopviewWidgetConfigHandler extends Component {
  constructor(props) {
    super(props);
    this.start = { x: 0, y: 0 };
    this.state = {
      isResizing: false,
      isMoving: false,
      isArrowsMove: false,
    };
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleDrag);
    window.addEventListener('mouseup', this.handleDragStop);
    window.addEventListener('mouseleave', this.handleDragCancel);
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleDrag);
    window.removeEventListener('mouseup', this.handleDragStop);
    window.removeEventListener('mouseleave', this.handleDragCancel);
    window.removeEventListener('keydown', this.handleArrowsMove);
    window.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = event => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ isArrowsMove: false });
      window.removeEventListener('keydown', this.handleArrowsMove);
    }
  };

  handleArrowsMove = e => {
    e.preventDefault();
    const { handleDrag, widget, maxX, maxY } = this.props;
    const { x, y, w, h } = widget;
    let newX = x;
    let newY = y;
    switch (e.keyCode) {
      case 37:
        newX = Math.max(0, Math.min(maxX - w, x - 1));
        break;
      case 38:
        newY = Math.max(0, Math.min(maxY - h, y - 1));
        break;
      case 39:
        newX = Math.max(0, Math.min(maxX - w, x + 1));
        break;
      case 40:
        newY = Math.max(0, Math.min(maxY - h, y + 1));
        break;
      default:
        break;
    }
    handleDrag(newX, newY, w, h, true);
  };

  handleDragStart = e => {
    e.preventDefault();
    this.start = { x: e.clientX, y: e.clientY };
  };

  handleDrag = e => {
    const { magnitude } = this.props;
    const { isResizing, isMoving } = this.state;
    if (isResizing || isMoving) {
      e.preventDefault();
      const dx = (e.clientX - this.start.x) / magnitude;
      const dy = (e.clientY - this.start.y) / magnitude;
      if (isResizing) {
        this.handleResize(dx, dy);
      } else {
        this.handleMove(dx, dy);
      }
    }
  };

  handleResize = (dx, dy, isFinal = false) => {
    const { magnitude } = this.props;
    const { handleDrag, widget, maxX, maxY } = this.props;
    const { x, y, w, h } = widget;
    const newW = Math.max(SIZE_MIN / magnitude, Math.min(maxX - x, w + dx));
    const newH = Math.max(SIZE_MIN / magnitude, Math.min(maxY - y, h + dy));
    handleDrag(x, y, newW, newH, isFinal);
  };

  handleMove = (dx, dy, isFinal = false) => {
    const { handleDrag, widget, maxX, maxY } = this.props;
    const { x, y, w, h } = widget;
    const newX = Math.max(0, Math.min(maxX - w, x + dx));
    const newY = Math.max(0, Math.min(maxY - h, y + dy));
    handleDrag(newX, newY, w, h, isFinal);
  };

  handleDragStop = e => {
    e.stopPropagation();
    const { magnitude } = this.props;
    const dx = (e.clientX - this.start.x) / magnitude;
    const dy = (e.clientY - this.start.y) / magnitude;
    this.setState(prevState => {
      const { isResizing, isMoving } = prevState;
      if (isResizing) {
        this.handleResize(dx, dy, true);
      } else if (isMoving) {
        this.handleMove(dx, dy, true);
      }
      return {
        isResizing: false,
        isMoving: false,
      };
    });
  };

  handleDragCancel = e => {
    e.preventDefault();
    this.setState(prevState => {
      const { isResizing, isMoving } = prevState;
      if (isResizing) {
        this.handleResize(0, 0);
      } else if (isMoving) {
        this.handleMove(0, 0);
      }
      return {
        isResizing: false,
        isMoving: false,
      };
    });
  };

  handleDuplication = () => {
    const { createWidget, t, topviewId, widget } = this.props;
    const widgetClone = omit(widget, ['_id', 'id', 'x', 'y']);

    if (widgetClone.rules) {
      widgetClone.rules = widgetClone.rules.map(r => omit(r, ['id']));
    }

    return createWidget(topviewId, widgetClone)
      .then(() => {
        showSuccess(t('showSuccessCreated'));
      })
      .catch(() => {
        showError(t('operationFailed'));
      });
  };

  render() {
    const {
      x, y, w, h, widget, deleteWidget, topviewId, magnitude,
    } = this.props;
    const { isResizing, isMoving, isArrowsMove } = this.state;
    const topbarHeight = 28 / magnitude;

    const redTintedShadow = (isResizing || isMoving || isArrowsMove)
      ? (
        <rect
          x={x}
          y={y}
          width={w}
          height={h}
          fill={COLORS.RESIZE_RED}
          fillOpacity={COLORS.RESIZE_OPACITY}
        />
      ) : null;

    const moveHandle = (
      <g
        className="move-handle"
        onMouseDown={e => {
          this.handleDragStart(e);
          this.setState({ isMoving: true });
        }}
      >
        <rect
          x={x}
          y={y}
          width={w}
          height={h}
          fill="white"
          opacity={0}
        />
      </g>
    );

    const resizeHandle = (
      <svg
        className="resize-handle"
        onMouseDown={e => {
          this.handleDragStart(e);
          this.setState({ isResizing: true });
        }}
        viewBox="0 0 20 20"
        x={x + w - SIZE_MIN / magnitude}
        y={y + h - SIZE_MIN / magnitude}
        width={SIZE_MIN / magnitude}
        height={SIZE_MIN / magnitude}
      >
        <path
          d="M 9 18 H 18 V 9"
          stroke="#ccc"
          strokeWidth={4}
          fill="none"
        />
        <path
          d="M 10 18 H 18 V 10"
          stroke="black"
          strokeWidth={2}
          fill="none"
        />
        <rect
          width={20}
          height={20}
          fill="transparent"
        />
      </svg>
    );

    const configurationIcons = (
      <g className="widget_topbar">
        <foreignObject x={x} y={y - topbarHeight + 1} width={w} height={topbarHeight}>
          <ConfigurationIcons
            isSmall
            displayHeadband
            fontSize={`${20 / magnitude}px`}
            handleDuplication={this.handleDuplication}
            handleArrowsMove={() => {
              if (!isArrowsMove) {
                window.addEventListener('keydown', this.handleArrowsMove);
              } else {
                window.removeEventListener('keydown', this.handleArrowsMove);
              }
              this.setState({ isArrowsMove: !isArrowsMove });
            }}
            handleArrowsIsMoving={isArrowsMove}
            EditionForm={{
              Component: TopviewWidgetEditionForm,
              props: { widget, topviewId },
            }}
            DeletionForm={{
              Component: DeleteConfirmationForm,
              props: { handleDelete: () => deleteWidget(topviewId, widget.id) },
            }}
            topviewId={topviewId}
            widget={widget}
          />
        </foreignObject>
      </g>
    );

    return (
      <g>
        {redTintedShadow}
        {moveHandle}
        {resizeHandle}
        {configurationIcons}
      </g>
    );
  }
}

TopviewWidgetConfigHandler.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    deleteWidget: reduxOperations.topviews.deleteWidget,
    createWidget: reduxOperations.topviews.createWidget,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(withTranslation()(TopviewWidgetConfigHandler));
