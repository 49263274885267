import React from 'react';
import PropTypes from 'prop-types';

import { FormParetoChart } from '~components/forms';

function FormTileParetoChart({ selectedTile }) {
  return (
    <FormParetoChart
      selectedTile={selectedTile}
    />
  );
}

FormTileParetoChart.propTypes = {
  selectedTile: PropTypes.object,
};

FormTileParetoChart.defaultProps = {
  selectedTile: {},
};

export default FormTileParetoChart;
