import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './RoundButton.scss';

const propTypes = {
  backgroundImage: PropTypes.string,
  backgroundSize: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  name: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  color: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
};

const defaultProps = {
  backgroundImage: '',
  backgroundSize: '',
  className: '',
  content: undefined,
  name: undefined,
  color: undefined,
  onClick: () => { },
  small: false,
};

const RoundButton = ({
  backgroundImage, backgroundSize, className, content, name, color, onClick, small,
}) => {
  const circleStyle = {
    borderColor: color,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize,
  };

  return (
    <div className={classnames('RoundButton', { smallRoundButton: small })}>
      <button
        type="button"
        className={classnames('btn', 'Circle', { smallCircle: small }, className)}
        style={circleStyle}
        onClick={onClick}
      >
        <div className="Circle__Content">
          {content}
        </div>
      </button>
      {name && (small ? <span>{name}</span> : <h3>{name}</h3>)}
    </div>
  );
};

RoundButton.propTypes = propTypes;
RoundButton.defaultProps = defaultProps;

export default RoundButton;
