import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga4';
import { getSocket } from '~services/socket';
import { evaluate, tryJSONParse } from '~utils/parser';
import TileContents from '../TileContents';
import { IframeMedia } from './IframeMedia';
import { ImageMedia } from './ImageMedia';
import { reduxOperations } from '~services';
import { showError } from '~utils/toast';

const MediaTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const [displayURL, setDisplayURL] = useState(tile.url);
  const socket = getSocket();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const clientName = useSelector(state => state.settings.settings);
  const connexion = useSelector(state => state.session.connexion);
  const tileUrl = useRef(tile?.url);
  const tileRules = useRef(tile?.rules || []);

  const handleSocketData = socketData => {
    const websocketData = JSON.parse(socketData);
    let urlToShow = tileUrl.current;
    if (tileRules?.current) {
      const mediaRules = tileRules.current.filter(rule => rule.type === 'media');
      for (const mediaRule of mediaRules) {
        if (evaluate(mediaRule.condition, websocketData, mediaRule.variableType)) {
          urlToShow = mediaRule.media;
          break;
        }
      }
    }
    setDisplayURL(urlToShow);
  };

  const handleOnClickMedia = async () => {
    const triggerId = tile.onClickTrigger;
    const params = tryJSONParse(tile.ruleParams) || {};
    if (triggerId) {
      // Sent to the global GA view for global KPI analysis
      ReactGA.event({
        category: clientName,
        action: 'TRIGGER_MEDIA_CLICKED',
        label: connexion.infos.email,
      });

      // Each client has its own GA property defined and the data will be more precise on each client property
      ReactGA.event({
        category: clientName,
        action: 'TRIGGER_MEDIA_CLICKED',
        label: JSON.stringify({
          user: connexion.infos.email,
          payload: {
            triggerId,
            params,
          },
        }),
      });
      return dispatch(reduxOperations.triggers.executeTrigger(triggerId, params)).then(res => {
        if (res.payload.response === 'Rule is not active') {
          showError(t('ruleNotActive'));
        }
      });
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (socket) {
      socket.on('data', handleSocketData);
    }
    return () => {
      if (socket) {
        socket.removeListener('data', handleSocketData);
      }
    };
  }, [socket, backgroundColor, height, tile, width]);

  useEffect(() => {
    tileUrl.current = tile.url;
    tileRules.current = tile.rules;
  }, [tile]);

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
    >
      {displayURL && tile.mediaType === 'image' && (
        <ImageMedia
          displayURL={displayURL}
          tile={tile}
          onClick={handleOnClickMedia}
        />
      )}
      {displayURL && tile.mediaType === 'iframe' && (
        <IframeMedia
          displayURL={displayURL}
          tile={tile}
          onClick={handleOnClickMedia}
        />
      )}
    </TileContents>
  );
};

MediaTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    title: PropTypes.string,
    mediaType: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.string,
    refreshRateValue: PropTypes.number,
    refreshRateToggle: PropTypes.bool,
    scale: PropTypes.number,
    onClickTrigger: PropTypes.string,
    ruleParams: PropTypes.string,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default MediaTile;
