import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';
import { DashboardAction, DashboardActionTypes } from './actions';
import { Dashboard } from './types';

export type DashboardReducerState = Dashboard[] | null;

function dashboards(state: DashboardReducerState = null, action: DashboardAction) {
  switch (action.type) {
    case DashboardActionTypes.GET_DASHBOARDS_RESPONSE:
      return action.payload.dashboards;

    case DashboardActionTypes.ADD_DASHBOARD_RESPONSE:
      return state?.concat(action.payload.dashboard);

    case DashboardActionTypes.UPDATE_DASHBOARD_RESPONSE:
      return state?.map(dashboard => (
        dashboard.id === action.payload.dashboard.id ? action.payload.dashboard : dashboard));

    case DashboardActionTypes.DELETE_DASHBOARD_RESPONSE: {
      const newState = state?.filter(dashboard => dashboard.id !== action.payload.dashboardId);
      newState?.forEach(dashboard => {
        if (dashboard.type === 'Multiple') {
          dashboard.dashboards = dashboard.dashboards.filter(d => d !== action.payload.dashboardId);
        }
      });
      return newState;
    }

    // Tiles
    case DashboardActionTypes.ADD_DASHBOARD_TILE_RESPONSE:
    case DashboardActionTypes.UPDATE_DASHBOARD_TILE_RESPONSE:
    case DashboardActionTypes.UPDATE_MANY_DASHBOARD_TILES_RESPONSE: {
      const dashboardId = action.payload.id;
      return state?.map(dashboard => {
        if (dashboard.id === dashboardId) {
          return { ...dashboard, tiles: action.payload.tiles };
        }
        return dashboard;
      });
    }
    case DashboardActionTypes.DELETE_DASHBOARD_TILE_RESPONSE: {
      const { tileId } = action.payload;
      return state?.map(dashboard => {
        if (dashboard.id === action.payload.dashboardId) {
          return { ...dashboard, tiles: dashboard.tiles.filter(tile => tile.id !== tileId) };
        }
        return dashboard;
      });
    }
    default:
      return state;
  }
}

const tilePropTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  w: PropTypes.number,
  h: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
  title: PropTypes.string,
});

const dashboardPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  folderId: PropTypes.string,
  tiles: PropTypes.arrayOf(tilePropTypes),
  backgroundURL: PropTypes.string,
  createdAt: PropTypes.number,
  modifiedAt: PropTypes.number,
  createdBy: PropTypes.string,
  modifiedBy: PropTypes.string,
});

export const propTypes = {
  tile: tilePropTypes,
  dashboards: nullable(
    PropTypes.arrayOf(dashboardPropTypes),
  ),
};

export default combineReducers({ dashboards });
