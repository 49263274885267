import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Cleave from 'cleave.js/react';
import { RootState } from '~services/store';
import { TextInput } from '~UI/index';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import 'cleave.js/dist/addons/cleave-phone.us';
import './ActionForm.scss';

const propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      phoneNumber: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
  }),
};

const defaultProps = {
  action: {
    params: {
      phoneNumber: '',
      message: '',
    },
  },
};
const SMSActionForm = ({ action }) => {
  const { t } = useTranslation();
  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);
  const machines = useSelector((state: RootState) => state.machines);

  const { params } = action;

  const [phoneNumber, setPhoneNumber] = useState((params && params.phoneNumber) || '');
  const [message, setMessage] = useState((params && params.message) || '');

  const onMessageChange = messageArg => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    let id = messageArg;
    try {
      id = variableToId(messageArg, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }

    setMessage(id);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    return idToReadableExpr(value, inputsAndVariables);
  };

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  return (
    <div className="smsActionForm">
      <div className="phoneNumberEntry">
        <div className="inputTitle">{t('sendTo')}</div>
        <Cleave
          className="phoneCleave fullwidth"
          name="phoneNumber"
          options={{
            phone: true,
            phoneRegionCode: 'US',
          }}
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          placeholder="1 514 555 5555"
        />
      </div>

      <div className="messageEntry">
        <div className="inputTitle">{t('message')}</div>
        <input
          type="hidden"
          value={message}
          name="message"
        />
        <TextInput
          options={inputsAndVariables}
          trigger="$"
          value={message ? idToReadable(message) : null}
          Component="textarea"
          className="fullwidth"
          placeholder={t('triggerVariableList')}
          onChange={e => onMessageChange(e)}
        />
      </div>
    </div>
  );
};

SMSActionForm.propTypes = propTypes;
SMSActionForm.defaultProps = defaultProps;

export default SMSActionForm;
