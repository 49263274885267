import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './Button.scss';

interface CancelButtonProps {
  label?: string;
  onClick: () => void;
}

const CancelButton = ({
  label,
  onClick,
}: CancelButtonProps) => {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className="StyledButton CancelButton"
      onClick={onClick}
    >
      {label || t('cancel')}
    </button>
  );
};

CancelButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};
CancelButton.defaultProps = {
  label: '',
  onClick: () => undefined,
};

export default CancelButton;
