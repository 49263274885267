import API from '~services/endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  getVariableInputsRequest,
  getVariableInputsResponse,
  addVariableRequest,
  addVariableResponse,
  updateVariableRequest,
  updateVariableResponse,
  patchVariableRequest,
  patchVariableResponse,
  deleteVariableRequest,
  deleteVariableResponse,
} from './actions';

function fetchVariables() {
  return fetchOnceOperation(
    getVariableInputsRequest,
    getVariableInputsResponse,
    API.getVariables,
    state => state.variables,
  );
}

function addVariable(formData) {
  return dispatch => {
    dispatch(addVariableRequest(formData));
    return API.addVariable(formData)
      .then(variable => {
        if (variable) {
          dispatch(addVariableResponse(variable));
        }
      });
  };
}

function updateVariable(variableId, formData) {
  return dispatch => {
    dispatch(updateVariableRequest(variableId, formData));
    return API.updateVariable(variableId, formData)
      .then(variable => {
        if (variable) {
          dispatch(updateVariableResponse(variable));
        }
      });
  };
}

function patchVariable(variableId, formData) {
  return dispatch => {
    dispatch(patchVariableRequest(variableId, formData));
    return API.patchVariable(variableId, formData)
      .then(variable => {
        if (variable) {
          dispatch(patchVariableResponse(variable));
        }
      });
  };
}

function deleteVariable(variableId) {
  return dispatch => {
    dispatch(deleteVariableRequest(variableId));
    return API.deleteVariable(variableId)
      .then(res => {
        if (!res.error) {
          dispatch(deleteVariableResponse(res.variableId));
        }
      });
  };
}

export default {
  fetchVariables,
  addVariable,
  updateVariable,
  patchVariable,
  deleteVariable,
};
