import React from 'react';
import PropTypes from 'prop-types';
import Slider, { Handle } from 'rc-slider';

import './RangeSlider.scss';
import 'rc-slider/assets/index.css';

const marks = {};
[1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90].forEach(el => {
  marks[el.toString()] = '';
});

const RangeSlider = ({
  defaultValue, max, min, onChange, unit,
}) => {
  const myHandle = parameters => {
    const { value, ...rest } = parameters;
    return (
      <Handle className="handle" value={value} {...rest}>
        <span className="handle-tooltip">
          {`${value} ${unit}`}
        </span>
      </Handle>
    );
  };

  return (
    <div className="RangeSlider">
      <Slider
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={onChange}
        dots
        marks={marks}
        trackStyle={{ background: 'none' }}
        handle={myHandle}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
};

export { RangeSlider };
