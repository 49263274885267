import permissionsId from '../featuresTypes';
import getPermissionState from '../reduxStore/getPermissionState';

const isUserAllowedAccess = () => getPermissionState(permissionsId.STREAMS_READ || permissionsId.STREAMS_EDIT);

const isUserAllowedToConfigure = () => getPermissionState(permissionsId.STREAMS_EDIT);

export default {
  isUserAllowedAccess,
  isUserAllowedToConfigure,
};
