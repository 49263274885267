import API from '~services/endpoints';

import { fetchOperation, fetchOnceOperation } from '../fetchOperation';
import {
  getOperationsRequest,
  getOperationsResponse,
  addOperationRequest,
  addOperationResponse,
  updateOperationRequest,
  updateOperationResponse,
  deleteOperationRequset,
  deleteOperationResponse,
} from './actions';

function fetchOperations() {
  return fetchOnceOperation(
    getOperationsRequest,
    getOperationsResponse,
    API.getOperations,
    state => state.operations.operations,
  );
}

function forceFetchOperations() {
  return fetchOperation(
    getOperationsRequest,
    getOperationsResponse,
    API.getOperations,
    state => state.operations.operations,
  );
}

function addOperation(data) {
  return dispatch => {
    dispatch(addOperationRequest(data));
    return API.createOperation(data)
      .then(res => dispatch(addOperationResponse(res)));
  };
}

function updateOperation(id, data) {
  return dispatch => {
    dispatch(updateOperationRequest(id, data));
    return API.updateOperation(id, data)
      .then(res => dispatch(updateOperationResponse(res)));
  };
}

function deleteOperation(id) {
  return dispatch => {
    dispatch(deleteOperationRequset(id));
    return API.deleteOperation(id)
      .then(res => dispatch(deleteOperationResponse(res)));
  };
}

export default {
  fetchOperations,
  forceFetchOperations,
  addOperation,
  updateOperation,
  deleteOperation,
};
