import types from './types';

const getFormsRequest = () => ({
  type: types.GET_FORMS_REQUEST,
});

const getFormsResponse = data => ({
  type: types.GET_FORMS_RESPONSE,
  payload: data,
});

export {
  getFormsRequest,
  getFormsResponse,
};
