import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@intelligenceindustrielle/react-ui-components';
import { getParameterIcon } from '~components/MachineState';
import {
  FontAwesome,
  MachineOperationPopup,
  MachineOperatorPopup,
  MachineSKUPopup,
  MachineWorkorderPopup,
  ModalHandler,
} from '~UI';

const popupComponents = {
  operation: MachineOperationPopup,
  operator: MachineOperatorPopup,
  skuNumber: MachineSKUPopup,
  workOrder: MachineWorkorderPopup,
};

const MachineParameter = ({
  parameter, machineId, machineParams, rearranged, height, width,
}) => {
  const popupComponent = popupComponents[parameter.name];

  const baseFontSize = Math.min(height, width) * 0.06;
  const smallerFontSize = baseFontSize * 0.6;
  const smallFontSize = baseFontSize * 0.75;
  const mediumFontSize = baseFontSize * 0.8;
  const rearrangedMultiplier = rearranged ? 1.2 : 1;
  let fontSize;

  if (parameter.value.length > 25) {
    fontSize = Math.max(smallerFontSize, 10) * rearrangedMultiplier;
  } else if (parameter.value.length > 20) {
    fontSize = Math.max(smallFontSize, 10) * rearrangedMultiplier;
  } else if (parameter.value.length > 15) {
    fontSize = Math.max(mediumFontSize, 15) * rearrangedMultiplier;
  } else {
    fontSize = Math.max(baseFontSize, 15) * rearrangedMultiplier;
  }

  return (
    <div className={`MachineState__Param ${rearranged ? 'rearranged' : 'flex H'}`}>
      <div className="MachineState__Param__Button inlineBlock">
        {
          parameter.readOnly ? (
            <div className="not-button">
              <FontAwesome
                icon={getParameterIcon(parameter.name)}
                style={{
                  position: 'relative',
                  right: 3,
                }}
              />
            </div>
          ) : (
            <ModalHandler
              Trigger={{
                Component: Button,
                props: {
                  label: (
                    <div className="MachineState__Param__Button__Content">
                      <FontAwesome
                        icon={getParameterIcon(parameter.name)}
                      />
                    </div>
                  ),
                },
              }}
              Modal={{
                Component: popupComponent,
                props: {
                  machineId,
                  machineParams,
                  value: parameter.value,
                },
              }}
            />
          )
        }
      </div>
      <div className="MachineState__Param__Label inlineBlock" style={{ fontSize }}>
        {parameter.value}
      </div>
    </div>
  );
};

MachineParameter.propTypes = {
  machineId: PropTypes.string.isRequired,
  machineParams: PropTypes.object.isRequired,
  parameter: PropTypes.shape({
    name: PropTypes.string,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
  }).isRequired,
  rearranged: PropTypes.bool,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};
MachineParameter.defaultProps = {
  rearranged: false,
};

export { MachineParameter };
