import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import types from './types';

const INITIAL_STATE = { wasRedirected: true, locations: [] };

const redirectHistoryPropTypes = PropTypes.shape({
  wasRedirected: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.string),
});

function redirectHistory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.CLEAR:
      if (!state.wasRedirected) {
        return {
          ...state,
          locations: [],
        };
      }
      return {
        ...state,
        wasRedirected: false,
      };
    case types.POP:
      return {
        wasRedirected: true,
        locations: state.locations.slice(0, -1),
      };
    case types.PUSH:
      return {
        wasRedirected: true,
        locations: state.locations.includes(action.payload) ? state.locations : [...state.locations, action.payload],
      };
    default:
      return state;
  }
}

export const propTypes = {
  redirectHistoryPropTypes,
};

export default combineReducers({
  redirectHistory,
});
