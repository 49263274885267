import types from './types';

const getTagsRequest = () => ({
  type: types.GET_TAGS_REQUEST,
});
const getTagsResponse = response => ({
  type: types.GET_TAGS_RESPONSE,
  payload: response,
});

const addTagRequest = tag => ({
  type: types.ADD_TAG_REQUEST,
  payload: tag,
});

const addTagResponse = data => ({
  type: types.ADD_TAG_RESPONSE,
  payload: data,
});

const updateTagRequest = (tagId, tagName) => ({
  type: types.UPDATE_TAG_REQUEST,
  payload: { tagId, tagName },
});

const updateTagResponse = data => ({
  type: types.UPDATE_TAG_RESPONSE,
  payload: data,
});

const deleteTagRequest = tagId => ({
  type: types.DELETE_TAG_REQUEST,
  payload: tagId,
});

const deleteTagResponse = data => ({
  type: types.DELETE_TAG_RESPONSE,
  payload: data,
});

export {
  getTagsRequest,
  getTagsResponse,
  addTagRequest,
  addTagResponse,
  updateTagRequest,
  updateTagResponse,
  deleteTagRequest,
  deleteTagResponse,
};
