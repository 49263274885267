// alphabetical order
const actions = () => '/actions';
const admin = () => '/admin';
const changeLog = () => '/changelog';
const config = () => '/config';
const configDashboard = id => `/config/dashboard/${id}`;
const configRuleEngine = id => `/ruleEdition/${id}`;
const configTopview = id => `/config/topview/${id}`;
const dashboard = id => `/dashboard/${id}`;
const dashboards = () => '/dashboards';
const events = () => '/events';
const home = () => '/home';
const linkRedirection = linkId => `/redirect/${linkId}`;
const login = () => '/login';
const logs = () => '/logs';
const machine = id => `/machine/${id}`;
const machines = () => '/machines';
const operations = () => '/operations';
const report = id => `/report/${id}`;
const reports = () => '/reports';
const ruleEngine = () => '/ruleEngine';
const shifts = () => '/shifts';
const signup = () => '/signup';
const stopwatches = () => '/stopwatches';
const stream = id => `/stream/${id}`;
const inputs = streamId => `${stream(streamId)}/inputs`;
const images = () => '/images';
const streams = () => '/streams';
const topview = id => `/topview/${id}`;
const topviews = () => '/topviews';
const user = id => `/user/${id}`;
const users = () => '/users';
const variables = () => '/variables';
const verifyAccount = () => '/users/:id/verify';

const utils = {
  isConfig: path => path.startsWith(config()),
  // for creating a config link from a regular link
  configPathFrom: path => `${config()}${path}`,
};

const checkIfConfig = (prevPath, nextPath, isInConfigurationMode = false) => (
  (utils.isConfig(prevPath) || isInConfigurationMode)
    ? utils.configPathFrom(nextPath) : nextPath);

const paths = {
  // alphabetical order
  actions: actions(),
  admin: admin(),
  changeLog: changeLog(),
  config: config(),
  configDashboard: configDashboard(':selectedDashboard'),
  configRuleEngine: configRuleEngine(':selectedRule'),
  configTopview: configTopview(':selectedTopview'),
  dashboard: dashboard(':selectedDashboard'),
  dashboards: dashboards(),
  events: events(),
  home: home(),
  inputs: inputs(':selectedStream'),
  images: images(),
  linkRedirection: linkRedirection(':linkId'),
  login: login(),
  logs: logs(),
  machine: machine(':selectedMachine'),
  machines: machines(),
  operations: operations(),
  report: report(':selectedReport'),
  reports: reports(),
  ruleEngine: ruleEngine(),
  shifts: shifts(),
  signup: signup(),
  stopwatches: stopwatches(),
  stream: stream(':selectedStream'),
  streams: streams(),
  topview: topview(':selectedTopview'),
  topviews: topviews(),
  user: user(':selectedUser'),
  users: users(),
  variables: variables(),
  verifyAccount: verifyAccount(),
};

export default {
  paths,
  utils,
  // alphabetical order
  admin: (prevPath = '') => checkIfConfig(prevPath, admin()),
  changeLog,
  config,
  configDashboard: (id, prevPath = '') => checkIfConfig(prevPath, configDashboard(id)),
  configRuleEngine: (id, prevPath = '') => checkIfConfig(prevPath, configRuleEngine(id)),
  configRules: (id, prevPath = '') => checkIfConfig(prevPath, configRuleEngine(id)),
  configTopview: (id, prevPath = '') => checkIfConfig(prevPath, topview(id), true),
  dashboard: (id, prevPath = '') => checkIfConfig(prevPath, dashboard(id)),
  dashboards: (prevPath = '') => checkIfConfig(prevPath, dashboards()),
  events,
  home,
  inputs: (id, prevPath = '') => checkIfConfig(prevPath, inputs(id)),
  images: (prevPath = '') => checkIfConfig(prevPath, images()),
  linkRedirection,
  login,
  logs: (prevPath = '') => checkIfConfig(prevPath, logs()),
  machine: (id, prevPath = '') => checkIfConfig(prevPath, machine(id)),
  machines: (prevPath = '') => checkIfConfig(prevPath, machines()),
  operations: (prevPath = '') => checkIfConfig(prevPath, operations()),
  report: (id, prevPath = '') => checkIfConfig(prevPath, report(id)),
  reports: (prevPath = '') => checkIfConfig(prevPath, reports()),
  ruleEngine: (prevPath = '') => checkIfConfig(prevPath, ruleEngine()),
  shifts: (prevPath = '') => checkIfConfig(prevPath, shifts()),
  signup,
  stopwatches: (prevPath = '') => checkIfConfig(prevPath, stopwatches()),
  stream: (id, prevPath = '') => checkIfConfig(prevPath, stream(id)),
  streams: (prevPath = '') => checkIfConfig(prevPath, streams()),
  topview: (id, prevPath = '', isInConfigurationMode?) => checkIfConfig(prevPath, topview(id), isInConfigurationMode),
  topviews: (prevPath = '') => checkIfConfig(prevPath, topviews()),
  user: (id, prevPath = '') => checkIfConfig(prevPath, user(id)),
  users: (prevPath = '') => checkIfConfig(prevPath, users()),
  variables: (prevPath = '') => checkIfConfig(prevPath, variables()),
  verifyAccount,
};
