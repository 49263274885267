import React from 'react';
import PropTypes from 'prop-types';
import ReactMultiSelect from 'react-multi-select-component';
import { useTranslation } from 'react-i18next';
import './MultiSelect.scss';

const MultiSelect = ({ className, onChange, options, value }) => {
  const { t } = useTranslation();

  return (
    <ReactMultiSelect
      className={`multi-select ${className}`}
      labelledBy=""
      options={options}
      value={value}
      onChange={onChange}
      overrideStrings={{
        selectSomeItems: t('select'),
        allItemsAreSelected: t('selectedAll'),
        selectAll: t('selectAll'),
        search: t('Search'),
      }}
    />
  );
};

MultiSelect.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
};
MultiSelect.defaultProps = {
  className: '',
};

export { MultiSelect };
