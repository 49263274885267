import { sortArray } from '~utils/sort';

function getStreamsToGroupedOptions(streams) {
  return streams.map(stream => {
    const sortedProperties = sortArray('alphabetically', stream.properties, 'variable');
    return ({
      label: `Stream - ${stream.name}`,
      options: sortedProperties.map(property => (
        {
          value: property.id,
          label: property.variable,
        }
      )),
    });
  });
}

export { getStreamsToGroupedOptions };
