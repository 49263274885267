import { get, post, put, del } from '../endpoints';

export const getOperators = () => get('/operators');

export const createOperator = operator => post('/operators', {}, operator);

export const updateOperator = (id, operator) => put('/operators/:id', { id }, operator);

export const updateOperatorMachines = (id, machinesIds) => put('/operators/:id/machines', { id }, machinesIds);

export const deleteOperator = id => del('/operators/:id', { id });
