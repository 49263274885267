import types from './types';

const getReportsRequest = () => ({
  type: types.GET_REPORTS_REQUEST,
});
const getReportsResponse = response => ({
  type: types.GET_REPORTS_RESPONSE,
  payload: response,
});

const addReportRequest = report => ({
  type: types.ADD_REPORT_REQUEST,
  payload: report,
});
const addReportResponse = data => ({
  type: types.ADD_REPORT_RESPONSE,
  payload: data,
});

const updateReportRequest = (id, report) => ({
  type: types.UPDATE_REPORT_REQUEST,
  payload: { id, report },
});
const updateReportResponse = data => ({
  type: types.UPDATE_REPORT_RESPONSE,
  payload: data,
});

const deleteReportRequest = id => ({
  type: types.DELETE_REPORT_REQUEST,
  payload: id,
});
const deleteReportResponse = id => ({
  type: types.DELETE_REPORT_RESPONSE,
  payload: id,
});

export {
  getReportsRequest,
  getReportsResponse,
  addReportRequest,
  addReportResponse,
  updateReportRequest,
  updateReportResponse,
  deleteReportRequest,
  deleteReportResponse,
};
