import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { getMetric, getStatisticsFrom } from '~components/utils';
import { FontAwesome, IntervalTimePicker } from '~UI';
import { sortArray } from '~utils/sort';

const valueOptions = [
  'useRate',
  'availability',
  'performanceRatio',
  'qualityRatio',
  'overallEffectiveness',
  'totalEffectivePerformance',
  'inputCount',
  'outputCount',
  'scrapCount',
  'acceptableOutputCount',
  'operationProgress',
];

const FormGaugeMachine = ({ selectedObject, selectedType }) => {
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));
  const machinePropertiesOptions = valueOptions.map(v => ({ label: t(v), value: v }));

  const [currentOperation, setOperation] = useState(selectedObject.currentOperation || false);
  const [currentSKUNumber, setSKUNumber] = useState(selectedObject.currentSKUNumber || false);
  const [currentWorkOrder, setWorkOrder] = useState(selectedObject.currentWorkOrder || false);
  const [valueType, setValueType] = useState(selectedObject.valueType || 'useRate');
  const [intervalMax, setIntervalMax] = useState(selectedObject.intervalMax || 100);
  const [intervalMin, setIntervalMin] = useState(selectedObject.intervalMin || 0);

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        defaultValue={selectedObject.machineId}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('machineKPI')}</div>
      <Select
        className="fullwidth"
        name="valueType"
        value={valueType}
        onChange={setValueType}
        options={machinePropertiesOptions}
        placeholder={t('select')}
      />
      {t(`${valueType}Desc`) && (
        <div>
          <FontAwesome icon="info-circle" />
          {`  ${t(`${valueType}Desc`)}`}
        </div>
      )}

      { valueType !== 'operationProgress' && (
        <>
          <div>
            <div className="multipleSelectionInput">
              <Checkbox
                controlledCheck={currentOperation}
                checked={currentOperation}
                onChange={() => setOperation(!currentOperation)}
                label={t('currentOperation')}
              />
            </div>
            <div className="multipleSelectionInput">
              <Checkbox
                controlledCheck={currentSKUNumber}
                checked={currentSKUNumber}
                onChange={value => setSKUNumber(value)}
                label={t('currentSKUNumber')}
                disabled={currentOperation && getStatisticsFrom(valueType) === 'operation'}
              />
            </div>
            <div className="multipleSelectionInput">
              <Checkbox
                controlledCheck={currentWorkOrder}
                checked={currentWorkOrder}
                onChange={value => setWorkOrder(value)}
                label={t('currentWorkOrder')}
                disabled={currentOperation && getStatisticsFrom(valueType) === 'operation'}
              />
            </div>
          </div>

          <div>
            <input
              type="hidden"
              name="currentOperation"
              value={currentOperation}
            />
            <input
              type="hidden"
              name="currentSKUNumber"
              value={currentSKUNumber}
            />
            <input
              type="hidden"
              name="currentWorkOrder"
              value={currentWorkOrder}
            />
          </div>

          {
            getMetric(valueType) === 'unit' && (
              <div>
                <div className="inputTitle">{t('interval')}</div>
                <span>{t('between')}</span>
                &nbsp;
                <input
                  disabled={selectedType === 'circularChart'}
                  type="number"
                  name="number:intervalMin"
                  min={0}
                  step={1}
                  value={selectedType === 'circularChart' ? 0 : intervalMin}
                  onChange={e => setIntervalMin(e.currentTarget.value)}
                />
                &nbsp;
                <span>{t('and')}</span>
                &nbsp;
                <input
                  type="number"
                  name="number:intervalMax"
                  min={0}
                  step={1}
                  value={intervalMax}
                  onChange={e => setIntervalMax(e.currentTarget.value)}
                />
              </div>
            )
          }
        </>
      )}

      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={selectedObject.intervalType}
        intervalTypeInput
        alwaysOption={currentOperation && getStatisticsFrom(valueType) === 'operation'}
      />
    </div>
  );
};

FormGaugeMachine.propTypes = {
  selectedObject: PropTypes.shape({
    currentOperation: PropTypes.bool,
    currentSKUNumber: PropTypes.bool,
    currentWorkOrder: PropTypes.bool,
    intervalMax: PropTypes.number,
    intervalMin: PropTypes.number,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
    valueType: PropTypes.string,
  }),
  selectedType: PropTypes.string.isRequired,
};
FormGaugeMachine.defaultProps = {
  selectedObject: {},
};

export { FormGaugeMachine };
