import types from '../types';

const addDefectCauseRequest = data => ({
  type: types.ADD_DEFECT_CAUSE_REQUEST,
  payload: data,
});

const addDefectCauseResponse = (data, request = null) => ({
  type: types.ADD_DEFECT_CAUSE_RESPONSE,
  payload: data,
  request,
});

const updateDefectCauseRequest = data => ({
  type: types.UPDATE_DEFECT_CAUSE_REQUEST,
  payload: data,
});

const updateDefectCauseResponse = (data, request = null) => ({
  type: types.UPDATE_DEFECT_CAUSE_RESPONSE,
  payload: data,
  request,
});

const deleteDefectCauseRequest = data => ({
  type: types.DELETE_DEFECT_CAUSE_REQUEST,
  payload: data,
});

const deleteDefectCauseResponse = (data, request = null) => ({
  type: types.DELETE_DEFECT_CAUSE_RESPONSE,
  payload: data,
  request,
});

const addSubDefectCauseRequest = data => ({
  type: types.ADD_SUB_DEFECT_CAUSE_REQUEST,
  payload: data,
});

const addSubDefectCauseResponse = (data, request = null) => ({
  type: types.ADD_SUB_DEFECT_CAUSE_RESPONSE,
  payload: data,
  request,
});

const updateSubDefectCauseRequest = data => ({
  type: types.UPDATE_SUB_DEFECT_CAUSE_REQUEST,
  payload: data,
});

const updateSubDefectCauseResponse = (data, request = null) => ({
  type: types.UPDATE_SUB_DEFECT_CAUSE_RESPONSE,
  payload: data,
  request,
});

export {
  addDefectCauseRequest,
  addDefectCauseResponse,
  updateDefectCauseRequest,
  updateDefectCauseResponse,
  deleteDefectCauseRequest,
  deleteDefectCauseResponse,
  addSubDefectCauseRequest,
  addSubDefectCauseResponse,
  updateSubDefectCauseRequest,
  updateSubDefectCauseResponse,
};
