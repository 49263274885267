import React from 'react';

const timeline = ({ height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 450.83 165"
    height={height * 0.8}
    fill="currentcolor"
  >
    <g>
      <g>
        <path d="
          M 405.33 146.5
          L 412.83 146.5
          L 412.83 153
          L 405.33 153
          L 7.5 153
          L 40 153
          L 40 146.5
          L 7.5 146.5
          L 405.33 146.5 Z "
        />
        <g>
          <rect x="40" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="80" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="120" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="160" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="200" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="240" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="280" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="320" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="360" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
          <rect x="400" y="148" width="8" height="17" transform="matrix(1,0,0,1,0,0)" />
        </g>
      </g>
      <g>
        <path
          d="M 374 0 L 362 0 L 362 0 L 362 142 L 374 142 L 374 0 Z
        M 379 0 L 401 0 L 401 142 L 379 142 L 379 0 Z
        M 357 0 L 227 0 L 227 0 L 227 142 L 357 142 L 357 0 L 357 0 Z
        M 217 0 L 183 0 L 183 0 L 183 142 L 217 142 L 217 0 L 217 0 L 217 0 Z
        M 162 0 L 154 0 L 154 0 L 154 142 L 162 142 L 162 0 L 162 0 L 162 0 L 162 0 Z
        M 133 0 L 95 0 L 95 0 L 95 142 L 133 142 L 133 0 L 133 0 L 133 0 L 133 0 L 133 0 Z
        M 80 0 L 40 0 L 40 0 L 40 142 L 80 142 L 80 0 L 80 0 L 80 0 L 80 0 L 80 0 L 80 0 Z
 "
        />
      </g>
    </g>
  </svg>
);

export default timeline;
