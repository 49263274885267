/**
 * This file synchronizes all reoccuring jobs accross the application so that
 * they all fire (almost) at the same time
 */
interface Subscriber {
  call: () => void;
  delay: number;
}

let subscribers: Subscriber[] = [];
let tick = 0;
let interval: ReturnType<typeof setInterval> | undefined;

function handleTick() {
  tick += 1;
  subscribers
    .filter(s => (tick % s.delay) === 0)
    .forEach(s => s.call());
}

const Tick = {
  start: () => {
    if (typeof interval === 'undefined') {
      interval = setInterval(handleTick, 1000);
    }
  },

  stop: () => {
    clearInterval(interval);
    subscribers = [];
    tick = 0;
    interval = undefined;
  },

  /**
   * @param {function} call  The function to call every `delay` seconds.
   * @param {number}   delay The number of seconds between each calls.
   */
  subscribe: (call, delay = 1) => {
    subscribers.push({ call, delay });
  },

  /**
   * @param {function} call  The function to remove from Tick's call list.
   */
  unsubscribe: call => {
    const index = subscribers.map(t => t.call).indexOf(call);
    if (index >= 0) {
      subscribers.splice(index, 1);
    }
  },
};

export default Tick;
