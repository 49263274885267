import types from './types';

const patchEventRequest = (eventId, eventData) => ({
  type: types.PATCH_EVENT_REQUEST,
  payload: { eventId, eventData },
});
const patchEventResponse = response => ({
  type: types.PATCH_EVENT_RESPONSE,
  payload: response,
});

export { patchEventRequest, patchEventResponse };
