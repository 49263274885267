import types from './types';

const setConfigurationMode = bool => ({
  type: types.SET_CONFIGURATION_MODE,
  payload: bool,
});

const setFullscreen = bool => ({
  type: types.SET_FULLSCREEN,
  payload: bool,
});

const setLanguage = language => ({
  type: types.SET_LANGUAGE,
  payload: language,
});

export {
  setConfigurationMode,
  setFullscreen,
  setLanguage,
};
