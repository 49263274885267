import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services';
import { showSuccess, showError } from '~utils/toast';
import { CancelButton, SubmitButton } from '~UI/Buttons';
import { DefaultModal } from '~UI/DefaultModal';
import { triggerTypes } from '~utils/types';
import TagsInput from '~UI/Tags/TagsInput';
import { idsToTags } from '~utils/tags';

const propTypes = {
  // explicitly given props
  show: PropTypes.bool,
  modifiedItemId: PropTypes.string,
  onHide: PropTypes.func,
  updateTags: PropTypes.func,
};

const defaultProps = {
  onHide: () => { },
  show: false,
  modifiedItemId: '',
  updateTags: null,
};

const RuleTagsForm = ({ show, modifiedItemId, onHide, updateTags }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const triggers = useSelector(state => state.triggers);
  const tags = useSelector(state => state.tags.tags);

  const [trigger, setTrigger] = useState(modifiedItemId && triggers.find(tr => tr.id === modifiedItemId));
  const [triggerTags, setTriggerTags] = useState(trigger && idsToTags(trigger.tags, tags));

  useEffect(() => {
    setTrigger(modifiedItemId && triggers.find(tr => tr.id === modifiedItemId));
    setTriggerTags(trigger && idsToTags(trigger.tags, tags));
  }, [modifiedItemId]);

  useEffect(() => {
    setTriggerTags(trigger && idsToTags(trigger.tags, tags));
  }, [tags]);

  const handleOnHide = () => {
    onHide();
    const originalTriggerTags = trigger ? idsToTags(trigger.tags, tags) : [];
    setTriggerTags(originalTriggerTags);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setTrigger({
      ...trigger,
      tags: triggerTags.map(tag => tag.id),
    });
    if (updateTags) {
      updateTags(triggerTags);
      onHide();
      return;
    }

    const isFakeDefaults = [triggerTypes.BUTTON].includes(trigger.type);
    let triggerDetails = {};
    if (trigger.details && Object.entries(trigger.details).length > 0) {
      triggerDetails = trigger.details;
    } else if (isFakeDefaults) {
      triggerDetails = { type: trigger.type };
    }

    const request = {
      details: triggerDetails,
      isActive: trigger.isActive,
      name: trigger.name,
      type: isFakeDefaults ? triggerTypes.DEFAULT : trigger.type,
      actionsToPerform: trigger.actionsToPerform || [],
      condition: isEmpty(trigger.condition) ? null : trigger.condition,
      tags: triggerTags,
    };

    if (modifiedItemId && request) {
      dispatch(reduxOperations.triggers.updateTrigger(modifiedItemId, request))
        .then(() => {
          onHide();
          dispatch(reduxOperations.tags.forceFetchTags());
          showSuccess(t('showSuccessUpdated'));
        })
        .catch(() => showError(t('invalidFormData')));
    } else {
      showError(t('invalidFormData'));
    }
  };

  return (
    <DefaultModal
      title={t('modificationTags')}
      show={show}
      closePopup={handleOnHide}
    >
      <form
        id="RuleTags__form"
        onSubmit={handleSubmit}
      >
        <div className="inputTitle">{t('tags')}</div>
        <TagsInput
          tags={tags}
          currentTags={triggerTags || []}
          modifyTags={newTags => setTriggerTags(newTags)}
        />
        <div className="buttonsHolder" style={{ textAlign: 'right' }}>
          <div>
            <CancelButton onClick={handleOnHide} />
            <SubmitButton />
          </div>
        </div>
      </form>
    </DefaultModal>
  );
};

RuleTagsForm.propTypes = propTypes;
RuleTagsForm.defaultProps = defaultProps;

export default RuleTagsForm;
