import { triggerTypes } from '../types';

const getTriggerIcon = triggerType => {
  switch (triggerType) {
    case triggerTypes.DEFAULT:
      return 'link';
    case triggerTypes.BUTTON:
      return 'button';
    case triggerTypes.CRON:
      return 'clock';
    case triggerTypes.MACHINE_PARAM_CHANGE:
      return 'hashtag';
    case triggerTypes.STOP_CAUSE_ASSIGN:
    case triggerTypes.STOP_CAUSE:
      return 'stop-circle';
    case triggerTypes.VALUE_CHANGE:
    case triggerTypes.VALUE_SET:
    case triggerTypes.VALUE:
      return 'ii-variable';
    case triggerTypes.WEBHOOK:
      return 'link';
    case '+':
      return 'plus';
    default:
      return null;
  }
};

export default getTriggerIcon;
