function getMetric(property) {
  switch (property) {
    case 'inputCount':
    case 'outputCount':
    case 'scrapCount':
    case 'acceptableOutputCount':
    case 'operationProgress':
      return 'unit';
    default:
      return 'percentage';
  }
}

export { getMetric };
