import { sortArray } from '~utils/sort';

function getMachinesKPISToGroupedOptions(machines) {
  return machines.map(machine => {
    const sortedProperties = sortArray('alphabetically', machine.kpis, 'name');
    return ({
      label: `Machine - ${machine.name}`,
      options: sortedProperties.map(property => (
        {
          value: property.id,
          label: property.variable,
        }
      )),
    });
  });
}

export { getMachinesKPISToGroupedOptions };
