import React from 'react';
import PropTypes from 'prop-types';

import FontAwesome from '../FontAwesome';
import './Button.scss';

const SquaredAddButton = ({ className, ...props }) => (
  <div
    className={`SquaredAddButton ${className || ''}`}
    {...props}
  >
    <FontAwesome icon="plus" />
  </div>
);

SquaredAddButton.propTypes = {
  className: PropTypes.string.isRequired,
};

export default SquaredAddButton;
