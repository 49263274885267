import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { IconsLibrary } from '~images/imagesLibrary';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { DefaultModal } from '../DefaultModal';
import DefaultSubmitButton from '../Buttons/DefaultSubmitButton';
import FileUpload from '../File/FileUpload';
import './IconPicker.scss';
import iconsKeywords from './iconsKeywords.json';
import { handleFileSelect } from '~utils/handleFileSelect';

function IconPicker({ onClick, defaultIcon, images }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [icon, setIcon] = useState(defaultIcon);
  const [searchText, setSearchText] = useState('');
  const keys = Object.keys(IconsLibrary);

  const handleClick = name => {
    setIcon(name);
    onClick(name);
    setShow(false);
  };

  const hasKeyword = keywords => (
    keywords.some(keyword => {
      const normalizedKeyword = keyword.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const normalizedSearchText = searchText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return normalizedKeyword.startsWith(normalizedSearchText);
    }));

  const getIcon = key => {
    const option = {
      icon: key,
    };
    return (
      <StopCauseIcon
        key={key}
        option={option}
        onClick={() => handleClick(key)}
      />
    );
  };

  const onFileSelect = selectedFile => {
    handleFileSelect(selectedFile, dispatch, t, fileIcon => {
      setIcon(fileIcon);
      onClick(fileIcon);
    });
  };

  return (
    <>
      {show && <div className="fade modal-backdrop in" />}
      <DefaultModal
        title={t('selectIcon')}
        show={show}
        closePopup={() => { setShow(false); }}
        style={{ zIndex: 1500 }}
      >
        <div className="searchBar">
          <input
            placeholder={t('searchByKeyword')}
            onChange={e => setSearchText(e.target.value.toUpperCase())}
            data-testid="iconpicker-searchInput"
          />
        </div>
        <div
          className="IconsContainer"
          data-testid="iconpicker-iconsList"
        >
          {
            keys
              .filter(key => hasKeyword(iconsKeywords[key]))
              .map(key => getIcon(key))
          }
        </div>
      </DefaultModal>
      <div className="inputTitle">{t('icon')}</div>
      <input type="hidden" readOnly name="icon" value={icon} />
      <DefaultSubmitButton
        label={t('selectIcon')}
        isActive
        onClick={() => setShow(true)}
      />
      <div className="inputTitle">{ t('image')}</div>
      <div className="file-upload-stopcause">
        <FileUpload
          onFileSelect={onFileSelect}
          images={images}
          image={icon}
          setImage={image => { onClick(image); setIcon(image); }}
          type="causesIcons"
          options={{ blueButton: true, popoverTop: true }}
        />
      </div>

      <input type="hidden" readOnly name="icon" value={icon} />
    </>
  );
}

IconPicker.propTypes = {
  onClick: PropTypes.func.isRequired,
  defaultIcon: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export {
  IconPicker,
};
