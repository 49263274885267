import React from 'react';
import PropTypes from 'prop-types';
import BarChartEvents from './BarChartEvents';
import BarChartMachine from './BarChartMachine';
import BarChartVariables from './BarChartVariables';

const BarChartTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const { barChartType } = tile;

  if (barChartType === 'events') {
    return (
      <BarChartEvents
        backgroundColor={backgroundColor}
        height={height}
        tile={tile}
        width={width}
      />
    );
  }
  if (barChartType === 'machine') {
    return (
      <BarChartMachine
        backgroundColor={backgroundColor}
        height={height}
        tile={tile}
        width={width}
      />
    );
  }
  if (barChartType === 'variables') {
    return (
      <BarChartVariables
        backgroundColor={backgroundColor}
        height={height}
        tile={tile}
        width={width}
      />
    );
  }

  return null;
};

BarChartTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  tile: PropTypes.shape({
    barChartType: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
  }).isRequired,
  width: PropTypes.number,
};

export default BarChartTile;
