import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import isEqual from 'lodash.isequal';
import textures from 'textures';
import { millisToHHmmss } from '~utils/time';
import {
  PADDING_LEFT,
  PADDING_RIGHT,
  PADDING_LEFT_WITH_ARROWS,
  PADDING_RIGHT_WITH_ARROWS,
  PADDING_TOP,
  PADDING_BOTTOM,
  FONT_SIZE_AXISX,
} from './constants';
import './Charts.scss';

const propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  hasArrows: PropTypes.bool.isRequired,
  domainX: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  events: PropTypes.arrayOf(PropTypes.object),
  performanceData: PropTypes.arrayOf(PropTypes.object),
  performanceEvents: PropTypes.arrayOf(PropTypes.object),
  showPopup: PropTypes.func,
  showPerformancePopup: PropTypes.func,
};

const defaultProps = {
  data: [],
  events: [],
  performanceData: [],
  performanceEvents: [],
  showPopup: () => undefined,
  showPerformancePopup: () => undefined,
};

const calculateXAxis = (domainBorders, width) => {
  const start = domainBorders[0];
  const end = domainBorders[1];
  // width of a time on the x axis in px + 5px of padding. Ex: "23:40"
  const timeWidthSize = 35 + 5;
  const oneHour = 3600000;
  const xAxisWidth = (width - PADDING_LEFT * 2);
  const nbHoursInShift = (end - start) / oneHour;
  const theoreticalTimeGap = oneHour / ((xAxisWidth / timeWidthSize) / nbHoursInShift);
  let timeGap = oneHour;
  let finalTimeGap = 1;
  // At each iteration, we try to reduce the gap as much as possible on the xAxis
  const hourGapOptions = [16, 8, 4, 2, 1, 0.5, 0.25];
  hourGapOptions.forEach(gap => {
    if (theoreticalTimeGap < oneHour * gap) {
      timeGap = oneHour * gap;
      finalTimeGap = gap;
    }
  });

  const domainX = [0];
  for (let i = start; i < end; i += timeGap) {
    domainX.push(i);
  }

  const numberOfTicks = (end - start) / timeGap;
  const spaceBetweenTicks = xAxisWidth / numberOfTicks;
  const lastTickPosition = Math.trunc(numberOfTicks) * spaceBetweenTicks;

  // The ratio of the time difference between the last tick and the before to last tick.
  // E.g.: before to last tick : 16:00
  // last tick: 16:15
  // hourRatioOfLastTick = 15 / 16 = 0.25
  const hourRatioOfLastTick = (end - domainX[domainX.length - 1]) / oneHour;

  // If the last tick is a "good tick" (16:00 when the time gap is one hour, 16:30 when the time gap is 30 min etc..)
  // OR if the last "good tick" position and the end of the shift position is greater than 35px
  if (hourRatioOfLastTick < finalTimeGap && xAxisWidth - lastTickPosition < 35) {
    domainX.pop();
  }
  domainX.push(end);
  return domainX;
};

class Timeline extends Component {
  componentDidMount() {
    this.tooltip = d3.select('body').append('div')
      .attr('class', 'tooltip');
  }

  componentDidUpdate(prevProps) {
    const {
      height, width, domainX, data, events, performanceData, performanceEvents,
    } = this.props;
    if (
      prevProps.height !== height || prevProps.width !== width
      || !isEqual(prevProps.domainX, domainX)
      || !isEqual(prevProps.data, data)
      || !isEqual(prevProps.events, events)
      || !isEqual(prevProps.performanceData, performanceData)
      || !isEqual(prevProps.performanceEvents, performanceEvents)
    ) {
      this.createStackedBarChart();
    }
  }

  componentWillUnmount() {
    this.tooltip.remove();
  }

  createStackedBarChart = () => {
    const {
      height, width, domainX, hasArrows,
      data, events, showPopup, performanceData, showPerformancePopup, performanceEvents,
    } = this.props;
    const { node } = this;
    const heightChart = 0.85 * height - PADDING_BOTTOM;

    // Remove everything inside the div
    d3.select(node).selectAll('*').remove();

    // SVG for the graph
    const svg = d3.select(node)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    const x = d3.scaleTime()
      .domain(domainX)
      .range([
        hasArrows
          ? PADDING_LEFT_WITH_ARROWS
          : PADDING_LEFT, width - (hasArrows ? PADDING_RIGHT_WITH_ARROWS : PADDING_RIGHT),
      ]);
    const y = d3.scaleBand()
      .domain(['state'])
      .range([heightChart, PADDING_TOP]);

    // X Axis
    const xAxis = d3.axisBottom(x)
      .tickValues(calculateXAxis(domainX, width))
      .tickSizeOuter(0)
      .tickFormat(d3.timeFormat('%H:%M'));
    svg.append('g')
      .attr('transform', `translate(0,${heightChart})`)
      .call(xAxis)
      .style('font-size', FONT_SIZE_AXISX);

    // Machine status ON
    svg.append('g')
      .selectAll('rect')
      .data(data.filter(d => d.status === 'ON'))
      .join('rect')
      .attr('x', d => x(d.start))
      .attr('y', y('state'))
      .attr('width', d => x(d.end) - x(d.start))
      .attr('height', y.bandwidth())
      .attr('fill', d => d.color);

    // Each texture has to be generated and called by the SVG beforehand
    const uniquePerformanceColors = [...new Set(performanceData.map(event => event.color))];
    const texturesList = {};
    uniquePerformanceColors.forEach(color => {
      const texture = textures.lines().size(30).strokeWidth(1.3).stroke(color);
      texturesList[color] = texture;
      svg.call(texture);
    });

    // Performance status
    svg.append('g')
      .selectAll('rect')
      .data(performanceData)
      .join('rect')
      .attr('x', d => x(d.start))
      .attr('y', y('state'))
      .attr('width', d => x(d.end) - x(d.start))
      .attr('height', y.bandwidth)
      .attr('fill', d => texturesList[d.color].url())
      .classed('cursorpointer', true)
      .on('click', d => {
        const event = performanceEvents.find(e => e.id === d.id);
        if (event) {
          showPerformancePopup(event);
        }
      });

    // Machine status OFF
    svg.append('g')
      .selectAll('rect')
      .data(data.filter(d => d.status !== 'ON'))
      .join('rect')
      .attr('x', d => x(d.start))
      .attr('y', y('state'))
      .attr('width', d => x(d.end) - x(d.start))
      .attr('height', y.bandwidth())
      .attr('fill', d => d.color)
      .classed('cursorpointer', d => d.status === 'OFF')
      .on('click', d => {
        if (d.status === 'OFF') {
          const event = events.find(e => e.id === d.id);
          if (event) {
            showPopup(event);
          }
        }
      });

    // Tooltips
    const { tooltip } = this;
    svg.on('mousemove touchmove', () => {
      const [mouseX] = d3.mouse(node);
      const graphX = (x.invert(mouseX)).getTime();
      const performanceEvent = performanceData.find(d => d.start <= graphX && graphX < d.end);
      const machineStatusEvent = data.find(d => d.start <= graphX && graphX < d.end);
      const machineParams = machineStatusEvent?.params;
      const eventToShow = (machineStatusEvent?.status === 'OFF') ? machineStatusEvent : performanceEvent || machineStatusEvent;

      if (eventToShow) {
        let htmlContent = `<b style="font-size: 1.1em;">${millisToHHmmss(eventToShow.start)} - ${millisToHHmmss(eventToShow.end)}</b><br/>`;
        if (eventToShow.label) {
          if (eventToShow.hierarchyCause) {
            htmlContent += `<b style="font-size: 1.1em;">${eventToShow.hierarchyCause} -> ${eventToShow.label}</b><br/>`;
          } else {
            htmlContent += `<b style="font-size: 1.1em;">${eventToShow.label}</b><br/>`;
          }
        }
        htmlContent += eventToShow.duration ? `<i style="font-size:14px" class="fa">&#xf017;</i>  ${eventToShow.duration}<br/>` : '';
        htmlContent += machineParams?.operator ? `<i style="font-size:14px" class="fa">&#xf007;</i> ${machineParams.operator}<br/>` : '';
        htmlContent += machineParams?.workOrder ? `<i style="font-size:14px" class="fa">&#xf145;</i> ${machineParams.workOrder}<br/>` : '';
        htmlContent += machineParams?.operation ? `<i style="font-size:14px" class="fa">&#xf013;</i> ${machineParams.operation}<br/>` : '';
        htmlContent += machineParams?.skuNumber ? `<i style="font-size:14px" class="fa">&#xf292;</i> ${machineParams.skuNumber}` : '';

        tooltip.html(htmlContent)
          .style('visibility', 'visible')
          .style('color', 'white')
          .style('font-size', '14px');

        const tooltipBox = tooltip.node().getBoundingClientRect();
        const tooltipHeight = tooltipBox.height;
        let tooltipY = d3.event.pageY + (tooltipHeight / 2);

        if (tooltipY < 245) {
          tooltipY = d3.event.pageY + (tooltipHeight * 1.5);
        }

        tooltip.style('left', `${d3.event.pageX}px`)
          .style('bottom', `${window.innerHeight - tooltipY}px`);
      } else {
        tooltip.style('visibility', 'hidden');
      }
    });
    svg.on('mouseleave touchend', () => {
      tooltip.style('visibility', 'hidden');
    });
  };

  render() {
    return <div ref={node => this.node = node} />;
  }
}

Timeline.propTypes = propTypes;
Timeline.defaultProps = defaultProps;
function mapStateToProps(state) {
  return {
    language: state.views.language,
  };
}

export default connect(mapStateToProps)(Timeline);
