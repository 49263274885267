import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { reduxOperations } from '~services';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI';
import directLinks from '~utils/directLinks';

import UserModificationForm from './UserModificationForm/UserModificationForm';

const UserPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { selectedUser } = useParams();
  const reports = useSelector(state => state.reports.reports);
  const users = useSelector(state => state.users.users);
  const fetchReports = () => dispatch(reduxOperations.reports.fetchReports());
  const fetchUsers = () => dispatch(reduxOperations.users.fetchUsers());
  const fetchAllUsersPermissions = () => dispatch(reduxOperations.users.fetchAllUsersPermissions());

  const [user, setUser] = useState({});

  useEffect(() => {
    if (users) {
      setUser(users.find(u => u.id === selectedUser));
    }
  }, [users]);

  const content = () => (
    <PageTemplate
      sidebar
      title={user.name}
    >
      <UserModificationForm
        accountType={user.accountType}
        modifiedItemId={selectedUser}
        onHide={() => history.push(directLinks.users(location.pathname))}
      />
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[reports, users, users[0].permissions]}
      resourceFetchers={[fetchReports, fetchUsers, fetchAllUsersPermissions]}
      getContents={content}
    />
  );
};

export { UserPage };
