import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '~services/store';
import { TextInput } from '~UI/index';
import { idToReadable, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './Forms.scss';

const FormText = ({ selectedObject }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);
  const [text, setText] = useState((selectedObject && selectedObject.text) || '');
  const [showError, setShowError] = useState(false);

  const properties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const allVariables = [...properties, ...variables, ...kpis];
  const sortedVariables = sortArray('alphabetically', allVariables, 'variable').map(x => x.variable);
  sortedVariables.splice(0, 0, 'NOW');

  const handleTextChange = newText => {
    let id = newText;
    try {
      id = variableToId(newText, allVariables);
      setShowError(false);
    } catch (error) {
      // TODO: Error handling of non existent variable is not made and should be done
      setShowError(true);
    }
    setText(id);
  };

  return (
    <div className="Form">
      <div className="inputTitle">
        {t('text')}
        {' '}
        {/*
          showError and variableUsedIsNotDefined message should be deleted when the catch in handleTextChange is handled
        */}
        {showError && <span style={{ color: 'red', fontSize: '16px' }}>{t('variableUsedIsNotDefined')}</span>}
      </div>
      <TextInput
        className="fullwidth"
        Component="textarea"
        onChange={handleTextChange}
        options={sortedVariables}
        placeholder={t('triggerVariableList')}
        trigger="$"
        value={idToReadable(text)}
      />
      <input
        name="text"
        type="hidden"
        value={text}
      />
    </div>
  );
};

FormText.propTypes = {
  selectedObject: PropTypes.shape({
    text: PropTypes.string,
  }),
};
FormText.defaultProps = {
  selectedObject: {},
};

export { FormText };
