import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { serverTime } from '~utils/time';
import 'react-datepicker/dist/react-datepicker.css';
import { useShift } from '~utils/hooks';

const IntervalTimePicker = ({
  alwaysOption,
  intervalTypeInput,
  customOption,
  changeIntervalType,
  intervalType: intervalTypeProp,
  startDate,
  endDate,
  handleChangeDate,
}) => {
  const { t } = useTranslation();

  const [currentShift] = useShift(null);

  const [intervalType, setIntervalType] = useState(intervalTypeProp);

  const handleChangeIntervalType = newIntervalType => {
    // if the parent component needs to update the value in its state
    changeIntervalType(newIntervalType);

    setIntervalType(newIntervalType);
  };

  const handleSelection = val => {
    switch (val) {
      case 'lastHour':
        handleChangeDate(serverTime() - 3600000, serverTime());
        handleChangeIntervalType('lastHour');
        break;
      case 'shift':
        handleChangeDate(currentShift.start.getTime(), currentShift.end.getTime());
        handleChangeIntervalType('shift');
        break;
      case 'last24Hours':
        handleChangeDate(serverTime() - 86400000, serverTime());
        handleChangeIntervalType('last24Hours');
        break;
      case 'custom':
        handleChangeIntervalType('custom');
        break;
      case 'always':
        handleChangeDate(null, null);
        handleChangeIntervalType('always');
        break;
      default:
        break;
    }
  };

  const customInterval = () => (
    <div style={{ marginTop: '5px' }}>
      <span style={{ marginRight: '5px' }}>{t('from')}</span>
      <DatePicker
        className="form-control"
        selected={new Date(parseInt(startDate, 10))}
        onChange={e => handleChangeDate(e.getTime(), null)}
        showTimeSelect
        dateFormat="MM/d/yyyy h:mm aa"
      />
      <span style={{ margin: '0 5px 0 5px' }}>{t('to')}</span>
      <DatePicker
        className="form-control"
        selected={new Date(parseInt(endDate, 10))}
        onChange={e => handleChangeDate(null, e.getTime())}
        showTimeSelect
        dateFormat="MM/d/yyyy h:mm aa"
      />
    </div>
  );

  const buttons = [
    { label: t('lastHour'), value: 'lastHour' },
    { label: t('shift'), value: 'shift' },
    { label: t('last24Hours'), value: 'last24Hours' },
  ];

  if (customOption) {
    buttons.push({ label: t('customized'), value: 'custom' });
  }

  if (alwaysOption) {
    buttons.push({ label: t('sinceAlways'), value: 'always' });
  }

  useEffect(() => {
    handleSelection(intervalType);
  }, []);

  useEffect(() => {
    handleChangeIntervalType(intervalType);
  }, [intervalTypeProp]);

  return (
    <div className="Interval__TimePicker">
      <ButtonGroup
        buttons={buttons}
        onChange={handleSelection}
        value={intervalType}
      />
      {
        intervalTypeInput && (
          <input
            type="hidden"
            name={typeof intervalTypeInput === 'boolean' ? 'intervalType' : intervalTypeInput}
            value={intervalType}
          />
        )
      }
      {
        intervalType === 'custom' && customInterval()
      }
    </div>
  );
};

IntervalTimePicker.propTypes = {
  alwaysOption: PropTypes.bool,
  intervalTypeInput: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  customOption: PropTypes.bool,
  changeIntervalType: PropTypes.func,
  intervalType: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  handleChangeDate: PropTypes.func,
};
IntervalTimePicker.defaultProps = {
  alwaysOption: false,
  intervalTypeInput: false,
  customOption: false,
  changeIntervalType: () => { },
  intervalType: 'shift',
  startDate: null,
  endDate: null,
  handleChangeDate: () => { },
};

export default IntervalTimePicker;
