import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import API from '~services/endpoints';

const useLoginRefresh = (refreshInterval: number) => {
  const session = useSelector((state: any) => state.session);
  useEffect(() => {
    const refreshLogin = async () => {
      if (session.connexion.loggedin) {
        await API.loginRefresh();
      }
    };

    const intervalId = setInterval(refreshLogin, refreshInterval);

    return () => clearInterval(intervalId);
  }, [refreshInterval]);
};

export default useLoginRefresh;
