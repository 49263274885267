import React from 'react';
import PropTypes from 'prop-types';
import './ImageSelect.scss';

const ImageSelect = ({ images, image, setImage, setShow }) => (
  <div className="image-grid">
    {images
      .toReversed()
      .map((img, index) => (
        <div
          key={index}
          className={`image-box ${image === img.data ? 'selected' : ''}`}
          onClick={() => {
            setImage(image === img.data ? '' : img.data);
            setShow(false);
          }}
        >
          <img src={img.data} alt="" />
        </div>
      ))}
  </div>
);

ImageSelect.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ).isRequired,
  image: PropTypes.string.isRequired,
  setImage: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};

export default ImageSelect;
