const average = numbers => (numbers.length === 0 ? 0 : numbers.reduce((a, b) => a + b, 0) / numbers.length);

const round = (number, nbDecimals) => {
  const pow10 = 10 ** nbDecimals;
  return Math.round(number * pow10) / pow10;
};

const roundStr = (number, nbDecimals) => round(number, nbDecimals).toFixed(nbDecimals);

const floor = (number, nbDecimals) => {
  const pow10 = 10 ** nbDecimals;
  return Math.floor(number * pow10) / pow10;
};

const getNumberRange = n => (Number.isNaN(parseFloat(n)) ? 0 : 10 ** ((Math.floor(Math.abs(n))).toString().length - 1));

export {
  average,
  round,
  roundStr,
  floor,
  getNumberRange,
};
