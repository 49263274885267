import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import Tick from '~utils/Tick';
import { getDataRangeStartEnd, serverTime } from '~utils/time';
import BarChartComponent from './BarChartComponent';
import getBarChartLapse from '~utils/getBarChartLapse';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';

const BarChartEvents = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();

  const [data, setData] = useState(null);
  const [events, setEvents] = useState([]);

  const [currentShift, shifts] = useShift(tile.machineId);

  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);
  const idleTimerRef = useRef(null);

  const handleSocketEvent = socketEvent => {
    if (socketEvent.name === tile.axisY && !hasUsedArrows) {
      events.push(socketEvent);
      setEvents(events);
    }
  };

  const evaluateEvents = eventsArg => {
    const newData = [];
    const lapse = getBarChartLapse(timePeriod);

    if (eventsArg.length) {
      for (let i = timePeriod.start; i < timePeriod.end; i += lapse) {
        const eventsIn = eventsArg.filter(ev => ev.timestamp >= i && ev.timestamp <= i + lapse);
        const nb = eventsIn.length;
        if (i < serverTime()) {
          newData.push({
            x: i,
            y: nb,
          });
        }
      }
    }
    setData(newData);
  };

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const fetchEvents = async () => {
    const { start, end } = timePeriod;

    const filter = {
      name: tile.axisY,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };
    const { events: newEvents } = await API.getEvents(filter, undefined, undefined, ['timestamp']);
    setEvents(newEvents);
    evaluateEvents(newEvents);
  };

  useEffect(() => {
    fetchEvents();
  }, [timePeriod]);

  const handleOnActive = () => {
    fetchEvents();
  };

  const handleOnIdle = () => {
    fetchEvents();
    idleTimerRef.current.reset();
  };

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, tile.intervalType || 'shift');
    setEvents([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 10); // 10 secondes

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [tile, tile.intervalType, currentShift]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  useEffect(() => {
    if (socket) {
      socket.on('event', handleSocketEvent);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
      }
    };
  }, [socket]);

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <BarChartComponent
        backgroundColor={backgroundColor}
        data={data}
        height={height}
        tile={tile}
        timePeriod={timePeriod}
        canNavigateTime={goBackward => canNavigateTime(goBackward, timePeriod, shifts, tile.intervalType || 'shift')}
        navigateTime={navigateTime}
        hasUsedArrows={hasUsedArrows}
        width={width}
      />
    </>
  );
};

BarChartEvents.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  tile: PropTypes.shape({
    axisY: PropTypes.string,
    goal: PropTypes.number,
    intervalType: PropTypes.string,
    machineId: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
};

export default BarChartEvents;
