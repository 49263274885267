export default {
  GET_MACHINES_REQUEST: 'GET_MACHINES_REQUEST',
  GET_MACHINES_RESPONSE: 'GET_MACHINES_RESPONSE',
  GET_MACHINE_PARAMS_REQUEST: 'GET_MACHINE_PARAMS_REQUEST',
  GET_MACHINE_PARAMS_RESPONSE: 'GET_MACHINE_PARAMS_RESPONSE',
  ADD_MACHINE_REQUEST: 'ADD_MACHINE_REQUEST',
  ADD_MACHINE_RESPONSE: 'ADD_MACHINE_RESPONSE',
  UPDATE_MACHINE_REQUEST: 'UPDATE_MACHINE_REQUEST',
  UPDATE_MACHINE_RESPONSE: 'UPDATE_MACHINE_RESPONSE',
  PATCH_MACHINE_REQUEST: 'PATCH_MACHINE_REQUEST',
  PATCH_MACHINE_RESPONSE: 'PATCH_MACHINE_RESPONSE',
  DELETE_MACHINE_REQUEST: 'DELETE_MACHINE_REQUEST',
  DELETE_MACHINE_RESPONSE: 'DELETE_MACHINE_RESPONSE',

  ADD_STOP_CAUSE_REQUEST: 'ADD_STOP_CAUSE_REQUEST',
  ADD_STOP_CAUSE_RESPONSE: 'ADD_STOP_CAUSE_RESPONSE',
  UPDATE_STOP_CAUSE_REQUEST: 'UPDATE_STOP_CAUSE_REQUEST',
  UPDATE_STOP_CAUSE_RESPONSE: 'UPDATE_STOP_CAUSE_RESPONSE',
  DELETE_STOP_CAUSE_REQUEST: 'DELETE_STOP_CAUSE_REQUEST',
  DELETE_STOP_CAUSE_RESPONSE: 'DELETE_STOP_CAUSE_RESPONSE',
  ADD_SUB_STOP_CAUSE_REQUEST: 'ADD_SUB_STOP_CAUSE_REQUEST',
  ADD_SUB_STOP_CAUSE_RESPONSE: 'ADD_SUB_STOP_CAUSE_RESPONSE',
  UPDATE_SUB_STOP_CAUSE_REQUEST: 'UPDATE_SUB_STOP_CAUSE_REQUEST',
  UPDATE_SUB_STOP_CAUSE_RESPONSE: 'UPDATE_SUB_STOP_CAUSE_RESPONSE',
  DELETE_SUB_STOP_CAUSE_REQUEST: 'DELETE_SUB_STOP_CAUSE_REQUEST',
  DELETE_SUB_STOP_CAUSE_RESPONSE: 'DELETE_SUB_STOP_CAUSE_RESPONSE',

  ADD_PERFORMANCE_CAUSE_REQUEST: 'ADD_PERFORMANCE_CAUSE_REQUEST',
  ADD_PERFORMANCE_CAUSE_RESPONSE: 'ADD_PERFORMANCE_CAUSE_RESPONSE',
  UPDATE_PERFORMANCE_CAUSE_REQUEST: 'UPDATE_PERFORMANCE_CAUSE_REQUEST',
  UPDATE_PERFORMANCE_CAUSE_RESPONSE: 'UPDATE_PERFORMANCE_CAUSE_RESPONSE',
  DELETE_PERFORMANCE_CAUSE_REQUEST: 'DELETE_PERFORMANCE_CAUSE_REQUEST',
  DELETE_PERFORMANCE_CAUSE_RESPONSE: 'DELETE_PERFORMANCE_CAUSE_RESPONSE',
  ADD_SUB_PERFORMANCE_CAUSE_REQUEST: 'ADD_SUB_PERFORMANCE_CAUSE_REQUEST',
  ADD_SUB_PERFORMANCE_CAUSE_RESPONSE: 'ADD_SUB_PERFORMANCE_CAUSE_RESPONSE',
  UPDATE_SUB_PERFORMANCE_CAUSE_REQUEST: 'UPDATE_SUB_PERFORMANCE_CAUSE_REQUEST',
  UPDATE_SUB_PERFORMANCE_CAUSE_RESPONSE: 'UPDATE_SUB_PERFORMANCE_CAUSE_RESPONSE',

  ADD_DEFECT_CAUSE_REQUEST: 'ADD_DEFECT_CAUSE_REQUEST',
  ADD_DEFECT_CAUSE_RESPONSE: 'ADD_DEFECT_CAUSE_RESPONSE',
  UPDATE_DEFECT_CAUSE_REQUEST: 'UPDATE_DEFECT_CAUSE_REQUEST',
  UPDATE_DEFECT_CAUSE_RESPONSE: 'UPDATE_DEFECT_CAUSE_RESPONSE',
  DELETE_DEFECT_CAUSE_REQUEST: 'DELETE_DEFECT_CAUSE_REQUEST',
  DELETE_DEFECT_CAUSE_RESPONSE: 'DELETE_DEFECT_CAUSE_RESPONSE',
  ADD_SUB_DEFECT_CAUSE_REQUEST: 'ADD_SUB_DEFECT_CAUSE_REQUEST',
  ADD_SUB_DEFECT_CAUSE_RESPONSE: 'ADD_SUB_DEFECT_CAUSE_RESPONSE',
  UPDATE_SUB_DEFECT_CAUSE_REQUEST: 'UPDATE_SUB_DEFECT_CAUSE_REQUEST',
  UPDATE_SUB_DEFECT_CAUSE_RESPONSE: 'UPDATE_SUB_DEFECT_CAUSE_RESPONSE',

  ADD_KPI_REQUEST: 'ADD_KPI_REQUEST',
  ADD_KPI_RESPONSE: 'ADD_KPI_RESPONSE',
  UPDATE_KPI_REQUEST: 'UPDATE_KPI_REQUEST',
  UPDATE_KPI_RESPONSE: 'UPDATE_KPI_RESPONSE',
  DELETE_KPI_REQUEST: 'DELETE_KPI_REQUEST',
  DELETE_KPI_RESPONSE: 'DELETE_KPI_RESPONSE',

  ADD_MACHINE_SHIFT_REQUEST: 'ADD_MACHINE_SHIFT_REQUEST',
  ADD_MACHINE_SHIFT_RESPONSE: 'ADD_MACHINE_SHIFT_RESPONSE',
  UPDATE_MACHINE_SHIFT_REQUEST: 'UPDATE_MACHINE_SHIFT_REQUEST',
  UPDATE_MACHINE_SHIFT_RESPONSE: 'UPDATE_MACHINE_SHIFT_RESPONSE',
  DELETE_MACHINE_SHIFT_REQUEST: 'DELETE_MACHINE_SHIFT_REQUEST',
  DELETE_MACHINE_SHIFT_RESPONSE: 'DELETE_MACHINE_SHIFT_RESPONSE',

  SET_PARAMS_REQUEST: 'SET_PARAMS_REQUEST',
  SET_PARAMS_RESPONSE: 'SET_PARAMS_RESPONSE',
};
