import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getSocket } from '~services/socket';
import { RootState } from '~services/store';
import { evaluate } from '~utils/parser';
import WidgetContents from './WidgetContents';

const DEFAULT_BACKGROUND_COLOR = '#444444';

const Widget = ({
  onClick, h, w, x, y, widget, fetchDelay,
}) => {
  const socket = getSocket();
  const widgetRef = useRef(widget);
  const widgetValue = useSelector((state: RootState) => state.tileWidgetValue);

  useEffect(() => {
    widgetRef.current = widget;
  }, [widget]);

  const [backgroundColor, setBackgroundColor] = useState(DEFAULT_BACKGROUND_COLOR);

  const handleSocketData = socketData => {
    const websocketData = JSON.parse(socketData);

    let currentValue = widgetValue?.find(data => data.selectedObjectId === widget.id)?.value;

    if (typeof currentValue === 'string' && !Number.isNaN(parseFloat(currentValue))) {
      currentValue = Number(currentValue);
    }

    websocketData.tempsReel.currentValue = currentValue;

    let newBackgroundColor = DEFAULT_BACKGROUND_COLOR;
    if (widgetRef.current.rules) {
      const colorRules = widgetRef.current.rules.filter(rule => rule.type === 'color');
      for (const rule of colorRules) {
        if (evaluate(rule.condition, websocketData, rule.variableType)) {
          newBackgroundColor = rule.color;
          break;
        }
      }
    }
    setBackgroundColor(newBackgroundColor);
  };

  useEffect(() => {
    if (socket) {
      socket.on('data', handleSocketData);
    }

    return () => {
      socket?.removeListener('data', handleSocketData);
    };
  }, [socket]);

  const WidgetContent = WidgetContents[widget.type] || WidgetContents.empty;

  return (
    <g onClick={onClick}>
      <WidgetContent
        widget={widget}
        fetchDelay={fetchDelay}
        backgroundColor={backgroundColor}
        x={x}
        y={y}
        w={w}
        h={h}
      />
    </g>
  );
};

Widget.propTypes = {
  onClick: PropTypes.func,
  fetchDelay: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rules: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.string,
  }).isRequired,
};
Widget.defaultProps = {
  onClick: () => undefined,
};

export default Widget;
