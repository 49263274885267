import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { BackIcon, StopCauseIcon } from '~UI/RoundButton/RoundIcons';

const propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
  })).isRequired,
  backPage: PropTypes.bool.isRequired,
  onBackPage: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const TransitEventMenu = ({
  id, backPage, options, onSelect, onBackPage, onSubmit, onHide, children,
}) => {
  const { t } = useTranslation();

  const backButton = backPage && <BackIcon onClick={onBackPage} />;

  const menu = options && options.map(option => {
    let classNames = '';
    if (id === option.id) {
      classNames = 'Selected';
    } else if (id !== null) {
      classNames = 'Unselected';
    }

    return (
      <StopCauseIcon
        key={option.id}
        className={classNames}
        option={option}
        onClick={() => onSelect(option.id)}
      />
    );
  });

  const form = (
    <div className="StopSelectionForm">
      <br />
      <form id="Stop_Selection_Form">
        {children}
      </form>
      <div className="btn-group fullwidth">
        <button type="button" className="submitButton red" onClick={onHide}>
          {t('cancel')}
        </button>
        <button type="submit" className="submitButton" disabled={id === null} onClick={onSubmit}>
          {t('edit')}
        </button>
      </div>
    </div>
  );

  return (
    <div className="MachineEventMenu__Options">
      {backButton}
      {menu}
      {form}
    </div>
  );
};

TransitEventMenu.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    language: state.views.language,
  };
}

export default connect(mapStateToProps)(TransitEventMenu);
