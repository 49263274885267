enum Types {
  GET_IMAGES_REQUEST = 'GET_IMAGES_REQUEST',
  GET_IMAGES_RESPONSE = 'GET_IMAGES_RESPONSE',
  ADD_IMAGE_REQUEST = 'ADD_IMAGE_REQUEST',
  ADD_IMAGE_RESPONSE = 'ADD_IMAGE_RESPONSE',
  DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST',
  DELETE_IMAGE_RESPONSE = 'DELETE_IMAGE_RESPONSE',
}

export default Types;
