import { Dispatch } from 'redux';
import { fetchOnceOperation } from '../fetchOperation';
import {
  getFolders as APIgetFolders,
  addFolder as APIcreateFolder,
  updateFolder as APIupdateFolder,
  addSubfolder as APIaddSubfolder,
  updateSubfolder as APIupdateSubfolder,
  deleteSubfolder as APIdeleteSubfolder,
  deleteFolder as APIdeleteFolder,
} from './endpoints';
import {
  addFolderRequest,
  addFolderResponse,
  getFoldersRequest,
  getFoldersResponse,
  updateFolderRequest,
  updateFolderResponse,
  addSubfolderRequest,
  addSubfolderResponse,
  updateSubfolderRequest,
  updateSubfolderResponse,
  deleteFolderRequest,
  deleteFolderResponse,
  deleteSubfolderRequest,
  deleteSubfolderResponse,
  FolderAction,
} from './actions';
import { Folder } from './types';

type FolderDispatch = Dispatch<FolderAction>;

export const fetchFolders = () => fetchOnceOperation(
  getFoldersRequest,
  getFoldersResponse,
  APIgetFolders,
  state => state.folders,
);

export const addFolder = (folder: Folder) => (dispatch: FolderDispatch) => {
  dispatch(addFolderRequest({ folder }));
  return APIcreateFolder(folder)
    .then(res => dispatch(addFolderResponse(res)));
};

export const updateFolder = (id: string, folder: Folder) => (dispatch: FolderDispatch) => {
  dispatch(updateFolderRequest({ id, folder }));
  return APIupdateFolder(id, folder)
    .then(res => {
      dispatch(updateFolderResponse(res));
    });
};

export const addSubfolder = (id: string, subfolder: Folder) => (dispatch: FolderDispatch) => {
  dispatch(addSubfolderRequest({ id, subfolder }));
  return APIaddSubfolder(id, subfolder)
    .then(res => {
      dispatch(addSubfolderResponse(res));
    });
};

export const updateSubfolder = (folderId: string, id: string, subfolder: Folder) => (dispatch: FolderDispatch) => {
  dispatch(updateSubfolderRequest({ folderId, id, subfolder }));
  return APIupdateSubfolder(folderId, id, subfolder)
    .then(res => dispatch(updateSubfolderResponse(res)));
};

export const deleteSubfolder = (id: string, folderId: string) => (dispatch: FolderDispatch) => {
  dispatch(deleteSubfolderRequest({ id, folderId }));
  return APIdeleteSubfolder(id, folderId)
    .then(res => dispatch(deleteSubfolderResponse({
      id: res.folderId,
    })));
};

export const deleteFolder = (id: string) => (dispatch: FolderDispatch) => {
  dispatch(deleteFolderRequest({ id }));
  return APIdeleteFolder(id)
    .then(res => dispatch(deleteFolderResponse({
      id: res.folderId,
    })));
};

export default {
  fetchFolders,
  addFolder,
  updateFolder,
  addSubfolder,
  updateSubfolder,
  deleteFolder,
  deleteSubfolder,
};
