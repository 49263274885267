/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services';
import { ResourcesHandler } from '~UI';
import { getFormData } from '~utils';
import { showError } from '~utils/toast';

import { MachineParamsPopup } from './MachineParamsPopup';

const MachineOperationPopup = ({
  machineId, machineParams, show, onHide, onSubmitTrigger, params, eventModifyTrigger,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const operations = useSelector(state => state.operations.operations).filter(
    element => element.machines.includes(machineId),
  );
  const fetchOperations = useDispatch(reduxOperations.operations.fetchOperations());

  function handleSubmit(e) {
    if (e) { e.preventDefault(); }

    const formData = getFormData('Operation__Popup__Form');
    const newOperation = operations.find(o => o.name === formData.operation);
    if (eventModifyTrigger) {
      eventModifyTrigger(newOperation.name);
    } else if (newOperation) {
      const currentOperation = operations.find(o => o.name === machineParams.operation);
      if (currentOperation) {
        dispatch(reduxOperations.operations.updateOperation(
          currentOperation.id,
          { ...currentOperation, isCompleted: true },
        ));
      }

      dispatch(reduxOperations.machines.setParams(machineId, { ...machineParams, ...formData }));

      if (onSubmitTrigger) {
        const optionalTriggerParams = { ...params, ...formData };
        dispatch(reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
          ...optionalTriggerParams,
          machineId,
        }));
      }
    } else {
      showError(t('operationNotExist'));
    }
    onHide();
  }

  useEffect(() => {
    if (show) {
      dispatch(reduxOperations.operations.forceFetchOperations());
    }
  }, [show]);

  const content = () => (
    <MachineParamsPopup
      parameter={machineParams.operation}
      onHide={onHide}
      show={show}
      paramType="Operation"
      onSubmit={handleSubmit}
      options={operations}
    />
  );

  return (
    <ResourcesHandler
      resources={[operations]}
      resourceFetchers={[fetchOperations]}
      getContents={content}
    />
  );
};

MachineOperationPopup.propTypes = {
  machineId: PropTypes.string.isRequired,
  machineParams: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmitTrigger: PropTypes.string,
  params: PropTypes.object,
  eventModifyTrigger: PropTypes.func,
};
MachineOperationPopup.defaultProps = {
  onSubmitTrigger: '',
  params: {},
  eventModifyTrigger: null,
};

export { MachineOperationPopup };
