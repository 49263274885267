import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import omit from 'lodash.omit';

import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AddIcon } from '~UI/RoundButton/RoundIcons';
import DraggableCauseIconContainer from '~components/UI/RoundButton/DraggableCauseIconContainer';
import { SubmitButton } from '~UI';
import { reduxOperations } from '~services';
import { showError, showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';

import StopCauseEdition from './StopCauseEdition';
import StopCauseForm from './StopCauseForm';

const StopCausesForm = ({ machineId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const machines = useSelector(state => state.machines);

  const [selectedStopCause, setSelectedStopCause] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [stopCauses, setStopCauses] = useState([]);

  useEffect(() => {
    const machine = machines.find(mach => mach.id === machineId);
    setStopCauses(machine?.stopCauses || []);
  }, [machineId, machines]);

  const updateStopCauses = newStopcauses => {
    setStopCauses(newStopcauses);
    dispatch(reduxOperations.machines.patchMachine(machineId, { stopCauses: newStopcauses }));
  };

  const selectPreset = () => {
    const machine = machines.find(mach => mach.id === selectedPreset);

    if (machine && machine.stopCauses) {
      const newStopcauses = machine.stopCauses.map(topCause => omit({
        ...topCause,
        subMenu: topCause.subMenu.map(subCause => omit(subCause, 'id')),
      }, 'id'));
      dispatch(reduxOperations.machines.patchMachine(machineId, { stopCauses: newStopcauses }))
        .then(() => {
          showSuccess(t('operationSucceeded'));
        })
        .catch(error => showError(error.status));
    } else {
      showError(t('machineNotFound'));
    }
  };

  const presetSelection = () => (
    <>
      <span className="presetSelectionText">
        {`${t('createFromTemplate')}: `}
      </span>
      <Select
        style={{ width: '150px', display: 'inline-block' }}
        value={selectedPreset || '-'}
        onChange={setSelectedPreset}
        options={[
          { label: '-', value: '-' },
          ...sortArray('alphabetically', machines.filter(m => m.id !== machineId), 'name')
            .map(machine => ({ label: machine.name, value: machine.id })),
        ]}
      />
      &nbsp;
      <SubmitButton
        onClick={() => selectPreset()}
        label={t('import')}
        askConfirmation
      />
    </>
  );

  const machine = machines.find(m => m.id === machineId);

  if (selectedStopCause && selectedStopCause.name) {
    const currentStopCause = machine.stopCauses.find(sc => sc.id === selectedStopCause.id);
    return (
      <StopCauseEdition
        stopcause={currentStopCause}
        goBackForm={() => setSelectedStopCause(null)}
        machineId={machineId}
      />
    );
  }

  if (selectedStopCause) {
    return (
      <StopCauseForm
        stopcause={selectedStopCause}
        isInSubMenu={false}
        goBackForm={() => setSelectedStopCause(null)}
        machineId={machineId}
      />
    );
  }

  return (
    <div className="StopCausesForm">
      {presetSelection()}
      <div className="StopCausesIconsContainer">
        <DndProvider backend={HTML5Backend}>
          <DraggableCauseIconContainer
            causesArray={stopCauses}
            onClick={setSelectedStopCause}
            updateCauses={updateStopCauses}
          >
            <AddIcon
              onClick={() => setSelectedStopCause({})}
            />
          </DraggableCauseIconContainer>
        </DndProvider>
      </div>
    </div>
  );
};

StopCausesForm.propTypes = {
  machineId: PropTypes.string.isRequired,
};

export default StopCausesForm;
