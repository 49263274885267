import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { CancelButton, DefaultModal, SubmitButton } from '~UI/index';
import { getFormData } from '~utils/index';
import { sortArray } from '~utils/sort';
import { showError, showSuccess } from '~utils/toast';
import './MachinePage.scss';

const MachineCreationForm = ({ onHide, show }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const streams = useSelector((state: RootState) => state.streams);
  const streamOptions = sortArray('alphabetically', streams, 'name').map(s => ({ label: s.name, value: s.id }));

  const [language, setLanguage] = useState('fr');

  function addMachine(data) {
    reduxOperations.machines.addMachine(data)(dispatch)
      .then(() => {
        onHide();
        showSuccess(t('operationSucceeded'));
      })
      .catch(error => showError(error.status));
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = getFormData('Machine__form');
    addMachine({
      name: formData.name,
      language: formData.language,
      streamId: formData.streamId,
      skuNumbers: [],
    });
  }

  const handleChangeLanguage = buttonTypeArg => {
    setLanguage(buttonTypeArg);
  };

  const buttons = [
    { label: t('EN'), value: 'en' },
    { label: t('FR'), value: 'fr' },
    { label: t('ES'), value: 'es' },
  ];

  return (
    <DefaultModal
      show={show}
      title={t('createMachine')}
      closePopup={onHide}
      children={(
        <form
          id="Machine__form"
          onSubmit={handleSubmit}
        >
          <div className="inputTitle">{t('name')}</div>
          <input
            name="name"
            className="fullwidth"
            type="text"
            placeholder="Name"
          />

          <div className="inputTitle">{t('stream')}</div>
          <Select
            style={{ width: '100%' }}
            defaultValue=""
            name="streamId"
            options={streamOptions}
            placeholder={t('select')}
          />
          <br />
          <div className="inputTitle">{t('language')}</div>
          <ButtonGroup
            name="language"
            buttons={buttons}
            onChange={handleChangeLanguage}
            value={language}
          />
          <div className="buttonsHolder" style={{ textAlign: 'right' }}>
            <CancelButton onClick={onHide} />
            <SubmitButton label={t('create')} />
          </div>
        </form>
      )}
    />
  );
};

MachineCreationForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default MachineCreationForm;
