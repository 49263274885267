import types from '../types';

const addMachineShiftRequest = data => ({
  type: types.ADD_MACHINE_SHIFT_REQUEST,
  payload: data,
});
const addMachineShiftResponse = (data, request = null) => ({
  type: types.ADD_MACHINE_SHIFT_RESPONSE,
  payload: data,
  request,
});

const updateMachineShiftRequest = data => ({
  type: types.UPDATE_MACHINE_SHIFT_REQUEST,
  payload: data,
});
const updateMachineShiftResponse = (data, request = null) => ({
  type: types.UPDATE_MACHINE_SHIFT_RESPONSE,
  payload: data,
  request,
});

const deleteMachineShiftRequest = data => ({
  type: types.DELETE_MACHINE_SHIFT_REQUEST,
  payload: data,
});
const deleteMachineShiftResponse = (data, request = null) => ({
  type: types.DELETE_MACHINE_SHIFT_RESPONSE,
  payload: data,
  request,
});

export {
  addMachineShiftRequest,
  addMachineShiftResponse,
  updateMachineShiftRequest,
  updateMachineShiftResponse,
  deleteMachineShiftRequest,
  deleteMachineShiftResponse,
};
