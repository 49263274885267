import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getSocket } from '~services/socket';
import { evaluate } from '~utils/parser';
import JsxWidget from '../BaseWidgets/JsxWidget';
import { IframeMedia } from './IframeMedia';
import { ImageMedia } from './ImageMedia';

class MediaWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSocketInitialized: false,
      displayURL: props.widget.url,
    };
  }

  componentDidMount() {
    const socket = getSocket();
    const { isSocketInitialized } = this.state;
    if (socket && !isSocketInitialized) {
      socket.on('data', this.handleSocketData);
      this.setState({
        isSocketInitialized: true,
      });
    }
  }

  componentDidUpdate() {
    const socket = getSocket();
    const { isSocketInitialized } = this.state;
    if (socket && !isSocketInitialized) {
      socket.on('data', this.handleSocketData);
      this.setState({
        isSocketInitialized: true,
      });
    }
  }

  componentWillUnmount() {
    const socket = getSocket();
    if (socket) {
      socket.removeListener('data', this.handleSocketData);
    }
  }

  handleSocketData = socketData => {
    const { widget } = this.props;
    const websocketData = JSON.parse(socketData);
    let displayURL = widget.url;
    if (widget.rules) {
      const mediaRules = widget.rules.filter(rule => rule.type === 'media');
      for (const mediaRule of mediaRules) {
        if (evaluate(mediaRule.condition, websocketData, mediaRule.variableType)) {
          displayURL = mediaRule.media;
          break;
        }
      }
    }
    this.setState({ displayURL });
  };

  render() {
    const { displayURL } = this.state;
    const {
      widget, backgroundColor, x, y, w, h,
    } = this.props;

    return (
      <JsxWidget
        dimension={{ x, y, w, h }}
        backgroundColor={backgroundColor}
        shape={widget.format.shape}
      >
        {displayURL && widget.mediaType === 'image' && (
          <ImageMedia
            displayURL={displayURL}
            widget={widget}
          />
        )}
        {displayURL && widget.mediaType === 'iframe' && (
          <IframeMedia
            displayURL={displayURL}
            widget={widget}
          />
        )}
      </JsxWidget>
    );
  }
}

MediaWidget.propTypes = {
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    type: PropTypes.string,
    format: PropTypes.shape({
      shape: PropTypes.string,
    }),
    mediaType: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({})),
    url: PropTypes.string,
    refreshRateValue: PropTypes.number,
    refreshRateToggle: PropTypes.bool,
    scale: PropTypes.number,
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default MediaWidget;
