import React from 'react';
import PropTypes from 'prop-types';

import { FormGauge } from '~components/forms';

const FormTileGauge = ({ selectedTile, tileType }) => (
  <FormGauge
    selectedObject={selectedTile}
    selectedType={tileType}
    isTile
  />
);

FormTileGauge.propTypes = {
  selectedTile: PropTypes.object,
  tileType: PropTypes.string.isRequired,
};
FormTileGauge.defaultProps = {
  selectedTile: {},
};

export default FormTileGauge;
