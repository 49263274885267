import { toast } from 'react-toastify';
import HttpStatus from 'http-status-codes';
import i18n from 'i18next';

const showSuccess = (msg, autoClose = 2500) => toast.success(msg, {
  autoClose,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: 'customToast',
  toastId: 'success',
});

const showUpload = (promise, pendingMsg, successMsg) => {
  toast.promise(
    promise,
    {
      pending: pendingMsg,
      success: successMsg,
      error: {
        render(data:any) {
          return data?.data?.status || 'Error uploading the image';
        },
      },
    },
    {
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: 'customToast',
      toastId: 'upload',
    },
  );
  return promise;
};

const showError = (msg, autoClose = 2500) => toast.error(msg, {
  autoClose,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  className: 'customToast',
  toastId: 'error',
});

const showHttpError = (error, autoClose = 2500) => {
  let msg = `${error.code} ${error.status}`;
  if (error.code === HttpStatus.CONFLICT) {
    msg = i18n.t('errorHttpConflict');
  }
  if (error.code === HttpStatus.BAD_REQUEST) {
    msg = i18n.t('errorHttpBadRequest');
  }
  toast.error(msg, {
    autoClose,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: 'customToast',
    toastId: 'error',
  });
};

export {
  showSuccess,
  showError,
  showHttpError,
  showUpload,
};
