import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, ColorPicker } from '@intelligenceindustrielle/react-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultModal, SubmitButton } from '~UI/index';
import { getFormData } from '~utils/getFormData';
import reduxOperations from '~services/reduxOperations';
import { showError, showSuccess } from '~utils/toast';
import { RootState } from '~services/store';

import './FolderPopupForm.scss';

const findFolder = (folders, id, parentId = null) => {
  for (let i = 0; i < folders?.length; i += 1) {
    if (folders[i]?.id === id) {
      return { folder: folders[i], parentId };
    }

    if (folders[i]?.subfolders) {
      const found = findFolder(folders[i].subfolders, id, folders[i]?.id);
      if (found) {
        return found;
      }
    }
  }
};

const FolderPopupForm = ({
  parentId, modifiedItemId, show, onHide,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const folders = useSelector((state: RootState) => state.folders);
  const result = findFolder(folders, modifiedItemId) || { folder: null, parentId: null };
  const { folder, parentId: parentFolderId } = result;

  const [folderName, setFolderName] = useState(folder?.name || '');
  const [folderColor, setFolderColor] = useState(folder?.color || 'white');

  useEffect(() => {
    if (folder) {
      setFolderName(folder.name);
      setFolderColor(folder.color);
    } else {
      setFolderName('');
      setFolderColor('white');
    }
  }, [folder, modifiedItemId, folders]);

  const handleSubmit = e => {
    e.preventDefault();
    const formData: any = getFormData('folderForm');

    if (!formData.name) {
      showError(t('missingName'));
      return;
    }

    if (!modifiedItemId && !parentId) {
      reduxOperations.folders.addFolder(formData)(dispatch)
        .then(() => showSuccess(t('showSuccessCreated')))
        .then(() => onHide())
        .then(() => setFolderName(''))
        .catch(() => showError(t('folderNameExistsError')));
    } else if (parentId) {
      reduxOperations.folders.addSubfolder(parentId, formData)(dispatch)
        .then(() => showSuccess(t('showSuccessCreated')))
        .then(() => onHide())
        .then(() => setFolderName(''))
        .catch(() => showError(t('folderNameExistsError')));
    } else if (parentFolderId) {
      reduxOperations.folders.updateSubfolder(parentFolderId, modifiedItemId, formData)(dispatch)
        .then(() => showSuccess(t('showSuccessUpdated')))
        .then(() => onHide())
        .catch(() => showError(t('folderNameExistsError')));
    } else {
      reduxOperations.folders.updateFolder(modifiedItemId, formData)(dispatch)
        .then(() => showSuccess(t('showSuccessUpdated')))
        .then(() => onHide())
        .catch(() => showError(t('folderNameExistsError')));
    }
  };

  const getContents = () => (
    <div className="FolderPopupForm">
      <div className="inputTitle">{t('name')}</div>
      <form
        id="folderForm"
        onSubmit={handleSubmit}
      >
        <Input
          name="name"
          value={folderName}
          onChange={setFolderName}
          placeholder={t('valueInput')}
        />
        <div className="inputTitle">{t('color')}</div>
        <ColorPicker
          name="color"
          defaultValue={folderColor}
          onChange={setFolderColor}
        />
        <SubmitButton className="buttonsHolder" label={t('submit')} />
      </form>
    </div>
  );

  return (
    <DefaultModal
      closePopup={onHide}
      title={modifiedItemId ? t('editFolder') : t('createNewFolder')}
      show={show}
      children={getContents()}
    />
  );
};

FolderPopupForm.propTypes = {
  parentId: PropTypes.string,
  modifiedItemId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

FolderPopupForm.defaultProps = {
  parentId: '',
  modifiedItemId: '',
};

export default FolderPopupForm;
