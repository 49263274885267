const getTitle = (tile, language) => {
  if (!(tile.title || tile.titleEN || tile.titleFR || tile.titleES)) {
    return '';
  }
  switch (language) {
    case 'en':
      return tile.titleEN || tile.titleFR || tile.titleES || tile.title || '';
    case 'fr':
      return tile.titleFR || tile.titleEN || tile.titleES || tile.title || '';
    case 'es':
      return tile.titleES || tile.titleFR || tile.titleEN || tile.title || '';
    default:
      return tile.titleEN || tile.titleFR || tile.titleES || tile.title || '';
  }
};

export { getTitle };
