import React from 'react';
import { DefaultModal } from '~UI/index';

interface InfoPopupProps {
  showInfoPopup: boolean;
  handleClosePopup: () => void;
  mediaURL: string;
}

const InfoPopup: React.FC<InfoPopupProps> = ({ showInfoPopup, handleClosePopup, mediaURL }) => {
  const isImage = /\.(jpeg|jpg|gif|png|webp)$/.test(mediaURL);
  return (
    <DefaultModal
      show={showInfoPopup}
      title="Info"
      closePopup={handleClosePopup}
      fullSize
    >
      <div style={{ height: '80vh' }}>
        {isImage ? (
          <div className="framed flex V">
            <img
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain',
              }}
              src={mediaURL}
              alt="Failed to load"
            />
          </div>
        ) : (
          <div className="framed flex V">
            <iframe
              style={{
                border: 0,
                width: '100%',
                height: '100%',
              }}
              title="iframeTile"
              src={mediaURL}
            >
              <p>Your browser does not support iframes</p>
            </iframe>
          </div>
        )}
      </div>
    </DefaultModal>
  );
};

export default InfoPopup;
