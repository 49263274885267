import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const DeleteTransparentButton = ({ content, ...props }) => {
  const { t } = useTranslation();
  return (
    <button type="button" className="deleteIcon" {...props}>
      {content || t('cancel')}
    </button>
  );
};

DeleteTransparentButton.propTypes = {
  content: PropTypes.elementType.isRequired,
};

export default DeleteTransparentButton;
