import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI';

import TopviewSelectionGrid from './TopviewSelectionGrid';

const TopviewSelectionPage = () => {
  const { t } = useTranslation();
  const tags = useSelector(state => state.tags.tags);
  const topviews = useSelector(state => state.topviews.topviews);
  const images = useSelector(state => state.images.images);

  const dispatch = useDispatch();

  const getContents = () => (
    <PageTemplate
      footer
      pageTemplateClass="flex V justifyStart"
      sidebar
      title={t('topviews')}
    >
      <TopviewSelectionGrid />
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[tags, images, topviews]}
      resourceFetchers={[
        () => dispatch(reduxOperations.tags.fetchTags()),
        () => dispatch(reduxOperations.images.fetchImages()),
        () => dispatch(reduxOperations.topviews.fetchTopviews()),
      ]}
      getContents={getContents}
    />
  );
};

export { TopviewSelectionPage };
