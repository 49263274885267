export default {
  GET_SHIFTS_REQUEST: 'GET_SHIFTS_REQUEST',
  GET_SHIFTS_RESPONSE: 'GET_SHIFTS_RESPONSE',
  ADD_SHIFTS_REQUEST: 'ADD_SHIFTS_REQUEST',
  ADD_SHIFTS_RESPONSE: 'ADD_SHIFTS_RESPONSE',
  UPDATE_SHIFTS_REQUEST: 'UPDATE_SHIFTS_REQUEST',
  UPDATE_SHIFTS_RESPONSE: 'UPDATE_SHIFTS_RESPONSE',
  DELETE_SHIFTS_REQUEST: 'DELETE_SHIFTS_REQUEST',
  DELETE_SHIFTS_RESPONSE: 'DELETE_SHIFTS_RESPONSE',
};
