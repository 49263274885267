const variableRegex = new RegExp(`(?:${'\\$\\{[A-Za-z0-9_-]+\\}'}\\w*)`, 'g');

function getVariablesDependencies(expression) {
  const dependencies = new Set();
  let match = variableRegex.exec(expression);
  while (match) {
    dependencies.add(match[0].substr(2).slice(0, -1));
    match = variableRegex.exec(expression);
  }
  return Array.from(dependencies);
}

export {
  getVariablesDependencies,
};
