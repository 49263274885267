import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '~UI/index';

import MachineParamChangeTriggerForm from './MachineParamChangeTriggerForm';

const MachineParamTriggerForm = ({ trigger, selectTriggerType }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const triggerType = 'MachineParamChangeTrigger';
    selectTriggerType(triggerType);
  }, []);

  return (
    <div className="MachineParamTrigger">
      <div style={{ marginBottom: '7px', marginTop: '7px' }}>
        <FontAwesome icon="info-circle" />
        &nbsp;&nbsp;
        {t('machineParamChangeDefinition')}
      </div>

      <MachineParamChangeTriggerForm trigger={trigger} />
    </div>
  );
};

MachineParamTriggerForm.propTypes = {
  trigger: PropTypes.object.isRequired,
  selectTriggerType: PropTypes.func.isRequired,
};

export default MachineParamTriggerForm;
