import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import CardActions from '../CardActions';

interface BasicCardProps {
  card: {
    id: string;
    name: string;
  };
  title: string;
  text: string;
  icon: React.ReactNode;
  duplicateAction?: (card: { id: string, name: string }) => void;
  editModal?: {
    Component: object;
  };
  deleteModal?: {
    Component: object;
  };
  onClick?: () => void;
  style?: React.CSSProperties;
}

function BasicCard({
  card,
  title,
  text,
  icon,
  duplicateAction,
  editModal,
  deleteModal,
  onClick,
  style,
}: BasicCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const [dynamicHeight, setDynamicHeight] = useState<number | null>(null);

  const updateDimensions = () => {
    const newDynamicHeight = cardRef.current ? cardRef.current.clientWidth * 0.75 : null;
    setDynamicHeight(newDynamicHeight);
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <div
      className="cardContainer basicCardContainer"
      ref={cardRef}
      style={dynamicHeight ? { height: dynamicHeight } : {}}
    >
      <div
        className={`basicCardContent ${text ? '' : 'noText'}`}
        onClick={onClick}
        style={onClick && { cursor: 'pointer', ...style }}
      >
        <div className="basicCardIcon">{icon}</div>
        <span className="basicCardTitle">{title}</span>
        <span className="basicCardText">{text}</span>
      </div>
      {(!!duplicateAction || !!editModal || !!deleteModal) && (
        <CardActions
          card={card}
          duplicateAction={duplicateAction}
          editModal={editModal}
          deleteModal={deleteModal}
        />
      )}
    </div>
  );
}

const ModalPropTypes = PropTypes.shape({
  Component: PropTypes.elementType,
  props: PropTypes.object,
});

BasicCard.propTypes = {
  card: PropTypes.object.isRequired,
  icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  duplicateAction: PropTypes.func,
  editModal: ModalPropTypes,
  deleteModal: ModalPropTypes,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

BasicCard.defaultProps = {
  deleteModal: undefined,
  duplicateAction: undefined,
  editModal: undefined,
  onClick: undefined,
  style: {},
};

export default BasicCard;
