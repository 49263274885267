import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesome, ModalHandler } from '~UI/index';
import { useOnClickOutside } from '~utils/hooks';
import FavoritesHandler from '~components/UI/Wrappers/FavoritesHandler';
import { stopPropagation } from '~utils/index';
import { isOutOfViewport } from './utils';
import './cardActions.scss';

interface CardActionsProps {
  card: {
    id: string;
    name: string;
  };
  cardType?: string;
  duplicateAction?: (card: { id: string; name: string }) => void;
  editModal?: {
    Component: object;
  };
  editModalTitle?: string;
  editIcon?: string;
  deleteModal?: {
    Component: object;
  };
  supportFavorites?: boolean;
  optionalAction?: {
    optText: string;
    optIcon: string;
    optModal?: {
      Component: object;
    };
    optOnClick?: (card: { id: string; name: string }) => void;
  };
  configureLink?: string;
}

const CardActions = ({
  card,
  cardType,
  duplicateAction,
  editModal,
  editModalTitle,
  editIcon,
  deleteModal,
  supportFavorites,
  optionalAction,
  configureLink,
}: CardActionsProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setShowDropdown(false));

  useEffect(() => {
    if (dropdownRef.current) {
      const isOutOfViewportResults = isOutOfViewport(dropdownRef.current);
      if (isOutOfViewportResults?.any) {
        dropdownRef.current.style.bottom = '0';
        dropdownRef.current.style.top = 'unset';
        dropdownRef.current.style.marginBottom = '60px';
      } else {
        dropdownRef.current.style.bottom = 'unset';
        dropdownRef.current.style.top = '0';
        dropdownRef.current.style.marginBottom = '0';
      }
    }
  }, [showDropdown]);

  const editAction = props => (
    <div className="cardDropdownItem" {...props}>
      <span className="cardDropdownItemText">{editModalTitle || t('properties')}</span>
      <FontAwesome icon={editIcon} />
    </div>
  );

  const deleteAction = props => (
    <div className="cardDropdownItem" {...props}>
      <span className="cardDropdownItemText">{t('delete')}</span>
      <FontAwesome icon="trash" />
    </div>
  );

  const optAction = props => optionalAction && (
    <div className="cardDropdownItem" {...props}>
      <span className="cardDropdownItemText">{optionalAction.optText}</span>
      <FontAwesome icon={optionalAction.optIcon} />
    </div>
  );

  return (
    <div className="cardActions" ref={ref}>
      <FontAwesome
        icon="ellipsis-h"
        onClick={e => {
          setShowDropdown(!showDropdown);
          stopPropagation(e);
        }}
      />
      <div
        className="cardDropdown"
        ref={dropdownRef}
        style={{
          ...(!showDropdown && { display: 'none' }),
        }}
      >
        {supportFavorites && (
          <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
            <FavoritesHandler
              favoriteType={(cardType ?? '')}
              favoriteId={card.id}
              isConfigurationEnabled
            />
          </div>
        )}
        {configureLink && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
              <div className="cardDropdownItem" onClick={() => history.push(configureLink)}>
                <span className="cardDropdownItemText">{t('configure')}</span>
                <FontAwesome icon="gear" />
              </div>
            </div>
          </>
        )}
        {editModal && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
              <ModalHandler
                Trigger={{
                  Component: editAction,
                  props: {},
                }}
                Modal={editModal}
              />
            </div>
          </>
        )}
        {duplicateAction && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
              <div className="cardDropdownItem" onClick={() => duplicateAction(card)}>
                <span className="cardDropdownItemText">{t('duplicate')}</span>
                <FontAwesome icon="clone" />
              </div>
            </div>
          </>
        )}
        {optionalAction && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
              {optionalAction.optModal ? (
                <ModalHandler
                  Trigger={{
                    Component: optAction,
                    props: {},
                  }}
                  Modal={optionalAction.optModal}
                />
              ) : (
                <div className="cardDropdownItem" onClick={() => optionalAction?.optOnClick && optionalAction.optOnClick(card)}>
                  <span className="cardDropdownItemText">{optionalAction.optText}</span>
                  <FontAwesome icon={optionalAction.optIcon} />
                </div>
              )}
            </div>
          </>
        )}
        {deleteModal && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer deleteAction" onClick={() => setShowDropdown(false)}>
              <ModalHandler
                Trigger={{
                  Component: deleteAction,
                  props: {},
                }}
                Modal={deleteModal}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const ModalPropTypes = PropTypes.shape({
  Component: PropTypes.elementType.isRequired,
  props: PropTypes.object.isRequired,
});

CardActions.propTypes = {
  card: PropTypes.object.isRequired,
  cardType: PropTypes.string,
  duplicateAction: PropTypes.func.isRequired,
  editModal: ModalPropTypes,
  editModalTitle: PropTypes.string,
  editIcon: PropTypes.string,
  deleteModal: ModalPropTypes,
  supportFavorites: PropTypes.bool,
  optionalAction: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
  configureLink: PropTypes.string,
};

CardActions.defaultProps = {
  cardType: '',
  deleteModal: undefined,
  editModal: undefined,
  editModalTitle: undefined,
  editIcon: 'edit',
  optionalAction: false,
  supportFavorites: false,
  configureLink: undefined,
};

export default CardActions;
