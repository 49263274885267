const PermissionType = {
  NONE: 'NONE',
  ADMIN: 'ADMIN',
  // alphabetical order
  ACTIONS_EDIT: 'ACTIONS_EDIT',
  DASHBOARDS_EDIT: 'DASHBOARDS_EDIT',
  EVENTS_PAGE_EDIT: 'EVENTS_PAGE_EDIT',
  EVENTS_PAGE_READ: 'EVENTS_PAGE_READ',
  FOLDERS_EDIT: 'FOLDERS_EDIT',
  MACHINES_EDIT: 'MACHINES_EDIT',
  OPERATIONS_EDIT: 'OPERATIONS_EDIT',
  REPORTS_EDIT: 'REPORTS_EDIT',
  REPORTS_READ: 'REPORTS_READ',
  SHIFTS_EDIT: 'SHIFTS_EDIT',
  STOPWATCHES_EDIT: 'STOPWATCHES_EDIT',
  STREAMS_READ: 'STREAMS_READ',
  STREAMS_EDIT: 'STREAMS_EDIT',
  TAGS_EDIT: 'TAGS_EDIT',
  TOPVIEWS_EDIT: 'TOPVIEWS_EDIT',
  TRIGGERS_EDIT: 'TRIGGERS_EDIT',
  USERS_EDIT: 'USERS_EDIT',
  USERS_READ: 'USERS_READ',
  VARIABLES_EDIT: 'VARIABLES_EDIT',
  LOGS_READ: 'LOGS_READ',
  LOGS_EDIT: 'LOGS_EDIT',
};

Object.freeze(PermissionType);

export default PermissionType;
