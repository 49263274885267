import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import { FontAwesome, TextInput } from '~UI/index';
import { idToReadable, readableToId } from '~utils/parser';
import { triggerTypes } from '~utils/types';

import {
  getStreamsToGroupedOptions,
  getVariablesToGroupedOptions,
} from './utils';
import { RootState } from '~services/store';

function getTriggerValueType(triggerValue) {
  if (typeof triggerValue === 'boolean') {
    return 'boolean';
  } if (typeof triggerValue === 'string' && triggerValue.length > 0) {
    return 'string';
  } if (!Number.isNaN(+triggerValue) && triggerValue !== null && typeof triggerValue !== 'string') { // isNaN("") and isNaN(null) returns false
    return 'number';
  }
  return 'number';
}

function getTriggerEmptyValue(triggerValue) {
  if (!triggerValue) {
    if (triggerValue === false || triggerValue === 0) {
      return false;
    }
    return true;
  }
  return false;
}

const FormValueTrigger = ({ selectedObject }) => {
  const { t } = useTranslation();

  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);

  const [triggerType, setTriggerType] = useState(selectedObject.triggerType || triggerTypes.VALUE_CHANGE);
  const [triggerValueType, setTriggerValueType] = useState(getTriggerValueType(selectedObject.triggerValue));
  const [triggerValue, setTriggerValue] = useState(selectedObject.triggerValue);
  const [triggerEmptyValue, setTriggerEmptyValue] = useState(getTriggerEmptyValue(selectedObject.triggerValue));

  const valueTriggerOptions = [
    { label: t('valueChange'), value: triggerTypes.VALUE_CHANGE },
    { label: t('valueSet'), value: triggerTypes.VALUE_SET },
  ];
  const variableOptions = [getVariablesToGroupedOptions(variables), ...getStreamsToGroupedOptions(streams)];

  function handleEmptyValueRadio(isEmpty) {
    setTriggerEmptyValue(isEmpty);
    if (triggerValueType === 'boolean') {
      setTriggerValue('true');
    } else if (triggerValueType === 'string') {
      setTriggerValue('');
    } else {
      setTriggerValue(0);
    }
  }

  function handleChangeTriggerValueType(e) {
    if (e === 'boolean') {
      setTriggerValue('true');
    } else if (e === 'string') {
      setTriggerValue('');
    } else {
      setTriggerValue(0);
    }
    setTriggerValueType(e);
  }

  function getValueInput() {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const inputsAndVariables = [...inputProperties, ...variables].map(x => x.variable);
    inputsAndVariables.splice(0, 0, 'NOW');

    switch (triggerValueType) {
      case 'number':
        return (
          <input
            disabled={triggerEmptyValue}
            style={{ marginLeft: '5px' }}
            type="number"
            step="0.01"
            value={triggerValue}
            onChange={e => setTriggerValue(e.target.value)}
          />
        );
      case 'boolean':
        return (
          <Select
            style={triggerEmptyValue ? { display: 'inline-block', width: '10%', opacity: '0.6' }
              : { display: 'inline-block', width: '10%' }}
            disabled={triggerEmptyValue}
            value={triggerValue.toString()}
            onChange={setTriggerValue}
            options={[{ label: t('true'), value: 'true' }, { label: t('false'), value: 'false' }]}
          />
        );
      case 'string':
        return (
          <TextInput
            Component={undefined}
            disabled={triggerEmptyValue}
            style={{ marginLeft: '5px', width: '270px' }}
            options={inputsAndVariables}
            trigger="$"
            value={triggerValue ? idToReadable(triggerValue) : ''}
            onChange={e => setTriggerValue(readableToId(e))}
            placeholder={t('triggerVariableList')}
          />
        );
      default:
        return null;
    }
  }

  return (
    <div>
      <div className="inputTitle">{t('triggerType')}</div>
      <input
        type="hidden"
        name="triggerType"
        value={triggerType}
      />
      <ButtonGroup
        buttons={valueTriggerOptions}
        onChange={type => setTriggerType(type)}
        value={triggerType}
      />
      <div>
        <FontAwesome icon="info-circle" />
        {' '}
        {triggerType === triggerTypes.VALUE_CHANGE
          ? t('valueChangeDefinition')
          : t('valueSetDefinition')}
      </div>

      <div className="inputTitle">{t('variable')}</div>
      <Select
        name="valueId"
        options={variableOptions}
        defaultValue={selectedObject.valueId}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('willTriggerOn')}</div>
      <div>
        <input
          type="radio"
          id="isEvery"
          defaultChecked={triggerEmptyValue}
          checked={triggerEmptyValue}
          onClick={() => handleEmptyValueRadio(true)}
        />
        <label htmlFor="isEvery">
          {triggerType === triggerTypes.VALUE_CHANGE
            ? t('everyChanges')
            : t('everySet')}
        </label>
      </div>
      <div>
        <input
          type="radio"
          id="isOnlyWhen"
          defaultChecked={!triggerEmptyValue}
          checked={!triggerEmptyValue}
          onClick={() => handleEmptyValueRadio(false)}
        />
        <label htmlFor="isOnlyWhen">
          {triggerType === triggerTypes.VALUE_CHANGE
            ? t('onlyWhenValueChangesTo')
            : t('onlyWhenValueSetTo')}
        </label>
        <input
          type="hidden"
          value={triggerValue}
          name={triggerEmptyValue ? 'triggerValue' : `${triggerValueType}:triggerValue`}
        />
        <Select
          style={triggerEmptyValue ? { margin: '0 5px 0 5px', display: 'inline-block', width: '16%', opacity: '0.6' }
            : { margin: '0 5px 0 5px', display: 'inline-block', width: '16%' }}
          disabled={triggerEmptyValue}
          value={triggerValueType}
          onChange={e => handleChangeTriggerValueType(e)}
          options={[
            { label: t('number'), value: 'number' },
            { label: t('boolean'), value: 'boolean' },
            { label: t('string'), value: 'string' },
          ]}
          placeholder={t('select')}
        />
        {getValueInput()}
      </div>
    </div>
  );
};

FormValueTrigger.propTypes = {
  selectedObject: PropTypes.object,
};
FormValueTrigger.defaultProps = {
  selectedObject: {},
};

export { FormValueTrigger };
