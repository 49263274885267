const padding = number => {
  if ((number || number === 0) && !Number.isNaN(Number(number))) {
    if (number >= 0) {
      return number.toString().padStart(2, '0');
    }
    const abs = Math.abs(number);
    return `-${abs.toString().padStart(2, '0')}`;
  }
  return '';
};

const setStringAt = (baseStr, index, addedStr) => {
  if (index > baseStr.length) {
    throw new Error('Index is greater than the base string.');
  }
  return baseStr.substr(0, index) + addedStr + baseStr.substr(index);
};

const numberToHex = number => (number.toString(16));

const rgbaToHex = color => {
  if (typeof color !== 'object') {
    throw new Error('Color is not an object.');
  }
  if ((!color.r && color.r !== 0) || (!color.g && color.g !== 0)
    || (!color.b && color.b !== 0)) {
    throw new Error('The object is malformed.');
  }
  color.a = color.a === undefined ? 1 : color.a;
  // convert to HEX
  const red = numberToHex(color.r).length === 1 ? `0${numberToHex(color.r)}` : numberToHex(color.r);
  const green = numberToHex(color.g).length === 1 ? `0${numberToHex(color.g)}` : numberToHex(color.g);
  const blue = numberToHex(color.b).length === 1 ? `0${numberToHex(color.b)}` : numberToHex(color.b);

  const alpha = numberToHex(Math.round(color.a * 255)).length === 1
    ? `0${numberToHex(Math.round(color.a * 255))}`
    : numberToHex(Math.round(color.a * 255));

  return `#${red}${green}${blue}${alpha}`;
};

const stopPropagation = e => {
  if (!e) {
    e = window.event;
  }
  e.cancelBubble = true;
  if (e.stopPropagation) {
    e.stopPropagation();
  }
};

export { getFormData } from './getFormData';
export { getTitle } from './getTitle';
export { getVariablesDependencies } from './getVariablesDependencies';
export { invertColor } from './invertColor';

export {
  padding,
  setStringAt,
  numberToHex,
  rgbaToHex,
  stopPropagation,
};
