import PropTypes from 'prop-types';

export enum ActionType {
  API_CALL = 'APICallAction',
  EMAIL = 'EmailAction',
  EVENT = 'EventAction',
  MACHINE_PARAM = 'MachineParamAction',
  MACHINE_STATE = 'MachineStateAction',
  MQTT = 'MQTTAction',
  PART = 'PartAction',
  PERFORMANCE = 'PerformanceAction',
  PUSH_NOTIFICATION = 'PushNotificationAction',
  SMS = 'SMSAction',
  STOPWATCH = 'StopwatchAction',
  VARIABLE = 'VariableAction',
}

export interface Action {
  id: string;
  name: string;
  type: ActionType;
}

export const ActionPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ActionType)),
});

