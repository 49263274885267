import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './DefaultModal.scss';

const DefaultModal = ({
  title,
  children,
  show,
  headerBackgroundColor,
  closePopup,
  headless,
  noScrollingOutside,
  fullSize,
  style,
}) => (
  <Modal
    show={show}
    className={`${fullSize ? 'DefaultModalFullSize' : 'DefaultModal'} ${noScrollingOutside ? 'noScrollingOutside' : ''} theme-${localStorage.getItem('theme')} undraggable`}
    dialogClassName={`${fullSize ? 'DefaultModalFullSize' : 'DefaultModal'}`}
    onHide={closePopup}
    onClose={closePopup}
    style={style}
  >
    {!headless && (
      <Modal.Header
        closeButton
        style={{
          backgroundColor: headerBackgroundColor,
        }}
      >
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
    )}
    <Modal.Body>
      {children}
    </Modal.Body>
  </Modal>
);

DefaultModal.propTypes = {
  children: PropTypes.node,
  closePopup: PropTypes.func,
  fullSize: PropTypes.bool,
  headerBackgroundColor: PropTypes.string,
  headless: PropTypes.bool,
  noScrollingOutside: PropTypes.bool,
  show: PropTypes.bool,
  style: PropTypes.shape({}),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.elementType,
  ]),
};

DefaultModal.defaultProps = {
  children: null,
  closePopup: () => undefined,
  fullSize: false,
  headerBackgroundColor: '', // TODO
  headless: false,
  noScrollingOutside: false,
  show: false,
  style: {},
  title: '',
};

export default DefaultModal;
