export default {
  rows: 6,
  breakpoint: {
    large: 1200,
    medium: 996,
    small: 768,
    xsmall: 480,
    xxsmall: 240,
  },
  column: {
    large: 12,
    medium: 9,
    small: 6,
    xsmall: 4,
    xxsmall: 2,
  },
};
