import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import {
  getStreamsToGroupedOptions,
  getVariablesToGroupedOptions,
  getMachinesKPISToGroupedOptions,
} from '~components/forms/utils';
import { ActionPropTypes } from '~services/actions/types';
import { RootState } from '~services/store';
import { TextInput, FontAwesome } from '~UI/index';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './ActionForm.scss';

const MachineParamActionForm = ({ action }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const operators = useSelector((state: RootState) => state.operators);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));
  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);

  const [value, setValue] = useState(action.params.value);
  const [machineId, setMachineId] = useState(action.params.machineId);
  const [param, setParam] = useState(action.params.param);

  const operations = useSelector((state: RootState) => state.operations.operations).filter(
    element => element.machines.includes(machineId),
  );
  const machine = machines.find(elem => elem.id === machineId) || {};
  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

  const variableOptions = [
    getVariablesToGroupedOptions(variables),
    ...getStreamsToGroupedOptions(streams),
    ...getMachinesKPISToGroupedOptions(machines),
  ];

  const selectOptions = variableOptions.map(group => ({
    label: group.label,
    options: (group.options || []).map(option => ({
      label: option.label,
      value: variableToId(`$${option.label}`, inputsAndVariables),
    })),
  }));

  const operationButtons = [{
    label: 'Operation',
    value: 'operation',
  },
  {
    label: 'Variable',
    value: 'variable',
  }];

  const initialButtonState = value?.includes('$') ? 'variable' : 'operation';
  const [buttonStatus, setButtonStatus] = useState(initialButtonState);

  const machineOperators = operators?.filter(op => machine?.operators?.includes(op.id));

  const options = sortArray('alphabetically', inputsAndVariables, 'variable').map(x => x.variable);
  options.splice(0, 0, 'NOW');

  const idToReadable = expression => {
    if (typeof expression === 'string') {
      return idToReadableExpr(expression, inputsAndVariables) || expression;
    }
    return expression;
  };

  const onValueChange = val => {
    let id = val;
    try {
      id = variableToId(val, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }
    setValue(id);
  };

  const handleParamChange = e => {
    setParam(e);
    setValue('');
  };

  const showParamValue = () => {
    switch (param) {
      case 'skuNumber':
        return (
          <>
            <div className="inputTitle">{t('value')}</div>
            <Select
              name="value"
              value={value}
              onChange={setValue}
              placeholder={t('select')}
              options={
                (machine.skuNumbers || []).map(sku => ({
                  label: sku.name,
                  value: sku.name,
                }))
              }
            />
          </>
        );
      case 'operator':
        return (
          <>
            <div className="inputTitle">{t('value')}</div>
            <Select
              name="value"
              value={value}
              onChange={setValue}
              placeholder={t('select')}
              options={
                (machineOperators || []).map(op => ({
                  label: op.name,
                  value: op.name,
                }))
              }
            />
          </>
        );
      case 'workOrder':
        return (
          <>
            <div className="inputTitle">{t('value')}</div>
            <input
              type="hidden"
              value={value}
              name="value"
            />
            <TextInput
              className="fullwidth"
              onChange={e => onValueChange(e)}
              options={options}
              placeholder={t('triggerVariableList')}
              trigger="$"
              value={idToReadable(value)}
            />
            <div style={{ marginBottom: '8px' }}>
              <FontAwesome icon="info-circle" />
            &nbsp;&nbsp;
              {t('stringInVariables')}
            </div>
          </>
        );
      case 'operation':
        return (
          <>
            <ButtonGroup
              buttons={operationButtons}
              onChange={setButtonStatus}
              value={buttonStatus}
            />
            {
              buttonStatus === 'operation' ? (
                <Select
                  name="value"
                  value={value}
                  onChange={setValue}
                  placeholder={t('select')}
                  options={(operations || []).map(o => (
                    { label: o.name, value: o.name }
                  ))}
                />
              ) : (
                <Select
                  name="value"
                  value={value}
                  onChange={setValue}
                  placeholder={t('select')}
                  options={selectOptions}
                />
              )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={setMachineId}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('machineParam')}</div>
      <Select
        style={!machineId ? { opacity: '0.6' } : {}}
        name="param"
        className="fullwidth"
        disabled={!machineId}
        value={param}
        onChange={e => handleParamChange(e)}
        options={[
          { label: t('skuNumber'), value: 'skuNumber' },
          { label: t('operator'), value: 'operator' },
          { label: t('workOrder'), value: 'workOrder' },
          { label: t('operation'), value: 'operation' },
        ]}
        placeholder={t('select')}
      />
      {param && showParamValue()}
    </div>
  );
};

MachineParamActionForm.propTypes = {
  action: ActionPropTypes,
};
MachineParamActionForm.defaultProps = {
  action: {
    params: {},
  },
};

export { MachineParamActionForm };
