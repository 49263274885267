import { Dashboard } from './types';

export enum DashboardActionTypes {
  GET_DASHBOARDS_REQUEST = 'GET_DASHBOARDS_REQUEST',
  GET_DASHBOARDS_RESPONSE = 'GET_DASHBOARDS_RESPONSE',
  UPDATE_DASHBOARD_REQUEST = 'UPDATE_DASHBOARD_REQUEST',
  UPDATE_DASHBOARD_RESPONSE = 'UPDATE_DASHBOARD_RESPONSE',
  ADD_DASHBOARD_REQUEST = 'ADD_DASHBOARD_REQUEST',
  ADD_DASHBOARD_RESPONSE = 'ADD_DASHBOARD_RESPONSE',
  DELETE_DASHBOARD_REQUEST = 'DELETE_DASHBOARD_REQUEST',
  DELETE_DASHBOARD_RESPONSE = 'DELETE_DASHBOARD_RESPONSE',

  UPDATE_DASHBOARD_TILE_REQUEST = 'UPDATE_DASHBOARD_TILE_REQUEST',
  UPDATE_DASHBOARD_TILE_RESPONSE = 'UPDATE_DASHBOARD_TILE_RESPONSE',
  UPDATE_MANY_DASHBOARD_TILES_REQUEST = 'UPDATE_MANY_DASHBOARD_TILES_REQUEST',
  UPDATE_MANY_DASHBOARD_TILES_RESPONSE = 'UPDATE_MANY_DASHBOARD_TILES_RESPONSE',
  ADD_DASHBOARD_TILE_REQUEST = 'ADD_DASHBOARD_TILE_REQUEST',
  ADD_DASHBOARD_TILE_RESPONSE = 'ADD_DASHBOARD_TILE_RESPONSE',
  DELETE_DASHBOARD_TILE_REQUEST = 'DELETE_DASHBOARD_TILE_REQUEST',
  DELETE_DASHBOARD_TILE_RESPONSE = 'DELETE_DASHBOARD_TILE_RESPONSE',
}

export type DashboardAction =
  | { type: DashboardActionTypes.GET_DASHBOARDS_REQUEST; payload: { dashboards: Dashboard[] } }
  | { type: DashboardActionTypes.GET_DASHBOARDS_RESPONSE; payload: { dashboards: Dashboard[] } }
  | { type: DashboardActionTypes.UPDATE_DASHBOARD_REQUEST; payload: { id: string; dashboard: Dashboard } }
  | { type: DashboardActionTypes.UPDATE_DASHBOARD_RESPONSE; payload: { id: string; dashboard: Dashboard } }
  | { type: DashboardActionTypes.ADD_DASHBOARD_REQUEST; payload: { id: string; dashboard: Partial<Dashboard> } }
  | { type: DashboardActionTypes.ADD_DASHBOARD_RESPONSE; payload: { id: string; dashboard: Dashboard } }
  | { type: DashboardActionTypes.DELETE_DASHBOARD_REQUEST; payload: { dashboardId: string } }
  | { type: DashboardActionTypes.DELETE_DASHBOARD_RESPONSE; payload: { dashboardId: string } }
  | { type: DashboardActionTypes.ADD_DASHBOARD_TILE_REQUEST; payload: { id: string; tiles: any } }
  | { type: DashboardActionTypes.ADD_DASHBOARD_TILE_RESPONSE; payload: { id: string; tiles: any } }
  | { type: DashboardActionTypes.UPDATE_DASHBOARD_TILE_REQUEST; payload: { id: string; tiles: any } }
  | { type: DashboardActionTypes.UPDATE_DASHBOARD_TILE_RESPONSE; payload: { id: string; tiles: any } }
  | { type: DashboardActionTypes.UPDATE_MANY_DASHBOARD_TILES_REQUEST; payload: { id: string; tiles: any } }
  | { type: DashboardActionTypes.UPDATE_MANY_DASHBOARD_TILES_RESPONSE; payload: { id: string; tiles: any } }
  | { type: DashboardActionTypes.DELETE_DASHBOARD_TILE_REQUEST; payload: { dashboardId: string; tileId: string } }
  | { type: DashboardActionTypes.DELETE_DASHBOARD_TILE_RESPONSE; payload: { dashboardId: string; tileId: string } };

const getDashboardsRequest = () => ({
  type: DashboardActionTypes.GET_DASHBOARDS_REQUEST,
});

const getDashboardsResponse = (data, request = null) => ({
  type: DashboardActionTypes.GET_DASHBOARDS_RESPONSE,
  payload: data,
  request,
});

const addDashboardRequest = data => ({
  type: DashboardActionTypes.ADD_DASHBOARD_REQUEST,
  payload: data,
});

const addDashboardResponse = (data, request = null) => ({
  type: DashboardActionTypes.ADD_DASHBOARD_RESPONSE,
  payload: data,
  request,
});

const updateDashboardRequest = data => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_REQUEST,
  payload: data,
});

const updateDashboardResponse = (data, request = null) => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_RESPONSE,
  payload: data,
  request,
});

const deleteDashboardRequest = data => ({
  type: DashboardActionTypes.DELETE_DASHBOARD_REQUEST,
  payload: data,
});

const deleteDashboardResponse = (data, request = null) => ({
  type: DashboardActionTypes.DELETE_DASHBOARD_RESPONSE,
  payload: data,
  request,
});

// Tiles
const updateDashboardTileRequest = data => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_TILE_REQUEST,
  payload: data,
});

const updateDashboardTileResponse = (data, request = null) => ({
  type: DashboardActionTypes.UPDATE_DASHBOARD_TILE_RESPONSE,
  payload: data,
  request,
});

const updateManyDashboardTilesRequest = data => ({
  type: DashboardActionTypes.UPDATE_MANY_DASHBOARD_TILES_REQUEST,
  payload: data,
});

const updateManyDashboardTilesResponse = (data, request = null) => ({
  type: DashboardActionTypes.UPDATE_MANY_DASHBOARD_TILES_RESPONSE,
  payload: data,
  request,
});

const addDashboardTileRequest = data => ({
  type: DashboardActionTypes.ADD_DASHBOARD_TILE_REQUEST,
  payload: data,
});

const addDashboardTileResponse = (data, request = null) => ({
  type: DashboardActionTypes.ADD_DASHBOARD_TILE_RESPONSE,
  payload: data,
  request,
});

const deleteDashboardTileRequest = data => ({
  type: DashboardActionTypes.DELETE_DASHBOARD_TILE_REQUEST,
  payload: data,
});

const deleteDashboardTileResponse = (data, request = null) => ({
  type: DashboardActionTypes.DELETE_DASHBOARD_TILE_RESPONSE,
  payload: data,
  request,
});

export {
  // Dashboard
  getDashboardsRequest,
  getDashboardsResponse,
  updateDashboardRequest,
  updateDashboardResponse,
  addDashboardRequest,
  addDashboardResponse,
  deleteDashboardRequest,
  deleteDashboardResponse,
  // Tiles
  updateDashboardTileRequest,
  updateDashboardTileResponse,
  updateManyDashboardTilesRequest,
  updateManyDashboardTilesResponse,
  addDashboardTileRequest,
  addDashboardTileResponse,
  deleteDashboardTileRequest,
  deleteDashboardTileResponse,
};
