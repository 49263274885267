import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import { CancelButton, SubmitButton, TextInput, FontAwesome } from '~UI';
import { getFormData } from '~utils';
import { showError } from '~utils/toast';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import '../RuleEditionPages.scss';

const TriggerConditionsForm = ({
  handleSaveConditions, handleClose, condition, popupId, isAdding,
}) => {
  const { t } = useTranslation();
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);
  const stopwatches = useSelector(state => state.stopwatches.stopwatches);
  const machines = useSelector(state => state.machines);

  let inputProperties = [].concat(...streams.map(s => s.properties));
  let kpis = [].concat(...machines.map(m => m.kpis || []));
  let inputsAndVariables = [...inputProperties, ...variables, ...kpis];

  const [inputText, setInputText] = useState(!isAdding
    ? idToReadableExpr(condition.expression, inputsAndVariables)
    : '');

  const handleSubmit = e => {
    e.preventDefault();
    const data = getFormData(`TriggerConditionForm_${popupId}`);

    if (!data.text) {
      showError(t('showErrorFields'));
      return;
    }

    const newInputProperties = [].concat(...streams.map(s => s.properties));
    const newKpis = [].concat(...machines.map(m => m.kpis || []));
    const newInputsAndVariables = [...newInputProperties, ...variables, ...newKpis];
    let { text } = data;
    try {
      text = variableToId(data.text, newInputsAndVariables);
    } catch (error) {
      showError(t('variableUsedIsNotDefined'));
      return;
    }
    data.text = text;

    if (isAdding) {
      const newExpression = {
        type: 'Expression',
        expression: data.text,
        id: uuidv4(),
      };

      if (condition.id === 'firstCondition') {
        handleSaveConditions(newExpression.id, newExpression, true);
        return;
      }

      if (condition.type === 'Expression') {
        const newCondition = {
          type: t('and'),
          id: uuidv4(),
          conditions: [
            Object.assign({}, condition),
            newExpression,
          ],
        };
        handleSaveConditions(condition.id, newCondition, true);
      } else {
        const newCondition = Object.assign({}, condition, { conditions: [...condition.conditions, newExpression] });
        handleSaveConditions(condition.id, newCondition, true);
      }
    } else {
      const newExpression = { ...condition, expression: data.text };
      handleSaveConditions(condition.id, newExpression, true);
    }
  };

  inputProperties = [].concat(...streams.map(s => s.properties));
  kpis = [].concat(...machines.map(m => m.kpis || []));
  inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable')
    .map(x => x.variable)
    .concat(stopwatches.map(x => x.name));
  inputsAndVariables.splice(0, 0, 'NOW');

  return (
    <form id={`TriggerConditionForm_${popupId}`} onSubmit={handleSubmit}>
      <div className="inputTitle">
        {t('if')}
        <Icon
          icon="info-circle"
          tooltipLabel={t('conditionSyntax')}
          tooltipPlacement="right"
        />
      </div>
      <TextInput
        options={inputsAndVariables}
        name="text"
        trigger="$"
        className="fullwidth"
        placeholder={t('triggerVariableList')}
        value={inputText}
        onChange={e => setInputText(e)}
        autoFocus
      />
      <div style={{ marginBottom: '8px' }}>
        <FontAwesome icon="info-circle" />
            &nbsp;&nbsp;
        {t('stringInVariables')}
      </div>
      <div className="buttonsHolder" style={{ textAlign: 'right' }}>
        <CancelButton onClick={handleClose} />
        <SubmitButton label={inputText ? t('modify') : t('add')} />
      </div>
    </form>
  );
};

TriggerConditionsForm.propTypes = {
  handleSaveConditions: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  condition: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    expression: PropTypes.string,
    conditions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  popupId: PropTypes.string.isRequired,
  isAdding: PropTypes.bool.isRequired,
};

export default TriggerConditionsForm;
