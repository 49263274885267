import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { TextInput, FontAwesome } from '~UI';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './TriggerForms.scss';

const propTypes = {
  trigger: PropTypes.shape({
    details: PropTypes.shape({
      valueId: PropTypes.string,
      changesTo: PropTypes.number,
    }),
  }).isRequired,
};

const addVariablesToSelectGroupedOptions = (variables, groupedOptions) => {
  const variablesList = variables.map(variable => (
    {
      // Words in value can be search in the select textbox
      value: variable.id,
      label: variable.variable,
    }
  ));

  groupedOptions.push({
    label: 'Variables',
    options: variablesList,
  });
};

const addStreamsToSelectGroupedOptions = (streams, groupedOptions) => {
  const streamsPropertiesList = streams.map(stream => {
    const propertiesList = stream.properties.map(property => (
      {
        // Words in value can be search in the select textbox
        value: property.id,
        label: property.variable,
      }
    ));

    return {
      label: `Stream - ${stream.name}`,
      options: propertiesList,
    };
  });

  groupedOptions.push(...streamsPropertiesList);
};

const addMachinesToSelectGroupedOptions = (machines, groupedOptions) => {
  const machinesKPIsList = machines.map(machine => {
    const KPIsList = (machine.kpis || []).map(property => (
      {
        // Words in value can be search in the select textbox
        value: property.id,
        label: property.variable,
      }
    ));

    return {
      label: `Machine - ${machine.name}`,
      options: KPIsList,
    };
  });

  groupedOptions.push(...machinesKPIsList);
};

const ValueChangeTriggerForm = ({ trigger }) => {
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);

  const { details } = trigger;
  const [valueId, setValueId] = useState(details?.valueId || '');
  const [changesTo, setChangesTo] = useState(details?.changesTo);
  const [isInChangesToMode, setIsInChangesToMode] = useState(false);
  const [inputType, setInputType] = useState(null);
  const [groupedOptions, setGroupedOptions] = useState([]);

  useEffect(() => {
    setValueId(details?.valueId || '');
    setChangesTo(details?.changesTo);
    if (typeof changesTo === 'boolean') {
      setInputType('boolean');
    } else if (typeof changesTo === 'string' && changesTo.length > 0) {
      setInputType('string');
    } else if (!Number.isNaN(+changesTo) && changesTo !== null && typeof changesTo !== 'string') { // isNaN("") and isNaN(null) returns false
      setInputType('number');
    }
    if (inputType) {
      setIsInChangesToMode(true);
    }
    const options = [];
    addVariablesToSelectGroupedOptions(variables, options);
    addStreamsToSelectGroupedOptions(streams, options);
    addMachinesToSelectGroupedOptions(machines, options);
    setGroupedOptions(options);
  }, [trigger]);

  const handleSelectionChange = selectValue => {
    setValueId(selectValue);
  };

  const setToAllChanges = () => {
    setIsInChangesToMode(false);
    setInputType(null);
    setChangesTo(null);
  };

  const setToChangesTo = () => {
    setIsInChangesToMode(true);
    setChangesTo(0);
  };

  const handleInputTypeChange = e => {
    let changesToArg = 0;
    if (e === 'boolean') {
      changesToArg = true;
    } else if (e === 'string') {
      changesToArg = '';
    }
    setInputType(e);
    setChangesTo(changesToArg);
  };

  const handleChangesTo = e => {
    setChangesTo(e);
  };

  const handleChangesToString = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    let id = value;
    try {
      id = variableToId(value, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the TriggerCRUDPage
      // This way the error message is popped only on submit and not on change
    }

    setChangesTo(id);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    return idToReadableExpr(value, inputsAndVariables);
  };

  const renderValueInput = () => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
    inputsAndVariables.splice(0, 0, 'NOW');

    if (inputType === 'boolean') {
      return (
        <Select
          style={!isInChangesToMode ? { width: '200px', marginRight: '5px', display: 'inline-block', opacity: '0.6' }
            : { width: '200px', marginRight: '5px', display: 'inline-block' }}
          value={changesTo.toString()}
          onChange={handleChangesTo}
          disabled={!isInChangesToMode}
          name="boolean:changesTo"
          options={[
            { label: t('true'), value: 'true' },
            { label: t('false'), value: 'false' },
          ]}
        />
      );
    }
    if (inputType === 'string') {
      return (
        <>
          <input
            type="hidden"
            value={changesTo || ''}
            name="changesTo"
          />
          <TextInput
            options={inputsAndVariables}
            trigger="$"
            value={changesTo ? idToReadable(changesTo) : ''}
            style={{ height: '35px', width: '270px' }}
            onChange={e => handleChangesToString(e)}
            placeholder={t('triggerVariableList')}
          />
          <div style={{ marginBottom: '8px' }}>
            <FontAwesome icon="info-circle" />
            &nbsp;&nbsp;
            {t('stringInVariables')}
          </div>
        </>
      );
    }
    return (
      <input
        type="number"
        name="number:changesTo"
        step="0.01"
        value={changesTo}
        disabled={!isInChangesToMode}
        placeholder="0"
        style={{ height: '35px' }}
        onChange={e => handleChangesTo(e.target.value)}
      />
    );
  };

  return (
    <div className="valuesTriggerForm">
      <div className="inputTitle">{t('varName')}</div>

      <div className="selectVariable">
        <Select
          name="valueId"
          onChange={handleSelectionChange}
          options={groupedOptions}
          value={valueId}
          placeholder={t('selectAVariable')}
        />
      </div>

      <div className="willTriggerOn">
        <div className="inputTitle">{t('willTriggerOn')}</div>
        <div className="options">
          <input
            className="optionRadio"
            type="radio"
            name="triggerType"
            checked={!isInChangesToMode}
            onClick={setToAllChanges}
            form="ValueShouldNotBeInFormData"
          />
          <div className="optionName">{t('everyChanges')}</div>
        </div>

        <div className="options">
          <input
            className="optionRadio"
            type="radio"
            name="triggerType"
            checked={isInChangesToMode}
            onClick={setToChangesTo}
            form="ValueShouldNotBeInFormData"
          />

          <div className="optionName">{t('onlyWhenValueChangesTo')}</div>
          <Select
            className={`selectInputType ${!isInChangesToMode && 'disabled'}`}
            onChange={handleInputTypeChange}
            disabled={!isInChangesToMode}
            value={inputType || 'number'}
            options={[
              { label: t('string'), value: 'string' },
              { label: t('boolean'), value: 'boolean' },
              { label: t('number'), value: 'number' },
            ]}
          />
          {renderValueInput()}
        </div>
      </div>
    </div>
  );
};

ValueChangeTriggerForm.propTypes = propTypes;

export default ValueChangeTriggerForm;
