import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@intelligenceindustrielle/react-ui-components';

const propTypes = {
  stream: PropTypes.shape({
    name: PropTypes.string.isRequired,
    topicIn: PropTypes.string.isRequired,
    topicOut: PropTypes.string.isRequired,
    periodState: PropTypes.string,
  }).isRequired,
};

const Sensor = ({ stream }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="inputTitle">{t('name')}</div>
      <input
        name="name"
        type="text"
        defaultValue={stream ? stream.name : null}
        className="fullwidth"
      />
      <div className="inputTitle">{t('inboundTopic')}</div>
      <input
        name="topicIn"
        type="text"
        defaultValue={stream ? stream.topicIn : null}
        className="fullwidth"
      />
      <div className="inputTitle">{t('outboundTopic')}</div>
      <input
        name="topicOut"
        type="text"
        defaultValue={stream ? stream.topicOut : null}
        className="fullwidth"
      />
      <div className="inputTitle">
        {`${t('periodState')}`}
        <Icon
          icon="info-circle"
          tooltipLabel={t('periodStateInfo')}
        />
      </div>
      <input
        name="number:periodState"
        type="number"
        defaultValue={stream?.periodState || 3600000}
        placeholder="Period state"
      />
    </div>
  );
};

Sensor.propTypes = propTypes;

export default Sensor;
