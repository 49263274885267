import React from 'react';
import PropTypes from 'prop-types';
import AutocompleteInput from 'react-autocomplete-input';
import 'react-autocomplete-input/dist/bundle.css';
import './TextInput.scss';

const TEXT_INPUT_AUTOCOMPLETE_OFFSET = 17;
const MAX_OPTIONS_DISPLAYED = 10;

interface TextInputProps {
  autoComplete?: 'off';
  className?: string;
  Component: JSX.Element | string;
  disabled?: boolean;
  matchAny?: boolean;
  name?: string;
  onChange: (value: string) => void;
  options: any[];
  placeholder?: string;
  rows?: number;
  spacer?: string;
  style?: React.CSSProperties;
  trigger: any;
  value: string | null;
}

const TextInput = ({
  autoComplete,
  Component,
  disabled,
  matchAny,
  name,
  options,
  rows,
  trigger,
  ...props
}: TextInputProps) => (
  <AutocompleteInput
    {...props}
    Component={Component}
    autoComplete={autoComplete}
    disabled={disabled}
    matchAny={matchAny}
    maxOptions={MAX_OPTIONS_DISPLAYED}
    name={name}
    offsetY={TEXT_INPUT_AUTOCOMPLETE_OFFSET}
    options={options.filter(o => !!o)}
    rows={rows}
    spacer=""
    trigger={trigger}
  />
);

TextInput.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  Component: PropTypes.string,
  trigger: PropTypes.string,
};
TextInput.defaultProps = {
  autoComplete: 'off',
  className: '',
  Component: 'input',
  disabled: false,
  name: undefined,
  placeholder: undefined,
  spacer: '',
  style: {},
  trigger: '',
};

export { TextInput };
