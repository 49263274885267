import React from 'react';
import PropTypes from 'prop-types';

import { FormInfo } from '~components/forms';

function FormTileInfo({ selectedTile }) {
  return (
    <FormInfo selectedObject={selectedTile} />
  );
}

FormTileInfo.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileInfo.defaultProps = {
  selectedTile: {},
};

export default FormTileInfo;
