import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ValidConfirmationForm } from '../../Popups';
import './Button.scss';

const SubmitButton = ({
  askConfirmation, className, label, onClick, style,
}) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  return (
    <span>
      <button
        type="submit"
        className={classnames({
          StyledButton: true,
          SubmitButton: true,
          ...(className && { [className]: true }),
        })}
        onClick={askConfirmation ? () => setShow(true) : onClick}
        style={style}
      >
        {label || t('submit')}
      </button>
      <ValidConfirmationForm
        show={show}
        onHide={() => setShow(false)}
        handleValidation={onClick}
        content={t('eraseCurrentConfiguration')}
        actionLabel={t('import')}
      />
    </span>
  );
};

SubmitButton.propTypes = {
  askConfirmation: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
SubmitButton.defaultProps = {
  askConfirmation: false,
  className: '',
  label: '',
  onClick: () => undefined,
  style: {},
};

export { SubmitButton };
