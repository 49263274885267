import isEmpty from 'lodash.isempty';
import Route from 'route-parser';

const checkStatus = async response => {
  if (response.ok) {
    return response.json();
  }
  const error = new Error(`HTTP Error ${response.statusText}`);
  error.status = response.statusText;
  error.response = await response.json();
  error.code = response.status;
  console.error(error);
  throw error;
};

const hasDefinedValue = value => (typeof value !== 'undefined' && value !== null);

const dataAsQueryString = data => {
  const query = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => {
    if (hasDefinedValue(value)) {
      if (value.toString() === '[object Object]') {
        query.append(key, JSON.stringify(value));
      } else {
        query.append(key, value);
      }
    }
  });
  const queryStr = query.toString();
  return (queryStr.length > 0 ? `?${queryStr}` : '');
};

const dataAsBodyPayload = data => {
  const payload = {};
  Object.entries(data).forEach(([key, value]) => {
    if (hasDefinedValue(value)) {
      payload[key] = value;
    }
  });

  if (isEmpty(payload)) {
    return JSON.stringify(payload);
  }
  return JSON.stringify(data);
};

async function perform(endpoint, ressources) {
  return fetch(`/api/v1${endpoint}`, ressources)
    .then(checkStatus);
}

export async function get(endpoint, params = {}, query = {}) {
  const ressources = {
    method: 'GET',
  };
  const route = new Route(endpoint);
  return perform(
    route.reverse(params) + dataAsQueryString(query),
    ressources,
  );
}

export async function post(endpoint, params = {}, body = {}) {
  const ressources = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    body: dataAsBodyPayload(body),
  };
  const route = new Route(endpoint);
  return perform(route.reverse(params), ressources);
}

async function postDefault(endpoint, params = {}, formData) {
  const ressources = {
    method: 'POST',
    body: formData,
  };

  const route = new Route(endpoint);
  return perform(route.reverse(params), ressources);
}

export async function put(endpoint, params = {}, body = {}) {
  const ressources = {
    method: 'PUT',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    body: dataAsBodyPayload(body),
  };
  const route = new Route(endpoint);
  return perform(
    route.reverse(params),
    ressources,
  );
}

export async function patch(endpoint, params = {}, body = {}) {
  const ressources = {
    method: 'PATCH',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }),
    body: dataAsBodyPayload(body),
  };
  const route = new Route(endpoint);
  return perform(
    route.reverse(params),
    ressources,
  );
}

export async function del(endpoint, params = {}, query = {}) {
  const ressources = {
    method: 'DELETE',
  };
  const route = new Route(endpoint);
  return perform(
    route.reverse(params) + dataAsQueryString(query),
    ressources,
  );
}

function Routes() {
  this.login = ({
    username = null, password = null, redirected = false, redirectedContent = null, code = null,
  }) => post('/login', {}, {
    username, password, redirected, redirectedContent, code,
  });
  this.loginMicrosoft = () => post('/login/microsoft', {}, {});
  this.loginRefresh = () => post('/login/refresh', {}, {});
  this.logout = () => post('/logout', {}, {});

  this.getServerTime = () => get('/serverTime', undefined, { time: Date.now() });

  // Users
  this.getUserVerification = userId => get('/users/:userId/verification', { userId }, {});
  this.getUser = id => get('/users/:id', { id });
  this.getUsers = () => get('/users');
  this.addUser = (userData, origin) => post('/users', {}, { userData, origin });
  this.updateUser = (userId, userData) => put('/users/:userId', { userId }, userData);
  this.verifyUser = (userId, userData) => put('/users/:userId/verify', { userId }, { isVerified: true, userData });
  this.deleteUser = userId => del('/users/:userId', { userId });
  this.getUserPermissions = userId => get('/users/:userId/permissions', { userId: encodeURIComponent(userId) });
  this.getAllUsersPermissions = () => get('/permissions');
  this.getUserAppKeys = userId => get('/users/:userId/appKeys', { userId });
  this.updateUsersContentsPermissions = (users, content) => patch('/users/permissions', {}, { users, content });
  this.addUserAppKey = (userId, appKey) => post('/users/:userId/appKeys', { userId }, { appKey });
  this.deleteUserAppKey = (userId, keyId) => del('/users/:userId/appKeys/:keyId', { userId, keyId });
  this.sendWelcomeEmail = userId => put('/users/:userId/welcome-email', { userId }, {});

  // Streams
  this.getStreams = () => get('/streams');
  this.addStream = stream => post('/streams', {}, stream);
  this.updateStream = (streamId, stream) => put('/streams/:streamId', { streamId }, stream);
  this.deleteStream = streamId => del('/streams/:streamId', { streamId });
  // Stream properties
  this.getStreamProperties = streamId => get('/streams/:streamId/properties', { streamId });
  this.addStreamProperty = (streamId, property) => post('/streams/:streamId/properties', { streamId }, property);
  this.updateStreamProperty = (streamId, propertyId, property) => put('/streams/:streamId/properties/:propertyId', { streamId, propertyId }, property);
  this.deleteStreamProperty = (streamId, propertyId) => del('/streams/:streamId/properties/:propertyId', { streamId, propertyId });

  // Machines
  this.getMachines = () => get('/machines', {});
  this.addMachine = machine => post('/machines', {}, machine);
  this.updateMachine = (id, machine) => put('/machines/:id', { id }, machine);
  this.patchMachine = (id, machine) => patch('/machines/:id', { id }, machine);
  this.deleteMachine = id => del('/machines/:id', { id });
  this.getMachineStats = (id, filter) => get('/machines/:id/stats', { id }, { filter });
  this.getPerformanceDropStats = (id, filter) => get('/machines/:id/performanceDropStats', { id }, { filter });
  this.updateMachineParams = (machineId, data) => put('/machines/:machineId/params', { machineId }, data);
  // KPIs
  this.getMachineKPIs = id => get('/machines/:id/kpis', { id });
  this.addMachineKPI = (id, kpiData) => post('/machines/:id/kpis', { id }, kpiData);
  this.updateMachineKPI = (id, kpiId, kpiData) => put('/machines/:id/kpis/:kpiId', { id, kpiId }, kpiData);
  this.deleteMachineKPI = (id, kpiId) => del('/machines/:id/kpis/:kpiId', { id, kpiId });
  // StopCauses
  this.getStopCauses = machineId => get('/machines/:machineId/stopcauses', { machineId });
  this.createStopCause = (machineId, stopCause) => post('/machines/:machineId/stopcauses', { machineId }, stopCause);
  this.updateStopCause = (machineId, id, stopCause) => put('/machines/:machineId/stopcauses/:id', { machineId, id }, stopCause);
  this.deleteStopCause = (machineId, id) => del('/machines/:machineId/stopcauses/:id', { machineId, id });
  this.createSubStopCause = (machineId, parentStopCauseId, subStopCause) => post('/machines/:machineId/stopcauses/:parentStopCauseId/substopcauses', { machineId, parentStopCauseId }, subStopCause);
  this.updateSubStopCause = (machineId, parentStopCauseId, id, subStopCause) => put('/machines/:machineId/stopcauses/:parentStopCauseId/substopcauses/:id', { machineId, parentStopCauseId, id }, subStopCause);
  this.deleteSubStopCause = (machineId, parentStopCauseId, id) => del('/machines/:machineId/stopcauses/:parentStopCauseId/substopcauses/:id', { machineId, parentStopCauseId, id });
  // PerformanceCauses
  this.getPerformanceCauses = machineId => get('/machines/:machineId/performancecauses', { machineId });
  this.createPerformanceCause = (machineId, performanceCause) => post('/machines/:machineId/performancecauses', { machineId }, performanceCause);
  this.updatePerformanceCause = (machineId, id, performanceCause) => put('/machines/:machineId/performancecauses/:id', { machineId, id }, performanceCause);
  this.deletePerformanceCause = (machineId, id) => del('/machines/:machineId/performancecauses/:id', { machineId, id });
  this.createSubPerformanceCause = (machineId, parentPerformanceCauseId, subPerformanceCause) => post('/machines/:machineId/performancecauses/:parentPerformanceCauseId/subperformancecauses', { machineId, parentPerformanceCauseId }, subPerformanceCause);
  this.updateSubPerformanceCause = (machineId, parentPerformanceCauseId, id, subPerformanceCause) => put('/machines/:machineId/performancecauses/:parentPerformanceCauseId/subperformancecauses/:id', { machineId, parentPerformanceCauseId, id }, subPerformanceCause);
  // DefectCauses
  this.getDefectCauses = machineId => get('/machines/:machineId/defectcauses', { machineId });
  this.createDefectCause = (machineId, defectCause) => post('/machines/:machineId/defectcauses', { machineId }, defectCause);
  this.updateDefectCause = (machineId, id, defectCause) => put('/machines/:machineId/defectcauses/:id', { machineId, id }, defectCause);
  this.deleteDefectCause = (machineId, id) => del('/machines/:machineId/defectcauses/:id', { machineId, id });
  this.createSubDefectCause = (machineId, parentDefectCauseId, subDefectCause) => post('/machines/:machineId/defectcauses/:parentDefectCauseId/subdefectcauses', { machineId, parentDefectCauseId }, subDefectCause);
  this.updateSubDefectCause = (machineId, parentDefectCauseId, id, subDefectCause) => put('/machines/:machineId/defectcauses/:parentDefectCauseId/subdefectcauses/:id', { machineId, parentDefectCauseId, id }, subDefectCause);
  // Shifts
  this.getMachineShifts = machineId => get('/machines/:machineId/shifts', { machineId });
  this.createMachineShift = (machineId, shift) => post('/machines/:machineId/shifts', { machineId }, shift);
  this.updateMachineShift = (machineId, id, shift) => put('/machines/:machineId/shifts/:id', { machineId, id }, shift);
  this.deleteMachineShift = (machineId, id) => del('/machines/:machineId/shifts/:id', { machineId, id });

  // Variables
  this.getVariables = () => get('/variables');
  this.addVariable = variable => post('/variables', {}, variable);
  this.updateVariable = (variableId, variableData) => put('/variables/:variableId', { variableId }, variableData);
  this.patchVariable = (variableId, variableData) => patch('/variables/:variableId', { variableId }, variableData);
  this.deleteVariable = variableId => del('/variables/:variableId', { variableId });

  // Values
  this.getValues = (valueId, filter, limit) => get('/values/:id', { id: valueId }, { filter, limit });
  this.getMultiplesValues = ids => post('/values', {}, { ids });
  this.updateValue = (valueId, data) => post('/values/:id', { id: valueId }, data);

  // Dashboards & Tiles
  this.getDashboards = () => get('/dashboards');
  this.addDashboard = dashboard => post('/dashboards', {}, dashboard);
  this.updateDashboard = (dashId, dashboard) => put('/dashboards/:dashId', { dashId }, dashboard);
  this.deleteDashboard = dashId => del('/dashboards/:dashId', { dashId });

  this.addDashboardTile = (dashboardId, tileData) => post('/dashboards/:dashboardId/tiles', { dashboardId }, tileData);
  this.updateDashboardTile = (dashboardId, tileId, tileData) => put('/dashboards/:dashboardId/tiles/:tileId', { dashboardId, tileId }, tileData);
  this.updateManyDashboardTiles = (dashboardId, tileData) => put('/dashboards/:dashboardId/tiles/', { dashboardId }, tileData);
  this.deleteDashboardTile = (dashboardId, tileId) => del('/dashboards/:dashboardId/tiles/:tileId', { dashboardId, tileId });

  // Topviews & Widgets
  this.getTopviews = () => get('/topviews');
  this.addTopview = topview => post('/topviews', {}, topview);
  this.updateTopview = (topviewId, topview) => put('/topviews/:topviewId', { topviewId }, topview);
  this.deleteTopview = topviewId => del('/topviews/:topviewId', { topviewId });

  this.addWidget = (topviewId, widget) => post('/topviews/:topviewId/widgets', { topviewId }, widget);
  this.updateWidget = (topviewId, widgetId, widget) => put('/topviews/:topviewId/widgets/:widgetId', { topviewId, widgetId }, widget);
  this.deleteWidget = (topviewId, widgetId) => del('/topviews/:topviewId/widgets/:widgetId', { topviewId, widgetId });

  // Events
  this.getEvents = (filter, sort, limit, select = undefined) => get('/events', {}, { filter, sort, limit, select });
  this.getDistinctEvents = (property, filter) => get('/events/distinct/:property', { property }, { filter });
  this.getDistinctMachineStatusEvents = eventFilter => post('/events/distinct/machinestatus', {}, eventFilter);
  this.countEvents = filter => get('/events/count', {}, { filter });
  this.createEvent = event => post('/events', {}, event);
  this.updateEvent = (eventId, event) => put('/events/:eventId', { eventId }, event);
  this.patchEvent = (eventId, event) => patch('/events/:eventId', { eventId }, event);
  this.deleteEvent = eventId => del('/events/:eventId', { eventId });

  // LinkRedirection
  this.getLinkRedirection = linkURL => get('/linkRedirections/:linkURL', { linkURL });
  this.addLinkRedirection = data => post('/linkRedirections', {}, data);

  // Triggers
  this.getTriggers = () => get('/triggers');
  this.addTrigger = triggerInfo => post('/triggers', {}, triggerInfo);
  this.updateTrigger = (id, triggerInfo) => put('/triggers/:id', { id }, triggerInfo);
  this.patchTrigger = (id, triggerInfo) => patch('/triggers/:id', { id }, triggerInfo);
  this.deleteTrigger = id => del('/triggers/:id', { id });
  this.executeTrigger = (triggerId, params) => post('/triggers/:triggerId', { triggerId }, params);

  // Stopwatches
  this.getStopwatches = () => get('/stopwatches');
  this.addStopwatch = stopwatch => post('/stopwatches', {}, stopwatch);
  this.updateStopwatch = (stopwatchId, stopwatch) => put('/stopwatches/:stopwatchId', { stopwatchId }, stopwatch);
  this.deleteStopwatch = stopwatchId => del('/stopwatches/:stopwatchId', { stopwatchId });
  this.stopwatchIO = (stopwatchId, flag) => put('/stopwatches/:stopwatchId/actions', { stopwatchId }, flag);

  // Settings
  this.getSettings = () => get('/settings');
  this.updateSettings = settings => put('/settings', {}, settings);
  this.patchSettings = settings => patch('/settings', {}, settings);

  // Shifts
  this.getShifts = () => get('/shifts');
  this.addShift = shiftInfo => post('/shifts', {}, shiftInfo);
  this.updateShift = (id, shiftInfo) => put('/shifts/:id', { id }, shiftInfo);
  this.deleteShift = id => del('/shifts/:id', { id });

  // Feedback
  this.createBug = bugInfos => postDefault('/feedback/bugs', {}, bugInfos);
  this.createEvaluation = evaluationInfos => post('/feedback/evaluations', {}, evaluationInfos);

  // Forms
  this.getForms = () => get('/forms');

  // Tags
  this.getTags = () => get('/tags');
  this.createTag = data => post('/tags', {}, data);
  this.updateTag = (id, data) => put('/tags', { id }, data);
  this.deleteTag = id => del('/tags', { id });

  // File Upload
  this.uploadFile = file => fetch('/api/v1/files', { method: 'POST', body: file }).then(checkStatus);

  // Images
  this.getImages = () => get('/images');
  this.addImage = imageInfo => post('/images', {}, imageInfo);
  this.deleteImage = id => del('/images/:id', { id });

  // Operations
  this.getOperations = () => get('/operations');
  this.getOperationStats = (id, filter) => get('/operations/:id/stats', { id }, { filter });
  this.createOperation = data => post('/operations', {}, data);
  this.updateOperation = (id, data) => put('/operations/:id', { id }, data);
  this.deleteOperation = id => del('/operations/:id', { id });

  // Reports
  this.getReports = () => get('/reports');
  this.addReport = report => post('/reports', {}, report);
  this.updateReport = (id, report) => put('/reports/:id', { id }, report);
  this.deleteReport = id => del('/reports/:id', { id });

  // Logs
  this.getLogs = (filter, sort, limit, select = undefined) => get('/logs', {}, { filter, sort, limit, select });

  // Client Metrics
  this.getClientMetrics = filter => get('/clientMetrics', {}, { filter });

  // AI
  this.sendAudioToTranscription = data => postDefault('/ai/transcription', {}, data);
  this.sendPromptTileHtmlFile = data => postDefault('/ai/promptTile', {}, data);
  this.initializeChat = (assistantId, message) => post('/chatOpenAI/start', {}, { assistantId, message });
  this.sendMessageGPT = (message, assistantId, threadId) => post('/chatOpenAI/sendMessage', {}, { message, assistantId, threadId });
  this.getAssistants = () => get('/chatOpenAI/assistants');
  this.sendMessageMake = (requestBody, id, file) => post('/make/sendMessage', {}, { requestBody, id, file });
  this.sendImagePrompt = (model, prompt) => get('/ai/generateImage', {}, { model, prompt });
}

const API = new Routes();

export default API;
