import formatTime from '~utils/time/convertToStandardTime';

const formatSKU = skus => skus.map(sku => ({
  ...sku,
  unitCompletionTime: formatTime(sku.unitCompletionTime),
}));

export default formatSKU;

const findCauseInSubMenu = (childCause, parent, subMenu, isByName) => {
  for (const subCause of subMenu) {
    if ((isByName && subCause.name === childCause.name) || (!isByName && subCause.id === childCause.id)) {
      return {
        childCause: subCause,
        parentCause: parent,
      };
    } if (subCause.subMenu) {
      const result = findCauseInSubMenu(childCause, subCause, subCause.subMenu, isByName);
      if (result) return result;
    }
  }
  return undefined;
};

export const getCauseInfosByNameOrId = (parentCause, childCause, causes, isByName = false) => {
  for (const cause of causes) {
    const result = findCauseInSubMenu(childCause, cause, cause.subMenu, isByName);
    if (result) return result;

    if ((isByName && cause.name === childCause.name) || (!isByName && cause.id === childCause.id)) {
      return {
        childCause: cause,
        parentCause: null,
      };
    }
  }
  // cause not found
  return undefined;
};
