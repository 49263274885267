import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MarkdownIt from 'markdown-it';
import { Accordion } from '@intelligenceindustrielle/react-ui-components';
import { PageTemplate } from '~components/Pages';
import temp from './PublicChangeLog.md';
import '@intelligenceindustrielle/react-ui-components/dist/styles.css';
import './ChangeLogPage.scss';

interface Item {
  body: JSX.Element[];
  title: string | null;
}

const parseMarkdown = md => {
  const tokens = (new MarkdownIt()).parse(md, {});
  let title = null;
  let bodyArr: JSX.Element[] = [];
  let isTitle = false;

  const ret: Item[] = [];

  tokens
    .forEach((token, index) => {
      if (token.markup === '##' && token.type === 'heading_open') {
        if (title) {
          ret.push({ title, body: [...bodyArr] });
          title = null;
          bodyArr = [];
        }
        isTitle = true;
      } else if (!isTitle) {
        if (token.type === 'inline') {
          if (index - 1 >= 0 && tokens[index - 1].markup === '###') {
            bodyArr.push(
              <div key={index}>
                <h2>{token.content}</h2>
                <br />
              </div>,
            );
          } else if (index - 1 >= 0 && tokens[index - 1].markup === '####') {
            bodyArr.push(
              <div key={index}>
                <h3>{token.content}</h3>
                <br />
              </div>,
            );
          } else if (index - 1 >= 0 && tokens[index - 1].markup === '#####') {
            bodyArr.push(
              <p key={index}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {` - ${token.content}`}
              </p>,
            );
          } else if (index - 1 >= 0 && tokens[index - 1].markup === '######') {
            bodyArr.push(
              <p key={index}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;
                {` - ${token.content}`}
              </p>,
            );
          } else if (index - 1 >= 0 && tokens[index - 1].markup !== '#') {
            bodyArr.push(
              <p key={index}>
                {`- ${token.content}`}
              </p>,
            );
          }
        }
      } else {
        title = token.content;
        isTitle = false;
      }
      if (index === tokens.length - 1) {
        ret.push({
          title,
          body: bodyArr,
        });
      }
    });
  return ret;
};

const ChangeLogPage = () => {
  const { t } = useTranslation();

  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    fetch(temp)
      .then(response => response.text())
      .then(text => {
        setItems(parseMarkdown(text));
      });
  }, []);

  return (
    <PageTemplate sidebar>
      <div className="changeLogPage">
        <h1 className="title">{t('changeLog')}</h1>
        <Accordion
          allowMultipleExpanded
          allowZeroExpanded
          items={items}
        />
      </div>
    </PageTemplate>
  );
};

export default ChangeLogPage;
