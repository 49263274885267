import { DateTime, Duration } from 'luxon';

const dateToString = date => DateTime.fromJSDate(date).toFormat('yyyy-MM-dd HH:mm:ss');

const dateToStringWithoutYearAndMillis = date => DateTime.fromJSDate(date).toFormat('MM-dd HH:mm');

const dateToHHmm = date => DateTime.fromJSDate(date).toFormat('HH:mm');

/**
 * Given a time string like this '16:30'
 * this returns { hours: 16, minutes: 30, seconds: 0 }
 */
const splitTime = time => {
  if (typeof time !== 'string') {
    throw new Error('Parameter is not a string');
  }
  if (Number(time.split(':')[0]) > 23) {
    throw new Error('Hours should not be above 23');
  }
  if (Number(time.split(':')[1]) > 59) {
    throw new Error('Minutes should not be above 59');
  }
  if (!Number.isNaN(Number(time.split(':')[2])) && Number(time.split(':')[2]) > 59) {
    throw new Error('Seconds should not be above 59');
  }
  return {
    hours: Number(time.split(':')[0]),
    minutes: Number(time.split(':')[1]),
    seconds: Number(time.split(':')[2]) || 0,
  };
};

// Format 14:00
const millisToHHmm = time => DateTime.fromMillis(time).toFormat('HH:mm');

// Format 14:00:00
const millisToHHmmss = time => DateTime.fromMillis(time).toFormat('HH:mm:ss');

/**
 * A moment.js wrapper to format durations (not Dates).
 *
 * @param {Number} duration Duration you want to format in ms
 * @param {Object} format An object where you can choose how you want to format.  Every field is optional.
 * {
 *   year: Boolean => if false, it will never displays the year in your result.
 *   month: Boolean => if false, it will never displays the month in your result.
 *   day: Boolean => if false, it will never displays the day in your result.
 *   hour: Boolean => displays the number of hours without a unit
 *   hourSeparator: String => displays the number of hours with the chosen unit
 *   min: Boolean => displays the number of minutes without a unit
 *   minSeparator: String => displays the number of minutes with the chosen unit
 *   sec: Boolean => displays the number of seconds without a unit
 *   secSeparator: String => displays the number of seconds with the chosen unit
 *   millisec: Boolean => displays the number of milliseconds without a unit
 *   millisecSeparator: String => displays the number of milliseconds with the chosen unit
 * }
 * @param {Boolean} adaptative default true.
 * If you choose to show every field but your duration is just 90sec, it will show "1:30" and not "0 year 0 month ..."
 * @param {String} language default en. valid["en", "fr"]
 * Since you can not choose your separator for day/month/year,
 * The function will automatically translate and grant plural if need be like: "1 day", "2 days", "1 jour", "2 jours"
 * @param {Boolean} semiAdaptative default false
 * If you choose to show every field but your duration is just 90sec, it will
 * show "00:01:30" and not "0 year 0 month..."
 */
const formatDuration = (duration, format, adaptative = true, language = 'en', semiAdaptative = false) => {
  if (Number.isNaN(duration) || duration === undefined || duration === null || duration < 0) {
    return '-';
  }

  const translations = {
    day: {
      en: 'day',
      es: 'día',
      fr: 'jour',
    },
    month: {
      en: 'month',
      es: 'mes',
      fr: 'mois',
    },
    year: {
      en: 'year',
      es: 'año',
      fr: 'an',
    },
  };

  const dayTrad = translations.day[language] || translations.day.en;
  const monthTrad = translations.month[language] || translations.month.en;
  const yearTrad = translations.year[language] || translations.year.en;

  const durations = Duration.fromMillis(duration).shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds', 'milliseconds').values;
  const clone = { ...durations };
  for (const [key, value] of Object.entries(clone)) {
    if (key !== 'days' && key !== 'years' && key !== 'months') {
      durations[key] = value.toString().padStart(2, '0');
    }
  }

  let res = '';
  let isParent0 = semiAdaptative || adaptative;

  if (format.year) {
    isParent0 = durations.years === 0 && isParent0;
    const separator = durations.years > 1 ? `${yearTrad}s` : yearTrad;
    res = isParent0 ? res : `${durations.years} ${separator} `;
  }

  if (format.month) {
    isParent0 = durations.months === 0 && isParent0;
    const separator = durations.months > 1 && language === 'en' ? `${monthTrad}s` : monthTrad;
    res = isParent0 ? res : `${res}${durations.months} ${separator} `;
  }

  if (format.day) {
    isParent0 = durations.days === 0 && isParent0;
    const separator = durations.days > 1 ? `${dayTrad}s` : dayTrad;
    res = isParent0 ? res : `${res}${durations.days} ${separator} `;
  }

  if (format.hour) {
    isParent0 = !semiAdaptative && (durations.hours === '00' && isParent0);
    res = isParent0 ? res : `${res}${durations.hours}${res}`;
  }
  if (format.hourSeparator) {
    isParent0 = !semiAdaptative && (durations.hours === '00' && isParent0);
    res = isParent0 ? res : `${res}${durations.hours}${format.hourSeparator}`;
  }

  if (format.min) {
    isParent0 = durations.minutes === '00' && isParent0;
    res = isParent0 ? res : `${res}${durations.minutes}`;
  }
  if (format.minSeparator) {
    isParent0 = durations.minutes === '00' && isParent0;
    res = isParent0 ? res : `${res}${durations.minutes}${format.minSeparator}`;
  }

  if (format.sec) {
    isParent0 = durations.seconds === '00' && isParent0;
    res = isParent0 ? res : `${res}${durations.seconds}`;
  }
  if (format.secSeparator) {
    isParent0 = durations.seconds === '00' && isParent0;
    res = isParent0 ? res : `${res}${durations.seconds}${format.secSeparator}`;
  }

  if (format.millisec) {
    isParent0 = durations.milliseconds === '00' && isParent0;
    res = isParent0 ? res : `${res}${durations.milliseconds}`;
  }
  if (format.millisecSeparator) {
    isParent0 = durations.milliseconds === '00' && isParent0;
    res = isParent0 ? res : `${res}${durations.milliseconds}${format.millisecSeparator}`;
  }

  return res.length ? res : '00';
};

export {
  dateToString,
  dateToStringWithoutYearAndMillis,
  dateToHHmm,
  splitTime,
  millisToHHmm,
  millisToHHmmss,
  formatDuration,
};
