import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getEmbedTokenURL } from '~services/powerBI/endpoints';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI/index';
import './ReportPage.scss';

const ReportPage = () => {
  const dispatch = useDispatch();
  const reports = useSelector((state: RootState) => state.reports.reports);

  const { selectedReport } = useParams<{ selectedReport: string; }>();
  const [iframeLoading, setLoading] = useState(true);
  const [report, setReport] = useState<any>();
  const [embedToken, setEmbedToken] = useState<string | undefined>();
  const [embedUrl, setEmbedUrl] = useState<string | undefined>();
  const [reportId, setReportId] = useState<string | undefined>();
  const [isEmbeddedReport, setIsEmbeddedReport] = useState<boolean>(false);

  useEffect(() => {
    if (reports) {
      const currentReport = reports.find(r => r.id === selectedReport);
      setReport(currentReport);
    }
  }, [reports, selectedReport]);

  useEffect(() => {
    if (report?.url) {
      const url = new URL(report.url);
      if (url.pathname.includes('/reportEmbed')) {
        setIsEmbeddedReport(true);
        const reportIdFromUrl = url.searchParams.get('reportId');
        if (reportIdFromUrl) {
          setReportId(reportIdFromUrl);
          getEmbedTokenURL(reportIdFromUrl, report?.workspace).then(res => {
            setEmbedToken(res.embedToken);
            setEmbedUrl(res.embedUrl);
            setLoading(false);
          }).catch(error => {
            console.error('Error fetching embed token:', error);
            setLoading(false);
          });
        }
      } else if (url.pathname.includes('/view')) {
        setIsEmbeddedReport(false);
        setLoading(false);
      }
    }
  }, [report]);

  // const handleExportPDF = () => {
  //   if (reportId && embedToken) {
  //     exportToPDF(reportId).then(res => {
  //       const blob = new Blob([res], { type: 'application/pdf' });
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'report.pdf';
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     }).catch(error => {
  //       console.error('Error exporting report to PDF:', error);
  //     });
  //   }
  // };

  const renderReport = () => {
    if (isEmbeddedReport && embedUrl && embedToken) {
      return (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',
            id: reportId,
            embedUrl,
            accessToken: embedToken,
            tokenType: models.TokenType.Embed,
            settings: {
              navContentPaneEnabled: false,
              filterPaneEnabled: false,
            },
          }}
          eventHandlers={
            new Map([
              ['loaded', () => setLoading(false)],
              ['error', event => console.error(event.detail)],
            ])
          }
          cssClassName="powerBiReport"
        />
      );
    } if (report?.url && !isEmbeddedReport) {
      return (
        <iframe
          title={report?.name || 'Report'}
          className="report"
          src={report?.url}
          style={{
            border: 0,
            width: '100vw',
          }}
          onLoad={() => setLoading(false)}
        />
      );
    }
    return null;
  };

  const getContents = () => (
    <PageTemplate
      footer
      pageTemplateClass="flex V justifyStart"
      noNPSPopup
      sidebar
      noHorizontalPadding
    >
      <div className="ReportPage">
        {(!report || iframeLoading) && (<Loader />)}
        {/* <Button className="exportPDF" onClick={handleExportPDF}>Export to PDF</Button> */}
        <div className="IFrame framed flex H">
          {renderReport()}
        </div>
      </div>
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[reports]}
      resourceFetchers={[
        () => dispatch(reduxOperations.reports.fetchReports() as any),
      ]}
      getContents={getContents}
    />
  );
};

export { ReportPage };
