import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { CircularChart } from '~UI/svg';
import { invertColor } from '~utils';
import TileContents from '../TileContents';

const CircularChartTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const container = useRef();
  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      ref={container}
      height={height}
      width={width}
    >
      <div className="framed flex V center">
        <CircularChart
          selectedObject={tile}
          backgroundColor={backgroundColor}
          textColor={invertColor(backgroundColor)}
          dimension={{ width, height }}
          isTile
        />
      </div>
    </TileContents>
  );
};

CircularChartTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.shape({
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
    id: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default CircularChartTile;
