/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle, FontAwesome } from '~components/UI';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { sortArray } from '~utils/sort';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { RootState } from '~services/store';
import { StopCause, ParentStopCause } from '../../pages/Config/Machines/stopCauses/StopCause.interface';
import { PerformanceCause, ParentPerformanceCause } from '../../pages/Config/Machines/performanceCauses/PerformanceCause.interface';

const FormParetoChart = ({ selectedTile }) => {
  const { t } = useTranslation();
  const language = useSelector((state: RootState) => state.views.language);

  const machines = useSelector((state: RootState) => state.machines);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));
  const [intervalType, setIntervalType] = useState(selectedTile.intervalType);

  const [showArrows, setShowArrows] = useState(selectedTile.showArrows);
  const [nbDisplayedRows, setNbDisplayedRows] = useState(selectedTile.nbDisplayedRows);

  function handleShowArrows() {
    setShowArrows(!showArrows);
  }
  const [machineId, setMachineId] = useState(selectedTile.machineId);
  const [stopCausesState, setStopCausesState] = useState<StopCause[]>([]);
  const [performanceCausesState, setPerformanceCausesState] = useState<PerformanceCause[]>([]);
  const [hiddenPerformanceCausesState, setHiddenPerformanceCausesState] = useState<string[]>([]);
  const [hiddenStopCausesState, setHiddenStopCausesState] = useState<string[]>([]);
  const [isSubMenuState, setIsSubMenuState] = useState(false);
  const [causeType, setCauseType] = useState(selectedTile.causeType || 'stopCause');
  const [menuStack, setMenuStack] = useState<any>([]);

  const causeTypes = [
    { label: t('stopCause'), value: 'stopCause' },
    { label: t('performanceCause'), value: 'performanceCause' },
  ];

  const getCausesState = () => (causeType === 'stopCause' ? stopCausesState : performanceCausesState);
  const setHiddenCausesState = newState => (causeType === 'stopCause' ? setHiddenStopCausesState(newState) : setHiddenPerformanceCausesState(newState));
  const getHiddenCausesState = () => (causeType === 'stopCause' ? hiddenStopCausesState : hiddenPerformanceCausesState);
  const setCausesState = newState => (causeType === 'stopCause' ? setStopCausesState(newState) : setPerformanceCausesState(newState));

  useEffect(() => {
    const selectedMachine = machines.find(m => m.id === machineId);
    if (causeType === 'stopCause') {
      setHiddenStopCausesState(selectedTile.machineId === machineId ? selectedTile.hiddenStopCauses : []);
      setCausesState(selectedMachine?.stopCauses);
    } else if (causeType === 'performanceCause') {
      setHiddenPerformanceCausesState(selectedTile.machineId === machineId ? selectedTile.hiddenPerformanceCauses : []);
      setCausesState(selectedMachine?.performanceCauses);
    }
    setIsSubMenuState(false);
  }, [machineId, causeType]);

  const handleCauseToggle = cause => {
    const hiddenCausesState = getHiddenCausesState();
    if (hiddenCausesState?.includes(cause.id)) {
      setHiddenCausesState(hiddenCausesState.filter(id => id !== cause.id));
    } else {
      setHiddenCausesState([...hiddenCausesState, cause.id]);
    }
  };

  const handleCauseSelection = cause => {
    const selectedMachine = machines.find(m => m.id === machineId);
    const causesState = getCausesState();
    if (!cause) {
      setIsSubMenuState(false);
      setCausesState(selectedMachine?.stopCauses);
      setMenuStack([]);
    } else if (cause?.subMenu?.length) {
      setIsSubMenuState(true);
      setMenuStack([...menuStack, causesState]);
      setCausesState(cause.subMenu);
    } else {
      handleCauseToggle(cause);
    }
  };

  const handleBackButton = () => {
    if (menuStack.length > 0) {
      const newMenuStack = [...menuStack];
      const previousMenu = newMenuStack.pop();
      setMenuStack(newMenuStack);
      setCausesState(previousMenu);
      setIsSubMenuState(newMenuStack.length > 0);
    }
  };

  const causesState = getCausesState();
  const hiddenCausesState = getHiddenCausesState();

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        defaultValue={machineId}
        onChange={e => setMachineId(e)}
        placeholder={t('select')}
      />
      <div className="inputTitle">{t('causeType')}</div>
      <ButtonGroup
        buttons={causeTypes}
        onChange={setCauseType}
        value={causeType}
      />
      <input type="hidden" name="causeType" value={causeType} />
      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={intervalType}
        intervalTypeInput
        changeIntervalType={value => setIntervalType(value)}
      />
      <input type="hidden" name="intervalType" value={intervalType} />
      <div className="inputTitle">
        {t('nbDisplayedRows')}
      </div>
      <input
        type="number"
        name="nbDisplayedRows"
        min="1"
        step="1"
        defaultValue={nbDisplayedRows || 1}
        onChange={e => setNbDisplayedRows(e.target.value)}
      />
      <div className="inputTitle">
        {t('showTimeNavigationArrows')}
        &nbsp;
        <CheckboxToggle
          name="showArrows"
          controlledCheck={showArrows}
          onChange={handleShowArrows}
        />
      </div>
      { machineId && (
        <div className="inputTitle">
          {t('stopCausesShown')}
        </div>
      )}
      <input type="hidden" name="array:hiddenStopCauses" value={hiddenStopCausesState} />
      <input type="hidden" name="array:hiddenPerformanceCauses" value={hiddenPerformanceCausesState} />
      {
        isSubMenuState && (
          <FontAwesome
            icon="arrow-left"
            className="backButtonArrow"
            style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
            onClick={handleBackButton}
          />
        )
      }
      {
        causesState?.map(cause => {
          const badgeCount = causeType === 'stopCause'
            ? (cause as ParentStopCause).subMenu?.length
            : (cause as ParentPerformanceCause).subMenu?.length;
          let causeName;
          switch (language) {
            case 'en':
              causeName = cause.nameEN || cause.name;
              break;
            case 'fr':
              causeName = cause.nameFR || cause.name;
              break;
            case 'es':
              causeName = cause.nameES || cause.name;
              break;
            default:
              causeName = cause.name;
          }

          return (
            <StopCauseIcon
              key={cause.id}
              option={{
                ...cause,
                name: causeName,
                badgeCount,
              }}
              small
              className={(hiddenCausesState?.includes(cause.id)) ? 'Unselected' : 'Selected'}
              onClick={() => handleCauseSelection(cause)}
            />
          );
        })
      }
    </div>
  );
};

FormParetoChart.propTypes = {
  selectedTile: PropTypes.object,
};
FormParetoChart.defaultProps = {
  selectedTile: {},
};

export { FormParetoChart };
