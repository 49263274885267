import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from '~components/Pages';
import { DefaultThemedButton, ResourcesHandler } from '~UI';
import { reduxOperations } from '~services';
import { configurationFeature } from '~utils/featureToggles';
import TriggersPage from './TriggersPage';
import { Page401Unauthorized } from '../../ErrorPages';
import './RuleEnginePages.scss';

const RuleEnginePage = () => {
  const dispatch = useDispatch();

  const actions = useSelector(state => state.actions);
  const triggers = useSelector(state => state.triggers);
  const tags = useSelector(state => state.tags.tags);
  const machines = useSelector(state => state.machines);
  const streams = useSelector(state => state.streams);
  const stopwatches = useSelector(state => state.stopwatches);
  const users = useSelector(state => state.users.users);
  const variables = useSelector(state => state.variables);
  const operations = useSelector(state => state.operations);
  const { t } = useTranslation();

  const getPage = () => (
    configurationFeature.isUserAllowedAccessRuleEngine() ? (
      <PageTemplate
        pageId="RuleEnginePage"
        sidebar
        rightButtons={(
          <div className="rulesToActionsContainer">
            <DefaultThemedButton
              key="btnAction"
              content={(
                <Link
                  key="/config/actions"
                  to="/config/actions"
                  className="rulesToActionsLink"
                >
                  {t('actionsList')}
                </Link>
              )}
              isActive={false}
              className="rulesToActionsButton"
            />
          </div>
        )}
      >
        <TriggersPage />
      </PageTemplate>
    ) : (
      <Page401Unauthorized />
    )
  );

  return (
    <ResourcesHandler
      getContents={getPage}
      pageId="RuleEnginePage"
      resources={[actions, triggers, streams, stopwatches, users, variables, machines, tags, operations]}
      resourceFetchers={[
        () => dispatch(reduxOperations.actions.fetchActions()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.tags.fetchTags()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.stopwatches.fetchStopwatches()),
        () => dispatch(reduxOperations.users.fetchUsers()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
      ]}
    />
  );
};

export default RuleEnginePage;
