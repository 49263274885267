import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '~UI';
import { useOnClickOutside } from '~utils/hooks';

import { stopPropagation } from '~utils';
import { isOutOfViewport } from './utils';

import './tileActions.scss';

function BasicCard({
  card, duplicateAction, duplicateToAction,
}) {
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef();
  const dropdownRef = useRef(null);
  useOnClickOutside(ref, () => setShowDropdown(false));

  useEffect(() => {
    const { bottom, right } = isOutOfViewport(dropdownRef.current);
    if (bottom) {
      dropdownRef.current.style.bottom = 0;
      dropdownRef.current.style.top = 'unset';
      dropdownRef.current.style.marginBottom = '60px';
    } else if (right) {
      dropdownRef.current.style.right = 0;
      dropdownRef.current.style.left = 'unset';
    } else {
      dropdownRef.current.style.bottom = 'unset';
      dropdownRef.current.style.top = 0;
      dropdownRef.current.style.marginBottom = '0';
    }
  }, [showDropdown]);

  return (
    <div className="cardsActions" ref={ref}>
      <FontAwesome icon="clone" onClick={e => { setShowDropdown(!showDropdown); stopPropagation(e); }} />
      <div className="cardDropdown" style={{ display: !showDropdown && 'none' }} ref={dropdownRef}>
        {duplicateAction && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
              <div className="cardDropdownItem" onClick={() => duplicateAction(card)}>
                <span className="cardDropdownItemText">{t('duplicateHere')}</span>
              </div>
            </div>
          </>
        )}
        {duplicateToAction && (
          <>
            <hr className="cardDropdownDivider" />
            <div className="cardDropdownItemContainer" onClick={() => setShowDropdown(false)}>
              <div className="cardDropdownItem" onClick={() => duplicateToAction()}>
                <span className="cardDropdownItemText">{t('duplicateTo')}</span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

BasicCard.propTypes = {
  card: PropTypes.object.isRequired,
  duplicateAction: PropTypes.func.isRequired,
  duplicateToAction: PropTypes.func.isRequired,
};

export default BasicCard;
