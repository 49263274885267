import store from '~services/store';
import idToReadableExpr from './idToReadableExpr';

function idToReadable(expression) {
  const storeState = store.getState();
  const { machines, streams, variables } = storeState;

  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const properties = [].concat(...streams.map(s => s.properties || []));
  const allVariables = [...kpis, ...properties, ...variables];

  return idToReadableExpr(expression, allVariables);
}

export { idToReadable };
