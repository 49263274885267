import API from '~services/endpoints';

import { fetchOnceOperation } from '../fetchOperation';
import {
  getImagesRequest,
  getImagesResponse,
  addImageRequest,
  addImageResponse,
  deleteImageRequest,
  deleteImageResponse,
} from './actions';

function fetchImages() {
  return fetchOnceOperation(
    getImagesRequest,
    getImagesResponse,
    API.getImages,
    state => state.images.images,
  );
}

function addImage(image) {
  return dispatch => {
    dispatch(addImageRequest(image));
    return API.addImage(image)
      .then(res => dispatch(addImageResponse(res.image)));
  };
}

function deleteImage(id) {
  return dispatch => {
    dispatch(deleteImageRequest(id));
    return API.deleteImage(id)
      .then(res => dispatch(deleteImageResponse(res.id)));
  };
}

export default {
  fetchImages,
  addImage,
  deleteImage,
};
