import { AudioAction, AudioActionTypes } from './actions';
import { Audio } from './types';

export type AudioReducerState = Audio[];

export default function audios(state: AudioReducerState = [], action: AudioAction) {
  switch (action.type) {
    case AudioActionTypes.ADD_AUDIO:
      return [...state, action.payload];
    case AudioActionTypes.REMOVE_AUDIO:
      return state.filter(audio => audio.id !== action.payload);
    default:
      return state;
  }
}
