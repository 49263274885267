import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RootState } from '~services/store';
import { TextInput, FontAwesome } from '~UI/index';
import { idToReadableExpr } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './ActionForm.scss';

const addVariablesToSelectGroupedOptions = (variables, groupedOptions) => {
  const variablesList = variables.map(variable => (
    {
      // Words in value can be searched with the select textbox
      value: variable.id,
      label: variable.variable,
    }
  ));

  groupedOptions.push({
    label: 'Variables',
    options: variablesList,
  });
};

const addStreamsToSelectGroupedOptions = (streams, groupedOptions) => {
  streams.forEach(stream => {
    const propertiesList = stream.properties.map(property => (
      {
        // Words in value can be searched with the select textbox
        value: property.id,
        label: property.variable,
      }
    ));
    groupedOptions.push({
      label: `Stream - ${stream.name}`,
      options: propertiesList,
    });
  });
};

const VariableActionForm = ({ action }) => {
  const { t } = useTranslation();

  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);
  const machines = useSelector((state: RootState) => state.machines);

  const [groupedOptions, setGroupedOptions] = useState([]);
  const [valueId, setValueId] = useState(action.params.valueId);
  const [value, setValue] = useState(action.params.value);

  useEffect(() => {
    addVariablesToSelectGroupedOptions(variables, groupedOptions);
    addStreamsToSelectGroupedOptions(streams, groupedOptions);
    // Do not allow the change of a machine kpi value

    setGroupedOptions(groupedOptions);
  }, []);

  const handleSelectionChange = selectValue => {
    setValueId(selectValue);
  };

  const idToReadable = valueArg => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    return idToReadableExpr(valueArg, inputsAndVariables) || valueArg;
  };

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  return (
    <>
      <div className="inputTitle">{t('variableName')}</div>
      <Select
        name="valueId"
        onChange={handleSelectionChange}
        options={groupedOptions}
        value={valueId}
        placeholder={t('selectAVariable')}
      />
      <div className="inputTitle">{t('newValue')}</div>
      <TextInput
        name="value"
        options={inputsAndVariables}
        trigger="$"
        value={value ? idToReadable(value) : null}
        className="fullwidth"
        onChange={e => setValue(e)}
        placeholder={t('triggerVariableList')}
      />
      <div style={{ marginBottom: '8px' }}>
        <FontAwesome icon="info-circle" />
            &nbsp;&nbsp;
        {t('stringInVariables')}
      </div>
    </>
  );
};

VariableActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      valueId: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  }),
};
VariableActionForm.defaultProps = {
  action: {
    params: {
      valueId: null,
      value: null,
    },
  },
};

export default VariableActionForm;
