import { fetchOnceOperation } from '../fetchOperation';
import {
  getFormsRequest,
  getFormsResponse,
} from './actions';
import API from '~services/endpoints';

function fetchForms() {
  return fetchOnceOperation(
    getFormsRequest,
    getFormsResponse,
    API.getForms,
    state => state.forms,
  );
}

export default {
  fetchForms,
};
