import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import machineProperties from '~components/forms/machineProperties.json';
import { CancelButton, SubmitButton } from '~UI';
import { reduxOperations } from '~services';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';
import { verifyVariableName } from '~utils/parser';
import { FontAwesome } from '~components/UI';

const propTypes = {
  machineId: PropTypes.string.isRequired,
  modifiedItemId: PropTypes.string,
  goBack: PropTypes.func.isRequired,
};

const defaultProps = {
  modifiedItemId: null,
};

const MachineKPIsForm = ({ machineId, modifiedItemId, goBack }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);

  const machine = machines.find(elem => elem.id === machineId) || {};
  const kpis = machine.kpis || [];
  const [kpi, setKpi] = useState(kpis.find(elem => elem.id === modifiedItemId) || {});
  const machinePropertiesOptions = machineProperties
    .filter(property => property.property !== 'stopCauseDowntime' && property.property !== 'performanceCauseDropTime')
    .map(property => ({
      value: property.property,
      label: t(property.name),
    }));

  const kpiCanBeInstantaneous = name => {
    switch (name) {
      // case 'quantityLeft':
      // case 'timeLeft':
      // case 'projectedRemainingTime':
      case 'unitCompletionTime':
      case 'hourlyPlannedOutputCount':
      case 'minutelyPlannedOutputCount':
      case 'requiredQuantity':
      case 'currentWorkOrder':
      case 'currentSKUNumber':
      case 'currentOperation':
      case 'currentOperator':
      case 'currentStopCause':
        return true;
      default:
        return false;
    }
  };

  const kpiCanNotBeRanged = name => {
    switch (name) {
      case 'currentWorkOrder':
      case 'currentSKUNumber':
      case 'currentOperation':
      case 'currentOperator':
      case 'currentStopCause':
        return true;
      default:
        return false;
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formData = getFormData('KPI__form');
    if (verifyVariableName(formData.variable)) {
      return;
    }

    const properties = [].concat(...streams.map(s => s.properties));
    const machineKPIs = [].concat(...machines.map(m => m.kpis || []));
    const all = [...properties, ...variables, ...machineKPIs];
    const sameName = all.find(variable => {
      if (variable.variable === formData.variable) {
        return !modifiedItemId || modifiedItemId !== variable.id;
      }
      return false;
    });
    if (sameName) {
      showError(t('variableNameExistsError'));
      return;
    }

    if (modifiedItemId) {
      dispatch(reduxOperations.machines.updateKPI(machineId, modifiedItemId, formData))
        .then(() => {
          showSuccess(t('showSuccessUpdated'));
          goBack();
        })
        .catch(error => showError(error.status));
    } else {
      dispatch(reduxOperations.machines.addKPI(machineId, formData))
        .then(() => {
          showSuccess(t('showSuccessCreated'));
          goBack();
        })
        .catch(error => showError(error.status));
    }
  };

  return (
    <form
      id="KPI__form"
      onSubmit={handleSubmit}
    >
      <div className="inputTitle">{t('machineKPI')}</div>
      <Select
        name="name"
        className="fullwidth"
        value={kpi.name}
        options={machinePropertiesOptions}
        onChange={name => setKpi({ ...kpi, name })}
      />
      <div className="inputTitle">{t('variable')}</div>
      <input
        type="text"
        name="variable"
        className="fullwidth"
        value={kpi.variable}
        onChange={e => setKpi({ ...kpi, variable: e.target.value })}
      />
      <div className="inputTitle">{t('timePeriod')}</div>
      <Select
        name="intervalType"
        className="fullwidth"
        value={kpi.intervalType}
        options={[
          ...(kpiCanBeInstantaneous(kpi.name) ? [{ label: t('instant'), value: 'instant' }] : []),
          ...(kpiCanNotBeRanged(kpi.name) ? []
            : [
              { label: t('1h'), value: '1h' },
              { label: t('4h'), value: '4h' },
              { label: t('8h'), value: '8h' },
              { label: t('shift'), value: 'shift' },
              { label: t('24h'), value: '24h' },
            ]),
        ]}
        onChange={intervalType => setKpi({ ...kpi, intervalType })}
      />
      <div className="inputTitle">{t('refreshInterval')}</div>
      <div className="kpiRefreshText">
        <FontAwesome icon="info-circle" />
        &nbsp;&nbsp;
        {t('refreshIntervalInfo')}
      </div>
      <Select
        name="refreshInterval"
        className="fullwidth"
        defaultValue={kpi.refreshInterval}
        options={[
          { label: t('1min'), value: '1min' },
          { label: t('1h'), value: '1h' },
        ]}
      />
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <CancelButton onClick={goBack} />
        <SubmitButton label={modifiedItemId ? t('modify') : t('add')} />
      </div>
    </form>
  );
};

MachineKPIsForm.propTypes = propTypes;
MachineKPIsForm.defaultProps = defaultProps;

export default MachineKPIsForm;
