import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Nav, NavItem } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import deepEqual from 'deep-equal';
import PhoneInput from 'react-phone-input-2';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import countries from './countries.json';

import {
  DeleteButton, CancelButton, SubmitButton, FontAwesome, DefaultThemedButton,
} from '~UI';
import { reduxOperations, reducersTypes } from '~services';
import { getFormData } from '~utils';
import { showSuccess, showError } from '~utils/toast';
import { UserRole } from '~services/users/types';

import UserPermissionForm from '../UserPermissionsForm/UserPermissionForm';
import UserAppKeysForm from '../UserAppKeysForm/UserAppKeysForm';
import { PasswordRestrictions, verifyPassword } from '../forms/PasswordRestrictions';

import './UserModificationForm.scss';
import 'react-phone-input-2/lib/high-res.css';

const defaultProps = {
  accountType: undefined,
};

const propTypes = {
  accountType: PropTypes.string.required,
  isCreating: PropTypes.bool.isRequired,
  users: reducersTypes.users.users.isRequired,
  modifiedItemId: PropTypes.string,
  selectedUser: reducersTypes.users.selectedUser.isRequired,
  addUser: reduxOperations.users.addUser.isRequired,
  updateUser: reduxOperations.users.updateUser.isRequired,
  deleteUser: reduxOperations.users.updateUser.isRequired,
  sendWelcomeEmail: reduxOperations.users.sendWelcomeEmail.isRequired,
  isProfile: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const UserRoleTranslationKeys = {
  [UserRole.DIRECTOR_MANAGER]: 'directorManager',
  [UserRole.SUPERVISION]: 'supervision',
};

class UserModificationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: props.isCreating,
      navSelected: 'information',
      passwordConfirmed: props.isCreating,
      password1: '',
      password2: '',
      init: false,
      showPasswords: false,
      showCurrentPassword: false,
      phoneNumber: '',
      country: '',
      language: 'fr',
      role: '',
      accountType: props.accountType,
    };
  }

  componentDidMount() {
    const { users, modifiedItemId, selectedUser } = this.props;
    const user = users && users.find(u => u.id === modifiedItemId);
    if (user) {
      this.setState({
        userPermissions: user.permissions,
        phoneNumber: user.phoneNumber,
        country: user.country,
        language: user.language,
        role: user.role,
      });
    } else {
      this.setState({
        userPermissions: selectedUser.permissions,
        phoneNumber: selectedUser.phoneNumber,
        country: selectedUser.country,
        language: selectedUser.language,
        role: selectedUser.role,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isCreating, users, modifiedItemId, selectedUser } = this.props;
    const { init } = this.state;
    const user = users && users.find(u => u.id === modifiedItemId);
    const prevUser = prevProps.users && prevProps.users.find(u => u.id === modifiedItemId);

    if ((user && !init) || (prevUser && user && !deepEqual(user.permissions, prevUser.permissions, { strict: true }))) {
      this.setState({
        userPermissions: user.permissions || { contents: [], features: [] },
        changePassword: isCreating,
        navSelected: 'information',
        passwordConfirmed: isCreating,
        password1: '',
        password2: '',
        init: true,
        phoneNumber: user.phoneNumber,
        role: user.role,
        country: user.country,
      });
    }

    if (!user && !init) {
      // reset the form
      this.setState({
        userPermissions: { contents: [], features: [] },
        changePassword: isCreating,
        navSelected: 'information',
        passwordConfirmed: isCreating,
        password1: '',
        password2: '',
        init: true,
        phoneNumber: selectedUser.phoneNumber,
        role: selectedUser.role,
        country: selectedUser.country,
      });
    }
  }

  updateFormPermissions = userPermissions => this.setState({ userPermissions });

  handleSubmit = e => {
    e.preventDefault();
    const {
      modifiedItemId, addUser, updateUser, t, isProfile, isCreating,
    } = this.props;
    const {
      changePassword, userPermissions, passwordConfirmed, country, accountType, language, role,
    } = this.state;

    if (modifiedItemId && changePassword && !passwordConfirmed) {
      showError(t('passwordsDoNotMatch'));
      return;
    }

    const formData = getFormData('userForm');

    if (!formData.name) {
      showError(t('missingName'));
      return;
    }
    if (!formData.password && !modifiedItemId && accountType === 'ii') {
      showError(t('missingPassword'));
      return;
    }
    if (formData.password && verifyPassword(formData.password)) {
      return;
    }
    if (!formData.username) {
      showError(t('missingUsername'));
      return;
    }
    if (isCreating && !formData.role) {
      showError(t('missingRole'));
      return;
    }

    const user = {
      accountType,
      name: formData.name,
      username: formData.username,
      phoneNumber: formData.phoneNumber.replaceAll('-', '').replaceAll('(', '').replaceAll(')', '').replaceAll('+', ''),
      country,
      language,
      role,
      ...(changePassword && passwordConfirmed && { password: formData.password }),
      ...(formData.currentPassword && { currentPassword: formData.currentPassword }),
    };

    if (!isProfile) {
      user.isVerified = true;
      user.permissions = userPermissions;
    }

    const operation = modifiedItemId ? updateUser : addUser;
    operation(user, (modifiedItemId || 'UserModificationForm'))
      .then(() => {
        showSuccess(t('success'));
        this.handleHide();
      })
      .catch(error => {
        showError(error.status);
      });
  };

  infoTab = user => {
    const { t, isProfile, isCreating, sendWelcomeEmail } = this.props;
    const {
      showPasswords, phoneNumber, accountType, language, role,
    } = this.state;

    return (
      <div>
        <input
          type="hidden"
          name="id"
          defaultValue={user.id}
          // eslint-disable-next-line react/no-unused-class-component-methods
          ref={input => this.input = input}
        />
        <div className="inputTitle">{t('name')}</div>
        <input
          name="name"
          defaultValue={user.name}
          className="fullwidth"
          type="text"
        />

        <div className="inputTitle">{`${t('username')} / ${t('email')}`}</div>
        <input
          name="username"
          defaultValue={user.username}
          className="fullwidth"
          type="text"
        />
        <div className="inlineInput">
          <div>
            <div className="inputTitle" style={{ marginBottom: '8px' }}>{t('phoneNumber')}</div>
            <PhoneInput
              inputProps={{
                name: 'phoneNumber',
              }}
              country="ca"
              value={phoneNumber}
              onlyCountries={countries}
              onChange={(phone, country) => this.setState({ phoneNumber: phone, country: country.countryCode })}
            />
          </div>
          <div>
            <div className="inputTitle">{t('communicationLanguage')}</div>
            <Select
              name="language"
              value={language}
              onChange={newLanguage => this.setState({ language: newLanguage })}
              options={[
                {
                  label: 'Français',
                  value: 'fr',
                },
                {
                  label: 'English',
                  value: 'en',
                },
              ]}
              placeholder={t('selectOption')}
              style={{ width: '200px' }}
            />
          </div>
          {!isProfile && (
            <div>
              <div className="inputTitle">{t('role')}</div>
              <Select
                name="role"
                value={role}
                onChange={newRole => this.setState({ role: newRole })}
                options={Object.entries(UserRole).map(([_key, value]) => ({
                  label: t(UserRoleTranslationKeys[value]),
                  value,
                }))}
                placeholder={t('selectOption')}
                style={{ width: '230px' }}
              />
            </div>

          )}
        </div>
        {
          !isCreating && user.welcomeEmailSent === false && (
            <DefaultThemedButton
              onClick={() => {
                sendWelcomeEmail(user.id);
                this.handleHide();
              }}
              content={t('sendWelcomeEmail')}
              className="email-button"
            />
          )
        }
        <div />

        {isCreating && accountType === 'ii' && (
          <>
            <div className="inputTitle">
              {t('password')}
              &nbsp;&nbsp;
              <FontAwesome
                icon={showPasswords ? 'eye-slash' : 'eye'}
                style={{ cursor: 'pointer' }}
                onClick={() => this.setState({ showPasswords: !showPasswords })}
              />
            </div>
            <input
              type={showPasswords ? 'text' : 'password'}
              name="password"
              className="fullwidth"
            />
            <PasswordRestrictions />
          </>
        )}
      </div>
    );
  };

  handlePasswordConfirmation = (e, inputId) => {
    const { password1, password2 } = this.state;

    if (inputId === '1') {
      this.setState({
        password1: e.target.value,
        passwordConfirmed: password2 === e.target.value,
      });
    } else {
      this.setState({
        password2: e.target.value,
        passwordConfirmed: password1 === e.target.value,
      });
    }
  };

  securityTab = () => {
    const { t, isProfile } = this.props;
    const {
      changePassword, passwordConfirmed, password1, password2, showPasswords, showCurrentPassword,
    } = this.state;

    return (
      <>
        <button
          type="button"
          className={`actionBtn ${changePassword ? 'actionBtnON' : ''}`}
          onClick={this.showPasswordInputs}
        >
          {t('editPassword')}
        </button>
        {
          changePassword && (
            <>
              <PasswordRestrictions />
              {isProfile && (
                <>
                  <div className="inputTitle">
                    {t('currentPassword')}
                    &nbsp;&nbsp;
                    <FontAwesome
                      icon={showCurrentPassword ? 'eye-slash' : 'eye'}
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.setState({ showCurrentPassword: !showCurrentPassword })}
                    />
                  </div>
                  <input
                    type={showCurrentPassword ? 'text' : 'password'}
                    name="currentPassword"
                    className="fullwidth"
                  />
                </>
              )}
              <div className="inputTitle">
                {t('newPassword')}
                &nbsp;&nbsp;
                <FontAwesome
                  icon={showPasswords ? 'eye-slash' : 'eye'}
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.setState({ showPasswords: !showPasswords })}
                />
              </div>
              <input
                type={showPasswords ? 'text' : 'password'}
                className="fullwidth"
                autoComplete="new-password"
                onChange={e => this.handlePasswordConfirmation(e, '1')}
                value={password1}
              />
              <div className="inputTitle">
                {t('confirmPassword')}
                <span style={{ display: passwordConfirmed ? 'initial' : 'none', marginLeft: '5px' }}>
                  <FontAwesome
                    icon="check"
                    color="green"
                  />
                </span>
              </div>
              <input
                type={showPasswords ? 'text' : 'password'}
                name="password"
                className="fullwidth"
                autoComplete="new-password"
                onChange={e => this.handlePasswordConfirmation(e, '2')}
                value={password2}
              />
            </>
          )
        }
      </>
    );
  };

  showPasswordInputs = () => {
    this.setState(state => ({ changePassword: !state.changePassword }));
  };

  handleSelectNav = eventKey => {
    this.setState({ navSelected: eventKey });
  };

  handleHide = () => {
    const { onHide } = this.props;
    this.setState({ init: false });
    return onHide();
  };

  handleDeleteUser = userId => {
    const { deleteUser, onHide } = this.props;
    deleteUser(userId);
    onHide();
  };

  render() {
    const {
      t, modifiedItemId, users, isCreating, isProfile, selectedUser,
    } = this.props;
    const { userPermissions, navSelected } = this.state;
    let user = null;
    if (isProfile) {
      user = selectedUser;
    } else if (users) {
      user = users.find(u => u.id === modifiedItemId);
    }
    if (!user) {
      user = {
        id: '',
        name: '',
        username: '',
      };
    }
    if (!user.accountType) {
      user.accountType = 'ii';
    }
    return (
      <div className="userModificationForm">
        <form
          id="userForm"
          onSubmit={this.handleSubmit}
        >
          <div>
            <Nav bsStyle="tabs" activeKey={navSelected} onSelect={eventKey => this.handleSelectNav(eventKey)}>
              <NavItem eventKey="information">
                {t('information')}
              </NavItem>
              {!isProfile && (
                <NavItem eventKey="permissions">
                  {t('permissions')}
                </NavItem>
              )}
              {!isCreating && !isProfile && (
                <NavItem eventKey="appKeys">
                  {t('appKeys')}
                </NavItem>
              )}
              {!isCreating && user.accountType === 'ii' && (
                <NavItem eventKey="security">
                  {t('security')}
                </NavItem>
              )}
            </Nav>
          </div>
          <div>
            <div style={{ display: navSelected === 'information' ? 'block' : 'none' }}>
              {this.infoTab(user)}
            </div>
            {
              !isProfile && (
                <>
                  <div style={{ display: navSelected === 'permissions' ? 'block' : 'none' }}>
                    <UserPermissionForm
                      user={user}
                      userPermissions={userPermissions}
                      updateFormPermissions={userPerms => this.updateFormPermissions(userPerms)}
                    />
                  </div>
                  {!isCreating && (
                    <div style={{ display: navSelected === 'appKeys' ? 'block' : 'none' }}>
                      <UserAppKeysForm user={user} />
                    </div>
                  )}
                </>
              )
            }
            {!isCreating && (
              <div style={{ display: navSelected === 'security' ? 'block' : 'none', color: 'white' }}>
                {this.securityTab()}
              </div>
            )}
          </div>
          <div className="userModificationFormFooter">
            {
              !isCreating ? (
                <DeleteButton handleDelete={() => this.handleDeleteUser(modifiedItemId)} askConfirmation />
              ) : <div />
            }
            <div>
              <CancelButton onClick={this.handleHide} />
              <SubmitButton
                label={isCreating ? t('add') : t('modify')}
                onClick={this.handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

UserModificationForm.defaultProps = defaultProps;
UserModificationForm.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    featureToggles: state.settings.settings.featureToggles,
    users: state.users.users,
    selectedUser: state.users.selectedUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    addUser: reduxOperations.users.addUser,
    updateUser: reduxOperations.users.updateUser,
    fetchUserPermissions: reduxOperations.users.fetchUserPermissions,
    deleteUser: reduxOperations.users.deleteUser,
    sendWelcomeEmail: reduxOperations.users.sendWelcomeEmail,
    fetchSettings: reduxOperations.settings.fetchSettings,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserModificationForm));
