import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '~components/SelectionGrid';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import directLinks from '~utils/directLinks';
import { configurationFeature } from '~utils/featureToggles';
import { showSuccess } from '~utils/toast';

import TopviewPopUpForm from './TopviewPopUpForm';

const TopviewSelectionGrid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tags = useSelector((state: RootState) => state.tags.tags);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const users = useSelector((state: RootState) => state.users.users);

  const updateUsersPermissions = (id, usersArg) => {
    if (configurationFeature.isUserAllowedAccessUsers()) {
      const permissionsData = {
        users: usersArg.map(user => user.value),
        content: {
          type: 'Topview',
          id,
        },
      };
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleDuplication = topview => {
    let usersWithPermission;

    if (topview.id) {
      usersWithPermission = users
        .reduce((result, user) => {
          const userHasTopviewAccess = user.permissions.contents.some(content => content.type === 'Topview' && content.id === topview.id);
          return userHasTopviewAccess ? [...result, { value: user.id }] : result;
        }, []);
    }

    const newTopview = {
      ...omit(topview, ['id']),
      name: `${topview.name} (${t('Copy')})`,
      widgets: topview.widgets.map(widget => ({
        ...omit(widget, ['id']),
        rules: (widget.rules || []).map(r => omit(r, ['id'])),
      })),
      tags: (topview.tags || [])
        .map(tagId => tags.find(tag => tagId === tag.id))
        .filter(tag => !!tag),
    };

    reduxOperations.topviews.createTopview(newTopview)(dispatch).then(res => {
      showSuccess(t('showSuccessCreated'));
      updateUsersPermissions(res.payload.topview.id, usersWithPermission);
    });
  };

  const getTopviews = () => {
    const topviewsItems = topviews.map(topview => ({
      ...topview,
      createdByUser: users.find(user => user.id === topview.createdBy)?.name,
      modifiedByUser: users.find(user => user.id === topview.modifiedBy)?.name,
    }));
    return topviewsItems;
  };

  const isUserAllowedTopviewConfig = configurationFeature.isUserAllowedAccessTopviews();

  return (
    <SelectionGrid
      contentType="Topview"
      deleteFunction={isUserAllowedTopviewConfig
        && (topviewId => reduxOperations.topviews.deleteTopview(topviewId)(dispatch))}
      directLinkType={directLinks.topview}
      EditForm={isUserAllowedTopviewConfig && TopviewPopUpForm}
      handleDuplication={isUserAllowedTopviewConfig && handleDuplication}
      isUserAllowedToConfig={isUserAllowedTopviewConfig}
      items={getTopviews()}
      redirectRessource="topview"
      storageType="K2_topviews"
      supportFavorites
      tagInfoText={t('tagInfoTopview')}
      titleCreateCard={t('addTopView')}
    />
  );
};

export default TopviewSelectionGrid;
