import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { PageTemplate } from '~components/Pages';
import { CreationHoverRoundButton, FontAwesome, ResourcesHandler } from '~UI';
import Topview from './Topview';
//  import JSONEditor from '~components/CodeEditor/JSONEditor';
import { TopviewWidgetEditionForm } from '../TopviewWidget';
import { reduxOperations } from '~services';
import { configurationFeature } from '~utils/featureToggles';
import directLinks from '~utils/directLinks';

const TopviewPage = () => {
  const dispatch = useDispatch();

  const isInConfigurationMode = useSelector(state => state.views.isInConfigurationMode);
  const topviews = useSelector(state => state.topviews.topviews);
  const resources = useSelector(state => [
    state.folders,
    state.forms,
    state.images.images,
    state.machines,
    state.operations.operations,
    state.operators,
    state.shifts.shifts,
    state.stopwatches.stopwatches,
    state.streams,
    state.triggers,
    state.variables,
  ]);

  //  const [showJSONEditor, setShowJSONEditor] = useState(false);

  const location = useLocation();

  const getPage = ([topviewsArg]) => {
    const topviewId = isInConfigurationMode ? location.pathname.split('/')[3]
      : location.pathname.split('/')[2];
    const topview = topviewsArg.find(tv => tv.id === topviewId);

    const creationButton = isInConfigurationMode ? (
      <CreationHoverRoundButton
        position={1}
        pullDown
        Modal={{
          Component: TopviewWidgetEditionForm,
          props: { topviewId },
        }}
      />
    )
      : null;

    // const jsonButton = isInConfigurationMode
    //  && configurationFeature.isUserAllowedAccessAdmin() && !showJSONEditor ? (
    //   <JSONButton
    //     position={2}
    //     pullDown
    //     onClick={() => setShowJSONEditor(prev => !prev)}
    //   />
    // ) : null;
    const rightJSONEditor = isInConfigurationMode
      && configurationFeature.isUserAllowedAccessAdmin() ? (
        <textarea />
      ) : null;
    const modifyIcon = configurationFeature.isUserAllowedAccessTopviews() ? (
      <Link to={location.pathname.startsWith('/config/')
        ? directLinks.topview(topviewId)
        : directLinks.configTopview(topviewId)}
      >
        <FontAwesome icon="edit" style={{ color: isInConfigurationMode ? '#0078FF' : null }} />
      </Link>
    ) : null;

    return (
      <PageTemplate
        rightButtons={[creationButton]}
        right={[rightJSONEditor]}
        modifyIcon={modifyIcon}
        noScrollBar
        sidebar
        noHorizontalPadding
      >
        <Topview topview={topview} />
      </PageTemplate>
    );
  };

  return (
    <ResourcesHandler
      resources={[topviews, ...resources]}
      resourceFetchers={[
        () => dispatch(reduxOperations.topviews.fetchTopviews()),
        () => dispatch(reduxOperations.folders.fetchFolders()),
        () => dispatch(reduxOperations.images.fetchImages()),
        () => dispatch(reduxOperations.stopwatches.fetchStopwatches()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
        () => dispatch(reduxOperations.shifts.fetchShifts()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.forms.fetchForms()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
        () => dispatch(reduxOperations.operators.fetchOperators()),
      ]}
      getContents={getPage}
    />
  );
};

export default TopviewPage;
