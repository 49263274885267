import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import registerServiceWorker from './registerServiceWorker';
// import { unregister } from './registerServiceWorker';
import './styles/style.scss';
import './styles/css/Bootstrap.css';
import App from './App';
import store from './services/store';

const queryClient = new QueryClient();

const render = (AppComponent: any) => {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppComponent />
      </QueryClientProvider>
    </Provider>,
  );
};
// unregister(); // unregisters the service workers from clients who may already have cached content
registerServiceWorker();

if (!localStorage.getItem('theme')) {
  localStorage.setItem('theme', 'Dark');
}

render(App);
