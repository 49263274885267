import { getTileWidgetIcon } from '~utils/icons';
import { tileWidgetTypes } from '~utils/types';

const tileOptions = [
  {
    type: tileWidgetTypes.AREACHART,
    name: 'areaChart',
    icon: getTileWidgetIcon(tileWidgetTypes.AREACHART),
    tag: 'graph',
  },
  {
    type: tileWidgetTypes.AUDIO,
    name: 'audio',
    icon: getTileWidgetIcon(tileWidgetTypes.AUDIO),
    tag: 'media',
  },
  {
    type: tileWidgetTypes.BAR_CHART,
    name: 'barChart',
    icon: getTileWidgetIcon(tileWidgetTypes.BAR_CHART),
    tag: 'graph',
  },
  {
    type: tileWidgetTypes.BUTTON,
    name: 'button',
    icon: getTileWidgetIcon(tileWidgetTypes.BUTTON),
    tag: 'other',
  },
  {
    type: tileWidgetTypes.CIRCULAR,
    name: 'circularChart',
    icon: getTileWidgetIcon(tileWidgetTypes.CIRCULAR),
    tag: 'graph',
  },
  {
    type: tileWidgetTypes.DATA_ENTRY,
    name: 'dataEntry',
    icon: getTileWidgetIcon(tileWidgetTypes.DATA_ENTRY),
    tag: 'other',
  },
  {
    type: tileWidgetTypes.GAUGE,
    name: 'gauge',
    icon: getTileWidgetIcon(tileWidgetTypes.GAUGE),
    tag: 'graph',
  },
  {
    type: tileWidgetTypes.MACHINE_STATE,
    name: 'machineState',
    icon: getTileWidgetIcon(tileWidgetTypes.MACHINE_STATE),
    tag: 'other',
  },
  {
    type: tileWidgetTypes.MEDIA,
    name: 'media',
    icon: getTileWidgetIcon(tileWidgetTypes.MEDIA),
    tag: 'media',
  },
  {
    type: tileWidgetTypes.PARETOCHART,
    name: 'paretoChart',
    icon: getTileWidgetIcon(tileWidgetTypes.PARETOCHART),
    tag: 'graph',
  },
  {
    type: tileWidgetTypes.PROGRESS_BAR,
    name: 'progressBar',
    icon: getTileWidgetIcon(tileWidgetTypes.PROGRESS_BAR),
    tag: 'graph',
  },
  {
    type: tileWidgetTypes.PROMPT,
    name: 'Prompt',
    icon: getTileWidgetIcon(tileWidgetTypes.PROMPT),
    tag: 'prompt',
  },
  {
    type: tileWidgetTypes.SCORECARD,
    name: 'scorecard',
    icon: getTileWidgetIcon(tileWidgetTypes.SCORECARD),
    tag: 'other',
  },
  {
    type: tileWidgetTypes.STOPWATCH,
    name: 'stopwatch',
    icon: getTileWidgetIcon(tileWidgetTypes.STOPWATCH),
    tag: 'other',
  },
  {
    type: tileWidgetTypes.TEXT,
    name: 'text',
    icon: getTileWidgetIcon(tileWidgetTypes.TEXT),
    tag: 'media',
  },
  {
    type: tileWidgetTypes.TIMELINE,
    name: 'timeline',
    icon: getTileWidgetIcon(tileWidgetTypes.TIMELINE),
    tag: 'graph',
  },
];

export { tileOptions };
