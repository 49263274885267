import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Input } from '@intelligenceindustrielle/react-ui-components';
import { EntriesForm } from '~UI/index';
import './ActionForm.scss';

interface Param {
  key: string;
  value: string;
}

const propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

const defaultProps = {
  action: undefined,
};

const EventActionForm = ({ action }) => {
  const { t } = useTranslation();

  const rawParams = action && action.params ? action.params : {};

  const initialParams = Object.keys(rawParams).map(key => ({ key, value: rawParams[key] }));
  initialParams.splice(initialParams.findIndex(elem => elem.key === 'name'), 1);
  initialParams.splice(initialParams.findIndex(elem => elem.key === 'type'), 1);
  initialParams.push({
    key: '',
    value: '',
  });

  const initializeMachineIdList = paramsArg => {
    const listOfIndexes: number[] = [];
    paramsArg.forEach((elem, index) => {
      if (elem && elem.key.toLowerCase() === 'machineid') {
        listOfIndexes.push(index);
      }
    });
    return listOfIndexes;
  };

  const initialList = initializeMachineIdList(initialParams);

  const [params, setParams] = useState(initialParams);
  const listOfIndexesToShow = initialList;

  const handleParamsChange = paramsArg => setParams(paramsArg);

  const getParamsValue = () => {
    const value: Param[] = [];
    params.forEach(entry => {
      if (entry.key !== '') {
        value.push({
          key: entry.key,
          value: entry.value,
        });
      }
    });
    return value;
  };

  return (
    <>
      <div className="inputTitle">{t('eventName')}</div>
      <Input
        defaultValue={action ? action.params.name : null}
        name="name"
        placeholder="Event Name"
      />
      <div className="inputTitle">
        {t('eventParams')}
        <Icon
          icon="info-circle"
          tooltipLabel={t('useParamMachineId')}
        />
      </div>
      <EntriesForm
        params={params}
        onChange={handleParamsChange}
        listOfIndexesToShow={listOfIndexesToShow}
      />
      <input
        type="hidden"
        name="type"
        value="DefaultEvent"
      />
      {
        getParamsValue().map((element, index) => (
          <input
            key={element.key + index}
            type="hidden"
            name={element.key}
            value={element.value}
          />
        ))
      }
    </>
  );
};

EventActionForm.propTypes = propTypes;
EventActionForm.defaultProps = defaultProps;

export default EventActionForm;
