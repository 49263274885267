import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import types from './types';

const IS_IN_CONFIGURATION_MODE_INITIAL_STATE = false;
function isInConfigurationMode(state = IS_IN_CONFIGURATION_MODE_INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_CONFIGURATION_MODE:
      return action.payload;
    default:
      return state;
  }
}

const IS_FULLSCREEN_INITIAL_STATE = false;
function isFullscreen(state = IS_FULLSCREEN_INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FULLSCREEN:
      return action.payload;
    default:
      return state;
  }
}

const LANGUAGE_INITIAL_STATE = 'fr';
function language(state = LANGUAGE_INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}

const TRIGGERS_SEARCH_INPUT = '';
function triggersSearchInput(state = TRIGGERS_SEARCH_INPUT, action) {
  switch (action.type) {
    case types.SET_TRIGGERS_SEARCH_INPUT:
      return action.payload;
    default:
      return state;
  }
}

const ACTIONS_SEARCH_INPUT = '';
function actionsSearchInput(state = ACTIONS_SEARCH_INPUT, action) {
  switch (action.type) {
    case types.SET_ACTIONS_SEARCH_INPUT:
      return action.payload;
    default:
      return state;
  }
}

const SIDEBAR_SEARCH_INPUT = '';
function sideBarSearchInput(state = SIDEBAR_SEARCH_INPUT, action) {
  switch (action.type) {
    case types.SET_SIDEBAR_SEARCH_INPUT:
      return action.payload;
    default:
      return state;
  }
}

export const propTypes = {
  isFullscreen: PropTypes.bool,
  isInConfigurationMode: PropTypes.bool,
  language: PropTypes.string,
  triggersSearchInput: PropTypes.string,
  actionsSearchInput: PropTypes.string,
  sideBarSearchInput: PropTypes.string,
};

export default combineReducers({
  isInConfigurationMode,
  isFullscreen,
  language,
  triggersSearchInput,
  actionsSearchInput,
  sideBarSearchInput,
});
