import React from 'react';
import classnames from 'classnames';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle } from '../CheckboxToggle/CheckboxToggle';
import IconGroup from './IconGroup';

const inputs = {};

inputs.string = ({ widget, readOnly, rows, size }) => {
  switch (widget) {
    case 'dropdown':
    {
      const dropdown = ({
        onChange, value, id, enum: options,
      }) => (
        <Select
          name={id}
          className="fullwidth"
          value={value}
          onChange={e => onChange(id, e)}
          options={[
            { label: '-', value: '' },
            ...options.map(({ value: val, label }) => ({ label: label || val, value: val })),
          ]}
        />
      );
      return dropdown;
    }
    case 'buttongroup':
    {
      const buttongroup = ({ onChange, value, id, enum: buttons }) => (
        <ButtonGroup
          buttons={buttons}
          key={`i${id}`}
          onChange={selected => onChange(id, selected)}
          value={value}
        />
      );
      return buttongroup;
    }
    case 'icongroup':
    {
      const icongroup = ({ onChange, value, id, enum: options }) => (
        <IconGroup
          key={`i${id}`}
          options={options}
          value={value}
          onChange={selected => onChange(id, selected)}
        />
      );
      return icongroup;
    }
    case 'textarea':
    {
      const textarea = ({ onChange, value, id }) => (
        <textarea
          key={`i${id}`}
          value={value}
          rows={rows}
          onChange={e => onChange(id, e.target.value)}
        />
      );
      return textarea;
    }
    case 'text':
    default:
    {
      const def = ({
        onChange, value, id, required, minLength, maxLength,
      }) => (
        <input
          key={`i${id}`}
          type="text"
          name={id}
          className={classnames('fullwidth', size)}
          value={value}
          onChange={e => onChange(id, e.target.value)}
          required={required}
          disabled={readOnly}
          readOnly={readOnly}
          minLength={`${minLength}`}
          maxLength={`${maxLength}`}
        />
      );
      return def;
    }
  }
};

inputs.number = ({ widget, readOnly, size }) => {
  switch (widget) {
    default:
    {
      const def = ({
        onChange, value, id, required, min, max, step,
      }) => (
        <input
          key={`i${id}`}
          type="number"
          name={id}
          className={classnames('fullwidth', size)}
          value={value || 0}
          onChange={e => onChange(id, e.target.value)}
          required={required}
          disabled={readOnly}
          readOnly={readOnly}
          min={`${min}`}
          max={`${max}`}
          step={`${step}`}
        />
      );
      return def;
    }
  }
};

inputs.boolean = ({ widget }) => {
  switch (widget) {
    case 'toggle':
    default:
    {
      const def = ({ onChange, value, id }) => (
        <CheckboxToggle
          name={id}
          onChange={e => onChange(id, e.target.value)}
          defaultChecked={value}
        />
      );
      return def;
    }
  }
};

export default inputs;
