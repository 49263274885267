/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { trackPage } from '~utils/googleAnalytics';

export default function withTracker(WrappedComponent) {
  const propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  const HOC = class extends Component {
    componentDidMount() {
      const { location: { pathname } } = this.props;
      trackPage(pathname);
    }

    componentDidUpdate(prevProps) {
      const { location: { pathname } } = prevProps;
      const currentPage = pathname;
      const { location } = this.props;
      const nextPage = location.pathname;

      if (nextPage !== currentPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  HOC.propTypes = propTypes;

  return HOC;
}
