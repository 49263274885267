import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PageTemplate, RuleGrid } from '~components/Pages';
import { DefaultModal, DefaultThemedButton, ResourcesHandler } from '~UI';
import { reduxOperations } from '~services';
import ActionEditionForm from './ruleEngineEdition/actions/ActionEditionForm';
import './RuleEnginePages.scss';

const ActionsPage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // fetch
  const actions = useSelector(state => state.actions);
  const machines = useSelector(state => state.machines);
  const triggers = useSelector(state => state.triggers);
  const streams = useSelector(state => state.streams);
  const stopwatches = useSelector(state => state.stopwatches.stopwatches);
  const users = useSelector(state => state.users.users);
  const variables = useSelector(state => state.variables);
  const tags = useSelector(state => state.tags.tags);
  const operations = useSelector(state => state.operations.operations);
  const operators = useSelector(state => state.operators);

  const [isCrud, setIsCrud] = useState(false);
  const [actionId, setActionId] = useState(null);

  const toggleCRUDPage = actionIdArg => {
    setActionId(actionIdArg);
    setIsCrud(prevIsCrud => !prevIsCrud);
  };

  const parseActions = () => {
    const temp = actions.map(action => ({
      ...action,
      onClick: () => toggleCRUDPage(action.id),
    }));
    return [{
      type: '+',
      name: t('createAction'),
      onClick: () => toggleCRUDPage(null),
    }, ...temp];
  };

  const getContents = () => (
    <PageTemplate
      sidebar
      rightButtons={(
        <div className="rulesToActionsContainer">
          <DefaultThemedButton
            key="btnAction"
            content={(
              <Link
                key="/config/ruleEngine"
                to="/config/ruleEngine"
                className="rulesToActionsLink"
              >
                {t('rulesList')}
              </Link>
            )}
            isActive={false}
            className="rulesToActionsButton"
          />
        </div>
      )}
    >
      <DefaultModal
        show={isCrud}
        title={t('selectAction')}
        closePopup={() => {
          setActionId(null);
          setIsCrud(false);
        }}
        children={(
          <ActionEditionForm
            goBack={() => {
              setActionId(null);
              setIsCrud(false);
            }}
            actionId={actionId}
          />
        )}
      />
      <RuleGrid
        tiles={parseActions()}
        isActionList
      />
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[actions, triggers, streams, stopwatches, users, variables, machines, tags, operations, operators]}
      resourceFetchers={[
        () => dispatch(reduxOperations.actions.fetchActions()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.stopwatches.fetchStopwatches()),
        () => dispatch(reduxOperations.users.fetchUsers()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.tags.fetchTags()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
        () => dispatch(reduxOperations.operators.fetchOperators()),
      ]}
      getContents={getContents}
    />
  );
};

export default ActionsPage;
