import {
  setConfigurationMode,
  setFullscreen,
  setLanguage,
} from './actions';

export default {
  setConfigurationMode,
  setFullscreen,
  setLanguage,
};
