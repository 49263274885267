import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { padding } from '~utils';
import { BasicCard, Cards, FontAwesome } from '~UI';
import '../TriggerForms.scss';

const ShiftManager = ({
  shiftTimeOption,
  handleOptionChange,
  shifts,
  selectedShifts,
  handleShiftSelect,
  repeatOption,
  timezone,
}) => {
  const { t } = useTranslation();
  const prettyPrintShift = shift => `${padding(shift.hourStart)}:${padding(shift.minuteStart)}  -  ${padding(shift.hourEnd)}:${padding(shift.minuteEnd)}`;

  const renderShiftSelection = () => (
    <div className="beginningOrEndShift">
      <div className="inputTitle">{t('beginningOrEndShift')}</div>
      <Select
        style={{ width: '300px' }}
        onChange={handleOptionChange}
        name="shiftTimeOption"
        value={shiftTimeOption}
        options={[
          { label: t('beginningOfShift'), value: 'beginning' },
          { label: t('endOfShift'), value: 'end' },
        ]}
      />
    </div>
  );

  const renderShiftCards = () => shifts.map(shift => (
    <BasicCard
      key={shift.id}
      icon={<FontAwesome icon="ii-shift" size="4x" />}
      text={prettyPrintShift(shift)}
      title={shift.name}
      onClick={() => handleShiftSelect(shift)}
      selected={selectedShifts.includes(shift)}
      style={selectedShifts.includes(shift) ? { border: '1px solid #ccc' } : {}}
    />
  ));

  const renderAllShiftsText = () => {
    if (shiftTimeOption === 'beginning') {
      return <div>{t('cronAllShiftsBeginning')}</div>;
    } if (shiftTimeOption === 'end') {
      return <div>{t('cronAllShiftsEnd')}</div>;
    }

    return null;
  };

  return (
    <div className="shiftManager">
      <div className="shiftSelection">
        {renderShiftSelection()}
      </div>
      <div className="shiftCards">
        {(shiftTimeOption && repeatOption !== 'allShifts') ? (
          <Cards>
            {renderShiftCards()}
          </Cards>
        )
          : renderAllShiftsText()
        }
      </div>
      {selectedShifts?.length > 0 && (
        <>
          <div className="inputTitle">{t('timezone')}</div>
          <input type="text" name="timezone" value={timezone} readOnly />
        </>
      )}
    </div>
  );
};

ShiftManager.propTypes = {
  shiftTimeOption: PropTypes.string.isRequired,
  repeatOption: PropTypes.string.isRequired,
  handleOptionChange: PropTypes.func.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedShifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleShiftSelect: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default ShiftManager;
