import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { DefaultModal, SubmitButton } from '~UI/index';
import { reducersTypes } from '~services/index';
import './TriggerDuplicationPopup.scss';

const TriggerDuplicationPopup = ({ trigger, handleTriggerDuplication, onHide, show }) => {
  const { t } = useTranslation();

  const { actions } = trigger;

  const [selectedOption, setSelectedOption] = useState('2');

  const handleSubmit = () => {
    switch (selectedOption) {
      case '1':
        handleTriggerDuplication(null, trigger, null);
        break;
      case '2':
        handleTriggerDuplication(null, trigger, []);
        break;
      case '3':
        handleTriggerDuplication(null, trigger, actions);
        break;
      default:
        break;
    }
    onHide();
  };

  const getContents = () => (
    <div className="triggerDuplicationPopup">
      <Button
        className="summaryOptionContainer"
        onClick={() => setSelectedOption('1')}
      >
        <div className="summary">{t('duplicateTriggerOnly')}</div>
        <Checkbox
          className="selectItemBtn"
          checked={selectedOption === '1'}
        />
      </Button>
      <Button
        className="summaryOptionContainer"
        onClick={() => setSelectedOption('2')}
      >
        <div className="summary">{t('duplicateKeepActions')}</div>
        <Checkbox
          className="selectItemBtn"
          checked={selectedOption === '2'}
        />
      </Button>
      <Button
        className="summaryOptionContainer"
        onClick={() => setSelectedOption('3')}
      >
        <div className="summary">{t('duplicateTriggerAndActions')}</div>
        <Checkbox
          className="selectItemBtn"
          checked={selectedOption === '3'}
        />
      </Button>
      <SubmitButton className="buttonsHolder" label={t('submit')} onClick={handleSubmit} />
    </div>
  );

  return (
    <DefaultModal
      closePopup={onHide}
      title={t('duplicateSelect')}
      show={show}
      children={getContents()}
    />
  );
};

TriggerDuplicationPopup.propTypes = {
  trigger: reducersTypes.triggers.trigger.isRequired,
  handleTriggerDuplication: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.object.isRequired,
};

export default TriggerDuplicationPopup;
