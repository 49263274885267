import React from 'react';
import { useSelector } from 'react-redux';
import { AudioRecorder } from '~UI/AudioRecorder/AudioRecorder';
import './TextArea.scss';

interface TextAreaProps {
  value: string;
  onChange: (text: string) => void;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({ value, onChange, placeholder }) => {
  const featureToggles = useSelector((state: any) => state.settings.settings?.featureToggles);

  return (
    <div className={`text-area ${featureToggles?.AI?.whisper ? 'whisper' : ''}`}>
      <textarea
        className="text-area"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
      {featureToggles?.AI?.whisper && (
        <div className="audio-recorder">
          <AudioRecorder setText={text => onChange(value + text)} />
        </div>
      )}
    </div>
  );
};

TextArea.defaultProps = {
  placeholder: '',
};

export { TextArea };
