const variableToId = (variable, variableList) => {
  const regex = /\$[A-Za-z][A-Za-z0-9_]*/g;

  const variablesInExpression = variable.match(regex) || [];
  variablesInExpression.forEach((elem, index) => {
    variablesInExpression[index] = elem.replace(/\$/g, '');
  });
  let idFormatedVariable = ` ${variable}`.slice(1);
  const regExNOW = new RegExp('\\$NOW', 'g');
  // eslint-disable-next-line no-template-curly-in-string
  idFormatedVariable = idFormatedVariable.replace(regExNOW, '${NOW}');

  variablesInExpression.forEach(match => {
    let exist = false;
    variableList.forEach(elem => {
      if (match === elem.variable) {
        exist = true;
        idFormatedVariable = idFormatedVariable.replace(`$${match}`, `\${${elem.id}}`);
      }
    });
    if (!exist && match !== 'NOW') {
      // TODO: think about should we throw an Error, return the variable or something else
      throw new Error('Variable does not exist');
    }
  });

  return idFormatedVariable;
};

export default variableToId;
