import { tileWidgetTypes } from '~utils/types';

function shouldDisplayContentInput(type) {
  switch (type) {
    case tileWidgetTypes.MACHINE_STATE:
    case tileWidgetTypes.SCORECARD:
    case tileWidgetTypes.TEXT:
    case tileWidgetTypes.BUTTON:
      return true;
    default:
      return false;
  }
}

function shouldDisplayFormatInput(type) {
  switch (type) {
    case tileWidgetTypes.CIRCULAR:
    case tileWidgetTypes.GAUGE:
    case tileWidgetTypes.PROMPT:
      return false;
    default:
      return true;
  }
}

function shouldDisplayLinksToInput(type) {
  switch (type) {
    case tileWidgetTypes.BUTTON:
    case tileWidgetTypes.MEDIA:
      return false;
    default:
      return true;
  }
}

function shouldDisplayTitleInput(type) {
  switch (type) {
    case tileWidgetTypes.DATA_ENTRY:
    case tileWidgetTypes.GAUGE:
    case tileWidgetTypes.MACHINE_STATE:
    case tileWidgetTypes.SCORECARD:
    case tileWidgetTypes.STOPWATCH:
    case tileWidgetTypes.TEXT:
      return true;
    default:
      return false;
  }
}

function shouldDisplayZoomInput(type) {
  switch (type) {
    case tileWidgetTypes.BUTTON:
    case tileWidgetTypes.SCORECARD:
    case tileWidgetTypes.STOPWATCH:
    case tileWidgetTypes.TEXT:
      return true;
    default:
      return false;
  }
}

export {
  shouldDisplayContentInput,
  shouldDisplayFormatInput,
  shouldDisplayLinksToInput,
  shouldDisplayTitleInput,
  shouldDisplayZoomInput,
};
