import React from 'react';
import PropTypes from 'prop-types';

import { ConfigurationIcons } from '~UI/IconButtons';

import './TileComponent.scss';

const TileComponent = ({
  title, className, config, description, bgImage, bgIcon, handleSelection, children, favoriteIcon,
}) => {
  const { isConfigurationEnabled, ...configProps } = config;
  const configurationIcons = isConfigurationEnabled
    ? <ConfigurationIcons {...configProps} /> : null;

  return (
    <div
      className={[`Tile framed flex V ${isConfigurationEnabled ? 'cursorPointer' : ''}`, ...className.split(' ')].join(' ')}
      style={{ backgroundImage: bgImage }}
    >
      {isConfigurationEnabled ? null : favoriteIcon}

      <div className="Tile__Config">
        {configurationIcons}
      </div>

      <div
        role={handleSelection && 'button'}
        className="Tile__Content flex V"
        onClick={handleSelection}
      >
        {bgIcon}
        {title ? <h4>{title}</h4> : null}
        {description ? <p>{description}</p> : null}
        {children}
      </div>
    </div>
  );
};

TileComponent.propTypes = {
  className: PropTypes.string,
  bgImage: PropTypes.string,
  bgIcon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  handleSelection: PropTypes.func,
  config: PropTypes.shape({
    dashboardId: PropTypes.string,
    handleDuplication: PropTypes.func,
    isConfigurationEnabled: PropTypes.bool,
    isSmall: PropTypes.bool,
    displayHeadband: PropTypes.bool,
    EditionForm: PropTypes.object,
    DeletionForm: PropTypes.object,
    tile: PropTypes.object,
  }),
  children: PropTypes.element.isRequired,
  favoriteIcon: PropTypes.element,
};
TileComponent.defaultProps = {
  className: '',
  title: '',
  description: '',
  bgImage: 'none',
  bgIcon: null,
  handleSelection: undefined,
  config: { isConfigurationEnabled: false },
  favoriteIcon: null,
};

export default TileComponent;
