// TODO: Ici, optimalement, il faudrait utiliser un parser au lieu de faire des regex
const regexFloat = /[+-]?\d*\.?\d*/;
const regexConditions = new RegExp(`\\$\\{([A-Za-z0-9_-]+)\\}(>=|==|!=|<=|<|>)(".*?"|true|false|${regexFloat.source})`);

const splitCondition = condition => {
  const conditions = regexConditions.exec(condition);
  if (conditions) {
    const variable = conditions[1];
    const comparator = conditions[2];
    let valueToCompare: number | boolean | string = conditions[3];
    if (conditions[3] === 'true' || conditions[3] === 'false') {
      valueToCompare = conditions[3] === 'true';
    } else if (conditions[3].startsWith('"') && conditions[3].endsWith('"')) {
      valueToCompare = conditions[3].slice(1, -1); // remove the quotes
    } else if (!Number.isNaN(parseFloat(conditions[3]))) {
      valueToCompare = parseFloat(conditions[3]);
    }

    if (valueToCompare === '') {
      return null;
    }

    return {
      variable,
      comparator,
      valueToCompare,
    };
  }
  return null;
};

export default splitCondition;
