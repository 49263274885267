import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { CheckboxToggle } from '~components/UI';
import { sortArray } from '~utils/sort';

const FormStopwatch = ({ isTile, selectedObject }) => {
  const { t } = useTranslation();

  const stopwatches = useSelector((state: RootState) => state.stopwatches.stopwatches);
  const stopwatchOptions = sortArray('alphabetically', stopwatches, 'name').map(s => ({ label: s.name, value: s.id }));
  const timeFormatOptions = ['hh:mm:ss', 'hh:mm', 'mm:ss', 'hh', 'mm', 'ss'].map(s => ({ label: s, value: s }));

  return (
    <div>
      <div className="inputTitle">{t('chooseStopwatch')}</div>
      {isTile && (
        <Select
          name="stopwatchId"
          defaultValue={selectedObject.stopwatchId}
          options={stopwatchOptions}
          placeholder={t('select')}
        />
      )}
      {!isTile && (
        <Select
          name="stopwatch"
          defaultValue={selectedObject.stopwatch}
          options={stopwatchOptions}
          placeholder={t('select')}
        />
      )}
      <span style={{ paddingLeft: '4px' }}>
        {`${t('noStopwatchThenCreate')} `}
        <Link
          key="/config/stopwatches"
          to="/config/stopwatches"
          style={{ textDecoration: 'underline', color: '#0078FF' }}
        >
          {t('here')}
        </Link>
      </span>
      <div className="inputTitle">{t('timeFormat')}</div>
      <Select
        className="fullwidth"
        name="timeFormat"
        defaultValue={selectedObject.timeFormat || 'hh:mm:ss'}
        options={timeFormatOptions}
      />
      {isTile && (
        <div className="inputTitle">
          {`${t('Display')} ${t('startStopToggle')} ${t('button')}`}
          &nbsp;
          <CheckboxToggle
            name="displayButtons"
            defaultChecked={!!selectedObject.displayButtons}
          />
        </div>
      )}
    </div>
  );
};

FormStopwatch.propTypes = {
  isTile: PropTypes.bool,
  selectedObject: PropTypes.object,
};
FormStopwatch.defaultProps = {
  isTile: false,
  selectedObject: {},
};

export { FormStopwatch };
