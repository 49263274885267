import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { showError } from '~utils/toast';

const PasswordRestrictions = () => {
  const { t } = useTranslation();

  return (
    <ul>
      <li>{t('infoPasswordLength')}</li>
      <li>{t('infoPasswordLowercase')}</li>
      <li>{t('infoPasswordUppercase')}</li>
      <li>{t('infoPasswordNumeric')}</li>
    </ul>
  );
};

function verifyPassword(password) {
  if (!RegExp('^(?=.{8,})').test(password)) {
    showError(i18n.t('infoPasswordLength'));
    return true;
  }

  if (!RegExp('^(?=.*[a-z])').test(password)) {
    showError(i18n.t('infoPasswordLowercase'));
    return true;
  }

  if (!RegExp('^(?=.*[A-Z])').test(password)) {
    showError(i18n.t('infoPasswordUppercase'));
    return true;
  }

  if (!RegExp('^(?=.*[0-9])').test(password)) {
    showError(i18n.t('infoPasswordNumeric'));
    return true;
  }

  return false;
}

export {
  PasswordRestrictions,
  verifyPassword,
};
