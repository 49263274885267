import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { getActionIcon } from '~utils/icons';
import { DefaultModal, FontAwesome } from '~UI';
import { stopPropagation } from '~utils';
import { reducersTypes } from '~services';
import { ActionPropTypes, ActionType } from '~services/actions/types';
import { ActionParamForm } from './actions/actionsForms';
import './RuleEditionPages.scss';

const ActionSummaryComponent = ({
  actionId, actions, removeAction, index, trigger, setShowPopupId, renderPopup,
  closePopup, handleSavePassedParameters, showPopupId, moveAction, updateActions,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    drop() {
      updateActions();
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current && ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY / 6) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY * 6) {
        return;
      }
      moveAction(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    item: { actionId, index },
    type: 'card',
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (actionId && !actions.find(elem => elem.id === actionId)) {
      // said action is already removed
      removeAction(new Event('click'), index);
    }
  }, []);

  const renderParamsPopup = (popupId, indexArg, actionParamsKeys) => (
    <DefaultModal
      show={showPopupId === popupId}
      title={t('settings')}
      closePopup={closePopup}

    >
      <ActionParamForm
        parameters={
          trigger.actionsToPerform[indexArg] && trigger.actionsToPerform[indexArg].params
            ? trigger.actionsToPerform[indexArg].params
            : {}
        }
        actionIndex={indexArg}
        actionParamsKeys={actionParamsKeys}
        handleSave={handleSavePassedParameters}
        handleClose={closePopup}
      />
    </DefaultModal>
  );

  const showActionParamsPopup = (e, popupId) => {
    stopPropagation(e);
    setShowPopupId(popupId);
  };

  const action = actions.find(elem => elem.id === actionId);
  const regexParams = /(#\w+)/g;
  const actionParams = [];
  if (action) {
    Object.keys(action.params).forEach(paramKey => {
      const str = action.params[paramKey] || '';
      let params = [];
      if (typeof str === 'string') {
        params = str.match(regexParams);
      }
      if (Array.isArray(str)) {
        if (action.type === ActionType.API_CALL) {
          str.map(item => item.value).forEach(item => {
            if (typeof item === 'string') {
              if (item.search('#') !== -1) {
                params.push(item.match(regexParams)[0]);
              }
            }
          });
        } else {
          params = str
            .filter(item => item.search('#') !== -1)
            .map(item => item.match(regexParams)[0]);
        }
      }
      if (params) {
        actionParams.push(...params);
      }
    });
  }
  drag(drop(ref));
  return !action ? (
    <button
      className="createActionContainer"
      onClick={() => setShowPopupId(`action_${index}`)}
      disabled={!trigger}
      type="submit"
    >
      <div
        role="button"
        onClick={e => e.stopPropagation()}
      >
        {renderPopup(false, `action_${index}`, null)}
      </div>
      <div className="iconAction">
        <FontAwesome icon="plus" />
      </div>
      <div className="summary">
        {t('selectAction')}
      </div>
    </button>
  )
    : (
      <div ref={ref}>
        <button
          className="summaryActionContainer"
          onClick={() => setShowPopupId(`action_${index}`)}
          type="submit"
        >
          <div
            role="button"
            onClick={e => e.stopPropagation()}
          >
            {renderPopup(false, `action_${index}`, action)}
          </div>
          <div
            role="button"
            onClick={e => e.stopPropagation()}
          >
            {renderParamsPopup(`params_action_${index}`, index, actionParams)}
          </div>

          <div className="iconActionSummary">
            <FontAwesome icon={getActionIcon(action.type)} />
          </div>
          <div
            className="summary"
            style={{ width: actionParams.length > 0 ? '68%' : '88%' }}
            title={action.name}
          >
            {action.name}
          </div>
          {
            actionParams.length > 0 && (
              <div className="paramsContainer">
                <div
                  role="button"
                  key={`actionParam_${index}`}
                  className="actionParam"
                  onClick={e => showActionParamsPopup(e, `params_action_${index}`)}
                  title={t('triggerParameters')}
                >
                  {t('triggerParameters')}
                </div>
              </div>
            )
          }

          <div
            role="button"
            className="removeItemBtn"
            onClick={e => removeAction(e, index)}
          >
            <FontAwesome icon="close" />
          </div>
        </button>
      </div>
    );
};

ActionSummaryComponent.propTypes = {
  actions: PropTypes.arrayOf(ActionPropTypes).isRequired,
  trigger: reducersTypes.triggers.trigger.isRequired,
  actionId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  setShowPopupId: PropTypes.func.isRequired,
  renderPopup: PropTypes.func.isRequired,
  removeAction: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  moveAction: PropTypes.func.isRequired,
  updateActions: PropTypes.func.isRequired,
  handleSavePassedParameters: PropTypes.func.isRequired,
  showPopupId: PropTypes.string.isRequired,
};

export default ActionSummaryComponent;
