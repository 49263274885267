import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatDuration, serverTime } from '~utils/time';

const Timer = ({ eventTimestamp, fontSize, language }) => {
  const [timeInMS, setTimeInMS] = useState(serverTime() - eventTimestamp);

  useEffect(() => {
    setTimeInMS(serverTime() - eventTimestamp);
    const intervalId = setInterval(() => {
      setTimeInMS(serverTime() - eventTimestamp);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [eventTimestamp]);

  const timer = formatDuration(
    timeInMS,
    {
      year: true,
      month: true,
      day: true,
      hourSeparator: ':',
      minSeparator: ':',
      sec: true,
    },
    false,
    language,
    true,
  );

  return (
    <div style={{ fontSize: `${fontSize}px` }}>
      {timer}
    </div>
  );
};

Timer.propTypes = {
  eventTimestamp: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
};

export default Timer;
