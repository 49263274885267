import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services';
import API from '~services/endpoints';
import {
  CancelButton, DefaultModal, IntervalTimePicker, MultiSelect, SubmitButton, FontAwesome,
} from '~UI';
import { eventsToReadable, eventsTimeToDate } from '~utils/events';
import { sortArray } from '~utils/sort';
import { serverTime } from '~utils/time';

// CSV generation constants
const COLUMN_SEP = ',';
const LINE_SEP = '\n';
const CSVFieldsMachine = {
  TIMESTAMP: 'timestamp',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  DURATION: 'duration',
  TYPE: 'type',
  NAME: 'name',
  MACHINE_ID: 'machineId',
  STATE: 'state',
  OPERATION: 'operation',
  OPERATOR: 'operator',
  SKU_NUMBER: 'skuNumber',
  WORK_ORDER: 'workOrder',
  MOTIF: 'motif',
  COMMENTS: 'comments',
  RESOLUTIONS: 'resolutions',
  FILE: 'file',
};
const CSVFieldsDefault = {
  TIMESTAMP: 'timestamp',
  DATE: 'date',
  NAME: 'name',
};
const CSVFieldsParts = {
  TIMESTAMP: 'timestamp',
  DATE: 'date',
  NAME: 'name',
  MACHINE_ID: 'machineId',
  EVENT_TYPE: 'eventType',
  COUNT: 'count',
};

// https://halistechnology.com/2015/05/28/use-javascript-to-export-your-data-as-csv/
function downloadCSVLocally(data) {
  // TODO test that accents display well on CSV export
  const dataBlob = new Blob([`\ufeff${data}`], { type: 'text/csv;charset=utf-8' });
  const a = document.createElement('a');
  a.type = 'hidden';
  a.href = URL.createObjectURL(dataBlob);
  a.download = 'ii_events_export.csv';
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const ExportEventCSVForm = ({
  onHide, show, type, eventNames, CSVType,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  const [isLoading, setIsLoading] = useState(false);
  const [hasNoEvents, setHasNoEvents] = useState(false);
  const [startDate, setStartDate] = useState(serverTime());
  const [endDate, setEndDate] = useState(serverTime());
  const [selectedMachines, setSelectedMachines] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [namesOptions, setNamesOptions] = useState([]);
  const [nbEvents, setNbEvents] = useState(0);
  const [confirmExport, setConfirmExport] = useState(false);

  // equivalent of componentDidUpdate with a check on CSVType
  // this happens everytime the modal is opened
  useEffect(() => {
    dispatch(reduxOperations.shifts.fetchShifts());
  }, []);

  useEffect(() => {
    if (CSVType === 'events') {
      setSelectedMachines([]);
    } else {
      setSelectedMachines(machineOptions);
    }
    setSelectedNames([]);
    if (eventNames) {
      const newNamesOptions = eventNames.map(name => ({
        label: name,
        value: name,
      }));
      setNamesOptions(newNamesOptions);
    }
  }, [CSVType, eventNames]);

  useEffect(() => {
    setSelectedNames(namesOptions);
  }, [namesOptions]);

  // equivalent of componentDidUpdate with a check on the filters
  useEffect(() => {
    setHasNoEvents(false);
    setConfirmExport(false);
    setNbEvents(0);
  }, [startDate, endDate, selectedMachines, selectedNames]);

  const handleChangeDate = (sDate, eDate) => {
    const start = sDate || startDate;
    const end = eDate || endDate;
    setStartDate(start);
    setEndDate(end);
  };

  const cancelExport = () => {
    setConfirmExport(false);
    setNbEvents(0);
  };

  const fectchCount = async () => {
    setIsLoading(true);
    const name = selectedNames.length ? { $in: selectedNames.map(n => n.value) } : undefined;
    const machineId = selectedMachines.length && selectedMachines.length !== machineOptions.length
      ? { $in: selectedMachines.map(m => m.value) } : undefined;
    const timestamp = { $gte: startDate, $lte: endDate };
    const filter = { type, name, machineId, timestamp };
    const { count } = await API.countEvents(filter);
    if (!count) {
      setHasNoEvents(true);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setNbEvents(count);
    setConfirmExport(true);
  };

  const fetchEvents = async () => {
    const name = selectedNames.length ? { $in: selectedNames.map(n => n.value) } : undefined;
    const machineId = selectedMachines.length && selectedMachines.length !== machineOptions.length
      ? { $in: selectedMachines.map(m => m.value) } : undefined;
    const timestamp = { $gte: startDate, $lte: endDate };
    const filter = { type, name, machineId, timestamp };
    const { events } = await API.getEvents(filter);
    return events || [];
  };

  const exportData = () => {
    setIsLoading(true);
    fetchEvents().then(events => {
      if (!events.length) {
        setHasNoEvents(true);
        return;
      }

      let eventsClone;
      const fields = new Set();
      const regex = /[\r\n]+/g;

      const parseEvents = eventsParse => eventsParse(events)
        .filter(event => event.status !== 'END')
        .sort((eventA, eventB) => eventB.timestamp - eventA.timestamp)
        .map(event => {
          if (event.comments) {
            event.comments = event.comments.replace(regex, '   ');
          }
          if (event.resolutions) {
            event.resolutions = event.resolutions.replace(regex, '   ');
          }
          if (event.infos) {
            event.infos.forEach(info => {
              fields.add(info.name);
              event[info.name] = info.value;
            });
          }
          return event;
        });

      if (CSVType === 'machine') {
        Object.values(CSVFieldsMachine).forEach(field => fields.add(field));
        eventsClone = parseEvents(eventsToReadable);
      } else if (CSVType === 'parts') {
        Object.values(CSVFieldsParts).forEach(field => fields.add(field));
        eventsClone = parseEvents(eventsTimeToDate);
      } else {
        Object.values(CSVFieldsDefault).forEach(field => fields.add(field));
        eventsClone = parseEvents(eventsTimeToDate);
      }

      // Change the array to CSV
      let keys = [...fields];
      // data
      const data = eventsClone.map(event => keys.map(key => event[key]));
      // finalization
      keys = keys.map(key => (key === CSVFieldsMachine.DURATION ? `${key} (s)` : key));
      const lines = [keys, ...data];
      const result = lines.map(line => line.join(COLUMN_SEP)).join(LINE_SEP) + LINE_SEP;

      downloadCSVLocally(result);
      onHide();
    }).finally(() => setIsLoading(false));
  };

  return (
    <DefaultModal
      show={show}
      closePopup={onHide}
      title={t('exportCSVTitle')}
    >
      {
        (CSVType === 'machine' || CSVType === 'parts') && (
          <>
            <div className="inputTitle">{t('machines')}</div>
            <MultiSelect
              options={machineOptions}
              value={selectedMachines}
              onChange={options => setSelectedMachines(options)}
            />
          </>
        )
      }
      {
        CSVType === 'events' && (
          <>
            <div className="inputTitle">{t('events')}</div>
            <MultiSelect
              options={namesOptions}
              value={selectedNames}
              onChange={options => setSelectedNames(options)}
            />
          </>
        )
      }
      <div className="inputTitle">{t('timePeriod')}</div>
      <h5>
        {`${new Date(startDate).toLocaleString()} - ${new Date(endDate).toLocaleString()}`}
      </h5>
      <IntervalTimePicker
        intervalType="last24Hours"
        startDate={startDate}
        endDate={endDate}
        handleChangeDate={handleChangeDate}
        customOption
      />
      {isLoading && (
        <div className="loaderForm">
          {t('doNotQuitWhileLoading')}
          <Loader />
        </div>
      )}
      {hasNoEvents && (<h4>{`${t('noEvents')}.`}</h4>)}
      {nbEvents > 0 && (
        <>
          <div className="warningEvents">
            <h4>
              <FontAwesome icon="exclamation-triangle" />
              {` ${t('nbEventsToFetch')}: ${nbEvents}`}
            </h4>
            {t('exportLimitation')}
          </div>
          <h4>{`${t('confirmExport')} ?`}</h4>
        </>
      )}
      <div className="buttonsHolder" style={{ textAlign: 'right' }}>
        <CancelButton onClick={confirmExport ? cancelExport : onHide} />
        <SubmitButton
          label={confirmExport ? t('confirm') : t('export')}
          onClick={confirmExport ? exportData : fectchCount}
        />
      </div>
    </DefaultModal>
  );
};

ExportEventCSVForm.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  eventNames: PropTypes.arrayOf(PropTypes.string),
  CSVType: PropTypes.string.isRequired,
};
ExportEventCSVForm.defaultProps = {
  eventNames: undefined,
};

export { ExportEventCSVForm };
