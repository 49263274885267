import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DeleteConfirmationForm } from '~components/Popups';
import './Button.scss';

const DeleteButton = ({ askConfirmation, handleDelete, style }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <button
        type="button"
        className="StyledButton DeleteButton"
        onClick={askConfirmation ? handleShow : handleDelete}
        style={style}
      >
        {t('delete')}
      </button>
      <DeleteConfirmationForm
        show={show}
        onHide={() => setShow(false)}
        handleDelete={handleDelete}
      />
    </>
  );
};

DeleteButton.propTypes = {
  askConfirmation: PropTypes.bool,
  handleDelete: PropTypes.func,
  style: PropTypes.object,
};
DeleteButton.defaultProps = {
  askConfirmation: false,
  handleDelete: () => undefined,
  style: {},
};

export default DeleteButton;
