import React from 'react';
import PropTypes from 'prop-types';
import PromptWidgetMachine from './PromptWidgetMachine';
import PromptWidgetVariables from './PromptWidgetVariables';
import JsxWidget from '../BaseWidgets/JsxWidget';

function PromptWidget({
  backgroundColor, h, w, x, y, widget,
}) {
  return (
    <JsxWidget
      dimension={{ x, y, w, h }}
      backgroundColor={backgroundColor}
      shape={widget.format.shape}
    >
      <>
        {widget.dataType === 'machine' && (
          <PromptWidgetMachine
            h={h}
            w={w}
            widget={widget}
          />
        )}
        {widget.dataType === 'variables' && (
          <PromptWidgetVariables
            h={h}
            w={w}
            widget={widget}
          />
        )}
      </>
    </JsxWidget>
  );
}

PromptWidget.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    dataType: PropTypes.string.isRequired,
    format: PropTypes.shape({
      shape: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PromptWidget;
