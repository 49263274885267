import types from './types';

const getStopwatchesRequest = () => ({
  type: types.GET_STOPWATCHES_REQUEST,
});
const getStopwatchesResponse = response => ({
  type: types.GET_STOPWATCHES_RESPONSE,
  payload: response,
});

const addStopwatchRequest = request => ({
  type: types.ADD_STOPWATCHES_REQUEST,
  payload: request,
});
const addStopwatchResponse = response => ({
  type: types.ADD_STOPWATCHES_RESPONSE,
  payload: response,
});

const updateStopwatchRequest = request => ({
  type: types.UPDATE_STOPWATCHES_REQUEST,
  payload: request,
});
const updateStopwatchResponse = response => ({
  type: types.UPDATE_STOPWATCHES_RESPONSE,
  payload: response,
});

const socketUpdateStopwatch = stopwatch => ({
  type: types.SOCKET_UPDATE_STOPWATCH,
  payload: stopwatch,
});

const deleteStopwatchRequest = request => ({
  type: types.DELETE_STOPWATCHES_REQUEST,
  payload: request,
});
const deleteStopwatchResponse = response => ({
  type: types.DELETE_STOPWATCHES_RESPONSE,
  payload: response,
});

const stopwatchIORequest = request => ({
  type: types.STOPWATCHIO_STOPWATCHES_REQUEST,
  payload: request,
});
const stopwatchIOResponse = response => ({
  type: types.STOPWATCHIO_STOPWATCHES_RESPONSE,
  payload: response,
});

export {
  getStopwatchesRequest,
  getStopwatchesResponse,
  addStopwatchRequest,
  addStopwatchResponse,
  updateStopwatchRequest,
  updateStopwatchResponse,
  deleteStopwatchRequest,
  deleteStopwatchResponse,
  stopwatchIORequest,
  stopwatchIOResponse,
  socketUpdateStopwatch,
};
