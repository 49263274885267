import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { reducersTypes } from '~services';
import TileContents from '../TileContents';
import { Scorecard } from '~UI/svg';

const ScorecardTile = ({ backgroundColor, height, tile, width }) => {
  const container = useRef();
  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      ref={container}
      height={height}
      width={width}
    >
      <Scorecard
        selectedObject={tile}
        backgroundColor={backgroundColor}
        dimension={{ w: width, h: height }}
      />
    </TileContents>
  );
};

ScorecardTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  width: PropTypes.number.isRequired,
};

export default ScorecardTile;
