import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesome, ModalHandler } from '~UI';
import './IconButtonComponent.scss';

const IconButtonComponent = ({
  Modal, onClick, icon, className, tooltipLabel, tooltipPlace, language,
}) => {
  const id = uuidv4();
  const props = {
    icon,
    className,
    'data-tip': tooltipLabel ? tooltipLabel[language] : null,
    'data-place': tooltipPlace,
    'data-for': id,
  };

  if (Modal === null) {
    return (
      <span>
        {tooltipLabel && <Tooltip id={id} className="icon-tooltip" />}
        <FontAwesome onClick={onClick} {...props} />
      </span>
    );
  }

  return (
    <span>
      {tooltipLabel && <Tooltip id={id} />}
      <ModalHandler
        Modal={Modal}
        Trigger={{ Component: FontAwesome, props }}
      />
    </span>
  );
};

function mapStateToProps(state) {
  return {
    language: state.views.language,
  };
}

IconButtonComponent.propTypes = {
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }),
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  tooltipLabel: PropTypes.shape({}),
  tooltipPlace: PropTypes.string,
};
IconButtonComponent.defaultProps = {
  onClick: null,
  Modal: null,
  tooltipLabel: null,
  tooltipPlace: null,
};

export default connect(mapStateToProps)(IconButtonComponent);
