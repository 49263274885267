import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckboxToggle } from '~UI/index';

const FormImage = ({ selectedObject }) => {
  const { t } = useTranslation();

  const [linksTo, setLinksTo] = useState(selectedObject.linksTo || '');
  const [openNewTab, setToggle] = useState(selectedObject.openNewTab || false);

  useEffect(() => {
    if (!linksTo) {
      setToggle(false);
    }
  }, [linksTo]);

  const handleToggle = () => {
    setToggle(prevState => !prevState);
  };

  function handleLinksToChange(e) {
    setLinksTo(e.currentTarget.value);
  }

  return (
    <div>
      <div className="inputTitle">{t('LinksTo')}</div>
      <input
        name="linksTo"
        type="text"
        className="fullwidth"
        value={linksTo}
        onChange={handleLinksToChange}
      />

      <div className="inputTitle">
        {t('openNewTab')}
        &nbsp;
        <CheckboxToggle
          name="openNewTab"
          controlledCheck={openNewTab}
          onChange={handleToggle}
        />
      </div>
    </div>
  );
};

FormImage.propTypes = {
  selectedObject: PropTypes.object.isRequired,
};

export { FormImage };
