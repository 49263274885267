import { DEFAULT_ALIVE_DELAY } from '~utils/constants';

const getAliveBlocks = (alives, tsStart, currentTS, aliveDelay = DEFAULT_ALIVE_DELAY) => {
  // If this is the first time we receive a one and a single alive in the middle of the shift
  if (alives.length === 1 && alives[0].timestamp - aliveDelay > tsStart) {
    return [{
      start: tsStart,
      end: alives[0].timestamp - aliveDelay,
      status: alives[0].shutdown ? 'POWEREDOFF' : 'UNAVAILABLE',
    }, {
      start: alives[0].timestamp + aliveDelay,
      end: currentTS,
      status: alives[0].shutdown ? 'POWEREDOFF' : 'UNAVAILABLE',
    }];
  }
  // In case that we do not have an Alive during the shift or
  // the alives array has only one object: the "aliveBefore", the first alive before the shift
  if (alives.length === 0 || (alives.length === 1 && alives[0].timestamp + aliveDelay < tsStart)) {
    return [{
      start: tsStart,
      end: currentTS,
      status: alives.length === 1 && alives[0].shutdown ? 'POWEREDOFF' : 'UNAVAILABLE',
    }];
  }

  const alivesCopy = [...alives].sort((aliveA, aliveB) => aliveA.timestamp - aliveB.timestamp);
  const aliveBlocks = [];

  if (alivesCopy.length > 1) {
    // creates the first unavailable block, from before the shift to the first alive in the shift
    const firstAliveTS = alivesCopy[0].timestamp;
    const secondAliveTS = alivesCopy[1].timestamp;
    if ((secondAliveTS - firstAliveTS) > aliveDelay) {
      aliveBlocks.push({
        start: Math.max(firstAliveTS, tsStart),
        end: secondAliveTS,
        status: alivesCopy[0].shutdown ? 'POWEREDOFF' : 'UNAVAILABLE',
      });
    }
    // If the first alive is after tsStart: first stream connexion
    if (firstAliveTS > tsStart) {
      aliveBlocks.push({
        start: tsStart,
        end: firstAliveTS,
        status: 'UNAVAILABLE',
      });
    }

    // creates the last unavailable block, from the last received alive to the current timestamp
    const lastAliveTS = alivesCopy[alivesCopy.length - 1].timestamp;
    if ((currentTS - lastAliveTS) > aliveDelay) {
      aliveBlocks.push({
        start: lastAliveTS,
        end: currentTS,
        status: alivesCopy[alivesCopy.length - 1].shutdown ? 'POWEREDOFF' : 'UNAVAILABLE',
      });
    }

    // goes through the Alives and creates a block every time there are alives missing
    for (let i = 1; i < alivesCopy.length - 1; i += 1) {
      const start = alivesCopy[i].timestamp;
      const end = alivesCopy[i + 1].timestamp;
      if ((end - start) > aliveDelay) {
        aliveBlocks.push({
          start,
          end,
          status: alivesCopy[i].shutdown ? 'POWEREDOFF' : 'UNAVAILABLE',
        });
      }
    }
  }

  return [...aliveBlocks].sort((aliveA, aliveB) => aliveA.start - aliveB.start);
};

const getDataBlocks = (events, tsStart, currentTS) => {
  const eventsCopy = [...events].sort((eventA, eventB) => eventA.timestamp - eventB.timestamp);
  const dataBlocks = [];

  if (eventsCopy.length) {
    const firstEvent = eventsCopy[0];
    const nextEvent = eventsCopy[1];
    const start = Math.max(firstEvent.timestamp, tsStart);
    const end = nextEvent ? nextEvent.timestamp : currentTS;
    dataBlocks.push({
      id: firstEvent.id,
      status: firstEvent.status,
      motif: firstEvent.motif,
      causeId: firstEvent.stopCauseId || firstEvent.performanceCauseId,
      start,
      end: Math.max(start, end),
      params: {
        ...(firstEvent.operator && { operator: firstEvent.operator }),
        ...(firstEvent.operation && { operation: firstEvent.operation }),
        ...(firstEvent.skuNumber && { skuNumber: firstEvent.skuNumber }),
        ...(firstEvent.workOrder && { workOrder: firstEvent.workOrder }),
      },
    });
  }

  for (let i = 1; i < eventsCopy.length; i += 1) {
    const currentEvent = eventsCopy[i];
    const nextEvent = eventsCopy[i + 1];
    const start = Math.max(currentEvent.timestamp, tsStart);
    const end = nextEvent ? nextEvent.timestamp : currentTS;
    dataBlocks.push({
      id: currentEvent.id,
      status: currentEvent.status,
      motif: currentEvent.motif,
      causeId: currentEvent.stopCauseId || currentEvent.performanceCauseId,
      start,
      end: Math.max(start, end),
      params: {
        ...(currentEvent.operator && { operator: currentEvent.operator }),
        ...(currentEvent.operation && { operation: currentEvent.operation }),
        ...(currentEvent.skuNumber && { skuNumber: currentEvent.skuNumber }),
        ...(currentEvent.workOrder && { workOrder: currentEvent.workOrder }),
      },
    });
  }

  return [...dataBlocks].sort((blockA, blockB) => blockA.start - blockB.start);
};

const mergeAndSortBlocks = (aliveBlocks, dataBlocks) => {
  const blocks = [...aliveBlocks, ...dataBlocks];
  return blocks.sort((blockA, blockB) => {
    if (blockA.start !== blockB.start) {
      return blockA.start - blockB.start;
    }
    if (blockA.status === 'UNAVAILABLE' || blockA.status === 'POWEREDOFF') {
      return -1;
    }
    if (blockB.status === 'UNAVAILABLE' || blockA.status === 'POWEREDOFF') {
      return 1;
    }
    return 0;
  });
};

const determineBlockPriority = blocks => {
  const newBlocks = [];
  for (let i = 0; i < blocks.length; i += 1) {
    const block = blocks[i];
    const {
      status, start, end, motif, id, params, causeId,
    } = block;

    // Handles the UNAVAILABLE blocks. The valid event blocks are divided in the "else"
    if (status === 'UNAVAILABLE' || status === 'POWEREDOFF') {
      newBlocks.push({ ...block });
    } else {
      const overlappingAliveBlocks = blocks.filter(b => (b.status === 'UNAVAILABLE' || b.status === 'POWEREDOFF') && (b.end >= start && b.start < end));
      // UNAVAILABLE blocks that completely overlap normal block. If some exists, don't create the blocks under it
      const overrideAliveBlocks = blocks.filter(b => (b.status === 'UNAVAILABLE' || b.status === 'POWEREDOFF') && b.start <= start && b.end >= end);

      if (overrideAliveBlocks.length === 0) {
        // This is to create a valid event block.
        // If there is one or more overlapping Alive Blocks in this event,
        // we create the first event block between the start of the event and the start of the first UNAVAILABLE block.
        const endOfFirstSplitBlock = overlappingAliveBlocks.length ? overlappingAliveBlocks[0].start : end;
        if (endOfFirstSplitBlock > start) {
          newBlocks.push({
            start,
            end: endOfFirstSplitBlock,
            status,
            motif,
            causeId,
            id,
            params,
          });
        }

        // The other event blocks for that event (the ones between two UNAVAILABLE blocks) will be created here
        // It's important to note that we just split one event into "fake blocks" here for the timeline.
        for (let j = 0; j < overlappingAliveBlocks.length; j += 1) {
          const overlappingAliveBlock = overlappingAliveBlocks[j];
          const nextOverlappingAliveBlock = (j !== overlappingAliveBlocks.length - 1)
            ? overlappingAliveBlocks[j + 1]
            : null;
          const endOfFakeBlock = nextOverlappingAliveBlock ? nextOverlappingAliveBlock.start : end;
          if (overlappingAliveBlock.end <= end) {
            newBlocks.push({
              start: overlappingAliveBlock.end,
              end: endOfFakeBlock,
              status,
              motif,
              causeId,
              id,
              params,
            });
          }
        }
      }
    }
  }
  return newBlocks;
};

const getBlocks = (alives, events, tsStart, currentTS, aliveDelay = DEFAULT_ALIVE_DELAY, showAlive = false) => {
  const aliveBlocks = showAlive ? getAliveBlocks(alives, tsStart, currentTS, aliveDelay) : [];
  const dataBlocks = getDataBlocks(events, tsStart, currentTS);
  const mergedBlocks = mergeAndSortBlocks(aliveBlocks, dataBlocks);
  return determineBlockPriority(mergedBlocks);
};

export {
  getAliveBlocks,
  getDataBlocks,
  mergeAndSortBlocks,
  determineBlockPriority,
  getBlocks,
};
