import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { CheckboxToggle } from '~UI/index';
import { sortArray } from '~utils/sort';
import './TriggerForms.scss';

function MachineParamChangeTriggerForm({ trigger }) {
  const { t } = useTranslation();
  const { details } = trigger;

  const machines = useSelector((state: RootState) => state.machines);
  const operations = useSelector((state: RootState) => state.operations.operations);
  const operators = useSelector((state: RootState) => state.operators);

  const [machineId, setMachineId] = useState(details && details.machineId);
  const [param, setParam] = useState(details ? details.param : '');
  const [changesTo, setChangesTo] = useState(details ? details.changesTo : undefined);
  const [isInChangesToMode, setIsInChangesToMode] = useState(details && details.changesTo);
  const [isInUsePreviousValuesMode, setIsInUsePreviousValuesMode] = useState(details && details.usePreviousValues);
  const [isInAllExceptChangesToMode, setIsInAllExceptChangesToMode] = useState(details && details.allExceptChangesTo);

  const machine = machines.find(m => m.id === machineId);
  const machineOperators = operators?.filter(op => machine?.operators?.includes(op.id));

  const setToAllChanges = () => {
    setIsInChangesToMode(false);
    setChangesTo('');
  };

  const setToChangesTo = () => {
    setIsInChangesToMode(true);
    setIsInAllExceptChangesToMode(false);
    setChangesTo('');
  };

  const setToAllExceptChangesTo = () => {
    setIsInChangesToMode(true);
    setIsInAllExceptChangesToMode(true);
    setChangesTo('');
  };

  const renderChangesToInput = () => {
    if (param === 'operator') {
      return (
        <Select
          name="changesTo"
          style={!isInChangesToMode ? { width: '200px', display: 'inline-block', opacity: '0.6' }
            : { width: '200px', display: 'inline-block' }}
          value={changesTo}
          onChange={setChangesTo}
          disabled={!isInChangesToMode}
          options={((machine && sortArray('alphabetically', machineOperators, 'name')) || [])
            .map(operator => ({ label: operator.name, value: operator.name }))}
          placeholder={t('select')}
        />
      );
    }
    if (param === 'skuNumber') {
      return (
        <Select
          name="changesTo"
          style={!isInChangesToMode ? { width: '200px', display: 'inline-block', opacity: '0.6' }
            : { width: '200px', display: 'inline-block' }}
          value={changesTo}
          onChange={setChangesTo}
          disabled={!isInChangesToMode}
          options={((machine && sortArray('alphabetically', machine.skuNumbers, 'name')) || []).map(sku => (
            { label: sku.name, value: sku.name }))}
          placeholder={t('select')}
        />
      );
    }

    if (param === 'operation') {
      return (
        <Select
          name="changesTo"
          style={!isInChangesToMode ? { width: '200px', display: 'inline-block', opacity: '0.6' }
            : { width: '200px', display: 'inline-block' }}
          value={changesTo}
          onChange={setChangesTo}
          disabled={!isInChangesToMode}
          options={operations && operations.map(o => (
            { label: o.name, value: o.name }))}
          placeholder={t('select')}
        />
      );
    }
    return (
      <Input
        style={!isInChangesToMode && { opacity: '0.6' }}
        disabled={!isInChangesToMode}
        name="changesTo"
        onChange={value => setChangesTo(value)}
        value={changesTo}
        placeholder={t('valueInput')}
      />
    );
  };

  const renderPlaceHolderInput = () => {
    if (param === 'operator') {
      return (
        <Select
          disabled
          style={{ width: '200px', display: 'inline-block', opacity: '0.6' }}
          placeholder={t('select')}
        />
      );
    }
    if (param === 'skuNumber') {
      return (
        <Select
          disabled
          style={{ width: '200px', display: 'inline-block', opacity: '0.6' }}
          placeholder={t('select')}
        />
      );
    }

    if (param === 'operation') {
      return (
        <Select
          disabled
          style={{ width: '200px', display: 'inline-block', opacity: '0.6' }}
          placeholder={t('select')}
        />
      );
    }
    return (
      <Input
        style={{ opacity: '0.6' }}
        disabled
        name="changesTo"
        placeholder={t('valueInput')}
      />
    );
  };

  return (
    <div className="valuesTriggerForm">
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }))}
        value={machineId}
        onChange={setMachineId}
        placeholder={t('select')}
      />
      {
        machine && (
          <>
            <div className="inputTitle">{t('machineParam')}</div>
            <Select
              name="param"
              className="fullwidth"
              value={param}
              onChange={e => {
                setParam(e);
                setChangesTo('');
              }}
              options={Object.keys(machine.params || {})
                .map(par => ({ label: par, value: par }))}
              placeholder={t('select')}
            />
          </>
        )
      }
      <div className="willTriggerOn">
        <div className="inputTitle">{t('willTriggerOn')}</div>
        <div className="options">
          <input
            className="optionRadio"
            type="radio"
            name="triggerType"
            defaultChecked={!isInChangesToMode}
            onClick={setToAllChanges}
            form="ValueShouldNotBeInFormData"
          />
          <div className="optionName">{t('everyChanges')}</div>
        </div>

        <div className="options">
          <input
            className="optionRadio"
            type="radio"
            name="triggerType"
            defaultChecked={isInChangesToMode}
            onClick={setToChangesTo}
            form="ValueShouldNotBeInFormData"
          />

          <div className="optionName">{t('onlyWhenMachineParamChangesTo')}</div>
          {(isInChangesToMode && !isInAllExceptChangesToMode && renderChangesToInput()) || renderPlaceHolderInput()}
        </div>

        <input type="hidden" name="boolean:allExceptChangesTo" value={isInAllExceptChangesToMode} />

        <div className="options">
          <input
            className="optionRadio"
            type="radio"
            name="triggerType"
            defaultChecked={isInChangesToMode && isInAllExceptChangesToMode}
            onClick={setToAllExceptChangesTo}
            form="ValueShouldNotBeInFormData"
          />
          <div className="optionName">{t('onlyWhenMachineParamDoesNotChangeTo')}</div>
          {(isInChangesToMode && isInAllExceptChangesToMode && renderChangesToInput()) || renderPlaceHolderInput()}
        </div>
      </div>
      <div>
        <div className="inputTitle">
          {t('usePreviousValues')}
          &nbsp;
          <CheckboxToggle
            name="usePreviousValues"
            controlledCheck={isInUsePreviousValuesMode}
            onChange={e => setIsInUsePreviousValuesMode(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

MachineParamChangeTriggerForm.propTypes = {
  trigger: PropTypes.shape({
    details: PropTypes.shape({
      machineId: PropTypes.string,
      param: PropTypes.string,
      changesTo: PropTypes.number,
      usePreviousValues: PropTypes.bool,
      allExceptChangesTo: PropTypes.bool,
    }),
  }).isRequired,
};

export default MachineParamChangeTriggerForm;
