import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Collapse } from 'react-bootstrap';
import { sortArray } from '~utils/sort';
import { DeleteIcon } from '~components/UI/IconButtons';

import { CheckboxToggle, DefaultThemedButton, MultiSelect } from '~UI/index';

import './EventPageSettings.scss';

const InputRows = ({
  title,
  open,
  setOpen,
  items,
  onNameChange,
  onSelectionChange,
  onRemoveItem,
  onAddItem,
  buttonText,
}) => {
  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);

  return (
    <div className="rowGroup">
      <div className="titleContainer">
        <div className="filterTitle">
          {title}
          &nbsp;
        </div>
        <span
          className="arrowRight"
          onClick={() => setOpen(!open)}
        >
          &rang;
        </span>
      </div>
      <Collapse in={open}>
        <div>
          {items?.map(item => (
            <div className="inputRow" key={item?.id}>
              <div className="inputGroup">
                <div className="filterTitle">
                  {t('name')}
                </div>
                <input
                  type="text"
                  value={item.name}
                  onChange={e => onNameChange(item.id, e.target.value)}
                />
              </div>
              <div>
                <div className="filterTitle">
                  {t('machines')}
                </div>
                {
                  machines && (
                    <MultiSelect
                      className="selectDropdown"
                      options={sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }))}
                      value={item.machines?.map(machineId => {
                        const machine = machines.find(m => m.id === machineId);
                        return { label: machine?.name, value: machine?.id };
                      })}
                      onChange={options => onSelectionChange(item.id, options)}
                    />
                  )
                }
              </div>
              <div className="deleteIcon">
                <DeleteIcon onClick={() => onRemoveItem(item.id)} />
              </div>
            </div>
          ))}
          <DefaultThemedButton onClick={onAddItem} content={t(buttonText)} className="addButton" />
        </div>
      </Collapse>
    </div>
  );
};

const EventPageSettings = ({ eventPageFilters, setEventPageFilters }) => {
  const { t } = useTranslation();

  const [openDepartments, setOpenDepartments] = useState(false);
  const [openSites, setOpenSites] = useState(false);

  const handleNameChange = (type, id, newName) => {
    setEventPageFilters(prevState => {
      const newItems = prevState[type].map(
        item => (item.id === id ? { ...item, name: newName } : item),
      );
      return { ...prevState, [type]: newItems };
    });
  };

  const handleMachineSelectionChange = (type, id, newSelection) => {
    setEventPageFilters(prevState => {
      const newItems = prevState[type].map(
        item => (item.id === id
          ? { ...item, machines: newSelection.map(machine => machine.value) }
          : item),
      );
      return { ...prevState, [type]: newItems };
    });
  };

  const addItem = type => {
    setEventPageFilters(prevState => ({
      ...prevState,
      [type]: [...prevState[type], { id: uuidv4(), name: '', machines: [] }],
    }));
  };

  const removeItem = (type, id) => {
    setEventPageFilters(prevState => ({
      ...prevState,
      [type]: prevState[type].filter(item => item.id !== id),
    }));
  };

  const handleFilterChange = filterName => {
    setEventPageFilters({
      ...eventPageFilters,
      [filterName]: !eventPageFilters[filterName],
    });
  };

  return (
    <div className="eventPageSettingsModificationForm">
      <div className="eventPageSettingsTitle">
        {t('eventPageSettings')}
      </div>
      <div>
        <div className="formsContainer">
          <div className="subTitle">
            {t('selectFiltersEventPageSettings')}
            :
          </div>
          <div className="settingsInputGroup">
            <div className="rowContainer">
              <InputRows
                title="Sites"
                open={openSites}
                setOpen={setOpenSites}
                items={eventPageFilters.sites}
                onNameChange={(id, newName) => handleNameChange('sites', id, newName)}
                onSelectionChange={(id, newSelection) => handleMachineSelectionChange('sites', id, newSelection)}
                onRemoveItem={id => removeItem('sites', id)}
                onAddItem={() => addItem('sites')}
                buttonText="addSite"
              />

              <InputRows
                title={t('departments')}
                open={openDepartments}
                setOpen={setOpenDepartments}
                items={eventPageFilters.departments}
                onNameChange={(id, newName) => handleNameChange('departments', id, newName)}
                onSelectionChange={(id, newSelection) => handleMachineSelectionChange('departments', id, newSelection)}
                onRemoveItem={id => removeItem('departments', id)}
                onAddItem={() => addItem('departments')}
                buttonText="addDepartment"
              />
            </div>
            <div className="filterTitle">
              {t('skuNumber')}
                  &nbsp;
              <CheckboxToggle
                controlledCheck={eventPageFilters.skuNumber}
                onChange={() => handleFilterChange('skuNumber')}
              />
            </div>
            <div className="filterTitle">
              {t('operation')}
                  &nbsp;
              <CheckboxToggle
                controlledCheck={eventPageFilters.operation}
                onChange={() => handleFilterChange('operation')}
              />
            </div>
            <div className="filterTitle">
              {t('workOrder')}
                  &nbsp;
              <CheckboxToggle
                controlledCheck={eventPageFilters.workOrder}
                onChange={() => handleFilterChange('workOrder')}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="settingsModificationFormFooter">
        <span />
        <div />
      </div>
    </div>
  );
};

EventPageSettings.propTypes = {
  eventPageFilters: PropTypes.shape({
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        machines: PropTypes.arrayOf(
          PropTypes.string.isRequired,
        ).isRequired,
      }),
    ),
    departments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        machines: PropTypes.arrayOf(
          PropTypes.string.isRequired,
        ).isRequired,
      }),
    ),
    skuNumber: PropTypes.bool.isRequired,
    operation: PropTypes.bool.isRequired,
    workOrder: PropTypes.bool.isRequired,
  }).isRequired,
  setEventPageFilters: PropTypes.func.isRequired,
};

InputRows.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      machines: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
  ).isRequired,
  onNameChange: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  onAddItem: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export { EventPageSettings };
