import { Dispatch } from 'redux';
import {
  getOperators as APIgetOperators,
  createOperator as APIcreateOperator,
  updateOperator as APIupdateOperator,
  deleteOperator as APIdeleteOperator,
} from './endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  getOperatorsRequest,
  getOperatorsResponse,
  addOperatorRequest,
  addOperatorResponse,
  updateOperatorRequest,
  updateOperatorResponse,
  deleteOperatorRequest,
  deleteOperatorResponse,
  OperatorAction,
} from './actions';
import { Operator } from './types';

type OperatorDispatch = Dispatch<OperatorAction>;

function fetchOperators() {
  return fetchOnceOperation(
    getOperatorsRequest,
    getOperatorsResponse,
    APIgetOperators,
    state => state.operators,
  );
}

function addOperator(operator: Operator) {
  return (dispatch: OperatorDispatch) => {
    dispatch(addOperatorRequest({ operator }));
    return APIcreateOperator(operator)
      .then(res => dispatch(addOperatorResponse(res)));
  };
}

function updateOperator(id: string, operator: Operator) {
  return (dispatch: OperatorDispatch) => {
    dispatch(updateOperatorRequest({ id, operator }));
    return APIupdateOperator(id, operator)
      .then(res => dispatch(updateOperatorResponse(res)));
  };
}

function deleteOperator(id: string) {
  return (dispatch: OperatorDispatch) => {
    dispatch(deleteOperatorRequest({ id }));
    return APIdeleteOperator(id)
      .then(res => dispatch(deleteOperatorResponse(res)));
  };
}

export default {
  addOperator,
  deleteOperator,
  fetchOperators,
  updateOperator,
};
