import { DateTime } from 'luxon';

const dayInMinutes = 24 * 60;
const hoursInMinutes = 60;

export interface IRelativeTime {
  id: string;
  start: number;
  end: number;
}

export default shift => {
  const relativeTimes: IRelativeTime[] = [];
  if (!shift.days) {
    shift.days = [0, 1, 2, 3, 4, 5, 6];
  }

  shift.days.forEach(day => {
    const startDateTime = DateTime.fromObject({
      hour: shift.hourStart,
      minute: shift.minuteStart,
    }).setZone(shift.timezone);

    const endDateTime = DateTime.fromObject({
      hour: shift.hourEnd,
      minute: shift.minuteEnd,
    }).setZone(shift.timezone);

    const start = dayInMinutes * day + hoursInMinutes * shift.hourStart + shift.minuteStart - startDateTime.offset;
    let end = 0;
    // If the end of the shift is the next day (ex: from 20h12 to 5h10 the next day)
    if (hoursInMinutes * shift.hourEnd + shift.minuteEnd <= hoursInMinutes * shift.hourStart + shift.minuteStart) {
      const dayNumber = (day + 1) % 7;
      end = dayInMinutes * dayNumber + hoursInMinutes * shift.hourEnd + shift.minuteEnd - endDateTime.offset;
    } else {
      end = dayInMinutes * day + hoursInMinutes * shift.hourEnd + shift.minuteEnd - endDateTime.offset;
    }
    relativeTimes.push({ start, end, id: shift.id });
  });

  return relativeTimes.sort((a, b) => a.start - b.start);
};
