import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckboxToggle, RangeSlider } from '~UI/index';

const FormIframe = ({ selectedObject }) => {
  const { t } = useTranslation();

  const [refreshRateToggle, setToggle] = useState(selectedObject.refreshRateToggle || false);
  const [refreshRateValue, setRateValue] = useState(selectedObject.refreshRateValue || 30);

  const handleRefreshRateToggle = () => {
    setToggle(prevState => !prevState);
  };

  const handleSliderRange = e => {
    setRateValue(e);
  };

  return (
    <div>
      <div className="inputTitle">
        {t('refreshRate')}
        &nbsp;
        <CheckboxToggle
          name="refreshRateToggle"
          controlledCheck={refreshRateToggle}
          onChange={handleRefreshRateToggle}
        />
      </div>

      {refreshRateToggle && (
        <div>
          <RangeSlider
            defaultValue={refreshRateValue}
            onChange={handleSliderRange}
            min={1}
            max={90}
            unit="mins"
          />
          <input
            type="hidden"
            name="refreshRateValue"
            value={refreshRateValue}
          />
        </div>
      )}

      <div className="inputTitle">{t('scaleFactor')}</div>
      <input
        name="scale"
        type="number"
        min="0"
        step="0.05"
        defaultValue={selectedObject.scale || 1}
      />
    </div>
  );
};

FormIframe.propTypes = {
  selectedObject: PropTypes.object.isRequired,
};

export { FormIframe };
