import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle, IntervalTimePicker } from '~UI';

import {
  getStreamsToGroupedOptions,
  getVariablesToGroupedOptions,
  getMachinesKPISToGroupedOptions,
} from '../utils';

import '../Forms.scss';

const FormGaugeVariables = ({ selectedObject, selectedType, showArrows }) => {
  const { t } = useTranslation();

  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);
  const machines = useSelector(state => state.machines);

  const variableOptions = [
    getVariablesToGroupedOptions(variables),
    ...getStreamsToGroupedOptions(streams),
    ...getMachinesKPISToGroupedOptions(machines),
  ];

  const properties = [].concat(...streams.map(s => s.properties));
  const machinesKPIS = [].concat(...machines.map(m => m.kpis));
  const all = [...properties, ...variables, ...machinesKPIS];

  const [valueId, setValueId] = useState(selectedObject.valueId);
  const [intervalMax, setIntervalMax] = useState(selectedObject.intervalMax || 100);
  const [intervalMin, setIntervalMin] = useState(selectedObject.intervalMin || 0);
  const [isAggregated, setAggregated] = useState(selectedObject.isAggregated || false);
  const [isEnabled, setEnabled] = useState(false);

  const valueStillExists = !valueId || all.find(v => v.id === valueId);
  const aggregateOptions = [
    { label: t('sum'), value: 'sum' },
    { label: t('minimum'), value: 'min' },
    { label: t('maximum'), value: 'max' },
  ];

  useEffect(() => {
    const currentVariable = all.find(a => a.id === valueId);
    if (currentVariable) {
      setEnabled(currentVariable.isEnabled);
    }
  }, [valueId]);

  useEffect(() => {
    setAggregated(false);
  }, [showArrows]);

  return (
    <div>
      <div className="inputTitle">{t('variable')}</div>
      {!valueStillExists && `${t('errorDeletedVariable')}.`}
      <Select
        name="valueId"
        options={variableOptions}
        value={valueId}
        onChange={setValueId}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('unitMeasurement')}</div>
      <input
        type="text"
        name="units"
        className="fullwidth"
        defaultValue={selectedObject.units}
      />

      <div className="inputTitle">{t('interval')}</div>
      <span>{t('between')}</span>
      &nbsp;
      <input
        type="number"
        name="number:intervalMin"
        min={0}
        step={1}
        value={selectedType === 'circularChart' ? 0 : intervalMin}
        onChange={e => setIntervalMin(e.currentTarget.value)}
      />
      &nbsp;
      <span>{t('and')}</span>
      &nbsp;
      <input
        type="number"
        name="number:intervalMax"
        min={0}
        step={1}
        value={intervalMax}
        onChange={e => setIntervalMax(e.currentTarget.value)}
      />

      <div className="inputTitle">
        {t('dataCollectionPeriod')}
        &nbsp;
        <CheckboxToggle
          name="isAggregated"
          controlledCheck={isEnabled && isAggregated}
          onChange={e => setAggregated(e.target.value)}
          readOnly={!isEnabled || showArrows}
        />
      </div>
      {isEnabled && isAggregated && (
        <div className="coloredLeftBorderBlock">
          <IntervalTimePicker
            intervalType={selectedObject.intervalType}
            intervalTypeInput
          />
          <div className="inputTitle">{t('aggregate')}</div>
          <Select
            name="aggregateType"
            style={{ width: '12%' }}
            defaultValue={selectedObject.aggregateType || 'sum'}
            options={aggregateOptions}
          />
        </div>
      )}

      <div style={{ color: 'lightBlue', display: isEnabled ? 'none' : 'block' }}>{t('cannotToggleDataPeriod')}</div>
      <div style={{ color: 'lightBlue', display: isEnabled && showArrows ? 'block' : 'none' }}>{t('cannotToggleDataPeriodTimeNavigation')}</div>
    </div>
  );
};

FormGaugeVariables.propTypes = {
  selectedObject: PropTypes.shape({
    aggregateType: PropTypes.string,
    intervalMax: PropTypes.number,
    intervalMin: PropTypes.number,
    intervalType: PropTypes.string,
    isAggregated: PropTypes.bool,
    valueId: PropTypes.string,
    units: PropTypes.string,
  }),
  selectedType: PropTypes.string.isRequired,
  showArrows: PropTypes.bool,
};
FormGaugeVariables.defaultProps = {
  selectedObject: {},
};

export { FormGaugeVariables };
