import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import { ruleTypes } from '~utils/types';
import './NavigationTabs.scss';

import {
  shouldDisplayAdvancedTab,
  shouldDisplayColorRuleTab,
  shouldDisplayHideRuleTab,
  shouldDisplayMediaRuleTab,
  shouldDisplayTriggerRuleTab,
  shouldDisplayStyleTab,
  shouldDisplayTextRuleTab,
} from './displayTabs';

const NavigationTabs = ({
  isNew, isTile, navSelected, onSelect, selectedObject, type,
}) => {
  const { t } = useTranslation();

  const colorRules = (selectedObject.rules?.filter(r => r.type === ruleTypes.COLOR).length) || 0;
  const hideRules = (selectedObject.rules?.filter(r => r.type === ruleTypes.HIDE).length) || 0;
  const mediaRules = (selectedObject.rules?.filter(r => r.type === ruleTypes.MEDIA).length) || 0;
  const triggerRules = (selectedObject.rules?.filter(r => r.type === ruleTypes.TRIGGER).length) || 0;
  const textRules = (selectedObject.rules?.filter(r => r.type === ruleTypes.TEXT).length) || 0;

  return (
    <div className="no-wrap-tabs-container">
      <Nav
        bsStyle="tabs"
        activeKey={navSelected}
        onSelect={eventKey => onSelect(eventKey)}
        className="no-wrap-tabs"
      >
        <NavItem eventKey="parameters">
          {t('parameters')}
        </NavItem>
        {shouldDisplayAdvancedTab(type) && (
          <NavItem eventKey="advanced">
            {t('advanced')}
          </NavItem>
        )}
        {(isTile || shouldDisplayStyleTab(type)) && (
          <NavItem eventKey="style">
            {t('style')}
          </NavItem>
        )}
        {shouldDisplayColorRuleTab(type) && (
          <NavItem eventKey={ruleTypes.COLOR}>
            {`${t('colorRules')} (${colorRules})`}
          </NavItem>
        )}
        {!isNew && shouldDisplayTextRuleTab(type, selectedObject) && (
          <NavItem eventKey={ruleTypes.TEXT}>
            {`${t('textRules')} (${textRules})`}
          </NavItem>
        )}
        {!isNew && shouldDisplayMediaRuleTab(type, selectedObject) && (
          <NavItem eventKey={ruleTypes.MEDIA}>
            {`${t('mediaRules')} (${mediaRules})`}
          </NavItem>
        )}
        {!isNew && shouldDisplayTriggerRuleTab(type) && (
          <NavItem eventKey={ruleTypes.TRIGGER}>
            {`${t('triggerRules')} (${triggerRules})`}
          </NavItem>
        )}
        {!isNew && shouldDisplayHideRuleTab(type) && (
          <NavItem eventKey={ruleTypes.HIDE}>
            {`${t('hideRules')} (${hideRules})`}
          </NavItem>
        )}
      </Nav>
    </div>
  );
};

NavigationTabs.propTypes = {
  isNew: PropTypes.bool,
  isTile: PropTypes.bool,
  navSelected: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedObject: PropTypes.object,
  type: PropTypes.string.isRequired,
};
NavigationTabs.defaultProps = {
  isNew: false,
  isTile: false,
  selectedObject: {},
};

export { NavigationTabs };
