enum Types {
  SET_CONFIGURATION_MODE = 'SET_CONFIGURATION_MODE',
  SET_FULLSCREEN = 'SET_FULLSCREEN',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_TRIGGERS_SEARCH_INPUT = 'SET_TRIGGERS_SEARCH_INPUT',
  SET_ACTIONS_SEARCH_INPUT = 'SET_ACTIONS_SEARCH_INPUT',
  SET_SIDEBAR_SEARCH_INPUT = 'SET_SIDEBAR_SEARCH_INPUT',
}

export default Types;
