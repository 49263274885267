import { fetchOnceOperation, fetchOperation } from '../../fetchOperation';
import {
  getMachinesRequest,
  getMachinesResponse,
  addMachineRequest,
  addMachineResponse,
  updateMachineRequest,
  updateMachineResponse,
  patchMachineRequest,
  patchMachineResponse,
  deleteMachineRequest,
  deleteMachineResponse,
} from '../actions';
import API from '~services/endpoints';

function fetchMachines() {
  return fetchOnceOperation(
    getMachinesRequest,
    getMachinesResponse,
    API.getMachines,
    state => state.machines,
  );
}

const forceFetchMachines = () => fetchOperation(
  getMachinesRequest,
  getMachinesResponse,
  API.getMachines,
  state => state.machines,
);

function addMachine(machine) {
  return dispatch => {
    dispatch(addMachineRequest(machine));
    return API.addMachine(machine)
      .then(res => {
        if (!res.error && res.machine) {
          dispatch(addMachineResponse(res));
        }
      });
  };
}

function updateMachine(machineId, machine) {
  return dispatch => {
    dispatch(updateMachineRequest({ machine }));
    return API.updateMachine(machineId, machine)
      .then(res => dispatch(updateMachineResponse(res)));
  };
}

function patchMachine(machineId, machine) {
  return dispatch => {
    dispatch(patchMachineRequest({ machine }));
    return API.patchMachine(machineId, machine)
      .then(res => dispatch(patchMachineResponse(res)));
  };
}

function deleteMachine(machineId) {
  return dispatch => {
    dispatch(deleteMachineRequest(machineId));
    return API.deleteMachine(machineId)
      .then(() => dispatch(deleteMachineResponse(machineId)));
  };
}

export {
  fetchMachines,
  forceFetchMachines,
  addMachine,
  updateMachine,
  patchMachine,
  deleteMachine,
};
