import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import './MachinePopup.scss';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  searchBar: PropTypes.node,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  children: PropTypes.node,
  type: PropTypes.string,
};

const defaultProps = {
  title: 'Popup',
  subtitle: null,
  searchBar: null,
  show: false,
  onHide: () => undefined,
  type: '',
  children: null,
};

const MachinePopup = ({
  title, subtitle, searchBar, children, show, onHide, type,
}) => (
  <Modal
    show={show}
    onHide={onHide}
    className={`MachinePopup theme-${localStorage.getItem('theme')} undraggable ${type}`}
    dialogClassName="modal-dialog-centered MachinePopup"
  >
    <Modal.Header className={subtitle ? '' : 'no-subtitle'}>
      <Modal.Title>
        {title}
        {subtitle}
      </Modal.Title>
      <div className="searchBarContainer">
        {searchBar}
      </div>
    </Modal.Header>

    <Modal.Body>
      {children}
    </Modal.Body>
  </Modal>
);

MachinePopup.propTypes = propTypes;
MachinePopup.defaultProps = defaultProps;

function mapStateToProps(state) {
  return {
    connexion: state.session.connexion,
  };
}

export default connect(mapStateToProps)(MachinePopup);
