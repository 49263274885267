import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { UserIcon } from '~UI/RoundButton/RoundIcons';
import { sortArray } from '~utils/sort';
import MachinePopup from './MachinePopup';

const MachineOperatorPopup = ({
  show,
  onHide,
  machineId,
  machineParams,
  onSubmitTrigger,
  params,
  eventModifyTrigger,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const machines = useSelector((state: RootState) => state.machines);
  const operators = useSelector((state: RootState) => state.operators);
  const machine = machines.find(m => m.id === machineId);
  const machineOperators = machine && operators?.filter(op => machine.operators.includes(op.id));

  const [operatorsFilter, setOperatorsFilter] = useState('');

  useEffect(() => {
    if (!show) {
      // Lets the popup close (otherwise we see the list reset before it's closed)
      setTimeout(() => {
        setOperatorsFilter('');
      }, 250);
    }
  }, [show]);

  const handleSelect = username => {
    if (eventModifyTrigger) {
      eventModifyTrigger(username);
    } else {
      reduxOperations.machines.setParams(machineId, { ...machineParams, operator: username })(dispatch);

      if (onSubmitTrigger) {
        const optionalTriggerParams = { ...params, username };
        reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
          ...optionalTriggerParams,
          machineId,
        })(dispatch);
      }
    }

    onHide();
  };

  const getContents = () => (
    <MachinePopup
      title={t('chooseOperator')}
      show={show}
      onHide={onHide}
    >
      <>
        <div className="searchBar">
          <input
            placeholder={t('Search')}
            onChange={e => setOperatorsFilter(e.currentTarget.value)}
            data-testid="machineOperatorPopup-searchInput"
          />
        </div>
        <div
          data-testid="machineOperatorPopup-usersList"
        >
          {
            sortArray('alphabetically', machineOperators || [], 'name')
              .filter(operator => operator.name.toLowerCase().includes(operatorsFilter.toLowerCase()))
              .map(operator => (
                <UserIcon
                  key={operator.id}
                  name={operator.name}
                  onClick={() => handleSelect(operator.name)}
                  backgroundURL={operator.image}
                />
              ))
          }
        </div>
      </>
    </MachinePopup>
  );

  return (
    <ResourcesHandler
      resources={[machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.machines.fetchMachines() as any),
      ]}
      getContents={getContents}
    />
  );
};

MachineOperatorPopup.propTypes = {
  // should be given by ModalHandler
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  machineId: PropTypes.string,
  machineParams: PropTypes.object,
  onSubmitTrigger: PropTypes.string,
  params: PropTypes.shape({}),
  eventModifyTrigger: PropTypes.func,
};
MachineOperatorPopup.defaultProps = {
  onSubmitTrigger: '',
  machineId: '',
  machineParams: {},
  params: {},
  eventModifyTrigger: null,
};

export default MachineOperatorPopup;
