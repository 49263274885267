import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '~UI/index';
import StopCauseAssignTriggerForm from './StopCauseAssignTriggerForm';

const StopCauseTriggerForm = ({ trigger, selectTriggerType }) => {
  const { t } = useTranslation();

  useEffect(() => {
    const triggerType = 'StopCauseAssignTrigger';
    selectTriggerType(triggerType);
  }, []);

  return (
    <div className="StopCauseTrigger">
      <div style={{ marginBottom: '7px', marginTop: '7px' }}>
        <FontAwesome icon="info-circle" />
        &nbsp;&nbsp;
        {t('stopCauseAssignDefinition')}
      </div>

      <StopCauseAssignTriggerForm trigger={trigger} />
    </div>
  );
};

StopCauseTriggerForm.propTypes = {
  trigger: PropTypes.object.isRequired,
  selectTriggerType: PropTypes.func.isRequired,
};

export default StopCauseTriggerForm;
