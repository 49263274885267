function getParameterIcon(name) {
  switch (name) {
    case 'operation':
      return 'cog';
    case 'operator':
      return 'user';
    case 'skuNumber':
      return 'hashtag';
    case 'workOrder':
      return 'ticket';
    default:
      return 'edit';
  }
}

export { getParameterIcon };
