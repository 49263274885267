import API from '~services/endpoints';
import { fetchOnceOperation } from '../fetchOperation';
import {
  createTopviewRequest, createTopviewResponse,
  readTopviewsRequest, readTopviewsResponse,
  updateTopviewRequest, updateTopviewResponse,
  deleteTopviewRequest, deleteTopviewResponse,
  createWidgetRequest, createWidgetResponse,
  updateWidgetRequest, updateWidgetResponse,
  deleteWidgetRequest, deleteWidgetResponse,
} from './actions';

// Topview

function createTopview(data) {
  const req = { data };
  return dispatch => {
    dispatch(createTopviewRequest(req));
    return API.addTopview(data)
      .then(res => dispatch(createTopviewResponse(res, req)));
  };
}

function fetchTopviews() {
  return fetchOnceOperation(
    readTopviewsRequest,
    readTopviewsResponse,
    API.getTopviews,
    state => state.topviews.topviews,
  );
}

function updateTopview(topviewId, data) {
  const req = { id: topviewId, data };
  return dispatch => {
    dispatch(updateTopviewRequest(req));
    return API.updateTopview(topviewId, data)
      .then(res => dispatch(updateTopviewResponse(res, req)));
  };
}

function deleteTopview(topviewId) {
  const req = { id: topviewId };
  return dispatch => {
    dispatch(deleteTopviewRequest(req));
    return API.deleteTopview(topviewId)
      .then(res => dispatch(deleteTopviewResponse(res, req)));
  };
}

// Widget
function createWidget(topviewId, data) {
  const req = { id: topviewId, data };
  return dispatch => {
    dispatch(createWidgetRequest(req));
    return API.addWidget(topviewId, data)
      .then(res => dispatch(createWidgetResponse(res, req)));
  };
}

function updateWidget(topviewId, widgetId, data) {
  const req = { id: topviewId, widgetId, data };
  return dispatch => {
    dispatch(updateWidgetRequest(req));
    return API.updateWidget(topviewId, widgetId, data)
      .then(res => dispatch(updateWidgetResponse(res, req)));
  };
}

function deleteWidget(topviewId, widgetId) {
  const req = { id: topviewId, widgetId };
  return dispatch => {
    dispatch(deleteWidgetRequest(req));
    return API.deleteWidget(topviewId, widgetId)
      .then(res => dispatch(deleteWidgetResponse(res, req)));
  };
}

export default {
  // Topview
  createTopview,
  fetchTopviews,
  updateTopview,
  deleteTopview,
  // Widget
  createWidget,
  updateWidget,
  deleteWidget,
};
