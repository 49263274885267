import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@intelligenceindustrielle/react-ui-components';
import LogoBlack from '~images/Logo_header_black.png';
import LogoWhite from '~images/Logo_header_white.png';
import { DefaultModal } from '~UI/DefaultModal';
import API from '~services/endpoints';
import './NPSPopup.scss';

const NPSPopup = ({ stayOnOutsideClick, closePopup, show }) => {
  const theme = useSelector(state => state.session.theme);
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);
  const [gradeProject, setGradeProject] = useState(0);

  const insertBugReport = async gradeRecommendation => {
    API.createEvaluation({
      gradeProject,
      gradeRecommendation,
    }).catch(() => { });
  };

  const getPageOne = () => {
    const numbers = [...Array(11).keys()];
    return (
      <>
        <img id="ii-icon" src={theme === 'Dark' ? LogoWhite : LogoBlack} alt="K2" width="50%" style={{ minWidth: '170px' }} />
        <div className="title">{t('areYouSatisfied')}</div>
        <div style={{ marginTop: '30px' }}>
          <div style={{ minHeight: '10px' }}>
            <div className="labelSatisfaction" style={{ textAlign: 'left' }}>{t('veryUnsatisfied')}</div>
            <div className="labelSatisfaction" style={{ textAlign: 'right' }}>{t('verySatisfied')}</div>
          </div>
          {
            numbers.map((content, index) => (
              <Button
                key={`satisfied_${index}`}
                className="npsButton"
                label={content.toString()}
                onClick={() => {
                  setPageNumber(prevPageNumber => prevPageNumber + 1);
                  setGradeProject(index);
                }}
              />
            ))
          }
        </div>
      </>
    );
  };

  const handleSubmit = gradeRecommendation => {
    setPageNumber(prevPageNumber => prevPageNumber + 1);
    insertBugReport(gradeRecommendation);
    setTimeout(() => {
      closePopup();
      setPageNumber(1);
    }, 2500);
  };

  const getPageTwo = () => {
    const numbers = [...Array(11).keys()];

    return (
      <>
        <img id="ii-icon" src={theme === 'Dark' ? LogoWhite : LogoBlack} alt="K2" width="50%" style={{ minWidth: '170px' }} />
        <div className="title">{t('probabilityRecommend')}</div>
        <div style={{ marginTop: '30px' }}>
          <div style={{ minHeight: '10px' }}>
            <div className="labelSatisfaction" style={{ textAlign: 'left' }}>{t('veryUnlikely')}</div>
            <div className="labelSatisfaction" style={{ textAlign: 'right' }}>{t('veryLikely')}</div>
          </div>
          {
            numbers.map((content, index) => (
              <Button
                key={`recommended_${index}`}
                className="npsButton"
                label={content.toString()}
                onClick={() => handleSubmit(index)}
              />
            ))
          }
        </div>
      </>
    );
  };

  const getPageThree = () => (
    <>
      <img id="ii-icon" src={theme === 'Dark' ? LogoWhite : LogoBlack} alt="K2" width="50%" style={{ minWidth: '170px' }} />
      <div className="title">{t('thankFeedback')}</div>
    </>
  );

  const getContents = () => {
    switch (pageNumber) {
      case 1:
        return getPageOne();
      case 2:
        return getPageTwo();
      case 3:
        return getPageThree();
      default:
        break;
    }
  };

  const cancelPopup = () => {
    closePopup();
    setPageNumber(1);
  };

  return (
    <DefaultModal
      closePopup={stayOnOutsideClick ? () => null : cancelPopup}
      headless
      show={show}
      children={(
        <div className="NPSPopup">
          <button
            type="button"
            className="close NPSPopupCloseButton"
            onClick={() => cancelPopup()}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          {getContents()}
        </div>
      )}
    />
  );
};

NPSPopup.propTypes = {
  stayOnOutsideClick: PropTypes.bool,
  closePopup: PropTypes.func,
  show: PropTypes.bool,
};

NPSPopup.DefaultModal = {
  stayOnOutsideClick: false,
};

export default NPSPopup;
