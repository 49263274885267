import React from 'react';
import PropTypes from 'prop-types';

import ScorecardVariable from './ScorecardVariable';
import ScorecardEvent from './ScorecardEvent';
import ScorecardMachine from './ScorecardMachine';

const scorecards = {
  variable: ScorecardVariable,
  event: ScorecardEvent,
  machine: ScorecardMachine,
};

const Scorecard = ({ selectedObject, ...props }) => {
  const ScorecardComponent = scorecards[selectedObject.scorecardType];
  return (
    <ScorecardComponent
      selectedObject={selectedObject}
      {...props}
    />
  );
};

Scorecard.propTypes = {
  selectedObject: PropTypes.object.isRequired,
};

export { Scorecard };
