const shouldDisplayFormContent = navSelected => {
  switch (navSelected) {
    case 'parameters':
    case 'advanced':
    case 'style':
      return true;
    default:
      return false;
  }
};

export {
  shouldDisplayFormContent,
};
