import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const DefaultSubmitButton = ({
  label,
  className,
  isActive,
  onClick,
  style,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      className={classnames({
        DefaultSubmitButton: true,
        IsActive: isActive,
        ...(className && { [className]: true }),
      })}
      onClick={onClick}
      style={style}
    >
      {label || t('submit')}
    </button>
  );
};

DefaultSubmitButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};
DefaultSubmitButton.defaultProps = {
  className: '',
  isActive: true,
  label: '',
  onClick: () => undefined,
  style: {},
};

export default DefaultSubmitButton;
