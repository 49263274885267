import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getSocket } from '~services/socket';
import { evaluate } from '~utils/parser';
import InfoPopup from '~components/UI/Popups/InfoPopup/InfoPopup';
import { PushButton } from '~UI';
import FontAwesome from '~UI/FontAwesome/FontAwesome';
import { getFontSizeToFitTile, getButtonFontSize } from '~utils/responsiveValue';
import TileContents from '../TileContents';

function InfoTile({
  backgroundColor,
  height,
  tile,
  width,
}) {
  const socket = getSocket();
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [btnSize, setBtnSize] = useState(false);
  const [mediaURL, setMediaURL] = useState();

  const { valueFontSize } = getFontSizeToFitTile(height, width, '0', false, false);

  const handleClosePopup = () => {
    setShowInfoPopup(false);
  };

  const handleInfoClick = () => {
    setShowInfoPopup(true);
  };

  const updateButtonSize = () => {
    setBtnSize(getButtonFontSize(height, width, 'medium'));
  };

  const handleSocketData = socketData => {
    const websocketData = JSON.parse(socketData);
    if (tile.rules) {
      let newURL = tile.url || '';
      const mediaRules = tile.rules.filter(rule => rule.type === 'media');
      for (const mediaRule of mediaRules) {
        if (evaluate(mediaRule.condition, websocketData, mediaRule.variableType)) {
          newURL = mediaRule.media;
          break;
        }
      }
      setMediaURL(newURL);
    }
  };

  useEffect(() => {
    updateButtonSize();
  }, []);

  useEffect(() => {
    if (socket && tile.url) {
      socket.on('data', handleSocketData);
    }
    return () => {
      if (socket) {
        socket.removeListener('data', handleSocketData);
      }
    };
  }, [tile, socket, mediaURL]);

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
    >
      {
        tile.style === 'button' ? (
          <div className="ButtonTile framed flex">
            <PushButton
              buttonSize={btnSize}
              buttonColor="#CCCCCC"
              buttonText="Info"
              onClick={handleInfoClick}
            />
          </div>
        ) : (
          <div className="framed flex V Info">
            <button
              className="info-button"
              onClick={handleInfoClick}
              type="button"
            >
              <FontAwesome icon="info-circle" style={{ fontSize: valueFontSize }} />
            </button>
          </div>
        )
      }

      <InfoPopup
        showInfoPopup={showInfoPopup}
        handleClosePopup={handleClosePopup}
        mediaURL={mediaURL}
      />
    </TileContents>
  );
}

InfoTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
};

export default InfoTile;
