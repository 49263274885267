import { splitTime } from '~utils/time';
import { setStringAt } from '~utils';

// This function converts the temporary array 'arr' used to format the cron into a string for the backend
// Ex: ["0", "*/10", "*", "*", "*", "*"] ==> "0 */10 * * * *"
const cronArrayToCronString = arr => {
  let result = '';
  if (!arr || !Array.isArray(arr) || arr.length !== 6) {
    return result;
  }

  for (let i = 0; i < 6; i += 1) {
    if (arr[i] === undefined) { throw new Error('Invalid argument (undefined)'); }
    result += `${arr[i]} `;
  }
  return result;
};

// If the interval is between two days (from 4PM to 3AM)
// Each day should be separated. E.g. from 4PM to 3AM every 2 hours: "16-23/2,0-2/2"
// The intervalAmount is 2. The intervalHours is "16-23,0-2"
const addIntervalHour = (intervalAmount, intervalHours) => {
  const index = intervalHours.indexOf(',');
  return `${setStringAt(intervalHours, index, `/${intervalAmount}`)}/${intervalAmount}`;
};

const convertFormToStringUtil = (
  selectedDays,
  startTimeStr,
  endTimeStr,
  specificTimeObj,
  intervalAmount,
  intervalUnit,
) => {
  const daysList = [
    selectedDays.sunday.value,
    selectedDays.monday.value,
    selectedDays.tuesday.value,
    selectedDays.wednesday.value,
    selectedDays.thursday.value,
    selectedDays.friday.value,
    selectedDays.saturday.value,
  ];
  const tempCronData = ['*', '*', '*', '*', '*', '*'];
  if (startTimeStr && endTimeStr) {
    let endHour = splitTime(endTimeStr).hours;
    const startHour = splitTime(startTimeStr).hours;
    if (endHour <= startHour && endHour !== 0) {
      tempCronData[1] = `${0}-${59}`;
      tempCronData[2] = `${startHour}-23,0-${parseInt(endHour, 10) - 1}`;
    } else {
      endHour = endHour === 0 ? '23' : parseInt(endHour, 10) - 1;
      tempCronData[1] = `${0}-${59}`;
      tempCronData[2] = `${startHour}-${endHour}`;
    }
  }
  if (intervalUnit === 'Seconds') {
    tempCronData[0] = `${tempCronData[0]}/${intervalAmount}`;
  } else if (intervalUnit === 'Minutes') {
    tempCronData[0] = '0';
    tempCronData[1] = `${tempCronData[1]}/${intervalAmount}`;
  } else if (tempCronData[2].length > 5) { // From here we suppose intervalUnit is 'Hours'
    tempCronData[0] = '0';
    tempCronData[1] = '0';
    tempCronData[2] = addIntervalHour(intervalAmount, tempCronData[2]);
  } else {
    tempCronData[0] = '0';
    tempCronData[1] = '0';
    tempCronData[2] = `${tempCronData[2]}/${intervalAmount}`;
  }

  if (daysList.indexOf(false) === -1) {
    tempCronData[5] = '*';
  } else {
    for (let i = 0; i < daysList.length; i += 1) {
      if (daysList[i]) {
        if (i === 0) {
          tempCronData[5] = i.toString();
        } else {
          tempCronData[5] = tempCronData[5] === '*' ? i.toString() : (`${tempCronData[5]},${i}`);
        }
      }
    }
  }
  if (specificTimeObj.hourSpecific !== null && specificTimeObj.minuteSpecific !== null) {
    tempCronData[1] = specificTimeObj.minuteSpecific.toString();
    tempCronData[2] = specificTimeObj.hourSpecific.toString();
    tempCronData[0] = '0';
  }
  const cronTime = tempCronData[0] === ''
    ? cronArrayToCronString(tempCronData).substr(1)
    : cronArrayToCronString(tempCronData);

  return { cron: cronTime.slice(0, -1) };
};

// Does not support every cron week day format
// Ex: "5 4 * * sun" from https://crontab.guru/
// Week days are only numbers (0 sunday, 6 is saturday)
const getSelectedDaysFromCron = (cron, allToTrue) => {
  if (!cron || cron.charAt(cron.length - 1) === '*' || allToTrue) {
    return {
      monday: { value: true, name: 'monday' },
      tuesday: { value: true, name: 'tuesday' },
      wednesday: { value: true, name: 'wednesday' },
      thursday: { value: true, name: 'thursday' },
      friday: { value: true, name: 'friday' },
      saturday: { value: true, name: 'saturday' },
      sunday: { value: true, name: 'sunday' },
    };
  }

  const days = cron.match(/\* \* (\d)(,\d)*/gm)[0];

  return {
    monday: { value: days.includes(1), name: 'monday' },
    tuesday: { value: days.includes(2), name: 'tuesday' },
    wednesday: { value: days.includes(3), name: 'wednesday' },
    thursday: { value: days.includes(4), name: 'thursday' },
    friday: { value: days.includes(5), name: 'friday' },
    saturday: { value: days.includes(6), name: 'saturday' },
    sunday: { value: days.includes(0), name: 'sunday' },
  };
};

const shiftToCronString = (shift, shiftTimeOption) => {
  const dayMapping = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const selectedDaysObject = dayMapping.reduce((acc, day, index) => {
    acc[day] = { value: shift.days.includes(index), name: day };
    return acc;
  }, {});

  let specificTimeObj;
  if (shiftTimeOption === 'beginning') {
    specificTimeObj = {
      hourSpecific: shift.hourStart,
      minuteSpecific: shift.minuteStart,
    };
  } else {
    specificTimeObj = {
      hourSpecific: shift.hourEnd,
      minuteSpecific: shift.minuteEnd,
    };
  }

  const { cron } = convertFormToStringUtil(
    selectedDaysObject,
    null,
    null,
    specificTimeObj,
    null,
    null,
  );
  return cron;
};

const getSelectedShiftsFromCron = (cronStrings, shiftTimeOption, repeatOption, factoryShifts, machineShifts) => {
  const cronArray = cronStrings.split('+');
  const selectedShifts = [];

  let shifts;
  if (repeatOption === 'factoryShifts') {
    shifts = factoryShifts;
  } else {
    shifts = machineShifts;
  }

  shifts.forEach(shift => {
    const cron = shiftToCronString(shift, shiftTimeOption);
    if (cronArray.includes(cron)) {
      selectedShifts.push(shift);
    }
  });
  return selectedShifts;
};

export {
  cronArrayToCronString,
  convertFormToStringUtil,
  getSelectedDaysFromCron,
  addIntervalHour,
  getSelectedShiftsFromCron,
  shiftToCronString,
};
