import React from 'react';
import PropTypes from 'prop-types';
import { reducersTypes } from '~services';
import { audioTypes } from '~utils/types';
import TileContents from '../TileContents';
import { AudioFile } from './AudioFile';
import { TextToSpeech } from './TextToSpeech';

const AudioTile = ({
  backgroundColor,
  height,
  tile,
  width,
}) => (
  <TileContents
    tile={tile}
    backgroundColor={backgroundColor}
    height={height}
    width={width}
  >
    <div className="framed flex V">
      {tile.audioType === audioTypes.FILE && (
        <AudioFile
          tile={tile}
        />
      )}
      {tile.audioType === audioTypes.TEXT_TO_SPEECH && (
        <TextToSpeech
          tile={tile}
        />
      )}
    </div>
  </TileContents>
);

AudioTile.propTypes = {
  backgroundColor: PropTypes.string,
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
  width: PropTypes.number.isRequired,
};
AudioTile.defaultProps = {
  backgroundColor: '',
};

export { AudioTile };
