const DEFAULT_ALIVE_DELAY = 180000; // 3 minutes
const FETCH_STATS_DELAY = 3.5; // 3,5 seconds
const FETCH_STATS_DELAY_MS = FETCH_STATS_DELAY * 1000;

const MAX_NB_EVENTS_ONE_PAGE = 50;
const MAX_NB_PAGES = 10;

const MAX_WIDTH_STANDARD_PHONE = 480;
const MAX_WIDTH_TABLET = 1024;
const MAX_WIDTH_VERTICAL_TABLET = 768;

const MIN_WIDTH_FULL_FOOTER = 670;
const MIN_WIDTH_FULL_MENU = 670;
const MIN_WIDTH_LOGIN = 400;

const SMALL_PHONE_WIDTH_FULL_MENU = 390;

export {
  DEFAULT_ALIVE_DELAY,
  FETCH_STATS_DELAY,
  FETCH_STATS_DELAY_MS,
  MAX_NB_EVENTS_ONE_PAGE,
  MAX_NB_PAGES,
  MAX_WIDTH_STANDARD_PHONE,
  MAX_WIDTH_TABLET,
  MAX_WIDTH_VERTICAL_TABLET,
  MIN_WIDTH_FULL_FOOTER,
  MIN_WIDTH_FULL_MENU,
  MIN_WIDTH_LOGIN,
  SMALL_PHONE_WIDTH_FULL_MENU,
};
