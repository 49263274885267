import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import {
  ArrowsMoveIcon, SettingsIcon, DeleteIcon, EditIcon, DuplicateIcon, DuplicateToIcon, WarningIcon,
} from './Icons';
import TileActions from './TileActions';
import './ConfigurationIcons.scss';
import MultipleDashboardsPopup from '../../../pages/Dashboards/Dashboard/Tile/MultipleDashboardsPopup';
import MultipleTopviewsPopup from '../../../pages/Topviews/TopviewWidget/MultipleTopviewsPopup';
import reduxOperations from '~services/reduxOperations';
import { showSuccess } from '~utils/toast';

const propTypes = {
  isSmall: PropTypes.bool,
  displayHeadband: PropTypes.bool,
  EditionForm: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }),
  DeletionForm: PropTypes.shape({
    Component: PropTypes.oneOfType([
      PropTypes.elementType,
      PropTypes.func,
    ]),
    props: PropTypes.shape({}),
  }),
  handleDuplication: PropTypes.func,
  tile: PropTypes.object,
  widget: PropTypes.object,
  dashboardId: PropTypes.string.isRequired,
  topviewId: PropTypes.string.isRequired,
  handleArrowsMove: PropTypes.func,
  handleArrowsIsMoving: PropTypes.bool,
  fontSize: PropTypes.string,
};

const defaultProps = {
  isSmall: false,
  displayHeadband: false,
  tile: {},
  widget: {},
  handleDuplication: null,
  handleArrowsMove: null,
  handleArrowsIsMoving: false,
  EditionForm: null,
  DeletionForm: null,
  fontSize: undefined,
};

const ConfigurationIcons = ({
  isSmall,
  displayHeadband,
  EditionForm,
  DeletionForm,
  handleDuplication,
  handleArrowsMove,
  handleArrowsIsMoving,
  fontSize,
  dashboardId,
  topviewId,
  tile,
  widget,
}) => {
  const { t } = useTranslation();

  const dashboards = useSelector(state => state.dashboards.dashboards);
  const topviews = useSelector(state => state.topviews.topviews);
  const validDashboards = dashboards.filter(dashboard => dashboard.id !== dashboardId);
  const validTopviews = topviews.filter(topview => topview.id !== topviewId);

  const dispatch = useDispatch();

  const [showDashboardPopup, setShowDashboardPopup] = useState(false);
  const [showTopviewPopup, setShowTopviewPopup] = useState(false);
  const [selectedDashboardIds, setSelectedDashboardIds] = useState([]);
  const [selectedTopviewIds, setSelectedTopviewIds] = useState([]);

  const handleDuplicateToDashboard = (id, tileClone) => {
    tileClone.rules.forEach(element => {
      delete element.id;
    });
    dispatch(reduxOperations.dashboards.addDashboardTile(id, tileClone));
  };

  const getImageDimensions = url => new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve({
      width: img.naturalWidth,
      height: img.naturalHeight,
    });
    img.src = url;
  });

  const handleDuplicateToTopview = (id, widgetClone) => {
    widgetClone.rules.forEach(element => {
      delete element.id;
    });
    dispatch(reduxOperations.topviews.createWidget(id, widgetClone));
  };

  const cancelDashboardPopup = () => setShowDashboardPopup(false);
  const cancelTopviewPopup = () => setShowTopviewPopup(false);

  const handleWidgetClone = (topview, widgetClone) => {
    widgetClone.rules.forEach(element => {
      omit(element, ['id']);
    });
    handleDuplicateToTopview(topview, widgetClone);
  };

  const operationTopview = () => {
    selectedTopviewIds.forEach(topview => {
      const selectedTopview = topviews.find(tv => tv.id === topview);
      const topviewUrl = selectedTopview?.backgroundURL;
      if (topviewUrl) {
        getImageDimensions(topviewUrl)
          .then(dimension => {
            const widgetClone = { ...omit(widget, ['_id', 'id', 'h', 'w', 'x', 'y', 'z']), y: (dimension.height * 3) / 100, h: (dimension.height * 5) / 100, w: (dimension.width * 5) / 100 };
            handleWidgetClone(topview, widgetClone);
          }).catch(err => console.error(err));
      } else {
        const widgetClone = omit(widget, ['_id', 'id', 'h', 'w', 'x', 'y', 'z']);
        handleWidgetClone(topview, widgetClone);
      }
    });
    showSuccess(t('operationSucceeded'));
    cancelTopviewPopup();
  };

  const operationDashboard = () => {
    const tileClone = omit(tile, ['_id']);
    tileClone.rules.forEach(element => {
      omit(element, ['id']);
    });
    selectedDashboardIds.forEach(dashboard => {
      handleDuplicateToDashboard(dashboard, tileClone);
    });
    showSuccess(t('operationSucceeded'));
    cancelDashboardPopup();
  };

  const selectTopview = selectedTopviewId => {
    const isAlreadySelected = selectedTopviewIds.find(id => id === selectedTopviewId);
    if (isAlreadySelected) {
      setSelectedTopviewIds(selectedTopviewIds.filter(id => selectedTopviewId !== id));
    } else {
      setSelectedTopviewIds([...selectedTopviewIds, selectedTopviewId]);
    }
  };

  const selectDashboard = selectedDashboardId => {
    const isAlreadySelected = selectedDashboardIds.find(id => id === selectedDashboardId);
    if (isAlreadySelected) {
      setSelectedDashboardIds(selectedDashboardIds.filter(id => selectedDashboardId !== id));
    } else {
      setSelectedDashboardIds([...selectedDashboardIds, selectedDashboardId]);
    }
  };

  const renderMultipleDashboardsPopup = () => (
    <MultipleDashboardsPopup
      showPopup={showDashboardPopup}
      operation={operationDashboard}
      validDashboards={validDashboards}
      dashboardsSelected={selectedDashboardIds}
      cancelPopup={cancelDashboardPopup}
      selectDashboard={id => selectDashboard(id)}
    />
  );

  const renderMultipleTopviewsPopup = () => (
    <MultipleTopviewsPopup
      showPopup={showTopviewPopup}
      operation={operationTopview}
      validTopviews={validTopviews}
      topviewsSelected={selectedTopviewIds}
      cancelPopup={cancelTopviewPopup}
      selectTopview={id => selectTopview(id)}
    />
  );

  const displayDashboards = () => {
    setShowDashboardPopup(true);
  };

  const displayTopviews = () => {
    setShowTopviewPopup(true);
  };

  const verifyOnClickTrigger = element => element?.type === 'button' && !element?.onClickTrigger;

  return (

    <div className={`ConfigBtns ${isSmall ? 'small' : 'big'} ${displayHeadband ? 'Head' : ''}`} style={{ fontSize }}>
      {showDashboardPopup && renderMultipleDashboardsPopup()}
      {showTopviewPopup && renderMultipleTopviewsPopup()}
      {(verifyOnClickTrigger(widget) || verifyOnClickTrigger(tile)) && <WarningIcon />}
      {handleDuplication && topviewId && (
        <DuplicateToIcon onClick={displayTopviews} />
      )}
      {handleArrowsMove && <ArrowsMoveIcon onClick={handleArrowsMove} handleArrowsIsMoving={handleArrowsIsMoving} />}
      {handleDuplication && dashboardId && (
        <TileActions
          duplicateAction={handleDuplication}
          duplicateToAction={displayDashboards}
        />
      )}
      {handleDuplication && topviewId && (
        <DuplicateIcon onClick={handleDuplication} />
      )}
      {
        isSmall
          ? <EditIcon Modal={EditionForm} />
          : <SettingsIcon Modal={EditionForm} />
      }
      <DeleteIcon Modal={DeletionForm} />
    </div>
  );
};

ConfigurationIcons.propTypes = propTypes;
ConfigurationIcons.defaultProps = defaultProps;

export default ConfigurationIcons;
