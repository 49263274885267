import {
  addPerformanceCauseRequest,
  addPerformanceCauseResponse,
  updatePerformanceCauseRequest,
  updatePerformanceCauseResponse,
  deletePerformanceCauseRequest,
  deletePerformanceCauseResponse,
  addSubPerformanceCauseRequest,
  addSubPerformanceCauseResponse,
  updateSubPerformanceCauseRequest,
  updateSubPerformanceCauseResponse,
} from '../actions';
import API from '~services/endpoints';

function addPerformanceCause(machineId, performanceCause) {
  return dispatch => {
    dispatch(addPerformanceCauseRequest({ machineId, performanceCause }));
    return API.createPerformanceCause(machineId, performanceCause)
      .then(res => {
        if (!res.error) {
          dispatch(addPerformanceCauseResponse({ machineId, machine: res.machine }));
        }
      });
  };
}

function updatePerformanceCause(machineId, performanceCauseId, performanceCause) {
  return dispatch => {
    dispatch(updatePerformanceCauseRequest({ machineId, performanceCauseId, performanceCause }));
    return API.updatePerformanceCause(machineId, performanceCauseId, performanceCause)
      .then(res => dispatch(updatePerformanceCauseResponse({ machineId, machine: res.machine })));
  };
}

function deletePerformanceCause(machineId, performanceCauseId) {
  return dispatch => {
    dispatch(deletePerformanceCauseRequest({ machineId, performanceCauseId }));
    return API.deletePerformanceCause(machineId, performanceCauseId)
      .then(res => dispatch(deletePerformanceCauseResponse({ machineId, machine: res.machine })));
  };
}

function addSubPerformanceCause(machineId, parentCauseId, performanceCause) {
  return dispatch => {
    dispatch(addSubPerformanceCauseRequest({ machineId, parentCauseId, performanceCause }));
    return API.createSubPerformanceCause(machineId, parentCauseId, performanceCause)
      .then(res => dispatch(addSubPerformanceCauseResponse({ machineId, machine: res.machine })));
  };
}

function updateSubPerformanceCause(machineId, parentCauseId, performanceCauseId, performanceCause) {
  return dispatch => {
    dispatch(updateSubPerformanceCauseRequest({ machineId, parentCauseId, performanceCauseId, performanceCause }));
    return API.updateSubPerformanceCause(machineId, parentCauseId, performanceCauseId, performanceCause)
      .then(res => dispatch(updateSubPerformanceCauseResponse({ machineId, machine: res.machine })));
  };
}

export {
  addPerformanceCause,
  updatePerformanceCause,
  deletePerformanceCause,
  addSubPerformanceCause,
  updateSubPerformanceCause,
};
