import React from 'react';

const carousel = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 174" fill="currentColor" height={height}>
    <g
      transform="translate(0.000000,174.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path d="M730 870 l0 -580 420 0 420 0 0 580 0 580 -420 0 -420 0 0 -580z
m670 0 l0 -420 -250 0 -250 0 0 420 0 420 250 0 250 0 0 -420z"
      />
      <path d="M320 870 l0 -420 165 0 165 0 0 420 0 420 -165 0 -165 0 0 -420z" />
      <path d="M1650 870 l0 -420 165 0 165 0 0 420 0 420 -165 0 -165 0 0 -420z" />
    </g>
  </svg>
);

export default carousel;
