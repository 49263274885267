import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { AreaChart } from '~UI/svg';
import { dateToStringWithoutYearAndMillis } from '~utils/time';
import { FontAwesome } from '~components/UI';
import TileContents from '../TileContents';
import '../TileContents.scss';

const AreaChartComponent = ({
  backgroundColor,
  canNavigateTime,
  data,
  hasUsedArrows,
  height,
  navigateTime,
  tile,
  timePeriod,
  width,
}) => {
  const container = useRef();
  const { t } = useTranslation();
  // const intervalType = tile.intervalType || 'shift';
  // const [currentShift] = useShift(null);
  // const { start, end } = getDataRangeStartEnd(intervalType, currentShift);

  const navigationText = hasUsedArrows
    ? `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.start))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.end))}`
    : '';

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      height={height}
      width={width}
      ref={container}
    >
      {!data && <Loader />}
      <div className={`timeNavigationContainer ${!data && 'hide'}`}>
        <div>{navigationText}</div>
        <div className="timeNavigation">
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-left"
              className="timeNavigationButton"
              style={{ opacity: !canNavigateTime(true) && 0.4, cursor: !canNavigateTime(true) && 'default' }}
              onClick={() => navigateTime(true)}
            />
          )}
          <div className={data ? '' : 'hide'}>
            <AreaChart
              height={hasUsedArrows ? height - 26 : height}
              width={tile.showArrows ? width - 70 : width}
              domainX={[timePeriod.start, timePeriod.end]}
              timePeriod={timePeriod}
              data={data || []}
              showGoalToggle={tile.showGoalToggle}
              goal={tile.goal}
            />
          </div>
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-right"
              className="timeNavigationButton"
              style={{ opacity: !canNavigateTime(false) && 0.4, cursor: !canNavigateTime(false) && 'default' }}
              onClick={() => navigateTime(false)}
            />
          )}
        </div>
      </div>
    </TileContents>
  );
};

AreaChartComponent.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  canNavigateTime: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  hasUsedArrows: PropTypes.bool,
  height: PropTypes.number.isRequired,
  navigateTime: PropTypes.func,
  tile: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
    intervalType: PropTypes.string,
    showArrows: PropTypes.bool,
  }).isRequired,
  timePeriod: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

AreaChartComponent.defaultProps = {
  data: null,
  hasUsedArrows: false,
  canNavigateTime: () => { },
  navigateTime: () => { },
};

export default AreaChartComponent;
