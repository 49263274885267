enum Types {
  GET_TRIGGERS_REQUEST = 'GET_TRIGGERS_REQUEST',
  GET_TRIGGERS_RESPONSE = 'GET_TRIGGERS_RESPONSE',
  ADD_TRIGGER_REQUEST = 'ADD_TRIGGER_REQUEST',
  ADD_TRIGGER_RESPONSE = 'ADD_TRIGGER_RESPONSE',
  UPDATE_TRIGGER_REQUEST = 'UPDATE_TRIGGER_REQUEST',
  UPDATE_TRIGGER_RESPONSE = 'UPDATE_TRIGGER_RESPONSE',
  PATCH_TRIGGER_REQUEST = 'PATCH_TRIGGER_REQUEST',
  PATCH_TRIGGER_RESPONSE = 'PATCH_TRIGGER_RESPONSE',
  DELETE_TRIGGER_REQUEST = 'DELETE_TRIGGER_REQUEST',
  DELETE_TRIGGER_RESPONSE = 'DELETE_TRIGGER_RESPONSE',
  TRIGGER_TRIGGER_REQUEST = 'TRIGGER_TRIGGER_REQUEST',
  TRIGGER_TRIGGER_RESPONSE = 'TRIGGER_TRIGGER_RESPONSE',
}

export default Types;
