import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import types from './types';
import userTypes from '../users/types';

const INITIAL_STATE = { loggedin: false, cause: 'DEFAUT' };
const SIGN_UP_INITIAL_STATE = false;
const VERIFICATION_INITIAL_STATE = false;
const THEME_INITIAL_STATE = localStorage.getItem('theme') || 'Dark';

const isSignupPropTypes = PropTypes.bool;
function isSignup(state = SIGN_UP_INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_SIGNUP:
      return action.payload;
    default:
      return state;
  }
}

const isVerificationPropTypes = PropTypes.bool;
function isVerification(state = VERIFICATION_INITIAL_STATE, action) {
  switch (action.type) {
    case types.UPDATE_VERIFICATION:
      return action.payload;
    default:
      return state;
  }
}

const connexionPropTypes = PropTypes.shape({
  loggedin: PropTypes.bool,
  infos: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    email: PropTypes.string,
    language: PropTypes.string,
  }),
});

function connexion(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.LOGIN_RESPONSE:
      return action.payload;
    case types.LOGIN_MICROSOFT_RESPONSE:
      return action.payload;
    case types.LOGOUT_RESPONSE:
      return action.payload;
    case userTypes.GET_USER_RESPONSE: {
      const newState = {
        ...state,
        infos: {
          ...state.infos,
          email: action.payload.user.username,
          name: action.payload.user.name,
        },
      };
      return newState;
    }
    default:
      return state;
  }
}

const themePropTypes = PropTypes.string;
function theme(state = THEME_INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_THEME:
      localStorage.setItem('theme', action.payload);
      return action.payload;
    default:
      return state;
  }
}

export const propTypes = {
  connexion: connexionPropTypes,
  theme: themePropTypes,
  isSignup: isSignupPropTypes,
  isVerification: isVerificationPropTypes,
};

export default combineReducers({
  connexion,
  theme,
  isSignup,
  isVerification,
});
