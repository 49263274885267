import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import directLinks from '~utils/directLinks';
import { FontAwesome } from '~UI/index';

const MIN_WIDTH = 750;
const MIN_WIDTH_DESCRIPTION = 1000;

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  logo: PropTypes.string,
  redirectLink: PropTypes.string,
  onClick: PropTypes.func,
};
const defaultProps = {
  title: '',
  description: '',
  logo: '',
  redirectLink: directLinks.home(),
  onClick: () => undefined,
};

const HomeTileContainer = ({
  title, description, logo, redirectLink, onClick,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleSelection = () => {
    onClick();
    history.push(redirectLink);
  };

  const handleResize = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="Tile homeTile flex V cursorPointer">
      <div
        className="Tile__Content flex V"
        onClick={handleSelection}
      >
        <FontAwesome
          className="Tile__Content__Icon"
          height={windowWidth >= MIN_WIDTH ? 120 : 60}
          icon={logo}
          style={windowWidth >= MIN_WIDTH ? { fontSize: '120px' } : { fontSize: '60px' }}
        />
        {windowWidth >= MIN_WIDTH ? <h3>{t(title)}</h3> : <h4>{t(title)}</h4>}
        {windowWidth >= MIN_WIDTH_DESCRIPTION && <p>{description}</p>}
      </div>
    </div>
  );
};

HomeTileContainer.defaultProps = defaultProps;
HomeTileContainer.propTypes = propTypes;

export default HomeTileContainer;
