import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextInput } from '~UI';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';

const ScorecardEventForm = ({ selectedObject, eventNames }) => {
  const { t } = useTranslation();

  const [eventName, setEventName] = useState(selectedObject.eventName || '');

  return (
    <>
      <div className="inputTitle">{t('event')}</div>
      <TextInput
        name="eventName"
        options={eventNames}
        className="fullwidth"
        value={eventName}
        onChange={name => setEventName(name)}
      />
      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={selectedObject.intervalType}
        intervalTypeInput
      />
    </>
  );
};

ScorecardEventForm.propTypes = {
  eventNames: PropTypes.arrayOf(PropTypes.string),
  selectedObject: PropTypes.shape({
    eventName: PropTypes.string,
    intervalType: PropTypes.string,
  }),
};
ScorecardEventForm.defaultProps = {
  selectedObject: {},
  eventNames: [],
};

export default ScorecardEventForm;
