const stopwatchIdToReadableExpr = (expression, stopwatches) => {
  let readableExpr = ` ${expression}`.slice(1);
  if (typeof expression === 'string') {
    stopwatches.forEach(stopwatch => {
      if (expression.includes(stopwatch.id)) {
        readableExpr = readableExpr.replace(`{${stopwatch.id}}`, stopwatch.name);
      }
    });
  } else if (typeof expression !== 'number') {
    throw new Error('Invalid expression in stopwatchIdToReadableExpr');
  }

  return readableExpr;
};

export default stopwatchIdToReadableExpr;
