import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DefaultTable } from '~components/Pages';
import { DefaultThemedButton, FontAwesome } from '~UI';
import { reduxOperations } from '~services';
import MachineSKUForm from './MachineSKUForm';
import formatSKU from './utils';

const propTypes = {
  machineId: PropTypes.string,
};

const defaultProps = {
  machineId: '',
};

const MachineSKU = ({ machineId }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const machines = useSelector(state => state.machines);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState(null);

  const handleSKUDelete = skuId => {
    const machine = machines.find(elem => elem.id === machineId) || {};
    const skuNumbers = machine.skuNumbers.filter(elem => elem.id !== skuId);
    return dispatch(reduxOperations.machines.patchMachine(machineId, { skuNumbers }));
  };

  const handleEdit = id => {
    setIsEditing(true);
    setSelectedSKU(id);
  };

  const goToHomeSKUTab = () => {
    setIsEditing(false);
    setSelectedSKU(null);
  };

  const machine = machines.find(elem => elem.id === machineId);
  const formattedSKU = machine.skuNumbers ? formatSKU(machine.skuNumbers) : [];
  const orderedSKU = formattedSKU.sort((a, b) => {
    if (a.order && b.order) {
      return a.order - b.order;
    }
    if (!b.order) {
      return -1;
    }
    if (!a.order) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <div className="topButtonsBlock flexSpaceBetween">
        {
          isEditing ? (
            <FontAwesome
              icon="arrow-left"
              className="backButtonArrow"
              style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
              onClick={goToHomeSKUTab}
            />
          ) : <div />
        }
        {
          selectedSKU === null && !isEditing && (
            <DefaultThemedButton
              key="btnAdd"
              content={t('add')}
              isActive={false}
              className="skuNumberAddButton"
              onClick={() => setIsEditing(true)}
            />
          )
        }
      </div>

      {
        isEditing ? (
          <MachineSKUForm
            machineId={machineId}
            modifiedItemId={selectedSKU}
            goBack={goToHomeSKUTab}
          />
        )
          : (
            <DefaultTable
              columnNames={[
                { name: t('name') },
                { name: t('description') },
                { name: t('unitCompletionTime') },
              ]}
              entriesProperties={['name', 'description', 'unitCompletionTime']}
              entries={orderedSKU}
              editFunction={handleEdit}
              deleteFunction={handleSKUDelete}
            />
          )
      }
    </>
  );
};

MachineSKU.propTypes = propTypes;
MachineSKU.defaultProps = defaultProps;

export default MachineSKU;
