import store from '~services/store';
import variableToId from './variableToId';

const readableToId = expression => {
  const storeState = store.getState();
  const { machines } = storeState.machines;
  const { streams } = storeState.streams;
  const { variables } = storeState.variables;

  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const properties = [].concat(...streams.map(s => s.properties || []));
  const allVariables = [...kpis, ...properties, ...variables];

  let expressionWithId;
  try {
    expressionWithId = variableToId(expression, allVariables);
  } catch (error) {
    // Error handling of non existent variable is made in the component
    // This way the error message is popped only on submit and not on change
  }
  return expressionWithId;
};

export { readableToId };
