import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import {
  FontAwesome, CheckboxToggle, TextInput,
} from '~UI/index';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';

import './ActionForm.scss';

const MachineStateActionForm = ({ action }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const language = useSelector((state: RootState) => state.views.language);
  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);

  const findStopCauseInSubMenu = (stopCauses, selectedStopCauseId) => {
    for (let i = 0; i < stopCauses.length; i += 1) {
      const stopCause = stopCauses[i];
      if (stopCause.id === selectedStopCauseId) {
        return [stopCauses];
      }
      if (stopCause.subMenu) {
        const result = findStopCauseInSubMenu(stopCause.subMenu, selectedStopCauseId);
        if (result) {
          return [stopCauses, ...result];
        }
      }
    }
    return null;
  };

  const preselectStopCause = machineIdArg => {
    const selectedMachine = machines.find(m => m.id === machineIdArg);
    let stopCauses = selectedMachine?.stopCauses;
    let menuStack = [];

    if (selectedMachine && stopCauses.length) {
      const selectedStopCauseId = action.params.stopCauseId;
      menuStack = findStopCauseInSubMenu(stopCauses, selectedStopCauseId);
      if (menuStack) {
        stopCauses = menuStack[menuStack.length - 1];
      }
      return { selectedStopCauseId, stopCauses, menuStack, isSubMenu: menuStack?.length > 1 };
    }
    return { selectedStopCauseId: '', stopCauses: null, menuStack: [], isSubMenu: false };
  };

  const { machineId: machineIdParam } = action.params;
  const {
    selectedStopCauseId: preselectSelectedStopCauseId,
    stopCauses: preselectStopCauses,
    menuStack: preselectMenuStack,
    isSubMenu: preselectIsSubMenu,
  } = preselectStopCause(machineIdParam);

  const [machineId, setMachineId] = useState(machineIdParam);
  const [stopCauses, setStopCauses] = useState(preselectStopCauses);
  const [selectedStopCauseId, setSelectedStopCauseId] = useState(preselectSelectedStopCauseId);
  const [menuStack, setMenuStack] = useState<any>(preselectMenuStack);
  const [isSubMenu, setIsSubMenu] = useState<any>(preselectIsSubMenu);
  const [checkboxStopCauseEnabled, setCheckboxStopCauseEnabled] = useState(!!preselectSelectedStopCauseId);
  const [status, setStatus] = useState(action.params.status || 'ON');
  const [delay, setDelay] = useState(action.params.delay);

  const onDelayChange = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    let delayUpdated = value;
    try {
      delayUpdated = variableToId(value, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }
    setDelay(delayUpdated);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    return idToReadableExpr(value, inputsAndVariables);
  };

  const handleStopCauseSelection = stopCause => {
    if (stopCause === null && menuStack.length > 0) {
      const lastMenu = menuStack[menuStack.length - 1];
      const newMenuStack = menuStack.slice(0, -1);
      setMenuStack(newMenuStack);
      setIsSubMenu(newMenuStack.length > 0);
      setStopCauses(lastMenu);
    } else if (stopCause?.subMenu?.length) {
      const newMenuStack = menuStack ? [...menuStack, stopCauses] : [stopCauses];
      setMenuStack(newMenuStack);
      setStopCauses(stopCause.subMenu);
      setIsSubMenu(true);
    } else if (stopCause) {
      setSelectedStopCauseId(stopCause.id);
    }
  };

  const handleChangeCheckboxStopCause = () => {
    const selectedMachine = machines.find(m => m.id === machineId);
    const newStopCauses = selectedMachine && selectedMachine.stopCauses;

    setCheckboxStopCauseEnabled(prevCheckboxStopCauseEnabled => !prevCheckboxStopCauseEnabled);
    setSelectedStopCauseId(prevSelectedStopCauseId => (!checkboxStopCauseEnabled ? prevSelectedStopCauseId : ''));
    setStopCauses(prevStopCauses => (!checkboxStopCauseEnabled ? prevStopCauses : newStopCauses));
    setIsSubMenu(prevIsSubMenu => (!checkboxStopCauseEnabled ? prevIsSubMenu : false));
  };

  const handleMachineIdChange = newMachineId => {
    const {
      selectedStopCauseId: newSelectedStopCauseId,
      stopCauses: newStopCauses,
      menuStack: newMenuStack,
      isSubMenu: newIsSubMenu,
    } = preselectStopCause(newMachineId);
    setMachineId(newMachineId);
    setIsSubMenu(newIsSubMenu);
    setSelectedStopCauseId(newSelectedStopCauseId);
    setStopCauses(newStopCauses);
    setMenuStack(newMenuStack);
  };

  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  const buttons = [{
    label: 'ON',
    value: 'ON',
  },
  {
    label: 'OFF',
    value: 'OFF',
  }];

  return (
    <>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('eventName')}</div>
      <input
        type="text"
        placeholder="Event Name"
        name="name"
        defaultValue={action.params.name}
        className="fullwidth"
      />

      <div className="inputTitle" style={{ display: 'inline-block', marginRight: '8px' }}>{t('status')}</div>
      <ButtonGroup
        buttons={buttons}
        name="status"
        onChange={newStatus => setStatus(newStatus)}
        value={status}
      />

      {
        stopCauses?.length && status === 'OFF' && (
          <>
            <div className="inputTitle">
              {t('stopCause')}
              &nbsp;
              <CheckboxToggle
                controlledCheck={checkboxStopCauseEnabled}
                onChange={handleChangeCheckboxStopCause}
              />
            </div>
            <div style={{ display: checkboxStopCauseEnabled ? 'block' : 'none' }}>
              <input type="hidden" name="stopCauseId" value={selectedStopCauseId} />
              {
                isSubMenu && (
                  <FontAwesome
                    icon="arrow-left"
                    className="backButtonArrow"
                    style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
                    onClick={() => handleStopCauseSelection(null)}
                  />
                )
              }
              {
                stopCauses.map(stopcause => {
                  let stopCauseName;
                  switch (language) {
                    case 'en':
                      stopCauseName = stopcause.nameEN || stopcause.name;
                      break;
                    case 'fr':
                      stopCauseName = stopcause.nameFR || stopcause.name;
                      break;
                    case 'es':
                      stopCauseName = stopcause.nameES || stopcause.name;
                      break;
                    default:
                      stopCauseName = stopcause.name;
                  }

                  return (
                    <StopCauseIcon
                      key={stopcause.id}
                      option={{
                        ...stopcause,
                        name: stopCauseName,
                        badgeCount: stopcause.subMenu?.length,
                      }}
                      small
                      className={(stopcause.id === selectedStopCauseId) ? 'Selected' : 'Unselected'}
                      onClick={() => handleStopCauseSelection(stopcause)}
                    />
                  );
                })
              }
            </div>
          </>
        )
      }
      <div className="inputTitle">{`${t('delay')} (${t('optional')})`}</div>
      <input
        type="hidden"
        value={delay}
        name="delay"
      />
      <TextInput
        options={inputsAndVariables}
        trigger="$"
        value={delay ? idToReadable(delay) : null}
        className="fullwidth"
        onChange={e => onDelayChange(e)}
        placeholder={t('triggerVariableList')}
      />
      <br />
    </>
  );
};

MachineStateActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      machineId: PropTypes.string,
      status: PropTypes.string,
      delay: PropTypes.string,
      stopCauseId: PropTypes.string,
    }).isRequired,
  }),
};
MachineStateActionForm.defaultProps = {
  action: {
    params: {},
  },
};

export default MachineStateActionForm;
