import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesome } from '~UI/index';
import ConfirmationForm from './ConfirmationForm';

const propTypes = {
  handleValidation: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // global state props
  content: PropTypes.string,
  actionLabel: PropTypes.string,
};

const ValidConfirmationForm = ({
  handleValidation, show, onHide, content, actionLabel,
}) => {
  const { t } = useTranslation();

  const title = () => (
    <>
      <FontAwesome
        icon="exclamation-circle"
        color="#0078FF"
      />
      &nbsp;
      {t('areYouSure')}
    </>
  );

  return (
    <ConfirmationForm
      title={title()}
      body={content || t('beSureQuit')}
      actionLabel={actionLabel || t('confirm')}
      show={show}
      onHide={onHide}
      handleAction={handleValidation}
      confirmButtonColor="#0078FF"
    />
  );
};

function mapStateToProps(state) {
  return {
    language: state.views.language,
  };
}

ValidConfirmationForm.propTypes = propTypes;

export default connect(mapStateToProps)(ValidConfirmationForm);
