import React, { useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultModal, SubmitButton } from '~UI/index';
import { getFormData } from '~utils/getFormData';
import reduxOperations from '~services/reduxOperations';
import { showSuccess } from '~utils/toast';
import { RootState } from '~services/store';

import './MoveToFolderPopup.scss';

const MoveToFolderPopup = ({ modifiedItemId, type, show, onHide }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const folders = useSelector((state: RootState) => state.folders);
  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const reports = useSelector((state: RootState) => state.reports.reports);

  const getInterface = () => {
    switch (type) {
      case 'dashboards':
        return dashboards;
      case 'topviews':
        return topviews;
      case 'reports':
        return reports;
      default:
        break;
    }
  };

  const item = getInterface()?.find(i => i.id === modifiedItemId);
  const folder = item?.folderId;
  const [folderDestination, setFolderDestination] = useState(folder?.name || '');

  const handleSubmit = e => {
    e.preventDefault();
    const formData = getFormData('folderForm');

    if (formData.folderId === '-') {
      formData.folderId = '';
    }

    switch (type) {
      case 'dashboards':
        reduxOperations.dashboards.updateDashboard(modifiedItemId, { ...item, ...formData })(dispatch)
          .then(() => showSuccess(t('showSuccessUpdated')))
          .then(() => onHide());
        break;
      case 'topviews':
        reduxOperations.topviews.updateTopview(modifiedItemId, { ...item, ...formData })(dispatch)
          .then(() => showSuccess(t('showSuccessUpdated')))
          .then(() => onHide());
        break;
      case 'reports':
        reduxOperations.reports.updateReport(modifiedItemId, { ...omit(item, ['id', 'modifiedAt', 'modifiedBy']), ...formData })(dispatch)
          .then(() => showSuccess(t('showSuccessUpdated')))
          .then(() => onHide());
        break;
      default:
        break;
    }
  };

  const getContents = () => (
    <div className="MoveToFolderPopup">
      <div className="inputTitle">{t('folder')}</div>
      <form
        id="folderForm"
        onSubmit={handleSubmit}
      >
        <Select
          name="folderId"
          value={folderDestination || folder || '-'}
          onChange={setFolderDestination}
          placeholder={t('select')}
          options={folders && [
            { label: '-', value: '-' },
            ...folders.flatMap(f => [
              { label: f.name, value: f.id },
              ...(f.subfolders || []).map(sf => ({ label: `${sf.name}`, value: sf.id })),
            ]),
          ]}
        />
        <SubmitButton className="buttonsHolder" label={t('submit')} />
      </form>
    </div>
  );

  return (
    <DefaultModal
      closePopup={onHide}
      title={t('moveTo')}
      show={show}
      children={getContents()}
    />
  );
};

MoveToFolderPopup.propTypes = {
  modifiedItemId: PropTypes.string,
  type: PropTypes.oneOf(['dashboards', 'topviews', 'reports']).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

MoveToFolderPopup.defaultProps = {
  modifiedItemId: '',
};

export default MoveToFolderPopup;
