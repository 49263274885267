import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { reduxOperations } from '~services';
import { getFormData } from '~utils';
import { showError } from '~utils/toast';
import { millisToHHmmss, formatDuration } from '~utils/time';
import API from '~services/endpoints';
import MachinePopup from './MachinePopup';
import MachineEventMenu from './MachineEventMenu';
import { usePrevious } from '~utils/hooks';

const getMenuLevel = (performanceCauses, event) => {
  let menuLevel = 0;
  let options = performanceCauses;
  let previousOptions = performanceCauses;
  let selectedItemName = null;
  let previousPerformanceCauseId = null;

  if (event && event.performanceCauseId) {
    performanceCauses.forEach(topCause => {
      if (topCause.id === event.performanceCauseId) {
        menuLevel = 1;
        options = performanceCauses;
        previousOptions = performanceCauses;
        selectedItemName = topCause.name;
      }
      topCause.subMenu.forEach(subCause => {
        if (subCause.id === event.performanceCauseId) {
          menuLevel = 2;
          options = topCause.subMenu;
          previousOptions = performanceCauses;
          selectedItemName = subCause.name;
          previousPerformanceCauseId = topCause.id;
        }
      });
    });
  }

  return {
    menuLevel,
    options,
    previousOptions,
    selectedItemName,
    previousPerformanceCauseId,
  };
};

const PerformanceEventPopup = ({
  show, onHide, machineId: machineIdProp, modifiedItemId,
  eventsList, event: eventProp, onSubmitTrigger, params, eventModifyTrigger, mandatoryComment,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);

  const initEvent = eventsList.find(ev => ev.id === modifiedItemId);

  const [options, setOptions] = useState(null);
  const [menuLevel, setMenuLevel] = useState(0);
  const [performanceCauseId, setPerformanceCauseId] = useState(null);
  const [previousPerformanceCauseId, setPreviousPerformanceCauseId] = useState(null);
  const [previousOptions, setPreviousOptions] = useState(null);
  const previousProps = usePrevious({ machineIdProp, eventProp });
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [machineId, setMachineId] = useState(initEvent ? initEvent.machineId : machineIdProp);
  const [event, setEvent] = useState(initEvent || eventProp);
  const [nextEvent, setNextEvent] = useState(null);
  const [parentOption, setParentOption] = useState(null);
  const [searchInput, setSearchInput] = useState('');

  const fetchNextEvent = async eventArg => {
    if (eventArg) {
      const filter = {
        type: 'PerformanceEvent',
        machineId: eventArg.machineId,
        name: eventArg.name,
        timestamp: {
          $gt: eventArg.timestamp,
        },
      };
      const sort = { timestamp: 1 };
      const newNextEvent = (await API.getEvents(filter, sort, 1)).events;
      setNextEvent(newNextEvent.length ? newNextEvent[0] : null);
    }
  };

  const initForm = (machineIdArg, eventArg) => {
    const machine = machines.find(m => m.id === machineIdArg);
    if (machine) {
      const {
        menuLevel: newMenuLevel,
        options: newOptions,
        previousOptions: newPreviousOptions,
        selectedItemName: newSelectedItemName,
      } = getMenuLevel(machine.performanceCauses, eventArg);
      setMenuLevel(newMenuLevel);
      setOptions(newOptions);
      setPreviousOptions(newPreviousOptions);
      setSelectedItemName(newSelectedItemName);
      setPerformanceCauseId(eventArg && eventArg.performanceCauseId);
      setPreviousPerformanceCauseId(eventArg && eventArg.performanceCauseId);
      fetchNextEvent(eventArg);
    }
  };

  useEffect(() => {
    if (previousProps?.machineIdProp !== machineIdProp) {
      setMachineId(machineIdProp);
    }
  }, [machineIdProp]);

  useEffect(() => {
    if (previousProps?.eventProp !== eventProp) {
      setEvent(eventProp);
    }
  }, [eventProp]);

  useEffect(() => {
    if (show && event) {
      if (event.status === 'END') {
        onHide();
      } else if (event.status === 'START') {
        setEvent(eventProp);
        initForm(machineId, event);
      }
    }
  }, [show, event]);

  useEffect(() => {
    initForm(machineIdProp, eventProp);
  }, [machineId, event]);

  const getEventDetails = () => {
    if (event) {
      const start = millisToHHmmss(event.timestamp);
      const end = nextEvent && millisToHHmmss(nextEvent.timestamp);

      const duration = nextEvent && formatDuration(nextEvent.timestamp - event.timestamp, {
        year: true, month: true, day: true, hourSeparator: 'h ', minSeparator: 'min ', secSeparator: 'sec',
      }, true, language);

      return (
        <div>
          <p>
            {nextEvent ? `${t('duration')}: ${duration} - ` : null}
            {nextEvent ? `${t('from')} ${start} ` : `${t('at')} ${start}`}
            {nextEvent ? `${t('to')} ${end}` : ''}
          </p>
        </div>
      );
    }
    return null;
  };

  const handleSelection = performanceCauseIdArg => {
    const menuItem = options.find(sm => sm.id === performanceCauseIdArg);
    const currentMenuLevel = menuLevel;
    if (menuItem.subMenu && menuItem.subMenu.length > 0) {
      setOptions(menuItem.subMenu);
      setParentOption(menuItem);
      setMenuLevel(currentMenuLevel + 1);
      setSelectedItemName(null);
      setPerformanceCauseId(performanceCauseIdArg);
      setPreviousPerformanceCauseId(performanceCauseIdArg);
    } else {
      setSearchInput('');
      setMenuLevel(currentMenuLevel + 1);
      setSelectedItemName(menuItem.name);
      setPerformanceCauseId(performanceCauseIdArg);
    }
  };

  const handleBackButton = () => {
    setOptions(menuLevel - 1 > 0 ? options : previousOptions);
    setParentOption(null);
    setMenuLevel(menuLevel - 1);
    setSelectedItemName(null);
    setPerformanceCauseId(menuLevel - 1 > 0 ? previousPerformanceCauseId : null);
  };

  const handleSubmit = async (comments, resolutions) => {
    // Call API to update event
    const formData = getFormData('Stop_Selection_Form');
    const comment = comments || formData.comments;
    const resolution = resolutions || formData.resolutions;
    if (mandatoryComment && (!comment || comment.trim().length === 0)) {
      showError(t('pleaseEnterComment'));
      return;
    }
    const updatedData = {
      motif: selectedItemName,
      comments: comment,
      resolutions: resolution,
      file: formData.file,
      performanceCauseId,
    };
    // old events don't have an uuid
    const id = event.id || event._id || modifiedItemId;
    if (eventModifyTrigger) {
      eventModifyTrigger(updatedData);
    } else {
      dispatch(reduxOperations.events.patchEvent(id, updatedData));
    }

    if (onSubmitTrigger) {
      const optionalTriggerParams = { ...params, ...formData, ...updatedData };
      dispatch(reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
        ...optionalTriggerParams,
        eventId: id,
      }));
    }

    onHide();
  };

  const handleClosePopup = () => {
    if (mandatoryComment) {
      showError(t('pleaseEnterComment'));
    } else {
      onHide();
    }
  };

  const searchBar = (
    <Input
      clearable
      style={{ width: '300px' }}
      onChange={value => setSearchInput(value)}
      onClear={() => setSearchInput('')}
      value={searchInput}
      placeholder={t('Search')}
    />
  );

  const getContents = () => {
    const machine = machines.find(m => m.id === machineId);

    return (
      <MachinePopup
        title={(machine ? `${machine.name} : ` : '') + t('detectedPerformanceDrop')}
        subtitle={event ? getEventDetails() : null}
        searchBar={selectedItemName ? null : searchBar}
        show={show}
        onHide={handleClosePopup}
      >
        <MachineEventMenu
          name={selectedItemName}
          options={options}
          parentOption={parentOption}
          backPage={menuLevel > 0}
          comments={event && event.comments}
          resolutions={event && event.resolutions}
          file={event && event.file}
          onBackPage={handleBackButton}
          onSelect={handleSelection}
          onSubmit={handleSubmit}
          onHide={mandatoryComment ? undefined : handleClosePopup}
          filter={searchInput}
          language={language}
        />
      </MachinePopup>
    );
  };

  return (
    <ResourcesHandler
      resources={[machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.machines.fetchMachines()),
      ]}
      getContents={getContents}
    />
  );
};

PerformanceEventPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  machineId: PropTypes.string,
  modifiedItemId: PropTypes.string,
  eventsList: PropTypes.arrayOf(PropTypes.object),
  event: PropTypes.object,
  onSubmitTrigger: PropTypes.string,
  params: PropTypes.shape({}),
  eventModifyTrigger: PropTypes.func,
  mandatoryComment: PropTypes.bool,
};
PerformanceEventPopup.defaultProps = {
  show: false,
  onHide: () => {},
  machineId: null,
  modifiedItemId: null,
  eventsList: [],
  event: {},
  onSubmitTrigger: '',
  params: {},
  eventModifyTrigger: null,
  mandatoryComment: false,
};

export default PerformanceEventPopup;
