import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { DefaultModal } from '~UI/DefaultModal';
import JsonForm from '~UI/JsonForm/JsonForm';

import { reduxOperations, reducersTypes } from '~services';

const propTypes = {
  forms: reducersTypes.forms.isRequired,
  fetchForms: PropTypes.func.isRequired,
  variables: reducersTypes.variables.isRequired,
  fetchVariables: PropTypes.func.isRequired,
  machines: reducersTypes.machines.isRequired,
  fetchMachines: PropTypes.func.isRequired,
  streams: reducersTypes.streams.isRequired,
  fetchStreams: PropTypes.func.isRequired,

  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmitTrigger: PropTypes.string,
  executeTrigger: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
};

class JsonFormPopup extends Component {
  handleSubmit = formData => {
    const { onHide, executeTrigger, onSubmitTrigger, params } = this.props;

    if (onSubmitTrigger) {
      executeTrigger(onSubmitTrigger, {
        ...params,
        ...formData,
      });
    }

    onHide();
  };

  getContents = () => {
    const {
      show, onHide, formId, forms, params,
    } = this.props;
    const form = forms.find(f => f.id === formId);
    return (
      <DefaultModal
        show={show}
        title={form.label}
        closePopup={onHide}
        children={(
          <JsonForm
            form={form}
            params={params}
            onSubmit={this.handleSubmit}
            onCancel={onHide}
          />
        )}
      />
    );
  };

  render() {
    const {
      streams,
      variables,
      machines,
      forms,
      fetchStreams,
      fetchVariables,
      fetchMachines,
      fetchForms,
    } = this.props;
    return (
      <ResourcesHandler
        resources={[streams, variables, machines, forms]}
        resourceFetchers={[fetchStreams, fetchVariables, fetchMachines, fetchForms]}
        getContents={this.getContents}
      />
    );
  }
}

JsonFormPopup.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    streams: state.streams,
    variables: state.variables,
    machines: state.machines,
    forms: state.forms,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchStreams: reduxOperations.streams.fetchStreams,
    fetchVariables: reduxOperations.variables.fetchVariables,
    fetchMachines: reduxOperations.machines.fetchMachines,
    fetchForms: reduxOperations.forms.fetchForms,
    executeTrigger: reduxOperations.triggers.executeTrigger,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(JsonFormPopup);
