const formatTime = timeInMs => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;

  const ms = `${timeInMs}ms`;
  const s = `${Math.trunc(((timeInMs % hour) % minute) / second)}s`;
  const min = `${Math.trunc((timeInMs % hour) / minute)}min`;
  const h = `${Math.trunc(timeInMs / hour)}h`;

  if (timeInMs < 1000) {
    return ms;
  }
  if (timeInMs < 60000) {
    return s;
  }
  if (timeInMs < 3600000) {
    if (s === '0s') {
      return min;
    }
    return `${min} ${s}`;
  }

  if (s === '0s' && min === '0min') {
    return h;
  }
  if (s === '0s') {
    return `${h} ${min}`;
  }
  return `${h} ${min} ${s}`;
};

export default formatTime;
