const processFormData = (formData, key, value) => {
  const data = { ...formData };
  let finalKey = key;
  let finalValue = value;
  const [keyType0, keyType1] = key.split(':');
  if (keyType0 === 'boolean') {
    finalKey = keyType1;
    finalValue = value === 'true';
  } else if (keyType0 === 'number') {
    if (Number.isNaN(Number(value))) {
      throw new Error('Invalid number value');
    }
    finalKey = keyType1;
    finalValue = Number(value);
  } else if (keyType0 === 'string') {
    finalKey = keyType1;
    finalValue = value;
  } else if (keyType0 === 'array') {
    finalKey = keyType1;
    finalValue = value.split(',').filter(a => !!a);
  }
  if (value === 'null') {
    finalValue = null;
  }
  if (finalValue !== null) {
    data[finalKey] = finalValue;
  }
  return data;
};

const getFormData = idHTML => {
  let data = {};
  const formData = new FormData(document.querySelector(`#${idHTML}`));
  formData.forEach((value, key) => {
    data = processFormData(data, key, value);
  });
  return data;
};

export {
  getFormData,
  processFormData,
};
