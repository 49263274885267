import { audioTypes, tileWidgetTypes } from '~utils/types';

function shouldDisplayAdvancedTab(type) {
  switch (type) {
    case tileWidgetTypes.MACHINE_STATE:
      return true;
    default:
      return false;
  }
}

function shouldDisplayColorRuleTab(type) {
  switch (type) {
    case tileWidgetTypes.MACHINE_STATE:
    case tileWidgetTypes.PROMPT:
      return false;
    default:
      return true;
  }
}

function shouldDisplayHideRuleTab(type) {
  switch (type) {
    case tileWidgetTypes.BUTTON:
      return true;
    default:
      return false;
  }
}

function shouldDisplayMediaRuleTab(type, selectedObject) {
  switch (type) {
    case tileWidgetTypes.AUDIO:
      return selectedObject.audioType === audioTypes.FILE;
    case tileWidgetTypes.BUTTON:
    case tileWidgetTypes.INFO:
    case tileWidgetTypes.MEDIA:
      return true;
    default:
      return false;
  }
}

function shouldDisplayTriggerRuleTab(type) {
  return type === tileWidgetTypes.BUTTON;
}

function shouldDisplayStyleTab(type) {
  switch (type) {
    case tileWidgetTypes.BUTTON:
    case tileWidgetTypes.CIRCULAR:
    case tileWidgetTypes.DATA_ENTRY:
    case tileWidgetTypes.GAUGE:
    case tileWidgetTypes.MACHINE_STATE:
    case tileWidgetTypes.SCORECARD:
    case tileWidgetTypes.STOPWATCH:
    case tileWidgetTypes.TEXT:
    case tileWidgetTypes.MEDIA:
    case tileWidgetTypes.PROMPT:
      return true;
    default:
      return false;
  }
}

function shouldDisplayTextRuleTab(type, selectedObject) {
  switch (type) {
    case tileWidgetTypes.AUDIO:
      return selectedObject.audioType === audioTypes.TEXT_TO_SPEECH;
    case tileWidgetTypes.BUTTON:
    case tileWidgetTypes.TEXT:
      return true;
    default:
      return false;
  }
}

function shouldHaveCurrentValue(type) {
  switch (type) {
    case tileWidgetTypes.CIRCULAR:
    case tileWidgetTypes.GAUGE:
    case tileWidgetTypes.SCORECARD:
    case tileWidgetTypes.TEXT:
      return true;
    default:
      return false;
  }
}

export {
  shouldDisplayAdvancedTab,
  shouldDisplayColorRuleTab,
  shouldDisplayHideRuleTab,
  shouldDisplayMediaRuleTab,
  shouldDisplayTriggerRuleTab,
  shouldDisplayStyleTab,
  shouldDisplayTextRuleTab,
  shouldHaveCurrentValue,
};
