import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Duration } from 'luxon';
import IdleTimer from 'react-idle-timer';
import { reduxOperations } from '~services';
import { getSocket } from '~services/socket';
import { getTimerFontSize } from '~utils/responsiveValue';
import TileContents from '../TileContents';
import { showError } from '~utils/toast';
import { serverTime } from '~utils/time';

const StopwatchTile = ({
  backgroundColor,
  height,
  isConfigurationAllowed,
  tile,
  width,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const socket = getSocket();
  const stopwatches = useSelector(state => state.stopwatches.stopwatches);
  const [currentStopwatch, setCurrentStopwatch] = useState(stopwatches.find(elem => tile.stopwatchId === elem.id));
  const currentStopwatchRef = useRef(currentStopwatch);
  const [isActive, setIsActive] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const timer = useRef(null);

  const idleTimerRef = useRef(null);

  /**
   * Send to the DB
   */
  const startTimerDB = () => {
    dispatch(reduxOperations.stopwatches.stopwatchIO(currentStopwatch.id, { flag: 'on' }))
      .catch(error => {
        showError(error.status);
      });
  };

  const stopTimerDB = () => {
    dispatch(reduxOperations.stopwatches.stopwatchIO(currentStopwatch.id, { flag: 'off' }))
      .catch(error => {
        showError(error.status);
      });
  };

  const resetTimerDB = () => {
    dispatch(reduxOperations.stopwatches.stopwatchIO(currentStopwatch.id, { flag: 'reset' }))
      .catch(error => {
        showError(error.status);
      });
  };

  /**
   * Locally starts the Timer
   */
  const startTimerLocal = watch => {
    // turns on timer and updates the appropriate attributes of the timer
    setIsActive(true);
    const startOfTimer = serverTime() - watch.currentTime; // assigns the current time since Epoch
    // minus the time already elapsed in timer
    // i.e the "past projected start time"
    // the setInterval function allows repetition of execution at each time interval specified
    // the interval ID is stored in 'this.timer', which can get cleared later on
    clearInterval(timer.current);
    timer.current = setInterval(() => {
      setCurrentTime(serverTime() - startOfTimer);
    }, 100);
  };

  /**
   * Halts timer increment and sets its state to "stopped"
   */
  const stopTimerLocal = watch => {
    clearInterval(timer.current);
    setIsActive(false);
    setCurrentTime(watch.currentTime);
  };

  /**
   * Zero-resets all states of the timer
   */
  const resetTimerLocal = watch => {
    clearInterval(timer.current);
    setCurrentTime(0);
    if (watch.isActive) {
      startTimerLocal(watch);
    } else {
      stopTimerLocal(watch);
    }
  };

  const initStopwatch = stopwatchArg => {
    const newCurrentTime = (serverTime() - stopwatchArg.savedAtEpoch) + stopwatchArg.currentTime;
    if (stopwatchArg) {
      setCurrentStopwatch(stopwatchArg);
      setIsActive(stopwatchArg.isActive);
      const startOfTimer = stopwatchArg.isActive ? (serverTime() - newCurrentTime) : 0;
      setCurrentTime(stopwatchArg.isActive
        ? newCurrentTime
        : stopwatchArg.currentTime);

      clearInterval(timer.current);
      if (stopwatchArg.isActive) {
        timer.current = setInterval(() => {
          setCurrentTime(serverTime() - startOfTimer);
        }, 100);
      }
    }
  };

  const handleSocketData = socketData => {
    if (socketData.id === currentStopwatchRef.current.id) {
      dispatch(reduxOperations.stopwatches.socketUpdateStopwatch(socketData));
      switch (socketData.action) {
        case 'start':
          startTimerLocal(socketData.notification);
          break;
        case 'stop':
          stopTimerLocal(socketData.notification);
          break;
        case 'reset':
          resetTimerLocal(socketData.notification);
          break;
        case 'updateStopwatchProperties':
          initStopwatch(socketData.notification);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (currentStopwatch) {
      initStopwatch(currentStopwatch);
    }
    return () => clearInterval(timer.current);
  }, []);

  useEffect(() => {
    socket?.on('stopwatches', handleSocketData);
    return () => {
      socket?.removeListener('stopwatches', handleSocketData);
    };
  }, [socket]);

  useEffect(() => {
    const newStopwatch = stopwatches.find(elem => tile.stopwatchId === elem.id);
    if ((!currentStopwatch && newStopwatch)
      || (currentStopwatch && (newStopwatch.id !== currentStopwatch.id
        || newStopwatch.startTime !== currentStopwatch.startTime))) {
      setCurrentStopwatch(newStopwatch);
      initStopwatch(newStopwatch);
    }
  }, [stopwatches, tile.stopwatchId]);

  useEffect(() => {
    currentStopwatchRef.current = currentStopwatch;
  }, [currentStopwatch]);

  const handleOnIdle = () => {
    initStopwatch(stopwatches.find(elem => tile.stopwatchId === elem.id));
    idleTimerRef.current.reset();
  };

  const render = () => {
    let displayTime = currentTime;

    if (currentStopwatch && currentStopwatch.type === 'CountDown') {
      displayTime = currentStopwatch.startTime - currentTime;
    }

    const formatedTime = (displayTime >= 0 ? '' : '-') + Duration.fromMillis(Math.abs(displayTime)).toFormat(tile.timeFormat);
    if (!currentStopwatch) {
      return (
        isConfigurationAllowed
          ? (
            <h3>
              {t('stopwatchDidNotLoad')}
              <br />
              {t('selectAnotherStopwatch')}
            </h3>
          )
          : <h3>{t('stopwatchDidNotLoad')}</h3>
      );
    }

    const {
      timerFontSize,
      buttonFontSize,
    } = getTimerFontSize(height, width, formatedTime, tile.zoom, tile.displayButtons);

    return (
      <TileContents
        tile={tile}
        backgroundColor={backgroundColor}
        height={height}
        width={width}
      >
        <div className="framed flex V Stopwatch">
          <div
            className="Stopwatch__Display"
            style={{ fontSize: `${timerFontSize}px` }}
          >
            {formatedTime}
          </div>

          {/* ---------- Stopwatch control buttons ----------- */}
          {
            tile.displayButtons ? (
              <div className="Stopwatch__Control flex H">
                { /* Show the Start button if stopped with zero time */
                  isActive === false && displayTime === 0 && (
                    <button
                      type="button"
                      onClick={startTimerDB}
                      key="btnStart1"
                      style={{ fontSize: `${buttonFontSize}px` }}
                    >
                      {t('startVerb')}
                    </button>
                  )
                }
                { /* Show the Stop button if currently running */
                  isActive === true && (
                    <button
                      type="button"
                      onClick={stopTimerDB}
                      key="btnStop"
                      style={{ fontSize: `${buttonFontSize}px` }}
                    >
                      {t('stopVerb')}
                    </button>
                  )
                }
                { /* Show the Resume and Reset buttons if stopped with nonzero time */
                  isActive === false && displayTime !== 0 && ([
                    <button
                      type="button"
                      onClick={startTimerDB}
                      key="btnStart2"
                      style={{ fontSize: `${buttonFontSize}px` }}
                    >
                      {t('startVerb')}
                    </button>,
                    <button
                      type="button"
                      onClick={resetTimerDB}
                      key="btnReset"
                      style={{ fontSize: `${buttonFontSize}px` }}
                    >
                      {t('reset')}
                    </button>,
                  ])
                }
              </div>
            )
              : null
          }
        </div>
        <IdleTimer
          ref={idleTimerRef}
          onIdle={handleOnIdle}
          debounce={250}
          timeout={1000 * 60 * 5}
        />
      </TileContents>
    );
  };
  return render();
};

StopwatchTile.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  isConfigurationAllowed: PropTypes.bool.isRequired,
  tile: PropTypes.shape({
    stopwatchId: PropTypes.string.isRequired,
    title: PropTypes.string,
    zoom: PropTypes.string,
    id: PropTypes.string,
    timeFormat: PropTypes.string,
    displayButtons: PropTypes.bool,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default StopwatchTile;
