import React from 'react';
import PropTypes from 'prop-types';
import { getApproximateFontSize } from '~utils/responsiveValue';
import { invertColor } from '~utils/index';
import CircleWidget from './CircleWidget';
import RectWidget from './RectWidget';

const TITLE_FONT_SIZE_HEIGHT = 0.22;
const TITLE_POSITION = 0.10;

const shapeWidgets = {
  circle: CircleWidget,
  rect: RectWidget,
};

interface SvgWidgetProps {
  backgroundColor: string;
  children: JSX.Element[] | JSX.Element | null;
  dimension: { x: number; y: number; w: number; h: number; };
  shape: string;
  title?: string;
  titleSize?: number;
}

const SvgWidget = ({
  dimension,
  backgroundColor,
  children = null,
  shape,
  title,
  titleSize,
}: SvgWidgetProps) => {
  const ShapeWidget = shapeWidgets[shape] || RectWidget;
  const fontSize = titleSize || getApproximateFontSize(title, dimension.w, dimension.h * TITLE_FONT_SIZE_HEIGHT);
  return (
    <ShapeWidget dimension={dimension} backgroundColor={backgroundColor}>
      <text
        x={dimension.x + dimension.w / 2}
        y={dimension.y + dimension.h * TITLE_POSITION}
        fill={invertColor(backgroundColor)}
        style={{ fontSize: `${fontSize}px`, fontWeight: 'bold' }}
      >
        {title}
      </text>
      {children}
    </ShapeWidget>
  );
};

SvgWidget.propTypes = {
  dimension: PropTypes.shape({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
  }).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  shape: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleSize: PropTypes.number.isRequired,
};

export default SvgWidget;
