import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { audioTypes } from '~utils/types';

function AudioPlayer() {
  const dispatch = useDispatch();
  const audios = useSelector((state: RootState) => state.audios);
  const language = useSelector((state: RootState) => state.views.language);
  const [playing, setPlaying] = useState(false);

  function playAudio() {
    const audioToPlay = audios[0];
    switch (audioToPlay.type) {
      case audioTypes.FILE: {
        const audio = new Audio(audioToPlay.url);
        audio.addEventListener('ended', () => setPlaying(false));
        setPlaying(true);
        audio.play();
        break;
      }
      case audioTypes.TEXT_TO_SPEECH: {
        const utter = new SpeechSynthesisUtterance(audioToPlay.text);
        utter.rate = 0.9;
        utter.addEventListener('end', () => setPlaying(false));
        // need to check this for firefox
        if (language === 'fr') {
          utter.lang = 'fr-FR';
        } else {
          utter.lang = 'en-US';
        }
        window.speechSynthesis.speak(utter);
        break;
      }
      default:
      // do nothing
    }
    dispatch(reduxOperations.audios.removeAudio(audioToPlay.id));
  }

  useEffect(() => {
    if (!playing && audios.length > 0) {
      playAudio();
    }
  }, [playing, audios]);

  return null;
}

export { AudioPlayer };
