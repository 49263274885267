import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import allReducers from '.';
import storeCachingMiddleware from '~utils/featureToggles/reduxStore/storeCachingMiddleware';
import { sendEvent } from '~utils/googleAnalytics';

const googleAnalyticsMiddleware = _store => next => action => {
  sendEvent(action);
  const result = next(action);
  return result;
};

let middlewares;
if (process.env.NODE_ENV === 'production') {
  middlewares = [thunk, promise, storeCachingMiddleware, googleAnalyticsMiddleware];
} else {
  const logger = createLogger();
  middlewares = [thunk, promise, logger, storeCachingMiddleware, googleAnalyticsMiddleware];
}

const store = createStore(allReducers, applyMiddleware(...middlewares));

export default store;

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
