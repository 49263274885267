import ReactGA from 'react-ga4';
import API from '~services/endpoints';

const isDevelopment = process.env.NODE_ENV === 'development';
const demoName = 'demok2';
let name = '';
let username = 'unknown';

/*
const handleError = (messageOrEvent, source, lineNo, rowNo, error) => {
  ReactGA.exception({
    description: JSON.stringify({
      messageOrEvent, source, lineNo, rowNo, error,
    }),
  });
};
*/

const initGoogleAnalytics = () => {
  API.getSettings().then(results => {
    console.info('GA Initialization');
    if (isDevelopment) {
      // How to see the results in development
      // https://support.google.com/analytics/answer/6277302
      name = 'localhost';
      ReactGA.initialize(
        [{
          trackingId: 'UA-XXXX',
          gaOptions: { name },
        }],
      );
    } else {
      name = results.settings.googleAnalytics.clientName;
      ReactGA.initialize(
        [{
          trackingId: 'G-KFFDMDDQ8K',
          gaOptions: { name: demoName },
        }, {
          trackingId: results.settings.googleAnalytics.clientTrackingId,
          gaOptions: { name },
        }],
      );
    }
    // window.onerror = handleError;
  });
};

const trackPage = page => {
  const date = (new Date()).toUTCString();
  ReactGA.send({
    hitType: 'pageview',
    page,
    title: `${date};${name};${username}`,
  });
};

/*
const sendEventToDemoK2 = eventData => {
  // All the clients will send basic info to the demo k2 tracking id
  // This will allow us to have one source of data for all the clients
  if (!isDevelopment && name !== 'DemoK2') {
    ReactGA.ga('demok2.send', 'event', eventData);
  }
};

const sendEventToClient = eventData => {
  // Each client has its own GA property defined and the data will be more precise on each client property
  ReactGA.ga(`${name}.send`, 'event', eventData);
};
*/

// sendEvent catches all the requests made from the client from the reducers.
const sendEvent = action => {
  // const actionTypeParts = action.type.split('_');

  if (action.type === 'LOGIN_RESPONSE') {
    username = action.payload && action.payload.infos ? action.payload.infos.email : 'unknown';
    // TODO: the informations sent is currently not used, will put it back when needed
    /*
    const eventData = {
      category: name,
      action: 'LOGIN',
      label: username,
    };
    sendEventToDemoK2(eventData);
    sendEventToClient(eventData);
    */
  }

  /*
  if (actionTypeParts[actionTypeParts.length - 1] === 'REQUEST' && username !== 'unknown' && username !== undefined) {
    switch (actionTypeParts[0]) {
      case 'GET':
      case 'ADD':
      case 'UPDATE':
      case 'DELETE':
        sendEventToDemoK2({
          eventCategory: name,
          eventAction: action.type,
          eventLabel: username,
        });
        sendEventToClient({
          eventCategory: name,
          eventAction: action.type,
          eventLabel: JSON.stringify({
            user: username,
            payload: action.payload || undefined,
          }),
        });
        break;
      default:
        break;
    }
  }
  */
};

export {
  initGoogleAnalytics,
  trackPage,
  sendEvent,
};
