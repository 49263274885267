import React from 'react';
import PropTypes from 'prop-types';
import { invertColor } from '~utils';
import { fitText } from '~utils/responsiveValue';

const WIDTH_RATIO = 0.95;
const METRIC_WIDTH_RATIO = 0.75;

const Text = ({
  value, units, comparisonMetric: metric, backgroundColor, relativeWidth, selectedObject,
}) => {
  const { contentSize, zoom } = selectedObject;
  const baseProps = {
    x: relativeWidth / 2,
    textAnchor: 'middle',
    dominantBaseline: 'middle',
    fill: invertColor(backgroundColor),
  };
  let metricText = '';
  if (metric && Object.keys(metric).length > 0) {
    if (metric.isUp) {
      metricText = `▲ ${metric.value}`;
    } else {
      metricText = `▼ ${metric.value}`;
    }
  }

  let valueWithUnits = value;
  if (selectedObject.unitPosition === 'left') {
    valueWithUnits = `${units} ${value}`;
  } else if (selectedObject.unitPosition === 'right') {
    valueWithUnits = `${value} ${units}`;
  }

  function getValueUnitsFontSize(height) {
    const textToFit = selectedObject.unitPosition === 'left' || selectedObject.unitPosition === 'right'
      ? valueWithUnits
      : value;
    return contentSize || fitText(textToFit, relativeWidth * WIDTH_RATIO, 0.85 * height, zoom);
  }

  function getMetricFontSize(height) {
    return contentSize || fitText(metricText, relativeWidth * METRIC_WIDTH_RATIO, 0.85 * height, zoom);
  }

  if (metric && units) {
    const valuePositionY = 30;
    const valueHeight = 65;
    const metricPositionY = 60;
    const metricHeight = 15;
    const unitPositionY = 75;
    const unitHeight = 15;
    const rightUnitHeight = 45;
    return (
      <>
        <tspan
          {...baseProps}
          y={valuePositionY}
          fontSize={getValueUnitsFontSize(valueHeight)}
        >
          {selectedObject.unitPosition === 'left' && (
            <tspan
              fontSize={getValueUnitsFontSize(rightUnitHeight)}
              dx="-5"
            >
              {units}
            </tspan>
          )}
          {value}
          {selectedObject.unitPosition === 'right' && (
            <tspan
              fontSize={getValueUnitsFontSize(rightUnitHeight)}
              dx="5"
            >
              {units}
            </tspan>
          )}
        </tspan>
        <tspan
          {...baseProps}
          y={metricPositionY}
          fontSize={getMetricFontSize(metricHeight)}
          fill={metric.color}
        >
          {metricText}
        </tspan>
        {(selectedObject.unitPosition === 'bottom' || !selectedObject.unitPosition) && (
          <tspan
            {...baseProps}
            y={unitPositionY}
            fontSize={getValueUnitsFontSize(unitHeight)}
          >
            {units}
          </tspan>
        )}
      </>
    );
  }

  if (metric) {
    const valuePositionY = 40;
    const valueHeight = 65;
    const metricPositionY = 75;
    const metricHeight = 15;
    return (
      <>
        <tspan
          {...baseProps}
          y={valuePositionY}
          fontSize={getValueUnitsFontSize(valueHeight)}
        >
          {value}
        </tspan>
        <tspan
          {...baseProps}
          y={metricPositionY}
          fontSize={getMetricFontSize(metricHeight)}
          fill={metric.color}
        >
          {metricText}
        </tspan>
      </>
    );
  }

  if (units) {
    const valuePositionY = 40;
    const valueHeight = 60;
    const unitPositionY = 75;
    const unitHeight = 15;
    const rightUnitHeight = 45;
    return (
      <>
        <tspan
          {...baseProps}
          y={valuePositionY}
          fontSize={getValueUnitsFontSize(valueHeight)}
        >
          {selectedObject.unitPosition === 'left' && (
            <tspan fontSize={getValueUnitsFontSize(rightUnitHeight)} dx="-5">
              {units}
            </tspan>
          )}
          {value}
          {selectedObject.unitPosition === 'right' && (
            <tspan fontSize={getValueUnitsFontSize(rightUnitHeight)} dx="5">
              {units}
            </tspan>
          )}
        </tspan>
        {(selectedObject.unitPosition === 'bottom' || !selectedObject.unitPosition) && (
          <tspan
            {...baseProps}
            y={unitPositionY}
            fontSize={getValueUnitsFontSize(unitHeight)}
          >
            {units}
          </tspan>
        )}
      </>
    );
  }

  const valuePositionY = 45;
  const valueHeight = 70;
  return (
    <tspan
      {...baseProps}
      y={valuePositionY}
      fontSize={getValueUnitsFontSize(valueHeight)}
    >
      {value}
    </tspan>
  );
};

Text.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  selectedObject: PropTypes.object.isRequired,
  value: PropTypes.string,
  units: PropTypes.string,
  comparisonMetric: PropTypes.object,
  relativeWidth: PropTypes.number.isRequired,
};
Text.defaultProps = {
  value: '',
  units: '',
  comparisonMetric: null,
};

export { Text };
