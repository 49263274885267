import React from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';
import { NavLink } from '@mantine/core';
import FontAwesome from '~components/UI/FontAwesome';
import { ItemNavLink } from './ItemNavLink';

const WorkspaceItem = ({
  item, disabled, handleFavoriteChange, handleDuplication, handleDelete, onClick, icon, type, url,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type,
    item: { type, id: item.id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleClick = e => {
    if (!(e.ctrlKey || e.shiftKey || e.button === 1)) {
      e.preventDefault();
      onClick(e);
    }
  };

  const opacity = isDragging ? 0 : 1;
  return (
    <div ref={drag} style={{ opacity }}>
      <ItemNavLink
        className="editItemMenu"
        disabled={disabled}
        handleFavoriteChange={handleFavoriteChange}
        handleDuplication={handleDuplication}
        handleDelete={handleDelete}
        key={item.id}
        id={item.id}
        share
        target={(
          <a href={url} target="_blank" rel="noopener noreferrer" className="item">
            <NavLink
              className="item"
              label={item.name}
              icon={icon}
              onClick={handleClick}
              rightSection={<FontAwesome icon="ellipsis-h" />}
            />
          </a>
        )}
        type={type}
      />
    </div>
  );
};

WorkspaceItem.propTypes = {
  disabled: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleDuplication: PropTypes.func.isRequired,
  handleFavoriteChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  item: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default WorkspaceItem;
