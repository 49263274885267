import React from 'react';

const xmark = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 174" fill="currentColor" height={height}>
    <g
      transform="translate(0.000000,174.000000) scale(0.100000,-0.100000)"
      stroke="none"
    >
      <path d="M635 1361 c-44 -18 -69 -72 -56 -119 3 -13 85 -101 181 -197 l175
-175 -175 -175 c-96 -96 -178 -184 -181 -197 -21 -77 52 -146 127 -118 16 6
106 87 199 181 l170 169 174 -174 c97 -97 184 -177 198 -181 79 -20 149 66
112 138 -6 12 -86 97 -177 190 l-167 167 167 168 c91 92 171 177 177 189 37
72 -33 158 -112 138 -14 -4 -101 -84 -198 -181 l-174 -174 -170 169 c-185 185
-214 204 -270 182z"
      />
    </g>
  </svg>
);

export default xmark;
