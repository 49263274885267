import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { invertColor, getTitle } from '~utils';
import { getTitleFontSize } from '~utils/responsiveValue';
import { tileWidgetTypes } from '~utils/types';
import './TileContents.scss';

const TileContents = forwardRef(({
  backgroundColor,
  children,
  extraHeaders,
  height,
  showTitle,
  tile,
  topRightSection,
  whiteBorder,
  width,
}, ref) => {
  const doHideTitleSpace = [tileWidgetTypes.MEDIA, tileWidgetTypes.PROMPT].includes(tile.type) && !tile.title;
  const bordered = tile.type === tileWidgetTypes.BUTTON && 'bordered';
  const whiteBordered = whiteBorder ? 'white' : '';

  const titleSize = tile.titleSize || Math.max(getTitleFontSize(height, width, tile.title), 10);

  const featureToggles = useSelector(state => state.settings.settings.featureToggles);
  const language = useSelector(state => state.views.language);

  const title = featureToggles.features?.tileTranslations ? getTitle(tile, language) : tile?.title || '';

  return (
    <div
      className={`Dashboard__Widget framed ${bordered} ${whiteBordered}`}
      style={{ backgroundColor, color: invertColor(backgroundColor) }}
    >
      <div className="topRightSection">
        {topRightSection}
      </div>
      {extraHeaders}
      {showTitle && !doHideTitleSpace && (
        <div
          className="Dashboard__Widget__Title"
          style={{ fontSize: `${titleSize}px`, lineHeight: 1 }}
        >
          {title}
        </div>
      )}
      <div
        id={`content_${tile.id}`}
        className="Dashboard__Widget__Contents"
        style={{ height: doHideTitleSpace ? '100%' : '85%' }}
        ref={ref}
      >
        {children}
      </div>
    </div>
  );
});

TileContents.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.node,
  extraHeaders: PropTypes.node,
  height: PropTypes.number.isRequired,
  showTitle: PropTypes.bool,
  tile: PropTypes.object,
  whiteBorder: PropTypes.bool,
  width: PropTypes.number.isRequired,
  topRightSection: PropTypes.element,
};
TileContents.defaultProps = {
  children: null,
  extraHeaders: null,
  showTitle: true,
  tile: {
    id: '',
    title: '',
    type: '',
  },
  whiteBorder: false,
  topRightSection: null,
};

export default TileContents;
