import types from './types';

const loginRequest = request => ({
  type: types.LOGIN_REQUEST,
  payload: request,
});

const updateIsSignup = isSignup => ({
  type: types.UPDATE_SIGNUP,
  payload: isSignup,
});

const updateIsVerification = isVerification => ({
  type: types.UPDATE_VERIFICATION,
  payload: isVerification,
});

const setTheme = theme => ({
  type: types.SET_THEME,
  payload: theme,
});

const loginResponse = response => ({
  type: types.LOGIN_RESPONSE,
  payload: response,
});

const logoutRequest = (request = null as unknown) => ({
  type: types.LOGOUT_REQUEST,
  payload: request,
});

const logoutResponse = response => ({
  type: types.LOGOUT_RESPONSE,
  payload: response,
});

const signUpRequest = signupRequest => ({
  type: types.SIGNUP_REQUEST,
  payload: signupRequest,
});

const signUpResponse = signupResponse => ({
  type: types.SIGNUP_RESPONSE,
  payload: signupResponse,
});

const loginMicrosoftRequest = () => ({
  type: types.LOGIN_MICROSOFT_REQUEST,
});

const loginMicrosoftResponse = microsoftResponse => ({
  type: types.LOGIN_MICROSOFT_RESPONSE,
  payload: microsoftResponse,
});

export {
  loginRequest,
  setTheme,
  loginResponse,
  updateIsSignup,
  updateIsVerification,
  signUpRequest,
  signUpResponse,
  logoutRequest,
  logoutResponse,
  loginMicrosoftRequest,
  loginMicrosoftResponse,
};
