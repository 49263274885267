import React, { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { setTileWidgetValue } from '~services/tileWidgetValue/actions';
import { Text } from './Text';

// Regular expression to check if a string represents a valid number (integer or decimal)
const NUMBER_REGEX = /^\d+(\.\d+)?$/;
// Regular expression to check if a string represents a valid time string in the format 'hh:mm' or 'mm:ss'
const TIME_STRING_REGEX = /^(\d{1,2})h(\d{2})|(\d{1,2})m(\d{2})$/;

const parseTimeStringToMillis = timeString => {
  const matches = timeString.match(TIME_STRING_REGEX);
  if (!matches) return null;

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (matches[1] && matches[2]) {
    // This means we have a match for the 'hh:mm' format
    hours = parseInt(matches[1], 10);
    minutes = parseInt(matches[2], 10);
  } else if (matches[3] && matches[4]) {
    // This means we have a match for the 'mm:ss' format
    minutes = parseInt(matches[3], 10);
    seconds = parseInt(matches[4], 10);
  }

  return ((hours * 60 * 60) + (minutes * 60) + seconds) * 1000;
};

const ResponsiveScorecard = memo(({
  x,
  y,
  w,
  h,
  selectedObject,
  value,
  units,
  hasLoaded,
  comparisonMetric,
  backgroundColor,
  isCircle,
  loaderPos,
}) => {
  const dispatch = useDispatch();
  const relativeWidth = isCircle ? 100 : (w / h) * 100; // if height is 100 what the width should be
  let displayedValue = value;

  if (typeof value === 'boolean') {
    displayedValue = value ? 'true' : 'false';
  }

  useEffect(() => {
    let finalValue = value;
    if (typeof value === 'string') {
      if (NUMBER_REGEX.test(value)) {
        finalValue = parseFloat(value);
      } else if (TIME_STRING_REGEX.test(value)) {
        finalValue = parseTimeStringToMillis(value);
      }
    }

    dispatch(setTileWidgetValue({ selectedObjectId: selectedObject.id, value: finalValue }));
  }, [value, selectedObject?.id]);

  if (hasLoaded) {
    return (
      <svg viewBox={`0 0 ${relativeWidth} 100`} x={x} y={y} width={w} height={h}>
        <text>
          <Text
            selectedObject={selectedObject}
            value={displayedValue.toString()}
            units={units}
            comparisonMetric={comparisonMetric}
            backgroundColor={backgroundColor}
            relativeWidth={relativeWidth}
          />
        </text>
      </svg>
    );
  }

  return (
    <svg>
      <foreignObject {...loaderPos}>
        <Loader />
      </foreignObject>
    </svg>
  );
});

ResponsiveScorecard.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  selectedObject: PropTypes.object.isRequired,
  x: PropTypes.number,
  y: PropTypes.number,
  w: PropTypes.number,
  h: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  units: PropTypes.string,
  hasLoaded: PropTypes.bool,
  comparisonMetric: PropTypes.object,
  isCircle: PropTypes.bool,
  loaderPos: PropTypes.object,
};
ResponsiveScorecard.defaultProps = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  value: '',
  units: '',
  hasLoaded: false,
  comparisonMetric: null,
  isCircle: false,
  loaderPos: null,
};

export default ResponsiveScorecard;
