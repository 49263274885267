import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select, Icon } from '@intelligenceindustrielle/react-ui-components';
import FontAwesome from '~UI/FontAwesome';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { RootState } from '~services/store';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import { CheckboxToggle, TextInput } from '~components/UI';
import './TriggerForms.scss';

interface StopCause {
  id: string;
  name: string;
  subMenu?: StopCause[];
  nameEN?: string;
  nameFR?: string;
  nameES?: string;
}

const StopCauseAssignTriggerForm = ({ trigger }) => {
  const { t } = useTranslation();
  const { details } = trigger;

  const machines = useSelector((state: RootState) => state.machines);
  const language = useSelector((state: RootState) => state.views.language);
  const settings = useSelector((state: RootState) => state.settings.settings);
  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);

  const findStopCauseInSubMenu = (stopCauses, selectedStopCauseId) => {
    for (let i = 0; i < stopCauses.length; i += 1) {
      const stopCause = stopCauses[i];
      if (stopCause.id === selectedStopCauseId) {
        return [stopCauses];
      }
      if (stopCause.subMenu) {
        const result = findStopCauseInSubMenu(stopCause.subMenu, selectedStopCauseId);
        if (result) {
          return [stopCauses, ...result];
        }
      }
    }
    return null;
  };

  const preSelectStopCause = machineIdArg => {
    const selectedMachine = machines.find(m => m.id === machineIdArg);
    let stopCauses = selectedMachine && selectedMachine.stopCauses;
    let menuStack = [];

    if (selectedMachine && stopCauses.length) {
      const selectedStopCauseId = details?.stopCauseId;
      menuStack = findStopCauseInSubMenu(stopCauses, selectedStopCauseId);
      if (menuStack) {
        stopCauses = menuStack[menuStack.length - 1];
      }
      return { selectedStopCauseId, stopCauses, menuStack };
    }
    return { selectedStopCauseId: '', stopCauses: null, menuStack: [] };
  };

  const [selectedStopCauseIdState, setSelectedStopCauseId] = useState(details?.stopCauseId);
  const [machineId, setMachineId] = useState(details?.machineId);
  const [addComment, setAddComment] = useState(details?.addComment);
  const [delay, setDelay] = useState(details?.delay);
  const [stopCausesState, setStopCauses] = useState<StopCause[] | null>(null);
  const [isSubMenuState, setIsSubMenu] = useState<boolean | null>(null);
  const [menuStack, setMenuStack] = useState<Array<null | { id: string; name: string; }[] | null>>([]);

  const onDelayChange = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    let delayUpdated = value;
    try {
      delayUpdated = variableToId(value, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }
    setDelay(delayUpdated);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    return idToReadableExpr(value, inputsAndVariables);
  };

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(v => v.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  useEffect(() => {
    const preSelectStopCauseObj = preSelectStopCause(machineId);
    setSelectedStopCauseId(preSelectStopCauseObj.selectedStopCauseId);
    setStopCauses(preSelectStopCauseObj.stopCauses);
    setIsSubMenu(preSelectStopCauseObj?.menuStack?.length > 1);
    setMenuStack(preSelectStopCauseObj.menuStack || []);
  }, [machineId]);

  const handleBackButton = () => {
    if (menuStack.length > 0) {
      const newMenuStack = [...menuStack];
      const previousMenu = newMenuStack.pop();
      setMenuStack(newMenuStack);
      setStopCauses(previousMenu || null);
      setIsSubMenu(newMenuStack.length > 0);
    }
  };

  const handleStopCauseSelection = stopCause => {
    const selectedMachine = machines.find(m => m.id === machineId);
    if (!stopCause) {
      setIsSubMenu(false);
      setStopCauses(selectedMachine?.stopCauses);
      setMenuStack([]);
    } else if (stopCause?.subMenu?.length) {
      setIsSubMenu(true);
      setMenuStack([...menuStack, stopCausesState]);
      setStopCauses(stopCause.subMenu);
    } else if (selectedStopCauseIdState === stopCause.id) {
      setSelectedStopCauseId('');
    } else {
      setSelectedStopCauseId(stopCause.id);
    }
  };

  const handleMachineIdChange = newMachineId => {
    const { selectedStopCauseId, stopCauses } = preSelectStopCause(newMachineId);
    setMachineId(newMachineId);
    setIsSubMenu(menuStack?.length > 1);
    setSelectedStopCauseId(selectedStopCauseId);
    setStopCauses(stopCauses);
    setMenuStack(menuStack || []);
  };

  return (
    <div className="valuesTriggerForm">
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }))}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />
      {machineId && <div className="inputTitle">{t('stopCause')}</div>}
      <input type="hidden" name="stopCauseId" value={selectedStopCauseIdState} />
      <div>
        {
          !isSubMenuState && machineId && (
            <StopCauseIcon
              key="unknown"
              option={{
                name: t('unknown'),
                icon: 'Interrogation',
                color: settings.defaultUnfilledStopCauseColor || '#444',
              }}
              small
              className={(selectedStopCauseIdState === 'unknown') ? 'Selected' : 'Unselected'}
              onClick={() => handleStopCauseSelection({ id: 'unknown' })}
            />
          )
        }
        {
          isSubMenuState && (
            <FontAwesome
              icon="arrow-left"
              className="backButtonArrow"
              style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
              onClick={handleBackButton}
            />
          )
        }
        {
          stopCausesState?.map(stopcause => {
            let stopCauseName;
            switch (language) {
              case 'en':
                stopCauseName = stopcause.nameEN || stopcause.name;
                break;
              case 'fr':
                stopCauseName = stopcause.nameFR || stopcause.name;
                break;
              case 'es':
                stopCauseName = stopcause.nameES || stopcause.name;
                break;
              default:
                stopCauseName = stopcause.name;
            }

            return (
              <StopCauseIcon
                key={stopcause.id}
                option={{
                  ...stopcause,
                  name: stopCauseName,
                  badgeCount: stopcause.subMenu?.length,
                }}
                small
                className={(stopcause.id === selectedStopCauseIdState) ? 'Selected' : 'Unselected'}
                onClick={() => handleStopCauseSelection(stopcause)}
              />
            );
          })
        }
      </div>
      {
        machineId && (
          <>
            <div className="inputTitle">
              {`${t('delayInMinutes')} (${t('optional')})`}
              <Icon
                icon="info-circle"
                tooltipLabel={t('delayInMinutesDefinition')}
              />
            </div>
            <input type="hidden" name="delay" value={delay} />
            <TextInput
              options={inputsAndVariables}
              trigger="$"
              value={delay ? idToReadable(delay) : null}
              className="fullwidth"
              onChange={e => onDelayChange(e)}
              placeholder={t('triggerVariableList')}
            />
          </>
        )
      }
      <div className="inputTitle">
        {t('addComment')}
        <Icon
          icon="info-circle"
          tooltipLabel={t('addCommentDefinition')}
        />
        &nbsp;
        <CheckboxToggle
          name="addComment"
          controlledCheck={addComment}
          readOnly={!selectedStopCauseIdState}
          onChange={setAddComment}
        />
      </div>
    </div>
  );
};

StopCauseAssignTriggerForm.propTypes = {
  trigger: PropTypes.shape({
    details: PropTypes.shape({
      machineId: PropTypes.string,
      stopCauseId: PropTypes.string,
      addComment: PropTypes.bool,
      delay: PropTypes.string,
    }),
  }).isRequired,
};

export default StopCauseAssignTriggerForm;
