import React from 'react';

const santaStopIcon = ({ height }) => (
  <svg
    height={height * 5}
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M335.523 127.92C335.523 118.176 331.653 108.832 324.762 101.942C317.872 95.052 308.528 91.1814 298.784 91.1814C289.04 91.1814 279.697 95.052 272.806 101.942C265.916 108.832 262.046 118.176 262.046 127.92C262.046 137.664 265.916 147.008 272.806 153.898C279.697 160.788 289.04 164.658 298.784 164.658C308.528 164.658 317.872 160.788 324.762 153.898C331.653 147.008 335.523 137.664 335.523 127.92ZM186.808 225.506C194.386 217.929 204.718 213.643 215.51 213.643C216.965 213.643 218.419 213.719 219.797 213.872L195.917 285.589C188.798 307.02 197.218 330.594 216.429 342.61L282.405 383.865L262.964 451.831C259.213 464.843 266.791 478.389 279.803 482.14C292.814 485.89 306.36 478.313 310.112 465.302L332.077 388.457C336.594 372.69 330.087 355.852 316.235 347.202L272.761 320.032L296.411 256.964L300.315 266.377C311.796 293.778 338.507 311.613 368.204 311.613H384.507C398.054 311.613 409 300.667 409 287.12C409 273.572 398.054 262.628 384.507 262.628H368.204C358.331 262.628 349.375 256.658 345.626 247.549L340.803 236.068C329.628 209.204 307.05 188.691 279.19 180.119L241.915 168.638C233.42 166.036 224.542 164.658 215.587 164.658C191.86 164.658 169.052 174.073 152.29 190.911L134.533 208.592C124.965 218.158 124.965 233.696 134.533 243.263C144.1 252.831 159.637 252.831 169.205 243.263L186.885 225.583L186.808 225.506ZM160.403 360.597H115.092C101.545 360.597 90.5996 371.541 90.5996 385.089C90.5996 398.636 101.545 409.582 115.092 409.582H168.363C182.905 409.582 196.07 401.01 201.963 387.767L210.765 367.945L203.494 363.353C190.1 355.009 180.15 342.84 174.486 328.986L160.403 360.597Z" fill="white" />
    <path d="M260.19 47.7582C260.272 47.2016 260.315 46.6405 260.323 46.0784C260.317 43.4726 259.47 40.9387 257.906 38.8542C256.343 36.7697 254.147 35.2463 251.648 34.5115C260.682 24.6401 273.196 18.6641 286.552 17.8451C287.752 17.761 289.024 17.713 290.224 17.713C295.143 17.5956 300.035 18.4521 304.621 20.2333C309.207 22.0147 313.396 24.6854 316.945 28.0921C332.808 43.2226 336.6 71.4918 337.476 85.9745H256.988C259.519 70.4719 264.186 62.7448 268.087 58.905L268.675 58.3411C268.867 58.1613 269.058 58.0051 269.25 57.8371C270.624 56.6235 272.253 55.7302 274.014 55.2214L274.385 55.1495C274.465 55.1416 274.541 55.1183 274.61 55.0807C274.679 55.0434 274.743 54.9926 274.792 54.9313C274.843 54.8701 274.879 54.7995 274.902 54.7238C274.926 54.6478 274.932 54.5682 274.926 54.4895C274.918 54.4107 274.894 54.3343 274.857 54.2645C274.82 54.1947 274.769 54.1331 274.708 54.0829C274.647 54.0327 274.575 53.9951 274.5 53.9723C274.424 53.9494 274.345 53.9416 274.265 53.9496C272.367 54.2923 270.591 55.1189 269.105 56.3492C268.944 55.0191 268.513 53.7355 267.842 52.5764C267.17 51.4173 266.268 50.4068 265.195 49.6059C263.695 48.605 261.98 47.972 260.19 47.7582Z" fill="#9F0500" />
    <path d="M259.136 46.0813C259.136 46.5999 259.091 47.1177 259.003 47.6292C259.003 47.8331 259.003 48.0252 258.919 48.2291C258.836 48.4332 258.823 48.625 258.764 48.8291C258.24 50.7724 257.184 52.5307 255.715 53.9058C254.245 55.281 252.421 56.2182 250.447 56.6117C248.473 57.0051 246.43 56.8393 244.546 56.1329C242.66 55.4263 241.011 54.2074 239.783 52.6134C238.554 51.0193 237.797 49.1138 237.593 47.1116C237.389 45.1093 237.75 43.0902 238.634 41.2818C239.515 39.4737 240.887 37.948 242.591 36.8773C244.295 35.8066 246.265 35.2334 248.277 35.2224C248.661 35.2224 249.02 35.2224 249.392 35.2824L250.041 35.3783L250.761 35.5222C253.141 36.0837 255.262 37.4314 256.782 39.3473C258.301 41.2632 259.132 43.6357 259.136 46.0813Z" fill="#EFF0F2" />
    <path d="M257.957 87.1771H337.773C337.916 87.1771 338.048 87.1771 338.193 87.1771H338.769C340.3 87.4185 341.694 88.2019 342.696 89.3844C343.698 90.5672 344.241 92.0704 344.229 93.6204V109.219C344.225 110.93 343.543 112.57 342.333 113.78C341.124 114.99 339.483 115.671 337.773 115.674H254.715C253.005 115.671 251.366 114.99 250.156 113.78C248.945 112.57 248.264 110.93 248.262 109.219V93.6204C248.262 91.9084 248.941 90.2664 250.152 89.0556C251.362 87.8451 253.005 87.165 254.715 87.165L257.957 87.1771Z" fill="#EFF0F2" />
  </svg>
);

export default santaStopIcon;
