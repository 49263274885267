import React from 'react';
import PropTypes from 'prop-types';
import { BasicCard, FontAwesome, ModalHandler } from '~UI';

function CreateCard({ title, modal }) {
  const addButton = props => (
    <button type="button" className="addCardButton" {...props}>
      <FontAwesome icon="plus" />
    </button>
  );

  return (
    <ModalHandler
      Trigger={{
        Component: BasicCard,
        props: {
          title,
          icon: addButton(),
        },
      }}
      Modal={modal}
    />
  );
}

CreateCard.propTypes = {
  title: PropTypes.string.isRequired,
  modal: PropTypes.shape({
    Component: PropTypes.elementType.isRequired,
    props: PropTypes.object.isRequired,
  }).isRequired,
};

export default CreateCard;
