import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Icon } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { TextInput } from '~UI/index';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './ActionForm.scss';

const MQTTActionForm = ({ action }) => {
  const { t } = useTranslation();
  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);
  const machines = useSelector((state: RootState) => state.machines);

  const { params } = action;

  const [topic, setTopic] = useState((params && params.topic) || '');
  const [message, setMessage] = useState((params && params.message) || '');
  const [selectedFlag, setSelectedFlag] = useState((params && params.messageType) || 'setProperty');

  const handleFlagSelection = value => setSelectedFlag(value);

  const handleChangeTopic = topicSelected => {
    if (topicSelected.includes(' (')) {
      const newTopic = topicSelected.split(' (')[0];
      setTopic(newTopic);
    } else {
      setTopic(topicSelected);
    }
  };

  const handleChangeMessage = messageArg => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    let id = messageArg;
    try {
      id = variableToId(messageArg, inputsAndVariables);
    } catch (error) {
      // Error handling of non existent variable is made in the ActionEditionForm
      // This way the error message is popped only on submit and not on change
    }
    setMessage(id);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];

    return idToReadableExpr(value, inputsAndVariables);
  };

  const topics = streams.map(stream => `${stream.topicOut} (${stream.name})`);

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  const buttons = [
    { label: 'Set Property', value: 'setProperty' },
    { label: 'Request Action', value: 'requestAction' },
  ];

  return (
    <div className="mqttActionForm">
      <div className="inputTitle">{t('mqttTopic')}</div>
      <TextInput
        name="topic"
        options={topics}
        value={topic}
        className="fullwidth"
        onChange={e => handleChangeTopic(e)}
        placeholder="Topic"
        matchAny
      />
      <div className="inputTitle">{t('type')}</div>
      <input type="hidden" name="messageType" value={selectedFlag} />
      <ButtonGroup
        buttons={buttons}
        onChange={handleFlagSelection}
        value={selectedFlag}
      />
      <div className="inputTitle">
        {t('mqttMessageJson')}
        <Icon
          icon="info-circle"
          tooltipLabel={t('mqttActionCanUseVariables')}
        />
      </div>
      <input
        type="hidden"
        value={message}
        name="message"
      />
      <TextInput
        options={inputsAndVariables}
        trigger="$"
        value={message ? idToReadable(message) : null}
        Component="textarea"
        rows={5}
        className="fullwidth"
        onChange={e => handleChangeMessage(e)}
        placeholder={`{"${t('parameter')}": "${t('value')}"}`}
        matchAny
        style={{ resize: 'vertical', width: `${100}%` }}
      />
    </div>
  );
};

MQTTActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      message: PropTypes.string,
      messageType: PropTypes.string,
      topic: PropTypes.string,
    }),
  }),
};
MQTTActionForm.defaultProps = {
  action: {
    params: {
      topic: '',
      message: '',
    },
  },
};

export default MQTTActionForm;
