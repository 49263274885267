import { setFeatureToggles, setUserPermissionToggles } from './reduxStoreCache';

const featureTogglesFetcher = action => {
  if (action.type === 'GET_SETTINGS_RESPONSE') {
    setFeatureToggles(action.payload.settings.featureToggles);
  }
};

const userPermissionTogglesFetcher = action => {
  if (action.type === 'GET_USER_PERMISSIONS_RESPONSE') {
    setUserPermissionToggles(action.payload.user.permissions);
  }
};

const storeCachingMiddleware = _store => next => action => {
  featureTogglesFetcher(action);
  userPermissionTogglesFetcher(action);
  return next(action);
};

export default storeCachingMiddleware;
