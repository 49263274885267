import { Log } from './types';

export enum LogActionTypes {
  GET_LOGS_REQUEST = 'GET_LOGS_REQUEST',
  GET_LOGS_RESPONSE = 'GET_LOGS_RESPONSE',
}

export type LogAction =
  | { type: LogActionTypes.GET_LOGS_REQUEST; payload: { logs: Log[] } }
  | { type: LogActionTypes.GET_LOGS_RESPONSE; payload: { logs: Log[] } };

const getLogsRequest = data => ({
  type: LogActionTypes.GET_LOGS_REQUEST,
  payload: data,
});

const getLogsResponse = (data, request = null) => ({
  type: LogActionTypes.GET_LOGS_RESPONSE,
  payload: data,
  request,
});

export {
  getLogsRequest,
  getLogsResponse,
};
