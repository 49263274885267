import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import { sortArray } from '~utils/sort';

const FormTileProgressBar = ({ selectedTile }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  return (
    <div>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        defaultValue={selectedTile.machineId}
        placeholder={t('select')}
      />
    </div>
  );
};

FormTileProgressBar.propTypes = {
  selectedTile: PropTypes.shape({
    machineId: PropTypes.string,
  }).isRequired,
};

export default FormTileProgressBar;
