import React from 'react';
import './cards.scss';

interface CardsProps {
  children: React.ReactNode;
}

const Cards = ({ children }: CardsProps, ref) => (
  <div className="cardsContainer" ref={ref}>
    {children}
  </div>
);

export default React.forwardRef<HTMLElement, CardsProps>(Cards);
