import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { reducersTypes } from '~services/index';

const IframeMedia = ({ displayURL, widget }) => {
  const [lastUpdated, setLastUpdated] = useState(Date.now());

  function timeToUpdateIFrame() {
    if (widget.refreshRateToggle) {
      const nextUpdate = new Date(lastUpdated + widget.refreshRateValue * 60000);
      if (nextUpdate.getTime() < Date.now()) {
        setLastUpdated(Date.now());
      }
    }
  }
  timeToUpdateIFrame();

  return (
    <div
      className="framed flex V"
      style={{ zIndex: -1, position: 'relative' }}
    >
      <iframe
        style={{
          border: 0,
          height: `${100 * (widget.scale || 1)}%`,
          width: `${100 * (widget.scale || 1)}%`,
          zIndex: -1,
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          transform: `scale(${1 / (widget.scale || 1)})`,
          transformOrigin: '0 0',
          borderRadius: widget.format.shape === 'circle' ? '50%' : 0,
        }}
        title="iframeWidget"
        src={displayURL}
        key={lastUpdated}
      >
        <p>Your browser does not support iframes</p>
      </iframe>
    </div>
  );
};

IframeMedia.propTypes = {
  displayURL: PropTypes.string.isRequired,
  widget: reducersTypes.topviews.widget.isRequired,
};

export { IframeMedia };
