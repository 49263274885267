import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '~components/Pages';
import { DefaultThemedButton, FontAwesome } from '~UI';
import { reduxOperations } from '~services';
import MachineKPIsForm from './MachineKPIsForm';
import { showError } from '~utils/toast';
import { variableIsCriticallyUsed, getCriticallyUsedErrorMessage } from '~utils/isVariableUsed';

const propTypes = {
  machineId: PropTypes.string,
};

const defaultProps = {
  machineId: '',
};

const MachineKPIs = ({ machineId }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);
  const variables = useSelector(state => state.variables);
  const triggers = useSelector(state => state.triggers);
  const actions = useSelector(state => state.actions);

  const [isEditing, setIsEditing] = useState(false);
  const [selectedKPI, setSelectedKPI] = useState(null);

  const handleEdit = id => {
    setIsEditing(true);
    setSelectedKPI(id);
  };

  const handleDelete = kpiId => {
    const message = getCriticallyUsedErrorMessage(kpiId, actions, triggers, variables, language);
    if (message) {
      showError(message);
      return;
    }
    return dispatch(reduxOperations.machines.deleteKPI(machineId, kpiId));
  };

  const goToHomeKPITab = () => {
    setIsEditing(false);
    setSelectedKPI(null);
  };

  const isNotUsedStyleCondition = () => ({
    style: { opacity: '0.6' },
    condition: kpiId => !variableIsCriticallyUsed(kpiId, actions, triggers, variables),
  });

  const machine = machines.find(elem => elem.id === machineId);
  const kpis = machine.kpis || [];
  return (
    <>
      <div className="topButtonsBlock flexSpaceBetween">
        {
          isEditing && (
            <FontAwesome
              icon="arrow-left"
              className="backButtonArrow"
              style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
              onClick={goToHomeKPITab}
            />
          )
        }
        {
          selectedKPI === null && !isEditing && (
            <DefaultThemedButton
              key="btnAdd"
              content={t('add')}
              isActive={false}
              className="skuNumberAddButton"
              onClick={() => setIsEditing(true)}
            />
          )
        }
      </div>

      {
        isEditing ? (
          <MachineKPIsForm
            machineId={machineId}
            modifiedItemId={selectedKPI}
            goBack={goToHomeKPITab}
          />
        )
          : (
            <DefaultTable
              columnNames={[
                { name: t('name') },
                { name: t('variable') },
                { name: t('timePeriod') },
                { name: t('refreshInterval') },
              ]}
              entriesProperties={['name', 'variable', 'intervalType', 'refreshInterval']}
              entriesStyle={[
                { property: 'name', ...isNotUsedStyleCondition },
                { property: 'variable', ...isNotUsedStyleCondition },
                { property: 'intervalType', ...isNotUsedStyleCondition },
              ]}
              entries={
                kpis.map(kpi => ({
                  id: kpi.id,
                  name: t(kpi.name),
                  variable: kpi.variable,
                  intervalType: t(kpi.intervalType),
                  refreshInterval: t(kpi.refreshInterval) || t('1min'),
                }))
              }
              editFunction={handleEdit}
              deleteFunction={handleDelete}
            />
          )
      }
    </>
  );
};

MachineKPIs.propTypes = propTypes;
MachineKPIs.defaultProps = defaultProps;

export default MachineKPIs;
