import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle, CancelButton, SubmitButton } from '~components/UI';
import { RootState } from '~services/store';
import { reduxOperations } from '~services/index';
import { getFormData } from '~utils/index';
import { showError, showSuccess } from '~utils/toast';

const MachineParams = ({ machineId, onHide, onChange }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const machines = useSelector((state: RootState) => state.machines);
  const operators = useSelector((state: RootState) => state.operators);
  const operations = useSelector((state: RootState) => state.operations.operations).filter(
    element => element.machines.includes(machineId),
  );

  const machine = machines.find(elem => elem.id === machineId) || {};
  const machineParams = machine.params || {};

  const [skuNumber, setSKUNumber] = useState(machineParams.skuNumber);
  const [operator, setOperator] = useState(machineParams.operator);
  const [operation, setOperation] = useState(machineParams.operation);
  const [hasSKUNumber, setHasSKUNumber] = useState(!!machineParams.skuNumber);
  const [hasOperator, setHasOperator] = useState(!!machineParams.operator);
  const [hasWorkOrder, setHasWorkOrder] = useState(!!machineParams.workOrder || machineParams.workOrder === '');
  const [hasOperation, setHasOperation] = useState(!!machineParams.operation);

  const machineOperations = operations.filter(element => element.machines.includes(machineId));
  const machineOperators = operators?.filter(op => machine?.operators?.includes(op.id));

  const onFormChange = () => {
    const formData = getFormData('machineParams__form');
    Object.keys(formData).forEach(key => (formData[key] === '') && key !== 'workOrder' && delete formData[key]);
    onChange(formData);
  };

  useEffect(() => {
    onFormChange();
  }, [hasSKUNumber, hasOperator, hasWorkOrder, hasOperation]);

  const handleSubmit = e => {
    e.preventDefault();
    const formData = getFormData('machineParams__form');
    Object.keys(formData).forEach(key => (formData[key] === '') && key !== 'workOrder' && delete formData[key]);

    reduxOperations.machines.setParams(machine.id, formData)(dispatch).then(() => {
      showSuccess(t('operationSucceeded'));
      onChange(formData);
      onHide();
    }).catch(error => showError(error.status));
  };

  const hasOperationHandler = () => {
    if (!hasSKUNumber) {
      setHasSKUNumber(prevState => !prevState);
    }
    setHasOperation(prevState => !prevState);
  };

  const operationValueHandler = e => {
    setOperation(e);
    setSKUNumber(machineOperations.find(op => op.name === e).skuNumber);
    onFormChange();
  };

  return (
    <form id="machineParams__form" onSubmit={handleSubmit}>
      <div className="machineParamsContainer">
        <div className="inputTitle">
          {t('skuNumber')}
          &nbsp;
          <CheckboxToggle
            controlledCheck={hasSKUNumber}
            onChange={() => setHasSKUNumber(prevState => !prevState)}
            readOnly={operation && hasOperation}
          />
        </div>
        {hasSKUNumber && (
          <Select
            style={operation && hasOperation ? { opacity: '0.6' } : null}
            name="skuNumber"
            className="fullwidth"
            disabled={operation && hasOperation}
            value={skuNumber || ''}
            onChange={e => {
              setSKUNumber(e);
              onFormChange();
            }}
            options={
              machine.skuNumbers && machine.skuNumbers.map(sku => (
                { label: sku.name, value: sku.name }
              ))
            }
            placeholder={t('select')}
          />
        )}
      </div>
      <div className="machineParamsContainer">
        <div className="inputTitle">
          {t('operator')}
          &nbsp;
          <CheckboxToggle
            controlledCheck={hasOperator}
            onChange={() => setHasOperator(prevState => !prevState)}
          />
        </div>
        {hasOperator && (
          <Select
            name="operator"
            className="fullwidth"
            value={operator || ''}
            onChange={e => {
              setOperator(e);
              onFormChange();
            }}
            options={
              machineOperators?.map(op => (
                { label: op.name, value: op.name }
              ))
            }
            placeholder={t('select')}
          />
        )}
      </div>
      <div className="machineParamsContainer">
        <div className="inputTitle">
          {t('workOrder')}
          &nbsp;
          <CheckboxToggle
            controlledCheck={hasWorkOrder}
            onChange={() => setHasWorkOrder(prevState => !prevState)}
          />
        </div>
        {hasWorkOrder && (
          <input
            name="workOrder"
            type="text"
            defaultValue={machineParams.workOrder || ''}
            className="fullwidth"
            onChange={onFormChange}
          />
        )}
      </div>
      <div className="machineParamsContainer">
        <div className="inputTitle">
          {t('operation')}
          &nbsp;
          <CheckboxToggle
            controlledCheck={hasOperation}
            onChange={hasOperationHandler}
          />
        </div>
        {hasOperation && (
          <Select
            name="operation"
            className="fullwidth"
            value={operation || ''}
            onChange={e => operationValueHandler(e)}
            options={
              machineOperations.map(o => (
                { label: o.name, value: o.name }
              ))
            }
            placeholder={t('select')}
          />
        )}
      </div>
      <div className="buttonsHolder" style={{ textAlign: 'right' }}>
        <CancelButton onClick={onHide} />
        <SubmitButton />
      </div>
    </form>
  );
};

MachineParams.propTypes = {
  machineId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

MachineParams.defaultProps = {
  machineId: '',
};

export default MachineParams;
