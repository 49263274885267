import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { reducersTypes } from '~services';

const IframeMedia = ({ displayURL, tile }) => {
  const [lastUpdated, setLastUpdated] = useState(Date.now());

  function timeToUpdateIFrame() {
    if (tile.refreshRateToggle) {
      const nextUpdate = new Date(lastUpdated + tile.refreshRateValue * 60000);
      if (nextUpdate.getTime() < Date.now()) {
        setLastUpdated(Date.now());
      }
    }
  }
  timeToUpdateIFrame();

  return (
    <div className="framed flex V">
      <iframe
        style={{
          border: 0,
          height: !tile.scale ? `${100}%` : `${((1 / tile.scale) * 100)}%`,
          width: !tile.scale ? `${100}%` : `${((1 / tile.scale) * 100)}%`,
          transform: !tile.scale ? `scale(${1})` : `scale(${tile.scale})`,
          transformOrigin: '0 0',
          position: 'absolute',
          top: 0,
        }}
        title="iframeTile"
        scrolling="auto"
        src={displayURL}
        key={lastUpdated}
        allow="camera;microphone"
      >
        <p>Your browser does not support iframes</p>
      </iframe>
    </div>
  );
};

IframeMedia.propTypes = {
  displayURL: PropTypes.string.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
};

export { IframeMedia };
