import { Folder } from './types';

export enum FolderActionTypes {
  GET_FOLDERS_REQUEST = 'GET_FOLDERS_REQUEST',
  GET_FOLDERS_RESPONSE = 'GET_FOLDERS_RESPONSE',
  ADD_FOLDER_REQUEST = 'ADD_FOLDER_REQUEST',
  ADD_FOLDER_RESPONSE = 'ADD_FOLDER_RESPONSE',
  UPDATE_FOLDER_REQUEST = 'UPDATE_FOLDER_REQUEST',
  UPDATE_FOLDER_RESPONSE = 'UPDATE_FOLDER_RESPONSE',
  DELETE_FOLDER_REQUEST = 'DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_RESPONSE = 'DELETE_FOLDER_RESPONSE',
  ADD_SUBFOLDER_REQUEST = 'ADD_SUBFOLDER_REQUEST',
  ADD_SUBFOLDER_RESPONSE = 'ADD_SUBFOLDER_RESPONSE',
  UPDATE_SUBFOLDER_REQUEST = 'UPDATE_SUBFOLDER_REQUEST',
  UPDATE_SUBFOLDER_RESPONSE = 'UPDATE_SUBFOLDER_RESPONSE',
  DELETE_SUBFOLDER_REQUEST = 'DELETE_SUBFOLDER_REQUEST',
  DELETE_SUBFOLDER_RESPONSE = 'DELETE_SUBFOLDER_RESPONSE',
}

export type FolderAction =
  | { type: FolderActionTypes.GET_FOLDERS_REQUEST; }
  | { type: FolderActionTypes.GET_FOLDERS_RESPONSE; payload: { folders: Folder[] } }
  | { type: FolderActionTypes.ADD_FOLDER_REQUEST; payload: { folder: Partial<Folder> } }
  | { type: FolderActionTypes.ADD_FOLDER_RESPONSE; payload: { folder: Folder } }
  | { type: FolderActionTypes.UPDATE_FOLDER_REQUEST; payload: { id: string; folder: Partial<Folder> } }
  | { type: FolderActionTypes.UPDATE_FOLDER_RESPONSE; payload: { folder: Folder } }
  | { type: FolderActionTypes.DELETE_FOLDER_REQUEST; payload: { id: string } }
  | { type: FolderActionTypes.DELETE_FOLDER_RESPONSE; payload: { id: string } }
  | { type: FolderActionTypes.ADD_SUBFOLDER_REQUEST; payload: { id: string; subfolder: Partial<Folder> } }
  | { type: FolderActionTypes.ADD_SUBFOLDER_RESPONSE; payload: { folder: Folder } }
  | { type: FolderActionTypes.UPDATE_SUBFOLDER_REQUEST;
    payload: { folderId: string; id: string; subfolder: Partial<Folder> } }
  | { type: FolderActionTypes.UPDATE_SUBFOLDER_RESPONSE; payload: { folder: Folder } }
  | { type: FolderActionTypes.DELETE_SUBFOLDER_REQUEST; payload: { folderId: string; id: string } }
  | { type: FolderActionTypes.DELETE_SUBFOLDER_RESPONSE; payload: { id: string } };

export const getFoldersRequest = (): FolderAction => ({
  type: FolderActionTypes.GET_FOLDERS_REQUEST,
});

export const getFoldersResponse = (payload: { folders: Folder[] }): FolderAction => ({
  type: FolderActionTypes.GET_FOLDERS_RESPONSE,
  payload,
});

export const addFolderRequest = (payload: { folder: Folder }): FolderAction => ({
  type: FolderActionTypes.ADD_FOLDER_REQUEST,
  payload,
});

export const addFolderResponse = (payload: { folder: Folder }): FolderAction => ({
  type: FolderActionTypes.ADD_FOLDER_RESPONSE,
  payload,
});

export const updateFolderRequest = (payload: { id: string, folder: Folder }): FolderAction => ({
  type: FolderActionTypes.UPDATE_FOLDER_REQUEST,
  payload,
});

export const updateFolderResponse = (payload: { folder: Folder }): FolderAction => ({
  type: FolderActionTypes.UPDATE_FOLDER_RESPONSE,
  payload,
});

export const deleteFolderRequest = (payload: { id: string }): FolderAction => ({
  type: FolderActionTypes.DELETE_FOLDER_REQUEST,
  payload,
});

export const deleteFolderResponse = (payload: { id: string }): FolderAction => ({
  type: FolderActionTypes.DELETE_FOLDER_RESPONSE,
  payload,
});

export const addSubfolderRequest = (payload: { id: string; subfolder: Folder }): FolderAction => ({
  type: FolderActionTypes.ADD_SUBFOLDER_REQUEST,
  payload,
});

export const addSubfolderResponse = (payload: { folder: Folder }): FolderAction => ({
  type: FolderActionTypes.ADD_SUBFOLDER_RESPONSE,
  payload,
});

export const updateSubfolderRequest = (payload: { folderId: string; id: string; subfolder: Folder }): FolderAction => ({
  type: FolderActionTypes.UPDATE_SUBFOLDER_REQUEST,
  payload,
});

export const updateSubfolderResponse = (payload: { folder: Folder }): FolderAction => ({
  type: FolderActionTypes.UPDATE_SUBFOLDER_RESPONSE,
  payload,
});

export const deleteSubfolderRequest = (payload: { folderId: string; id: string }): FolderAction => ({
  type: FolderActionTypes.DELETE_SUBFOLDER_REQUEST,
  payload,
});

export const deleteSubfolderResponse = (payload: { id: string }): FolderAction => ({
  type: FolderActionTypes.DELETE_SUBFOLDER_RESPONSE,
  payload,
});

