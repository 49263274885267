import React, { Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import directLinks from '~utils/directLinks';
import {
  configurationFeature,
  eventsFeature,
  manageUsersFeature,
  reportFeature,
} from '~utils/featureToggles';
import { reduxOperations, reducersTypes } from './services';
import withTracker from './withTracker';
import ChangeLogPage from './pages/ChangeLog/ChangeLogPage';
import ConfigPages from './pages/Config/ConfigPages';
import DashboardPage from './pages/Dashboards/DashboardPage';
import { DashboardSelectionPage } from './pages/Dashboards/DashboardSelectionPage';
import { Page401Unauthorized, Page404NotFound } from './pages/ErrorPages';
import EventsPage from './pages/Events/EventsPage';
import HomePage from './pages/Home/HomePage';
import { ReportPage } from './pages/Reports/ReportPage';
import { ReportSelectionPage } from './pages/Reports/ReportSelectionPage';
import SignupPage from './pages/Login/SignUpPage';
import TopviewPage from './pages/Topviews/TopviewPage';
import { TopviewSelectionPage } from './pages/Topviews/TopviewSelectionPage';
import VerifyAccount from './pages/Login/VerifyAccount';

const { utils: { configPathFrom }, paths } = directLinks;
const validateLengthHasChanged = (oldComponents, newComponents) => {
  if (oldComponents && newComponents && oldComponents.length === newComponents.length) {
    return false;
  }
  return true;
};
const verifyIfAllowed = (feature, page) => (feature.isUserAllowedAccess()
  ? withTracker(page) : Page401Unauthorized);
class Routes extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      location: { pathname: nextPathname },
      dashboards: nextDashboards,
      streams: nextStreams,
      topviews: nextTopviews,
      users: nextUsers,
      machines: nextMachines,
    } = nextProps;
    const {
      location: { pathname },
      dashboards, streams, topviews, users, machines,
    } = this.props;

    if (pathname !== nextPathname) {
      const { redirectHistoryClear } = this.props;
      redirectHistoryClear();
    }

    return (
      validateLengthHasChanged(dashboards, nextDashboards)
      || validateLengthHasChanged(streams, nextStreams)
      || validateLengthHasChanged(topviews, nextTopviews)
      || validateLengthHasChanged(users, nextUsers)
      || validateLengthHasChanged(machines, nextMachines)
      || pathname !== nextPathname
    );
  }

  // This function verifies the component with the ID from the path exists before trying to render it
  verifyComponent = (component, componentPage) => {
    const {
      [component]: elementsToInspect,
      location: { pathname },
      fetchDashboards, fetchFolders, fetchReports, fetchStreams, fetchTopviews, fetchUsers, fetchMachines, fetchTags,
    } = this.props;
    const splittedArray = pathname.split('/');
    let id = splittedArray.pop();

    // For streams the id of the stream is not the last string after a "/"
    if (component === 'streams') {
      id = splittedArray[splittedArray.length - 1];
    }

    let pageToRender = Page404NotFound;
    if (Array.isArray(elementsToInspect)) {
      elementsToInspect.forEach(element => {
        if (element.id === id) {
          pageToRender = componentPage;
        }
      });
    } else {
      pageToRender = Loader;
      fetchDashboards();
      fetchFolders();
      fetchReports();
      fetchStreams();
      fetchTags();
      fetchTopviews();
      fetchUsers();
      fetchMachines();
    }
    if (pageToRender === Page404NotFound || pageToRender === Loader) {
      return pageToRender;
    }
    return withTracker(pageToRender);
  };

  render() {
    const { connexion: { loggedin }, isVerification } = this.props;
    return (
      <Switch>
        <Route path={paths.home} component={withTracker(HomePage)} />
        <Redirect exact from="/" to={paths.home} />
        <Redirect exact from={paths.login} to={paths.home} />
        <Route path={paths.signup} component={loggedin ? withTracker(HomePage) : withTracker(SignupPage)} />

        <Route
          path={paths.verifyAccount}
          component={((!isVerification && !loggedin)
            || (isVerification && loggedin)) ? withTracker(HomePage) : withTracker(VerifyAccount)}
        />

        <Route path={paths.changeLog} component={verifyIfAllowed(manageUsersFeature, ChangeLogPage)} />
        <Route path={paths.dashboard} component={this.verifyComponent('dashboards', DashboardPage)} />
        <Route exact path={paths.dashboards} component={withTracker(DashboardSelectionPage)} />
        <Route path={paths.events} component={verifyIfAllowed(eventsFeature, EventsPage)} />
        <Route path={paths.report} component={verifyIfAllowed(reportFeature, ReportPage)} />
        <Route exact path={paths.reports} component={withTracker(ReportSelectionPage)} />
        <Route path={paths.topview} component={this.verifyComponent('topviews', TopviewPage)} />
        <Route exact path={paths.topviews} component={withTracker(TopviewSelectionPage)} />

        {/* Configuration pages */}
        <Route exact path={paths.config} component={verifyIfAllowed(configurationFeature, ConfigPages.Default)} />
        <Route path={configPathFrom(paths.actions)} component={withTracker(ConfigPages.Actions)} />
        <Route path={configPathFrom(paths.admin)} component={withTracker(ConfigPages.Admin)} />
        <Route path={configPathFrom(paths.configRuleEngine)} component={withTracker(ConfigPages.RuleEdition)} />
        <Route path={configPathFrom(paths.dashboard)} component={this.verifyComponent('dashboards', ConfigPages.Dashboard)} />
        <Route path={configPathFrom(paths.dashboards)} component={withTracker(ConfigPages.Dashboards)} />
        <Route path={configPathFrom(paths.inputs)} component={this.verifyComponent('streams', ConfigPages.Inputs)} />
        <Route path={configPathFrom(paths.images)} component={withTracker(ConfigPages.Images)} />
        <Route path={configPathFrom(paths.logs)} component={withTracker(ConfigPages.Logs)} />
        <Route path={configPathFrom(paths.machine)} component={this.verifyComponent('machines', ConfigPages.Machine)} />
        <Route path={configPathFrom(paths.machines)} component={withTracker(ConfigPages.Machines)} />
        <Route path={configPathFrom(paths.operations)} component={withTracker(ConfigPages.Operations)} />
        <Route path={configPathFrom(paths.report)} component={ConfigPages.Report} />
        <Route path={configPathFrom(paths.reports)} component={withTracker(ConfigPages.Reports)} />
        <Route path={configPathFrom(paths.ruleEngine)} component={withTracker(ConfigPages.RuleEngine)} />
        <Route path={configPathFrom(paths.shifts)} component={withTracker(ConfigPages.Shifts)} />
        <Route path={configPathFrom(paths.stopwatches)} component={withTracker(ConfigPages.Stopwatches)} />
        <Route path={configPathFrom(paths.streams)} component={withTracker(ConfigPages.Streams)} />
        <Route path={configPathFrom(paths.topview)} component={this.verifyComponent('topviews', ConfigPages.Topview)} />
        <Route path={configPathFrom(paths.topviews)} component={withTracker(ConfigPages.Topviews)} />
        <Route path={configPathFrom(paths.user)} component={this.verifyComponent('users', ConfigPages.User)} />
        <Route path={configPathFrom(paths.users)} component={withTracker(ConfigPages.Users)} />
        <Route path={configPathFrom(paths.variables)} component={withTracker(ConfigPages.Variables)} />

        <Route path="*" component={Page404NotFound} />
      </Switch>
    );
  }
}

Routes.propTypes = {
  connexion: reducersTypes.session.connexion.isRequired,
  dashboards: reducersTypes.dashboards.dashboards.isRequired,
  isVerification: reducersTypes.session.isVerification.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  redirectHistoryClear: PropTypes.func.isRequired,
  streams: reducersTypes.streams.isRequired,
  topviews: reducersTypes.topviews.topviews.isRequired,
  users: reducersTypes.users.users.isRequired,
  machines: reducersTypes.machines.isRequired,
};

function mapStateToProps(state) {
  return {
    connexion: state.session.connexion,
    isVerification: state.session.isVerification,
    dashboards: state.dashboards.dashboards,
    streams: state.streams,
    topviews: state.topviews.topviews,
    users: state.users.users,
    machines: state.machines,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchDashboards: reduxOperations.dashboards.fetchDashboards,
    fetchFolders: reduxOperations.folders.fetchFolders,
    fetchReports: reduxOperations.reports.fetchReports,
    fetchStreams: reduxOperations.streams.fetchStreams,
    fetchTags: reduxOperations.tags.fetchTags,
    fetchTopviews: reduxOperations.topviews.fetchTopviews,
    fetchUsers: reduxOperations.users.fetchUsers,
    fetchMachines: reduxOperations.machines.fetchMachines,
    redirectHistoryClear: reduxOperations.redirectHistory.redirectHistoryClear,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
