import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { DefaultTable } from '~components/Pages';
import {
  FontAwesome, CancelButton, SubmitButton, TextInput,
} from '~UI';
import { reduxOperations } from '~services';
import { updateOperatorMachines } from '~services/operators/endpoints';
import { getFormData } from '~utils';
import { showError, showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';
import { SquaredAddButton } from '~components/UI';
import MultipleMachinesPopup from './MultipleMachinesPopup';

const operatorActions = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CLONE: 'clone',
  MULTIPLE_CLONE: 'multipleClone',
  MULTIPLE_DELETE: 'multipleDelete',
};

const MachineOperators = ({ machineId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const machines = useSelector(state => state.machines);
  const operators = useSelector(state => state.operators);
  const machine = machines.find(m => m.id === machineId);

  const [machineOperators, setMachineOperators] = useState(machine && operators?.filter(op => machine.operators
    .includes(op.id)).map(op => ({ ...op, isEnabled: false })));
  const [isEditing, setIsEditing] = useState(false);
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedOperatorId, setSelectedOperatorId] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [operation, setOperation] = useState(null);
  const [machinesSelected, setMachinesSelected] = useState([]);
  const [showMachineList, setShowMachineList] = useState(false);
  const [operatorName, setOperatorName] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [buttonsActive, setButtonsActive] = useState(false);

  useEffect(() => {
    if (machineOperators.find(op => op.isEnabled)) {
      setButtonsActive(true);
    } else {
      setButtonsActive(false);
    }
  }, [machineOperators]);

  const cancelPopup = () => {
    setShowPopup(false);
    setSelectedOperatorId(null);
    setOperatorName(null);
    setMachinesSelected([]);
    setShowMachineList(false);
    setActionType(null);
  };

  const handleAddButton = () => {
    setIsEditing(true);
    setOperatorName('');
    setSelectedOperatorId(null);
  };

  const handleEdit = operatorId => {
    const operator = machine && machineOperators.find(elem => elem.id === operatorId);

    setIsEditing(true);
    setSelectedOperatorId(operatorId);
    setOperatorName((operator && operator.name) || '');
    setShowPopup(false);
  };

  const handleDelete = (e, selectedOperatorIdArg, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    [...selected, machineId]
      .forEach(mId => {
        const mach = machines.find(m => m.id === mId);
        const newOperators = mach && mach.operators.filter(opId => opId !== selectedOperatorIdArg);
        reduxOperations.machines.patchMachine(mId, { operators: newOperators })(dispatch);
      });

    showSuccess(t('operationSucceeded'));
    cancelPopup();
  };

  const goToHomeOperatorTab = () => {
    setSelectedOperatorId(null);
    setIsEditing(false);
  };

  const handleSubmit = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    const formData = getFormData('operatorForm');
    const machinesIds = [machineId, ...selected];
    if (selectedOperatorId) {
      reduxOperations.operators.updateOperator(selectedOperatorId, {
        name: formData.name,
      })(dispatch)
        .then(res => {
          const { operator } = res.payload;
          updateOperatorMachines(operator.id, machinesIds)
            .then(() => {
              showSuccess(t('operationSucceeded'));
              dispatch(reduxOperations.machines.forceFetchMachines());
            });
        })
        .catch(error => {
          showError(error.status);
        });
    } else {
      const existingOperator = operators.find(op => op.name === formData.name);
      if (existingOperator) {
        updateOperatorMachines(existingOperator.id, machinesIds)
          .then(() => {
            showSuccess(t('operationSucceeded'));
            dispatch(reduxOperations.machines.forceFetchMachines());
          })
          .catch(error => {
            showError(error.status);
          });
      } else {
        reduxOperations.operators.addOperator({
          name: formData.name,
        })(dispatch)
          .then(res => {
            const { operator } = res.payload;
            updateOperatorMachines(operator.id, machinesIds)
              .then(() => {
                showSuccess(t('operationSucceeded'));
                dispatch(reduxOperations.machines.forceFetchMachines());
              });
          })
          .catch(error => {
            showError(error.status);
          });
      }
    }

    setIsEditing(false);
    setSelectedOperatorId(null);
    setMachinesSelected([]);
    setShowMachineList(false);
    setShowPopup(false);
    setOperatorName(null);
  };

  const handleOperatorDuplication = (e, operatorId, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    if (!selected.length) {
      showError(t('allMachinesHaveOperator'));
      cancelPopup();
      return;
    }

    updateOperatorMachines(operatorId, selected)
      .then(() => {
        showSuccess(t('operationSucceeded'));
        dispatch(reduxOperations.machines.forceFetchMachines());
      })
      .catch(error => {
        showError(error.status);
      });
    cancelPopup();
  };

  const selectPreset = () => {
    const mach = machines.find(m => m.id === selectedPreset);

    if (mach && mach.operators) {
      const newOperators = [...mach.operators];
      dispatch(reduxOperations.machines.patchMachine(machineId, { operators: newOperators }))
        .then(() => {
          showSuccess(t('operationSucceeded'));
        })
        .catch(error => showError(error.status));
    } else {
      showError(t('machineNotFound'));
    }
  };

  const presetSelection = () => (
    <>
      <span className="presetSelectionText">
        {`${t('createFromTemplate')}: `}
      </span>
      <Select
        style={{
          width: 150,
          display: 'inline-block',
        }}
        value={selectedPreset || '-'}
        onChange={setSelectedPreset}
        options={[
          { label: '-', value: '-' },
          ...sortArray('alphabetically', machines.filter(m => m.id !== machineId), 'name')
            .map(m => ({ label: m.name, value: m.id })),
        ]}
      />
      &nbsp;
      <SubmitButton
        onClick={() => selectPreset()}
        label={t('import')}
        askConfirmation
      />
    </>
  );

  const openMultipleMachinePopup = (e, operationArg) => {
    if (e) {
      e.preventDefault();
    }
    setOperation(() => operationArg);
    setShowPopup(true);
  };

  const selectMachine = machineIdArg => {
    if (Array.isArray(machineIdArg) && machineIdArg.length === 0) {
      setMachinesSelected([]);
      return;
    }
    const ids = Array.isArray(machineIdArg) ? machineIdArg : [machineIdArg];
    ids.forEach(id => {
      const index = machinesSelected.indexOf(id);
      if (index === -1) {
        setMachinesSelected(prev => [...prev, id]);
      } else {
        setMachinesSelected(prev => prev.filter(mId => id !== mId));
      }
    });
  };

  const renderMultipleMachinesPopup = () => {
    const validMachines = machines.filter(m => {
      if (m.id === machineId) {
        return false;
      }
      const operatorByName = operators.find(op => op.name === operatorName);
      const machineHasOperator = m.operators.includes(operatorByName?.id) || m.operators.includes(selectedOperatorId);
      const machineHasOneOperatorMin = m.operators
        .some(opId => machineOperators.find(op => op.id === opId)?.isEnabled);
      const machineDoesntHaveOneOperatorMin = !m.operators
        .find(opId => machineOperators.find(op => op.id === opId)?.isEnabled)
        && machineOperators.find(op => op.isEnabled);

      switch (actionType) {
        case operatorActions.ADD:
        case operatorActions.CLONE:
          return !machineHasOperator;
        case operatorActions.EDIT:
          return false;
        case operatorActions.DELETE:
          return machineHasOperator;
        case operatorActions.MULTIPLE_CLONE:
          return machineDoesntHaveOneOperatorMin;
        case operatorActions.MULTIPLE_DELETE:
          return machineHasOneOperatorMin;
        default:
          return false;
      }
    });
    if (!validMachines.length && operation) {
      operation(null, []);
      return;
    }
    return (
      <MultipleMachinesPopup
        showPopup={showPopup && validMachines.length}
        showMachineList={showMachineList}
        operation={operation}
        validMachines={validMachines}
        machinesSelected={machinesSelected}
        cancelPopup={cancelPopup}
        setShowMachineList={value => setShowMachineList(value)}
        selectMachine={id => selectMachine(id)}
      />
    );
  };

  const getOperatorForm = () => {
    const isExistingOperator = machineOperators.find(elem => elem.name === operatorName);

    const existingOperator = isExistingOperator ? (
      <div className="inputTitle">
        {t('operatorName')}
        {!selectedOperatorId && ` - ${t('existingOperator')}`}
      </div>
    ) : (
      <div className="inputTitle">
        {t('operatorName')}
        {!selectedOperatorId && ` - ${t('newOperator')}`}
      </div>
    );

    return (
      <form
        id="operatorForm"
        onSubmit={e => {
          if (selectedOperatorId) {
            setActionType(operatorActions.EDIT);
          } else {
            setActionType(operatorActions.ADD);
          }
          openMultipleMachinePopup(e, handleSubmit);
        }}
      >
        <div className="inputTitle">{existingOperator}</div>
        {
          selectedOperatorId ? (
            <input
              name="name"
              type="text"
              className="fullwidth"
              value={operatorName}
              onChange={e => setOperatorName(e.currentTarget.value)}
            />
          ) : (
            <TextInput
              name="name"
              options={sortArray('alphabetically', operators.filter(op => !machineOperators.find(oper => oper.id === op.id)), 'name').map(op => op.name)}
              className="fullwidth"
              regex="^[a-zA-Z0-9_\-\ ]+$"
              value={operatorName}
              onChange={name => setOperatorName(name)}
              onSelect={name => setOperatorName(name)}
            />
          )
        }

        <div style={{ textAlign: 'right', marginTop: '10px' }}>
          <CancelButton onClick={goToHomeOperatorTab} />
          <SubmitButton label={selectedOperatorId ? t('modify') : t('add')} />
        </div>
      </form>
    );
  };

  const onSelectionModeChange = () => {
    if (selectionMode) {
      setMachineOperators(machineOperators.map(op => ({ ...op, isEnabled: false })));
    }
    setSelectionMode(!selectionMode);
  };

  const handleMultipleOperatorDuplication = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    if (!selected.length) {
      showError(t('allMachinesHaveOperators'));
      cancelPopup();
      return;
    }
    const operatorsToDuplicate = machineOperators.filter(op => op.isEnabled);
    operatorsToDuplicate.forEach(op => updateOperatorMachines(op.id, selected)
      .then(() => {
        showSuccess(t('operationSucceeded'));
        dispatch(reduxOperations.machines.forceFetchMachines());
      })
      .catch(error => {
        showError(error.status);
      }));
    cancelPopup();
  };

  const handleMultipleDelete = (e, selected = []) => {
    if (e) {
      e.preventDefault();
    }
    const operatorsToDelete = machineOperators.filter(op => op.isEnabled);
    if (!operatorsToDelete.length) {
      cancelPopup();
      return;
    }
    [...selected, machineId]
      .forEach(mId => {
        const mach = machines.find(m => m.id === mId);
        const newOperators = mach && mach.operators
          .filter(opId => !operatorsToDelete.find(op => op.id === opId));
        reduxOperations.machines.patchMachine(mId, { operators: newOperators })(dispatch);
      });
    showSuccess(t('operationSucceeded'));
    cancelPopup();
  };

  useEffect(() => {
    setMachineOperators(machine && operators?.filter(op => machine.operators.includes(op.id))
      .map(op => ({
        ...op,
        isEnabled: machineOperators.find(elem => elem.id === op.id)?.isEnabled,
      })));
  }, [machine, operators]);

  return (
    <>
      <div className="topButtonsBlock flexSpaceBetween">
        {showPopup && renderMultipleMachinesPopup()}
        {
          isEditing && (
            <FontAwesome
              icon="arrow-left"
              className="backButtonArrow"
              style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
              onClick={goToHomeOperatorTab}
            />
          )
        }
        {
          selectedOperatorId === null && !isEditing && (
            <>
              <div>{presetSelection()}</div>
              <div className="displayIconContainer">
                {selectionMode && (
                  <>
                    <div
                      role="button"
                      className={`displayIcon ${!buttonsActive && 'disabled'}`}
                      onClick={event => {
                        if (!buttonsActive) {
                          return;
                        }
                        setShowMachineList(true);
                        setActionType(operatorActions.MULTIPLE_CLONE);
                        openMultipleMachinePopup(event, (e, selected) => {
                          handleMultipleOperatorDuplication(e, selected);
                        });
                      }}
                    >
                      <FontAwesome icon="clone" style={buttonsActive ? { color: 'white' } : { color: 'grey' }} />
                    </div>
                    <div
                      role="button"
                      className={`displayIcon ${!buttonsActive && 'disabled'}`}
                      onClick={
                        event => {
                          if (!buttonsActive) {
                            return;
                          }
                          setActionType(operatorActions.MULTIPLE_DELETE);
                          openMultipleMachinePopup(event, (e, selected) => handleMultipleDelete(e, selected));
                        }
                      }
                    >
                      <FontAwesome icon="trash" style={buttonsActive ? { color: 'white' } : { color: 'grey' }} />
                    </div>
                  </>
                )}
                <div
                  role="button"
                  className={`displayIcon ${selectionMode && 'isActive'}`}
                  onClick={() => onSelectionModeChange()}
                >
                  <FontAwesome icon="list-check" style={{ color: 'white' }} />
                </div>
                <SquaredAddButton onClick={handleAddButton} />
              </div>
            </>
          )
        }
      </div>

      {
        isEditing ? getOperatorForm() : (
          <DefaultTable
            columnNames={[
              { name: t('name') },
            ]}
            entriesProperties={selectionMode ? ['name', 'check'] : ['name']}
            entries={sortArray('alphabetically', machineOperators || [], 'name')}
            toggleOnChange={(id, newChecked) => {
              if (id === 'all') {
                setMachineOperators(machineOperators.map(op => ({ ...op, isEnabled: newChecked })));
              } else {
                setMachineOperators(machineOperators.map(op => (op.id === id ? { ...op, isEnabled: newChecked } : op)));
              }
            }}
            editFunction={handleEdit}
            duplicateFunction={
              input => {
                setSelectedOperatorId(input.id);
                setShowMachineList(true);
                setActionType(operatorActions.CLONE);
                openMultipleMachinePopup(null, (e, selected) => handleOperatorDuplication(e, input.id, selected));
              }
            }
            deleteFunction={id => {
              setSelectedOperatorId(id);
              setActionType(operatorActions.DELETE);
              openMultipleMachinePopup(null, (e, selected) => handleDelete(e, id, selected));
            }}
            noNumbering
          />
        )
      }
    </>
  );
};

MachineOperators.propTypes = {
  machineId: PropTypes.string,
};

MachineOperators.defaultProps = {
  machineId: '',
};

export default MachineOperators;
