import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { handleTagSelection, getTagListFromUsedTags } from '~utils/tags';
import { configurationFeature } from '~utils/featureToggles';
import { reduxOperations, reducersTypes } from '~services';
import { ActionPropTypes } from '~services/actions/types';
import TagsSelection from '~UI/Tags/TagsSelection';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { PageTemplate } from '~components/Pages';
import { ModalHandler, SquaredAddButton } from '~UI';
import VariablesList from './VariablesList';
import VariablePopupForm from './VariablePopUpForm';
import { Page401Unauthorized } from '../../ErrorPages';

import './VariablesPage.scss';

const updateLocalStorage = getLocalStorageSetter('K2_variables');

// TODO: Between 769px (just above vertical tablet) and 868px the display is not optimal
// Not fixed because it is not a range of standard display
const MAX_WIDTH_FULL_SEARCH_BAR = 712;

const VariablesPage = ({ actions, triggers, dashboards }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const streams = useSelector(state => state.streams);
  const tags = useSelector(state => state.tags.tags);
  const variables = useSelector(state => state.variables);
  const machines = useSelector(state => state.machines);

  const storage = getLocalStorageObject('K2_variables');

  const [filterText, setFilterText] = useState('');
  const [sortInput, setSortInput] = useState(storage.sortParameter || '');
  const [selectedTags, setSelectedTags] = useState(storage.tags || ['all']);
  const [isSearchBarCollapsed, setIsSearchBarCollapsed] = useState(false);

  const handleResize = () => setIsSearchBarCollapsed(window.innerWidth < MAX_WIDTH_FULL_SEARCH_BAR);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSortChange = newSortInput => {
    updateLocalStorage('sortParameter', newSortInput);
    setSortInput(newSortInput);
  };

  const selectTag = (selectedTag, withCtrl) => {
    setSelectedTags(prevSelectedTags => {
      let newTags = handleTagSelection(selectedTag, prevSelectedTags, withCtrl);
      const usedList = getTagListFromUsedTags(variables, newTags);
      newTags = newTags.filter(tag => usedList.find(usedTag => usedTag === tag) || tag === 'all');
      updateLocalStorage('tags', newTags);
      return newTags;
    });
  };

  const getPage = ([streamsArg, variablesArg]) => {
    let usedSelectedTags = getTagListFromUsedTags(variablesArg, selectedTags);
    usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
    const usedTags = getTagListFromUsedTags(variablesArg, tags);

    return (
      configurationFeature.isUserAllowedAccessVariables() ? (
        <PageTemplate
          sidebar
        >
          {usedTags.length > 0 && !(usedTags.length === 1 && tags.includes('all')) && (
            <TagsSelection
              tags={usedTags}
              selectedTags={usedSelectedTags}
              selectTag={selectTag}
              tagInfo={t('tagInfoVariable')}
            />
          )}
          <div className="VariablesPage">
            <div className="VariablesPageButtons">
              <div>
                {`${t('Search')}:`}
                <Input
                  clearable
                  onChange={value => setFilterText(value)}
                  onClear={() => setFilterText('')}
                  placeholder={t('variableName')}
                  style={{
                    display: 'inline-block',
                    margin: '0 5px',
                    width: 250,
                  }}
                  value={filterText}
                />
                {isSearchBarCollapsed && <br />}
                {`${t('sortBy')}:`}
                <Select
                  style={{ display: 'inline-block', width: '300px' }}
                  className="sortInput inputFilter"
                  value={sortInput}
                  onChange={e => onSortChange(e)}
                  options={[
                    { label: '-', value: '-' },
                    { label: t('name'), value: 'byName' },
                  ]}
                />
              </div>
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={{
                  Component: VariablePopupForm,
                  props: {},
                }}
              />
            </div>
            <VariablesList
              variables={variablesArg}
              streams={streamsArg}
              filter={filterText}
              sort={sortInput}
              usedSelectedTags={usedSelectedTags}
            />
          </div>
        </PageTemplate>
      ) : (
        <Page401Unauthorized />
      )
    );
  };

  return (
    <ResourcesHandler
      resources={[streams, variables, actions, triggers, tags, dashboards, machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
        () => dispatch(reduxOperations.actions.fetchActions()),
        () => dispatch(reduxOperations.triggers.fetchTriggers()),
        () => dispatch(reduxOperations.tags.fetchTags()),
        () => dispatch(reduxOperations.dashboards.fetchDashboards()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
      ]}
      getContents={getPage}
    />
  );
};

VariablesPage.propTypes = {
  actions: PropTypes.arrayOf(ActionPropTypes).isRequired,
  triggers: reducersTypes.triggers.isRequired,
  dashboards: reducersTypes.dashboards.dashboards.isRequired,
};

export default VariablesPage;
