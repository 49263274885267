let featureTogglesCache = {
  pages: {},
  buttons: {},
};
let userPermissionTogglesCache = { features: [] };

const getFeatureToggles = () => featureTogglesCache;

const setFeatureToggles = featureToggles => {
  featureTogglesCache = featureToggles;
};

const getUserPermissionToggles = () => userPermissionTogglesCache;

const setUserPermissionToggles = userPermissionToggles => {
  userPermissionTogglesCache = userPermissionToggles;
};

export {
  getFeatureToggles,
  setFeatureToggles,
  getUserPermissionToggles,
  setUserPermissionToggles,
};
