import EmptyTile from './EmptyTile/EmptyTile';
// alphabetical order
import AreaChartTile from './AreaChartTile/AreaChartTile';
import { AudioTile } from './AudioTile/AudioTile';
import BarChartTile from './BarChartTile/BarChartTile';
import { ButtonTile } from './ButtonTile/ButtonTile';
import CircularChartTile from './CircularChartTile/CircularChartTile';
import DataEntryTile from './DataEntryTile/DataEntryTile';
import GaugeTile from './GaugeTile/GaugeTile';
import InfoTile from './InfoTile/InfoTile';
import MachineStateTile from './MachineStateTile/MachineStateTile';
import MediaTile from './MediaTile/MediaTile';
import ParetoChartTile from './ParetoChartTile/ParetoChartTile';
import ProgressBarTileClientB from './ProgressBarTile/ProgressBarTileClientB';
import PromptTile from './PromptTile/PromptTile';
import ScorecardTile from './ScorecardTile/ScorecardTile';
import StopwatchTile from './Stopwatch/StopwatchTile';
import TextTile from './TextTile/TextTile';
import TimelineTile from './TimelineTile/TimelineTile';

export default {
  empty: EmptyTile,
  // alphabetical order
  areaChart: AreaChartTile,
  audio: AudioTile,
  barChart: BarChartTile,
  button: ButtonTile,
  circularChart: CircularChartTile,
  dataEntry: DataEntryTile,
  gauge: GaugeTile,
  info: InfoTile,
  machineState: MachineStateTile,
  media: MediaTile,
  paretoChart: ParetoChartTile,
  progressBar: ProgressBarTileClientB,
  prompt: PromptTile,
  scorecard: ScorecardTile,
  stopwatch: StopwatchTile,
  text: TextTile,
  timeline: TimelineTile,
};
