import React, { useState } from 'react';
import PropTypes, { object } from 'prop-types';
import { Nav, NavItem } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@intelligenceindustrielle/react-ui-components';
import { DefaultModal } from '~UI/index';
import { eventsToReadable } from '~utils/events';
import { EventsTable } from '~components/Pages';

const TimelineEventsPopup = ({
  selectedMachineId, events, show, onHide, onModifyEvent,
}) => {
  const { t } = useTranslation();

  const [commentsFilter, setCommentsFilter] = useState(false);
  const [navSelected, setNavSelected] = useState('MachineStatus');
  const settings = useSelector(state => state.settings.settings);

  const handleSelectNav = eventKey => {
    setNavSelected(eventKey);
  };

  const countComments = eventType => events.filter(
    event => event.type === eventType && event.comments && selectedMachineId === event.machineId,
  ).length;

  const filterEvents = () => {
    events = eventsToReadable(events);
    if (navSelected === 'MachineStatus') {
      return events.filter(event => ((commentsFilter
        ? event.comments && event.name && selectedMachineId === event.machineId
        : event.name && selectedMachineId === event.machineId) && event.type === navSelected && event.status === 'OFF'))
        .sort((eventA, eventB) => eventB.timestamp - eventA.timestamp);
    }
    return events.filter(event => ((commentsFilter
      ? event.comments && event.name && selectedMachineId === event.machineId
      : event.name && selectedMachineId === event.machineId) && event.type === navSelected && event.status !== 'END'))
      .sort((eventA, eventB) => eventB.timestamp - eventA.timestamp);
  };

  const filterColumns = () => {
    let columns = [];
    let colNames = [];

    const keyOrder = ['operation', 'skuNumber', 'workOrder'];

    const additionalColumns = keyOrder.filter(key => settings.eventPageFilters[key]);
    const additionalColNames = additionalColumns.map(column => ({ name: t(column) }));

    if (navSelected === 'MachineStatus' || navSelected === 'PerformanceEvent') {
      columns = [
        'startDate',
        'duration',
        'operator',
        ...additionalColumns,
        'motif',
        'comments',
        'resolutions',
      ];
      if (navSelected === 'MachineStatus') {
        colNames = [
          { name: t('start') },
          { name: t('duration') },
          { name: t('operator') },
          ...additionalColNames,
          { name: t('stopCause') },
          { name: t('comment') },
          { name: t('resolution') },
        ];
      } else {
        colNames = [
          { name: t('start') },
          { name: t('duration') },
          { name: t('operator') },
          ...additionalColNames,
          { name: t('performanceDropCause') },
          { name: t('comment') },
          { name: t('resolution') },
        ];
      }
    } else if (navSelected === 'PartEvent') {
      columns = [
        'startDate',
        'operator',
        ...additionalColumns,
        'motif',
        'comments',
        'resolutions',
      ];
      colNames = [
        { name: t('Date') },
        { name: t('operator') },
        ...additionalColNames,
        { name: t('defectCause') },
        { name: t('comment') },
        { name: t('resolution') },
      ];
    }
    return { columns, colNames };
  };

  const { columns, colNames } = filterColumns();

  const getContents = () => (
    <>
      <div className="leftHeader" style={{ display: 'flex', gap: '5px', marginBottom: '15px' }}>
        <Checkbox checked={commentsFilter} onChange={() => setCommentsFilter(!commentsFilter)} />
        <div className="inputTitle">{t('showCommentedEvents')}</div>
      </div>
      <EventsTable
        columnNames={colNames}
        entriesProperties={columns}
        entries={filterEvents()}
        isModifying
        onModifyEvent={onModifyEvent}
        isTimeline
        noPagination
      />
    </>
  );

  return (
    <DefaultModal
      closePopup={onHide}
      title={t('events')}
      show={show}
      fullSize
    >
      <Nav
        bsStyle="tabs"
        activeKey={navSelected}
        onSelect={eventKey => handleSelectNav(eventKey)}
      >
        <NavItem eventKey="MachineStatus">
          {t('machineStatus')}
          {countComments('MachineStatus') > 0 && (
            <span className="nav-badge">
              {countComments('MachineStatus')}
            </span>
          )}
        </NavItem>
        <NavItem eventKey="PerformanceEvent">
          {t('performance')}
          {countComments('PerformanceEvent') > 0 && (
            <span className="nav-badge">
              {countComments('PerformanceEvent')}
            </span>
          )}
        </NavItem>
        <NavItem eventKey="PartEvent">
          {t('defects')}
          {countComments('PartEvent') > 0 && (
            <span className="nav-badge">
              {countComments('PartEvent')}
            </span>
          )}
        </NavItem>
      </Nav>
      {getContents()}
    </DefaultModal>
  );
};

TimelineEventsPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  selectedMachineId: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(object).isRequired,
  onModifyEvent: PropTypes.func.isRequired,
};

export default TimelineEventsPopup;
