import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Icon, Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { RootState } from '~services/store';
import {
  FontAwesome, CheckboxToggle, TextInput,
} from '~UI/index';
import { StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { idToReadableExpr } from '~utils/parser';
import { sortArray } from '~utils/sort';

import './ActionForm.scss';

const PartActionForm = ({ action }) => {
  const { t } = useTranslation();

  const machines = useSelector((state: RootState) => state.machines);
  const variables = useSelector((state: RootState) => state.variables);
  const streams = useSelector((state: RootState) => state.streams);

  const { machineId: initMachineId } = action.params;

  const preselectDefectCause = machineIdArg => {
    const selectedMachine = machines.find(m => m.id === machineIdArg);
    let newDefectCauses = selectedMachine && selectedMachine.defectCauses;
    let newIsSubMenu = false;

    if (selectedMachine && newDefectCauses.length) {
      const newSelectedDefectCauseId = action.params.defectCauseId;
      newDefectCauses.forEach(topCause => {
        topCause.subMenu.forEach(subCause => {
          if (subCause.id === newSelectedDefectCauseId) {
            newIsSubMenu = true;
            newDefectCauses = topCause.subMenu;
          }
        });
      });
      return {
        selectedDefectCauseId: newSelectedDefectCauseId,
        defectCauses: newDefectCauses,
        isSubMenu: newIsSubMenu,
      };
    }
    return { selectedDefectCauseId: '', defectCauses: null, isSubMenu: newIsSubMenu };
  };
  const {
    selectedDefectCauseId: initSelectedDefectCauseId,
    defectCauses: initDefectCauses,
    isSubMenu: initIsSubMenu,
  } = preselectDefectCause(initMachineId);

  const [machineId, setMachineId] = useState(initMachineId);
  const [defectCauses, setDefectCauses] = useState(initDefectCauses);
  const [selectedDefectCauseId, setSelectedDefectCauseId] = useState(initSelectedDefectCauseId);
  const [isSubMenu, setIsSubMenu] = useState(initIsSubMenu);
  const [checkboxDefectCauseEnabled, setCheckboxDefectCauseEnabled] = useState(!!initSelectedDefectCauseId);
  const [eventType, setEventType] = useState(action.params.eventType || 'OUT');
  const [count, setCount] = useState(action.params.count);

  const idToReadable = value => {
    if (typeof value === 'string') {
      const inputProperties = [].concat(...streams.map(s => s.properties));
      const kpis = [].concat(...machines.map(m => m.kpis || []));
      const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
      return idToReadableExpr(value, inputsAndVariables) || value;
    }
    return value;
  };

  const handleDefectCauseSelection = defectCause => {
    const selectedMachine = machines.find(m => m.id === machineId);

    if (!defectCause) {
      setSelectedDefectCauseId('');
      setIsSubMenu(false);
      setDefectCauses(selectedMachine && selectedMachine.defectCauses);
    } else if (defectCause.subMenu && defectCause.subMenu.length) {
      setDefectCauses(defectCause.subMenu);
      setIsSubMenu(true);
    } else {
      setSelectedDefectCauseId(defectCause.id);
    }
  };

  const handleChangeCheckboxDefectCause = () => {
    const selectedMachine = machines.find(m => m.id === machineId);
    const newDefectCauses = selectedMachine && selectedMachine.defectCauses;

    setCheckboxDefectCauseEnabled(prevCheckboxDefectCauseEnabled => !prevCheckboxDefectCauseEnabled);
    setSelectedDefectCauseId(prevSelectedDefectCauseId => (!checkboxDefectCauseEnabled ? prevSelectedDefectCauseId : ''));
    setDefectCauses(prevDefectCauses => (!checkboxDefectCauseEnabled ? prevDefectCauses : newDefectCauses));
    setIsSubMenu(prevIsSubMenu => (!checkboxDefectCauseEnabled ? prevIsSubMenu : false));
  };

  const handleMachineIdChange = newMachineId => {
    const {
      selectedDefectCauseId: newSelectedDefectCauseId,
      defectCauses: newDefectCauses,
      isSubMenu: newIsSubMenu,
    } = preselectDefectCause(newMachineId);
    setMachineId(newMachineId);
    setIsSubMenu(newIsSubMenu);
    setSelectedDefectCauseId(newSelectedDefectCauseId);
    setDefectCauses(newDefectCauses);
  };

  const machineOptions = sortArray('alphabetically', machines, 'name').map(m => ({ label: m.name, value: m.id }));

  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
  inputsAndVariables.splice(0, 0, 'NOW');

  const buttons = [
    { value: 'IN', label: t('IN') },
    { value: 'OUT', label: t('OUT') },
    { value: 'SCRAP', label: t('SCRAP') },
  ];

  return (
    <>
      <div className="inputTitle">{t('machine')}</div>
      <Select
        name="machineId"
        options={machineOptions}
        value={machineId}
        onChange={handleMachineIdChange}
        placeholder={t('select')}
      />

      <div className="inputTitle">{t('eventName')}</div>
      <Input
        defaultValue={action.params.name}
        name="name"
      />

      <div className="inputTitle">
        {t('nbParts')}
        <Icon
          icon="info-circle"
          tooltipLabel={t('nbPartsOverridesSKU')}
        />
      </div>
      <TextInput
        name="count"
        options={inputsAndVariables}
        trigger="$"
        value={idToReadable(count)}
        className="fullwidth"
        onChange={e => setCount(e)}
        placeholder={t('triggerVariableList')}
      />

      <div className="inputTitle">{t('eventType')}</div>
      <ButtonGroup
        buttons={buttons}
        name="eventType"
        onChange={newEventType => setEventType(newEventType)}
        value={eventType}
      />

      {
        defectCauses && defectCauses.length && eventType === 'SCRAP' && (
          <>
            <div className="inputTitle">
              {t('defectCause')}
              &nbsp;
              <CheckboxToggle
                controlledCheck={checkboxDefectCauseEnabled}
                onChange={handleChangeCheckboxDefectCause}
              />
            </div>
            <div style={{ display: checkboxDefectCauseEnabled ? 'block' : 'none' }}>
              <input type="hidden" name="defectCauseId" value={selectedDefectCauseId} />
              {
                isSubMenu && (
                  <FontAwesome
                    icon="arrow-left"
                    className="backButtonArrow"
                    style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '8px', fontSize: '17px' }}
                    onClick={() => handleDefectCauseSelection(null)}
                  />
                )
              }
              {
                defectCauses.map(defectcause => (
                  <StopCauseIcon
                    key={defectcause.id}
                    option={defectcause}
                    small
                    className={(defectcause.id === selectedDefectCauseId) ? 'Selected' : 'Unselected'}
                    onClick={() => handleDefectCauseSelection(defectcause)}
                  />
                ))
              }
            </div>
          </>
        )
      }
    </>
  );
};

PartActionForm.propTypes = {
  action: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string,
      machineId: PropTypes.string,
      eventType: PropTypes.string,
      count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      defectCauseId: PropTypes.string,
    }).isRequired,
  }),
};
PartActionForm.defaultProps = {
  action: {
    params: {},
  },
};

export default PartActionForm;
