import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import Tick from '~utils/Tick';
import { getDataRangeStartEnd } from '~utils/time';
import ResponsiveScorecard from './ResponsiveScorecard';
import { getComparisonMetricObject } from './utils';
import { useShift } from '~utils/hooks';

const ScorecardEvent = ({
  selectedObject, backgroundColor, isCircle, dimension, loaderPos,
}) => {
  const socket = getSocket();
  const selectedObjectRef = useRef(selectedObject);

  useEffect(() => {
    selectedObjectRef.current = selectedObject;
  });

  const [currentShift] = useShift(null);

  const [events, setEvents] = useState([]);
  const [comparisonEvents, setComparisonEvents] = useState([]);
  const [comparisonMetricObject, setComparisonMetricObject] = useState(null);
  const [hasLoaded, setHasLoaded] = useState(false);

  const idleTimerRef = useRef(null);

  // Because of the socket props changing, the component renders very often
  // We compute our results for the render only when a significative value changes
  const updateTheRender = () => {
    const newComparisonMetricObject = selectedObject.comparisonMetric !== 'none'
      ? getComparisonMetricObject(events.length, comparisonEvents.length, null, selectedObject.comparisonMetric)
      : null;

    setComparisonMetricObject(newComparisonMetricObject);
  };

  const handleSocketEvent = socketEvent => {
    if (socketEvent.name === selectedObjectRef.current.eventName) {
      const event = events.find(elem => socketEvent.id === elem.id);
      setEvents(prevEvents => {
        if (!event) {
          return [...prevEvents, socketEvent];
        }
        return prevEvents;
      });
    }
  };

  const filterEvents = () => {
    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
    const timeDifference = end - start;
    let filteredEvents = [];
    if (selectedObject.comparisonMetric !== 'none') {
      filteredEvents = events.filter(event => !(event.timestamp >= start && event.timestamp <= end));
    }

    const comparisonIntervalType = selectedObject.comparisonIntervalType || 'shift';
    const newComparisonEvents = selectedObject.comparisonMetric !== 'none' ? [...comparisonEvents, ...filteredEvents] : [];
    const { start: comparisonStart, end: comparisonEnd } = getDataRangeStartEnd(comparisonIntervalType, currentShift);

    setEvents(prevEvents => prevEvents.filter(event => event.timestamp >= start && event.timestamp <= end));
    setComparisonEvents(newComparisonEvents.filter(event => event.timestamp >= comparisonStart - timeDifference
      && event.timestamp < comparisonEnd - timeDifference));
  };

  const fetchEvents = async isComparator => {
    const { eventName } = selectedObject;

    const intervalType = selectedObject.intervalType || 'shift';
    const { start, end } = getDataRangeStartEnd(intervalType, currentShift);
    const timeDifference = end - start;

    const comparisonIntervalType = selectedObject.comparisonIntervalType || 'shift';
    const {
      start: comparisonStart,
      end: comparisonEnd,
    } = getDataRangeStartEnd(comparisonIntervalType, currentShift);

    const filter = {
      name: eventName,
      timestamp: {
        $gte: isComparator ? comparisonStart - timeDifference : start,
        $lt: isComparator ? comparisonEnd - timeDifference : end,
      },
    };

    const { events: newEvents } = await API.getEvents(filter);

    if (isComparator) {
      if (newEvents) {
        setComparisonEvents(newEvents);
      } else {
        setComparisonEvents([]);
      }
    } else if (newEvents) {
      setEvents(newEvents);
    } else {
      setEvents([]);
    }
    filterEvents();
    setHasLoaded(true);
  };

  useEffect(() => {
    fetchEvents(false);
    if (selectedObject.comparisonMetric !== 'none') {
      fetchEvents(true);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('event', handleSocketEvent);
    }
    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
      }
    };
  }, [socket]);

  useEffect(() => {
    // Every 5 seconds, filter out the events that are not in the time interval anymore
    Tick.subscribe(filterEvents, 5); // 5 seconds

    fetchEvents(false);
    if (selectedObject.comparisonMetric !== 'none') {
      fetchEvents(true);
    }

    return () => {
      Tick.unsubscribe(filterEvents);
    };
  }, [socket, selectedObject, currentShift]);

  useEffect(() => {
    updateTheRender();
  }, [events, comparisonEvents]);

  const handleOnActive = () => {
    fetchEvents(false);
    if (selectedObject.comparisonMetric !== 'none') {
      fetchEvents(true);
    }
  };

  const handleOnIdle = () => {
    fetchEvents(false);
    if (selectedObject.comparisonMetric !== 'none') {
      fetchEvents(true);
    }
    idleTimerRef.current.reset();
  };

  return (
    <>
      <IdleTimer
        ref={idleTimerRef}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        timeout={1000 * 90}
        debounce={250}
      />
      <ResponsiveScorecard
        selectedObject={selectedObject}
        value={events.length.toString()}
        units={selectedObject.units}
        comparisonMetric={comparisonMetricObject}
        backgroundColor={backgroundColor}
        hasLoaded={hasLoaded}
        isCircle={isCircle}
        {...dimension}
        loaderPos={loaderPos}
      />
    </>
  );
};

ScorecardEvent.propTypes = {
  selectedObject: PropTypes.shape({
    intervalType: PropTypes.string,
    comparisonIntervalType: PropTypes.string,
    comparisonMetric: PropTypes.string,
    units: PropTypes.string,
    eventName: PropTypes.string,
  }).isRequired,
  backgroundColor: PropTypes.string,
  isCircle: PropTypes.bool,
  dimension: PropTypes.object,
  loaderPos: PropTypes.object,
};

export default ScorecardEvent;
