import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import DashboardPopUpForm from '../../../pages/Dashboards/DashboardPopUpForm';
import TopviewPopUpForm from '../../../pages/Topviews/TopviewSelectionPage/TopviewPopUpForm';
import { ReportPopupForm } from '../../../pages/Reports/ReportSelectionPage/ReportPopupForm';
import { LinkRedirectionPopup } from '~components/Popups/LinkRedirectionPopup';
import { DeleteConfirmationForm, SelectionForm } from '~components/Popups';
import FontAwesome from '~components/UI/FontAwesome';
import { RootState } from '~services/store';
import FolderPopupForm from '~components/Popups/FolderPopupForm/FolderPopupForm';
import MoveToFolderPopup from '~components/Popups/MoveToFolderPopup/MoveToFolderPopup';

const MAX_FAVORITES = 4;

const ItemNavLink = ({
  className,
  offset,
  id,
  type,
  disabled,
  handleFavoriteChange,
  handleDuplication,
  handleDelete,
  share,
  target,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const folders = useSelector((state: RootState) => state.folders);
  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const reports = useSelector((state: RootState) => state.reports.reports);
  const favorites = useSelector((state: RootState) => state.users.selectedUser.favorites);

  const [interfaceToEdit, setInterfaceToEdit] = useState('');
  const [interfaceToCreate, setInterfaceToCreate] = useState('');
  const [showFavorite, setShowFavorite] = useState(false);
  const [showMoveTo, setShowMoveTo] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const inFavorites = favorites && favorites[type]?.includes(id);

  const getFavoriteSelectionGridContent = () => {
    const favoritesDashboards = favorites?.dashboards && dashboards
      ? favorites.dashboards
        .map(favoriteDashboard => dashboards?.find(dash => dash.id === favoriteDashboard))
        .filter(favoriteDashboard => !!favoriteDashboard)
        .map(favoriteDashboard => ({
          id: favoriteDashboard.id,
          name: favoriteDashboard.name,
          img: favoriteDashboard.backgroundURL,
        }))
      : [];

    const favoritesReports = favorites?.reports && reports
      ? favorites.reports
        .map(favoriteReport => reports.find(rep => rep.id === favoriteReport))
        .filter(favoriteReport => !!favoriteReport)
        .map(favoriteReport => ({
          id: favoriteReport.id,
          name: favoriteReport.name,
        }))
      : [];

    const favoritesTopviews = favorites?.topviews && topviews
      ? favorites.topviews
        .map(favoriteTopview => topviews.find(tv => tv.id === favoriteTopview))
        .filter(favoriteTopview => !!favoriteTopview)
        .map(favoriteTopview => ({
          id: favoriteTopview.id,
          name: favoriteTopview.name,
          img: favoriteTopview.backgroundURL,
        }))
      : [];

    return [...favoritesDashboards, ...favoritesReports, ...favoritesTopviews];
  };

  const favoriteSelectModalContent = getFavoriteSelectionGridContent();

  return (
    <>
      <Menu offset={offset} width={300} position="bottom" transitionProps={{ transition: 'fade', duration: 200 }}>
        <Menu.Target>
          {target}
        </Menu.Target>
        <Menu.Dropdown className={className}>
          {type !== 'folders' && type !== 'subfolders' && (
            <Menu.Item
              className="favoriteOption"
              icon={<FontAwesome icon="star" />}
              onClick={() => (favoriteSelectModalContent.length >= MAX_FAVORITES && !inFavorites
                ? setShowFavorite(true)
                : handleFavoriteChange(type, id, null))}
            >
              {inFavorites ? t('removeFavorites') : t('addFavorites')}
            </Menu.Item>
          )}
          {type !== 'folders' && type !== 'subfolders' && (
            <Menu.Item
              icon={<FontAwesome icon="arrow-right" />}
              onClick={() => setShowMoveTo(true)}
              disabled={disabled || !folders?.length}
              style={disabled || !folders?.length ? { opacity: '0.6' } : {}}
            >
              {t('moveTo')}
            </Menu.Item>
          )}
          {(type === 'dashboards' || type === 'topviews') && (
            <Menu.Item
              icon={<FontAwesome icon="gear" />}
              onClick={() => history.push(`/config/${type.slice(0, -1)}/${id}`)}
              disabled={disabled}
              style={disabled ? { opacity: '0.6' } : {}}
            >
              {t('configure')}
            </Menu.Item>
          )}
          {type === 'folders' && (
            <Menu.Item
              icon={<FontAwesome icon="folder" />}
              onClick={() => setInterfaceToCreate(type)}
              disabled={disabled}
              style={disabled ? { opacity: '0.6' } : {}}
            >
              {t('newFolder')}
            </Menu.Item>
          )}
          <Menu.Item
            icon={<FontAwesome icon="edit" />}
            onClick={() => setInterfaceToEdit(type)}
            disabled={disabled}
            style={disabled ? { opacity: '0.6' } : {}}
          >
            {t('properties')}
          </Menu.Item>
          {handleDuplication && (
            <Menu.Item
              icon={<FontAwesome icon="clone" />}
              onClick={handleDuplication}
              disabled={disabled}
              style={disabled ? { opacity: '0.6' } : {}}
            >
              {t('duplicate')}
            </Menu.Item>
          )}
          {share && (
            <Menu.Item
              icon={<FontAwesome icon="share" />}
              onClick={() => setShowShare(true)}
            >
              {t('share')}
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item
            className="deleteOption"
            icon={<FontAwesome icon="trash" />}
            onClick={() => setShowDelete(true)}
            disabled={disabled}
            style={disabled ? { opacity: '0.6' } : {}}
          >
            {t('delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <SelectionForm
        handleAction={interfaceToDeleteId => handleFavoriteChange(type, id, interfaceToDeleteId)}
        actionLabel={t('replace')}
        title={t('selectAFavorite')}
        description={t('favoriteReplacementWarning')}
        content={favoriteSelectModalContent}
        show={showFavorite}
        onHide={() => setShowFavorite(false)}
      />
      {type !== 'folders' && (
        <MoveToFolderPopup
          modifiedItemId={id}
          type={type}
          show={showMoveTo}
          onHide={() => setShowMoveTo(false)}
        />
      )}
      {handleDelete && (
        <DeleteConfirmationForm
          handleDelete={handleDelete}
          show={showDelete}
          onHide={() => setShowDelete(false)}
        />
      )}
      {share && (
        <LinkRedirectionPopup
          contentType={type.charAt(0).toUpperCase() + type.slice(1, -1)}
          contentId={id}
          show={showShare}
          onHide={() => setShowShare(false)}
        />
      )}
      {type === 'dashboards' && <DashboardPopUpForm modifiedItemId={id} show={interfaceToEdit === 'dashboards'} onHide={() => setInterfaceToEdit('')} />}
      {type === 'topviews' && <TopviewPopUpForm modifiedItemId={id} show={interfaceToEdit === 'topviews'} onHide={() => setInterfaceToEdit('')} />}
      {type === 'reports' && <ReportPopupForm modifiedItemId={id} show={interfaceToEdit === 'reports'} onHide={() => setInterfaceToEdit('')} />}
      {type === 'folders' && <FolderPopupForm modifiedItemId={id} show={interfaceToEdit === 'folders'} onHide={() => setInterfaceToEdit('')} />}
      {type === 'subfolders' && <FolderPopupForm modifiedItemId={id} show={interfaceToEdit === 'subfolders'} onHide={() => setInterfaceToEdit('')} />}
      {type === 'folders' && <FolderPopupForm parentId={id} show={interfaceToCreate === 'folders'} onHide={() => setInterfaceToCreate('')} />}
    </>
  );
};

ItemNavLink.propTypes = {
  className: PropTypes.string.isRequired,
  offset: PropTypes.number,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleFavoriteChange: PropTypes.func,
  handleDuplication: PropTypes.func,
  handleDelete: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]).isRequired,
  share: PropTypes.bool,
  target: PropTypes.element.isRequired,
};

ItemNavLink.defaultProps = {
  offset: 0,
  handleFavoriteChange: null,
  handleDuplication: null,
  share: false,
};

export { ItemNavLink };
