import API from '~services/endpoints';

let delta = 0;
let isUpdating = false;
const deltas: number[] = [];

const setDeltaToMedian = () => {
  deltas.sort((a, b) => a - b);
  delta = Math.round(deltas[Math.floor(deltas.length / 2)]);
};

/**
 * Async method that will send a request to get server time `count` amount of times
 * and update delta to be more exact
 * @param {number} count
 */
const recUpdateDelta = count => {
  API.getServerTime().then(({ clientTime: sentTime, serverTime }) => {
    const currentTime = Date.now();
    const halfPing = (currentTime - sentTime) / 2;
    deltas.push(serverTime - currentTime + halfPing);
    setDeltaToMedian();
    if (count > 1) {
      setTimeout(() => recUpdateDelta(count - 1), 6000);
    } else {
      isUpdating = false;
    }
  });
};

/**
 * Async method that will end by updating `delta`
 */
const updateDelta = () => {
  if (!isUpdating) {
    isUpdating = true;
    deltas.length = 0;
    recUpdateDelta(5);
  }
};

/**
 * Get the server time in ms since Epoch
 */
export default () => {
  if (!delta) {
    updateDelta();
    return Date.now();
  }
  return Date.now() + delta;
};
