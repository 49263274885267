import {
  fetchMachines,
  forceFetchMachines,
  addMachine,
  updateMachine,
  patchMachine,
  deleteMachine,
} from './machine';
import {
  addKPI,
  updateKPI,
  deleteKPI,
} from './kpis';
import {
  addStopCause,
  updateStopCause,
  deleteStopCause,
  addSubStopCause,
  updateSubStopCause,
  deleteSubStopCause,
} from './stopCause';
import {
  addPerformanceCause,
  updatePerformanceCause,
  deletePerformanceCause,
  addSubPerformanceCause,
  updateSubPerformanceCause,
} from './performanceCause';
import {
  addDefectCause,
  updateDefectCause,
  deleteDefectCause,
  addSubDefectCause,
  updateSubDefectCause,
} from './defectCause';
import {
  addMachineShift,
  updateMachineShift,
  deleteMachineShift,
} from './machineShift';
import {
  setParams,
} from './paramsMachine';

export default {
  // Machine
  fetchMachines,
  forceFetchMachines,
  addMachine,
  updateMachine,
  patchMachine,
  deleteMachine,

  // KPIs
  addKPI,
  updateKPI,
  deleteKPI,

  // StopCauses
  addStopCause,
  updateStopCause,
  deleteStopCause,
  addSubStopCause,
  updateSubStopCause,
  deleteSubStopCause,

  // Performance Causes
  addPerformanceCause,
  updatePerformanceCause,
  deletePerformanceCause,
  addSubPerformanceCause,
  updateSubPerformanceCause,

  // Defect Causes
  addDefectCause,
  updateDefectCause,
  deleteDefectCause,
  addSubDefectCause,
  updateSubDefectCause,

  // Shifts
  addMachineShift,
  updateMachineShift,
  deleteMachineShift,

  // Params Machine
  setParams,
};
