const tryJSONParse = string => {
  try {
    const obj = JSON.parse(string);
    return typeof obj === 'object' ? obj : null;
  } catch (e) {
    return null;
  }
};

export default tryJSONParse;
