import React from 'react';

const dashboard = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 15 512 322" fill="currentcolor" height={height} width={width}>
    <g>
      <rect x="311.962" y="145.3" width="185.1" height="159.7" transform="matrix(1,0,0,1,0,0)" />
      <rect x="311.962" y="21.646" width="185.1" height="101.5" transform="matrix(1,0,0,1,0,0)" />
      <rect x="163.45" y="207.323" width="126.5" height="97.677" transform="matrix(1,0,0,1,0,0)" />
      <rect x="14.938" y="207.323" width="126.5" height="97.677" transform="matrix(1,0,0,1,0,0)" />
      <rect x="14.888" y="21.646" width="275.062" height="163.677" transform="matrix(1,0,0,1,0,0)" />
    </g>
  </svg>
);

export default dashboard;
