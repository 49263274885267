import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { reduxOperations } from '~services';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~UI';
import directLinks from '~utils/directLinks';
import MachineEditionForm from './MachineEditionForm';

const MachineEditionPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { selectedMachine } = useParams();
  const actions = useSelector(state => state.actions);
  const images = useSelector(state => state.images);
  const machines = useSelector(state => state.machines);
  const operations = useSelector(state => state.operations.operations);
  const operators = useSelector(state => state.operators);
  const streams = useSelector(state => state.streams);
  const variables = useSelector(state => state.variables);
  const [machine, setMachine] = useState({});

  useEffect(() => {
    if (machines) {
      setMachine(machines.find(u => u.id === selectedMachine));
    }
  }, [machines]);

  const content = () => (
    <PageTemplate
      sidebar
      title={machine.name}
    >
      <MachineEditionForm
        modifiedItemId={selectedMachine}
        onHide={() => history.push(directLinks.machines(location.pathname))}
      />
    </PageTemplate>
  );

  return (
    <ResourcesHandler
      resources={[actions, images, machines, operations, operators, streams, variables]}
      resourceFetchers={[
        () => dispatch(reduxOperations.actions.fetchActions()),
        () => dispatch(reduxOperations.images.fetchImages()),
        () => dispatch(reduxOperations.machines.fetchMachines()),
        () => dispatch(reduxOperations.operations.fetchOperations()),
        () => dispatch(reduxOperations.operators.fetchOperators()),
        () => dispatch(reduxOperations.streams.fetchStreams()),
        () => dispatch(reduxOperations.variables.fetchVariables()),
      ]}
      getContents={content}
    />
  );
};

export { MachineEditionPage };
