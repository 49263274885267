import React, { Component, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { DateTime } from 'luxon';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { reduxOperations, reducersTypes } from '~services';
import directLinks from '~utils/directLinks';
import { filterItems, sortItems } from '~components/SelectionGrid/utils';
import { manageUsersFeature } from '~utils/featureToggles';
import { PageTemplate, DefaultTable } from '~components/Pages';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ResourcesHandler,
  Cards, BasicCard, CreateCard, ModalHandler,
  SquaredAddButton, FontAwesome,
} from '~UI';
import imagesLibrary from '~images/imagesLibrary';
import { Page401Unauthorized } from '../../ErrorPages';
import { UserModificationPopup } from './UserModificationPopup';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { getTagListFromUsedTags } from '~utils/tags';
import './UsersPage.scss';

const displayOptions = {
  CARD: 'card',
  LIST: 'list',
};

const UserSelectionGrid = ({
  history, location, users, deleteUser, storageType,
}) => {
  const { t } = useTranslation();

  const [filterText, setFilterText] = useState('');
  const usersToDisplay = users ? users.filter(user => user.display) : [];
  const updateLocalStorage = getLocalStorageSetter(storageType);
  const storage = getLocalStorageObject(storageType);
  const [display, setDisplay] = useState(storage.display || displayOptions.CARD);
  const [sortInput, setSortInput] = useState(storage.sortParameter || '-');
  const [selectedTags] = useState(storage.tags || ['all']);
  const sortedItems = sortItems(usersToDisplay, sortInput);
  let usedSelectedTags = getTagListFromUsedTags(sortedItems, selectedTags);
  usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
  const filteredItems = filterItems(filterText, usedSelectedTags, sortedItems);

  function onSortChange(newSortInput) {
    updateLocalStorage('sortParameter', newSortInput);
    setSortInput(newSortInput);
  }

  function handleDisplay(newValue) {
    updateLocalStorage('display', newValue);
    setDisplay(newValue);
  }

  function getUserLogo(user) {
    const connectionType = user.connectionInfos?.connectionType;
    const { accountType } = user;
    if (connectionType === 'microsoft' || (!connectionType && accountType === 'microsoft')) {
      return <FontAwesome icon="microsoft" width="60px" height="60px" />;
    }
    return <img src={imagesLibrary.LogoII} alt="Intelligence Industrielle" width="60px" />;
  }

  return (
    <div>
      <div className="SearchBar">
        <div>
          {`${t('Search')}:`}
          <Input
            clearable
            onChange={value => setFilterText(value)}
            onClear={() => setFilterText('')}
            placeholder={t('searchByName')}
            style={{
              display: 'inline-block',
              margin: '0 5px',
              width: 250,
            }}
            value={filterText}
          />
          {`${t('sortBy')}:`}
          <Select
            style={{ display: 'inline-block', width: '300px' }}
            className="sortInput inputFilter"
            value={sortInput}
            onChange={e => onSortChange(e)}
            options={[
              { label: '-', value: '-' },
              { label: t('name'), value: 'byName' },
            ]}
          />
        </div>
        <div className="displayIconContainer">
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.CARD)}
          >
            <FontAwesome icon="th" />
          </div>
          <div
            role="button"
            className="displayIcon"
            onClick={() => handleDisplay(displayOptions.LIST)}
          >
            <FontAwesome icon="list-ul" />
          </div>
          {
            display === displayOptions.LIST && (
              <ModalHandler
                Trigger={{
                  Component: SquaredAddButton,
                  props: {},
                }}
                Modal={{
                  Component: UserModificationPopup,
                  props: { isCreating: true },
                }}
              />
            )
          }
        </div>
      </div>
      <div>
        {
          display === displayOptions.CARD ? (
            <Cards>
              <CreateCard
                title={t('addUser')}
                modal={{
                  Component: UserModificationPopup,
                  props: { isCreating: true },
                }}

              />
              {
                filteredItems.map(user => {
                  const lastConnectionAt = user.connectionInfos?.connections?.length > 0
                    ? user.connectionInfos.connections.reduce(
                      (prev, current) => ((prev.lastConnectionAt > current.lastConnectionAt) ? prev : current),
                    ).lastConnectionAt
                    : null;
                  return (
                    <BasicCard
                      key={user.id}
                      title={user.name}
                      text={lastConnectionAt ? DateTime.fromISO(lastConnectionAt).toRelative() : '-'}
                      icon={getUserLogo(user)}
                      deleteModal={manageUsersFeature.isUserAllowedToConfigure() && {
                        Component: DeleteConfirmationForm,
                        props: { handleDelete: () => deleteUser(user.id) },
                      }}
                      onClick={() => history.push(directLinks.user(user.id, location.pathname))}
                    />
                  );
                })}
            </Cards>
          )
            : (
              <div>
                <DefaultTable
                  columnNames={[
                    { name: t('name') },
                  ]}
                  entriesProperties={['name']}
                  entries={filteredItems}
                  deleteFunction={deleteUser}
                  onClickEvent={id => history.push(directLinks.user(id, location.pathname))}
                />
              </div>
            )}
      </div>
    </div>
  );
};

class UsersPage extends Component {
  componentDidMount() {
    const { fetchAllUsersPermissions } = this.props;
    fetchAllUsersPermissions();
  }

  getPage = () => {
    const {
      history, location, users, language, deleteUser, storageType,
    } = this.props;

    return (
      manageUsersFeature.isUserAllowedToConfigure()
        ? (
          <PageTemplate
            sidebar
          >
            <UserSelectionGrid
              language={language}
              users={users}
              storageType={storageType}
              fetchAllUsersPermissions={this.props}
              deleteUser={deleteUser}
              history={history}
              location={location}
            />
          </PageTemplate>
        ) : (
          <Page401Unauthorized />
        )
    );
  };

  render() {
    const {
      dashboards, fetchDashboards,
      reports, fetchReports,
      topviews, fetchTopviews,
    } = this.props;
    return (
      <ResourcesHandler
        resources={[dashboards, reports, topviews]}
        resourceFetchers={[fetchDashboards, fetchReports, fetchTopviews]}
        getContents={this.getPage}
      />
    );
  }
}

UsersPage.propTypes = {
  language: reducersTypes.views.language.isRequired,
  users: reducersTypes.users.users.isRequired,
  fetchAllUsersPermissions: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  storageType: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  dashboards: reducersTypes.dashboards.dashboards.isRequired,
  fetchDashboards: PropTypes.func.isRequired,
  topviews: reducersTypes.topviews.topviews.isRequired,
  fetchTopviews: PropTypes.func.isRequired,
  reports: reducersTypes.reports.reports.isRequired,
  fetchReports: PropTypes.func.isRequired,
};

UserSelectionGrid.propTypes = {
  users: reducersTypes.users.users.isRequired,
  deleteUser: PropTypes.func.isRequired,
  storageType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    dashboards: state.dashboards.dashboards,
    language: state.views.language,
    reports: state.reports.reports,
    topviews: state.topviews.topviews,
    users: state.users.users,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchDashboards: reduxOperations.dashboards.fetchDashboards,
    fetchReports: reduxOperations.reports.fetchReports,
    fetchTopviews: reduxOperations.topviews.fetchTopviews,
    fetchAllUsersPermissions: reduxOperations.users.fetchAllUsersPermissions,
    deleteUser: reduxOperations.users.deleteUser,
  }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPage));
