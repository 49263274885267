import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services';
import { getFormData } from '~utils';
import { showError } from '~utils/toast';

import MachinePopup from './MachinePopup';
import TransitEventMenu from './TransitEventMenu';
import popupOptions from './TransitPopupOptions.json';

const capitalize = s => s.charAt(0).toUpperCase() + s.slice(1);

const propTypes = {
  // should be given by ModalHandler
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // global states
  onSubmitTrigger: PropTypes.string.isRequired,
  params: PropTypes.arrayOf(PropTypes.shape({})),
  populateFormFields: PropTypes.shape({}),
};

const TransitEventPopup = ({
  show, onHide, onSubmitTrigger, params, populateFormFields,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const options = popupOptions;
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    setSelectedItemId(prevShow => {
      if (prevShow !== show) {
        setSelectedItemId(null);
      }
    });
  }, [show]);

  const handleSelection = menuId => {
    const menuItem = options.find(sm => sm.id === menuId);
    setSelectedItemId(menuItem.id);
  };

  const handleSubmit = async () => {
    const formData = getFormData('Stop_Selection_Form');
    if (onSubmitTrigger) {
      const triggerParams = {};
      Object.entries(params).forEach(([key, value]) => {
        triggerParams[key] = value.value;
      });
      dispatch(reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
        ...triggerParams,
        ...formData,
        motif: selectedItemId,
      }));
    } else {
      showError(t('popupMustHaveRule'));
    }
    onHide();
  };

  return (
    <MachinePopup
      title={t('helpNeeded')}
      subtitle={null}
      show={show}
      onHide={onHide}
    >
      <TransitEventMenu
        id={selectedItemId}
        options={options}
        onSelect={handleSelection}
        onSubmit={handleSubmit}
        onHide={onHide}
      >
        <div style={{ textAlign: 'left' }}>
          {
            Object.keys(populateFormFields || {}).map(formField => (
              <div>
                <input type="hidden" name={formField} value={populateFormFields[formField]} />
                <b>{capitalize(formField)}</b>
                :
                {populateFormFields[formField]}
              </div>
            ))
          }
          {
            /*
              <ControlLabel>No. Shop Order</ControlLabel>
              <FormControl
                componentClass="input"
                name="workOrder"
                defaultValue={params.workOrder ? params.workOrder.value : null}
                disabled={params.workOrder ? params.workOrder.readOnly : false}
                placeholder={t('workOrder')}
              />
              <br />
              <ControlLabel>Nom de l&apos;opérateur</ControlLabel>
              <FormControl
                componentClass="input"
                name="operator"
                defaultValue={params.operator ? params.operator.value : null}
                disabled={params.operator ? params.operator.readOnly : false}
                placeholder={t('operator')}
              />
              */
          }
        </div>
      </TransitEventMenu>
    </MachinePopup>
  );
};

TransitEventPopup.propTypes = propTypes;

export default TransitEventPopup;
