import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@mantine/core';
import { stopPropagation } from '~utils';
import { reducersTypes } from '~services';
import { sortArray } from '~utils/sort';
import TagInputButton from './TagInputButton';
import './TagsSelection.scss';

const propTypes = {
  tags: reducersTypes.tags.tags.isRequired,
  currentTags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modifyTags: PropTypes.func.isRequired,
};

const TagsInput = ({ tags, currentTags, modifyTags }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [hasTagDropdownOpen, setHasTagDropdownOpen] = useState(false);
  const [filteredTagsOptions, setFilteredTagsOptions] = useState([]);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [tagsButtonContainerWidth, setTagsButtonContainerWidth] = useState(0);
  const tagsButtonContainer = useRef(null);
  const tagsInput = useRef(null);

  useLayoutEffect(() => {
    if (tagsButtonContainer.current && tagsButtonContainerWidth !== tagsButtonContainer.current.offsetWidth) {
      setTagsButtonContainerWidth(tagsButtonContainer.current.offsetWidth);
    }
  });

  const handleTagSelection = newTagName => {
    const isTagUsed = currentTags.find(tag => tag.name === newTagName);
    const isTagExist = tags.find(tag => tag.name === newTagName);
    if (!isTagUsed && newTagName.length) {
      if (isTagExist) {
        modifyTags([...currentTags, isTagExist]);
      } else {
        // This id will be overridden by the server.
        // It is temporary to handle CRUD before it is actually created
        const newTag = { name: newTagName, color: '#333', id: uuidv4() };
        modifyTags([...currentTags, newTag]);
      }
    }
    setInputValue('');
    setFilteredTagsOptions([]);
  };

  const handleKeyDown = (e, tagName = false) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      if (inputValue && !tagName) {
        e.preventDefault();
      }
      let newTagName = tagName.value || inputValue;

      if (selectedOptionIndex !== 0) {
        newTagName = filteredTagsOptions[selectedOptionIndex];
      }

      handleTagSelection(newTagName);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (selectedOptionIndex > 0) {
        setSelectedOptionIndex(selectedOptionIndex - 1);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (selectedOptionIndex < 4) {
        setSelectedOptionIndex(selectedOptionIndex + 1);
      }
    }
  };

  const removeTagFromList = (e, tagName) => {
    stopPropagation(e);
    modifyTags(currentTags.filter(tag => tag.name !== tagName));
  };

  const updateTag = tag => {
    const newTags = currentTags.map(currentTag => {
      if (currentTag.id === tag.id) {
        return { id: tag.id, name: tag.name, color: tag.color };
      }
      return currentTag;
    });
    modifyTags(newTags);
  };

  const tagsOptions = [...new Set(sortArray('alphabetically', tags, 'name')?.map(tag => tag.name.normalize('NFD')))];
  let inputWidth = 320;
  if (tagsInput.current) {
    inputWidth = Math.max(tagsInput.current.offsetWidth - tagsButtonContainerWidth - 25, 300);
  }

  const handleSearchChange = value => {
    setInputValue(value.replace(' ', '').normalize('NFD'));
    const currentTagNames = currentTags.map(tag => tag.name);
    let filteredTags = [];
    if (value !== '') {
      filteredTags = tagsOptions.filter(tag => tag.toLowerCase().includes(value.toLowerCase())
        && !currentTagNames.includes(tag));
      filteredTags.unshift(value);
    }

    setFilteredTagsOptions(filteredTags);
    setSelectedOptionIndex(0);
  };

  return (
    <>
      <div className="TagsInput" ref={tagsInput}>
        <span ref={tagsButtonContainer}>
          {
            currentTags.map((tag, i) => tag && (
              <TagInputButton
                key={i}
                tags={tags}
                tag={tag}
                deleteAction={removeTagFromList}
                updateTag={updateTag}
                openDropdown={isOpen => setHasTagDropdownOpen(isOpen)}
              />
            ))
          }
        </span>
        <div onKeyDown={handleKeyDown}>
          <Autocomplete
            className="inlineTagInput"
            hoverOnSearchChange
            data={filteredTagsOptions}
            value={inputValue}
            onChange={handleSearchChange}
            style={{ width: `${inputWidth}px` }}
            onItemSubmit={newTagName => handleKeyDown({ key: 'Enter' }, newTagName)}
            placeholder={window.innerWidth < 720 ? '' : t('enterConfirmTag')}
          />
        </div>
      </div>
      {hasTagDropdownOpen && <div style={{ height: '100px' }} />}
    </>
  );
};

TagsInput.propTypes = propTypes;

export default TagsInput;
