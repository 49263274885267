import { useTranslation } from 'react-i18next';

export const getStopsTypes = () => {
  const { t } = useTranslation();
  return (
    [
      {
        label: t('planned'),
        value: 'planned',
      },
      {
        label: t('failure'),
        value: 'failure',
      },
      {
        label: t('idle'),
        value: 'idle',
      },
      {
        label: t('changeover'),
        value: 'changeover',
      },
      {
        label: t('minorStop'),
        value: 'minorStop',
      },
    ]
  );
};
