import types from './types';

function pendingRequests(state = [], action) {
  switch (action.type) {
    case types.FETCH_REQUEST:
      return [...state, action.payload.type];

    case types.FETCH_RESPONSE: {
      const newState = [...state];
      const index = newState.indexOf(action.payload.type);
      if (index >= 0) {
        newState.splice(index, 1);
      }
      return newState;
    }

    default:
      return state;
  }
}

export default pendingRequests;
