import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations, reducersTypes } from '~services';
import {
  CancelButton, DeleteButton, SubmitButton, FontAwesome, TextInput,
} from '~UI';
import TagsInput from '~UI/Tags/TagsInput';
import { getFormData } from '~utils';
import { idToReadableExpr, validateVarExists, variableToId, validateVarNamesDollarSign } from '~utils/parser';
import { sortArray } from '~utils/sort';
import { idsToTags } from '~utils/tags';
import { showError, showSuccess } from '~utils/toast';
import { ActionType } from '~services/actions/types';
import ActionForms from './actionsForms';
import { actionOptions } from './actionOptions';
import '../RuleEditionPages.scss';

const ActionEditionForm = ({
  actionId, goBack, handleSave, trigger, handleDelete,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actions = useSelector(state => state.actions);
  const machines = useSelector(state => state.machines);
  const stopwatches = useSelector(state => state.stopwatches.stopwatches);
  const streams = useSelector(state => state.streams);
  const tags = useSelector(state => state.tags.tags);
  const variables = useSelector(state => state.variables);

  const initAction = actions.find(element => element.id === actionId);
  const inputProperties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const initInputsAndVariables = [...inputProperties, ...variables, ...kpis];

  const [selectedActionType, setSelectedActionType] = useState(initAction ? initAction.type : null);
  const [name, setName] = useState(initAction ? initAction.name : '');
  const [action] = useState(actionId ? initAction : undefined);
  const [actionTags, setActionTags] = useState(initAction ? idsToTags(initAction.tags || [], tags) : []);
  const [condition, setCondition] = useState(actionId && initAction.condition ? idToReadableExpr(initAction.condition.expression, initInputsAndVariables) : '');

  useEffect(() => {
    const act = actions.find(element => element.id === actionId);
    setActionTags(act ? idsToTags(act.tags || [], tags) : []);
  }, [tags]);

  const selectActionType = newActionType => {
    setSelectedActionType(newActionType);
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (trigger) {
      trigger.actionsToPerform
        .forEach((act, index) => {
          if (act.id === actionId) {
            const formData = getFormData('Action__form');
            handleSave(index, formData);
          }
        });
    }

    const all = [...inputProperties, ...variables, ...kpis];

    const formData = { ...getFormData('Action__form') };

    if (selectedActionType === ActionType.API_CALL) {
      if (formData.headers) {
        formData.headers = JSON.parse(formData.headers);
      }
      if (formData.query) {
        formData.query = JSON.parse(formData.query);
      }
    }

    const request = {
      params: Object.entries(formData).length === 0
        ? undefined
        : formData,
      name,
      type: selectedActionType,
      tags: actionTags || [],
    };

    if (request.params.value && selectedActionType !== ActionType.MACHINE_PARAM) {
      if (validateVarNamesDollarSign(request.params.value)) {
        return;
      }
      try {
        request.params.value = variableToId(request.params.value, all);
      } catch {
        showError(t('variableUsedIsNotDefined'));
      }
    }
    if (request.params.count) {
      if (validateVarNamesDollarSign(request.params.count)) {
        return;
      }
      try {
        request.params.count = variableToId(request.params.count, all);
      } catch {
        showError(t('variableUsedIsNotDefined'));
      }
    }
    if (!validateVarExists(request.params)) {
      showError(t('variableUsedIsNotDefined'));
      return;
    }
    if (!request.name || request.name.length === 0) {
      showError(t('missingName'));
      return;
    }
    if (!/^\w+$/.test(request.params.name)) {
      showError(t('nameAlphanumEvent'));
      return;
    }
    if (Object.entries(formData).length === 0) {
      showError(t('error'));
      return;
    }
    if (selectedActionType === 'EmailAction' && !request.params.emailAddresses) {
      showError(t('missingEmail'));
      return;
    }
    if (condition) {
      try {
        request.condition = {
          type: 'Expression',
          expression: variableToId(condition, all),
          id: uuidv4(),
        };
      } catch (error) {
        showError(t('variableUsedIsNotDefined'));
        return;
      }
    }

    if (actionId) {
      dispatch(reduxOperations.actions.updateAction(actionId, request))
        .then(res => {
          goBack(res.payload.action);
          showSuccess(t('operationSucceeded'));
          dispatch(reduxOperations.tags.forceFetchTags());
        })
        .catch(error => {
          showError(`${t('error')}: ${error.response.error}`);
        });
    } else {
      dispatch(reduxOperations.actions.createAction(request))
        .then(res => {
          goBack(res.payload.action);
          showSuccess(t('operationSucceeded'));
          dispatch(reduxOperations.tags.forceFetchTags());
        })
        .catch(error => {
          showError(`${t('error')}: ${error.response.error}`);
        });
    }
  };

  const handleNameChange = e => {
    setName(e.target.value);
  };

  const handleDeleteAction = async () => {
    if (action && action.id) {
      dispatch(reduxOperations.actions.deleteAction(action.id)).then(() => {
        dispatch(reduxOperations.triggers.forceFetchTriggers());
      });
      if (trigger) {
        trigger.actionsToPerform
          .forEach((act, index) => {
            if (act.id === action.id) {
              handleDelete(index, action.id);
            }
          });
      }
      goBack(null);
    }
  };

  const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable')
    .map(x => x.variable)
    .concat(stopwatches.map(x => x.name));
  inputsAndVariables.splice(0, 0, 'NOW');

  const ActionForm = ActionForms[selectedActionType];

  return (
    <div className="actionTypeComponent">
      {!selectedActionType ? (
        <SelectionGrid
          onChange={selectActionType}
          options={
            actionOptions.map(({ icon, name: actionName, type }) => ({
              bgColor: '#1E871E',
              Icon: <FontAwesome icon={icon} size="3x" height="51" />,
              label: t(actionName),
              value: type,
            }))
          }
        />
      )
        : (
          <div>
            <FontAwesome
              icon="arrow-left"
              className="backButtonArrow"
              onClick={() => selectActionType(null)}
              style={{ position: 'absolute' }}
            />
            <div className="inputTitle">{t('actionName')}</div>
            <input
              type="text"
              className="fullwidth"
              placeholder={t('actionName')}
              onChange={handleNameChange}
              defaultValue={action ? action.name : null}
            />
            <form id="Action__form" onSubmit={handleSubmit}>
              <ActionForm action={action} />

              <div className="inputTitle">{t('condition')}</div>
              <TextInput
                options={inputsAndVariables}
                trigger="$"
                className="fullwidth"
                value={condition}
                onChange={e => setCondition(e)}
                placeholder={t('triggerVariableList')}
              />
              <div style={{ marginBottom: '8px' }}>
                <FontAwesome icon="info-circle" />
                &nbsp;&nbsp;
                {t('stringInVariables')}
              </div>

              <div className="inputTitle">{t('tags')}</div>
              <TagsInput
                tags={tags}
                currentTags={actionTags || []}
                modifyTags={newTags => setActionTags(newTags)}
              />
              <div className="buttonsHolder flexSpaceBetween">
                {
                  action && action.id ? (
                    <DeleteButton
                      handleDelete={handleDeleteAction}
                      askConfirmation
                    />
                  ) : <div />
                }
                <div>
                  <CancelButton onClick={() => goBack(null)} />
                  <SubmitButton
                    label={action && action.id ? t('modify') : t('add')}
                  />
                </div>
              </div>
            </form>
          </div>
        )
      }
    </div>
  );
};

ActionEditionForm.propTypes = {
  actionId: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  trigger: reducersTypes.triggers.trigger,
  handleSave: PropTypes.func,
  handleDelete: PropTypes.func,
};
ActionEditionForm.defaultProps = {
  actionId: null,
  trigger: null,
  handleSave: () => { },
  handleDelete: () => { },
};

export default ActionEditionForm;
