import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Accordion, Checkbox, ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { showError } from '~utils/toast';
import API from '~services/endpoints';
import { SubmitButton } from '~components/UI';
import { RootState } from '~services/store';
import './AdminPage.scss';

const RedirectionLinkTable = () => {
  const { t } = useTranslation();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);

  const [selectedDashboards, setSelectedDashboards] = useState<string[]>([]);
  const [selectedTopviews, setSelectedTopviews] = useState<string[]>([]);
  const [language, setLanguage] = useState<string | null>(null);

  const buttons = [
    { label: 'EN', value: 'en' },
    { label: 'FR', value: 'fr' },
    { label: 'ES', value: 'es' },
  ];

  const fetchLink = (contentType: string, contentId: string) => API.addLinkRedirection({ contentType, contentId })
    .then(res => (language ? `${window.location.protocol}//${window.location.host}/redirect/${language}/${res.linkRedirection}`
      : `${window.location.protocol}//${window.location.host}/redirect/${res.linkRedirection}`));

  const generate = async () => {
    if (!selectedDashboards.length && !selectedTopviews.length) {
      showError(t('noModulesSelected'));
      return;
    }

    const selectedItems = [
      ...dashboards?.map(dash => ({ ...dash, type: 'Dashboard' }))?.filter(dash => selectedDashboards.includes(dash.id)) || [],
      ...topviews?.map(topview => ({ ...topview, type: 'Topview' }))?.filter(topview => selectedTopviews.includes(topview.id)) || [],
    ];

    const newTab = window.open();
    newTab?.document.write('<html><head><title>Résultats</title></head><body>');

    for (const item of selectedItems) {
      try {
        const link = await fetchLink(item.type, item.id);
        newTab?.document.write(`<p>${item.name} - <a href="${link}" target="_blank">${link}</a></p>`);
      } catch {
        showError(t('error'));
      }
    }

    newTab?.document.write('</body></html>');
    newTab?.document.close();

    setSelectedDashboards([]);
    setSelectedTopviews([]);
  };

  return (
    <div className="RedirectionLinks">
      <div className="inputTitle">{t('language')}</div>
      <div className="languageSelector">
        <ButtonGroup
          buttons={buttons}
          value={language}
          onChange={setLanguage}
        />
      </div>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        blueThemed
        items={[
          {
            title: t('dashboards'),
            body: (
              <table className="redirectionLinkTable">
                <thead>
                  <tr>
                    <th key="colName" />
                    <th key="colCheckbox" style={{ textAlign: 'center' }} />
                  </tr>
                  <tr>
                    <td />
                    <td className="checkboxCell">
                      <Checkbox
                        checked={selectedDashboards.length === dashboards?.length}
                        onChange={checked => {
                          if (checked) {
                            setSelectedDashboards(dashboards?.map(dashboard => dashboard.id) || []);
                          } else {
                            setSelectedDashboards([]);
                          }
                        }}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {dashboards?.map(dashboard => (
                    <tr key={dashboard.id}>
                      <td>{dashboard.name}</td>
                      <td className="checkboxCell">
                        <Checkbox
                          checked={selectedDashboards.includes(dashboard.id)}
                          onChange={checked => {
                            if (checked) {
                              setSelectedDashboards([...selectedDashboards, dashboard.id]);
                            } else {
                              setSelectedDashboards(selectedDashboards.filter(id => id !== dashboard.id));
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ),
          },
          {
            title: t('topviews'),
            body: (
              <table className="redirectionLinkTable">
                <thead>
                  <tr>
                    <th key="colName" style={{ padding: 0, margin: 0 }} />
                    <th key="colCheckbox" style={{ textAlign: 'center', padding: 0, margin: 0 }} />
                  </tr>
                  <tr>
                    <td />
                    <td className="checkboxCell">
                      <Checkbox
                        checked={selectedTopviews.length === topviews?.length}
                        onChange={checked => {
                          if (checked) {
                            setSelectedTopviews(topviews?.map(topview => topview.id) || []);
                          } else {
                            setSelectedTopviews([]);
                          }
                        }}
                      />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {topviews?.map(topview => (
                    <tr key={topview.id}>
                      <td>{topview.name}</td>
                      <td className="checkboxCell">
                        <Checkbox
                          checked={selectedTopviews.includes(topview.id)}
                          onChange={checked => {
                            if (checked) {
                              setSelectedTopviews([...selectedTopviews, topview.id]);
                            } else {
                              setSelectedTopviews(selectedTopviews.filter(id => id !== topview.id));
                            }
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ),
          },
        ]}
      />
      <div className="buttonContainer">
        <SubmitButton label={t('generate')} onClick={generate} />
      </div>
    </div>
  );
};

export default RedirectionLinkTable;
