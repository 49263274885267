import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services/index';
import { FrameGrid } from '~components/Pages';
import { ResourcesHandler } from '~UI/index';
import directLinks from '~utils/directLinks';
import { RootState } from '~services/store';

const configPath = directLinks.utils.configPathFrom;

const Bookmarks = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const favorites = useSelector((state: RootState) => state.users.selectedUser.favorites);
  const reports = useSelector((state: RootState) => state.reports.reports);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);

  const getContent = () => {
    const pagesTiles = [
      {
        id: 'config',
        name: t('configuration'),
        link: `${configPath(directLinks.dashboards())}?from=bookmarks`,
        icon: 'gear',
      },
      {
        id: 'rulesEngine',
        name: t('rulesEngine'),
        link: `${configPath(directLinks.ruleEngine())}?from=bookmarks`,
        icon: 'gear',
        img: 'https://i.ibb.co/L5NMKzv/Capture-du-2020-02-25-15-14-26.png',
      },
      {
        id: 'events',
        name: t('events'),
        link: `${directLinks.events()}?from=bookmarks`,
        icon: 'list',
      },
      // events and machines stoppages used to be separated pages
      // stoppages now redirects to events for backwards compatibility
      // with users who had previously bookmarked machines stoppages page
      {
        id: 'stoppages',
        name: t('machinesStatus'),
        link: `${directLinks.events()}?from=bookmarks`,
        icon: 'exclamation-circle',
      },
    ];

    const favoritesPagesTiles = favorites
      ? favorites.pages
        .map(favoritePage => pagesTiles.find(p => p.id === favoritePage))
        .filter(favoritePage => !!favoritePage)
      : [];

    const favoritesDashboardsTiles = favorites
      ? favorites.dashboards
        .map(favoriteDashboard => dashboards?.find(d => d.id === favoriteDashboard))
        .filter(favoriteDashboard => !!favoriteDashboard)
        .map(favoriteDashboard => ({
          name: favoriteDashboard.name,
          link: `${directLinks.dashboard(favoriteDashboard.id)}?from=bookmarks`,
          img: favoriteDashboard.backgroundURL,
          type: 'dashboards',
          id: favoriteDashboard.id,
        }))
      : [];

    const favoritesReportsTiles = favorites
      ? favorites.reports
        .map(favoriteReport => reports.find(r => r.id === favoriteReport))
        .filter(favoriteReport => !!favoriteReport)
        .map(favoriteReport => ({
          name: favoriteReport.name,
          link: `${directLinks.report(favoriteReport.id)}?from=bookmarks`,
          img: favoriteReport.backgroundURL,
          type: 'reports',
          id: favoriteReport.id,
        }))
      : [];

    const favoritesTopviewsTiles = favorites
      ? favorites.topviews
        .map(favoriteTopview => topviews.find(topview => topview.id === favoriteTopview))
        .filter(favoriteTopview => !!favoriteTopview)
        .map(favoriteTopview => ({
          name: favoriteTopview.name,
          link: `${directLinks.topview(favoriteTopview.id)}?from=bookmarks`,
          img: favoriteTopview.backgroundURL,
          type: 'topviews',
          id: favoriteTopview.id,
        }))
      : [];

    const tiles = [
      ...favoritesPagesTiles,
      ...favoritesDashboardsTiles,
      ...favoritesReportsTiles,
      ...favoritesTopviewsTiles,
    ];

    return (
      <div className="Bookmarks">
        <h4>{t('bookmarks')}</h4>
        <hr />
        <FrameGrid tiles={tiles} />
      </div>
    );
  };

  return (
    <ResourcesHandler
      resources={[dashboards, reports, topviews]}
      resourceFetchers={[
        () => dispatch(reduxOperations.dashboards.fetchDashboards() as any),
        () => dispatch(reduxOperations.reports.fetchReports() as any),
        () => dispatch(reduxOperations.topviews.fetchTopviews() as any),
      ]}
      getContents={getContent}
    />
  );
};

export default Bookmarks;
