import types from './types';

const getOperationsRequest = () => ({
  type: types.GET_OPERATIONS_REQUEST,
});

const getOperationsResponse = response => ({
  type: types.GET_OPERATIONS_RESPONSE,
  payload: response,
});

const addOperationRequest = data => ({
  type: types.ADD_OPERATION_REQUEST,
  payload: { data },
});

const addOperationResponse = response => ({
  type: types.ADD_OPERATION_RESPONSE,
  payload: response,
});

const updateOperationRequest = (id, data) => ({
  type: types.UPDATE_OPERATION_REQUEST,
  payload: { id, data },
});

const updateOperationResponse = response => ({
  type: types.UPDATE_OPERATION_RESPONSE,
  payload: response,
});

const deleteOperationRequset = id => ({
  type: types.DELETE_OPERATION_REQUEST,
  payload: { id },
});

const deleteOperationResponse = response => ({
  type: types.DELETE_OPERATION_RESPONSE,
  payload: response,
});

export {
  getOperationsRequest,
  getOperationsResponse,
  addOperationRequest,
  addOperationResponse,
  updateOperationRequest,
  updateOperationResponse,
  deleteOperationRequset,
  deleteOperationResponse,
};
