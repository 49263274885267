import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Select } from '@intelligenceindustrielle/react-ui-components';
import API from '~services/endpoints';
import { CheckboxToggle, TextInput } from '~UI/index';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { sortArray } from '~utils/sort';
import { RootState } from '~services/store';

const FormAreaChart = ({ selectedObject }) => {
  const { t } = useTranslation();

  const streams = useSelector((state: RootState) => state.streams);
  const variables = useSelector((state: RootState) => state.variables);

  const [areaChartType, setAreaChartType] = useState((selectedObject && selectedObject.areaChartType) || 'events');
  const [axisY, setAxisY] = useState((selectedObject && selectedObject.axisY) || '');
  const [eventNames, setEventNames] = useState([]);
  const [showGoalToggle, setShowGoalToggle] = useState(selectedObject ? selectedObject.showGoalToggle : false);
  const [goal, setGoal] = useState(selectedObject ? selectedObject.goal : null);
  const [showArrows, setShowArrows] = useState(selectedObject.showArrows);

  const handleShowArrows = () => {
    setShowArrows(!showArrows);
  };

  const fetchEventNames = async () => {
    const { eventsPropertyElements } = await API.getDistinctEvents('name');
    setEventNames(eventsPropertyElements);
  };

  useEffect(() => {
    fetchEventNames();
  }, []);

  const showContent = () => {
    const properties = [].concat(...streams.map(s => s.properties));
    const all = [...properties, ...variables];
    const allEnabled = sortArray('alphabetically', all.filter(a => a.isEnabled), 'variable');

    if (areaChartType === 'events') {
      return (
        <div>
          <div className="inputTitle">{t('eventName')}</div>
          <TextInput
            Component={undefined}
            name="axisY"
            options={eventNames}
            className="fullwidth"
            value={axisY}
            onChange={name => setAxisY(name)}
            trigger={undefined}
          />
        </div>
      );
    }

    if (areaChartType === 'variables') {
      if (allEnabled.length > 0) {
        const valueStillExists = !selectedObject.axisY || all.find(v => v.id === selectedObject.axisY);
        const isStillEnabled = !selectedObject.axisY || allEnabled.find(v => v.id === selectedObject.axisY);
        return (
          <>
            <div className="inputTitle">{t('variable')}</div>
            {!valueStillExists && `${t('errorDeletedVariable')}.`}
            {valueStillExists && !isStillEnabled && `${t('errorHistoryDesactivated')}.`}
            <Select
              defaultValue={selectedObject.axisY}
              name="axisY"
              options={allEnabled.map(e => ({ label: e.variable, value: e.id }))}
              placeholder={t('selectOption')}
            />
          </>
        );
      }

      return (
        <div>{`${t('noHistoryVariables')}.`}</div>
      );
    }
  };

  const handleShowGoalToggle = () => {
    setShowGoalToggle(prevShowGoalToggle => !prevShowGoalToggle);
  };

  const handleChangeGoal = e => {
    setGoal(e.currentTarget.value);
  };

  const buttons = [
    { label: t('events'), value: 'events' },
    { label: t('variables'), value: 'variables' },
  ];

  return (
    <>
      <div className="inputTitle">{t('DataType')}</div>
      <input type="hidden" name="areaChartType" value={areaChartType} />
      <ButtonGroup
        buttons={buttons}
        onChange={type => {
          setAreaChartType(type);
          setAxisY('');
        }}
        value={areaChartType}
      />
      {showContent()}

      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={selectedObject.intervalType}
        intervalTypeInput
      />

      <div className="inputTitle">
        {t('setGoal')}
          &nbsp;
        <CheckboxToggle
          name="showGoalToggle"
          controlledCheck={showGoalToggle}
          onChange={handleShowGoalToggle}
        />
      </div>
      <div className={`toggleBlock ${showGoalToggle ? 'showToggledBlockPopup' : 'hideToggledBlock'}`}>
        {showGoalToggle && (
          <input
            type="number"
            name="number:goal"
            min="0"
            step="0.01"
            className="fullwidth"
            defaultValue={goal}
            onChange={handleChangeGoal}
          />
        )}
      </div>
      <div className="inputTitle">
        {t('showTimeNavigationArrows')}
        &nbsp;
        <CheckboxToggle
          name="showArrows"
          controlledCheck={showArrows}
          onChange={handleShowArrows}
        />
      </div>
    </>
  );
};

FormAreaChart.propTypes = {
  selectedObject: PropTypes.shape({
    areaChartType: PropTypes.string,
    axisY: PropTypes.string,
    showGoalToggle: PropTypes.bool,
    goal: PropTypes.number,
    intervalType: PropTypes.string,
    showArrows: PropTypes.bool,
  }),
};
FormAreaChart.defaultProps = {
  selectedObject: {},
};

export default FormAreaChart;
