import {
  loginRequest,
  loginResponse,
  loginMicrosoftRequest,
  loginMicrosoftResponse,
  logoutRequest,
  logoutResponse,
  setTheme,
  updateIsSignup,
  updateIsVerification,
} from './actions';
import API from '~services/endpoints';

function login(username = null, password = null, redirected = false, redirectedContent = null, code = null) {
  return dispatch => {
    if (redirected) {
      dispatch(loginRequest({ redirected, redirectedContent }));
      return API.login({ redirected, redirectedContent })
        .then(res => dispatch(loginResponse(res)));
    }
    dispatch(loginRequest({ username, password }));
    return API.login({ username, password, code })
      .then(res => dispatch(loginResponse(res)));
  };
}

function logout() {
  return dispatch => {
    dispatch(logoutRequest());
    return API.logout()
      .then(res => dispatch(logoutResponse(res)));
  };
}

function loginMicrosoft() {
  return dispatch => {
    dispatch(loginMicrosoftRequest());
    return API.loginMicrosoft()
      .then(res => dispatch(loginMicrosoftResponse(res)));
  };
}

export default {
  login,
  loginMicrosoft,
  logout,
  setTheme,
  updateIsSignup,
  updateIsVerification,
};
