import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import { reducersTypes } from '~services/index';
import { invertColor } from '~utils/index';
import './TagsSelection.scss';

const TagsSelection = ({
  tags, selectedTags, selectTag, tagInfo, style, infoIcon = true,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tagsSelectionContainer" style={style}>
      <div className="tagsSelection">
        {infoIcon && (
          <>
            <Icon
              icon="info-circle"
              style={{ fontSize: '17px' }}
              tooltipLabel={tagInfo}
              tooltipPlacement="right"
            />
            &nbsp;
          </>
        )}

        <button
          className={`tagButton ${selectedTags.includes('all') && 'active'} allButton`}
          key="all"
          onClick={() => selectTag('all')}
          type="button"
        >
          {t('all')}
        </button>
        {
          tags.map(tag => (
            <button
              className={`tagButton ${selectedTags.includes(tag.id) && 'active'}`}
              key={tag.id}
              onClick={e => selectTag(tag.id, e.metaKey || e.ctrlKey)}
              style={{ backgroundColor: tag.color, color: tag.color ? invertColor(tag.color) : '#ffffff' }}
              type="button"
            >
              {tag.name}
            </button>
          ))
        }
      </div>
    </div>
  );
};

TagsSelection.propTypes = {
  tags: reducersTypes.tags.tags,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  selectTag: PropTypes.func,
  tagInfo: PropTypes.string,
  style: PropTypes.object,
  infoIcon: PropTypes.bool,
};

export default TagsSelection;
