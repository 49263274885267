import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageTemplate } from '~components/Pages';
import './ErrorPage.scss';

interface ErrorPageProps {
  errorNumber: number;
  errorDefinition: string;
  errorDescription?: string;
}

const ErrorPage = ({
  errorNumber,
  errorDefinition,
  errorDescription,
}: ErrorPageProps) => {
  const { t } = useTranslation();
  const definitionText = errorDefinition;
  const descriptionText = errorDescription;

  return (
    <PageTemplate>
      <div className="ErrorPage">
        <h1>
          {`${t('error')} ${errorNumber}`}
        </h1>
        <h3>{ definitionText }</h3>
        <br />
        <h4>{ descriptionText }</h4>
      </div>
    </PageTemplate>
  );
};

ErrorPage.propTypes = {
  errorNumber: PropTypes.number.isRequired,
  errorDefinition: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]).isRequired,
  errorDescription: PropTypes.string,
};

export default ErrorPage;
