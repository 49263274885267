import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { reducersTypes } from '~services';
import { getSocket } from '~services/socket';
import API from '~services/endpoints';
import { getCookie } from '~utils/getCookie';

function PromptTileVariables({
  height,
  width,
  tile,
}) {
  const socket = getSocket();
  const variables = useSelector(state => state.variables);
  const [randomUUID] = useState(uuidv4());
  const [completeUrl, setCompleteUrl] = useState('');

  const handleSocketData = data => {
    socket.emit('updateStatistics', { uuid: randomUUID, statistics: data }); // TODO change to updateVariables
  };

  const fetchValues = async () => {
    const wait = ms => new Promise(resolve => {
      setTimeout(resolve, ms);
    });
    const { values } = await API.getMultiplesValues(variables.map(variable => variable.id));

    const data = variables.map(variable => {
      const value = values.find(v => v.valueId === variable.id);
      return {
        id: variable.id,
        name: variable.variable,
        value: value ? value.value : null,
      };
    });

    await wait(1000); // wait 1 second for the iframe to load
    data.forEach(d => {
      socket.emit('updateStatistics', { uuid: randomUUID, statistics: d }); // TODO change to updateVariables
    });
  };

  useEffect(() => {
    const token = getCookie('token');
    const baseUrl = process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : 'http://localhost:3000';
    setCompleteUrl(`${tile.url}?url=${baseUrl}&channel=${randomUUID}&token=${token}`);

    async function sendFirstData() {
      await fetchValues();
      socket?.on('value', handleSocketData);
    }

    sendFirstData();

    return () => {
      socket?.removeListener('value', handleSocketData);
    };
  }, [tile.url, randomUUID, socket, variables]);

  return (
    <div className="framed flex V">
      <iframe
        style={{
          border: 0,
          height,
          width,
          transformOrigin: '0 0',
          position: 'absolute',
          top: 0,
        }}
        title="iframeTile"
        scrolling="auto"
        src={completeUrl}
      >
        <p>Your browser does not support iframes</p>
      </iframe>
    </div>
  );
}

PromptTileVariables.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile.isRequired,
};

export default PromptTileVariables;
