import { getActionIcon } from '~utils/icons';
import { ActionType } from '~services/actions/types';

const actionOptions = [
  {
    type: ActionType.API_CALL,
    name: 'apiCall',
    icon: getActionIcon(ActionType.API_CALL),
  },
  {
    type: ActionType.EMAIL,
    name: 'email',
    icon: getActionIcon(ActionType.EMAIL),
  },
  {
    type: ActionType.EVENT,
    name: 'event',
    icon: getActionIcon(ActionType.EVENT),
  },
  {
    type: ActionType.MACHINE_PARAM,
    name: 'machineParam',
    icon: getActionIcon(ActionType.MACHINE_PARAM),
  },
  {
    type: ActionType.MACHINE_STATE,
    name: 'machineState',
    icon: getActionIcon(ActionType.MACHINE_STATE),
  },
  {
    type: ActionType.MQTT,
    name: 'mqttMessage',
    icon: getActionIcon(ActionType.MQTT),
  },
  {
    type: ActionType.PART,
    name: 'part',
    icon: getActionIcon(ActionType.PART),
  },
  {
    type: ActionType.PERFORMANCE,
    name: 'performance',
    icon: getActionIcon(ActionType.PERFORMANCE),
  },
  {
    type: ActionType.PUSH_NOTIFICATION,
    name: 'pushNotification',
    icon: getActionIcon(ActionType.PUSH_NOTIFICATION),
  },
  {
    type: ActionType.SMS,
    name: 'sms',
    icon: getActionIcon(ActionType.SMS),
  },
  {
    type: ActionType.STOPWATCH,
    name: 'Stopwatch',
    icon: getActionIcon(ActionType.STOPWATCH),
  },
  {
    type: ActionType.VARIABLE,
    name: 'variable',
    icon: getActionIcon(ActionType.VARIABLE),
  },
];

export { actionOptions };
