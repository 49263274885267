import React from 'react';
import ConfigWrapper from '~UI/Wrappers/ConfigPageWrapper';
import ActionsPage from './RuleEngine/ActionsPage';
import AdminPage from './Admin/AdminPage';
import DashboardPage from '../Dashboards/DashboardPage';
import { DashboardSelectionPage } from '../Dashboards/DashboardSelectionPage';
import InputsPage from './Streams/Inputs/InputsPage';
import LogsPage from './Logs/LogsPage';
import { MachineEditionPage } from './Machines/MachineEditionPage';
import MachineSelectionPage from './Machines/MachineSelectionPage';
import { OperationsPage } from './Operations/OperationsPage';
import { ReportPage } from '../Reports/ReportPage';
import { ReportSelectionPage } from '../Reports/ReportSelectionPage/ReportSelectionPage';
import RuleEditionPage from './RuleEngine/ruleEngineEdition/RuleEditionPage';
import RuleEnginePage from './RuleEngine/RuleEnginePage';
import ShiftsPage from './Shifts/ShiftsPage';
import StopwatchesPage from './Stopwatches/StopwatchesPage';
import StreamSelectionPage from './Streams/StreamSelectionPage';
import TopviewPage from '../Topviews/TopviewPage';
import { TopviewSelectionPage } from '../Topviews/TopviewSelectionPage';
import { UserPage } from './Users/UserPage';
import ImagesPage from './Images/ImagesPage';

import UsersPage from './Users/UsersPage';
import VariablesPage from './Variables/VariablesPage';

const ActionsConfigPage = () => (
  <ConfigWrapper>
    <ActionsPage />
  </ConfigWrapper>
);

const AdminConfigPage = () => (
  <ConfigWrapper>
    <AdminPage />
  </ConfigWrapper>
);

const DashboardConfigPage = () => (
  <ConfigWrapper>
    <DashboardPage />
  </ConfigWrapper>
);

const DashboardsConfigPage = () => (
  <ConfigWrapper>
    <DashboardSelectionPage />
  </ConfigWrapper>
);

const InputsConfigPage = () => (
  <ConfigWrapper>
    <InputsPage />
  </ConfigWrapper>
);

const ImagesConfigPage = () => (
  <ConfigWrapper>
    <ImagesPage />
  </ConfigWrapper>
);

const LogsConfigPage = () => (
  <ConfigWrapper>
    <LogsPage />
  </ConfigWrapper>
);

const MachineConfigPage = () => (
  <ConfigWrapper>
    <MachineEditionPage />
  </ConfigWrapper>
);

const MachinesConfigPage = () => (
  <ConfigWrapper>
    <MachineSelectionPage storageType="K2_machines" />
  </ConfigWrapper>
);

const OperationsConfigPage = () => (
  <ConfigWrapper>
    <OperationsPage />
  </ConfigWrapper>
);

const ReportConfigPage = () => (
  <ConfigWrapper>
    <ReportPage />
  </ConfigWrapper>
);

const ReportsConfigPage = () => (
  <ConfigWrapper>
    <ReportSelectionPage />
  </ConfigWrapper>
);

const RuleEditionConfigPage = () => (
  <ConfigWrapper>
    <RuleEditionPage />
  </ConfigWrapper>
);

const RuleEngineConfigPage = () => (
  <ConfigWrapper>
    <RuleEnginePage />
  </ConfigWrapper>
);

const ShiftsConfigPage = () => (
  <ConfigWrapper>
    <ShiftsPage />
  </ConfigWrapper>
);

const StopwatchesConfigPage = () => (
  <ConfigWrapper>
    <StopwatchesPage storageType="K2_stopwatches" />
  </ConfigWrapper>
);

const StreamsConfigPage = () => (
  <ConfigWrapper>
    <StreamSelectionPage storageType="K2_streams" />
  </ConfigWrapper>
);

const TopviewConfigPage = () => (
  <ConfigWrapper>
    <TopviewPage />
  </ConfigWrapper>
);

const TopviewsConfigPage = () => (
  <ConfigWrapper>
    <TopviewSelectionPage />
  </ConfigWrapper>
);

const UserConfigPage = () => (
  <ConfigWrapper>
    <UserPage />
  </ConfigWrapper>
);

const UsersConfigPage = () => (
  <ConfigWrapper>
    <UsersPage storageType="K2_users" />
  </ConfigWrapper>
);

const VariablesConfigPage = () => (
  <ConfigWrapper>
    <VariablesPage />
  </ConfigWrapper>
);

export default {
  Default: DashboardsConfigPage,
  // alphabetical order
  Actions: ActionsConfigPage,
  Admin: AdminConfigPage,
  Dashboard: DashboardConfigPage,
  Dashboards: DashboardsConfigPage,
  Inputs: InputsConfigPage,
  Images: ImagesConfigPage,
  Logs: LogsConfigPage,
  Machine: MachineConfigPage,
  Machines: MachinesConfigPage,
  Operations: OperationsConfigPage,
  Report: ReportConfigPage,
  Reports: ReportsConfigPage,
  RuleEdition: RuleEditionConfigPage,
  RuleEngine: RuleEngineConfigPage,
  Shifts: ShiftsConfigPage,
  Stopwatches: StopwatchesConfigPage,
  Streams: StreamsConfigPage,
  Topview: TopviewConfigPage,
  Topviews: TopviewsConfigPage,
  User: UserConfigPage,
  Users: UsersConfigPage,
  Variables: VariablesConfigPage,
};
