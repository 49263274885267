import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import reduxOperations from '~services/reduxOperations';
import { DefaultModal } from '~UI/DefaultModal';
import './LanguagePopup.scss';

const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  language: PropTypes.string.isRequired,
};

const defaultProps = {
  onHide: () => undefined,
  show: false,
};

const LanguagePopup = ({ show, onHide, language }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentLanguage, setCurrentLanguage] = useState(language);

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  const handleLanguageChange = async lang => {
    dispatch(reduxOperations.views.setLanguage(lang));
    setCurrentLanguage(lang);
    changeLanguage(lang);
    onHide();
  };

  const languages = [
    { name: 'English', value: 'en' },
    { name: 'Français', value: 'fr' },
    { name: 'Español', value: 'es' },
  ];

  return (
    <DefaultModal
      title={t('selectLanguage')}
      show={show}
      closePopup={onHide}
    >
      <div className="LanguagePopup">
        {languages.map(({ name, value }) => (
          <button
            type="button"
            key={value}
            className={`language-button ${currentLanguage === value ? 'selected' : ''}`}
            onClick={() => handleLanguageChange(value)}
          >
            {name}
          </button>
        ))}
      </div>
    </DefaultModal>
  );
};

LanguagePopup.propTypes = propTypes;
LanguagePopup.defaultProps = defaultProps;

export default LanguagePopup;
