export function isOutOfViewport(elem) {
  if (elem) {
    const bounding = elem.getBoundingClientRect();

    const top = bounding.top < 0;
    const left = bounding.left < 0;
    // -60 for the height of the footer
    const bottom = bounding.bottom > ((window.innerHeight || document.documentElement.clientHeight) - 60);
    const right = bounding.right > (window.innerWidth || document.documentElement.clientWidth);

    return {
      top,
      left,
      bottom,
      right,
      any: top || left || bottom || right,
      all: top && left && bottom && right,
    };
  }
  return false;
}

export default {
  isOutOfViewport,
};
