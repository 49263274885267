import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxOperations } from '~services';
import { isExternalLink } from '~utils/isExternalLink';
import Widget from '../Widget';
import ConfigHandler from './TopviewWidgetConfigHandler';
import './TopviewWidget.scss';

const TopviewWidget = ({
  topviewId, widget, magnitude, maxX, maxY, fetchDelay,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isInConfigurationMode = useSelector(state => state.views.isInConfigurationMode);

  const [x, setX] = useState(widget.x);
  const [y, setY] = useState(widget.y);
  const [w, setW] = useState(widget.w);
  const [h, setH] = useState(widget.h);

  const updateState = ({ widget: widgetArg }) => {
    setX(widgetArg.x);
    setY(widgetArg.y);
    setW(widgetArg.w);
    setH(widgetArg.h);
  };

  useEffect(() => {
    updateState({ widget });
  }, [widget]);

  const handleClick = () => {
    if (widget.type !== 'media' && widget.linksTo) {
      if ((isExternalLink(widget.linksTo) && !widget.linksTo.startsWith('/')) || widget.linksTo.includes('redirect')) {
        window.location.href = widget.linksTo;
      } else {
        const hostPath = widget.linksTo.replace('http://', '').replace('https://', '').split('/')[0];
        const inAppURL = widget.linksTo.replace(hostPath, '').replace('http://', '').replace('https://', '');
        dispatch(reduxOperations.redirectHistory.redirectHistoryPush(window.location.pathname));
        history.push(inAppURL);
      }
    }
  };

  const handleDrag = (xArg, yArg, wArg, hArg, isFinal) => {
    if (isFinal) {
      dispatch(reduxOperations.topviews.updateWidget(topviewId, widget.id, { x: xArg, y: yArg, w: wArg, h: hArg }));
    }
    setX(xArg);
    setY(yArg);
    setW(wArg);
    setH(hArg);
  };

  return (
    <g className={`Topview__Widget ${widget.linksTo ? 'Redirects' : ''}`}>
      <Widget
        widget={widget}
        fetchDelay={fetchDelay}
        x={x}
        y={y}
        w={w}
        h={h}
        onClick={handleClick}
      />
      {
        isInConfigurationMode && (
          <ConfigHandler
            x={x}
            y={y}
            w={w}
            h={h}
            widget={widget}
            topviewId={topviewId}
            magnitude={magnitude}
            handleDrag={handleDrag}
            maxX={maxX}
            maxY={maxY}
          />
        )
      }
    </g>
  );
};

TopviewWidget.propTypes = {
  topviewId: PropTypes.string.isRequired,
  fetchDelay: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    id: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    w: PropTypes.number,
    h: PropTypes.number,
    value: PropTypes.string,
    format: PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({}),
    }),
    linksTo: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  magnitude: PropTypes.number.isRequired,
  maxX: PropTypes.number.isRequired,
  maxY: PropTypes.number.isRequired,
};

export default TopviewWidget;
