import {
  get,
  post,
  put,
  del,
} from '../endpoints';

export const getActions = () => get('/actions', [], {});

export const createAction = action => post('/actions', [], action);

export const updateAction = (id, action) => put('/actions/:id', { id }, action);

export const deleteAction = id => del('/actions/:id', { id });
