import omit from 'lodash.omit';

type JsObject = { [key: string]: any };

const validateVarExists = (object: JsObject) => {
  const filter = ['name', 'type'];
  const filteredObject: JsObject = omit(object, filter);
  const notTransformedVariable = element => (element.startsWith('$') && !element.startsWith('${'));

  for (const value of Object.values(filteredObject)) {
    const splittedArray = value?.toString().split(' ');
    if (splittedArray?.some(notTransformedVariable)) {
      return false;
    }
  }

  return true;
};

export default validateVarExists;
