import { Action } from './types';

export enum ActionActionTypes {
  GET_ACTIONS_REQUEST = 'GET_ACTIONS_REQUEST',
  GET_ACTIONS_RESPONSE = 'GET_ACTIONS_RESPONSE',
  CREATE_ACTION_REQUEST = 'CREATE_ACTION_REQUEST',
  CREATE_ACTION_RESPONSE = 'CREATE_ACTION_RESPONSE',
  UPDATE_ACTION_REQUEST = 'UPDATE_ACTION_REQUEST',
  UPDATE_ACTION_RESPONSE = 'UPDATE_ACTION_RESPONSE',
  DELETE_ACTION_REQUEST = 'DELETE_ACTION_REQUEST',
  DELETE_ACTION_RESPONSE = 'DELETE_ACTION_RESPONSE',
}

export type ActionAction =
  | { type: ActionActionTypes.GET_ACTIONS_REQUEST; }
  | { type: ActionActionTypes.GET_ACTIONS_RESPONSE; payload: { actions: Action[] } }
  | { type: ActionActionTypes.CREATE_ACTION_REQUEST; payload: { action: Partial<Action> } }
  | { type: ActionActionTypes.CREATE_ACTION_RESPONSE; payload: { action: Action } }
  | { type: ActionActionTypes.UPDATE_ACTION_REQUEST; payload: { id: string; action: Action } }
  | { type: ActionActionTypes.UPDATE_ACTION_RESPONSE; payload: { action: Action } }
  | { type: ActionActionTypes.DELETE_ACTION_REQUEST; payload: string }
  | { type: ActionActionTypes.DELETE_ACTION_RESPONSE; payload: { actionId: string } };

export const getActionsRequest = (): ActionAction => ({
  type: ActionActionTypes.GET_ACTIONS_REQUEST,
});

export const getActionsResponse = (response): ActionAction => ({
  type: ActionActionTypes.GET_ACTIONS_RESPONSE,
  payload: response,
});

export const createActionRequest = (action): ActionAction => ({
  type: ActionActionTypes.CREATE_ACTION_REQUEST,
  payload: action,
});

export const createActionResponse = (payload): ActionAction => ({
  type: ActionActionTypes.CREATE_ACTION_RESPONSE,
  payload,
});

export const updateActionRequest = (id: string, action): ActionAction => ({
  type: ActionActionTypes.UPDATE_ACTION_REQUEST,
  payload: { id, action },
});

export const updateActionResponse = (payload): ActionAction => ({
  type: ActionActionTypes.UPDATE_ACTION_RESPONSE,
  payload,
});

export const deleteActionRequest = (payload: string): ActionAction => ({
  type: ActionActionTypes.DELETE_ACTION_REQUEST,
  payload,
});

export const deleteActionResponse = (payload): ActionAction => ({
  type: ActionActionTypes.DELETE_ACTION_RESPONSE,
  payload,
});
