import React from 'react';

const button = ({ size }) => (
  <svg version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" className={`svg-inline--fa ${size ? `fa-${size}` : ''}`} fill="currentcolor">
    <path d="m37.1 314a249 94.7 0 0 0-30.6 45.5 249 94.7 0 0 0 249 94.7 249 94.7 0 0 0 249-94.7 249 94.7 0 0 0-30.1-44.9v28.2c0 47.7-98 86.3-219 86.3-121 0-219-38.7-219-86.3z" strokeWidth="1.3" />
    <path d="m64.4 184c0-39.7 85.8-71.8 192-71.8 106 0 192 32.2 192 71.8s-85.8 71.8-192 71.8c-106 0-192-32.2-192-71.8zm0 61.5v82.2c0 39.7 85.8 71.8 192 71.8 106 0 192-32.2 192-71.8v-82.2c-84.9 61.6-298 61.7-383 0z" strokeWidth=".748" />
  </svg>
);

export default button;
