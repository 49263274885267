import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import { LogAction, LogActionTypes } from './actions';
import { Log } from './types';

export type LogReducerState = Log[] | null;

function logs(state: LogReducerState = null, action: LogAction) {
  switch (action.type) {
    case LogActionTypes.GET_LOGS_RESPONSE:
      return action.payload.logs;

    default:
      return state;
  }
}

const defaultLogPropTypes = PropTypes.shape({
  method: PropTypes.string,
  url: PropTypes.string,
  decodedToken: PropTypes.shape({ userId: PropTypes.string }),
  responseTime: PropTypes.number,
  responseLength: PropTypes.number,
  type: PropTypes.string,
  createdAt: PropTypes.number,
});

const actionLogPropTypes = PropTypes.shape({
  actionType: PropTypes.string,
  actionId: PropTypes.string,
  actionParams: PropTypes.any,
  createdAt: PropTypes.number,
});

const activityLogPropTypes = PropTypes.shape({
  ...defaultLogPropTypes,
  body: PropTypes.any,
});

const errorLogPropTypes = PropTypes.shape({
  ...defaultLogPropTypes,
  errorCode: PropTypes.number,
  details: PropTypes.any,
  request: PropTypes.any,
});

const logPropTypes = PropTypes.oneOfType([
  actionLogPropTypes,
  activityLogPropTypes,
  errorLogPropTypes,
]);

export const propTypes = PropTypes.arrayOf(nullable(logPropTypes));

export default logs;
