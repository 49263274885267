import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { SubmitButton, TextInput } from '~UI';
import { MAX_NB_EVENTS_ONE_PAGE, MAX_NB_PAGES } from '~utils/constants';
import { EventsList } from './EventsList';

const DefaultEventsList = ({ setDefaultNames }) => {
  const socket = getSocket();
  const { t } = useTranslation();

  const [eventsList, setEventsList] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [eventNames, setEventNames] = useState([]);

  useEffect(() => {
    (async () => {
      const { eventsPropertyElements: defaultNames } = await API.getDistinctEvents('name', { type: 'DefaultEvent' });
      setDefaultNames(defaultNames);
      setEventNames(defaultNames);
    })();
  }, []);

  const handleSocketEvent = newEvent => {
    if (newEvent.type === 'DefaultEvent') {
      setEventsList(prevEventsList => {
        const eventAlreadyExists = prevEventsList.some(elem => newEvent.id === elem.id);
        if (!eventAlreadyExists) {
          return [newEvent, ...prevEventsList];
        }
        const index = prevEventsList.findIndex(event => event.id === newEvent.id);
        prevEventsList[index] = newEvent;

        return prevEventsList;
      });
    }
  };

  const fetchEvents = async filter => {
    const sort = { timestamp: -1 };
    const { events } = await API.getEvents({ type: 'DefaultEvent', name: filter || undefined }, sort, MAX_NB_EVENTS_ONE_PAGE * MAX_NB_PAGES);
    setEventsList(events);
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('event', handleSocketEvent);
    }
    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
      }
    };
  }, [socket]);

  const filterEvents = events => events.filter(event => (
    event.name && event.name
      .toUpperCase()
      .indexOf(filterText.toUpperCase()) > -1
  ));

  const handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    fetchEvents(filterText);
  };

  return (
    <div className="list">
      <EventsList
        columnNames={['name', 'createdAt']}
        entriesProperties={['name', 'startDate']}
        filteredList={filterEvents(eventsList)}
        storageName="K2_default_events"
        leftHeader={(
          <form className="searchContainer" onSubmit={handleSubmit}>
            <TextInput
              options={eventNames}
              className="inputFilter"
              value={filterText}
              onChange={e => setFilterText(e)}
              style={{ marginLeft: 0 }}
              placeholder={t('eventName')}
            />
            <SubmitButton
              label={t('Search')}
            />
          </form>
        )}
      />
    </div>
  );
};

DefaultEventsList.propTypes = {
  setDefaultNames: PropTypes.func.isRequired,
};

export default DefaultEventsList;
