import React from 'react';
import PropTypes from 'prop-types';
import { getParameterIcon } from '~components/MachineState';
import { FontAwesome } from '~UI/index';

const MachineParameter = ({ parameter, fontSize }) => (
  <div
    style={{
      fontSize,
    }}
    key={parameter.name}
  >
    <FontAwesome icon={getParameterIcon(parameter.name)} />
      &nbsp;
    {parameter.value}
  </div>
);

MachineParameter.propTypes = {
  parameter: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  fontSize: PropTypes.number.isRequired,
};

export { MachineParameter };
