const simpleEval = (value1, comparator, value2) => {
  switch (comparator) {
    case '==':
      return value1 === value2;
    case '<':
      return value1 < value2;
    case '>':
      return value1 > value2;
    case '!=':
      return value1 !== value2;
    case '<=':
      return value1 <= value2;
    case '>=':
      return value1 >= value2;
    default:
      return false;
  }
};

export default simpleEval;
