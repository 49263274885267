import API from '~services/endpoints';

const castToType = (value, type) => {
  if (value === undefined) {
    return value;
  }
  switch (type) {
    case 'string':
      return `${value}`;
    case 'number':
      return Number(value);
    case 'boolean':
      return !!value;
    default:
      return value;
  }
};

const schemaIsRef = elem => elem && typeof elem === 'object' && typeof elem.ref === 'string';
const schemaToRef = ref => ref.ref;
const stringIsRef = str => str && typeof str === 'string' && /^\$\{[A-Za-z][A-Za-z0-9_/-]*\}$/g.test(str);
const stringToRef = ref => ref.substring(2, ref.length - 1);

/**
 * For things that can be get before the user starts to fill the form
 */
const getRef = (ref, src, params, formData) => {
  const refs = ref.split('/');
  if (refs[0] === 'variables') {
    if (refs[1]) {
      // specific variable, let's fetch it in `formToPayload`
      return `\${variables/${refs[1]}}`;
    }
    // array of variables
    return src.variables.map(v => ({
      label: v.label,
      value: `\${variables/${v.id}}`,
    }));
  }
  if (refs[0] === 'parameters') {
    if (refs[1]) {
      // specific param
      return params[refs[1]];
    }
    // array of parameters
    return Object.entries(params).map(([key, value]) => ({
      label: key,
      value,
    }));
  }
  if (refs[0] === 'operators') {
    const machineId = (formData && formData.machineId) || (params && params.machineId) || src.machines[0].id;
    const machine = src.machines.find(m => m.id === machineId);
    const operators = machine && machine.operators.map(u => ({
      value: u.name,
      label: u.name,
    }));

    return operators;
  }
  if (refs[0] === 'machines') {
    return src.machines;
  }
  if (refs[0] === 'events') {
    const machineId = (formData && formData.machineId) || (params && params.machineId) || src.machines[0].id;
    const machine = src.machines.find(m => m.id === machineId);
    const stopCauses = machine && machine.stopCauses.map(e => ({
      ...e,
      value: e.name,
      enum: e.subMenu.map(e2 => ({
        ...e2,
        value: e2.name,
      })),
    }));
    return stopCauses;
  }
  if (formData && refs[0] === 'fields' && refs[1]) {
    return formData[refs[1]];
  }
  return undefined;
};

/**
 * For things that is better to fetch directly from the server on submit such as variables
 */
const fetchRef = async ref => {
  const refs = ref.split('/');
  if (refs[0] === 'variables') {
    if (refs[1]) {
      // specific variable
      return API.getValues(refs[1], {}, 1).then(res => (res.values.length ? res.values[0].value : undefined));
    }
    throw Error('missing variable id');
  }
  throw Error(`unsupported ref: ${refs[0]}`);
};

export const schemaToForm = (jsonSchema, dataSource, params, formData) => ({
  ...jsonSchema,
  fields: jsonSchema.fields.map(field => {
    const ret = { ...field };
    // use default values if not specified
    ret.type = field.type || 'string';
    ret.label = field.label || field.id;
    ret.ui = field.ui || {};

    Object.keys(ret).forEach(key => {
      // parse references to their values
      if (schemaIsRef(ret[key])) {
        ret[key] = getRef(schemaToRef(ret[key]), dataSource, params, formData);
      }
    });
    return ret;
  }),
});

export const formToPayload = async (formData, jsonForm) => {
  const ret = { ...formData };
  for (const { id, type } of jsonForm.fields) {
    if (stringIsRef(ret[id])) {
      // TODO: find a better way to await in parallel
      ret[id] = await fetchRef(stringToRef(ret[id]));
    }
    ret[id] = castToType(ret[id], type);
  }
  return ret;
};

export const parseExpression = (expression, { src, params, formData }) => {
  if (schemaIsRef(expression)) {
    return getRef(schemaToRef(expression), src, params, formData);
  }
  return expression;
};
