enum Types {
  GET_USER_REQUEST = 'GET_USER_REQUEST',
  GET_USER_RESPONSE = 'GET_USER_RESPONSE',
  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_RESPONSE = 'GET_USERS_RESPONSE',
  ADD_USER_REQUEST = 'ADD_USER_REQUEST',
  ADD_USER_RESPONSE = 'ADD_USER_RESPONSE',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE',
  UPDATE_VERIFY_USER_REQUEST = 'UPDATE_VERIFY_USER_REQUEST',
  UPDATE_VERIFY_USER_RESPONSE = 'UPDATE_VERIFY_USER_RESPONSE',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_RESPONSE = 'DELETE_USER_RESPONSE',
  GET_ALL_USERS_PERMISSIONS_REQUEST = 'GET_ALL_USERS_PERMISSIONS_REQUEST',
  GET_ALL_USERS_PERMISSIONS_RESPONSE = 'GET_ALL_USERS_PERMISSIONS_RESPONSE',
  GET_USER_PERMISSIONS_REQUEST = 'GET_USER_PERMISSIONS_REQUEST',
  GET_USER_PERMISSIONS_RESPONSE = 'GET_USER_PERMISSIONS_RESPONSE',

  // contents permissions update
  ADD_DASHBOARD_RESPONSE = 'ADD_DASHBOARD_RESPONSE',
  DELETE_DASHBOARD_RESPONSE = 'DELETE_DASHBOARD_RESPONSE',
  UPDATE_USERS_CONTENTS_PERMISSIONS_REQUEST = 'UPDATE_USERS_CONTENTS_PERMISSIONS_REQUEST',
  UPDATE_USERS_CONTENTS_PERMISSIONS_RESPONSE = 'UPDATE_USERS_CONTENTS_PERMISSIONS_RESPONSE',

  SEND_WELCOME_EMAIL_REQUEST = 'SEND_WELCOME_EMAIL_REQUEST',
  SEND_WELCOME_EMAIL_RESPONSE = 'SEND_WELCOME_EMAIL_RESPONSE',
}

export enum UserRole {
  DIRECTOR_MANAGER = 'DIRECTOR_MANAGER',
  SUPERVISION = 'SUPERVISION',
}

export default Types;
