import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle, TextInput } from '~UI';
import { idToReadableExpr, variableToId } from '~utils/parser';
import { sortArray } from '~utils/sort';
import './EntriesForm.scss';

/**
 * Note: this is a fully controlled component, so the parent is responsible to
 * provide a non empty params as well as handling the last empty entry if applicable
 */
const EntriesForm = ({ params, showReadOnly, onChange, listOfIndexesToShow }) => {
  const { t } = useTranslation();

  const language = useSelector(state => state.views.language);
  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);

  const removeIndexFromList = (list, index, decrement) => {
    if (decrement) {
      list.filter(elem => elem > index).forEach(e => {
        list[list.indexOf(e)] -= 1;
      });
    } else {
      list.splice(list.indexOf(index), 1);
    }
  };

  const idToVarCatched = (newValue, variableList) => {
    let valueToReturn = newValue;
    try {
      valueToReturn = variableToId(newValue, variableList);
    } catch (error) {
      // Error handling handling of non existent variable is made by the component that holds this form
      // This way the error message is popped only on submit and not on change
    }
    return valueToReturn;
  };

  const handleValueChange = (newValue, index) => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    params[index] = {
      key: params[index].key || '',
      value: idToVarCatched(newValue, inputsAndVariables),
      readOnly: params[index].readOnly,
    };
    onChange([...params]);
  };

  const handleKeyChange = ({ target: { value: newKey } }, index) => {
    if (params[index].key.toLowerCase() === 'machineid' && newKey.toLowerCase() !== 'machineid' && listOfIndexesToShow) {
      removeIndexFromList(listOfIndexesToShow, index, false);
    }
    if (index === params.length - 1) {
      params.push({ key: '', value: '', readOnly: showReadOnly ? false : undefined });
    } else if (newKey === '' && listOfIndexesToShow) {
      removeIndexFromList(listOfIndexesToShow, index, true);
      params.splice(index, 1);
    }
    if (listOfIndexesToShow && newKey.toLowerCase() === 'machineid') {
      handleValueChange(machines[0].id, index);
      listOfIndexesToShow.push(index);
    }

    if (index === params.length - 1 || newKey !== '') {
      params[index] = {
        key: newKey,
        value: params[index].value || '',
        readOnly: params[index].readOnly,
      };
    }
    onChange([...params]);
  };

  const handleReadOnlyChange = (e, index) => {
    params[index] = {
      key: params[index].key || '',
      value: params[index].value || '',
      readOnly: e.target.value,
    };

    onChange([...params]);
  };

  const idToReadable = value => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = [...inputProperties, ...variables, ...kpis];
    return idToReadableExpr(value, inputsAndVariables);
  };

  const getOptions = () => sortArray('alphabetically', machines, 'name')
    .map(machine => ({ label: machine.name, value: machine.id }));

  const getEntry = (name, index) => {
    const inputProperties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const inputsAndVariables = sortArray('alphabetically', [...inputProperties, ...variables, ...kpis], 'variable').map(x => x.variable);
    inputsAndVariables.splice(0, 0, 'NOW');

    return (
      <div className="EntriesFormElement" key={`e${index}`}>
        <div className={`entries ${showReadOnly && `showReadOnly${language}`}`}>
          <input
            type="text"
            className="paramInput"
            placeholder={t('parameter')}
            value={name.key}
            onChange={e => handleKeyChange(e, index)}
          />
          {listOfIndexesToShow && listOfIndexesToShow.includes(index)
            ? (
              <Select
                style={{ display: 'inline', width: '100%' }}
                className="valueInput"
                value={params[index].value}
                onChange={e => { handleValueChange(e, index); }}
                options={getOptions()}
              />

            ) : (
              <TextInput
                options={inputsAndVariables}
                trigger="$"
                value={name.value && idToReadable(name.value)}
                className="valueInput"
                onChange={e => handleValueChange(e, index)}
                placeholder={`${t('value')}: ${t('triggerVariableList')}`}
              />
            )
          }
        </div>
        {
          showReadOnly && (
            <div className={language}>
              &nbsp;&nbsp;&nbsp;
              {t('readOnly')}
              &nbsp;&nbsp;
              <CheckboxToggle
                onChange={e => handleReadOnlyChange(e, index)}
                defaultChecked={name.readOnly}
              />
            </div>
          )
        }
      </div>
    );
  };

  return params.map((element, index) => getEntry(element, index));
};

EntriesForm.propTypes = {
  params: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
  })).isRequired,
  showReadOnly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  listOfIndexesToShow: PropTypes.arrayOf(PropTypes.number),
};

EntriesForm.defaultProps = {
  showReadOnly: false,
  listOfIndexesToShow: [],
};

export default EntriesForm;
