import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import omit from 'lodash.omit';

import { useTranslation } from 'react-i18next';
import { SelectionGrid } from '~components/SelectionGrid';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import directLinks from '~utils/directLinks';
import { configurationFeature } from '~utils/featureToggles';
import { showSuccess } from '~utils/toast';
import { idsToTags } from '~utils/tags';

import DashboardPopUpForm from '../DashboardPopUpForm';

const DashboardSelectionGrid = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const users = useSelector((state: RootState) => state.users.users);

  const deleteDashboard = dashboardId => reduxOperations.dashboards.deleteDashboard(dashboardId)(dispatch);

  const updateUsersPermissions = (id, usersArg) => {
    if (configurationFeature.isUserAllowedAccessUsers()) {
      const permissionsData = {
        users: usersArg.map(user => user.value),
        content: {
          type: 'Dashboard',
          id,
        },
      };
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleDuplication = dashboard => {
    let usersWithPermission;

    if (dashboard.id) {
      usersWithPermission = users
        .reduce((result, user) => {
          const userHasDashboardAccess = user.permissions.contents.some(content => content.type === 'Dashboard' && content.id === dashboard.id);
          return userHasDashboardAccess ? [...result, { value: user.id }] : result;
        }, []);
    }

    const newDashboard = Object.assign(
      omit(dashboard, ['id', 'createdAt', 'modifiedAt']),
      { name: `${dashboard.name} (${t('Copy')})` },
      { tags: idsToTags(dashboard.tags || [], tags) },
    );
    if (dashboard.tiles) {
      newDashboard.tiles = dashboard.tiles.map(tile => (
        Object.assign(
          omit(tile, ['i', 'id', '_id']),
          {
            rules: (tile.rules || []).map(r => omit(r, ['id'])),
          },
        )
      ));
    }

    reduxOperations.dashboards.addDashboard(newDashboard)(dispatch).then(res => {
      showSuccess(t('showSuccessCreated'));
      updateUsersPermissions(res.payload.dashboard.id, usersWithPermission);
      // commandsSave({
      //   undoAction: { action: deleteDashboard, params: [res.dashboard.id] },
      //   redoAction: { action: addDashboard, params: [newDashboard] },
      // });
    });
  };

  const getDashboards = () => {
    const dashboardsItems = dashboards?.map(dashboard => ({
      ...dashboard,
      createdByUser: users.find(user => user.id === dashboard.createdBy)?.name,
      modifiedByUser: users.find(user => user.id === dashboard.modifiedBy)?.name,
    }));
    return dashboardsItems;
  };

  const isUserAllowedDashboardConfig = configurationFeature.isUserAllowedAccessDashboards();

  return (
    <SelectionGrid
      contentType="Dashboard"
      deleteFunction={isUserAllowedDashboardConfig && deleteDashboard}
      directLinkType={directLinks.dashboard}
      EditForm={isUserAllowedDashboardConfig && DashboardPopUpForm}
      handleDuplication={isUserAllowedDashboardConfig && handleDuplication}
      isUserAllowedToConfig={isUserAllowedDashboardConfig}
      items={getDashboards() as object[]}
      redirectRessource="dashboard"
      storageType="K2_dashboards"
      supportFavorites
      tagInfoText={t('tagInfoDashboard')}
      titleCreateCard={t('addDashboard')}
    />
  );
};

export default DashboardSelectionGrid;
