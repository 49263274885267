enum Types {
  GET_TAGS_REQUEST = 'GET_TAGS_REQUEST',
  GET_TAGS_RESPONSE = 'GET_TAGS_RESPONSE',
  ADD_TAG_REQUEST = 'ADD_TAG_REQUEST',
  ADD_TAG_RESPONSE = 'ADD_TAG_RESPONSE',
  UPDATE_TAG_REQUEST = 'UPDATE_TAG_REQUEST',
  UPDATE_TAG_RESPONSE = 'UPDATE_TAG_RESPONSE',
  DELETE_TAG_REQUEST = 'DELETE_TAG_REQUEST',
  DELETE_TAG_RESPONSE = 'DELETE_TAG_RESPONSE',
}

export default Types;
