import React from 'react';
import { useDrop } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { NavLink } from '@mantine/core';
import { sortArray } from '~utils/sort';
import { RootState } from '~services/store';
import FontAwesome from '~components/UI/FontAwesome';
import reduxOperations from '~services/reduxOperations';
import { showSuccess } from '~utils/toast';
import { idsToTags } from '~utils/tags';
import { ItemNavLink } from './ItemNavLink';

const CHILDREN_OFFSET = 35;

const WorkspaceFolder = ({
  folder, folderItems, getFolderItems, searchInput, disabled, handleDelete, icon, isSubfolder,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const reports = useSelector((state: RootState) => state.reports.reports);
  const tags = useSelector((state: RootState) => state.tags.tags);

  const handleDrop = (item, folderId, monitor) => {
    if (monitor.didDrop() && !isSubfolder) {
      return;
    }

    const getInterface = () => {
      switch (item.type) {
        case 'dashboards': return dashboards;
        case 'topviews': return topviews;
        case 'reports': return reports;
        default: break;
      }
    };

    const itm = getInterface()?.find(i => i.id === item.id);
    if (itm) {
      const itmTags = idsToTags(itm.tags, tags);
      const updateAction = reduxOperations[item.type][`update${item.type.charAt(0).toUpperCase() + item.type.slice(1, -1)}`];
      updateAction(item.id, { ...itm, tags: itmTags, folderId })(dispatch)
        .then(() => showSuccess(t('showSuccessUpdated')));
    }
  };

  const [{ isOverCurrent }, drop] = useDrop({
    accept: ['dashboards', 'topviews', 'reports'],
    drop: (item, monitor) => handleDrop(item, folder ? folder.id : '', monitor),
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  });

  const handleDeleteSubfolder = (parentFolder, subfolder) => {
    if (!parentFolder) {
      return;
    }

    reduxOperations.folders.deleteSubfolder(parentFolder.id, subfolder.id)(dispatch);

    dashboards?.filter(dash => dash.folderId === subfolder.id)
      .forEach(item => reduxOperations.dashboards.updateDashboard(item.id, { ...item, folderId: '' })(dispatch));

    topviews?.filter(tv => tv.folderId === subfolder.id)
      .forEach(item => reduxOperations.topviews.updateTopview(item.id, { ...item, folderId: '' })(dispatch));

    reports?.filter(rep => rep.folderId === subfolder.id)
      .forEach(item => reduxOperations.reports.updateReport(item.id, { ...omit(item, ['id', 'modifiedAt', 'modifiedBy']), folderId: '' })(dispatch));

    localStorage.removeItem(`.${subfolder.name}_${subfolder.id}Folder`);
  };

  const background = isOverCurrent ? '#707070' : 'transparent';

  return (
    <div className={`workspaceRoot${isSubfolder ? ' subfolder' : ''}`} ref={drop} style={{ background }}>
      {folder && (
        <>
          <ItemNavLink
            className="editFolderMenu"
            offset={30}
            id={folder.id}
            type={isSubfolder ? 'subfolders' : 'folders'}
            disabled={disabled}
            handleDelete={handleDelete}
            target={<div className="editFolderIcon"><FontAwesome icon="ellipsis-h" /></div>}
          />
          <NavLink
            className="root"
            label={folder.name}
            icon={icon}
            childrenOffset={CHILDREN_OFFSET}
            defaultOpened={localStorage.getItem(`.${folder.name}_${folder.id}Folder`) === 'opened'}
            onChange={e => localStorage.setItem(`.${folder.name}_${folder.id}Folder`, e ? 'opened' : 'closed')}
          >
            {sortArray('alphabetically', folder.subfolders, 'name')?.map(subfolder => {
              const subfolderItems = getFolderItems(subfolder.id, subfolder.name) || [];
              return (
                <WorkspaceFolder
                  key={subfolder.id}
                  folder={subfolder}
                  folderItems={subfolderItems}
                  getFolderItems={getFolderItems}
                  searchInput={searchInput}
                  disabled={disabled}
                  handleDelete={() => handleDeleteSubfolder(folder, subfolder)}
                  icon={<FontAwesome icon="folder" style={{ color: `${subfolder.color || 'white'}` }} />}
                  isSubfolder
                />
              );
            })}
            {folderItems}
          </NavLink>
        </>
      )}
      {!folder && folderItems}
    </div>
  );
};

WorkspaceFolder.propTypes = {
  folder: PropTypes.object,
  folderItems: PropTypes.arrayOf(PropTypes.element).isRequired,
  getFolderItems: PropTypes.func,
  searchInput: PropTypes.string,
  disabled: PropTypes.bool,
  handleDelete: PropTypes.func,
  icon: PropTypes.element,
  isSubfolder: PropTypes.bool,
};

WorkspaceFolder.defaultProps = {
  folder: null,
  searchInput: '',
  disabled: false,
  handleDelete: null,
  icon: null,
  isSubfolder: false,
  getFolderItems: undefined,
};

export default WorkspaceFolder;
