import io, { Socket } from 'socket.io-client';
import StopwatchManager from '~utils/StopwatchManager';

let socket: Socket | null = null;

export function initializeSocket() {
  socket = io();
  socket.connect();
  socket.on('connect', () => {
    console.info('Socket connected');
    StopwatchManager.setupSWManager(socket);
  });
  socket.on('disconnect', () => {
    console.info('Socket disconnected');
  });
  socket.on('error', error => {
    console.error('Socket error', error);
  });
}

export function getSocket(): Socket | null {
  return socket;
}

export function getSocketInternal(
  resolve: (s: Socket) => void,
  reject: (error: string) => void,
  count: number,
): void {
  if (socket) {
    resolve(socket);
  }
  if (count > 10) {
    reject("Couldn't obtain socket after 10 tries");
  }
  setTimeout(() => {
    getSocketInternal(resolve, reject, count + 1);
  }, 500);
}

export function getSocketAsync(): Promise<Socket | null> {
  return new Promise((resolve, reject) => {
    getSocketInternal(resolve, reject, 0);
  });
}
