import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services/index';
import { showSuccess } from '~utils/toast';
import FontAwesome from '~UI/FontAwesome/FontAwesome';
import { SelectionForm } from '~components/Popups/FormPopup';
import { FavoriteIcon } from '~UI/IconButtons';
import ModalHandler from '~components/UI/ModalHandler';
import { RootState } from '~services/store';

const MAX_FAVORITES = 4;

const FavoritesHandler = ({
  isConfigurationEnabled,
  favoriteType,
  favoriteId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dashboards = useSelector((state: RootState) => state.dashboards.dashboards);
  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const reports = useSelector((state: RootState) => state.reports.reports);
  const favorites = useSelector((state: RootState) => state.users.selectedUser.favorites);
  const userId = useSelector((state: RootState) => (state.session.connexion.infos.id));

  const [isFavoriteState, setIsFavorite] = useState(false);

  const handleFavoriteChange = favoriteToRemoveId => {
    const newFavorites = { ...favorites };
    let confirmationMessage;
    let isFavorite;

    if (favorites[favoriteType].includes(favoriteId)) {
      newFavorites[favoriteType] = favorites[favoriteType].filter(f => f !== favoriteId);
      confirmationMessage = t('favoriteRemovedSuccessfully');
      isFavorite = false;
    } else {
      newFavorites.dashboards = favorites.dashboards.filter(f => f !== favoriteToRemoveId);
      newFavorites.reports = favorites.reports.filter(f => f !== favoriteToRemoveId);
      newFavorites.topviews = favorites.topviews.filter(f => f !== favoriteToRemoveId);
      newFavorites.pages = favorites.pages.filter(f => f !== favoriteToRemoveId);

      newFavorites[favoriteType].push(favoriteId);
      confirmationMessage = favoriteToRemoveId
        ? t('favoriteReplacedSuccessfully')
        : t('favoriteAddedSuccessfully');
      isFavorite = true;
    }

    reduxOperations.users.updateUser({ favorites: newFavorites }, userId)(dispatch).then(() => {
      setIsFavorite(isFavorite);
      showSuccess(confirmationMessage);
    });
  };

  const getPagesTiles = () => [
    {
      id: 'config',
      name: t('configuration'),
      icon: 'gear',
    },
    {
      id: 'rulesEngine',
      name: t('rulesEngine'),
      icon: 'gear',
      img: 'https://i.ibb.co/L5NMKzv/Capture-du-2020-02-25-15-14-26.png',
    },
    {
      id: 'events',
      name: t('events'),
      icon: 'list',
    },
    // events and machines stoppages used to be separated pages
    // stoppages now redirects to events for backwards compatibility
    // with users who had previously bookmarked machines stoppages page
    {
      id: 'stoppages',
      name: t('machinesStatus'),
      icon: 'exclamation-circle',
    },
  ];

  const getFavoriteSelectionGridContent = () => {
    const pagesTiles = getPagesTiles();

    const favoritesPages = favorites
      ? favorites.pages
        .map(favoritePage => pagesTiles.find(p => p.id === favoritePage))
        .filter(favoritePage => !!favoritePage)
      : [];

    const favoritesDashboards = favorites && favorites.dashboards && dashboards
      ? favorites.dashboards
        .map(favoriteDashboard => dashboards.find(dash => dash.id === favoriteDashboard))
        .filter(favoriteDashboard => !!favoriteDashboard)
        .map(favoriteDashboard => ({
          id: favoriteDashboard.id,
          name: favoriteDashboard.name,
          img: favoriteDashboard.backgroundURL,
        }))
      : [];

    const favoritesReports = favorites && favorites.reports && reports
      ? favorites.reports
        .map(favoriteReport => reports.find(rep => rep.id === favoriteReport))
        .filter(favoriteReport => !!favoriteReport)
        .map(favoriteReport => ({
          id: favoriteReport.id,
          name: favoriteReport.name,
        }))
      : [];

    const favoritesTopviews = favorites && favorites.topviews && topviews
      ? favorites.topviews
        .map(favoriteTopview => topviews.find(tv => tv.id === favoriteTopview))
        .filter(favoriteTopview => !!favoriteTopview)
        .map(favoriteTopview => ({
          id: favoriteTopview.id,
          name: favoriteTopview.name,
          img: favoriteTopview.backgroundURL,
        }))
      : [];

    return [...favoritesPages, ...favoritesDashboards, ...favoritesReports, ...favoritesTopviews];
  };

  const favoriteSelectModal = content => ({
    Component: SelectionForm,
    props: {
      handleAction: topviewToDeleteId => handleFavoriteChange(topviewToDeleteId),
      actionLabel: t('replace'),
      title: t('selectAFavorite'),
      description: t('favoriteReplacementWarning'),
      content,
    },
  });

  const favoriteSelectTriggerComponent = ({ isFavorite, onClick }) => (
    <div className="cardDropdownItem" onClick={onClick}>
      <span className="cardDropdownItemText">{t('favorite')}</span>
      <FontAwesome icon="star" color={isFavorite ? 'gold' : 'grey'} />
    </div>
  );

  const favoriteSelectModalContent = getFavoriteSelectionGridContent();
  const shouldPromptFavoriteSelectModal = favoriteSelectModalContent.length >= MAX_FAVORITES && !isFavoriteState;

  useEffect(() => {
    setIsFavorite(favorites[favoriteType].includes(favoriteId));
  }, [favorites, favoriteType, favoriteId]);

  return (
    <ModalHandler
      Trigger={{
        Component: isConfigurationEnabled ? favoriteSelectTriggerComponent : FavoriteIcon,
        props: {
          isFavorite: isFavoriteState,
          onClick: !shouldPromptFavoriteSelectModal ? () => handleFavoriteChange(null) : undefined,
        },
      }}
      Modal={favoriteSelectModal(favoriteSelectModalContent)}
      shouldPromptModal={shouldPromptFavoriteSelectModal}
    />
  );
};

FavoritesHandler.propTypes = {
  isConfigurationEnabled: PropTypes.bool,
  favoriteType: PropTypes.oneOf(['dashboards', 'reports', 'topviews']).isRequired,
  favoriteId: PropTypes.string.isRequired,
};

FavoritesHandler.defaultProps = {
  isConfigurationEnabled: false,
};

export default FavoritesHandler;
