import permissionsId from '../featuresTypes';
import getPermissionState from '../reduxStore/getPermissionState';

const isUserAllowedAccessDashboards = () => getPermissionState(permissionsId.DASHBOARDS_EDIT);
const isUserAllowedAccessFolders = () => getPermissionState(permissionsId.FOLDERS_EDIT);
const isUserAllowedAccessMachines = () => getPermissionState(permissionsId.MACHINES_EDIT);
const isUserAllowedAccessOperations = () => getPermissionState(permissionsId.OPERATIONS_EDIT);
const isUserAllowedAccessReports = () => getPermissionState(permissionsId.REPORTS_EDIT);
const isUserAllowedAccessRuleEngine = () => getPermissionState(permissionsId.TRIGGERS_EDIT);
const isUserAllowedAccessSettings = () => getPermissionState(permissionsId.ADMIN);
const isUserAllowedAccessShifts = () => getPermissionState(permissionsId.SHIFTS_EDIT);
const isUserAllowedAccessStopwatches = () => getPermissionState(permissionsId.STOPWATCHES_EDIT);
const isUserAllowedAccessStreams = () => getPermissionState(permissionsId.STREAMS_EDIT)
|| getPermissionState(permissionsId.STREAMS_READ);
const isUserAllowedAccessTopviews = () => getPermissionState(permissionsId.TOPVIEWS_EDIT);
const isUserAllowedAccessUsers = () => getPermissionState(permissionsId.USERS_EDIT);
const isUserAllowedAccessVariables = () => getPermissionState(permissionsId.VARIABLES_EDIT);
const isUserAllowedAccessLogs = () => getPermissionState(permissionsId.LOGS_EDIT);
const isUserAllowedAccessAdmin = () => getPermissionState(permissionsId.ADMIN);

const isUserAllowedAccess = () => (
  isUserAllowedAccessDashboards()
  || isUserAllowedAccessFolders()
  || isUserAllowedAccessLogs()
  || isUserAllowedAccessMachines()
  || isUserAllowedAccessOperations()
  || isUserAllowedAccessReports()
  || isUserAllowedAccessRuleEngine()
  || isUserAllowedAccessSettings()
  || isUserAllowedAccessShifts()
  || isUserAllowedAccessStopwatches()
  || isUserAllowedAccessStreams()
  || isUserAllowedAccessTopviews()
  || isUserAllowedAccessUsers()
  || isUserAllowedAccessVariables()
  || isUserAllowedAccessAdmin()
);

export default {
  isUserAllowedAccess,
  isUserAllowedAccessAdmin,
  // alphabetical order
  isUserAllowedAccessDashboards,
  isUserAllowedAccessFolders,
  isUserAllowedAccessLogs,
  isUserAllowedAccessMachines,
  isUserAllowedAccessOperations,
  isUserAllowedAccessReports,
  isUserAllowedAccessRuleEngine,
  isUserAllowedAccessSettings,
  isUserAllowedAccessShifts,
  isUserAllowedAccessStopwatches,
  isUserAllowedAccessStreams,
  isUserAllowedAccessTopviews,
  isUserAllowedAccessUsers,
  isUserAllowedAccessVariables,
};
