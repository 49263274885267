const triggerTypes = {
  DEFAULT: 'DefaultTrigger',
  BUTTON: 'ButtonTrigger',
  CRON: 'CronTrigger',
  MACHINE_PARAM_CHANGE: 'MachineParamChangeTrigger',
  STOP_CAUSE_ASSIGN: 'StopCauseAssignTrigger',
  STOP_CAUSE: 'StopCauseTrigger',
  VALUE_CHANGE: 'ValueChangeTrigger',
  VALUE_SET: 'ValueSetTrigger',
  VALUE: 'ValueTrigger',
  WEBHOOK: 'WebhookTrigger',
};

Object.freeze(triggerTypes);

export default triggerTypes;
