import API from '~services/endpoints';

const subscribePushNotification = (userId, subscription) => fetch(`/api/v1/users/${userId}/notifications`, {
  method: 'POST',
  body: JSON.stringify({
    pushSubscriptionObject: JSON.stringify(subscription),
  }),
  headers: {
    'Content-Type': 'application/json',
  },
});

const unsubscribePushNotification = (userId, subscription) => fetch(`/api/v1/users/${userId}/notifications`, {
  method: 'POST',
  body: JSON.stringify({
    pushSubscriptionObject: JSON.stringify(subscription),
  }),
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function coded by Google
async function urlBase64ToUint8Array() {
  const { settings } = await API.getSettings();
  const base64String = settings.publicVapidKey;
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const subscribeUser = async userId => {
  const convertedVapidKey = await urlBase64ToUint8Array();
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      if (!registration.pushManager) {
        console.error('Push manager unavailable.');
        return;
      }

      registration.pushManager.getSubscription().then(() => {
        registration.pushManager.subscribe({
          applicationServerKey: convertedVapidKey,
          userVisibleOnly: true,
        }).then(newSubscription => {
          if (userId) {
            subscribePushNotification(userId, newSubscription);
          }
        }).catch(e => {
          if (Notification.permission !== 'granted') {
            console.error('Permission was not granted.');
          } else {
            console.error('An error occurred during the subscription process.', e);
          }
        });
      });
    })
      .catch(e => {
        console.error('An error occurred during Service Worker registration.', e);
      });
  }
};

const unsubscribeUser = userId => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.pushManager.getSubscription().then(subscription => {
        if (subscription) {
          subscription.unsubscribe().then(() => {
            if (userId) {
              unsubscribePushNotification(userId, subscription);
            }
          }).catch(e => {
            console.error('Unsubscription failed', e);
          });
        }
      });
    });
  }
};

export {
  subscribeUser,
  unsubscribeUser,
};
