import i18n from 'i18next';
import store from '~services/store';
import { formatDuration, dateToString } from '~utils/time';

export const eventsToReadable = (events, machines: any[] | null = null) => {
  const storeState = store.getState();
  const { language } = storeState.views;

  const sortedEvents = [...events].sort((eventA, eventB) => eventA.timestamp - eventB.timestamp);
  return sortedEvents.map((event, i, eventsList) => {
    const nextEvent = eventsList.find(e => e.timestamp > event.timestamp
      && e.type === event.type
      && e.machineId === event.machineId);
    let state;
    switch (event.status) {
      case 'ON': {
        state = i18n.t('operating');
        break;
      }
      case 'OFF': {
        state = i18n.t('stopped');
        break;
      }
      case 'START': {
        state = i18n.t('performanceDrop');
        break;
      }
      default: {
        state = '-';
      }
    }

    let motif;
    switch (language) {
      case 'en': {
        motif = event.stopCauseEN || event.motif;
        break;
      }
      case 'fr': {
        motif = event.stopCauseFR || event.motif;
        break;
      }
      case 'es': {
        motif = event.stopCauseES || event.motif;
        break;
      }
      default: {
        motif = event.motif;
      }
    }

    return {
      ...event,
      createdAt: dateToString(new Date(event.createdAt)),
      startDate: dateToString(new Date(event.timestamp)),
      endDate: nextEvent ? dateToString(new Date(nextEvent.timestamp)) : '-',
      duration: nextEvent ? formatDuration(nextEvent.timestamp - event.timestamp, {
        year: true, month: true, day: true, hourSeparator: 'h ', minSeparator: 'min ', secSeparator: 'sec',
      }, true, language) : '-',
      state,
      motif,
      machineName: machines ? (machines.find(machine => event.machineId === machine.id) || {}).name : undefined,
    };
  });
};

export const eventsToUptime = (events, startTS, endTS) => {
  let onTime = 0;
  let totalTime = 0;

  const sortedEvents = [...events].sort((eventA, eventB) => eventA.timestamp - eventB.timestamp);
  // Remove all events that end before startTS
  while (sortedEvents[1] && sortedEvents[1].timestamp <= startTS) {
    sortedEvents.shift();
  }
  // Remove all events that start after endTS
  while (sortedEvents.length > 0 && sortedEvents[sortedEvents.length - 1].timestamp >= endTS) {
    sortedEvents.pop();
  }

  // Loop through events to calculate uptime
  for (let i = 0; i < sortedEvents.length; i += 1) {
    const start = (i === 0) ? Math.max(startTS, sortedEvents[i].timestamp) : sortedEvents[i].timestamp;
    const end = (i === sortedEvents.length - 1) ? endTS : sortedEvents[i + 1].timestamp;
    const duration = end - start;

    if (duration > 0) {
      if (sortedEvents[i].status === 'ON') {
        onTime += duration;
      }
      totalTime += duration;
    }
  }
  return totalTime ? onTime / totalTime : 0;
};

export const eventsTimeToDate = events => events.map(event => ({
  ...event,
  date: dateToString(new Date(event.timestamp)),
}));

export default {
  eventsToReadable,
  eventsToUptime,
  eventsTimeToDate,
};
