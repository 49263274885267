import types from './types';

const getLinkRedirectionRequest = linkId => ({
  type: types.GET_LINK_REDIRECTION_REQUEST,
  payload: linkId,
});

const getLinkRedirectionResponse = (data, request = null) => ({
  type: types.GET_LINK_REDIRECTION_RESPONSE,
  payload: data,
  request,
});

const updateLinkRedirection = data => ({
  type: types.UPDATE_LINK_REDIRECTION,
  payload: data,
});

export {
  getLinkRedirectionRequest,
  getLinkRedirectionResponse,
  updateLinkRedirection,
};
