import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GithubPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { invertColor } from '~utils';
import { reducersTypes } from '~services';
import { showError } from '~utils/toast';

import FontAwesome from '../FontAwesome';
import './TagsSelection.scss';

const colors = ['#F44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3',
  '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39',
  '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b',
];

const TagInputButton = ({
  tags, tag, deleteAction, updateTag, openDropdown,
}) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [tagName, setTagName] = useState(tag.name);
  const [tagColor, setTagColor] = useState(tag.color || '#333');
  const [isTagValid, setIsTagValid] = useState(true);
  const [isModified, setIsModified] = useState(false);

  // Also returns true if the base event is the dropdown (and not the parent)
  const findIfParentIsDropdown = event => {
    let currentDiv = event.target;
    let isParentDropdown = false;
    while (currentDiv) {
      if (typeof currentDiv.id === 'string') {
        if (currentDiv.id.includes('tagDropdown') || currentDiv.id.includes('tagDropdownButton')) {
          isParentDropdown = true;
        }
      }
      currentDiv = currentDiv?.parentElement;
    }
    return isParentDropdown;
  };

  const handleClickOutside = event => {
    if (!findIfParentIsDropdown(event)) {
      openDropdown(false);
      setShowDropdown(false);
      setIsModified(false);
      setTagName(tag.name);
      setTagColor(tag.color || '#333');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const submitTag = () => {
    if (!isTagValid) {
      showError(t('tagNameExistsError'));
      return;
    }
    updateTag({ id: tag.id, name: tagName, color: tagColor });
    openDropdown(false);
    setShowDropdown(false);
    setIsModified(false);
  };

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitTag();
    }
  };

  const handleNameChange = e => {
    const newTagName = e.currentTarget.value.replace(' ', '');
    const sameName = tags.find(tagArg => tagArg.name === newTagName && t.id !== tag.id);

    setTagName(newTagName);
    setIsTagValid(sameName === undefined);
  };

  const modified = () => {
    setIsModified(true);
  };

  return (
    <div
      className="tagButtonContainer"
      onKeyUp={handleKeyUp}
      key={tag.id}
    >
      <div
        className="tagDropdown"
        style={{ display: !showDropdown && 'none' }}
        id={`tagDropdown_${tag.id}`}
      >
        <input
          type="text"
          className="tagDropdownInput"
          style={{ backgroundColor: tagColor || '#333', color: invertColor(tagColor || '#333') }}
          value={tagName}
          onChange={e => { handleNameChange(e); modified(); }}
        />
        {
          isModified && (
            <FontAwesome
              icon={isTagValid ? 'check' : 'times'}
              className={isTagValid ? 'validTagInput' : 'invalidTagInput'}
              onClick={submitTag}
            />
          )
        }
        <GithubPicker
          width="244px"
          triangle="hide"
          colors={colors}
          color={tagColor}
          onChange={c => { setTagColor(c.hex); modified(); }}
        />
      </div>
      <button
        id={`tagDropdownButton_${tag.id}`}
        className="tagButton"
        type="button"
        style={{ backgroundColor: tag.color || '#333', color: invertColor(tag.color || '#333') }}
        onClick={() => {
          setShowDropdown(prevShowDropdown => !prevShowDropdown);
          openDropdown(!showDropdown);
        }}
      >
        <div className="buttonContent">
          <div className="buttonLabel">{tag.name}</div>
          <div className="deleteX" onClick={e => deleteAction(e, tag.name)}><FontAwesome icon="times" /></div>
        </div>
      </button>
    </div>
  );
};

TagInputButton.propTypes = {
  tags: reducersTypes.tags.tags.isRequired,
  tag: PropTypes.object.isRequired,
  deleteAction: PropTypes.func.isRequired,
  updateTag: PropTypes.func.isRequired,
  openDropdown: PropTypes.func.isRequired,
};

export default TagInputButton;
