import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from '@intelligenceindustrielle/react-ui-components';

import './ColorsSettings.scss';

const ColorsSettings = ({ defaultUnfilledStopCauseColor, setDefaultUnfilledStopCauseColor }) => {
  const { t } = useTranslation();

  return (
    <div className="colorsSettingsModificationForm">
      <div className="colorSettingsTitle">
        {t('colorSettings')}
      </div>
      <div className="formsContainer">
        <div className="settingsInputGroup">
          <div className="inputTitle">
            {t('defaultUnfilledStopCauseColor')}
            :
          </div>
          <ColorPicker
            onChange={hex => setDefaultUnfilledStopCauseColor(hex)}
            value={defaultUnfilledStopCauseColor}
          />
        </div>
      </div>
    </div>
  );
};

ColorsSettings.propTypes = {
  defaultUnfilledStopCauseColor: PropTypes.string.isRequired,
  setDefaultUnfilledStopCauseColor: PropTypes.func.isRequired,
};

export { ColorsSettings };
