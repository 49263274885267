import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { IntervalTimePicker, TextInput } from '~UI';

const FormGaugeEvents = ({ selectedObject, selectedType }) => {
  const { t } = useTranslation();

  const [eventNames, setEventNames] = useState([]);
  const [intervalMax, setIntervalMax] = useState(selectedObject.intervalMax || 100);
  const [intervalMin, setIntervalMin] = useState(selectedObject.intervalMin || 0);

  async function fetchEventNames() {
    const { eventsPropertyElements } = await API.getDistinctEvents('name');
    setEventNames(eventsPropertyElements);
  }

  useEffect(() => {
    fetchEventNames();
  }, []);

  return (
    <div>
      <div className="inputTitle">{t('eventName')}</div>
      <TextInput
        name="currentEvent"
        options={eventNames}
        className="fullwidth"
        defaultValue={selectedObject.currentEvent}
      />

      <div className="inputTitle">{t('interval')}</div>
      <span>{t('between')}</span>
      &nbsp;
      <input
        type="number"
        name="number:intervalMin"
        min={0}
        step={1}
        value={selectedType === 'circularChart' ? 0 : intervalMin}
        onChange={e => setIntervalMin(e.currentTarget.value)}
      />
      &nbsp;
      <span>{t('and')}</span>
      &nbsp;
      <input
        type="number"
        name="number:intervalMax"
        min={0}
        step={1}
        value={intervalMax}
        onChange={e => setIntervalMax(e.currentTarget.value)}
      />

      <div className="inputTitle">{t('dataCollectionPeriod')}</div>
      <IntervalTimePicker
        intervalType={selectedObject.intervalType}
        intervalTypeInput
      />
    </div>
  );
};

FormGaugeEvents.propTypes = {
  selectedObject: PropTypes.shape({
    currentEvent: PropTypes.string,
    intervalMax: PropTypes.number,
    intervalMin: PropTypes.number,
    intervalType: PropTypes.string,
    units: PropTypes.string,
  }),
  selectedType: PropTypes.string.isRequired,
};
FormGaugeEvents.defaultProps = {
  selectedObject: {},
};

export { FormGaugeEvents };
