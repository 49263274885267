import React, { useState, useEffect, Children } from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BackIcon, StopCauseIcon } from '~UI/RoundButton/RoundIcons';

const MachineEventMenu = ({
  name, backPage, parentOption, options, onSelect, onBackPage,
  onSubmit, onHide, comments, resolutions, children, filter, initialTab, onBreadcrumbClick,
  breadcrumbs, language, // file,
}) => {
  const { t } = useTranslation();
  const [navSelected, setNavSelected] = useState(initialTab || (!comments ? 'comments' : 'resolution'));
  const [selectedOption, setSelectedOption] = useState(options?.find(o => o.name === name));
  const [commentsState, setComments] = useState(comments);
  const [resolutionsState, setResolutions] = useState(resolutions);
  // const [files, setFiles] = useState(null);
  // const [preview, setPreview] = useState(null);

  useEffect(() => {
    setSelectedOption(options?.find(o => o.name === name));
    setComments(comments);
    setResolutions(resolutions);
  }, [name, options, comments, resolutions]);

  const handleTabChange = eventKey => {
    setNavSelected(eventKey);
  };

  const selectStopCause = option => {
    if (!option.subMenu || option.subMenu?.length === 0) {
      setSelectedOption(option);
    }
    onSelect(option.id);
  };

  const resetStopCauseSelected = () => {
    setSelectedOption(null);
    onBackPage();
  };

  const getStopCauseName = option => {
    const languageMap = {
      en: option.nameEN,
      fr: option.nameFR,
      es: option.nameES,
    };
    return languageMap[language] ? languageMap[language] : option.name;
  };

  const breadcrumbElements = (
    <div className="breadcrumb-container">
      {breadcrumbs?.length === 1 ? (
        <>
          <span className="breadcrumb-text" onClick={() => onBreadcrumbClick(0)}>
            {breadcrumbs[0].name}
          </span>
          <span className="breadcrumb-arrow">&gt;</span>
        </>
      ) : (
        breadcrumbs?.map((breadcrumb, index) => (
          <>
            <span className="breadcrumb-text" onClick={() => onBreadcrumbClick(index)}>
              {breadcrumb.name}
            </span>
            {index < breadcrumbs?.length - 1 && <span className="breadcrumb-arrow">&gt;</span>}
          </>
        ))
      )}
    </div>
  );

  const backButton = backPage
    ? <BackIcon onClick={resetStopCauseSelected} />
    : null;

  const menu = options
    ?.filter(o => !selectedOption || o.id === selectedOption.id)
    .filter(o => (o.isVisible !== undefined ? o.isVisible : true))
    .filter(o => getStopCauseName(o).toLowerCase().includes(filter.toLowerCase())
      || o.subMenu?.some(s => getStopCauseName(s).toLowerCase().includes(filter.toLowerCase()))
      || (parentOption && getStopCauseName(parentOption).toLowerCase().includes(filter.toLowerCase())))
    .map(
      option => (
        <StopCauseIcon
          key={option.id}
          option={{ ...option, name: getStopCauseName(option), badgeCount: option.subMenu?.length }}
          onClick={() => selectStopCause(option)}
        />
      ),
    );

  // const updateLoadedFile = e => {
  //   if (e.target.files) {
  //     setFiles(e.target.files);
  //     setPreview(URL.createObjectURL(e.target.files[0]));
  //   }
  // };

  const form = name && (
    <div className="StopSelectionForm">
      <div className="formContainer">
        <Nav bsStyle="tabs" activeKey={navSelected} onSelect={handleTabChange}>
          <NavItem eventKey="comments">
            {t('comments')}
          </NavItem>
          <NavItem eventKey="resolution">
            {t('resolution')}
          </NavItem>
        </Nav>
        <form id="Stop_Selection_Form">
          {
            navSelected === 'comments' && (
              <textarea
                rows={2}
                defaultValue={comments}
                value={commentsState}
                onChange={e => setComments(e.target.value)}
                name="comments"
                style={{ resize: 'vertical', width: `${100}%` }}
                placeholder={t('comments')}
              />
            )
          }
          {
            navSelected === 'resolution' && (
              <textarea
                rows={2}
                defaultValue={resolutions}
                value={resolutionsState}
                onChange={e => setResolutions(e.target.value)}
                name="resolutions"
                style={{ resize: 'vertical', width: `${100}%` }}
                placeholder={t('resolution')}
              />
            )
          }
          {
            /*
            <div style={{ display: 'flex' }}>
              <input
                type="file"
                name="optionalFile"
                id="fileUploadInput"
                onChange={updateLoadedFile}
                style={{ visibility: 'hidden', width: '0' }}
              />
              <label htmlFor="fileUploadInput" style={{ paddingTop: files || file ? '5px' : '15px' }}>
                {files || file ? <img src={preview || file} alt="preview" style={{ height: '60px' }} />
                : <FontAwesome icon="paperclip" />}
              </label>
            </div>
            */
          }
        </form>
        {Children.map(children, child => child)}
      </div>
      <div className="btn-group fullwidth">
        <button type="button" disabled={name === null} className="submitButton" onClick={() => onSubmit(commentsState, resolutionsState)}>
          {t('submit')}
        </button>
        {onHide !== MachineEventMenu.defaultProps.onHide && (
          <button type="button" className="submitButton red" onClick={onHide}>
            {t('cancel')}
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="MachineEventMenu__Options">
      {breadcrumbElements}
      {backButton}
      {menu}
      {form}
    </div>
  );
};

MachineEventMenu.propTypes = {
  backPage: PropTypes.bool.isRequired,
  onBackPage: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string,
  parentOption: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  comments: PropTypes.string,
  resolutions: PropTypes.string,
  onHide: PropTypes.func,
  children: PropTypes.node,
  filter: PropTypes.string,
  initialTab: PropTypes.string,
  onBreadcrumbClick: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  language: PropTypes.string.isRequired,
  // file: PropTypes.string,
};
MachineEventMenu.defaultProps = {
  name: '',
  parentOption: null,
  options: [],
  comments: '',
  resolutions: '',
  filter: '',
  children: null,
  onHide: () => {},
  initialTab: null,
};

export default MachineEventMenu;
