import {
  addKPIRequest,
  addKPIResponse,
  updateKPIRequest,
  updateKPIResponse,
  deleteKPIRequest,
  deleteKPIResponse,
} from '../actions';
import API from '~services/endpoints';

function addKPI(machineId, kpi) {
  return dispatch => {
    dispatch(addKPIRequest({ machineId, kpi }));
    return API.addMachineKPI(machineId, kpi)
      .then(res => {
        if (!res.error) {
          dispatch(addKPIResponse({ machineId, kpi: res.kpi }));
        }
      });
  };
}

function updateKPI(machineId, kpiId, kpi) {
  return dispatch => {
    dispatch(updateKPIRequest({ machineId, kpiId, kpi }));
    return API.updateMachineKPI(machineId, kpiId, kpi)
      .then(res => dispatch(updateKPIResponse({ machineId, kpi: res.kpi })));
  };
}

function deleteKPI(machineId, kpiId) {
  return dispatch => {
    dispatch(deleteKPIRequest({ machineId, kpiId }));
    return API.deleteMachineKPI(machineId, kpiId)
      .then(res => dispatch(deleteKPIResponse({ machineId, kpi: res.kpi })));
  };
}

export {
  addKPI,
  updateKPI,
  deleteKPI,
};
