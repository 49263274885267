import i18n from 'i18next';
import { showError } from '../toast';

const verifyVariableName = variable => {
  if (!variable) {
    showError(i18n.t('missingName'));
    return true;
  }
  if (variable.toLowerCase() === 'now') {
    showError(i18n.t('cannotCallNow'));
    return true;
  }
  if (variable.toLowerCase() === 'time') {
    showError(i18n.t('cannotCallTime'));
    return true;
  }
  if (!variable) {
    showError(i18n.t('showErrorFields'));
    return true;
  }
  if (!RegExp('^[A-Za-z]').test(variable)) {
    showError(i18n.t('mustStartWithLetter'));
    return true;
  }
  if (variable.includes(' ')) {
    showError(i18n.t('variableNoSpaceInName'));
    return true;
  }
  if ((variable.match(/^[A-Za-z][A-Za-z0-9_]*/g) || [''])[0].length !== variable.length) {
    showError(i18n.t('variableNameSpecialCharacters'));
    return true;
  }
  return false;
};

export default verifyVariableName;
