import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { reduxOperations } from '~services';
import { DashboardsSettings } from './DashboardsSettings';
import { ColorsSettings } from './ColorsSettings';
import { EventPageSettings } from './EventPageSettings';
import { CheckboxToggle } from '~UI/index';
import { showSuccess, showError } from '~utils/toast';
import { SubmitButton } from '~UI';
import './SettingsModificationForm.scss';

const SettingsModificationForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settings = useSelector(state => state.settings.settings);

  const [
    defaultUnfilledStopCauseColor,
    setDefaultUnfilledStopCauseColor,
  ] = useState(settings.defaultUnfilledStopCauseColor);
  const [disabledRedirectedInteractions, setDisabledRedirectedInteractions] = useState(
    settings.disabledRedirectedInteractions || false);
  const [dashboardSizes, setDashboardSizes] = useState(settings.dashboardSizes);
  const [eventPageFilters, setEventPageFilters] = useState(settings.eventPageFilters);

  useEffect(() => {
    dispatch(reduxOperations.settings.fetchSettings());
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    const newSettings = {
      defaultUnfilledStopCauseColor,
      disabledRedirectedInteractions,
      dashboardSizes,
      eventPageFilters,
    };

    dispatch(reduxOperations.settings.patchSettings(newSettings)).then(() => {
      showSuccess(t('success'));
    }).catch(error => showError(error.status));
  };
  return (
    <form id="settingsForm" onSubmit={handleSubmit}>
      <div className="settingsModificationForm">
        <DashboardsSettings
          dashboardSizes={dashboardSizes}
          setDashboardSizes={setDashboardSizes}
        />
        <ColorsSettings
          defaultUnfilledStopCauseColor={defaultUnfilledStopCauseColor}
          setDefaultUnfilledStopCauseColor={setDefaultUnfilledStopCauseColor}
        />
        <EventPageSettings
          eventPageFilters={eventPageFilters}
          setEventPageFilters={setEventPageFilters}
        />
        <div className="otherSettingsTitle">
          {t('otherSettings')}
        </div>
        <div className="inputTitle">
          {t('disabledRedirectedInteractions')}
          &nbsp;
          <CheckboxToggle
            controlledCheck={disabledRedirectedInteractions}
            onChange={() => setDisabledRedirectedInteractions(!disabledRedirectedInteractions)}
          />
        </div>
        <div className="settingsModificationFormFooter">
          <SubmitButton
            label={t('modify')}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
};

export { SettingsModificationForm };
