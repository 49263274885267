import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@intelligenceindustrielle/react-ui-components';
import { triggerTypes } from '~utils/types';
import { FontAwesome } from '~UI';
import ValueChangeTriggerForm from './ValueChangeTriggerForm';
import ValueSetTriggerForm from './ValueSetTriggerForm';

const ValueTriggerForm = ({ selectTriggerType, trigger }) => {
  const { t } = useTranslation();

  const initTriggerType = trigger.type === 'ValueTrigger' ? triggerTypes.VALUE_CHANGE : trigger.type;

  useEffect(() => {
    selectTriggerType(initTriggerType);
  }, []);

  const [triggerType, setTriggerType] = useState(initTriggerType);

  const handleChangeType = value => {
    selectTriggerType(value);
    setTriggerType(value);
  };

  const buttons = [
    { label: t('valueChange'), value: triggerTypes.VALUE_CHANGE },
    { label: t('valueSet'), value: triggerTypes.VALUE_SET },
  ];

  return (
    <div className="ValueTrigger">
      <div style={{ marginBottom: '7px' }}>
        <ButtonGroup
          buttons={buttons}
          onChange={handleChangeType}
          style={{ fontSize: '18px' }}
          value={triggerType}
        />
        <br />
        <FontAwesome icon="info-circle" />
          &nbsp;&nbsp;
        {
          triggerType === triggerTypes.VALUE_CHANGE
            ? t('valueChangeDefinition')
            : t('valueSetDefinition')
        }
      </div>

      {
        triggerType === triggerTypes.VALUE_CHANGE
          ? <ValueChangeTriggerForm trigger={trigger} />
          : <ValueSetTriggerForm trigger={trigger} />
      }
    </div>
  );
};

ValueTriggerForm.propTypes = {
  trigger: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  selectTriggerType: PropTypes.func.isRequired,
};

export default ValueTriggerForm;
