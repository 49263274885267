import StopwatchManager from '../StopwatchManager';
import simpleEval from './simpleEval';
import splitCondition from './splitCondition';

const evaluate = (condition, data, varType) => {
  if (data && (varType === 'value' || varType === 'stopwatch')) {
    const splittedCondition = splitCondition(condition);
    if (!splittedCondition) {
      return false;
    }
    const { variable, comparator, valueToCompare } = splittedCondition;

    if (varType === 'stopwatch') {
      return simpleEval(StopwatchManager.getStopwatchTime(variable), comparator, valueToCompare);
    }

    const value = data.tempsReel[variable];
    if (typeof (value) === 'undefined' || value === null) {
      // TODO: think about should we return false, throw an Error or something else
      // console.error(`Parser error: variable ${variable} doesn't exist`);
      return false;
    }

    if (typeof valueToCompare === 'boolean' || typeof value === 'string') {
      return simpleEval(value, comparator, valueToCompare);
    }
    if (typeof valueToCompare === 'number') {
      switch (comparator) {
        case '==':
          return parseFloat(data.tempsReel[variable]) === valueToCompare;
        case '<':
          return parseFloat(data.tempsReel[variable]) < valueToCompare;
        case '>':
          return parseFloat(data.tempsReel[variable]) > valueToCompare;
        case '>=':
          return parseFloat(data.tempsReel[variable]) >= valueToCompare;
        case '!=':
          return parseFloat(data.tempsReel[variable]) !== valueToCompare;
        case '<=':
          return parseFloat(data.tempsReel[variable]) <= valueToCompare;
        default:
          return false;
      }
    }
  }

  return false;
};

export default evaluate;
