import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import omit from 'lodash.omit';
import { useTranslation } from 'react-i18next';
import { getActionIcon } from '~utils/icons';
import FontAwesome from '~UI/FontAwesome';
import { getLocalStorageObject, getLocalStorageSetter } from '~utils/localStorage';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { stopPropagation } from '~utils/index';
import TagsSelection from '~UI/Tags/TagsSelection';
import { handleTagSelection, getTagListFromUsedTags, elementHasAllSelectedTags, idsToTags } from '~utils/tags';
import { sortArray } from '~utils/sort';
import ActionEditionForm from './ActionEditionForm';
import './ActionSelectionPage.scss';

const propTypes = {
  selectAction: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  selectedAction: PropTypes.string,
};

const defaultProps = {
  selectedAction: null,
};

const updateLocalStorage = getLocalStorageSetter('K2_ruleEngine');

const ActionSelection = ({ selectAction, closePopup, selectedAction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actions = useSelector((state: RootState) => state.actions);
  const tags = useSelector((state: RootState) => state.tags.tags);

  const storage = getLocalStorageObject('K2_ruleEngine');

  const [actionsFilter, setActionsFilter] = useState('');
  const [isCrud, setIsCRUD] = useState(false);
  const [selectedTags, setSelectedTags] = useState(storage.actionTags || ['all']);

  const handleSearchChange = e => {
    setActionsFilter(e.target.value);
  };

  const handleActionSelection = action => {
    selectAction(action, selectedAction);
    closePopup();
  };

  const handleDuplication = (e, action) => {
    stopPropagation(e);
    const newAction = omit(action, ['id']);
    newAction.name += ' (Copie)';
    newAction.tags = idsToTags(newAction.tags, tags);
    reduxOperations.actions.createAction(newAction)(dispatch);
  };

  const selectTag = (selectedTag, withCtrl) => {
    setSelectedTags(prevSelectedTags => {
      let newTags = handleTagSelection(selectedTag, prevSelectedTags, withCtrl);
      const usedList = getTagListFromUsedTags(actions, newTags);
      newTags = newTags.filter(tag => usedList.find(usedTag => usedTag === tag) || tag === 'all');
      updateLocalStorage('actionTags', newTags);
      return newTags;
    });
  };

  const getActionSelectionList = () => {
    let usedSelectedTags = getTagListFromUsedTags(actions, selectedTags);
    usedSelectedTags = usedSelectedTags.length ? usedSelectedTags : ['all'];
    const usedTags = getTagListFromUsedTags(actions, tags);

    const sortedActions = sortArray('alphabetically', actions, 'name');

    return (
      <div className="actionSelection">
        <div className="header">
          <div className="searchBar">
            <input
              placeholder={t('Search')}
              onChange={handleSearchChange}
              autoFocus
            />
          </div>

          <button
            className="createActionButton"
            onClick={() => setIsCRUD(true)}
            type="submit"
          >
            <FontAwesome icon="plus" className="icon" />
            <div className="text">
              {t('createAction')}
            </div>
          </button>
        </div>
        {usedTags.length > 0 && !(usedTags.length === 1 && tags.includes('all')) && (
          <TagsSelection
            tags={usedTags}
            selectedTags={usedSelectedTags}
            selectTag={selectTag}
            infoIcon={false}
          />
        )}
        <div className="actionsGrid">
          {
            sortedActions
              .filter(action => action.name.toLowerCase().includes(actionsFilter.toLowerCase()))
              .filter(action => elementHasAllSelectedTags(action, selectedTags))
              .map((action, index) => (
                <div
                  className="actionTile"
                  key={`action_${index}`}
                  onClick={() => handleActionSelection(action)}
                  role="button"
                >
                  <div className="actionContent">
                    <div className="icon">
                      <FontAwesome
                        icon={getActionIcon(action.type) || undefined}
                        size="2x"
                      />
                    </div>
                    <div className="infos">
                      <div className="text">
                        {action.name}
                      </div>
                      <div className="cardTagsContainer">
                        {idsToTags(action.tags, tags).map(tag => tag && tag.color && <div className="cardTag" style={{ background: tag.color }} key={tag.id} />)}
                      </div>
                    </div>

                  </div>
                  <div className="settingsIcon">
                    <FontAwesome icon="clone" onClick={e => handleDuplication(e, action)} />
                  </div>
                </div>
              ))
          }
        </div>
      </div>
    );
  };

  return isCrud ? (
    <ActionEditionForm
      goBack={action => (action !== null ? handleActionSelection(action) : setIsCRUD(false))}
      actionId={null}
    />
  )
    : getActionSelectionList();
};

ActionSelection.propTypes = propTypes;
ActionSelection.defaultProps = defaultProps;

export default ActionSelection;
