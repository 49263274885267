import types from '../types';

const addStopCauseRequest = data => ({
  type: types.ADD_STOP_CAUSE_REQUEST,
  payload: data,
});

const addStopCauseResponse = (data, request = null) => ({
  type: types.ADD_STOP_CAUSE_RESPONSE,
  payload: data,
  request,
});

const updateStopCauseRequest = data => ({
  type: types.UPDATE_STOP_CAUSE_REQUEST,
  payload: data,
});

const updateStopCauseResponse = (data, request = null) => ({
  type: types.UPDATE_STOP_CAUSE_RESPONSE,
  payload: data,
  request,
});

const deleteStopCauseRequest = data => ({
  type: types.DELETE_STOP_CAUSE_REQUEST,
  payload: data,
});

const deleteStopCauseResponse = (data, request = null) => ({
  type: types.DELETE_STOP_CAUSE_RESPONSE,
  payload: data,
  request,
});

const addSubStopCauseRequest = data => ({
  type: types.ADD_SUB_STOP_CAUSE_REQUEST,
  payload: data,
});

const addSubStopCauseResponse = (data, request = null) => ({
  type: types.ADD_SUB_STOP_CAUSE_RESPONSE,
  payload: data,
  request,
});

const updateSubStopCauseRequest = data => ({
  type: types.UPDATE_SUB_STOP_CAUSE_REQUEST,
  payload: data,
});

const updateSubStopCauseResponse = (data, request = null) => ({
  type: types.UPDATE_SUB_STOP_CAUSE_RESPONSE,
  payload: data,
  request,
});

const deleteSubStopCauseRequest = data => ({
  type: types.DELETE_SUB_STOP_CAUSE_REQUEST,
  payload: data,
});

const deleteSubStopCauseResponse = (data, request = null) => ({
  type: types.DELETE_SUB_STOP_CAUSE_RESPONSE,
  payload: data,
  request,
});

export {
  addStopCauseRequest,
  addStopCauseResponse,
  updateStopCauseRequest,
  updateStopCauseResponse,
  deleteStopCauseRequest,
  deleteStopCauseResponse,
  addSubStopCauseRequest,
  addSubStopCauseResponse,
  updateSubStopCauseRequest,
  updateSubStopCauseResponse,
  deleteSubStopCauseRequest,
  deleteSubStopCauseResponse,
};
