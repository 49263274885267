import {
  addMachineShiftRequest,
  addMachineShiftResponse,
  updateMachineShiftRequest,
  updateMachineShiftResponse,
  deleteMachineShiftRequest,
  deleteMachineShiftResponse,
} from '../actions';
import API from '~services/endpoints';

function addMachineShift(machineId, machineShift) {
  return dispatch => {
    dispatch(addMachineShiftRequest({ machineId, machineShift }));
    return API.createMachineShift(machineId, machineShift)
      .then(res => {
        if (!res.error) {
          dispatch(addMachineShiftResponse({ machineId, machine: res.machine }));
        }
      });
  };
}

function updateMachineShift(machineId, machineShiftId, machineShift) {
  return dispatch => {
    dispatch(updateMachineShiftRequest({ machineId, machineShiftId, machineShift }));
    return API.updateMachineShift(machineId, machineShiftId, machineShift)
      .then(res => dispatch(updateMachineShiftResponse({ machineId, machine: res.machine })));
  };
}

function deleteMachineShift(machineId, machineShiftId) {
  return dispatch => {
    dispatch(deleteMachineShiftRequest({ machineId, machineShiftId }));
    return API.deleteMachineShift(machineId, machineShiftId)
      .then(res => dispatch(deleteMachineShiftResponse({ machineId, machine: res.machine })));
  };
}

export {
  addMachineShift,
  updateMachineShift,
  deleteMachineShift,
};
