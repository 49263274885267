import difference from 'lodash.difference';
import { sortArray } from './sort';

const selectedTagsToTitle = (tags, selectedTags) => {
  let title = '';
  const prefillTags = selectedTags.map((sTag, index) => {
    const tag = tags.find(t => sTag === t.id);
    const tagName = (tag && tag.name) || '';
    title += index !== selectedTags.length - 1 ? `${tagName} - ` : tagName;
    return tag;
  });
  return { name: title, prefillTags };
};

const elementHasAllSelectedTags = (element, tags) => tags.includes('all') || difference(tags, element.tags).length === 0;

const handleTagSelection = (selectedTag, oldTags, withCtrl) => {
  let newTags: string[] = [];
  if (oldTags.length === 1) {
    if (oldTags[0] === selectedTag) {
      return oldTags;
    }
  }
  if (withCtrl) {
    newTags = [...oldTags];
  }
  if (selectedTag === 'all') {
    newTags = ['all'];
  } else if (newTags.includes(selectedTag)) {
    newTags = newTags.filter(tag => tag !== selectedTag && tag !== 'all');
    newTags = newTags.length ? newTags : ['all'];
  } else {
    newTags = newTags.filter(tag => tag !== 'all');
    newTags.push(selectedTag);
  }
  return newTags;
};

const idsToTags = (idsArray, tags) => idsArray.map(id => (tags || []).find(tag => tag.id === id));

const getUniqueTags = elements => {
  const shownTags = new Set();
  elements.forEach(elem => {
    (elem.tags || []).forEach(tag => {
      shownTags.add(tag);
    });
  });
  return Array.from(shownTags);
};

const getTagListFromUsedTags = (elements, tags) => {
  const tagsArray = getUniqueTags(elements)
    .map(shownTag => tags.find(tag => tag.id === shownTag || tag === shownTag))
    .filter(tag => !!tag);

  return sortArray('alphabetically', tagsArray, 'name');
};

export {
  selectedTagsToTitle,
  elementHasAllSelectedTags,
  handleTagSelection,
  idsToTags,
  getTagListFromUsedTags,
};
