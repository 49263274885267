import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import FontAwesome from '~UI/FontAwesome';
import imagesLibrary from '~images/imagesLibrary';

import RoundButton from './RoundButton';

const UserIcon = ({ backgroundURL, name, onClick }) => {
  const background = backgroundURL
    ? { backgroundImage: backgroundURL, backgroundSize: '100px' }
    : { content: <FontAwesome icon="user" size="4x" /> };

  return (
    <RoundButton
      name={name}
      onClick={onClick}
      {...background}
    />
  );
};
UserIcon.propTypes = {
  backgroundURL: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};
UserIcon.defaultProps = {
  backgroundURL: '',
  name: null,
  onClick: () => null,
};

const BackIcon = ({ onClick }) => (
  <RoundButton
    onClick={onClick}
    color="#444"
    content={
      (
        <FontAwesome
          icon="arrow-left"
          size="4x"
          color="#444"
        />
      )
    }
  />
);
BackIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const AddIcon = ({ onClick }) => (
  <RoundButton
    onClick={onClick}
    color="#444"
    content={
      (
        <FontAwesome
          icon="plus"
          size="4x"
          color="#444"
        />
      )
    }
  />
);
AddIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const StopCauseIcon = ({ className, onClick, option, small }) => {
  const { color, icon, name } = option;
  return (
    <>
      <RoundButton
        className={className}
        name={name}
        color={color || '#CCC'}
        onClick={onClick}
        backgroundImage={imagesLibrary[icon] || icon}
        backgroundSize="cover"
        small={small}
      />
      {option.badgeCount > 0 && (
        <div className="iconBadgeContainer">
          <Badge className={small ? 'iconSmallBadge' : 'iconBadge'}>
            {option.badgeCount}
          </Badge>
        </div>
      )}
    </>
  );
};
StopCauseIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  option: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    badgeCount: PropTypes.number,
  }).isRequired,
  small: PropTypes.bool,
};
StopCauseIcon.defaultProps = {
  className: '',
  onClick: () => null,
  small: false,
};

export {
  AddIcon,
  BackIcon,
  StopCauseIcon,
  UserIcon,
};
