import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import types from './types';
import { buildLinkRedirectionTargetURL } from '~utils/LinkRedirection';

function targetURL(state = null, action) {
  switch (action.type) {
    case types.GET_LINK_REDIRECTION_RESPONSE: {
      const { contentType, contentId } = action.payload;
      return buildLinkRedirectionTargetURL(contentType, contentId);
    }
    case types.UPDATE_LINK_REDIRECTION:
      return action.payload;
    default:
      return state;
  }
}

export const propTypes = {
  targetURL: PropTypes.string,
};

export default combineReducers({
  targetURL,
});
