import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { RootState } from '~services/store';
import { isRedirected } from '~utils/LinkRedirection';
import PageTitleAndDescription from '../PageTitleAndDescription/PageTitleAndDescription';
import Footer from '../Footer/Footer';
import FontAwesome from '../../UI/FontAwesome';
import { SideBar } from '../SideBar/SideBar';
import './PageTemplate.scss';

function PageTemplateComponent({
  title,
  description,
  right,
  header,
  sidebar,
  sidebarRef,
  children,
  rightButtons,
  pageTemplateClass,
  noScrollBar,
  pageId,
  noHorizontalPadding,
}) {
  const [isSideBarOpened, setIsSideBarOpened] = useState(localStorage.getItem('sideBar') === 'opened');
  const connexion = useSelector((state: RootState) => state.session.connexion);
  const isFromSharedLink = isRedirected(connexion.infos.id);
  const shouldDisplaySidebar = sidebar && !isFromSharedLink;

  return (
    <div className={classnames({
      Page: true,
      flex: true,
      V: true,
      noScrollBar,
    })}
    >

      <div className="Page__Header">
        {header}
      </div>

      <div
        className="Page__Contents flex"
        style={{
          ...(shouldDisplaySidebar && { justifyContent: 'right' }),
        }}
      >
        {
          shouldDisplaySidebar && (
            <div ref={sidebarRef}>
              <SideBar isSideBarOpened={isSideBarOpened} />
              <div
                className="arrow"
                onClick={() => {
                  setIsSideBarOpened(!isSideBarOpened);
                  localStorage.setItem('sideBar', isSideBarOpened ? 'closed' : 'opened');
                }}
                style={{
                  ...(!isSideBarOpened && { left: 0 }),
                }}
              >
                <FontAwesome icon="bars-staggered" />
              </div>
            </div>
          )
        }
        <div
          id={pageId}
          className={`PageTemplate ${pageTemplateClass}`}
          style={{
            ...((!shouldDisplaySidebar || !isSideBarOpened) && { width: '100%' }),
            ...(noHorizontalPadding && { padding: '50px 0' }),
          }}
        >
          <div id="topOfPage" />
          {
            title && (
              <div className="Page__Title">
                <PageTitleAndDescription
                  title={title}
                  description={description}
                />
              </div>
            )
          }
          {
            rightButtons && (
              <div className="ButtonHolder">
                <div className="ButtonHolderRight">
                  {rightButtons}
                </div>
              </div>
            )
          }
          {children}
        </div>
      </div>

      {
        right.map((element, i) => <React.Fragment key={`right_${i}`}>{element}</React.Fragment>)
      }

      <div className="Page__Footer">
        <Footer />
      </div>
    </div>
  );
}

PageTemplateComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  right: PropTypes.arrayOf(PropTypes.node),
  header: PropTypes.node,
  sidebar: PropTypes.bool,
  sidebarRef: PropTypes.func,
  pageTemplateClass: PropTypes.string,
  noScrollBar: PropTypes.bool,
  pageId: PropTypes.string,
  children: PropTypes.node,
  rightButtons: PropTypes.elementType,
  noHorizontalPadding: PropTypes.bool,
};

PageTemplateComponent.defaultProps = {
  title: '',
  description: '',
  right: [],
  header: null,
  sidebar: null,
  sidebarRef: null,
  pageTemplateClass: '',
  noScrollBar: false,
  pageId: '',
  noHorizontalPadding: false,
};

export default PageTemplateComponent;
