import React from 'react';

const santaStopIcon = ({ height }) => (
  <svg
    height={height * 5}
    viewBox="0 0 500 500"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M214.569 119.208C214.569 109.428 218.297 100.049 224.935 93.1336C231.574 86.2182 240.576 82.3333 249.963 82.3333C259.35 82.3333 268.353 86.2182 274.991 93.1336C281.628 100.049 285.357 109.428 285.357 119.208C285.357 128.988 281.628 138.367 274.991 145.283C268.353 152.198 259.35 156.083 249.963 156.083C240.576 156.083 231.574 152.198 224.935 145.283C218.297 138.367 214.569 128.988 214.569 119.208ZM244.065 352.75V451.084C244.065 464.681 233.519 475.667 220.469 475.667C207.416 475.667 196.872 464.681 196.872 451.084V279.692L175.783 316.259C169.073 327.86 154.546 331.624 143.412 324.633C132.277 317.641 128.664 302.508 135.374 290.908L178.364 216.389C191.194 194.188 214.201 180.59 239.05 180.59H260.951C285.799 180.59 308.806 194.188 321.636 216.389L364.625 290.908C371.336 302.508 367.723 317.641 356.588 324.633C345.454 331.624 330.929 327.86 324.218 316.259L303.055 279.692V451.084C303.055 464.681 292.51 475.667 279.459 475.667C266.406 475.667 255.863 464.681 255.863 451.084V352.75H244.065Z" fill="white" />
    <path d="M212.829 52.2938C212.908 51.7575 212.949 51.2168 212.957 50.6752C212.951 48.1644 212.135 45.7228 210.628 43.7142C209.122 41.7056 207.006 40.2377 204.598 39.5297C213.303 30.0179 225.361 24.2597 238.23 23.4705C239.387 23.3895 240.612 23.3433 241.768 23.3433C246.508 23.2301 251.222 24.0554 255.641 25.7717C260.06 27.4882 264.096 30.0616 267.516 33.3442C282.801 47.9235 286.455 75.1628 287.299 89.1179H209.742C212.182 74.1801 216.68 66.7345 220.438 63.0346L221.004 62.4912C221.189 62.3179 221.374 62.1675 221.559 62.0056C222.883 60.8362 224.452 59.9754 226.149 59.4851L226.507 59.4159C226.584 59.4082 226.657 59.3858 226.723 59.3496C226.79 59.3136 226.851 59.2647 226.898 59.2057C226.948 59.1467 226.983 59.0786 227.005 59.0056C227.028 58.9325 227.034 58.8558 227.026 58.7799C227.02 58.704 226.997 58.6304 226.961 58.5631C226.926 58.4959 226.877 58.4365 226.818 58.3881C226.759 58.3397 226.69 58.3035 226.617 58.2815C226.544 58.2595 226.468 58.252 226.391 58.2597C224.562 58.5899 222.851 59.3864 221.419 60.5719C221.264 59.2902 220.849 58.0534 220.202 56.9365C219.555 55.8196 218.686 54.8459 217.651 54.0742C216.206 53.1098 214.554 52.4999 212.829 52.2938Z" fill="#9F0500" />
    <path d="M210.674 89.1293H287.583C287.721 89.1293 287.849 89.1293 287.986 89.1293H288.543C290.018 89.3619 291.361 90.1167 292.327 91.2564C293.29 92.3959 293.815 93.8444 293.804 95.3379V110.368C293.8 112.017 293.143 113.597 291.977 114.763C290.812 115.929 289.231 116.585 287.583 116.588H207.552C205.904 116.585 204.323 115.929 203.157 114.763C201.991 113.597 201.335 112.017 201.332 110.368V95.3379C201.332 93.6882 201.987 92.106 203.154 90.9396C204.32 89.773 205.903 89.1177 207.552 89.1177L210.674 89.1293Z" fill="#EFF0F2" />
    <path d="M211.812 50.6784C211.81 51.1781 211.768 51.677 211.684 52.1699C211.684 52.3663 211.684 52.5514 211.603 52.7479C211.522 52.9445 211.511 53.1294 211.454 53.3261C210.948 55.1985 209.932 56.8928 208.516 58.2178C207.1 59.5429 205.342 60.446 203.44 60.8252C201.538 61.2042 199.569 61.0445 197.753 60.3638C195.937 59.6829 194.348 58.5084 193.164 56.9725C191.98 55.4365 191.249 53.6004 191.053 51.6711C190.857 49.7418 191.205 47.7962 192.056 46.0537C192.907 44.3115 194.228 42.8414 195.87 41.8097C197.511 40.7779 199.409 40.2257 201.348 40.2151C201.718 40.2151 202.065 40.2151 202.423 40.2729L203.048 40.3653L203.741 40.504C206.035 41.045 208.079 42.3436 209.544 44.1897C211.007 46.0358 211.808 48.3219 211.812 50.6784Z" fill="#EFF0F2" />
  </svg>
);

export default santaStopIcon;
