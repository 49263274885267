import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { reducersTypes } from '~services';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import TileContents from '../TileContents';
import './ProgressBarTile.scss';

class ProgressBarTileClientB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOperation: null,
      currentWorkOrder: null,
      isSocketInitialized: false,
      nbEvents: null,
    };
  }

  componentDidMount() {
    const socket = getSocket();
    const { isSocketInitialized } = this.state;
    this.fetchMachineParams();
    if (socket && !isSocketInitialized) {
      socket.on('event', this.handleSocketEvent);
      this.setState({
        isSocketInitialized: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const socket = getSocket();
    const { tile } = this.props;
    const { isSocketInitialized } = this.state;
    if (tile.machineId !== prevProps.tile.machineId) {
      this.fetchMachineParams();
    }
    if (socket && !isSocketInitialized) {
      socket.on('event', this.handleSocketEvent);
      this.setState({
        isSocketInitialized: true,
      });
    }
  }

  componentWillUnmount() {
    const socket = getSocket();
    if (socket) {
      socket.removeListener('event', this.handleSocketEvent);
    }
  }

  fetchMachineParams = (operation, workOrder) => {
    const { machines, tile } = this.props;
    let newOperation = operation;
    let newWorkOrder = workOrder;
    if (!newOperation && !newWorkOrder) {
      const { params } = machines.find(m => m.id === tile.machineId);
      newOperation = params.operation;
      newWorkOrder = params.workOrder;
    }
    this.setState({
      currentOperation: newOperation,
      currentWorkOrder: newWorkOrder,
      nbEvents: null,
    }, () => {
      this.fetchEvents();
    });
  };

  fetchEvents = async () => {
    const { tile } = this.props;
    const { currentOperation, currentWorkOrder } = this.state;
    if (currentOperation && currentWorkOrder) {
      const filter = {
        type: 'PartEvent',
        eventType: 'IN',
        machineId: tile.machineId,
        'infosObject.operation': currentOperation,
        'infosObject.workOrder': currentWorkOrder,
      };
      const { count } = await API.countEvents(filter);
      this.setState({ nbEvents: count });
    } else {
      this.setState({ nbEvents: 0 });
    }
  };

  handleSocketEvent = socketEvent => {
    const { machines, tile } = this.props;
    const { currentOperation, currentWorkOrder } = this.state;
    const { type, machineId } = socketEvent;
    if (type === 'PartEvent' && socketEvent.eventType === 'IN' && machineId === tile.machineId) {
      const { infosObject } = socketEvent;
      const { workOrder, operation } = machines.find(m => m.id === tile.machineId).params;
      if (infosObject.operation === operation && infosObject.workOrder === workOrder) {
        this.setState(prevState => ({ nbEvents: (prevState.nbEvents + 1) }));
      }
    }
    if (type === 'MachineStatus' && machineId === tile.machineId
      && (socketEvent.operation !== currentOperation || socketEvent.workOrder !== currentWorkOrder)) {
      this.fetchMachineParams(socketEvent.operation, socketEvent.workOrder);
    }
  };

  render() {
    const {
      backgroundColor,
      height,
      tile,
      width,
      operations,
    } = this.props;
    const { currentOperation, currentWorkOrder, nbEvents } = this.state;
    const operation = operations.find(o => o.name === currentOperation);
    const total = operation ? operation.requiredQuantity : 0;

    if (nbEvents === null) { // nbEvents can be 0
      return (
        <Loader />
      );
    }

    return (
      <TileContents
        tile={tile}
        backgroundColor={backgroundColor}
        height={height}
        width={width}
      >
        <h4>{currentOperation || '?'}</h4>
        <h4>{currentWorkOrder || '?'}</h4>
        <h1>
          {nbEvents}
          /
          {total}
        </h1>
        <ProgressBar
          striped
          max={total}
          now={nbEvents}
        />
      </TileContents>
    );
  }
}

ProgressBarTileClientB.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  machines: reducersTypes.machines.isRequired,
  operations: reducersTypes.operations.operations.isRequired,
  tile: PropTypes.shape({
    eventName: PropTypes.string,
    machineId: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    machines: state.machines,
    operations: state.operations.operations,
  };
}

export default connect(mapStateToProps)(ProgressBarTileClientB);
