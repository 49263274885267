import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const widgetPropTypes = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  z: PropTypes.number,
  w: PropTypes.number,
  h: PropTypes.number,
  linksTo: PropTypes.string,
});

const topviewPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  folderId: PropTypes.string,
  backgroundURL: PropTypes.string,
  widgets: PropTypes.arrayOf(widgetPropTypes),
});
const topviewsPropTypes = PropTypes.arrayOf(topviewPropTypes);

const indexOfId = (arr, id) => arr.map(item => item.id).indexOf(id);

function topviews(state = null, action) {
  if (!action.payload || action.payload.error) {
    return state;
  }

  const newState = state === null ? null : [...state];
  let index;
  switch (action.type) {
    case types.CREATE_TOPVIEW_RESPONSE:
      return [...state, action.payload.topview];

    case types.GET_TOPVIEWS_RESPONSE:
      return action.payload.topviews;

    case types.UPDATE_TOPVIEW_RESPONSE:
    case types.CREATE_WIDGET_RESPONSE:
    case types.UPDATE_WIDGET_RESPONSE:
    case types.DELETE_WIDGET_RESPONSE:
      index = indexOfId(newState, action.request.id);
      newState[index] = action.payload.topview;
      return newState;

    case types.DELETE_TOPVIEW_RESPONSE:
      index = indexOfId(newState, action.request.id);
      newState.splice(index, 1);
      return newState;

    default:
      return state;
  }
}

export const propTypes = {
  topviews: nullable(topviewsPropTypes),
  topview: nullable(topviewPropTypes),
  widget: nullable(widgetPropTypes),
};

export default combineReducers({
  topviews,
});
