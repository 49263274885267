import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { reduxOperations } from '~services';
import { Page401Unauthorized } from '../../../pages/ErrorPages';
import { configurationFeature } from '~utils/featureToggles';

const propTypes = {
  setConfigurationMode: PropTypes.func.isRequired,
  children: PropTypes.node,
};

class ConfigPageWrapper extends Component {
  componentDidMount() {
    const { setConfigurationMode } = this.props;
    setConfigurationMode(true);
  }

  componentWillUnmount() {
    const { setConfigurationMode } = this.props;
    setConfigurationMode(false);
  }

  render() {
    const { children } = this.props;
    return (configurationFeature.isUserAllowedAccess()
      ? children
      : <Page401Unauthorized />
    );
  }
}

ConfigPageWrapper.propTypes = propTypes;

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setConfigurationMode: reduxOperations.views.setConfigurationMode,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ConfigPageWrapper);
