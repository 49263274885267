function initializeSelectedUsers(itemType, itemId, users, userId) {
  const usersToDisplay = users ? users.filter(user => user.display && user.id !== userId) : [];

  const usersOptions = usersToDisplay.map(user => ({
    label: user.name,
    value: user.id,
  }));
  let usersWithPermission;

  if (itemId) {
    usersWithPermission = usersToDisplay
      .reduce((result, user) => {
        const userHasTopviewAccess = user.permissions.contents
          .some(content => content.type === itemType && content.id === itemId);
        return userHasTopviewAccess ? [...result, { value: user.id, label: user.name }] : result;
      }, []);
  } else {
    usersWithPermission = [...usersOptions];
  }

  return usersWithPermission;
}

export { initializeSelectedUsers };
