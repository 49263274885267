import types from './types';

const getShiftsRequest = () => ({
  type: types.GET_SHIFTS_REQUEST,
});
const getShiftsResponse = response => ({
  type: types.GET_SHIFTS_RESPONSE,
  payload: response,
});

const addShiftsRequest = request => ({
  type: types.ADD_SHIFTS_REQUEST,
  payload: request,
});
const addShiftsResponse = response => ({
  type: types.ADD_SHIFTS_RESPONSE,
  payload: response,
});

const updateShiftsRequest = request => ({
  type: types.UPDATE_SHIFTS_REQUEST,
  payload: request,
});
const updateShiftsResponse = response => ({
  type: types.UPDATE_SHIFTS_RESPONSE,
  payload: response,
});

const deleteShiftsRequest = request => ({
  type: types.DELETE_SHIFTS_REQUEST,
  payload: request,
});
const deleteShiftsResponse = response => ({
  type: types.DELETE_SHIFTS_RESPONSE,
  payload: response,
});

export {
  getShiftsRequest,
  getShiftsResponse,
  addShiftsRequest,
  addShiftsResponse,
  updateShiftsRequest,
  updateShiftsResponse,
  deleteShiftsRequest,
  deleteShiftsResponse,
};
