/* eslint-disable class-methods-use-this */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import { showError } from '~utils/toast';
import ResourcesHandler from '~UI/Wrappers/ResourcesHandler';
import { reduxOperations } from '~services';
import API from '~services/endpoints';
import { getFormData } from '~utils';
import { millisToHHmmss, formatDuration } from '~utils/time';
import MachinePopup from './MachinePopup';
import MachineEventMenu from './MachineEventMenu';
import { usePrevious } from '~utils/hooks';

const getMenuInformations = (stopCauses, eventArg) => {
  let menuLevel = 1;
  let options = stopCauses;
  let selectedItemName = null;
  let previousStopCauseId = null;
  const menuStack = [];
  const breadcrumbs = [];

  const traverseMenu = (menu, level, parentId) => {
    for (let i = 0; i < menu.length; i += 1) {
      if (menu[i].id === eventArg?.stopCauseId) {
        menuLevel = level;
        options = menu;
        selectedItemName = menu[i].name;
        previousStopCauseId = parentId;
        return true;
      }
      if (menu[i].subMenu) {
        menuStack.push(menu);
        breadcrumbs.push(menu[i]);
        if (traverseMenu(menu[i].subMenu, level + 1, menu[i].id)) {
          return true;
        }
        menuStack.pop();
        breadcrumbs.pop();
      }
    }
    return false;
  };
  traverseMenu(stopCauses, 1);

  return {
    menuLevel,
    options,
    selectedItemName,
    previousStopCauseId,
    menuStack,
    breadcrumbs,
  };
};

const MachineEventPopup = ({
  show, onHide, machineId: machineIdProp, modifiedItemId,
  eventsList, event: eventProp, onSubmitTrigger, params,
  eventModifyTrigger, initialTab, mandatoryStopCause, mandatoryComment,
}) => {
  const dispatch = useDispatch();

  const language = useSelector(state => state.views.language);
  const machines = useSelector(state => state.machines);
  const { t } = useTranslation();

  const initEvent = eventsList.find(ev => ev.id === modifiedItemId);

  const [options, setOptions] = useState(null);
  const [menuLevel, setMenuLevel] = useState(1);
  const [stopCauseId, setStopCauseId] = useState(null);
  const [selectedItemName, setSelectedItemName] = useState(null);
  const [machineId, setMachineId] = useState(initEvent ? initEvent.machineId : machineIdProp);
  const previousProps = usePrevious({ machineIdProp, eventProp });
  const [event, setEvent] = useState(initEvent || eventProp);
  const [nextEvent, setNextEvent] = useState(null);
  const [searchInput, setSearchInput] = useState('');
  const [menuStack, setMenuStack] = useState([]);
  const [parentOption, setParentOption] = useState(null);
  const [stopCauseEN, setStopCauseEN] = useState(null);
  const [stopCauseFR, setStopCauseFR] = useState(null);
  const [stopCauseES, setStopCauseES] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const initialOptions = initEvent ? initEvent.stopCauses : machines.find(m => m.id === machineId)?.stopCauses;

  const fetchNextEvent = async eventArg => {
    if (eventArg) {
      const filter = {
        type: 'MachineStatus',
        machineId: eventArg.machineId,
        timestamp: {
          $gt: eventArg.timestamp,
        },
      };
      const sort = { timestamp: 1 };
      const newNextEvent = (await API.getEvents(filter, sort, 1)).events;
      setNextEvent(newNextEvent.length ? newNextEvent[0] : null);
    }
  };

  const initForm = (machineIdArg, eventArg) => {
    const machine = machines.find(m => m.id === machineIdArg);
    if (machine) {
      const {
        menuLevel: newMenuLevel,
        options: newOptions,
        selectedItemName: newSelectedItemName,
        menuStack: newMenuStack,
        breadcrumbs: newBreadcrumbs,
      } = getMenuInformations(machine.stopCauses, eventArg);
      setMenuStack(newMenuStack);
      setMenuLevel(newMenuLevel);
      setOptions(newOptions);
      setSelectedItemName(newSelectedItemName);
      setBreadcrumbs(newBreadcrumbs);

      if (eventArg?.stopCauseId) {
        setStopCauseId(eventArg.stopCauseId);

        // Find the stopCause in the options array
        const stopCause = newOptions.find(option => option.id === eventArg.stopCauseId);
        if (stopCause) {
          setStopCauseEN(stopCause.nameEN);
          setStopCauseFR(stopCause.nameFR);
          setStopCauseES(stopCause.nameES);
        }
      }

      fetchNextEvent(eventArg);
    }
  };

  const getEventDetails = () => {
    if (event) {
      const start = millisToHHmmss(event.timestamp);
      const end = nextEvent && millisToHHmmss(nextEvent.timestamp);

      const duration = nextEvent && formatDuration(
        nextEvent.timestamp - event.timestamp,
        {
          year: true,
          month: true,
          day: true,
          hourSeparator: 'h ',
          minSeparator: 'min ',
          secSeparator: 'sec',
        },
        true,
        language,
      );

      return (
        <div>
          <p>
            {nextEvent ? `${t('duration')}: ${duration} - ` : null}
            {nextEvent ? `${t('from')} ${start} ` : `${t('at')} ${start}`}
            {nextEvent ? `${t('to')} ${end}` : ''}
          </p>
        </div>
      );
    }
    return null;
  };

  const handleBreadcrumbClick = level => {
    setSelectedItemName(null);
    if (level === 0) {
      setBreadcrumbs([]);
      setMenuLevel(1);
      setOptions(initialOptions);
      setParentOption(null);
    } else {
      setBreadcrumbs(breadcrumbs.slice(0, level));
      setParentOption(breadcrumbs[level - 1]);
      setMenuLevel(level + 1);
      setOptions(breadcrumbs[level - 1].subMenu);
    }
  };

  const handleSelection = stopCauseIdArg => {
    const menuItem = options.find(sm => sm.id === stopCauseIdArg);
    setBreadcrumbs([...breadcrumbs, { ...menuItem, options: menuItem.subMenu, parentOption: menuItem }]);
    if (menuItem.subMenu?.length > 0) {
      setParentOption(menuItem);
      setMenuLevel(menuLevel + 1);
      setMenuStack([...menuStack, options]);
      setOptions(menuItem.subMenu);
      setSelectedItemName(null);
      setStopCauseId(stopCauseIdArg);
    } else {
      setSelectedItemName(menuItem.name);
      setStopCauseId(stopCauseIdArg);
    }
  };

  const handleBackButton = () => {
    setBreadcrumbs(breadcrumbs.slice(0, -1));
    if (selectedItemName) {
      setSelectedItemName(null);
    } else if (menuStack.length > 0) {
      setMenuLevel(menuLevel - 1);
      const newMenuStack = [...menuStack];
      const previousMenu = newMenuStack.pop();
      setMenuStack(newMenuStack);
      setOptions(previousMenu);
    }
  };

  const handleSubmit = async (scComments, scResolutions) => {
    // Call API to update event
    const formData = getFormData('Stop_Selection_Form');
    const comment = scComments || formData.comments;
    const resolution = scResolutions || formData.resolutions;
    // let fileInfo = null;

    // if (formData.optionalFile && formData.optionalFile.name.length) {
    //   const fileData = new FormData();
    //   fileData.append('file', formData.optionalFile);
    //   fileInfo = await API.uploadFile(fileData);
    // }
    if (mandatoryComment && (!comment || comment.trim().length === 0)) {
      showError(t('pleaseEnterComment'));
      return;
    }
    const updatedData = {
      motif: selectedItemName,
      stopCauseEN,
      stopCauseFR,
      stopCauseES,
      comments: comment,
      resolutions: resolution,
      // ...(fileInfo && { file: fileInfo.response.file.location }),
      stopCauseId,
    };
    // old events don't have an uuid
    const id = event.id || event._id || modifiedItemId;
    if (eventModifyTrigger) {
      eventModifyTrigger({ ...updatedData });
    } else {
      dispatch(reduxOperations.events.patchEvent(id, updatedData));
    }

    if (onSubmitTrigger) {
      const optionalTriggerParams = { ...params, ...formData, ...updatedData };
      dispatch(reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
        ...optionalTriggerParams,
        eventId: id,
      }));
    }

    onHide();
  };

  const handleClosePopup = () => {
    let hasError = false;
    if (mandatoryStopCause) {
      showError(t('pleaseSelectStopCause'));
      hasError = true;
    }
    if (mandatoryComment) {
      showError(t('pleaseEnterComment'));
      hasError = true;
    }
    if (!hasError) {
      onHide();
    }
  };

  useEffect(() => {
    if (show && event) {
      if (event.status === 'ON') {
        onHide();
      } else if (event.status === 'OFF') {
        setEvent(eventProp);
        initForm(machineId, event);
      }
    }
  }, [show, event]);

  useEffect(() => {
    if (previousProps?.machineIdProp !== machineIdProp) {
      setMachineId(machineIdProp);
    }
  }, [machineIdProp]);

  useEffect(() => {
    if (previousProps?.eventProp?.id !== eventProp?.id) {
      setEvent(eventProp);
    }
  }, [eventProp]);

  const searchBar = (
    <Input
      clearable
      style={{ width: '300px' }}
      onChange={value => setSearchInput(value)}
      onClear={() => setSearchInput('')}
      value={searchInput}
      placeholder={t('Search')}
    />
  );

  const getContents = () => {
    const machine = machines.find(m => m.id === machineId);

    return (
      <MachinePopup
        title={(machine ? `${machine.name} : ` : '') + (nextEvent ? t('resolvedStop') : t('detectedStop'))}
        subtitle={event ? getEventDetails() : null}
        searchBar={selectedItemName ? null : searchBar}
        show={show}
        onHide={handleClosePopup}
      >
        <MachineEventMenu
          name={selectedItemName}
          parentOption={parentOption}
          options={options}
          backPage={menuLevel > 1 || selectedItemName}
          comments={event?.comments}
          resolutions={event?.resolutions}
          file={event?.file}
          onBackPage={handleBackButton}
          onSelect={handleSelection}
          onSubmit={handleSubmit}
          onHide={(mandatoryStopCause || mandatoryComment) ? undefined : handleClosePopup}
          filter={searchInput}
          initialTab={initialTab}
          language={language}
          onBreadcrumbClick={handleBreadcrumbClick}
          breadcrumbs={breadcrumbs}
        />
      </MachinePopup>
    );
  };

  return (
    <ResourcesHandler
      resources={[machines]}
      resourceFetchers={[
        () => dispatch(reduxOperations.machines.fetchMachines()),
      ]}
      getContents={getContents}
    />
  );
};

MachineEventPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  machineId: PropTypes.string,
  modifiedItemId: PropTypes.string,
  eventsList: PropTypes.arrayOf(PropTypes.object),
  event: PropTypes.object,
  onSubmitTrigger: PropTypes.string,
  params: PropTypes.object,
  eventModifyTrigger: PropTypes.func,
  initialTab: PropTypes.string,
  mandatoryStopCause: PropTypes.bool,
  mandatoryComment: PropTypes.bool,
};
MachineEventPopup.defaultProps = {
  event: {},
  machineId: null,
  show: false,
  modifiedItemId: null,
  eventsList: [],
  onSubmitTrigger: '',
  params: {},
  eventModifyTrigger: null,
  initialTab: null,
  mandatoryStopCause: false,
  mandatoryComment: false,
};

export default MachineEventPopup;
