import React from 'react';
import PropTypes from 'prop-types';
import HttpStatus from 'http-status-codes';
import { useTranslation } from 'react-i18next';
import FontAwesome from '~UI/FontAwesome';
import ConfirmationForm from './ConfirmationForm';
import { showSuccess, showError } from '~utils/toast';

const propTypes = {
  handleDelete: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  // global state props
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

const title = t => (
  <>
    <FontAwesome
      icon="exclamation-triangle"
      color="#bf3838"
    />
      &nbsp;
    {t('deleteConfirmation')}
  </>
);

const DeleteConfirmationForm = ({
  handleDelete, show, onHide, body,
}) => {
  const { t } = useTranslation();
  return (
    <ConfirmationForm
      title={title(t)}
      body={body || t('beSureDeletion')}
      actionLabel={t('delete')}
      show={show}
      onHide={onHide}
      confirmButtonColor="#bf3838"
      handleAction={() => {
        const promise = handleDelete();
        if (promise) {
          promise
            .then(res => {
              if (res !== 'Do not show') {
                showSuccess(t('showSuccessDeleted'));
              }
            })
            .catch(err => {
              if (err.code === HttpStatus.FAILED_DEPENDENCY) {
                showError(t('isReferencedError'));
              } else if (err.code === HttpStatus.NOT_FOUND) {
                showError(HttpStatus.NOT_FOUND);
              } else {
                showError(t('couldNotDelete'));
              }
            });
        }
      }}
    />
  );
};

DeleteConfirmationForm.propTypes = propTypes;

export default DeleteConfirmationForm;
