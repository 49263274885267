import {
  getReportsRequest,
  getReportsResponse,
  addReportRequest,
  addReportResponse,
  updateReportRequest,
  updateReportResponse,
  deleteReportRequest,
  deleteReportResponse,
} from './actions';

import { fetchOnceOperation } from '../fetchOperation';
import API from '~services/endpoints';

const fetchReports = () => fetchOnceOperation(
  getReportsRequest,
  getReportsResponse,
  API.getReports,
  state => state.reports.reports,
);

const addReport = report => dispatch => {
  dispatch(addReportRequest(report));
  return API.addReport(report)
    .then(res => dispatch(addReportResponse(res)));
};

const updateReport = (id, report) => dispatch => {
  dispatch(updateReportRequest(id, report));
  return API.updateReport(id, report)
    .then(res => dispatch(updateReportResponse(res)));
};

const deleteReport = id => dispatch => {
  dispatch(deleteReportRequest(id));
  return API.deleteReport(id)
    .then(res => dispatch(deleteReportResponse(res)));
};

export default {
  fetchReports,
  addReport,
  updateReport,
  deleteReport,
};
