import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FavoritesHandler from '~components/UI/Wrappers/FavoritesHandler';
import imagesLibrary from '~images/imagesLibrary';
import './FrameGrid.scss';

const propTypes = {
  tiles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.array,
  ]),
};
const defaultProps = {
  tiles: [],
};

const FrameGrid = ({ tiles }) => (
  <div className="FrameGridContainer">
    <div className="FrameGrid">
      {
        tiles.map((tile, index) => (
          <div className="CardContainer">
            <div className="CardFavorite">
              <FavoritesHandler favoriteId={tile.id} favoriteType={tile.type} />
            </div>
            <Link className="Card" key={`bookmark_${index}`} to={tile.link}>
              <div
                className="CardImageHolder"
                style={{
                  backgroundImage: !tile.img ? `url(${imagesLibrary.LogoIIDark})` : undefined,
                }}
              >
                {tile.img && <img alt="bookmark" className="CardImage" src={tile.img} />}
              </div>
              <div className="CardText">{tile.name}</div>
            </Link>
          </div>
        ))
      }
    </div>
  </div>
);

FrameGrid.propTypes = propTypes;
FrameGrid.defaultProps = defaultProps;

export default FrameGrid;
