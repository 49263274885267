import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import { PageTemplate } from '~components/Pages';
import { ResourcesHandler } from '~components/UI';
import { Page404NotFound } from '../../../ErrorPages';
import directLinks from '~utils/directLinks';
import { triggerTypes } from '~utils/types';
import './RuleEditionPages.scss';
import RuleEditionPageContent from './RuleEditionPageContent';

const configPath = directLinks.utils.configPathFrom;

const RuleEditionPage = () => {
  const dispatch = useDispatch();

  const actions = useSelector((state: RootState) => state.actions);
  const machines = useSelector((state: RootState) => state.machines);
  const streams = useSelector((state: RootState) => state.streams);
  const stopwatches = useSelector((state: RootState) => state.stopwatches.stopwatches);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const triggers = useSelector((state: RootState) => state.triggers);
  const users = useSelector((state: RootState) => state.users.users);
  const variables = useSelector((state: RootState) => state.variables);
  const operations = useSelector((state: RootState) => state.operations.operations);
  const operators = useSelector((state: RootState) => state.operators);
  const shifts = useSelector((state: RootState) => state.shifts.shifts);

  const [trigger, setTrigger] = useState(null);
  const [isNewTrigger, setIsNewTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const goBack = () => {
    history.push(configPath(directLinks.ruleEngine()));
  };

  const escapeFunction = event => {
    // Escape Key
    if (event.keyCode === 27) {
      goBack();
    }
  };

  useEffect(() => {
    if (triggers !== undefined && triggers !== null) {
      setIsLoading(false);
    }
  }, [triggers]);

  useEffect(() => {
    document.addEventListener('keydown', escapeFunction, false);

    return () => {
      document.removeEventListener('keydown', escapeFunction, false);
    };
  }, []);

  const getContents = ([triggersArg]) => {
    if (triggersArg && !trigger) {
      const match = history.location.pathname.split('/');
      const triggerId = match[3];
      // TODO: check the reality with our client of default vs button trigger
      // Check why in the server schemas, Default trigger can have a detail type named DefaultTrigger
      // IsFakeDefault == isButtonTrigger (button trigger is a Default Trigger dressed as a button)
      const rawNewTrigger = triggersArg.find(element => element.id === triggerId);
      const isFakeDefault = rawNewTrigger
        && rawNewTrigger.type === triggerTypes.DEFAULT && rawNewTrigger.details && rawNewTrigger.details.type;

      const newTrigger = isFakeDefault
        ? { ...rawNewTrigger, type: rawNewTrigger.details.type }
        : rawNewTrigger;

      if (newTrigger && trigger !== newTrigger) {
        setTrigger(newTrigger);
      } else if (triggerId === 'newTrigger' && !isNewTrigger) {
        setIsNewTrigger(true);
      }
    }

    return (
      isNewTrigger || trigger || isLoading ? (
        <PageTemplate
          sidebar
        >
          <RuleEditionPageContent
            goBack={goBack}
            trigger={trigger || undefined}
          />
        </PageTemplate>
      )
        : <Page404NotFound />
    );
  };

  return (
    <ResourcesHandler
      resources={[triggers, actions, variables, streams, users, stopwatches, machines, tags, operations,
        operators, shifts]}
      resourceFetchers={[
        () => dispatch(reduxOperations.triggers.fetchTriggers() as any),
        () => dispatch(reduxOperations.actions.fetchActions() as any),
        () => dispatch(reduxOperations.variables.fetchVariables() as any),
        () => dispatch(reduxOperations.streams.fetchStreams() as any),
        () => dispatch(reduxOperations.users.fetchUsers() as any),
        () => dispatch(reduxOperations.stopwatches.fetchStopwatches() as any),
        () => dispatch(reduxOperations.machines.fetchMachines() as any),
        () => dispatch(reduxOperations.tags.fetchTags() as any),
        () => dispatch(reduxOperations.operations.fetchOperations() as any),
        () => dispatch(reduxOperations.operators.fetchOperators() as any),
        () => dispatch(reduxOperations.shifts.fetchShifts() as any),
      ]}
      getContents={getContents}
    />
  );
};

export default RuleEditionPage;
