import React from 'react';
import PropTypes from 'prop-types';

import { FormAudio } from '~components/forms';

const FormTileAudio = ({ selectedTile }) => (
  <FormAudio
    selectedObject={selectedTile}
  />
);

FormTileAudio.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileAudio.defaultProps = {
  selectedTile: {},
};

export { FormTileAudio };
