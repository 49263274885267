import React, { useEffect, useState } from 'react';
import { MAX_NB_EVENTS_ONE_PAGE } from '~utils/constants';
import { DefaultTable } from '~components/Pages';

interface LogsListProps {
  columnNames: Array<object>;
  entriesProperties: Array<string>;
  entriesStyle?: Array<object>;
  filteredList: Array<object>;
  leftHeader: JSX.Element;
  noPagination?: boolean;
}

const LogsList: React.FC<LogsListProps> = ({
  columnNames,
  entriesProperties,
  entriesStyle,
  filteredList,
  leftHeader,
  noPagination,
}) => {
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setNumberOfPages(Math.ceil(filteredList.length / MAX_NB_EVENTS_ONE_PAGE));
    setCurrentPage(1);
  }, [filteredList]);

  const getPageNavigation = () => {
    const rows : JSX.Element[] = [];
    for (let i = 1; i <= numberOfPages; i += 1) {
      if (i === 1 || (currentPage + 1 >= i && currentPage - 1 <= i) || i === numberOfPages) {
        rows.push(
          <span
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`numbering ${currentPage === i ? 'active' : ''}`}
          >
            {i}
          </span>,
        );
      } else if (currentPage + 4 >= i && currentPage - 4 <= i) {
        rows.push(<span key={i} className="noNumbering">.</span>);
      }
    }
    return (
      <div className="pagination">
        <span
          className={`arrowLeft ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          &lang;
        </span>
        {rows}
        <span
          className={`arrowRight ${currentPage === numberOfPages ? 'disabled' : ''}`}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          &rang;
        </span>
      </div>
    );
  };

  const getEntries = () => (noPagination
    ? filteredList
    : filteredList.slice((currentPage - 1) * MAX_NB_EVENTS_ONE_PAGE, currentPage * MAX_NB_EVENTS_ONE_PAGE));

  return (
    <div>
      <div className="header">
        {leftHeader}
        {!noPagination && (
          <div className="topPagination">
            {getPageNavigation()}
          </div>
        )}
      </div>

      <DefaultTable
        columnNames={columnNames}
        entriesProperties={entriesProperties}
        entries={getEntries()}
        entriesStyle={entriesStyle}
        noNumbering
      />
      {!noPagination && (
        <div className="bottomPagination">
          {getPageNavigation()}
        </div>
      )}
    </div>
  );
};

LogsList.defaultProps = {
  noPagination: false,
  entriesStyle: [],
};

export { LogsList };

