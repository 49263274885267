import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const tagPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

const tagsPropTypes = PropTypes.arrayOf(tagPropTypes);

function tags(state = null, action) {
  switch (action.type) {
    case types.GET_TAGS_RESPONSE:
      return action.payload.tags;
    case types.ADD_TAG_RESPONSE:
      if (action.payload.error || !state) { return state; }
      return state.concat(action.payload.tag);
    case types.UPDATE_TAG_RESPONSE:
      if (action.payload.error || !state) { return state; }
      return state.map(tag => ((tag.id === action.payload.tag.id) ? action.payload.tag : tag));
    case types.DELETE_TAG_RESPONSE:
      if (action.payload.error || !state) { return state; }
      return state.filter(tag => tag.id !== action.payload.tagId);
    default:
      return state;
  }
}

export const propTypes = {
  tags: nullable(tagsPropTypes),
};

export default combineReducers({
  tags,
});
