import React from 'react';
import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import classnames from 'classnames';
import './Button.scss';

const DefaultThemedButton = ({ content, className, isActive, ...props }) => (
  <button
    type="button"
    className={classnames({
      DefaultThemedButton: true,
      IsActive: isActive,
      ...(className && { [className]: true }),
    })}
    {...props}
  >
    {content}
  </button>
);

DefaultThemedButton.propTypes = {
  content: nullable(
    PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
    ]),
  ),
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

DefaultThemedButton.defaultProps = {
  content: null,
  className: '',
  isActive: false,
};

export default DefaultThemedButton;
