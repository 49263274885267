import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { CheckboxToggle } from '~UI';
import IntervalTimePicker from '~UI/IntervalTimePicker/IntervalTimePicker';
import { sortArray } from '~utils/sort';

const VariableForm = ({ selectedObject, shouldRenderComparisonForm, renderAggregateForm, updateVariableUnits }) => {
  const { t } = useTranslation();

  const variables = useSelector(state => state.variables);
  const streams = useSelector(state => state.streams);
  const machines = useSelector(state => state.machines);

  const [isEnabled, setIsEnabled] = useState(false);
  const [valueId, setValueId] = useState(selectedObject.valueId
    ? selectedObject.valueId : variables.length > 0 && variables[0].id);
  const [isAggregated, setIsAggregated] = useState(selectedObject.isAggregated);

  const getValue = variableId => {
    const properties = [].concat(...streams.map(s => s.properties));
    const kpis = [].concat(...machines.map(m => m.kpis || []));
    const all = [...properties, ...variables, ...kpis];

    return all.find(elem => elem.id === variableId);
  };

  useEffect(() => {
    const variable = getValue(valueId);
    if (!variable) {
      return;
    }

    const isEnabledVar = getValue(variable.id).isEnabled || false;
    shouldRenderComparisonForm(isEnabledVar);
    setIsEnabled(isEnabledVar);
  }, []);

  const handleIdChange = varId => {
    const variable = getValue(varId);
    updateVariableUnits(variable);

    shouldRenderComparisonForm(variable.isEnabled || false);
    setValueId(varId);
    setIsEnabled(variable.isEnabled || false);
    setIsAggregated(false);
  };

  const handleChangeIsAggregated = () => {
    setIsAggregated(prevIsAggregated => !prevIsAggregated);
  };

  const properties = [].concat(...streams.map(s => s.properties));
  const kpis = [].concat(...machines.map(m => m.kpis || []));
  const all = sortArray('alphabetically', [...properties, ...variables, ...kpis], 'variable');
  const valueStillExists = !valueId || all.find(v => v.id === valueId);

  return (
    <>
      <div className="inputTitle">{t('variable')}</div>
      {!valueStillExists && `${t('errorDeletedVariable')}.`}
      <Select
        name="valueId"
        options={all.map(e => ({ label: e.variable, value: e.id }))}
        value={valueId}
        onChange={handleIdChange}
        placeholder={t('select')}
      />
      <div className="inputTitle">
        {t('dataCollectionPeriod')}
        &nbsp;
        <CheckboxToggle
          name="isAggregated"
          controlledCheck={isEnabled && isAggregated}
          readOnly={!isEnabled}
          onChange={handleChangeIsAggregated}
        />
      </div>
      {
        isEnabled && isAggregated && (
          <>
            <IntervalTimePicker
              intervalType={selectedObject.intervalType}
              intervalTypeInput
            />
            {isAggregated && renderAggregateForm('aggregateType')}
          </>
        )
      }
    </>
  );
};

VariableForm.propTypes = {
  renderAggregateForm: PropTypes.func.isRequired,
  shouldRenderComparisonForm: PropTypes.func.isRequired,
  updateVariableUnits: PropTypes.func.isRequired,
  selectedObject: PropTypes.object,
};
VariableForm.defaultProps = {
  selectedObject: {},
};

export default VariableForm;
