import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Responsive } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import deepEqual from 'deep-equal';
import omit from 'lodash.omit';
import { reduxOperations } from '~services/index';
import { RootState } from '~services/store';
import Tile from './Tile';
import DashboardSizes from './DashboardSizes';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './Dashboard.scss';

const ResponsiveReactGridLayout = Responsive;
const MENU_FOOTER_HEIGHT = 100;
const TILE_ASPECT_RATIO = 0.8;
const MIN_TILE_ASPECT_RATIO = 0.5;
const STANDARD_ROWS_NUMBER = 6;

const propTypes = {
  dashboard: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string,
    name: PropTypes.string,
    tiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        w: PropTypes.number.isRequired,
        h: PropTypes.number.isRequired,
      }),
    ).isRequired,
    createdAt: PropTypes.number,
    modifiedAt: PropTypes.number,
    createdBy: PropTypes.string,
    modifiedBy: PropTypes.string,
  }).isRequired,
  isConfigurationAllowed: PropTypes.bool.isRequired,
};

const Dashboard = ({ dashboard, isConfigurationAllowed }) => {
  const dispatch = useDispatch();

  const isInConfigurationMode = useSelector((state: RootState) => state.views.isInConfigurationMode);
  const settings = useSelector((state: RootState) => state.settings.settings);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getNumberOfColumns = dashboardWidth => {
    const { breakpoint, column } = (settings && settings.dashboardSizes) || DashboardSizes;
    if (dashboardWidth <= Math.round(breakpoint.xsmall * (dashboardWidth / windowWidth))) {
      return column.xxsmall;
    }
    if (dashboardWidth <= Math.round(breakpoint.small * (dashboardWidth / windowWidth))) {
      return column.xsmall;
    }
    if (dashboardWidth <= Math.round(breakpoint.medium * (dashboardWidth / windowWidth))) {
      return column.small;
    }
    if (dashboardWidth <= Math.round(breakpoint.large * (dashboardWidth / windowWidth))) {
      return column.medium;
    }
    return column.large;
  };

  const getUpdatedUnitWidth = width => {
    const numberOfColumns = getNumberOfColumns(width);
    return width / (numberOfColumns < 4 ? 4 : numberOfColumns);
  };

  const getUpdatedUnitHeight = (width, height) => {
    const dashboardSizes = (settings && settings.dashboardSizes) || DashboardSizes;
    const unitHeight = height / dashboardSizes.rows;
    let aspectRatio = TILE_ASPECT_RATIO;
    if (dashboardSizes.rows > STANDARD_ROWS_NUMBER) {
      aspectRatio = Math.max(TILE_ASPECT_RATIO * (STANDARD_ROWS_NUMBER / dashboardSizes.rows), MIN_TILE_ASPECT_RATIO);
    }
    const minHeight = getUpdatedUnitWidth(width) * aspectRatio;
    return unitHeight > minHeight ? unitHeight : minHeight;
  };

  const [tiles, setTiles] = useState(dashboard.tiles);

  useEffect(() => {
    const newTiles = [...dashboard.tiles];
    setTiles(newTiles);
  }, [dashboard.tiles]);

  const handleTileChange = (newLayout, oldItem, newItem) => {
    if (isInConfigurationMode && !deepEqual(oldItem, newItem, { strict: true })) {
      const newLayoutClean = newLayout.map(tile => (
        omit(
          { ...tile, id: tile.i },
          ['i', 'isDraggable', 'isResizable', 'maxH', 'minH', 'maxW', 'minW', 'moved', 'static'],
        )));
      dispatch(reduxOperations.dashboards.updateManyDashboardTiles(dashboard.id, newLayoutClean) as any);
      setTiles(newLayoutClean);
    }
  };

  const handleSuppression = id => dispatch(reduxOperations.dashboards.deleteDashboardTile(dashboard.id, id) as any)
    .then(setTiles(prevTiles => prevTiles.filter(tile => tile.id !== id)));
  const { breakpoint, column } = (settings && settings.dashboardSizes) || DashboardSizes;

  return (
    <SizeMe
      monitorHeight
    >
      {
        ({ size }) => {
          const { width, height } = size;
          const unitWidth = getUpdatedUnitWidth(width || windowWidth);
          const unitHeight = getUpdatedUnitHeight(
            width || windowWidth,
            height || (window.innerHeight - MENU_FOOTER_HEIGHT),
          );
          return (
            <div className="Dashboard framed">
              <ResponsiveReactGridLayout
                useCSSTransforms={false}
                className="Workspace__Dashboard"
                layouts={{ lg: tiles.map(tile => ({ ...tile, i: tile.id })) }}
                breakpoints={{
                  lg: Math.round(breakpoint.large * (width ? width / windowWidth : 1)),
                  md: Math.round(breakpoint.medium * (width ? width / windowWidth : 1)),
                  sm: Math.round(breakpoint.small * (width ? width / windowWidth : 1)),
                  xs: Math.round(breakpoint.xsmall * (width ? width / windowWidth : 1)),
                  xxs: Math.round(breakpoint.xxsmall * (width ? width / windowWidth : 1)),
                }}
                cols={{
                  lg: column.large,
                  md: column.medium,
                  sm: column.small,
                  xs: column.xsmall,
                  xxs: column.xxsmall,
                }}
                margin={[3, 3]}
                rowHeight={unitHeight - 3.5}
                onDragStop={handleTileChange}
                onResizeStop={handleTileChange}
                isDraggable={isConfigurationAllowed}
                isResizable={isConfigurationAllowed}
                draggableHandle={isConfigurationAllowed ? null : '.no-exist'}
                draggableCancel=".undraggable"
                width={width || windowWidth}
              >
                {
                  tiles.map(tile => (
                    <div
                      id={tile.id}
                      key={tile.id}
                      data-grid={tile}
                    >
                      <Tile
                        tile={tile}
                        isConfigurationAllowed={isConfigurationAllowed}
                        onSuppression={() => handleSuppression(tile.id)}
                        height={tile.h * unitHeight}
                        width={tile.w * unitWidth}
                        dashboardId={dashboard.id}
                      />
                    </div>
                  ))
                }
              </ResponsiveReactGridLayout>
              <div style={{ paddingBottom: '50px' }} />
            </div>
          );
        }
      }
    </SizeMe>
  );
};

Dashboard.propTypes = propTypes;

export default Dashboard;
