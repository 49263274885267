export function findNestedCause(causes, causeId) {
  if (!causes) {
    return null;
  }

  for (const cause of causes) {
    if (cause.id === causeId) {
      return cause;
    }

    if (cause.subMenu) {
      const foundCause = findNestedCause(cause.subMenu, causeId);
      if (foundCause) {
        return foundCause;
      }
    }
  }

  return null;
}
