import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import Tick from '~utils/Tick';
import { getDataRangeStartEnd } from '~utils/time';
import { useShift } from '~utils/hooks';
import AreaChartComponent from './AreaChartComponent';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';

const AreaChartEvents = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();
  const [currentShift, shifts] = useShift(null);

  const [data, setData] = useState(null);
  const [events, setEvents] = useState([]);

  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);

  const handleSocketEvent = socketEvent => {
    if (socketEvent.name === tile.axisY && !hasUsedArrows) {
      events.push(socketEvent);
      setEvents(events);
    }
  };

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const evaluateEvents = eventsArg => {
    const { start, end } = timePeriod;

    const lapse = Math.round((end - start) / 1500); // 1500 points on the graph
    const newData = {};

    if (eventsArg.length) {
      eventsArg.forEach((ev, index) => {
        const eventRelativeTime = ev.timestamp - start;
        const relativeIndex = Math.trunc(eventRelativeTime / lapse);
        const x = start + lapse * relativeIndex;
        newData[x] = {
          x,
          y: index,
        };
      });
    }

    const result = Object.keys(newData).map(key => newData[key]);
    setData(result);
  };

  const fetchEvents = async () => {
    const { start, end } = timePeriod;

    const filter = {
      name: tile.axisY,
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };

    const { events: newEvents } = await API.getEvents(filter, { timestamp: 1 });
    setEvents(newEvents);
    evaluateEvents(newEvents);
  };

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, tile.intervalType || 'shift');
    setEvents([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  useEffect(() => {
    if (socket) {
      socket.on('event', handleSocketEvent);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
      }
    };
  }, [socket]);

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    fetchEvents();
  }, [timePeriod, hasUsedArrows]);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, 20); // 20 secondes

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [tile, tile.intervalType, currentShift]);

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  return (
    <AreaChartComponent
      backgroundColor={backgroundColor}
      canNavigateTime={goBackward => canNavigateTime(goBackward, timePeriod, shifts, tile.intervalType || 'shift')}
      data={data}
      hasUsedArrows={hasUsedArrows}
      height={height}
      navigateTime={navigateTime}
      tile={tile}
      timePeriod={timePeriod}
      width={width}
    />
  );
};

AreaChartEvents.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  tile: PropTypes.shape({
    axisY: PropTypes.string,
    intervalType: PropTypes.string,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default AreaChartEvents;
