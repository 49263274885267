import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getTitle } from '~utils';
import SvgWidget from '../BaseWidgets/SvgWidget';
import { Scorecard } from '~UI/svg/Scorecard';

const TITLE_POSITION = 0.10;

const ScorecardWidget = ({
  backgroundColor, h, w, x, y, widget, fetchDelay,
}) => {
  const featureToggles = useSelector(state => state.settings.settings.featureToggles);
  const language = useSelector(state => state.views.language);

  const title = featureToggles.features?.tileTranslations ? getTitle(widget, language) : widget?.title || '';

  const scaledDownHeight = widget.title ? h * (1 - TITLE_POSITION) : h;
  let movedPosition = widget.title ? y + h * TITLE_POSITION : y;

  if (widget?.unitPosition === 'right' || widget?.unitPosition === 'left') {
    movedPosition += h * 0.15;
  } else if (widget?.unitPosition === 'bottom') {
    movedPosition += h * 0.1;
  }

  const loaderPos = {
    x,
    y: y + h / 3,
    width: w,
    height: h,
  };

  return (
    <SvgWidget
      dimension={{ x, y, w, h }}
      backgroundColor={backgroundColor}
      shape={widget.format.shape}
      title={title}
      titleSize={widget.titleSize}
    >
      <Scorecard
        selectedObject={widget}
        fetchDelay={fetchDelay}
        dimension={{ x, y: movedPosition, w, h: scaledDownHeight }}
        loaderPos={loaderPos}
        backgroundColor={backgroundColor}
        isCircle={widget.format && widget.format.shape === 'circle'}
      />
    </SvgWidget>
  );
};

ScorecardWidget.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  fetchDelay: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  widget: PropTypes.shape({
    format: PropTypes.shape({
      shape: PropTypes.string,
    }),
    title: PropTypes.string,
    titleSize: PropTypes.number,
    unitPosition: PropTypes.string,
  }).isRequired,
};

export default ScorecardWidget;
