import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { BarChart } from '~UI/svg';
import { FontAwesome } from '~UI/index';
import { dateToStringWithoutYearAndMillis } from '~utils/time';
import TileContents from '../TileContents';
import '../TileContents.scss';

const BarChartComponent = ({
  backgroundColor,
  data,
  height,
  timePeriod,
  tile,
  hasUsedArrows,
  canNavigateTime,
  navigateTime,
  width,
}) => {
  const container = useRef();

  const { t } = useTranslation();

  const navigationText = hasUsedArrows
    ? `${t('from2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.start))} ${t('to2')} ${dateToStringWithoutYearAndMillis(new Date(timePeriod.end))}`
    : '';

  return (
    <TileContents
      tile={tile}
      backgroundColor={backgroundColor}
      ref={container}
      height={height}
      width={width}
    >
      {!data && <Loader />}
      <div className={`timeNavigationContainer ${!data && 'hide'}`}>
        <div>{navigationText}</div>
        <div className="timeNavigation">
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-left"
              className="timeNavigationButton"
              style={{ opacity: !canNavigateTime(true) && 0.4, cursor: !canNavigateTime(true) && 'default' }}
              onClick={() => navigateTime(true)}
            />
          )}
          <BarChart
            height={hasUsedArrows ? height - 26 : height}
            width={tile.showArrows ? width - 70 : width}
            data={data || []}
            timePeriod={timePeriod}
            showGoalToggle={typeof tile.showGoalToggle === 'boolean' ? tile.showGoalToggle : false}
            goal={tile.goal}
            showBarsValues={tile.showBarsValues}
            showArrows={tile.showArrows}
          />
          {tile.showArrows && (
            <FontAwesome
              icon="arrow-right"
              className="timeNavigationButton"
              style={{ opacity: !canNavigateTime(false) && 0.4, cursor: !canNavigateTime(false) && 'default' }}
              onClick={() => navigateTime(false)}
            />
          )}
        </div>
      </div>
    </TileContents>
  );
};

BarChartComponent.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.number,
  timePeriod: PropTypes.shape({
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
  }).isRequired,
  tile: PropTypes.shape({
    id: PropTypes.string,
    goal: PropTypes.number,
    title: PropTypes.string,
    showBarsValues: PropTypes.bool,
    intervalType: PropTypes.string,
    showArrows: PropTypes.bool,
    showGoalToggle: PropTypes.bool,
  }).isRequired,
  hasUsedArrows: PropTypes.bool,
  canNavigateTime: PropTypes.func,
  navigateTime: PropTypes.func,
  width: PropTypes.number,
};
BarChartComponent.defaultProps = {
  data: [],
  hasUsedArrows: false,
  canNavigateTime: () => undefined,
  navigateTime: () => undefined,
};

export default BarChartComponent;
