import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { reducersTypes, reduxOperations } from '~services/index';
import { isExternalLink } from '~utils/isExternalLink';

const ImageMedia = ({ displayURL, widget }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  function handleClick() {
    if (widget.openNewTab) {
      window.open(widget.linksTo, '_blank');
    } else if (widget.linksTo) {
      if ((isExternalLink(widget.linksTo) && !widget.linksTo.startsWith('/')) || widget.linksTo.includes('redirect')) {
        window.location.href = widget.linksTo;
      } else {
        const hostPath = widget.linksTo.replace('http://', '').replace('https://', '').split('/')[0];
        const inAppURL = widget.linksTo.replace(hostPath, '').replace('http://', '').replace('https://', '');
        dispatch(reduxOperations.redirectHistory.redirectHistoryPush(window.location.pathname));
        history.push(inAppURL);
      }
    }
  }

  return (
    <div
      className="framed flex V"
      onClick={handleClick}
      role={widget.linksTo && 'button'}
    >
      <img
        className="Dashboard__Widget__Contents__Valeur__Media"
        src={displayURL}
        alt="Failed to load"
        style={widget.format.shape === 'circle' ? { borderRadius: '50%' } : {}}
      />
    </div>
  );
};

ImageMedia.propTypes = {
  displayURL: PropTypes.string.isRequired,
  widget: reducersTypes.topviews.widget.isRequired,
};

export { ImageMedia };
