import { Operator } from './types';

export enum OperatorActionTypes {
  ADD_OPERATOR_REQUEST = 'ADD_OPERATOR_REQUEST',
  ADD_OPERATOR_RESPONSE = 'ADD_OPERATOR_RESPONSE',
  GET_OPERATORS_REQUEST = 'GET_OPERATORS_REQUEST',
  GET_OPERATORS_RESPONSE = 'GET_OPERATORS_RESPONSE',
  UPDATE_OPERATOR_REQUEST = 'UPDATE_OPERATOR_REQUEST',
  UPDATE_OPERATOR_RESPONSE = 'UPDATE_OPERATOR_RESPONSE',
  DELETE_OPERATOR_REQUEST = 'DELETE_OPERATOR_REQUEST',
  DELETE_OPERATOR_RESPONSE = 'DELETE_OPERATOR_RESPONSE',
}

export type OperatorAction =
  | { type: OperatorActionTypes.ADD_OPERATOR_REQUEST; payload: { operator: Operator } }
  | { type: OperatorActionTypes.ADD_OPERATOR_RESPONSE; payload: { operator: Operator } }
  | { type: OperatorActionTypes.GET_OPERATORS_REQUEST; }
  | { type: OperatorActionTypes.GET_OPERATORS_RESPONSE; payload: { operators: Operator[] } }
  | { type: OperatorActionTypes.UPDATE_OPERATOR_REQUEST; payload: { id: string; operator: Operator } }
  | { type: OperatorActionTypes.UPDATE_OPERATOR_RESPONSE; payload: { operator: Operator } }
  | { type: OperatorActionTypes.DELETE_OPERATOR_REQUEST; payload: { id: string } }
  | { type: OperatorActionTypes.DELETE_OPERATOR_RESPONSE; payload: { id: string } };

export const getOperatorsRequest = (): OperatorAction => ({
  type: OperatorActionTypes.GET_OPERATORS_REQUEST,
});

export const getOperatorsResponse = (payload: { operators: Operator[] }): OperatorAction => ({
  type: OperatorActionTypes.GET_OPERATORS_RESPONSE,
  payload,
});

export const addOperatorRequest = (payload: { operator: Operator }): OperatorAction => ({
  type: OperatorActionTypes.ADD_OPERATOR_REQUEST,
  payload,
});

export const addOperatorResponse = (payload: { operator: Operator }): OperatorAction => ({
  type: OperatorActionTypes.ADD_OPERATOR_RESPONSE,
  payload,
});

export const updateOperatorRequest = (payload: { id: string, operator: Operator }): OperatorAction => ({
  type: OperatorActionTypes.UPDATE_OPERATOR_REQUEST,
  payload,
});

export const updateOperatorResponse = (payload: { operator: Operator }): OperatorAction => ({
  type: OperatorActionTypes.UPDATE_OPERATOR_RESPONSE,
  payload,
});

export const deleteOperatorRequest = (payload: { id: string }): OperatorAction => ({
  type: OperatorActionTypes.DELETE_OPERATOR_REQUEST,
  payload,
});

export const deleteOperatorResponse = (payload: { id: string }): OperatorAction => ({
  type: OperatorActionTypes.DELETE_OPERATOR_RESPONSE,
  payload,
});
