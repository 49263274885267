import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cloneDeep from 'lodash.clonedeep';
import { Accordion, Checkbox } from '@intelligenceindustrielle/react-ui-components';

// DB to Form for contents
const parseDbToForm = (contents, obj, contentType) => {
  const result = {};
  obj?.forEach(({ id, name }) => {
    const state = contents && contents.some(item => (item.type === contentType && item.id === id));
    result[id] = { id, name, state };
  });
  return result;
};

// Form to DB format
const parseFormToDb = (permObj, type) => Object.values(permObj)
  .filter(i => i.state && i.id !== i.name)
  .map(item => ({
    type,
    id: item.id,
  }));

const ContentPermissionTable = ({ contents, onChange }) => {
  const { t } = useTranslation();
  const dashboards = useSelector(state => state.dashboards.dashboards);
  const reports = useSelector(state => state.reports.reports);
  const topviews = useSelector(state => state.topviews.topviews);

  const [permObjDashboards, setDashboards] = useState(parseDbToForm(contents, dashboards, 'Dashboard'));
  const [permObjReports, setReports] = useState(parseDbToForm(contents, reports, 'Report'));
  const [permObjTopviews, setTopviews] = useState(parseDbToForm(contents, topviews, 'Topview'));

  useEffect(() => {
    setDashboards(parseDbToForm(contents, dashboards, 'Dashboard'));
    setReports(parseDbToForm(contents, reports, 'Report'));
    setTopviews(parseDbToForm(contents, topviews, 'Topview'));
  }, [contents]);

  function handleCheckboxChange(itemId, oldValue) {
    const isChecked = !oldValue;
    const cloneDashboardsPerm = cloneDeep(permObjDashboards);
    const cloneReportsPerm = cloneDeep(permObjReports);
    const cloneTopviewsPerm = cloneDeep(permObjTopviews);

    if (cloneDashboardsPerm[itemId] !== undefined) {
      cloneDashboardsPerm[itemId].state = isChecked;
    }
    if (cloneReportsPerm[itemId] !== undefined) {
      cloneReportsPerm[itemId].state = isChecked;
    }
    if (cloneTopviewsPerm[itemId] !== undefined) {
      cloneTopviewsPerm[itemId].state = isChecked;
    }

    onChange([
      ...parseFormToDb(cloneDashboardsPerm, 'Dashboard'),
      ...parseFormToDb(cloneReportsPerm, 'Report'),
      ...parseFormToDb(cloneTopviewsPerm, 'Topview'),
    ]);
    setDashboards(cloneDashboardsPerm);
    setReports(cloneReportsPerm);
    setTopviews(cloneTopviewsPerm);
  }

  const renderCheckbox = (feature, value) => (
    <Checkbox
      checked={value}
      onChange={() => handleCheckboxChange(feature, value)}
    />
  );

  function selectAll(e, type) {
    const cloneDashboardsPerm = cloneDeep(permObjDashboards);
    const cloneReportsPerm = cloneDeep(permObjReports);
    const cloneTopviewsPerm = cloneDeep(permObjTopviews);

    if (type === 'dashboards') {
      Object.keys(permObjDashboards).forEach(perm => {
        cloneDashboardsPerm[perm].state = e;
      });
    }
    if (type === 'reports') {
      Object.keys(permObjReports).forEach(perm => {
        cloneReportsPerm[perm].state = e;
      });
    }
    if (type === 'topviews') {
      Object.keys(permObjTopviews).forEach(perm => {
        cloneTopviewsPerm[perm].state = e;
      });
    }

    onChange([
      ...parseFormToDb(cloneDashboardsPerm, 'Dashboard'),
      ...parseFormToDb(cloneReportsPerm, 'Report'),
      ...parseFormToDb(cloneTopviewsPerm, 'Topview'),
    ]);
    setDashboards(cloneDashboardsPerm);
    setReports(cloneReportsPerm);
    setTopviews(cloneTopviewsPerm);
  }

  return (
    <Accordion
      allowMultipleExpanded
      allowZeroExpanded
      blueThemed
      items={[
        {
          title: t('dashboards'),
          body: (
            <table className="userPermissionTable">
              <thead>
                <tr>
                  <th key="colFeature" />
                  <th key="colRead" style={{ textAlign: 'center' }}>
                    <Checkbox
                      checked={!Object.keys(permObjDashboards).find(d => !permObjDashboards[d].state)}
                      onChange={e => selectAll(e, 'dashboards')}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(permObjDashboards).map(dashboard => (
                    <tr key={dashboard}>
                      <td>{permObjDashboards[dashboard].name}</td>
                      <td className="checkboxCell">{renderCheckbox(dashboard, permObjDashboards[dashboard].state)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ),
        },
        {
          title: t('topViews'),
          body: (
            <table className="userPermissionTable">
              <thead>
                <tr>
                  <th key="colFeature" />
                  <th key="colRead" style={{ textAlign: 'center' }}>
                    <Checkbox
                      checked={!Object.keys(permObjTopviews).find(topview => !permObjTopviews[topview].state)}
                      onChange={e => selectAll(e, 'topviews')}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(permObjTopviews).map(topview => (
                    <tr key={topview}>
                      <td>{permObjTopviews[topview].name}</td>
                      <td className="checkboxCell">{renderCheckbox(topview, permObjTopviews[topview].state)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ),
        },
        {
          title: t('reports'),
          body: (
            <table className="userPermissionTable">
              <thead>
                <tr>
                  <th key="colFeature" />
                  <th key="colRead" style={{ textAlign: 'center' }}>
                    <Checkbox
                      checked={!Object.keys(permObjReports).find(r => !permObjReports[r].state)}
                      onChange={e => selectAll(e, 'reports')}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  Object.keys(permObjReports).map(report => (
                    <tr key={report}>
                      <td>{permObjReports[report].name}</td>
                      <td className="checkboxCell">{renderCheckbox(report, permObjReports[report].state)}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ),
        },
      ]}
    />
  );
};

ContentPermissionTable.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ContentPermissionTable;
