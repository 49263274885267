import React from 'react';
import i18n from 'i18next';
import { ActionType } from '~services/actions/types';
import { triggerTypes } from '~utils/types';

/**
 * Functions used to return if a variable is used in a CRITICAL component of the application
*/
const atLeastOneVariableActionUsesThisVariable = (id, actions) => (actions || [])
  .filter(action => action.type === ActionType.VARIABLE)
  .find(action => action.params.valueId === id || action.params.value.includes(`\${${id}}`));

const allVariableActionsUsingThisVariable = (id, actions) => (actions || [])
  .filter(action => action.type === ActionType.VARIABLE && (action.params.valueId === id || action.params.value.includes(`\${${id}}`)));

const atLeastOneEventActionUsesThisVariable = (id, actions) => (actions || [])
  .filter(action => action.type === ActionType.MACHINE_STATE)
  .find(action => (action.params.delay
    && (typeof action.params.delay === 'string' && action.params.delay.includes(id))));

const allEventActionsUsingThisVariable = (id, actions) => (actions || [])
  .filter(action => action.type === ActionType.MACHINE_STATE && (action.params.delay
    && (typeof action.params.delay === 'string' && action.params.delay.includes(id))));

const atLeastOneValueChangeTriggerUsesThisVariable = (id, triggers) => (triggers || [])
  .filter(trigger => triggerTypes.VALUE_CHANGE === trigger.type)
  .find(trigger => trigger.details.valueId === id
    || (trigger.details.changesTo && typeof trigger.details.changesTo === 'string' && trigger.details.changesTo.includes(id)));

const allValueChangeTriggersUsingThisVariable = (id, triggers) => (triggers || [])
  .filter(trigger => triggerTypes.VALUE_CHANGE === trigger.type && (trigger.details.valueId === id
    || (trigger.details.changesTo && typeof trigger.details.changesTo === 'string' && trigger.details.changesTo.includes(id))));

const atLeastOneValueSetTriggerUsesThisVariable = (id, triggers) => (triggers || [])
  .filter(trigger => triggerTypes.VALUE_SET === trigger.type)
  .find(trigger => trigger.details.valueId === id
    || (trigger.details.setTo && typeof trigger.details.setTo === 'string' && trigger.details.setTo.includes(id)));

const allValueSetTriggersUsingThisVariable = (id, triggers) => (triggers || [])
  .filter(trigger => triggerTypes.VALUE_SET === trigger.type && (trigger.details.valueId === id
    || (trigger.details.setTo && typeof trigger.details.setTo === 'string' && trigger.details.setTo.includes(id))));

const atLeastOneVariableUsesThisVariable = (id, variables) => (variables || [])
  .find(el => typeof el.expression === 'string' && el.expression.includes(id));

const allVariablesUsingThisVariable = (id, variables) => (variables || [])
  .filter(el => typeof el.expression === 'string' && el.expression.includes(id));

const atLeastOneTriggerConditionUsesThisVariable = (id, triggers) => (triggers || [])
  .find(trigger => {
    if (Array.isArray(trigger.condition?.conditions)) {
      return trigger.condition.conditions.some(condition => condition.expression?.includes(id));
    }
    return trigger.condition?.expression?.includes(id);
  });

const allTriggerConditionsUsingThisVariable = (id, triggers) => (triggers || [])
  .filter(trigger => {
    if (Array.isArray(trigger.condition?.conditions)) {
      return trigger.condition.conditions.some(condition => condition.expression?.includes(id));
    }
    return trigger.condition?.expression?.includes(id);
  });

const atLeastOneActionConditionUsesThisVariable = (id, actions) => (actions || [])
  .find(action => action.condition?.expression?.includes(id));

const allActionConditionsUsingThisVariable = (id, actions) => (actions || [])
  .filter(action => action.condition?.expression?.includes(id));

const variableUsedByCriticalActionMessage = (id, actions) => {
  const action = atLeastOneVariableActionUsesThisVariable(id, actions)
    || atLeastOneEventActionUsesThisVariable(id, actions)
    || atLeastOneActionConditionUsesThisVariable(id, actions);

  if (action) {
    const text = (
      <>
        {i18n.t('variableIsUsedByAction')}
        <br />
        {`${i18n.t('actionName')} : ${action.name}`}
      </>
    );
    return text;
  }
  return null;
};

const variableUsedByTriggerMessage = (id, triggers) => {
  const trigger = atLeastOneValueChangeTriggerUsesThisVariable(id, triggers)
    || atLeastOneValueSetTriggerUsesThisVariable(id, triggers)
    || atLeastOneTriggerConditionUsesThisVariable(id, triggers);

  if (trigger) {
    return (
      <>
        {i18n.t('variableIsUsedByTrigger')}
        <br />
        {`${i18n.t('triggerName')} : ${trigger.name}`}
      </>
    );
  }
  return null;
};

const variableUsedByVariableMessage = (id, variables) => {
  const variableUsingId = atLeastOneVariableUsesThisVariable(id, variables);

  if (variableUsingId) {
    const { variable } = variableUsingId;
    return (
      <>
        {i18n.t('variableIsUsedByVariable')}
        <br />
        {`${i18n.t('variableName')} : ${variable}`}
      </>
    );
  }
  return null;
};

const getCriticallyUsedErrorMessage = (id, actions, triggers, variables) => {
  let message = variableUsedByCriticalActionMessage(id, actions);
  if (message) {
    return message;
  }
  message = variableUsedByTriggerMessage(id, triggers);
  if (message) {
    return message;
  }
  message = variableUsedByVariableMessage(id, variables);
  if (message) {
    return message;
  }
  return null;
};

/**
 * Functions used to return if a variable is used in a non critical component of the application
*/
const atLeastOneActionUsesThisVariable = (id, actions) => (actions || [])
  .find(action => Object
    .entries(action.params || {})
    .some(([_key, value]) => value.toString().includes(id)));

const atLeastOneDashboardUsesThisVariable = (id, dashboards) => (dashboards || [])
  .find(dashboard => {
    let using = false;
    if (dashboard.tiles) {
      dashboard.tiles.forEach(tile => {
        if (tile.valueId === id || tile.axisY === id) {
          using = dashboard;
        }
        tile.rules.forEach(rule => {
          if (rule.condition.includes(id)) {
            using = dashboard;
          }
        });
      });
    }
    return using;
  });

const variableUsedByActionMessage = (id, actions) => {
  const action = atLeastOneActionUsesThisVariable(id, actions);

  if (action) {
    const text = (
      <>
        {i18n.t('variableIsUsedByAction')}
        :
        <br />
        <b>{action.name}</b>
      </>
    );
    return text;
  }
  return null;
};

const variableUsedByDashboardMessage = (id, dashboards) => {
  const dashboard = atLeastOneDashboardUsesThisVariable(id, dashboards);

  if (dashboard) {
    const text = (
      <>
        {i18n.t('variableIsUsedByDashboard')}
        :
        <br />
        <b>{dashboard.name}</b>
      </>
    );
    return text;
  }
  return null;
};

const variableIsCriticallyUsed = (
  variableId,
  actions,
  triggers,
  variables,
) => (
  atLeastOneVariableActionUsesThisVariable(variableId, actions)
  || atLeastOneEventActionUsesThisVariable(variableId, actions)
  || atLeastOneValueChangeTriggerUsesThisVariable(variableId, triggers)
  || atLeastOneValueSetTriggerUsesThisVariable(variableId, triggers)
  || atLeastOneVariableUsesThisVariable(variableId, variables)
  || atLeastOneTriggerConditionUsesThisVariable(variableId, triggers)
  || atLeastOneActionConditionUsesThisVariable(variableId, actions)
  || variableUsedByActionMessage(variableId, actions)
);

const allActionsUsingThisVariable = (id, actions) => {
  const actionsList = [];
  actionsList.push(...allVariableActionsUsingThisVariable(id, actions));
  actionsList.push(...allEventActionsUsingThisVariable(id, actions));
  actionsList.push(...allActionConditionsUsingThisVariable(id, actions));
  return actionsList;
};

const allTriggersUsingThisVariable = (id, triggers) => {
  const triggersList = [];
  triggersList.push(...allValueChangeTriggersUsingThisVariable(id, triggers));
  triggersList.push(...allValueSetTriggersUsingThisVariable(id, triggers));
  triggersList.push(...allTriggerConditionsUsingThisVariable(id, triggers));
  return triggersList;
};

export {
  allActionsUsingThisVariable,
  allTriggersUsingThisVariable,
  allVariablesUsingThisVariable,
  atLeastOneActionUsesThisVariable,
  variableIsCriticallyUsed,
  getCriticallyUsedErrorMessage,
  variableUsedByActionMessage,
  variableUsedByDashboardMessage,
};
