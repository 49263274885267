import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Loader } from '@intelligenceindustrielle/react-ui-components';
import { dateToString } from '~utils/time';
import { showError } from '~utils/toast';
import API from '~services/endpoints';
import { DeleteTransparentButton, ModalHandler, DefaultModal } from '~UI';
import { DeleteConfirmationForm } from '~components/Popups';
import FeaturePermissionTable from '../UserPermissionsForm/FeaturePermissionTable';

const propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.shape({
    appKeys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    id: PropTypes.string.isRequired,
    permissions: PropTypes.shape({
      features: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

class UserAppKeysForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: props.user.appKeys || [],
      label: '',
      featurePermissions: [],
      showNewKey: false,
      isWaitingForGeneration: false,
      newKey: '',
    };
  }

  getNewKey = () => {
    const { t, user } = this.props;
    const { label, featurePermissions, isWaitingForGeneration } = this.state;
    if (label === '') {
      showError(t('missingLabel'));
      return;
    }
    if (featurePermissions.length === 0) {
      showError(t('missingPermSelection'));
      return;
    }
    if (isWaitingForGeneration) {
      return;
    }
    this.setState({ label: '', featurePermissions: [], showNewKey: true, isWaitingForGeneration: true });

    API
      .addUserAppKey(user.id, { label, permissions: { features: featurePermissions } })
      .then(res => {
        this.setState({
          newKey: res.token,
          showNewKey: true,
          isWaitingForGeneration: false,
          keys: res.user.appKeys,
        });
      });
  };

  revokeKey = async keyId => {
    const { user } = this.props;
    API
      .deleteUserAppKey(user.id, keyId)
      .then(({ user: { appKeys } }) => {
        this.setState({ keys: appKeys });
      });
  };

  hideNewKey = () => {
    this.setState({ showNewKey: false, newKey: undefined });
  };

  render() {
    const { t, user } = this.props;
    const {
      keys, label, featurePermissions, showNewKey, newKey,
    } = this.state;
    return (
      <>
        <p>{t('appKeysDesc')}</p>
        <div className="sectionTitle">{t('createAppKey')}</div>
        <div className="inputTitle">{t('label')}</div>
        <input
          name="label"
          value={label}
          className="fullwidth"
          type="text"
          onChange={e => this.setState({ label: e.target.value })}
        />
        <div className="inputTitle">{t('permissions')}</div>
        <FeaturePermissionTable
          allFeatures={(user.permissions && user.permissions.features) || []}
          allowedFeatures={featurePermissions}
          onChange={features => this.setState({ featurePermissions: features })}
        />
        <button
          type="button"
          className="actionBtn"
          onClick={this.getNewKey}
        >
          {t('createAppKey')}
        </button>

        <div className="sectionTitle">{`${t('activeAppKeys')} (${keys.length})`}</div>
        <table className="userPermissionTable">
          <thead>
            <tr>
              <th key="colLabel">{t('label')}</th>
              <th key="colCreated">{t('createdAt')}</th>
              <th key="colUsed">{t('lastUsedAt')}</th>
              <th key="colAction" />
            </tr>
          </thead>
          <tbody>
            {
              keys
                .map(({ created, label: lLabel, id, lastUsedAt }) => (
                  <tr key={id}>
                    <td>{lLabel}</td>
                    <td>{dateToString(new Date(created))}</td>
                    <td>
                      {' '}
                      {!lastUsedAt ? '' : dateToString(new Date(lastUsedAt))}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <ModalHandler
                        Modal={{
                          Component: DeleteConfirmationForm,
                          props: { handleDelete: () => this.revokeKey(id) },
                        }}
                        Trigger={{
                          Component: DeleteTransparentButton,
                          props: {
                            key: 'deleteIcon',
                            className: 'deleteIcon',
                            type: 'Button',
                            content: t('revoke'),
                          },
                        }}
                      />
                    </td>
                  </tr>
                ))
            }
          </tbody>
        </table>
        {keys.length === 0 && <p>{t('appKeysEmptyDesc')}</p>}
        <DefaultModal
          show={showNewKey}
          title={t('newAppKey')}
          closePopup={this.hideNewKey}
        >
          {t('newAppKeyDesc')}
          <br />
          <div style={{ overflowWrap: 'break-word', fontSize: '18px', margin: '5px' }}>
            {newKey || <Loader />}
          </div>
        </DefaultModal>
      </>
    );
  }
}

UserAppKeysForm.propTypes = propTypes;

export default withTranslation()(UserAppKeysForm);
