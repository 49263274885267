import { fetchOnceOperation } from '../fetchOperation';
import {
  getLinkRedirectionRequest,
  getLinkRedirectionResponse,
  updateLinkRedirection,
} from './actions';
import API from '~services/endpoints';

function getLinkRedirection(linkId) {
  return fetchOnceOperation(
    getLinkRedirectionRequest,
    getLinkRedirectionResponse,
    API.getLinkRedirection,
    state => state.linkRedirection.targetURL,
    [linkId],
  );
}

export default {
  getLinkRedirection,
  updateLinkRedirection,
};
