import React, { useState, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';
import { FontAwesome } from '~UI/index';
import API from '~services/endpoints';
import './AudioRecorder.scss';

const AudioRecorder: React.FC<{ setText: (text: string) => void }> = ({ setText }) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const sendAudioToBackend = async blob => {
    if (!blob) {
      console.error('No audio to send');
      return;
    }

    const formData = new FormData();
    formData.append('audio', blob, 'recording.wav');

    try {
      const response = await API.sendAudioToTranscription(formData);
      setText(response.transcription);
    } catch (error) {
      console.error('Error sending audio:', error);
    }
  };

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  useEffect(() => () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }, [intervalId]);

  return (
    <div>
      <ReactMediaRecorder
        audio
        onStop={(blobUrl, blob) => {
          sendAudioToBackend(blob);
          setElapsedTime(0);
          if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
          }
        }}
        render={({ startRecording, stopRecording, status }) => (
          <div>
            <button
              type="button"
              onClick={() => {
                if (status !== 'recording') {
                  setElapsedTime(0);
                  const id = setInterval(() => {
                    if (elapsedTime >= 120) {
                      stopRecording();
                      if (intervalId) {
                        clearInterval(intervalId);
                        setIntervalId(null);
                      }
                      return;
                    }
                    setElapsedTime(prevTime => prevTime + 1);
                  }, 1000);
                  setIntervalId(id);
                  startRecording();
                } else {
                  if (intervalId) {
                    clearInterval(intervalId);
                    setIntervalId(null);
                  }
                  stopRecording();
                }
              }}
              className={status === 'recording' ? 'left-button rotating' : 'left-button'}
            >
              <FontAwesome icon="microphone" size="lg" />
            </button>
            {status === 'recording' && (
              <div className="timer">
                {formatTime(elapsedTime)}
                {' '}
                / 2min
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
};

export { AudioRecorder };
