import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import setDateTime from './setDateTime';
import shiftToRelativeTimes from './shiftToRelativeTimes';

export default (shifts, timestamp) => {
  // if the collection is empty => 24h period
  if (shifts.length === 0) {
    const startDate = setDateTime(timestamp, 0, 0);
    const endDate = setDateTime(timestamp, 0, 0);
    endDate.setDate(endDate.getDate() + 1);
    return { start: startDate, end: endDate };
  }

  const now = DateTime.fromMillis(timestamp).toUTC();
  const currentDay = now.weekday % 7;
  const dayInMinutes = 24 * 60;
  const hoursInMinutes = 60;
  const weekInMinutes = 7 * dayInMinutes;
  let relativeTimes = [];
  shifts.forEach(shift => {
    relativeTimes = relativeTimes.concat(shiftToRelativeTimes(shift));
  });
  const sortedRelativeTimes = relativeTimes.sort((a, b) => a.start - b.start);
  const timestampToRelative = (currentDay * dayInMinutes) + (now.hour * hoursInMinutes) + now.minute;
  let currentShift = sortedRelativeTimes
    .find(shift => shift.start <= timestampToRelative && (timestampToRelative < shift.end || shift.end < shift.start));
  // Check if the current time is in previous week
  if (!currentShift && timestampToRelative < sortedRelativeTimes[0].start) {
    currentShift = sortedRelativeTimes
      .find(shift => shift.start <= timestampToRelative + weekInMinutes
        && (timestampToRelative + weekInMinutes < shift.end || shift.end < shift.start));
  }
  if (currentShift) {
    const shift = shifts.find(s => s.id === currentShift.id);
    const tzDate = moment.tz(timestamp, shift.timezone);

    const startDate = moment.tz({
      year: tzDate.year(), month: tzDate.month(), date: tzDate.date(), hour: shift.hourStart, minute: shift.minuteStart,
    }, shift.timezone);

    // If the shift starts on the day before of the timestamp
    if (tzDate.hour() < shift.hourStart || (tzDate.hour() === shift.hourStart && tzDate.minute() < shift.minuteStart)) {
      startDate.subtract(1, 'day');
    }

    const endDate = moment.tz({
      year: tzDate.year(), month: tzDate.month(), date: tzDate.date(), hour: shift.hourEnd, minute: shift.minuteEnd,
    }, shift.timezone);
    if (endDate.isBefore(startDate)) endDate.add(1, 'day');
    // Convert to local timezone
    const localStartDate = startDate.clone().tz(moment.tz.guess()).toDate();
    const localEndDate = endDate.clone().tz(moment.tz.guess()).toDate();
    return { start: localStartDate, end: localEndDate };
  }

  // No shift here means you have a "hole" in your configuration
  // Our logic to fill holes will be { start: end_of_previous_shift, end: start_of_next_shift }
  for (let index = 0; index < sortedRelativeTimes.length; index += 1) {
    // If timestamp is before the start of the first shift of the week
    if (index === 0 && sortedRelativeTimes[index].start > timestampToRelative) {
      const shiftStart = shifts.find(shift => shift.id === sortedRelativeTimes[index].id);
      const tzStartDate = moment.tz(timestamp, shiftStart.timezone);
      const startDate = moment.tz({
        year: tzStartDate.year(),
        month: tzStartDate.month(),
        date: tzStartDate.date(),
        hour: 0,
        minute: 0,
      }, shiftStart.timezone);
      let endDate;
      if (shiftStart.days.includes(tzStartDate.days() % 7)) {
        endDate = moment.tz({
          year: tzStartDate.year(),
          month: tzStartDate.month(),
          date: tzStartDate.date(),
          hour: shiftStart.hourStart,
          minute: shiftStart.minuteStart,
        }, shiftStart.timezone);
      } else {
        endDate = moment.tz({
          year: tzStartDate.year(),
          month: tzStartDate.month(),
          date: tzStartDate.date(),
          hour: 0,
          minute: 0,
        }, shiftStart.timezone);
        endDate.add(1, 'day');
      }

      // Convert to local timezone
      const localStartDate = startDate.clone().tz(moment.tz.guess()).toDate();
      const localEndDate = endDate.clone().tz(moment.tz.guess()).toDate();
      return { start: localStartDate, end: localEndDate };
    }
    // Find if relativeTimes is between shifts
    if (index + 1 !== sortedRelativeTimes.length
      && sortedRelativeTimes[index].end <= timestampToRelative
      && sortedRelativeTimes[index + 1].start > timestampToRelative) {
      const shiftStart = shifts.find(shift => shift.id === sortedRelativeTimes[index].id);
      const shiftEnd = shifts.find(shift => shift.id === sortedRelativeTimes[index + 1].id);
      const shiftStartStartInMin = shiftStart.hourStart * hoursInMinutes + shiftStart.minuteStart;
      const shiftStartEndInMin = shiftStart.hourEnd * hoursInMinutes + shiftStart.minuteEnd;
      let startDate;
      let endDate;
      const tzStartDate = moment.tz(timestamp, shiftStart.timezone);
      const tzStartDateInMin = tzStartDate.hour() * hoursInMinutes + tzStartDate.minute();
      if (shiftStart.days.includes(tzStartDate.day() % 7)) {
        if (shiftStartStartInMin > tzStartDateInMin && shiftStartEndInMin > tzStartDateInMin) {
          startDate = moment.tz({
            year: tzStartDate.year(), month: tzStartDate.month(), date: tzStartDate.date(), hour: 0, minute: 0,
          }, shiftStart.timezone);
        } else {
          startDate = moment.tz({
            year: tzStartDate.year(),
            month: tzStartDate.month(),
            date: tzStartDate.date(),
            hour: shiftStart.hourEnd,
            minute: shiftStart.minuteEnd,
          }, shiftStart.timezone);
        }
      } else if (shiftStartEndInMin < tzStartDateInMin) {
        startDate = moment.tz({
          year: tzStartDate.year(),
          month: tzStartDate.month(),
          date: tzStartDate.date(),
          hour: shiftStart.hourEnd,
          minute: shiftStart.minuteEnd,
        }, shiftStart.timezone);
      } else {
        startDate = moment.tz({
          year: tzStartDate.year(), month: tzStartDate.month(), date: tzStartDate.date(), hour: 0, minute: 0,
        }, shiftStart.timezone);
      }

      // If next shift is not on the day, end at midnight on the next day
      const tzEndDate = moment.tz(timestamp, shiftEnd.timezone);
      if (shiftEnd.days.includes(tzEndDate.day() % 7)) {
        endDate = moment.tz({
          year: tzEndDate.year(),
          month: tzEndDate.month(),
          date: tzEndDate.date(),
          hour: shiftEnd.hourStart,
          minute: shiftEnd.minuteStart,
        }, shiftEnd.timezone);
        if (endDate.isBefore(startDate)) {
          endDate = moment.tz({
            year: tzEndDate.year(), month: tzEndDate.month(), date: tzEndDate.date(), hour: 0, minute: 0,
          }, shiftEnd.timezone);
          endDate.add(1, 'day');
        }
      } else {
        endDate = moment.tz({
          year: tzEndDate.year(), month: tzEndDate.month(), date: tzEndDate.date(), hour: 0, minute: 0,
        }, shiftEnd.timezone);
        endDate.add(1, 'day');
      }

      // Convert to local timezone
      const localStartDate = startDate.clone().tz(moment.tz.guess()).toDate();
      const localEndDate = endDate.clone().tz(moment.tz.guess()).toDate();
      return { start: localStartDate, end: localEndDate };
    }

    // If timestamp is after the end of the last shift of the week
    if (index + 1 === sortedRelativeTimes.length
    && sortedRelativeTimes[index].end <= timestampToRelative) {
      const shiftEnd = shifts.find(shift => shift.id === sortedRelativeTimes[index].id);
      const tzEndDate = moment.tz(timestamp, shiftEnd.timezone);
      const startDate = shiftEnd.days.includes(tzEndDate.day() % 7)
        ? moment.tz({
          year: tzEndDate.year(),
          month: tzEndDate.month(),
          date: tzEndDate.date(),
          hour: shiftEnd.hourEnd,
          minute: shiftEnd.minuteEnd,
        }, shiftEnd.timezone)
        : moment.tz({
          year: tzEndDate.year(),
          month: tzEndDate.month(),
          date: tzEndDate.date(),
          hour: 0,
          minute: 0,
        }, shiftEnd.timezone);
      const endDate = moment.tz({
        year: tzEndDate.year(),
        month: tzEndDate.month(),
        date: tzEndDate.date(),
        hour: 0,
        minute: 0,
      }, shiftEnd.timezone);
      endDate.add(1, 'day');

      // Convert to local timezone
      const localStartDate = startDate.clone().tz(moment.tz.guess()).toDate();
      const localEndDate = endDate.clone().tz(moment.tz.guess()).toDate();
      return { start: localStartDate, end: localEndDate };
    }
  }

  // Should never happen. "Safety net" to prevent crash
  const startDate = setDateTime(timestamp, 0, 0);
  const endDate = setDateTime(timestamp, 0, 0);
  endDate.setDate(endDate.getDate() + 1);
  return { start: startDate, end: endDate };
};
