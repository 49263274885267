import PropTypes from 'prop-types';
import types from './types';

const formPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.object),
});

function forms(state = null, action) {
  switch (action.type) {
    case types.GET_FORMS_RESPONSE:
      if (!action.payload.error) {
        return action.payload.forms;
      }
      return state;
    default:
      return state;
  }
}

export const propTypes = {
  form: formPropTypes,
  forms: PropTypes.arrayOf(formPropTypes),
};

export default forms;
