import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import API from '~services/endpoints';
import { getSocket } from '~services/socket';
import { useShift } from '~utils/hooks';
import Tick from '~utils/Tick';
import { getDataRangeStartEnd, serverTime } from '~utils/time';
import getBarChartLapse from '~utils/getBarChartLapse';
import BarChartComponent from './BarChartComponent';
import { canNavigateTime, navigateTimeUtil } from '~utils/navigateTime';

const evaluateDelay = 10; // in seconds

const BarChartMachine = ({
  backgroundColor,
  height,
  tile,
  width,
}) => {
  const socket = getSocket();
  const machines = useSelector(state => state.machines);
  const { t } = useTranslation();

  const machine = machines.find(m => m.id === tile.machineId);

  const [data, setData] = useState(null);
  const [events, setEvents] = useState([]);
  const [operation, setOperation] = useState(machine && machine.params ? machine.params.operation : undefined);
  const [skuNumber, setSkuNumber] = useState(machine && machine.params ? machine.params.skuNumber : undefined);
  const [workOrder, setWorkOrder] = useState(machine && machine.params ? machine.params.workOrder : undefined);

  const [currentShift, shifts] = useShift(tile.machineId);

  const [timePeriod, setTimePeriod] = useState(getDataRangeStartEnd(tile.intervalType || 'shift', currentShift));
  const [hasUsedArrows, setHasUsedArrows] = useState(false);
  const idleTimer = useRef(null);

  const updateTimePeriod = () => {
    if (!hasUsedArrows) {
      const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
      setTimePeriod(newTimePeriod);
    }
  };

  const machinePropertyToFilter = property => {
    switch (property) {
      case 'inputCount':
        return 'IN';
      case 'outputCount':
        return 'OUT';
      case 'scrapCount':
        return 'SCRAP';
      default:
        return undefined;
    }
  };

  const evaluateEvents = eventsArg => {
    const newData = [];
    const lapse = getBarChartLapse(timePeriod);

    if (eventsArg.length) {
      for (let i = timePeriod.start; i < timePeriod.end; i += lapse) {
        const eventsIn = eventsArg.filter(ev => ev.timestamp >= i && ev.timestamp <= i + lapse);

        // An event is "active" when it's infos (sku or/and workOrder) matches the current Machine params
        // Previous events are the others
        const activeEvents = [];
        const previousEventsByProperties = {};
        let nbActiveCount = 0;
        let nbPreviousCount = 0;

        // Needed for acceptableOutputCount. Prevents multiple events loops
        let nbActiveOutputCount = 0;
        let nbActiveScrapCount = 0;
        let nbPreviousOutputCount = 0;
        let nbPreviousScrapCount = 0;

        // Sort the event between active and previous events
        eventsIn.forEach(event => {
          let isActive = true;
          if (operation) {
            const operationObject = event?.infos.find(info => info.name === 'operation');
            isActive = operationObject?.value === operation;
          } else if (skuNumber) {
            const skuObject = event.infos?.find(info => info.name === 'skuNumber');
            isActive = skuObject?.value === skuNumber;
          }
          if (workOrder !== undefined && isActive) {
            const workOrderObject = event.infos?.find(info => info.name === 'workOrder');
            isActive = workOrderObject?.value === workOrder;
          }
          if (isActive) {
            activeEvents.push(event);
            nbActiveCount += event.count || 1;
            nbActiveOutputCount += event.eventType === 'OUT' && (event.count || 1);
            nbActiveScrapCount += event.eventType === 'SCRAP' && (event.count || 1);
          } else {
            const operationObject = event?.infos.find(info => info.name === 'operation');
            const skuObject = event?.infos.find(info => info.name === 'skuNumber');
            const workOrderObject = event?.infos.find(info => info.name === 'workOrder');
            const operationValue = operationObject?.value || '';
            const skuValue = skuObject?.value || '';
            const workOrderValue = workOrderObject?.value || '';

            const key = `${operationValue}\t${skuValue}\t${workOrderValue}`;

            if (!previousEventsByProperties[key]) {
              previousEventsByProperties[key] = [];
            }
            previousEventsByProperties[key].push(event);
            nbPreviousCount += event.count || 1;
            nbPreviousOutputCount += event.eventType === 'OUT' && (event.count || 1);
            nbPreviousScrapCount += event.eventType === 'SCRAP' && (event.count || 1);
          }
        });

        if (tile.axisY === 'acceptableOutputCount') {
          nbActiveCount = nbActiveOutputCount - nbActiveScrapCount;
          nbPreviousCount = nbPreviousOutputCount - nbPreviousScrapCount;
        }

        // The active event bar (blue), will always be drawn first and will be the sum of both columns
        // The bar value will show the sum but the tooltip will show only the active count value
        if (i < serverTime()) {
          newData.push({
            x: i,
            y: nbActiveCount + nbPreviousCount,
            color: '#0078FF',
            tooltip: { count: nbActiveCount, operation, skuNumber, workOrder },
          });
          Object.entries(previousEventsByProperties).forEach(([key, previousEvents]) => {
            const count = previousEvents.reduce((acc, event) => {
              if (tile.axisY === 'acceptableOutputCount') {
                return acc + (event.eventType === 'OUT' ? (event.count || 1) : 0) - (event.eventType === 'SCRAP' ? (event.count || 1) : 0);
              }
              return acc + (event.count || 1);
            }, 0);

            newData.push({
              x: i,
              y: nbPreviousCount,
              color: '#777',
              tooltip: { count, operation: key.split('\t')[0], skuNumber: key.split('\t')[1], workOrder: key.split('\t')[2] },
              // We do not want a value for the grey bar (previous events)
              // To do that, we explicitly set barValue to ' ' which overrides default y value
              barValue: ' ',
            });
            nbPreviousCount -= count;
          });
        }
      }
    }
    setData(newData);
  };

  const fetchEvents = async () => {
    const { start, end } = timePeriod;

    const filter = {
      machineId: tile.machineId,
      eventType: machinePropertyToFilter(tile.axisY),
      type: 'PartEvent',
      timestamp: {
        $gte: start,
        $lt: end,
      },
    };

    const { events: newEvents } = await API.getEvents(filter, undefined, undefined, ['timestamp', 'infos', 'count', 'eventType']);
    setEvents(newEvents);
    evaluateEvents(newEvents);
  };

  const handleSocketEvent = socketEvent => {
    const { type, machineId, eventType } = socketEvent;

    const tileEventType = machinePropertyToFilter(tile.axisY);
    if (type === 'PartEvent' && machineId === tile.machineId
      && (tileEventType === eventType || tile.axisY === 'acceptableOutputCount') && !hasUsedArrows) {
      events.push(socketEvent);
      setEvents(events);
    }
  };

  const handleSocketMachines = socketEvent => {
    if (socketEvent.type === 'MACHINE_PARAM_UPDATE' && socketEvent.machineId === tile.machineId) {
      const newOperation = (socketEvent.params && socketEvent.params.operation) || undefined;
      const newSkuNumber = (socketEvent.params && socketEvent.params.skuNumber) || undefined;
      const newWorkOrder = (socketEvent.params && socketEvent.params.workOrder) || undefined;
      setOperation(newOperation);
      setSkuNumber(newSkuNumber);
      setWorkOrder(newWorkOrder);
      fetchEvents();
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    Tick.subscribe(updateTimePeriod, evaluateDelay);

    return () => {
      Tick.unsubscribe(updateTimePeriod);
    };
  }, [currentShift, hasUsedArrows]);

  useEffect(() => {
    const newTimePeriod = getDataRangeStartEnd(tile.intervalType || 'shift', currentShift);
    setTimePeriod(newTimePeriod);
  }, [tile, tile.intervalType, currentShift]);

  useEffect(() => {
    if (socket) {
      socket.on('event', handleSocketEvent);
      socket.on('machines', handleSocketMachines);
    }

    return () => {
      if (socket) {
        socket.removeListener('event', handleSocketEvent);
        socket.removeListener('machines', handleSocketMachines);
      }
    };
  }, [socket]);

  useEffect(() => {
    fetchEvents();
  }, [timePeriod]);

  const handleOnActive = () => {
    fetchEvents();
  };

  const handleOnIdle = () => {
    fetchEvents();
    idleTimer.current.reset();
  };

  useEffect(() => {
    setHasUsedArrows(false);
  }, [tile.intervalType]);

  const navigateTime = goBackward => {
    const { newTimePeriod, hasUsedArrows: newHasUsedArrows } = navigateTimeUtil(goBackward, timePeriod, shifts, tile.intervalType || 'shift');
    setEvents([]);
    setTimePeriod(newTimePeriod);
    setHasUsedArrows(newHasUsedArrows);
  };

  if (!machine) {
    return (
      <h3>{t('machineIsNotConfiguredOrDeleted')}</h3>
    );
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        timeout={1000 * 90}
        onActive={handleOnActive}
        onIdle={handleOnIdle}
        debounce={250}
      />
      <BarChartComponent
        backgroundColor={backgroundColor}
        data={data}
        height={height}
        tile={tile}
        timePeriod={timePeriod}
        canNavigateTime={goBackward => canNavigateTime(goBackward, timePeriod, shifts, tile.intervalType || 'shift')}
        navigateTime={navigateTime}
        hasUsedArrows={hasUsedArrows}
        width={width}
      />
    </>
  );
};

BarChartMachine.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  tile: PropTypes.shape({
    axisY: PropTypes.string,
    goal: PropTypes.number,
    machineId: PropTypes.string,
    intervalType: PropTypes.string,
  }).isRequired,
  width: PropTypes.number,
};

export default BarChartMachine;
