import React from 'react';
import PropTypes from 'prop-types';

import { FormTimeline } from '~components/forms';

function FormTileTimeline({ selectedTile }) {
  return (
    <FormTimeline
      selectedObject={selectedTile}
    />
  );
}

FormTileTimeline.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileTimeline.defaultProps = {
  selectedTile: {},
};

export default FormTileTimeline;
