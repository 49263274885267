import types from '../types';

const getMachinesRequest = data => ({
  type: types.GET_MACHINES_REQUEST,
  payload: data,
});

const getMachinesResponse = data => ({
  type: types.GET_MACHINES_RESPONSE,
  payload: data,
});

const addMachineRequest = data => ({
  type: types.ADD_MACHINE_REQUEST,
  payload: data,
});

const addMachineResponse = (data, request = null) => ({
  type: types.ADD_MACHINE_RESPONSE,
  payload: data,
  request,
});

const updateMachineRequest = data => ({
  type: types.UPDATE_MACHINE_REQUEST,
  payload: data,
});

const updateMachineResponse = (data, request = null) => ({
  type: types.UPDATE_MACHINE_RESPONSE,
  payload: data,
  request,
});

const patchMachineRequest = data => ({
  type: types.PATCH_MACHINE_REQUEST,
  payload: data,
});

const patchMachineResponse = (data, request = null) => ({
  type: types.PATCH_MACHINE_RESPONSE,
  payload: data,
  request,
});

const deleteMachineRequest = data => ({
  type: types.DELETE_MACHINE_REQUEST,
  payload: data,
});

const deleteMachineResponse = (data, request = null) => ({
  type: types.DELETE_MACHINE_RESPONSE,
  payload: data,
  request,
});

export {
  getMachinesRequest,
  getMachinesResponse,
  addMachineRequest,
  addMachineResponse,
  updateMachineRequest,
  updateMachineResponse,
  patchMachineRequest,
  patchMachineResponse,
  deleteMachineRequest,
  deleteMachineResponse,
};
