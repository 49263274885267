import {
  commandsUndo,
  commandsUndoFail,
  commandsRedo,
  commandsRedoFail,
  commandsSave,
} from './actions';

export default {
  commandsUndo,
  commandsUndoFail,
  commandsRedo,
  commandsRedoFail,
  commandsSave,
};
