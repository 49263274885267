import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Icon } from '@intelligenceindustrielle/react-ui-components';
import DraggableCauseIconContainer from '~components/UI/RoundButton/DraggableCauseIconContainer';
import reduxOperations from '~services/reduxOperations';
import { AddIcon, StopCauseIcon } from '~UI/RoundButton/RoundIcons';
import { FontAwesome } from '~UI';
import PerformanceCauseForm from './PerformanceCauseForm';
import '../stopCauses/StopCausesForm.scss';

const SUB_MENU_LIMIT = 100;

const PerformanceCauseEdition = ({ goBackForm, performanceCause, machineId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [editedPerformanceCause, setEditedPerformanceCause] = useState(null);
  const [isInSubMenu, setIsInSubMenu] = useState(false);
  const [subPerformanceCauses, setSubPerformanceCauses] = useState(performanceCause.subMenu || []);

  useEffect(() => {
    setSubPerformanceCauses(performanceCause.subMenu || []);
  }, [performanceCause]);

  const handlePerformanceCauseSelection = (editedPerformanceCauseArg, isInSubMenuArg) => {
    setEditedPerformanceCause(editedPerformanceCauseArg);
    if (typeof isInSubMenuArg === 'boolean') {
      setIsInSubMenu(isInSubMenuArg);
    }
  };

  const updateSubPerformanceCauses = newSubPerformanceCauses => {
    dispatch(reduxOperations.machines
      .updatePerformanceCause(
        machineId, performanceCause.id, { ...performanceCause, subMenu: newSubPerformanceCauses },
      ));
  };

  if (editedPerformanceCause) {
    return (
      <PerformanceCauseForm
        firstPerformanceCause={performanceCause}
        performanceCause={editedPerformanceCause}
        isInSubMenu={isInSubMenu}
        goBackEdition={() => handlePerformanceCauseSelection(null)}
        goBackForm={goBackForm}
        machineId={machineId}
      />
    );
  }

  return (
    <div className="PerformanceCauseEdition">
      <div className="flexSpaceBetween">
        <FontAwesome
          icon="arrow-left"
          className="backButtonArrow"
          style={{ marginLeft: '0px', cursor: 'pointer', marginTop: '4px', fontSize: '20px' }}
          onClick={goBackForm}
        />
        <span style={{ fontSize: '20px' }}>
          <Icon
            icon="info-circle"
            tooltipLabel={t('stopCauseInfo')}
          />
        </span>
      </div>
      <div className="centered">
        <StopCauseIcon
          option={performanceCause}
          onClick={() => handlePerformanceCauseSelection(performanceCause, false)}
        />
      </div>
      <hr />
      <h2>{t('subMenu')}</h2>
      <div className="PerformanceCausesIconsContainer" style={{ justifyContent: 'left' }}>
        <DndProvider backend={HTML5Backend}>
          <DraggableCauseIconContainer
            causesArray={subPerformanceCauses}
            onClick={pc => handlePerformanceCauseSelection(pc, true)}
            updateCauses={updateSubPerformanceCauses}
          >
            {(performanceCause.subMenu || []).length < SUB_MENU_LIMIT && (
              <AddIcon
                onClick={() => handlePerformanceCauseSelection({}, true)}
              />
            )}
          </DraggableCauseIconContainer>
        </DndProvider>
      </div>
    </div>
  );
};

PerformanceCauseEdition.propTypes = {
  goBackForm: PropTypes.func.isRequired,
  performanceCause: PropTypes.shape({
    id: PropTypes.string,
    subMenu: PropTypes.arrayOf(PropTypes.object),
  }),
  machineId: PropTypes.string.isRequired,
};
PerformanceCauseEdition.defaultProps = {
  performanceCause: {},
};

export default PerformanceCauseEdition;
