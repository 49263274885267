import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import nullable from 'prop-types-nullable';

import types from './types';

const imagePropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.any.isRequired,
  contentType: PropTypes.string.isRequired,
});

const imagesPropTypes = PropTypes.arrayOf(imagePropTypes);

function images(state = null, action) {
  switch (action.type) {
    case types.GET_IMAGES_RESPONSE:
      return action.payload.images;
    case types.ADD_IMAGE_RESPONSE:
      return state.concat(action.payload);
    case types.DELETE_IMAGE_RESPONSE:
      return state.filter(i => i.id !== action.payload);
    default:
      return state;
  }
}

export const propTypes = {
  images: nullable(imagesPropTypes),
};

export default combineReducers({ images });
