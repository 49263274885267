import API from '~services/endpoints';
import { patchEventRequest, patchEventResponse } from './actions';

const patchEvent = (eventId, eventData) => dispatch => {
  dispatch(patchEventRequest(eventId, eventData));
  return API.patchEvent(eventId, eventData).then(res => dispatch(patchEventResponse(res)));
};

export default {
  patchEvent,
};
