import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './CheckboxToggle.scss';
import 'pretty-checkbox/src/pretty-checkbox.scss';

const CheckboxToggle = ({
  controlledCheck, defaultChecked, name, onChange, readOnly, small,
}) => {
  const [checked, setChecked] = useState(controlledCheck || defaultChecked);

  useEffect(() => {
    if (typeof controlledCheck === 'boolean') {
      setChecked(controlledCheck);
    }
  }, [controlledCheck]);

  function handleChange() {
    if (!readOnly) {
      setChecked(prevState => {
        onChange({ target: { name, value: !prevState } });
        return !prevState;
      });
    }
  }

  return (
    <div className={`pretty p-switch p-fill CheckboxToggle ${small && 'small'}`}>
      <input
        type="hidden"
        name={name ? `boolean:${name}` : null}
        value={checked}
      />
      <input
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        disabled={readOnly}
      />
      <div className={`state ${checked && 'p-success'}`}>
        <label />
      </div>
    </div>
  );
};

CheckboxToggle.propTypes = {
  controlledCheck: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  small: PropTypes.bool,
};
CheckboxToggle.defaultProps = {
  controlledCheck: null,
  defaultChecked: false,
  name: '',
  onChange: () => { },
  readOnly: false,
  small: false,
};

export { CheckboxToggle };
