import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import './ConfirmationForm.scss';
import { useTranslation } from 'react-i18next';

const ConfirmationForm = ({
  handleAction, show, onHide, title, body, actionLabel, confirmButtonColor,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      className={`DefaultModal theme-${localStorage.getItem('theme')} undraggable confirmationFormPopup`}
      dialogClassName="DefaultModal"
      backdropClassName="delete-modal-backdrop"
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ textAlign: 'center' }}>
            {title}
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="ConfirmationText">
          {body}
          <div className="actions">
            <button
              type="button"
              className="PopupButton cancel"
              onClick={onHide}
            >
              {t('cancel')}
            </button>
            {actionLabel && (
              <button
                type="button"
                className="PopupButton"
                style={{ backgroundColor: confirmButtonColor }}
                onClick={() => {
                  handleAction();
                  onHide();
                }}
              >
                {actionLabel}
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmationForm.propTypes = {
  actionLabel: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  confirmButtonColor: PropTypes.string,
  handleAction: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
};
ConfirmationForm.defaultProps = {
  confirmButtonColor: '#fff',
};

export default ConfirmationForm;
