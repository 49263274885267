import PropTypes from 'prop-types';
import types from './types';

const INITIAL_STATE = {
  pastActions: [],
  futureActions: [],
};

const commandPropTypes = PropTypes.shape({
  action: PropTypes.func,
  params: PropTypes.arrayOf(PropTypes.shape({})),
});

const commandsPropTypes = PropTypes.shape({
  pastActions: PropTypes.arrayOf(
    PropTypes.shape({
      undoAction: commandPropTypes,
      redoAction: commandPropTypes,
    }),
  ),
  futureActions: PropTypes.arrayOf(
    PropTypes.shape({
      undoAction: commandPropTypes,
      redoAction: commandPropTypes,
    }),
  ),
});

function commands(state = INITIAL_STATE, action) {
  const { pastActions, futureActions } = state;

  switch (action.type) {
    case types.UNDO: {
      const newPastActions = pastActions.slice(0, pastActions.length - 1);
      const newFutureActions = pastActions.length
        ? [...futureActions, pastActions[pastActions.length - 1]]
        : [...futureActions];
      return {
        pastActions: newPastActions,
        futureActions: newFutureActions,
      };
    }
    case types.UNDO_FAIL: {
      const updatedPast = pastActions.slice(0, pastActions.length - 1);
      return {
        pastActions: updatedPast,
        futureActions,
      };
    }
    case types.REDO: {
      const newPast = futureActions.length
        ? [...pastActions, futureActions[futureActions.length - 1]]
        : [...pastActions];
      const newFuture = futureActions.slice(0, futureActions.length - 1);
      return {
        pastActions: newPast,
        futureActions: newFuture,
      };
    }
    case types.REDO_FAIL: {
      const updatedFuture = futureActions.slice(0, futureActions.length - 1);
      return {
        pastActions,
        futureActions: updatedFuture,
      };
    }
    case types.SAVE: {
      const nPast = action.payload ? [...pastActions, action.payload] : [...pastActions];
      return {
        pastActions: nPast,
        futureActions: [],
      };
    }
    default:
      return state;
  }
}

export const propTypes = {
  commandPropTypes,
  commandsPropTypes,
};

export default commands;
