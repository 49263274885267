import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { sortArray } from '~utils/sort';
import { SubmitButton } from '~UI';
import ContentPermissionTable from './ContentPermissionTable';
import FeaturePermissionTable from './FeaturePermissionTable';

const propTypes = {
  userPermissions: PropTypes.shape({
    contents: PropTypes.arrayOf(PropTypes.object),
    features: PropTypes.arrayOf(PropTypes.string),
  }),
  updateFormPermissions: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    permissions: PropTypes.shape({
      contents: PropTypes.arrayOf(PropTypes.object),
      features: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

const defaultProps = {
  userPermissions: undefined,
};

const UserPermissionForm = ({ user: editingUser, userPermissions, updateFormPermissions }) => {
  const { t } = useTranslation();

  const selectedUser = useSelector(state => state.users.selectedUser);
  const users = useSelector(state => state.users.users);
  const [selectedPreset, setSelectedPreset] = useState(null);

  const myFeatures = selectedUser.permissions.features ? selectedUser.permissions.features : [];

  const onPermissionChange = ({ features, contents }) => {
    updateFormPermissions({
      features: features || userPermissions.features,
      contents: contents || userPermissions.contents,
    });
  };

  const selectPreset = () => {
    const userSelected = users.find(u => u.id === selectedPreset);
    const updatedFeatures = userSelected.permissions.features.filter(feature => feature !== 'ADMIN');
    onPermissionChange({ features: updatedFeatures, contents: userSelected.permissions.contents });
  };

  const importPermissionForm = () => (
    <>
      <span className="presetSelectionText">
        {`${t('importPermissionsFromUser')}: `}
      </span>
      <Select
        style={{
          width: 150,
          display: 'inline-block',
        }}
        value={selectedPreset || '-'}
        onChange={setSelectedPreset}
        options={[
          { label: '-', value: '-' },
          ...sortArray('alphabetically', users, 'name')
            .filter(u => u.id !== editingUser.id)
            .map(u => ({ label: u.name, value: u.id })),
        ]}
      />
      &nbsp;
      <SubmitButton
        onClick={e => {
          e.preventDefault();
          selectPreset();
        }}
        label={t('import')}
      />
    </>
  );

  return userPermissions ? (
    <>
      {importPermissionForm()}
      <ContentPermissionTable
        contents={userPermissions.contents}
        onChange={contents => onPermissionChange({ contents })}
      />
      <FeaturePermissionTable
        allFeatures={myFeatures}
        allowedFeatures={userPermissions.features.filter(perm => myFeatures.includes(perm))}
        onChange={features => onPermissionChange({ features })}
      />
    </>
  ) : null;
};

UserPermissionForm.propTypes = propTypes;
UserPermissionForm.defaultProps = defaultProps;

export default UserPermissionForm;
