import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as d3 from 'd3';
import { Duration } from 'luxon';
import { reducersTypes } from '~services/index';
import { round } from '~utils/math';
import { StopCause } from '../../../pages/Config/Machines/stopCauses/StopCause.interface';
import { PerformanceCause } from '../../../pages/Config/Machines/performanceCauses/PerformanceCause.interface';
import {
  MAX_BAR_HEIGHT,
  BLUE,
  PADDING_LEFT_WITH_ARROWS,
  PADDING_BOTTOM,
} from './constants';

import './Charts.scss';

const propTypes = {
  tile: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    causeType: PropTypes.string,
    machineId: PropTypes.string,
    intervalType: PropTypes.string,
    showArrows: PropTypes.bool,
    nbDisplayedRows: PropTypes.number,
    hiddenStopCauses: PropTypes.arrayOf(PropTypes.string),
    hiddenPerformanceCauses: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  stats: PropTypes.object,
  machines: reducersTypes.machines,
  showArrows: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

const defaultProps = {
  machines: [],
  showArrows: false,
  stats: {},
};

const padToTwoDigits = num => num.toString().padStart(2, '0');

const displayTooltipDowntime = value => Duration.fromMillis(value).toFormat('hh:mm:ss');

const getMaxDataValue = data => {
  let max = 1;

  for (let i = 0; i < data.length; i += 1) {
    max = Math.max(max, data[i].value);
  }

  return max;
};

const convertMsToHHMM = ms => {
  if (ms === undefined) {
    return '00h00';
  }
  let sec = Math.floor(ms / 1000);
  let min = Math.floor(sec / 60);
  sec %= 60;
  min = sec >= 30 ? min + 1 : min;
  const hours = Math.floor(min / 60);
  min %= 60;

  return `${padToTwoDigits(hours)}h${padToTwoDigits(min)}`;
};

const callTooltip = (g, value) => {
  if (!value) {
    g.style('display', 'none');
  } else {
    g.style('display', null)
      .style('pointer-events', 'none')
      .style('font', '15px sans-serif');

    const path = g.selectAll('path')
      .data([null])
      .join('path')
      .attr('class', `background_${localStorage.theme === 'Dark' ? 'dark' : 'light'}`);

    const text = g.selectAll('text')
      .data([null])
      .join('text')
      .call(t => t
        .selectAll('tspan')
        .data((`${displayTooltipDowntime(value.value)}\n${value.name}`).split(/\n/))
        .join('tspan')
        .attr('x', 0)
        .attr('y', (d, i) => `${i * 1.1}em`)
        .attr('class', `centerText text_${localStorage.theme === 'Dark' ? 'dark' : 'light'}`)
        .text(d => d));

    const { y, width: w, height: h } = text.node().getBBox();
    text.attr('transform', `translate(0,${15 - y})`);
    path.attr('d', `M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${1.45 * h}h-${w + 20}z`);
  }
};

type Cause = StopCause | PerformanceCause;

type CombinedCause = Cause & { subMenu?: CombinedCause[] };

const flattenCauses = (causes: CombinedCause[]): CombinedCause[] => {
  let flatCauses: CombinedCause[] = [];

  for (const cause of causes) {
    flatCauses.push(cause);

    if (cause.subMenu) {
      flatCauses = flatCauses.concat(flattenCauses(cause.subMenu));
    }
  }

  return flatCauses;
};

const ParetoChart = ({
  tile,
  height,
  width,
  stats,
  machines,
  showArrows,
  language,
}) => {
  const node = useRef(null);
  const { t } = useTranslation();

  const getCauseData = (causeType: 'stopCause' | 'performanceCause') => {
    const machine = machines.find(m => m.id === tile.machineId);
    const causes = causeType === 'stopCause' ? machine.stopCauses : machine.performanceCauses;
    const flattenedCauses = flattenCauses(causes);
    const hiddenCauses = causeType === 'stopCause' ? tile.hiddenStopCauses : tile.hiddenPerformanceCauses;
    const statsCauses = causeType === 'stopCause' ? stats?.stopCauses : stats?.performanceCauses;
    const totalCauseTime = causeType === 'stopCause' ? stats.totalDowntime : stats.totalPerformanceDropTime;
    const filledInCause = causeType === 'stopCause' ? stats.filledInDowntime : stats.filledInPerformanceCause;

    const data: { x?: number; name: string; color: string; value: number; }[] = [];
    let sum = 0;

    for (let i = 0; i < flattenedCauses.length; i += 1) {
      const cause = flattenedCauses[i];
      const causeStats = statsCauses?.find(s => s[`${causeType}Id`] === cause.id);
      const isNotHidden = !hiddenCauses?.find(id => id === cause.id);
      if (isNotHidden && causeStats?.duration) {
        let causeName = cause.name;
        if (language === 'en' && cause.nameEN) {
          causeName = cause.nameEN;
        } else if (language === 'es' && cause.nameES) {
          causeName = cause.nameES;
        } else if (language === 'fr' && cause.nameFR) {
          causeName = cause.nameFR;
        }
        data.push({
          name: causeName,
          color: cause.color,
          value: causeStats.duration,
        });
        sum += causeStats.duration;
      }
    }

    const max = getMaxDataValue(data);

    for (let i = 0; i < data.length; i += 1) {
      const element = data[i];
      data[i] = { ...element, x: element.value / max };
    }

    return {
      data: data.sort((a, b) => b.value - a.value).slice(0, Math.min(data.length, tile.nbDisplayedRows || 5)),
      totalDowntime: sum,
      unknownValue: totalCauseTime - filledInCause,
      otherValue: filledInCause - sum,
    };
  };

  const getDowntimes = () => getCauseData(tile.causeType);

  const createParetoChart = () => {
    const { data, totalDowntime, unknownValue, otherValue } = getDowntimes();

    const heightContent = 0.85 * height - PADDING_BOTTOM;
    const heightHeaderFooter = heightContent * 0.15;

    d3.select(node.current).selectAll('*').remove();

    const totalDowntimeSvg = d3.select(node.current)
      .append('svg')
      .classed('nocursorpointer', true)
      .attr('width', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('height', heightHeaderFooter);

    totalDowntimeSvg.append('text')
      .attr('x', 0)
      .attr('y', heightHeaderFooter / 2 + 2)
      .text(convertMsToHHMM(totalDowntime))
      .style('font-size', '20px')
      .style('fill', 'white');

    // SVG for the graph
    const svg = d3.select(node.current)
      .append('svg')
      .classed('nocursorpointer', true)
      .attr('width', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('height', heightContent * 0.7);

    // Define a scale for the y positions
    const yScale = d3.scaleBand()
      .domain(data.map((d, i) => i))
      .range([10, heightContent * 0.7])
      .padding(0.1);

    // Bars
    const bars = svg.selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('y', (d, i) => yScale(i) + 2)
      .attr('width', d => (d.value / totalDowntime) * (showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2))
      .attr('height', Math.min(yScale.bandwidth() * 0.45, MAX_BAR_HEIGHT))
      .attr('fill', d => d.color || BLUE)
      .attr('rx', 5)
      .attr('ry', 5);

    // Cause name
    svg.selectAll('text.bar')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'bar')
      .attr('text-anchor', 'start')
      .attr('x', 0)
      .attr('y', (d, i) => yScale(i) - 2)
      .text(d => (width < 300 && d.name.length > 12 ? `${d.name.substring(0, 12)}...` : d.name))
      .style('fill', 'white')
      .style('font', `${Math.min(yScale.bandwidth() / 2, 15)}px sans-serif`);

    // Time value
    svg.selectAll('text.time')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'time')
      .attr('text-anchor', 'end')
      .attr('x', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('y', (d, i) => yScale(i) - 2)
      .text(d => `${convertMsToHHMM(d.value)}` || (d.value !== undefined ? round(d.value, 1) : ''))
      .style('fill', 'white')
      .style('font', `${Math.min(yScale.bandwidth() / 2, 15)}px sans-serif`);

    const textSpacing = heightHeaderFooter / 2;

    const footerSvg = d3.select(node.current)
      .append('svg')
      .classed('nocursorpointer', true)
      .attr('width', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('height', heightHeaderFooter);

    const generateText = () => {
      const textKey = tile.causeType === 'performanceCause' ? 'otherPerformanceDropCauses' : 'otherStopCauses';
      const text = t(textKey);
      return width < 300 && text.length > 12 ? `${text.substring(0, 12)}...` : text;
    };

    footerSvg.append('line')
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('y2', 0)
      .style('stroke', 'grey')
      .style('stroke-width', 2);

    footerSvg.append('text')
      .attr('x', 0)
      .attr('y', textSpacing)
      .text(generateText())
      .style('fill', 'white');

    footerSvg.append('text')
      .attr('x', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('y', textSpacing)
      .attr('text-anchor', 'end')
      .style('fill', 'white')
      .text(`${convertMsToHHMM(otherValue)}`);

    footerSvg.append('text')
      .attr('x', 0)
      .attr('y', (textSpacing * 2) - 1)
      .text(t('unknown'))
      .style('fill', 'white');

    footerSvg.append('text')
      .attr('x', showArrows ? width - PADDING_LEFT_WITH_ARROWS * 3 : width - PADDING_LEFT_WITH_ARROWS * 2)
      .attr('y', (textSpacing * 2) - 1)
      .attr('text-anchor', 'end')
      .style('fill', 'white')
      .text(`${convertMsToHHMM(unknownValue)}`);

    // Tooltip
    const tooltip = svg.append('g');
    bars.on('mousemove touchmove', d => {
      const [mouseX, mouseY] = d3.mouse(svg.node());
      tooltip.attr('transform', `translate(${mouseX},${mouseY})`)
        .call(callTooltip, d);
    })
      .on('mouseleave touchend', () => {
        tooltip.call(callTooltip, null);
      });
  };

  useEffect(() => {
    createParetoChart();
  }, [width, height, stats, tile.hiddenStopCauses, language]);

  return <div ref={node} />;
};

ParetoChart.propTypes = propTypes;
ParetoChart.defaultProps = defaultProps;

export default ParetoChart;
