
import {
  addAudio,
  removeAudio,
} from './actions';

export default {
  addAudio,
  removeAudio,
};
