import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  CancelButton, SubmitButton, FontAwesome,
  DefaultModal, BasicCard, Cards,
} from '~UI';
import { sortArray } from '~utils/sort';

const MultipleTopviewsPopup = ({
  showPopup, operation, validTopviews, topviewsSelected,
  cancelPopup, selectTopview,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {showPopup && <div className="fade modal-backdrop in" />}
      <DefaultModal
        closePopup={cancelPopup}
        show={showPopup}
        title={t('selectYourTopviews')}
        style={{ zIndex: 1500 }}
      >
        <div>
          <Cards>
            {sortArray('alphabetically', validTopviews, 'name')
              .map(topview => (
                <BasicCard
                  key={topview.id}
                  title={topview.name}
                  text={topview.description || ' '}
                  icon={<FontAwesome icon="desktop" size="4x" />}
                  onClick={() => selectTopview(topview.id)}
                  style={topviewsSelected.includes(topview.id) && { boxShadow: '0px 0px 4px 2px #0078FF' }}
                />
              ))}
          </Cards>
          <div className="buttonsHolder flexSpaceBetween">
            <div />
            <div>
              <CancelButton onClick={cancelPopup} />
              <SubmitButton label={t('confirm')} onClick={operation} />
            </div>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

MultipleTopviewsPopup.propTypes = {
  showPopup: PropTypes.bool,
  operation: PropTypes.func.isRequired,
  cancelPopup: PropTypes.func.isRequired,
  validTopviews: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  topviewsSelected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectTopview: PropTypes.func.isRequired,
};
MultipleTopviewsPopup.defaultProps = {
  showPopup: () => { },
};

export default MultipleTopviewsPopup;
