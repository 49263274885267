import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { RootState } from '~services/store';
import { reduxOperations } from '~services/index';
import { getFormData } from '~utils/index';
import { showError } from '~utils/toast';

import { MachineParamsPopup } from './MachineParamsPopup';

const MachineSKUPopup = ({
  machineId, machineParams, onHide, onSubmitTrigger, params, show, eventModifyTrigger,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const machines = useSelector((state: RootState) => state.machines);

  const { skuNumbers } = machines.find(m => m.id === machineId);

  const handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    const formData = getFormData('SKU__Popup__Form');
    if (skuNumbers.find(s => s.name === formData.skuNumber)) {
      if (eventModifyTrigger) {
        eventModifyTrigger(formData.skuNumber);
      } else {
        reduxOperations.machines.setParams(machineId, { ...machineParams, ...formData })(dispatch);
        if (onSubmitTrigger) {
          const optionalTriggerParams = { ...params, ...formData };
          reduxOperations.triggers.executeTrigger(onSubmitTrigger, {
            ...optionalTriggerParams,
            machineId,
          })(dispatch);
        }
      }
    } else {
      showError(t('SKUNotExist'));
    }
  };

  return (
    <MachineParamsPopup
      parameter={machineParams.skuNumber}
      onHide={onHide}
      show={show}
      paramType="SKU"
      onSubmit={handleSubmit}
      options={skuNumbers}
    />
  );
};

MachineSKUPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  machineId: PropTypes.string.isRequired,
  machineParams: PropTypes.object.isRequired,
  onSubmitTrigger: PropTypes.string,
  params: PropTypes.object,
  eventModifyTrigger: PropTypes.func,
};
MachineSKUPopup.defaultProps = {
  onSubmitTrigger: '',
  params: {},
  eventModifyTrigger: null,
};

export { MachineSKUPopup };
