import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Input, Select } from '@intelligenceindustrielle/react-ui-components';
import { initializeSelectedUsers } from '~components/SelectionGrid';
import {
  CancelButton, DeleteButton, DefaultModal, MultiSelect, SubmitButton, TagsInput, FileUpload,
} from '~components/UI';
import { RootState } from '~services/store';
import { reduxOperations } from '~services/index';
import { getFormData } from '~utils/getFormData';
import { showSuccess, showError } from '~utils/toast';
import { sortArray } from '~utils/sort';
import { idsToTags } from '~utils/tags';
import { configurationFeature } from '~utils/featureToggles';
import { Folder } from '~services/folders/types';
import { handleFileSelect } from '~utils/handleFileSelect';

const TopviewPopUpForm = ({
  modifiedItemId,
  show,
  onHide,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const topviews = useSelector((state: RootState) => state.topviews.topviews);
  const folders = useSelector((state: RootState) => state.folders);
  const images = useSelector((state: RootState) => state.images.images);
  const tags = useSelector((state: RootState) => state.tags.tags);
  const userId = useSelector((state: RootState) => state.session.connexion.infos.id);
  const users = useSelector((state: RootState) => state.users.users);

  const topview = topviews?.find(r => r.id === modifiedItemId);

  const [folder, setFolder] = useState(topview?.folderId);
  const [image, setImage] = useState(topview?.backgroundURL || '');
  const [topviewTags, setTopviewTags] = useState(topview && tags ? idsToTags(topview.tags, tags) : []);
  const [selectedUsers, setSelectedUsers] = useState<{ value: string }[]>([]);

  const updateUsersPermissions = id => {
    const usersWithPermission = selectedUsers.concat([{ value: userId }]);

    const permissionsData = {
      users: usersWithPermission.map(user => user.value),
      content: {
        type: 'Topview',
        id,
      },
    };
    if (configurationFeature.isUserAllowedAccessUsers()) {
      reduxOperations.users.updateUsersContentsPermissions(permissionsData)(dispatch).then(() => {
        dispatch(reduxOperations.users.fetchAllUsersPermissions() as any);
      });
    }
  };

  const handleOnHide = () => {
    onHide();
    setFolder(topview?.folderId);
    setSelectedUsers([]);
  };

  const handleSubmit = e => {
    e.preventDefault();

    const formData = getFormData('Topview__form');
    formData.tags = topviewTags;

    if (!formData.name) {
      showError(t('missingName'));
      return;
    }

    if (formData.folderId === '-') {
      formData.folderId = '';
    }

    if (modifiedItemId) {
      reduxOperations.topviews.updateTopview(modifiedItemId, formData)(dispatch)
        .then(() => {
          handleOnHide();
          dispatch(reduxOperations.tags.forceFetchTags() as any);
          showSuccess(t('showSuccessUpdated'));
          updateUsersPermissions(modifiedItemId);
        }).catch(() => {
          showError(t('error'));
        });
    } else {
      reduxOperations.topviews.createTopview(formData)(dispatch)
        .then(res => {
          handleOnHide();
          dispatch(reduxOperations.tags.forceFetchTags() as any);
          showSuccess(t('showSuccessCreated'));
          updateUsersPermissions(res.payload.topview.id);
          history.replace(`/topview/${res.payload.topview.id}`);
        }).catch(() => {
          showError(t('error'));
        });
    }
  };

  const onFileSelect = selectedFile => {
    handleFileSelect(selectedFile, dispatch, t, setImage);
  };

  const handleDelete = async () => {
    reduxOperations.topviews.createTopview(modifiedItemId);
    handleOnHide();
  };

  const handleUsersSelect = options => {
    setSelectedUsers(options);
  };

  useEffect(() => {
    if (modifiedItemId) {
      setSelectedUsers(initializeSelectedUsers('Topview', modifiedItemId, users, userId));
    }
  }, [modifiedItemId, users]);

  useEffect(() => {
    const newTopview = topviews?.find(v => v.id === modifiedItemId);

    setTopviewTags(newTopview && idsToTags(newTopview.tags, tags));
  }, [show]);

  const usersToDisplay = users ? users.filter(user => user.display && user.id !== userId) : [];
  const usersOptions = usersToDisplay.map(user => ({
    label: user.name,
    value: user.id,
  }));

  return (
    <DefaultModal
      title={modifiedItemId ? t('editTopview') : t('createNewTopview')}
      show={show}
      closePopup={handleOnHide}
    >
      <form
        id="Topview__form"
        onSubmit={handleSubmit}
      >
        <div className="inputTitle">{t('name')}</div>
        <Input
          defaultValue={topview?.name}
          name="name"
          placeholder={t('valueInput')}
        />
        <div className="inputTitle">{t('description')}</div>
        <Input
          defaultValue={topview?.description}
          name="description"
          placeholder={t('valueInput')}
        />

        <div className="inputTitle">{`${t('image')} URL`}</div>
        <div className="fileContainer">
          <Input
            className="fullwidth"
            value={image}
            onChange={setImage}
            name="backgroundURL"
            placeholder={t('valueInput')}
            style={{ marginRight: '10px', flexGrow: 1 }}
          />
          <FileUpload
            onFileSelect={onFileSelect}
            images={images}
            image={image}
            setImage={setImage}
          />
        </div>
        {folders && (
          <>
            <div className="inputTitle">{t('folder')}</div>
            <Select
              className="fullwidth"
              name="folderId"
              value={folder || '-'}
              onChange={setFolder}
              options={[{ label: '-', value: '-' },
                ...sortArray('alphabetically', folders, 'name')?.map((f: Folder) => ({ label: f.name, value: f.id }))]}
            />
          </>
        )}
        {
          configurationFeature.isUserAllowedAccessUsers() && (
            <>
              <div className="inputTitle">{t('UserPermissions')}</div>
              <MultiSelect
                options={usersOptions}
                value={selectedUsers}
                onChange={options => handleUsersSelect(options)}
              />
            </>
          )
        }
        <div className="inputTitle">{t('tags')}</div>
        <TagsInput
          tags={tags}
          currentTags={topviewTags || []}
          modifyTags={newTags => setTopviewTags(newTags)}
        />
        {modifiedItemId && (
          <div style={{ margin: '1rem 0' }}>
            <div style={{ fontSize: '17px' }}>
              <div
                style={{ margin: '0.7rem 0' }}
                title={topview?.createdAt && DateTime.fromMillis(topview?.createdAt).toISO()}
              >
                {t('createdBy')}
                &nbsp;
                <b>{users?.find(user => user.id === topview?.createdBy)?.name}</b>
                &nbsp;
                {topview?.createdAt && DateTime.fromMillis(topview.createdAt).toRelative()}
              </div>
              <div
                style={{ margin: '0.7rem 0' }}
                title={topview.modifiedAt && DateTime.fromMillis(topview.modifiedAt).toISO()}
              >
                {t('modifiedBy')}
                &nbsp;
                <b>{users?.find(user => user.id === topview.createdBy)?.name}</b>
                &nbsp;
                {topview.modifiedAt && DateTime.fromMillis(topview.modifiedAt).toRelative()}
              </div>
            </div>
          </div>
        )}
        <div className="buttonsHolder flexSpaceBetween">
          {
            modifiedItemId ? (
              <DeleteButton handleDelete={handleDelete} askConfirmation />
            ) : <div />
          }
          <div>
            <CancelButton onClick={handleOnHide} />
            <SubmitButton label={modifiedItemId ? t('modify') : t('add')} />
          </div>
        </div>
      </form>
    </DefaultModal>
  );
};

TopviewPopUpForm.propTypes = {
  modifiedItemId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

TopviewPopUpForm.defaultProps = {
  modifiedItemId: '',
};

export default TopviewPopUpForm;
