import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setTileWidgetValue } from '~services/tileWidgetValue/actions';
import { reducersTypes } from '~services';
import { getFontSizeToFitTile } from '~utils/responsiveValue';

function TextTileContent({
  displayText,
  height,
  tile,
  width,
}) {
  const dispatch = useDispatch();
  const { contentSize, zoom } = tile;
  const [valueFontSize, setValueFontSize] = useState();
  const [splitText, setSplitText] = useState([]);

  useEffect(() => {
    dispatch(setTileWidgetValue({ selectedObjectId: tile.id, value: displayText }));
  }, [displayText, tile.id]);

  function updateFontSize() {
    if (!contentSize) {
      const text = displayText.split('\n');
      const longestString = text.reduce((a, b) => (a.length > b.length ? a : b));
      const { valueFontSize: newFontSize } = getFontSizeToFitTile(height, width, longestString, false, false, zoom);
      setValueFontSize(newFontSize);
    }
  }

  useEffect(() => {
    if (contentSize) {
      setValueFontSize(contentSize);
    } else {
      updateFontSize();
    }
  }, [contentSize, height, width]);

  function updateText() {
    const text = displayText.split('\n');
    setSplitText(text);
    updateFontSize();
  }

  useEffect(() => {
    updateText();
  }, [displayText]);

  useEffect(() => {
    updateFontSize();
  }, [zoom]);

  useEffect(() => {
    window.addEventListener('resize', updateFontSize);
    return () => {
      window.removeEventListener('resize', updateFontSize);
    };
  }, []);

  return (
    <div
      className="framed flex V"
      style={{ fontSize: `${valueFontSize}px`, lineHeight: 1 }}
    >
      {
        splitText.map((line, index) => {
          if (index !== 0) {
            return (
              <>
                <br />
                {line}
              </>
            );
          }
          return line;
        })
      }
    </div>
  );
}

TextTileContent.propTypes = {
  displayText: PropTypes.string,
  height: PropTypes.number.isRequired,
  tile: reducersTypes.dashboards.tile,
  width: PropTypes.number.isRequired,
};
TextTileContent.defaultProps = {
  displayText: '',
  tile: {},
};

export default TextTileContent;
