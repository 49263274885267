import PropTypes from 'prop-types';
import nullable from 'prop-types-nullable';
import { FolderAction, FolderActionTypes } from './actions';
import { Folder } from './types';

export type FolderReducerState = Folder[] | null;

function folders(state: FolderReducerState = null, action: FolderAction) {
  switch (action.type) {
    case FolderActionTypes.GET_FOLDERS_RESPONSE:
      return action.payload.folders;
    case FolderActionTypes.ADD_FOLDER_RESPONSE:
      return state?.concat(action.payload.folder);
    case FolderActionTypes.UPDATE_FOLDER_RESPONSE:
    case FolderActionTypes.ADD_SUBFOLDER_RESPONSE:
    case FolderActionTypes.UPDATE_SUBFOLDER_RESPONSE:
      return state?.map(folder => (
        (folder.id === action.payload.folder.id)
          ? action.payload.folder
          : folder
      ));
    case FolderActionTypes.DELETE_FOLDER_RESPONSE:
      return state?.filter(folder => folder.id !== action.payload.id);
    case FolderActionTypes.DELETE_SUBFOLDER_RESPONSE:
      return state?.map(folder => {
        if (folder.subfolders) {
          return {
            ...folder,
            subfolders: folder.subfolders.filter(subfolder => subfolder.id !== action.payload.id),
          };
        }
        return folder;
      });
    default:
      return state;
  }
}

const folderPropTypes = PropTypes.shape({
  color: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
});

export const propTypes = {
  folders: nullable(
    PropTypes.arrayOf(folderPropTypes),
  ),
};

export default folders;
