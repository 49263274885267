import React, { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import API from '~services/endpoints';
import { SubmitButton, MultiSelect } from '~UI';
import { showSuccess, showError } from '~utils/toast';
import './EventFilterForm.scss';

const EventFilterForm = ({ onSubmit, type, filter }) => {
  const { t, i18n } = useTranslation();

  const settings = useSelector(state => state.settings.settings);
  const allMachines = useSelector(state => state.machines);
  const shifts = useSelector(state => state.shifts.shifts);
  const { eventPageFilters } = settings;

  const [selectedDepartments, setSelectedDepartments] = useState(
    eventPageFilters.departments?.map(d => ({ label: d.name, value: d.id })),
  );
  const [machine, setMachine] = useState(filter?.machine || '');
  const [eventType, setEventType] = useState(filter?.eventType || '');
  const [day, setDay] = useState(filter?.day || '');
  const [shift, setShift] = useState(filter?.shift || '');
  const [workOrder, setWorkOrder] = useState(filter?.workOrder || '');
  const [operation, setOperation] = useState(filter?.operation || '');
  const [sku, setSku] = useState(filter?.sku || '');

  const initialMachine = useRef(machine);
  const initialDay = useRef(day);

  const [machineOptions, setMachineOptions] = useState([]);
  const [eventTypeOptions, setEventTypeOptions] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [workOrderOptions, setWorkOrderOptions] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);
  const [skuOptions, setSkuOptions] = useState([]);

  const [timestampStart, setTimestampStart] = useState(null);
  const [timestampEnd, setTimestampEnd] = useState(null);

  const fetchMachineParams = async param => {
    const filterData = {
      property: param,
      machineIds: machine,
      filter: {
        timestamp: {
          $gt: timestampStart,
          $lt: timestampEnd,
        },
      },
    };

    const { eventsPropertyElements } = await API.getDistinctMachineStatusEvents(filterData);

    const formattedOptions = eventsPropertyElements.map(element => ({
      label: element,
      value: element,
    }));

    switch (param) {
      case 'workOrder':
        setWorkOrderOptions(formattedOptions);
        break;
      case 'skuNumber':
        setSkuOptions(formattedOptions);
        break;
      case 'operation':
        setOperationOptions(formattedOptions);
        break;
      default:
        break;
    }
  };

  const departmentOptions = eventPageFilters.departments.map(dep => ({
    value: dep.id,
    label: dep.name,
  }));

  useEffect(() => {
    switch (type) {
      case 'machine':
        setEventTypeOptions([
          { label: 'ON', value: 'ON' },
          { label: 'OFF', value: 'OFF' },
        ]);
        break;
      case 'part':
        setEventTypeOptions([
          { label: 'IN', value: 'IN' },
          { label: 'OUT', value: 'OUT' },
          { label: 'SCRAP', value: 'SCRAP' },
        ]);
        break;
      default:
        setEventTypeOptions([]);
    }
  }, [type]);

  const dateOptions = Array.from({ length: 7 }, (_, i) => {
    const formatDate = new Date();
    formatDate.setDate(formatDate.getDate() - i);
    const value = formatDate.toISOString().split('T')[0];
    const locale = i18n.language === 'fr' ? 'fr-CA' : 'en-US';
    const label = formatDate.toLocaleDateString(locale, { month: 'short', day: '2-digit' });
    return { label, value };
  });

  const allMachineOptions = allMachines.map(m => ({ label: m.name, value: m.id }));

  const handleSubmit = event => {
    event.preventDefault();
    if (!machine || !day) {
      showError(t('machineAndDateRequired'));
      return;
    }
    const formData = {
      selectedDepartments,
      machine,
      eventType,
      day,
      shift,
      workOrder,
      operation,
      sku,
      timestampStart,
      timestampEnd,
    };
    onSubmit(formData);
    showSuccess('Filter applied');
  };

  const getTimezoneOffset = () => {
    const offsetInMinutes = new Date().getTimezoneOffset();
    const offsetHours = Math.abs(offsetInMinutes / 60);
    const offsetMinutes = Math.abs(offsetInMinutes) % 60;
    const sign = offsetInMinutes > 0 ? '-' : '+';
    return `${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;
  };

  useEffect(() => {
    if (selectedDepartments.length === 0) {
      if (eventPageFilters.departments.length === 0) {
        setMachineOptions(allMachineOptions);
      } else {
        setMachine('');
        setMachineOptions([]);
      }
    } else {
      const selectedDepIds = selectedDepartments.map(option => option.value);
      const selectedDeps = eventPageFilters.departments.filter(d => selectedDepIds.includes(d.id));
      if (selectedDeps.length > 0) {
        const newMachineOptions = selectedDeps.flatMap(dep => dep.machines.map(machineId => {
          const machineOption = allMachines.find(m => m.id === machineId);
          return { label: machineOption?.name, value: machineOption?.id };
        }));
        setMachineOptions(newMachineOptions);
      }
    }
  }, [selectedDepartments]);

  useEffect(() => {
    if ((machine !== initialMachine.current || day !== initialDay.current) && machine && day) {
      setShift('');
    }
  }, [machine, day]);

  useEffect(() => {
    if (day && machine) {
      const selectedDate = new Date(`${day}T12:00:00${getTimezoneOffset()}`);
      const dayNumber = selectedDate.getDay();

      const selectedMachine = allMachines.find(m => m.id === machine);
      const shiftsToUse = selectedMachine?.shifts.length > 0 ? selectedMachine.shifts : shifts;

      const shiftsForSelectedDay = shiftsToUse.filter(s => s.days.includes(dayNumber));

      const formatShiftOptions = shiftsForSelectedDay.map(s => ({
        label: s.name,
        value: s.id,
      }));
      setShiftOptions(formatShiftOptions);

      if (!shift) {
        const selectedDateStart = new Date(`${day}T00:00:00${getTimezoneOffset()}`);
        const selectedDateEnd = new Date(`${day}T23:59:59${getTimezoneOffset()}`);
        setTimestampStart(selectedDateStart.getTime());
        setTimestampEnd(selectedDateEnd.getTime());
      }
    }
  }, [day, machine, shift]);

  useEffect(() => {
    if (shift) {
      const selectedMachine = allMachines.find(m => m.id === machine);
      const shiftsToUse = selectedMachine?.shifts.length > 0 ? selectedMachine.shifts : shifts;
      const selectedShift = shiftsToUse.find(s => s.id === shift);
      if (selectedShift) {
        const selectedDateStart = new Date(`${day}T12:00:00${getTimezoneOffset()}`);
        selectedDateStart.setHours(selectedShift.hourStart, selectedShift.minuteStart);
        const selectedDateEnd = new Date(`${day}T12:00:00${getTimezoneOffset()}`);
        selectedDateEnd.setHours(selectedShift.hourEnd, selectedShift.minuteEnd);
        if (selectedShift.hourEnd < selectedShift.hourStart) {
          selectedDateEnd.setDate(selectedDateEnd.getDate() + 1);
        }
        setTimestampStart(selectedDateStart.getTime());
        setTimestampEnd(selectedDateEnd.getTime());
      }
    }
  }, [shift]);

  useEffect(() => {
    if (timestampStart && timestampEnd && machine) {
      fetchMachineParams('workOrder');
      fetchMachineParams('operation');
      fetchMachineParams('skuNumber');
    }
  }, [timestampStart, timestampEnd, machine]);

  return (
    <form id="filterForm">
      <div className="formBody">
        {eventPageFilters.departments.length !== 0 && (
          <div className="selectContainer">
            <div className="inputTitle">{t('departments')}</div>
            <MultiSelect
              className="multi-select"
              options={departmentOptions}
              value={selectedDepartments}
              onChange={option => setSelectedDepartments(option)}
            />
          </div>
        )}
        <div className="selectContainer">
          <RequiredInput title={t('machine')} />
          <Select
            name="machine"
            options={machineOptions}
            value={machine}
            onChange={setMachine}
          />
        </div>
        <div className="selectContainer">
          <RequiredInput title={t('date')} />
          <Select
            name="date"
            options={dateOptions}
            value={day}
            onChange={setDay}
          />
        </div>
        {machine && day && (
          <>
            {type !== 'performance' && (
              <div className="selectContainer">
                <RemovableInput title={t('eventType')} value={eventType} onRemove={() => setEventType('')} />
                <Select
                  name="eventType"
                  options={eventTypeOptions}
                  value={eventType}
                  onChange={setEventType}
                />
              </div>
            )}
            <div className="selectContainer">
              <RemovableInput title={t('shift')} value={shift} onRemove={() => setShift('')} />
              <Select
                name="workShift"
                options={shiftOptions}
                value={shift}
                onChange={setShift}
              />
            </div>
            {eventPageFilters.workOrder && (
              <div className="selectContainer">
                <RemovableInput title={t('workOrder')} value={workOrder} onRemove={() => setWorkOrder('')} />
                <Select
                  name="workOrder"
                  options={workOrderOptions}
                  value={workOrder}
                  onChange={setWorkOrder}
                />
              </div>
            )}
            {eventPageFilters.operation && (
              <div className="selectContainer">
                <RemovableInput title={t('operation')} value={operation} onRemove={() => setOperation('')} />
                <Select
                  name="operation"
                  options={operationOptions}
                  value={operation}
                  onChange={setOperation}
                />
              </div>
            )}
            {eventPageFilters.skuNumber && (
              <div className="selectContainer">
                <RemovableInput title={t('skuNumber')} value={sku} onRemove={() => setSku('')} />
                <Select
                  name="skuNumber"
                  options={skuOptions}
                  value={sku}
                  onChange={setSku}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div className="submit">
        <SubmitButton className="buttonsHolder" label={t('apply')} onClick={handleSubmit} />
      </div>
    </form>
  );
};

const RemovableInput = ({ title, value, onRemove }) => (
  <div className="removableInput">
    {title}
    {value && (
      <span className="removeInput" onClick={onRemove}>
        X
      </span>
    )}
  </div>
);

const RequiredInput = ({ title }) => (
  <div className="requiredInput">
    {title}
    &nbsp;
    <div className="requiredIcon">*</div>
  </div>
);

RequiredInput.propTypes = {
  title: PropTypes.string.isRequired,
};

RemovableInput.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

EventFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  filter: PropTypes.shape({
    machine: PropTypes.string,
    eventType: PropTypes.string,
    day: PropTypes.string,
    shift: PropTypes.string,
    workOrder: PropTypes.string,
    operation: PropTypes.string,
    sku: PropTypes.string,
  }),
};

EventFilterForm.defaultProps = {
  filter: {},
};

export default EventFilterForm;
