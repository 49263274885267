import React from 'react';
import PropTypes from 'prop-types';
import './PageTitleAndDescription.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const PageTitleAndDescription = ({ title, description }) => ((title.length > 0 || description.length > 0)
  ? (
    <div className="Page_Title_Description flex V">
      {title.length > 0 ? <h2>{title}</h2> : null}
      {description.length > 0 ? <p>{description}</p> : null}
    </div>
  )
  : null);

PageTitleAndDescription.propTypes = propTypes;

export default PageTitleAndDescription;
