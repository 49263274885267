import types from './types';

const getTriggersRequest = () => ({
  type: types.GET_TRIGGERS_REQUEST,
});
const getTriggersResponse = response => ({
  type: types.GET_TRIGGERS_RESPONSE,
  payload: response,
});

const addTriggerRequest = trigger => ({
  type: types.ADD_TRIGGER_REQUEST,
  payload: trigger,
});

const addTriggerResponse = data => ({
  type: types.ADD_TRIGGER_RESPONSE,
  payload: data,
});

const updateTriggerRequest = (triggerId, triggerData) => ({
  type: types.UPDATE_TRIGGER_REQUEST,
  payload: { triggerId, triggerData },
});

const updateTriggerResponse = data => ({
  type: types.UPDATE_TRIGGER_RESPONSE,
  payload: data,
});

const patchTriggerRequest = (triggerId, triggerData) => ({
  type: types.PATCH_TRIGGER_REQUEST,
  payload: { triggerId, triggerData },
});

const patchTriggerResponse = data => ({
  type: types.PATCH_TRIGGER_RESPONSE,
  payload: data,
});

const deleteTriggerRequest = triggerId => ({
  type: types.DELETE_TRIGGER_REQUEST,
  payload: triggerId,
});

const deleteTriggerResponse = data => ({
  type: types.DELETE_TRIGGER_RESPONSE,
  payload: data,
});

const executeTriggerRequest = (triggerId, params) => ({
  type: types.TRIGGER_TRIGGER_REQUEST,
  payload: {
    triggerId,
    params,
  },
});

const executeTriggerResponse = data => ({
  type: types.TRIGGER_TRIGGER_RESPONSE,
  payload: data,
});

export {
  getTriggersRequest,
  getTriggersResponse,
  addTriggerRequest,
  addTriggerResponse,
  updateTriggerRequest,
  updateTriggerResponse,
  patchTriggerRequest,
  patchTriggerResponse,
  deleteTriggerRequest,
  deleteTriggerResponse,
  executeTriggerRequest,
  executeTriggerResponse,
};
