import types from './types';

const redirectHistoryClear = () => ({
  type: types.CLEAR,
});

const redirectHistoryPop = () => ({
  type: types.POP,
});

const redirectHistoryPush = path => ({
  type: types.PUSH,
  payload: path,
});

export {
  redirectHistoryClear,
  redirectHistoryPop,
  redirectHistoryPush,
};
