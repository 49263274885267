import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import omit from 'lodash.omit';
import { Select } from '@intelligenceindustrielle/react-ui-components';
import { DeleteConfirmationForm } from '~components/Popups';
import {
  ModalHandler, ResourcesHandler,
  Cards, BasicCard, CreateCard, FontAwesome, SubmitButton,
} from '~UI';
import { reduxOperations } from '~services';
import { padding } from '~utils';
import { sortArray } from '~utils/sort';
import { showError, showSuccess } from '~utils/toast';
import { configurationFeature } from '~utils/featureToggles';

import { Page401Unauthorized } from '../../../ErrorPages';
import ShiftPopUpForm from './ShiftPopUpForm';
import './Shifts.scss';

const prettyPrintShift = shift => `${padding(shift.hourStart)}:${padding(shift.minuteStart)}  -  ${padding(shift.hourEnd)}:${padding(shift.minuteEnd)}`;

const MachineShifts = ({
  deleteMachineShift, commandsSave, addMachineShift, fetchMachines, patchMachine, machineId, machines,
}) => {
  const currMachine = machines.find(m => m.id === machineId);
  const { shifts } = currMachine;
  const { t } = useTranslation();
  const [selectedPreset, setSelectedPreset] = useState(null);

  const handleDelete = id => {
    deleteMachineShift(machineId, id);
    const shift = omit(shifts.find(s => s.id === id), ['id']);
    commandsSave({
      undoAction: { action: addMachineShift, params: [shift] },
      redoAction: { action: deleteMachineShift, params: [id] },
    });
  };

  const selectPreset = () => {
    const mach = machines.find(m => m.id === selectedPreset);

    if (mach?.shifts) {
      const newShifts = [...mach.shifts];
      patchMachine(machineId, { shifts: newShifts })
        .then(() => {
          showSuccess(t('operationSucceeded'));
        })
        .catch(error => showError(error.status));
    } else {
      showError(t('machineNotFound'));
    }
  };

  const presetSelection = () => (
    <>
      <span className="presetSelectionText">
        {`${t('createFromTemplate')}: `}
      </span>
      <Select
        style={{
          width: 150,
          display: 'inline-block',
        }}
        value={selectedPreset || '-'}
        onChange={setSelectedPreset}
        options={[
          { label: '-', value: '-' },
          ...sortArray('alphabetically', machines.filter(m => m.id !== machineId && m.shifts?.length > 0), 'name')
            .map(m => ({ label: m.name, value: m.id })),
        ]}
      />
      &nbsp;
      <SubmitButton
        onClick={() => selectPreset()}
        label={t('import')}
        askConfirmation
      />
    </>
  );

  const getContents = () => (
    <>
      <div className="shiftImport">{presetSelection()}</div>
      <Cards>
        <CreateCard
          title={t('addShift')}
          modal={{
            Component: ShiftPopUpForm,
            props: { isCreating: true, machineId },
          }}
        />
        {shifts.map(shift => (
          <ModalHandler
            key={shift.id}
            Trigger={{
              Component: BasicCard,
              props: {
                text: prettyPrintShift(shift),
                title: shift.name,
                icon: <FontAwesome icon="ii-shift" size="4x" />,
                editModal: {
                  Component: ShiftPopUpForm,
                  props: { modifiedItemId: shift.id, shifts, machineId },
                },
                deleteModal: {
                  Component: DeleteConfirmationForm,
                  props: { handleDelete: () => handleDelete(shift.id) },
                },
                style: { cursor: 'pointer' },
              },
            }}
            Modal={{
              Component: ShiftPopUpForm,
              props: { modifiedItemId: shift.id, shifts, machineId },
            }}
          />
        ))}
      </Cards>
    </>
  );

  return (configurationFeature.isUserAllowedAccessShifts() ? (
    <ResourcesHandler
      resources={[shifts]}
      resourceFetchers={[fetchMachines]}
      getContents={getContents}
    />
  ) : (
    <Page401Unauthorized />
  )
  );
};

MachineShifts.propTypes = {
  deleteMachineShift: PropTypes.func.isRequired,
  commandsSave: PropTypes.func.isRequired,
  addMachineShift: PropTypes.func.isRequired,
  fetchMachines: PropTypes.func.isRequired,
  patchMachine: PropTypes.func.isRequired,
  machines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  machineId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    language: state.views.language,
    machines: state.machines,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchMachines: reduxOperations.machines.fetchMachines,
    deleteMachineShift: reduxOperations.machines.deleteMachineShift,
    commandsSave: reduxOperations.commands.commandsSave,
    addMachineShift: reduxOperations.machines.addMachieShift,
    patchMachine: reduxOperations.machines.patchMachine,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MachineShifts);
