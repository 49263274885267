import PropTypes from 'prop-types';

export interface Audio {
  id: string;
  text: string;
  type: string;
  url: string;
}

export const AudioPropTypes = PropTypes.shape({
  id: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
});
