import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { reducersTypes } from '~services';
import { showError } from '~utils/toast';
import { simpleEval } from '~utils/parser';

import inputs from './inputs';
import { schemaToForm, formToPayload, parseExpression } from './parsers';

import './JsonForm.scss';

const propTypes = {
  src: PropTypes.object.isRequired,
  /* Refer to './schema.d.ts' */
  form: reducersTypes.forms.form.isRequired,
  params: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const defaultProps = {
  params: {},
};

class JsonForm extends Component {
  constructor(props) {
    super(props);
    const { form: rawForm, src, params } = this.props;
    const formData = {};
    const form = schemaToForm(rawForm, src, params);
    form.fields.forEach(({ id, default: defaultValue }) => {
      formData[id] = params[id] ? params[id] : defaultValue;
    });
    this.state = { form, formData };
  }

  onChange = (id, value) => {
    const { form: rawForm, src, params } = this.props;
    const { formData: stateFormData } = this.state;
    const formData = {
      ...stateFormData,
      [id]: value,
    };
    this.setState({
      formData,
      form: schemaToForm(rawForm, src, params, formData),
    });
  };

  onSubmit = e => {
    const { onSubmit, t } = this.props;
    const { formData, form } = this.state;
    e.preventDefault();
    // validate required fields
    for (const { id, required, label, if: condition } of form.fields) {
      if (!condition || this.evaluate(condition)) {
        // fields that can be filled
        if (required && !formData[id]) {
          showError(`${t('requiredField')}${label}`);
          return;
        }
      } else if (formData[id]) {
        formData[id] = undefined;
      }
    }
    formToPayload(formData, form).then(onSubmit);
  };

  evaluate = ([left, sign, right]) => {
    const { src, params } = this.props;
    const { formData } = this.state;
    const value1 = parseExpression(left, { src, params, formData });
    const value2 = parseExpression(right, { src, params, formData });
    return simpleEval(value1, sign, value2);
  };

  renderForm = () => {
    const { form: { fields }, formData } = this.state;
    return fields.map(field => {
      const {
        id, label, type, ui, if: condition,
      } = field;

      return !ui.hidden && (!condition || this.evaluate(condition)) && (
        <div key={id}>
          {!ui.hideLabel && <span className={classnames('inputTitle', field.required && 'required')} key={`l${id}`}>{label}</span>}
          {
            inputs[type](ui)({
              ...field, id, value: formData[id], onChange: this.onChange,
            })
          }
        </div>
      );
    });
  };

  render() {
    const { onCancel, t } = this.props;
    const { form } = this.state;

    return (
      <div className="JsonForm">
        {!(form.ui && form.ui.hideLabel) && <h3>{form.label}</h3>}
        <form onSubmit={this.onSubmit}>
          {this.renderForm()}
          <div style={{ marginTop: '40px', textAlign: (form.ui && form.ui.actions && form.ui.actions.align) || 'right' }}>
            <button
              type="submit"
              className={classnames('submitBtn', form.ui && form.ui.actions && form.ui.actions.size)}
            >
              {t('submit')}
            </button>
            <button
              type="button"
              onClick={onCancel}
              className={classnames('cancelBtn', form.ui && form.ui.actions && form.ui.actions.size)}
            >
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

JsonForm.propTypes = propTypes;
JsonForm.defaultProps = defaultProps;

const mapStateToProps = state => {
  const streams = state.streams || [];
  const variables = state.variables || [];
  const properties = [].concat(...streams.map(s => s.properties));
  const combinedVars = [
    ...properties.map(x => ({
      id: x.id,
      label: x.variable,
    })),
    ...variables.map(x => ({
      id: x.id,
      label: x.variable,
    })),
  ];
  return ({
    language: state.views.language,
    src: {
      variables: combinedVars,
      machines: state.machines.map(x => ({
        ...x,
        value: x.id,
        label: x.name,
      })),
    },
  });
};

export default (connect(mapStateToProps)(withTranslation()(JsonForm)));
