import types from './types';

const getImagesRequest = () => ({
  type: types.GET_IMAGES_REQUEST,
});

const getImagesResponse = response => ({
  type: types.GET_IMAGES_RESPONSE,
  payload: response,
});

const addImageRequest = request => ({
  type: types.ADD_IMAGE_REQUEST,
  payload: request,
});

const addImageResponse = response => ({
  type: types.ADD_IMAGE_RESPONSE,
  payload: response,
});

const deleteImageRequest = request => ({
  type: types.DELETE_IMAGE_REQUEST,
  payload: request,
});

const deleteImageResponse = response => ({
  type: types.DELETE_IMAGE_RESPONSE,
  payload: response,
});

export {
  getImagesRequest,
  getImagesResponse,
  addImageRequest,
  addImageResponse,
  deleteImageRequest,
  deleteImageResponse,
};
