import React from 'react';

const borderStrokeWidth = 5;

const progressBar = ({ height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 153" height={height} width={height * 2.5}>
    <g>
      <g>
        <path
          d="M 23.25 22
          L 487.25 22
          C 492.769 22 497.25 22.231 497.25 27.75
          L 497.25 125.75
          C 497.25 131.269 492.769 130 487.25 130
          L 23.25 130
          C 17.731 130 19 131.269 19 125.75
          L 19 27.75
          C 19 22.231 17.731 22 23.25 22 Z"
          style={{
            fill: 'none', stroke: 'currentColor', strokeWidth: borderStrokeWidth, strokeLinecap: 'square', strokeMiterlimit: 2,
          }}
        />
      </g>
      <g>
        <line x1="135.25" y1="22" x2="135.25" y2="130" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.5} stroke="currentColor" />
        <line x1="253.25" y1="22" x2="253.25" y2="130" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.5} stroke="currentColor" />
        <line x1="373" y1="22" x2="372.938" y2="130" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.5} stroke="currentColor" />

        <line x1="45.875" y1="22.125" x2="18.375" y2="48.625" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="42.125" y1="23.812" x2="20.625" y2="45.313" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="30.562" y1="21.563" x2="18" y2="34.687" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="60.188" y1="21.812" x2="17.063" y2="64.937" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="76.313" y1="22.125" x2="18" y2="79.125" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="91.758" y1="23.203" x2="16.5" y2="97.938" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="105.258" y1="25.313" x2="18.375" y2="112.175" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="121.875" y1="23.964" x2="19.5" y2="126.339" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="142.312" y1="20.204" x2="33.562" y2="128.954" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="155.437" y1="24.3" x2="46.312" y2="132.475" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="175.375" y1="20.438" x2="67.125" y2="128.688" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="189.437" y1="23.728" x2="80.937" y2="132.228" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="205.031" y1="24.469" x2="101.156" y2="128.429" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="223.297" y1="22.313" x2="114.047" y2="130.613" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="239.625" y1="23.113" x2="130.625" y2="131.571" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="256.391" y1="21.973" x2="148.891" y2="129.473" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="270.766" y1="23.91" x2="163.266" y2="131.41" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="292.391" y1="20.469" x2="183.391" y2="127.757" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="306.383" y1="24.155" x2="198.121" y2="131.875" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="326.136" y1="22.22" x2="219.203" y2="129.154" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="342.896" y1="23.367" x2="235.5" y2="130.763" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="360.961" y1="22.688" x2="253.523" y2="128.883" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="373.57" y1="28.922" x2="269.508" y2="132.568" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="371.25" y1="49.359" x2="291.312" y2="129.344" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="371.742" y1="68.039" x2="309.727" y2="130.688" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="373.547" y1="85.406" x2="326.109" y2="131.527" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="371.438" y1="105.375" x2="348.563" y2="128.321" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
        <line x1="371.25" y1="119.719" x2="361.313" y2="129.915" vectorEffect="non-scaling-stroke" strokeWidth={borderStrokeWidth * 0.2} stroke="currentColor" />
      </g>
    </g>
  </svg>
);

export default progressBar;
