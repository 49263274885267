// https://chrisfrew.in/blog/persist-and-remember-page-scroll-position-with-react-hooks/

import { useEffect } from 'react';

import useSessionStorage from './useSessionStorage';

// sets scrollY position of window based on a setting condition, i.e. when api calls are done
// also sets the scroll position when unmounting, i.e. a user navigates to a different page
function useContainerScrollPosition(storageKey, setCondition) {
  const [scrollYStorage, setScrollYStorage] = useSessionStorage(`${storageKey}_scrollY`, 0);
  const [, setScrollHeightStorage] = useSessionStorage(`${storageKey}_scrollHeight`);

  useEffect(() => {
    // if the setcondition is true (AKA everything in the DOM is loaded: fire off the scrollTo()!)
    if (storageKey && setCondition) {
      const container = document.getElementById(storageKey);
      if (container) {
        container.scrollTop = scrollYStorage || 0;
      }
    }
  }, [setCondition, scrollYStorage]);

  // purely on un mount: store the scroll position the user was at to sessionStorage
  // see the yellow note at https://reactjs.org/docs/hooks-effect.html near the very bottom
  useEffect(() => () => {
    if (storageKey) {
      const container = document.getElementById(storageKey);
      if (container) {
        setScrollYStorage(container.scrollTop);
        setScrollHeightStorage(container.scrollHeight);
      }
    }
  }, []);
}

export default useContainerScrollPosition;
