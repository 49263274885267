import React from 'react';
import PropTypes from 'prop-types';
import { FormDataEntry } from '~components/forms';

const FormTileDataEntry = ({ selectedTile }) => (
  <FormDataEntry
    selectedObject={selectedTile}
  />
);

FormTileDataEntry.propTypes = {
  selectedTile: PropTypes.object,
};
FormTileDataEntry.defaultProps = {
  selectedTile: {},
};

export default FormTileDataEntry;
