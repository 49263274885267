const scorecardTypes = {
  // alphabetical order
  EVENT: 'event',
  MACHINE: 'machine',
  VARIABLE: 'variable',
};

Object.freeze(scorecardTypes);

export { scorecardTypes };
