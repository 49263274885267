import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import omit from 'lodash.omit';

import { Select } from '@intelligenceindustrielle/react-ui-components';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AddIcon } from '~UI/RoundButton/RoundIcons';
import { SubmitButton } from '~UI';
import DraggableCauseIconContainer from '~components/UI/RoundButton/DraggableCauseIconContainer';
import { reduxOperations } from '~services';
import { showError, showSuccess } from '~utils/toast';
import { sortArray } from '~utils/sort';

import PerformanceCauseEdition from './PerformanceCauseEdition';
import PerformanceCauseForm from './PerformanceCauseForm';

const PerformanceCausesForm = ({ machineId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const machines = useSelector(state => state.machines);

  const [selectedPerformanceCause, setSelectedPerformanceCause] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [performanceCauses, setPerformanceCauses] = useState([]);

  useEffect(() => {
    const machine = machines.find(mach => mach.id === machineId);
    setPerformanceCauses(machine?.performanceCauses || []);
  }, [machineId, machines]);

  const updatePerformanceCauses = newPerformanceCauses => {
    setPerformanceCauses(newPerformanceCauses);
    dispatch(reduxOperations.machines.patchMachine(machineId, { performanceCauses: newPerformanceCauses }));
  };

  const selectPreset = () => {
    const machine = machines.find(mach => mach.id === selectedPreset);

    if (machine && machine.performanceCauses) {
      const newPerformanceCauses = machine.performanceCauses.map(topCause => omit({
        ...topCause,
        subMenu: topCause.subMenu.map(subCause => omit(subCause, 'id')),
      }, 'id'));
      dispatch(reduxOperations.machines.patchMachine(machineId, { performanceCauses: newPerformanceCauses }))
        .then(() => {
          showSuccess(t('operationSucceeded'));
        })
        .catch(error => showError(error.status));
    } else {
      showError(t('machineNotFound'));
    }
  };

  const presetSelection = () => (
    <>
      <span className="presetSelectionText">
        {`${t('createFromTemplate')}: `}
      </span>
      <Select
        style={{ width: '150px', display: 'inline-block' }}
        value={selectedPreset || '-'}
        onChange={setSelectedPreset}
        options={[
          { label: '-', value: '-' },
          ...sortArray('alphabetically', machines.filter(m => m.id !== machineId), 'name').map(machine => ({ label: machine.name, value: machine.id })),
        ]}
      />
      &nbsp;
      <SubmitButton
        onClick={() => selectPreset()}
        label={t('import')}
        askConfirmation
      />
    </>
  );

  const machine = machines.find(m => m.id === machineId);

  if (selectedPerformanceCause && selectedPerformanceCause.name) {
    const currentPerformanceCause = machine.performanceCauses.find(pc => pc.id === selectedPerformanceCause.id);
    return (
      <PerformanceCauseEdition
        performanceCause={currentPerformanceCause}
        goBackForm={() => setSelectedPerformanceCause(null)}
        machineId={machineId}
      />
    );
  }

  if (selectedPerformanceCause) {
    return (
      <PerformanceCauseForm
        performanceCause={selectedPerformanceCause}
        isInSubMenu={false}
        goBackForm={() => setSelectedPerformanceCause(null)}
        machineId={machineId}
      />
    );
  }

  return (
    <div className="PerformanceCausesForm">
      {presetSelection()}
      <div className="PerformanceCausesIconsContainer">
        <DndProvider backend={HTML5Backend}>
          <DraggableCauseIconContainer
            causesArray={performanceCauses}
            onClick={setSelectedPerformanceCause}
            updateCauses={updatePerformanceCauses}
          >
            <AddIcon
              onClick={() => setSelectedPerformanceCause({})}
            />
          </DraggableCauseIconContainer>
        </DndProvider>
      </div>
    </div>
  );
};

PerformanceCausesForm.propTypes = {
  machineId: PropTypes.string.isRequired,
};

export default PerformanceCausesForm;
