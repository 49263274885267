import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from '@intelligenceindustrielle/react-ui-components';
import { reduxOperations } from '~services';
import { DeleteIcon } from '~components/UI/IconButtons';
import { showSuccess, showError } from '~utils/toast';
import './ImagePromptsSettings.scss';
import { SubmitButton } from '~UI';

const ImagePromptsSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allImagePrompts = useSelector(state => state.settings.settings?.imagePrompts);

  const [imagePrompts, setImagePrompts] = useState(allImagePrompts);

  useEffect(() => {
    dispatch(reduxOperations.settings.fetchSettings());
  }, []);

  const handleChangeImagePromptName = (event, imagePromptIndex) => {
    const newName = event;

    const newImagePrompts = imagePrompts.map((imagePrompt, index) => (
      index === imagePromptIndex ? { ...imagePrompt, name: newName } : imagePrompt
    ));

    setImagePrompts(newImagePrompts);
  };

  const handleCreateImagePrompt = (event, imagePromptIndex) => {
    const newPrompt = event;

    const newImagePrompts = imagePrompts.map((imagePrompt, index) => (
      index === imagePromptIndex ? { ...imagePrompt, prompt: newPrompt } : imagePrompt
    ));

    setImagePrompts(newImagePrompts);
  };

  const onRemoveItem = imagePromptName => {
    const newImagePrompts = imagePrompts.filter(imagePrompt => imagePrompt.name !== imagePromptName);
    setImagePrompts(newImagePrompts);
  };

  const handleAddImagePrompt = () => {
    const newImagePrompt = {
      name: '',
      prompt: '',
    };
    setImagePrompts([...imagePrompts, newImagePrompt]);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const isImagePromptNameUnique = imagePrompts.every((imagePrompt, index) => {
      const currentName = imagePrompt.name;
      return imagePrompts.findIndex((prompt, i) => i !== index && prompt.name === currentName) === -1;
    });

    if (!isImagePromptNameUnique) {
      showError('Tout les noms doivent être uniques');
      return;
    }

    dispatch(reduxOperations.settings.patchSettings({ imagePrompts }))
      .then(() => {
        showSuccess(t('success'));
      })
      .catch(error => {
        showError(error.status);
      });
  };

  return (
    <div className="imagePromptsSettingsModificationForm">
      <div className="formsContainer">
        {imagePrompts.map((imagePrompt, index) => (
          <div key={index} className="imagePromptItem">
            <div className="rowFields">
              <div className="rowInputName">
                <span className="label">
                  {t('name')}
                  :
                </span>
                {' '}
                <Input
                  className="fullwidth"
                  value={imagePrompt.name}
                  onChange={event => handleChangeImagePromptName(event, index)}
                  name="imagePromptName"
                  placeholder={t('valueInput')}
                  style={{ marginRight: '10px', flexGrow: 1 }}
                />
              </div>
              <div className="rowInputId">
                <span className="label">Prompt:</span>
                {' '}
                <Input
                  className="largewidth"
                  value={imagePrompt.prompt}
                  onChange={event => handleCreateImagePrompt(event, index)}
                  name="imagePrompt"
                  placeholder={t('valueInput')}
                  style={{ marginRight: '10px', flexGrow: 1 }}
                />
              </div>
            </div>
            <div className="deleteIcon">
              <DeleteIcon onClick={() => onRemoveItem(imagePrompt.name)} />
            </div>
          </div>
        ))}
        <div className="buttonFooter">
          <button
            type="button"
            className="addImagePromptButton"
            onClick={handleAddImagePrompt}
          >
            {t('addImagePrompt')}
          </button>
          <SubmitButton
            label={t('modify')}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default ImagePromptsSettings;
