import React, { useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import { Prompt, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { reduxOperations, reducersTypes } from '~services';
import { DeleteConfirmationForm, QuitConfirmationForm } from '~components/Popups';
import {
  DefaultModal,
  CancelButton,
  DeleteButton,
  SubmitButton,
  FontAwesome,
  ModalHandler,
} from '~UI';
import { showError, showSuccess } from '~utils/toast';
import directLinks from '~utils/directLinks';
import { triggerTypes } from '~utils/types';
import { stopPropagation } from '~utils';
import { idsToTags } from '~utils/tags';
import TriggerEditionForm from './triggers/TriggerEditionForm';
import ActionEditionForm from './actions/ActionEditionForm';
import TriggerSummaryComponent from './TriggerSummaryComponent';
import ActionsContainer from './ActionsContainer';
import ActionSummaryComponent from './ActionSummaryComponent';
import ActionSelection from './actions/ActionSelectionPage';
import RuleTagsForm from '../../../../components/Pages/Grids/RuleGrid/RuleTagsForm/RuleTagsForm';
import './RuleEditionPages.scss';

const configPath = directLinks.utils.configPathFrom;

const propTypes = {
  trigger: reducersTypes.triggers.trigger,
  goBack: PropTypes.func.isRequired,
};

const defaultProps = {
  trigger: null,
};

const RuleEditionPageContent = ({ trigger, goBack }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const language = useSelector(state => state.views.language);
  const actions = useSelector(state => state.actions);
  const tags = useSelector(state => state.tags.tags);

  const history = useHistory();

  const [triggerState, setTrigger] = useState(trigger);
  const initialTrigger = cloneDeep(triggerState);
  const [name, setName] = useState(triggerState ? triggerState.name : '');
  const [showPopupId, setShowPopupId] = useState(null);
  const [showQuitDialog, setShowQuitDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [bypassDialog, setBypassDialog] = useState(false);
  const [nextLocation, setNextLocation] = useState(configPath(directLinks.ruleEngine()));
  const [tagsState, setTags] = useState(triggerState ? idsToTags((triggerState.tags || []), tags) : []);

  const blockQuit = () => {
    if (bypassDialog) { return false; }
    return !isEqual(triggerState, initialTrigger);
  };

  const routerGoTo = path => {
    setBypassDialog(true);
    history.push(path);
  };

  const handleQuit = () => {
    routerGoTo(nextLocation);
  };

  const handleQuitting = nextLocationArg => {
    setShowQuitDialog(true);
    setNextLocation(nextLocationArg);
    return false;
  };

  const handleDeleting = () => {
    setShowDeleteDialog(true);
  };

  const handleQuitDialogClose = () => {
    setShowQuitDialog(false);
  };

  const handleDeleteDialogClose = () => {
    setShowDeleteDialog(false);
  };

  const handleSetShowPopupId = showPopupIdArg => {
    setShowPopupId(showPopupIdArg);
  };

  const closePopup = () => {
    setShowPopupId(null);
  };

  const handleSubmit = () => {
    // used to convert 'fakeDefaults' (button trigges) to defaults with type
    const isFakeDefaults = [triggerTypes.BUTTON, triggerTypes.WEBHOOK].includes(triggerState.type);
    let triggerDetails = {};
    if (triggerState.details && Object.entries(triggerState.details).length > 0) {
      triggerDetails = triggerState.details;
    } else if (isFakeDefaults) {
      triggerDetails = { type: triggerState.type };
    }

    const request = {
      details: triggerDetails,
      isActive: triggerState.isActive,
      name,
      type: isFakeDefaults ? triggerTypes.DEFAULT : triggerState.type,
      actionsToPerform: triggerState.actionsToPerform,
      condition: isEmpty(triggerState.condition) ? null : triggerState.condition,
      tags: tagsState,
    };
    if (!request.name || request.name.length === 0) {
      showError(t('missingName'));
      return;
    }
    if (triggerState.id) {
      dispatch(reduxOperations.triggers.updateTrigger(triggerState.id, request))
        .then(() => {
          routerGoTo(configPath(directLinks.ruleEngine()));
          showSuccess(t('showSuccessUpdated'));
          dispatch(reduxOperations.tags.forceFetchTags());
        })
        .catch(err => showError(err.response.error));
    } else {
      dispatch(reduxOperations.triggers.addTrigger(request))
        .then(() => {
          routerGoTo(configPath(directLinks.ruleEngine()));
          showSuccess(t('showSuccessCreated'));
          dispatch(reduxOperations.tags.forceFetchTags());
        })
        .catch(err => showError(err.response.error));
    }
  };

  const handleSavePassedParameters = (actionIndex, newParams) => {
    setTrigger(prevTrigger => {
      const newActionsToPerform = prevTrigger.actionsToPerform
        .map((action, index) => {
          if (index === actionIndex) {
            const params = {};
            for (const prop in newParams) {
              if (newParams[prop] !== '') {
                params[prop] = newParams[prop];
              }
            }
            return { ...action, params };
          }
          return action;
        });
      const newTrigger = { ...prevTrigger, actionsToPerform: newActionsToPerform };
      return newTrigger;
    });
  };

  const handleSaveActionParameters = (actionIndex, newParams) => {
    const openParameters = Object.values(newParams)
      .filter(param => {
        if (Array.isArray(param)) {
          return param.map(item => item.search('#') !== -1);
        }
        return typeof param === 'string' && param.search('#') !== -1;
      })
      .map(param => {
        if (Array.isArray(param)) {
          return param.map(item => item.substring(1, param.length));
        }
        return param.substring(1, param.length);
      });

    setTrigger(prevTrigger => {
      const newActionsToPerform = prevTrigger.actionsToPerform.map((action, index) => {
        if (index === actionIndex) {
          const params = {};
          if (action.params) {
            for (const prop in action.params) {
              if (openParameters.includes(prop)) {
                params[prop] = newParams[prop] || prevTrigger.actionsToPerform[index].params[prop];
              }
            }
            return { ...action, params };
          }
        }
        return action;
      });
      const newTrigger = { ...prevTrigger, actionsToPerform: newActionsToPerform };
      return newTrigger;
    });
  };

  const handleDeleteRule = async () => {
    if (triggerState.id) {
      dispatch(reduxOperations.triggers.deleteTrigger(triggerState.id));
      routerGoTo(configPath(directLinks.ruleEngine()));
    }
  };

  const handleUpdateTrigger = (triggerArg, formData) => {
    triggerArg.details = formData;
    setTrigger(triggerArg);
  };

  const handleSaveConditions = (id, newCondition, isAdding) => {
    const copyTrigger = { ...triggerState, condition: { ...triggerState.condition } };

    if (isAdding) {
      if (isEmpty(copyTrigger.condition) || copyTrigger.condition.id === id) {
        copyTrigger.condition = newCondition;
      } else {
        copyTrigger.condition.conditions.forEach((cond, index) => {
          if (cond.id === id) {
            copyTrigger.condition.conditions[index] = newCondition;
          } else if (cond.type !== 'Expression') {
            copyTrigger.condition.conditions[index].conditions.forEach((subCond, index2) => {
              if (subCond.id === id) {
                copyTrigger.condition.conditions[index].conditions[index2] = newCondition;
              }
            });
          }
        });
      }
    } else {
      copyTrigger.condition = newCondition;
    }

    setTrigger(copyTrigger);
    closePopup();
  };

  const selectAction = newAction => {
    setTrigger(prevTrigger => {
      const actionsToPerform = prevTrigger.actionsToPerform || [];
      return { ...prevTrigger, actionsToPerform: [...actionsToPerform, { id: newAction.id, params: {} }] };
    });
  };

  const removeActionFromList = (index, id) => {
    // if index = 0 does not pass the condition
    if (index !== null && index !== undefined) {
      setTrigger(prevTrigger => {
        const newTrigger = { ...prevTrigger, actionsToPerform: [...prevTrigger.actionsToPerform] };
        newTrigger.actionsToPerform.splice(index, 1);
        return newTrigger;
      });
    } else {
      setTrigger(prevTrigger => {
        const newTrigger = {
          ...prevTrigger,
          actionsToPerform: prevTrigger.actionsToPerform
            .filter(action => action.id !== id),
        };
        return newTrigger;
      });
    }
  };

  const removeActionHandler = (e, index) => {
    stopPropagation(e);
    removeActionFromList(index);
  };

  const updateActionsList = newActionsList => {
    setTrigger(prevTrigger => {
      const newTrigger = { ...prevTrigger, actionsToPerform: newActionsList };
      return newTrigger;
    });
  };

  const renderPopup = (isTrigger, popupId, action) => (isTrigger ? (
    <DefaultModal
      show={showPopupId === popupId}
      title={t('selectTrigger')}
      closePopup={closePopup}
    >
      <TriggerEditionForm
        trigger={triggerState}
        handleUpdateTrigger={handleUpdateTrigger}
        closePopup={closePopup}
      />
    </DefaultModal>

  ) : (
    <DefaultModal
      show={showPopupId === popupId}
      title={t('selectAction')}
      closePopup={closePopup}
    >
      {action ? (
        <ActionEditionForm
          goBack={closePopup}
          actionId={action.id}
          trigger={triggerState}
          handleSave={handleSaveActionParameters}
          handleDelete={removeActionFromList}
        />
      ) : (
        <ActionSelection
          selectAction={selectAction}
          closePopup={closePopup}
          actions={actions}
          selectedAction={action}
        />
      )}
    </DefaultModal>
  ));

  const renderActionSummaryRow = (actionId, index, moveAction, updateActions) => (
    <ActionSummaryComponent
      key={`action_${index}`}
      actions={actions}
      actionId={actionId}
      index={index}
      setShowPopupId={handleSetShowPopupId}
      showPopupId={showPopupId}
      renderPopup={renderPopup}
      trigger={triggerState}
      removeAction={removeActionHandler}
      closePopup={closePopup}
      handleSavePassedParameters={handleSavePassedParameters}
      moveAction={moveAction}
      updateActions={updateActions}
    />
  );

  return (
    <div className="RuleEditionContainer">
      <Prompt
        when={blockQuit()}
        message={handleQuitting}
      />
      <QuitConfirmationForm
        handleQuit={handleQuit}
        show={showQuitDialog}
        onHide={handleQuitDialogClose}
      />
      <DeleteConfirmationForm
        handleDelete={handleDeleteRule}
        show={showDeleteDialog}
        onHide={handleDeleteDialogClose}
      />
      <div className="header">
        <input
          type="text"
          placeholder={t('giveNameToRule')}
          onChange={e => setName(e.target.value)}
          defaultValue={name}
          className="ruleNameInput"
        />
        <div className="tagIcon">
          <ModalHandler
            Trigger={{
              Component: FontAwesome,
              props: {
                icon: 'tag',
                size: '2x',
              },
            }}
            Modal={{
              Component: RuleTagsForm,
              props: {
                updateTags: newTags => setTags(newTags),
                modifiedItemId: triggerState && triggerState.id,
              },
            }}
          />
        </div>
      </div>

      <TriggerSummaryComponent
        trigger={triggerState}
        showPopupId={showPopupId}
        setShowPopupId={handleSetShowPopupId}
        closePopup={closePopup}
        handleSaveConditions={handleSaveConditions}
        renderPopup={renderPopup}
      />

      <div className="vlContainer">
        <div className={!triggerState ? 'vl' : 'vlSelected'} />
      </div>
      <div className="summaryTriggerTitle">
        {t('then')}
      </div>
      <DndProvider backend={HTML5Backend}>
        <ActionsContainer
          actionsToPerform={((triggerState && triggerState.actionsToPerform) || [])}
          renderActionSummaryRow={renderActionSummaryRow}
          language={language}
          updateActionsList={updateActionsList}
        />
        {renderActionSummaryRow(null)}
      </DndProvider>
      <div className="buttonsHolder flexSpaceBetween">
        {
          triggerState && triggerState.id ? (
            <DeleteButton
              handleDelete={handleDeleting}
            />
          ) : <div />
        }
        <div>
          <CancelButton onClick={goBack} />
          <SubmitButton label={t('submit')} onClick={handleSubmit} />
        </div>
      </div>
    </div>
  );
};

RuleEditionPageContent.propTypes = propTypes;
RuleEditionPageContent.defaultProps = defaultProps;

export default RuleEditionPageContent;
