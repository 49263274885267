import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CancelButton, SubmitButton } from '~UI/index';
import { getFormData } from '~utils/index';
import './ActionForm.scss';

const ActionParamForm = ({
  actionParamsKeys,
  parameters,
  handleClose,
  handleSave,
  actionIndex,
}) => {
  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();
    handleSave(actionIndex, getFormData('Action__form'));
    handleClose();
  };

  const replaceParam = str => str.replace('#', '');

  return (
    <form id="Action__form" onSubmit={handleSubmit}>
      {
        actionParamsKeys
          .map(param => replaceParam(param))
          .map(param => (
            <div key={`param_${param}`}>
              <div className="inputTitle">{replaceParam(param)}</div>
              <input
                placeholder={param}
                type="text"
                name={replaceParam(param)}
                defaultValue={parameters[param] || null}
                className="fullwidth"
              />
            </div>
          ))
      }
      <div className="buttonsHolder" style={{ textAlign: 'right' }}>
        <CancelButton onClick={handleClose} />
        <SubmitButton label={t('modify')} />
      </div>
    </form>
  );
};

ActionParamForm.propTypes = {
  actionParamsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  parameters: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  actionIndex: PropTypes.number.isRequired,
};

export default ActionParamForm;
