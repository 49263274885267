import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  MachineOperatorPopup, MachineEventPopup, PerformanceEventPopup,
  DefectEventPopup, MachineOperationPopup, MachineSKUPopup,
  ConfirmationForm,
} from '~components/Popups';
import { CommentIcon, EditIcon, PlusIcon } from '~components/UI/IconButtons';
import { reduxOperations } from '~services/index';
import API from '~services/endpoints';
import { showError } from '~utils/toast';
import './EventsTable.scss';

const EventsTable = ({
  columnNames, entriesProperties, entries, onModifyEvent, isModifying, isTimeline,
}) => {
  const { t } = useTranslation();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [editingProp, setEditingProp] = useState('');
  const [multipleProp, setMultipleProp] = useState(null);
  const [inputValues, setInputValues] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [entriesToUpdate, setEntriesToUpdate] = useState([]);
  const [editingValue, setEditingValue] = useState('');
  const [prevValue, setPrevValue] = useState(null);

  const operations = useSelector(state => state.operations.operations);
  const settings = useSelector(state => state.settings.settings);
  const offColor = settings.defaultUnfilledStopCauseColor;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reduxOperations.operators.fetchOperators());
    dispatch(reduxOperations.operations.fetchOperations());
  }, []);

  useEffect(() => {
    setSelectAll(false);
    const updatedSelectedEntries = selectedEntries.map(selectedEntry => {
      const newEntry = entries.find(entry => entry.id === selectedEntry.id);
      return newEntry || selectedEntry;
    });
    setSelectedEntries(updatedSelectedEntries);
  }, [entries]);

  const updateEntries = async (updatingEntries, prop, newValue) => {
    const updatedProp = prop === 'startDate' ? 'timestamp' : prop;
    const operation = prop === 'operation' ? operations.find(op => op.name === newValue) : null;
    const skuValue = prop === 'skuNumber' ? newValue : operation?.skuNumber || updatingEntries[0].skuNumber;

    const PART_EVENT_PARAMS_PROPS = ['skuNumber', 'workOrder', 'operation', 'operator'];
    const isPartEventParamsProp = pr => PART_EVENT_PARAMS_PROPS.includes(pr);

    const updateInfos = e => {
      const propsToUpdate = prop === 'operation' && operation
        ? ['operation', 'skuNumber', 'requiredQuantity', 'unitCompletionTime']
        : [prop];

      const updatedInfos = e.infos.map(info => {
        if (propsToUpdate.includes(info.name)) {
          return { ...info, value: operation ? operation[info.name === 'operation' ? 'name' : info.name] : info.value };
        }
        return info;
      });

      const updatedInfosObject = { ...e.infosObject };

      propsToUpdate.forEach(p => {
        if (!updatedInfos.some(info => info.name === p)) {
          updatedInfos.push({ name: p, value: operation ? operation[p === 'operation' ? 'name' : p] : newValue });
        }
        updatedInfosObject[p] = operation ? operation[p === 'operation' ? 'name' : p] : newValue;
      });

      return { ...e, infos: updatedInfos, infosObject: updatedInfosObject };
    };

    const updatedEntries = updatingEntries.map(e => {
      if ((prop === 'motif' || prop === 'comments' || prop === 'resolutions') && (['ON'].includes(e.status) || ['IN', 'OUT'].includes(e.eventType))) {
        return null;
      }
      return (e.type === 'PartEvent' && isPartEventParamsProp(prop) ? updateInfos(e) : { ...e, [updatedProp]: newValue });
    }).filter(e => e !== null);

    const determineData = updatedEntry => {
      if ((prop === 'motif' || prop === 'comments' || prop === 'resolutions')) {
        return newValue;
      }
      if (updatedEntry.type === 'PartEvent') {
        if (isPartEventParamsProp(prop)) {
          return { infos: updatedEntry.infos, infosObject: updatedEntry.infosObject };
        }
        return { [updatedProp]: newValue };
      }
      return { [updatedProp]: newValue, skuNumber: skuValue };
    };

    await Promise.all(updatedEntries.map(updatedEntry => dispatch(
      reduxOperations.events.patchEvent(updatedEntry.id, determineData(updatedEntry)),
    )));
  };

  const updateEntry = async (entry, prop, newValue) => {
    const updateMultipleEntries = selectedEntries.length > 1 && prop !== 'startDate' ? selectedEntries : [entry];
    if (prop === 'startDate') {
      newValue = Date.parse(newValue);
    }
    if (updateMultipleEntries.length > 1) {
      setEditingProp(prop);
      setEditingValue(newValue);
      setEntriesToUpdate(updateMultipleEntries);
      setShowConfirmation(true);
      return;
    }
    await updateEntries(updateMultipleEntries, prop, newValue);
    onModifyEvent();
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    if (newSelectAll) {
      setSelectedEntries(entries);
    } else {
      setSelectedEntries([]);
    }
  };

  const handleCheckboxChange = (entry, checked) => {
    if (checked) {
      setSelectedEntries([...selectedEntries, entry]);
    } else {
      setSelectedEntries(selectedEntries.filter(e => e.id !== entry.id));
    }
  };

  const getBackgroundColor = (entry, undefinedColor) => {
    const convertToSixDigitHex = color => {
      if (color.length === 4) {
        return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`;
      }
      return color;
    };

    if (entry.eventType === 'SCRAP') {
      return entry.causeColor ? `${convertToSixDigitHex(entry.causeColor)}70` : 'noColor';
    }
    if (entry.status === 'OFF') {
      return entry.causeColor ? `${convertToSixDigitHex(entry.causeColor)}70` : `${convertToSixDigitHex(undefinedColor)}70`;
    }
    if (entry.status === 'ON') {
      return '#1E871E70';
    }
    if (entry.status === 'START') {
      return entry.causeColor ? `${convertToSixDigitHex(entry.causeColor)}70` : '';
    }
    return '';
  };

  const handleDuplicateEvent = async entry => {
    const currentIndex = entries.findIndex(e => e.id === entry.id);
    const nextEntry = currentIndex > 0 ? entries[currentIndex - 1] : null;

    const newTimestamp = nextEntry
      ? (entry.timestamp + nextEntry.timestamp) / 2
      : entry.timestamp + 1;

    const newEntry = { ...entry, timestamp: newTimestamp, duplicate: true };
    delete newEntry.id;
    await API.createEvent(newEntry);
    onModifyEvent(true);
  };

  const handleInputChange = (prop, entryId, newValue) => {
    setInputValues(prevValues => ({
      ...prevValues,
      [`${prop}_${entryId}`]: newValue,
    }));
  };

  const handleOnClick = (prop, entryId) => {
    setMultipleProp(prop);
    setInputValues(prevValues => {
      setPrevValue(prevValues[`${prop}_${entryId}`]);
      return prevValues;
    });
  };

  const validateStartDate = value => {
    const newDate = Date.parse(value);
    const sevenDaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    if (newDate < sevenDaysAgo) {
      showError(t('cannotModifyOlderThan7Days'));
      return false;
    }
    return true;
  };

  const handleOnKeyDown = (e, entry, prop) => {
    if (e.key === 'Enter') {
      if (prop === 'startDate' && !validateStartDate(e.target.value)) {
        return;
      }
      updateEntry(entry, prop, e.target.value);
      setMultipleProp(null);
      e.target.blur();
    }
  };

  const handleOnBlur = (e, entry, prop) => {
    if (prop === 'startDate' && !validateStartDate(e.target.value)) {
      setInputValues(prevValues => ({
        ...prevValues,
        [`${prop}_${entry.id}`]: prevValue,
      }));
      return;
    }
    updateEntry(entry, prop, e.target.value);
    setMultipleProp(null);
  };

  const getInputValue = (prop, entry, inputs) => (inputs[`${prop}_${entry.id}`] !== undefined ? inputs[`${prop}_${entry.id}`] : entry[prop]);

  const getStyleForTd = (prop, isModify) => {
    if (prop === 'operator' || prop === 'operation') {
      return { maxWidth: '150px' };
    } if (isModify) {
      return {};
    }
    return { paddingTop: '12px', paddingBottom: '12px' };
  };

  const eventPopupComponents = {
    MachineStatus: MachineEventPopup,
    PerformanceEvent: PerformanceEventPopup,
    PartEvent: DefectEventPopup,
  };

  const getEventPopupComponent = entryType => eventPopupComponents[entryType];

  const renderOperatorCellContent = (entry, prop) => (isModifying && !isTimeline ? (
    <div className="editHover">
      <div className="textEllipsis">{entry[prop]}</div>
      <div className="editIcon">
        <EditIcon Modal={{
          Component: MachineOperatorPopup,
          props: {
            machineId: entry.machineId,
            eventModifyTrigger: username => updateEntry(entry, prop, username),
          },
        }}
        />
      </div>
    </div>
  ) : (
    <div className="textEllipsis" title={entry[prop]}>{entry[prop]}</div>
  ));

  const renderMotifCellContent = (entry, prop) => {
    const EventPopupComponent = getEventPopupComponent(entry.type);
    return (
      <div className={isTimeline ? 'editAlways' : 'editHover'}>
        <div className="editName">
          {entry[prop]}
        </div>
        <div className="editIcon">
          <EditIcon Modal={{
            Component: EventPopupComponent,
            props: {
              event: entry,
              machineId: entry.machineId,
              modifiedItemId: entry.id,
              eventModifyTrigger: updatedData => updateEntry(entry, prop, updatedData),
              ...entries,
            },
          }}
          />
        </div>
      </div>
    );
  };

  const renderStartDateWorkOrderCountCellContent = (entry, prop) => {
    const propToClass = {
      startDate: '',
      workOrder: 'medium',
      count: 'small',
    };
    return (
      <input
        className={`editable-cell ${propToClass[prop] || ''} 
          ${multipleProp === prop && prop !== 'startDate' && selectedEntries.some(e => e.id === entry.id) ? 'multiple-editing' : ''}`}
        value={getInputValue(prop, entry, inputValues)}
        onClick={() => handleOnClick(prop, entry.id)}
        onChange={e => { handleInputChange(prop, entry.id, e.target.value); }}
        onKeyDown={e => { handleOnKeyDown(e, entry, prop); }}
        onBlur={e => { handleOnBlur(e, entry, prop); }}
      />
    );
  };

  const renderSkuNumberCellContent = (entry, prop) => (
    !entry.operation ? (
      <div className="editHover">
        <div>{entry[prop]}</div>
        <div className="editIcon">
          <EditIcon Modal={{
            Component: MachineSKUPopup,
            props: {
              machineId: entry.machineId,
              machineParams: entry,
              eventModifyTrigger: operation => updateEntry(entry, prop, operation),
            },
          }}
          />
        </div>
      </div>
    ) : entry[prop]
  );

  const renderOperationCellContent = (entry, prop) => (isModifying ? (
    <div className="editHover">
      <div className="textEllipsis">{entry[prop]}</div>
      <div className="editIcon">
        <EditIcon Modal={{
          Component: MachineOperationPopup,
          props: {
            machineId: entry.machineId,
            machineParams: entry,
            eventModifyTrigger: operation => updateEntry(entry, prop, operation),
          },
        }}
        />
      </div>
    </div>
  ) : (
    <div className="editHover">
      <div className="textEllipsis" title={entry[prop]}>{entry[prop]}</div>
    </div>
  ));

  const EventPopupProps = (entry, prop, EventPopupComponent, initialTab) => ({
    Component: EventPopupComponent,
    props: {
      event: entry,
      machineId: entry.machineId,
      modifiedItemId: entry.id,
      eventModifyTrigger: updatedData => updateEntry(entry, prop, updatedData),
      initialTab,
      ...entries,
    },
  });

  const renderCommentsResolutionsCellContent = (entry, prop) => {
    const EventPopupComponent = getEventPopupComponent(entry.type);
    const initialTab = prop === 'comments' ? 'comments' : 'resolution';
    const eventPopupProps = EventPopupProps(entry, prop, EventPopupComponent, initialTab);

    if (isModifying && !isTimeline && entry.comments) {
      return (
        <div className="centerIcon">
          <CommentIcon Modal={eventPopupProps} />
        </div>
      );
    }

    if (isTimeline) {
      return (
        <div className="editAlways">
          <div className="editName">{entry[prop]}</div>
          <div className="editIcon">
            <EditIcon Modal={eventPopupProps} />
          </div>
        </div>
      );
    }

    if (entry.comments) {
      return (
        <div className="centerIcon">
          <CommentIcon className="commentIconDisabled" />
        </div>
      );
    }
  };

  const renderCellContent = (prop, entry) => {
    if (prop === 'operator') {
      return renderOperatorCellContent(entry, prop);
    }
    if (prop === 'motif' && (entry.status === 'OFF' || entry.eventType === 'SCRAP' || entry.status === 'START') && isModifying) {
      return renderMotifCellContent(entry, prop);
    }
    if ((prop === 'startDate' || prop === 'workOrder' || prop === 'count') && isModifying && !isTimeline) {
      return renderStartDateWorkOrderCountCellContent(entry, prop);
    }
    if (prop === 'skuNumber' && isModifying && !isTimeline) {
      return renderSkuNumberCellContent(entry, prop);
    }
    if (prop === 'operation' && !isTimeline) {
      return renderOperationCellContent(entry, prop);
    }
    if ((prop === 'comments' || prop === 'resolutions')) {
      return renderCommentsResolutionsCellContent(entry, prop);
    }
    return entry[prop];
  };

  return (
    <>
      <table className="EventsTable">
        <thead>
          <tr>
            {isModifying && (
              <th style={{ width: '10px' }}>
                <div className="centerIcon">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </div>
              </th>
            )}
            {columnNames.map(({ name }) => (
              <th style={isModifying ? {} : { paddingTop: '14px', paddingBottom: '14px' }} key={`col_${name}`}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {entries.map(entry => (
            <tr
              key={entry.id}
              style={{ backgroundColor: getBackgroundColor(entry, offColor) }}
            >
              {isModifying && (
                <td style={{ width: '10px' }}>
                  <div className="centerIcon">
                    {!isTimeline && (
                      <div className="duplicate-icon">
                        <PlusIcon onClick={() => handleDuplicateEvent(entry)} color="blue" />
                      </div>
                    )}
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={selectedEntries.some(e => e.id === entry.id)}
                      onChange={e => handleCheckboxChange(entry, e.target.checked)}
                    />
                  </div>
                </td>
              )}
              {entriesProperties.map(prop => (
                <td
                  key={`${prop}_${entry.id}`}
                  style={getStyleForTd(prop, isModifying)}
                >
                  {renderCellContent(prop, entry)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmationForm
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        title={t('confirm')}
        body={t('confirmMultipleEntries')}
        actionLabel={t('confirm')}
        confirmButtonColor="#0078FF"
        handleAction={async () => {
          await updateEntries(entriesToUpdate, editingProp, editingValue);
          onModifyEvent();
          setShowConfirmation(false);
        }}
      />
    </>
  );
};

EventsTable.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  entriesProperties: PropTypes.arrayOf(PropTypes.string).isRequired,
  entries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onModifyEvent: PropTypes.func.isRequired,
  isModifying: PropTypes.bool.isRequired,
  isTimeline: PropTypes.bool.isRequired,
};

export default EventsTable;
