import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { stopPropagation } from '~utils/index';

const propTypes = {
  Trigger: PropTypes.shape({
    Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    props: PropTypes.shape({
      isFavorite: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  }).isRequired,
  Modal: PropTypes.shape({
    Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    props: PropTypes.shape({
      onHide: PropTypes.func,
    }),
  }).isRequired,
  shouldPromptModal: PropTypes.bool,
};

const defaultProps = {
  shouldPromptModal: true,
};

const ModalHandler = ({ Trigger, Modal, shouldPromptModal }) => {
  const [show, setShow] = useState(false);

  const handleShow = e => {
    if (shouldPromptModal) {
      setShow(true);
      stopPropagation(e);
    }

    if (Trigger.props.onClick) {
      Trigger.props.onClick();
    }
  };

  const handleClose = () => {
    if (Object.prototype.hasOwnProperty.call(Modal.props, 'onHide')) {
      Modal.props.onHide();
    }
    setShow(false);
  };

  return (
    <>
      <Trigger.Component
        {...Trigger.props}
        onClick={handleShow}
      />
      {
        shouldPromptModal && (
          <Modal.Component
            {...Modal.props}
            show={show}
            onHide={handleClose}
          />
        )
      }
    </>
  );
};

ModalHandler.propTypes = propTypes;
ModalHandler.defaultProps = defaultProps;

export default ModalHandler;
