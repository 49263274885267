import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { tryJSONParse } from '~utils/parser';
import { getApproximateFontSize } from '~utils/responsiveValue';
import {
  PushButton,
  MachineOperatorPopup,
  MachineWorkorderPopup,
  TransitEventPopup,
} from '~UI';
import { showError } from '~utils/toast';
import { reduxOperations } from '~services';
import JsxWidget from './BaseWidgets/JsxWidget';

const ButtonWidget = ({
  widget,
  executeTrigger,
  backgroundColor,
  x,
  y,
  w,
  h,
}) => {
  const { t } = useTranslation();
  const connexion = useSelector(state => state.session.connexion);
  const clientName = useSelector(state => state.settings.settings.googleAnalytics.clientName);
  const [showPopup, setShowPopup] = useState(false);

  const trigger = () => {
    const triggerId = widget.onClickTrigger;
    const params = tryJSONParse(widget.buttonParams) || {};
    if (triggerId) {
      // Sent to the global GA view for global KPI analysis
      ReactGA.event({
        category: clientName,
        action: 'TRIGGER_BUTTON_CLICKED',
        label: connexion.infos.email,
      });

      // Each client has its own GA property defined and the data will be more precise on each client property
      ReactGA.event({
        category: clientName,
        action: 'TRIGGER_BUTTON_CLICKED',
        label: JSON.stringify({
          user: connexion.infos.email,
          payload: {
            triggerId,
            params,
          },
        }),
      });
      executeTrigger(triggerId, params).then(res => {
        if (res.payload.response === 'Rule is not active') {
          showError(t('ruleNotActive'));
        }
      });
    }
  };

  const onPopupShow = () => {
    setShowPopup(true);
  };

  const onPopupHide = () => {
    setShowPopup(false);
  };

  const handleOnClickButton = () => {
    const buttonType = widget.buttonType || widget.type;

    if (buttonType === 'triggerButton') {
      trigger();
    } else {
      onPopupShow();
    }
  };

  const getPopup = () => {
    const popupParams = tryJSONParse(widget.buttonParams) || {};
    switch (widget.popup) {
      case 'MachineOperatorPopup':
        return (
          <MachineOperatorPopup
            show={showPopup}
            onHide={onPopupHide}
            params={popupParams}
            onSubmitTrigger={widget.onClickTrigger}
          />
        );
      case 'MachineWorkorderPopup':
        return (
          <MachineWorkorderPopup
            show={showPopup}
            onHide={onPopupHide}
            params={popupParams}
            onSubmitTrigger={widget.onClickTrigger}
          />
        );
      case 'TransitEventPopup':
        return (
          <TransitEventPopup
            show={showPopup}
            onHide={onPopupHide}
            params={popupParams}
            onSubmitTrigger={widget.onClickTrigger}
          />
        );
      default:
        break;
    }
    return null;
  };

  const btnLength = Math.min(w, h) / 1.2;
  const fontSize = getApproximateFontSize(widget.buttonText, btnLength, btnLength, widget.zoom);
  return (
    <JsxWidget
      dimension={{ x, y, w, h }}
      backgroundColor={backgroundColor}
      shape={widget.format.shape}
    >
      <div className="ButtonTile framed flex">
        <PushButton
          buttonSize={btnLength}
          buttonColor={widget.buttonColor}
          buttonText={widget.buttonText}
          fontSize={fontSize}
          onClick={handleOnClickButton}
        />
      </div>
      {getPopup()}
    </JsxWidget>
  );
};

ButtonWidget.propTypes = {
  widget: PropTypes.shape({
    id: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    w: PropTypes.number,
    h: PropTypes.number,
    zoom: PropTypes.number,
    value: PropTypes.string,
    format: PropTypes.shape({
      type: PropTypes.string.isRequired,
      data: PropTypes.shape({}),
      shape: PropTypes.string,
    }),
    linksTo: PropTypes.string,
    type: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonText: PropTypes.string,
    buttonType: PropTypes.string,
    popup: PropTypes.string,
    onClickTrigger: PropTypes.func,
    buttonParams: PropTypes.shape({}),
  }).isRequired,
  executeTrigger: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  w: PropTypes.number.isRequired,
  h: PropTypes.number.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    executeTrigger: reduxOperations.triggers.executeTrigger,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ButtonWidget);
