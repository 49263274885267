import {
  addStopCauseRequest,
  addStopCauseResponse,
  updateStopCauseRequest,
  updateStopCauseResponse,
  deleteStopCauseRequest,
  deleteStopCauseResponse,
  addSubStopCauseRequest,
  addSubStopCauseResponse,
  updateSubStopCauseRequest,
  updateSubStopCauseResponse,
  deleteSubStopCauseRequest,
  deleteSubStopCauseResponse,

} from '../actions';
import API from '~services/endpoints';

function addStopCause(machineId, stopCause) {
  return dispatch => {
    dispatch(addStopCauseRequest({ machineId, stopCause }));
    return API.createStopCause(machineId, stopCause)
      .then(res => {
        if (!res.error) {
          dispatch(addStopCauseResponse({ machineId, machine: res.machine }));
        }
      });
  };
}

function updateStopCause(machineId, stopCauseId, stopCause) {
  return dispatch => {
    dispatch(updateStopCauseRequest({ machineId, stopCauseId, stopCause }));
    return API.updateStopCause(machineId, stopCauseId, stopCause)
      .then(res => dispatch(updateStopCauseResponse({ machineId, machine: res.machine })));
  };
}

function deleteStopCause(machineId, stopCauseId) {
  return dispatch => {
    dispatch(deleteStopCauseRequest({ machineId, stopCauseId }));
    return API.deleteStopCause(machineId, stopCauseId)
      .then(res => dispatch(deleteStopCauseResponse({ machineId, machine: res.machine })));
  };
}

function addSubStopCause(machineId, parentCauseId, stopCause) {
  return dispatch => {
    dispatch(addSubStopCauseRequest({ machineId, parentCauseId, stopCause }));
    return API.createSubStopCause(machineId, parentCauseId, stopCause)
      .then(res => dispatch(addSubStopCauseResponse({ machineId, machine: res.machine })));
  };
}

function updateSubStopCause(machineId, parentCauseId, stopCauseId, stopCause) {
  return dispatch => {
    dispatch(updateSubStopCauseRequest({ machineId, parentCauseId, stopCauseId, stopCause }));
    return API.updateSubStopCause(machineId, parentCauseId, stopCauseId, stopCause)
      .then(res => dispatch(updateSubStopCauseResponse({ machineId, machine: res.machine })));
  };
}

function deleteSubStopCause(machineId, parentCauseId, stopCauseId) {
  return dispatch => {
    dispatch(deleteSubStopCauseRequest({ machineId, parentCauseId, stopCauseId }));
    return API.deleteSubStopCause(machineId, parentCauseId, stopCauseId)
      .then(res => dispatch(deleteSubStopCauseResponse({ machineId, machine: res.machine })));
  };
}

export {
  addStopCause,
  updateStopCause,
  deleteStopCause,
  addSubStopCause,
  updateSubStopCause,
  deleteSubStopCause,
};
